<template>
  <p>
    <span v-if="isPro">Need more screens? to unlock more</span>
    <span v-else-if="shouldShowBonusScreensBanner">Claim Your 10 Bonus Screens with an Annual Subscription!</span>
    <span v-else>You’re on the free plan, upgrade to unlock more screens</span>
    &nbsp;
    <an-link @click="onUpgradeClick" variant="primary">
      <Calendly v-if="isRegularPro" :url="this.contactLink" displayType="popup"> Let's talk </Calendly>
      <span v-else-if="shouldShowBonusScreensBanner">Upgrade now</span>
      <span v-else>Buy more screens</span>
    </an-link>
  </p>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Calendly from '@/components/Calendly/Calendly';

export default {
  name: 'screen-limit-reached-banner',
  components: {
    Calendly
  },
  data() {
    return {
      contactLink: 'https://calendly.com/anima-demo/adjust-plan?source=anima-web&banner=pro-screens&type=top-banner'
    };
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'currentItem' }),
    ...mapGetters({
      screensLimit: 'teamMemberships/screensLimit',
      isPro: 'teamMemberships/isPro'
    }),
    isRegularPro() {
      return this.isPro && !this.team?.uses_stigg_integration;
    },
    shouldShowBonusScreensBanner() {
      return this.team?.uses_stigg_integration;
    }
  },
  mounted() {
    this.$trackEvent('webapp:screen_limit_reached_sales_banner.show', {
      plan_id: this.teamMemberships.team_plan,
      plan_screens: this.screensLimit,
      number_of_screens: this.team.projects_components_count
    });
  },
  methods: {
    onUpgradeClick() {
      this.$trackEvent('screen_limit_reached_banner::upgrade-now.clicked', {
        plan_id: this.teamMemberships.team_plan,
        plan_screens: this.screensLimit,
        number_of_screens: this.team.projects_components_count
      });

      if (!this.isRegularPro) {
        if (this.shouldShowBonusScreensBanner) {
          const interval = 'ANNUALLY';
          const query = {
            intentionType: 'UPGRADE_PLAN',
            interval,
            plan: 'plan-anima-pro',
            addon: 'addon-anima-bonus-pack'
          };
          this.$router.push({ name: 'team-payment-confirmation', query });
        } else {
          this.$router.push({ name: 'team-pricing' });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  &__content {
    display: flex;
    align-items: center;
    gap: 2em;
    justify-content: space-around;
    text-align: left;
    font-family: 'Mulish';
    font-style: normal;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ::v-deep button {
      color: #f3b962 !important;
      border: unset !important;
      background: var(--white) !important;
      padding: 6px 24px !important;
    }
  }
}
</style>

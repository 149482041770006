import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'theme',
  state: {
    themeName: 'light'
  },
  mutations: {
    setThemeName: (state, themeName) => (state.themeName = themeName)
  }
});

export const getRecaptchaToken = (action) => {
  return new Promise((resolve, reject) => {
    try {
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(async () => {
          const captchaToken = await window.grecaptcha.enterprise.execute('6LchCGEqAAAAADcpPOSXS8m8E5KAl8oVtdq5brk6', {
            action
          });
          resolve(captchaToken);
        });
      } else {
        resolve(process.env.SKIP_CAPTCHA_KEY);
      }
    } catch (error) {
      reject(error);
    }
  });
};

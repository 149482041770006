<template>
  <div id="app">
    <div class="content">
      <!-- Modals/toasts should go here  -->
      <Toasts />
      <IntercomBubble />
      <Modals />
      <Banners />
      <ConsentBanner />
      <DebugEvents />
      <DevConsole v-if="isDevConsoleOpen" @close="isDevConsoleOpen = false" />
      <div :style="viewStyle">
        <router-view />
      </div>
    </div>
    <div>
      <SidebarLayout :isOpen="isOnboardingOpen" v-bind="onboardingProps">
        <personalized-onboarding-panel @close="closeOnboarding" v-bind="onboardingProps" />
      </SidebarLayout>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Toasts from '@/containers/Toasts.vue';
import Modals from '@/containers/Modals.vue';
import Banners from '@/containers/Banners.vue';
import DebugEvents from '@/containers/DebugEvents.vue';
import DevConsole from '@/views/DevConsole.vue';
import SidebarLayout from '@/components/Layout/Sidebar/SidebarLayout.vue';
import { hideAppStatusBanner, showAppStatusBanner } from '@/services/banners';
import { checkAppStatus } from '@/services/app-status';
import { detectAdBlock } from '@/services/ad-blocker';
import IntercomBubble from '@/components/IntercomBubble/IntercomBubble.vue';
import { EventBus } from '@/services/bus';
import SocketMixin from '@/mixins/SocketMixin';
import JSConfetti from 'js-confetti';
import errorHandler from './services/errorHandler';
import ConsentBanner from '@/components/Banners/ConsentBanner.vue';

export default {
  components: {
    Toasts,
    Modals,
    Banners,
    IntercomBubble,
    DebugEvents,
    DevConsole,
    SidebarLayout,
    ConsentBanner
  },
  created() {
    this.showDevPrefsIfNeeded();
    this.screenSizeListener();
    this.verifyAppStatus();
    this.intervalId = setInterval(this.verifyAppStatus, 60 * 1000);
    this.initConfetti();
    this.getClientIpAddress();
  },
  mounted() {
    EventBus.$on('set-experiments', this.populateExperiments);
    EventBus.$on('network-error', this.showNetworkError);
    EventBus.$on('confetti', this.addConfetti);
    this.fetchTimeZoneExperiments();
  },
  destroyed() {
    EventBus.$off('set-experiments', this.populateExperiments);
    EventBus.$off('network-error', this.showNetworkError);
    EventBus.$off('confetti', this.addConfetti);
    clearInterval(this.intervalId);
  },
  mixins: [SocketMixin],
  data() {
    return {
      isDevConsoleOpen: false,
      intervalId: null,
      confettiCanvas: null,
      isOpen: true
    };
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('webappSystem', { isOnboardingOpen: 'isPersonalizedOnboardingOpen' }),
    ...mapState('webappSystem', { onboardingProps: 'personalizedOnboardingProps' }),
    ...mapState('webappSystem', ['bannerHeight', 'isBannerOpen']),
    ...mapGetters('experiments', ['experiments']),
    viewStyle() {
      return {
        height: `calc(100vh - ${this.isBannerOpen ? this.bannerHeight : '0'}px)`,
        transition: 'all 0.2s ease'
      };
    }
  },
  methods: {
    ...mapActions({
      fetchUserOnboarding: 'userOnboardings/fetchUserOnboarding',
      populateExperiments: 'experiments/populateExperiments',
      fetchTimeZoneExperiments: 'experiments/fetchTimeZoneExperiments',
      screenSizeListener: 'screenSize/listen'
    }),
    ...mapMutations('webappSystem', ['setAdBlockerEnabled', 'setIsPersonalizedOnboardingOpen', 'setIpAddress']),
    ...mapMutations({
      setUserOnboarding: 'userOnboardings/setCurrentItem'
    }),

    async verifyAppStatus() {
      const { status, props = {} } = await checkAppStatus();
      if (status === 'problem') {
        showAppStatusBanner(props);
      } else {
        hideAppStatusBanner();
      }
    },
    async checkForAdBlocker() {
      const isAdBlockerEnabled = await detectAdBlock();
      this.setAdBlockerEnabled(isAdBlockerEnabled);
    },
    showDevPrefsIfNeeded() {
      if (window.location.host.startsWith('canary')) {
        this.isDevConsoleOpen = true;
      }
    },
    showNetworkError() {
      showAppStatusBanner();
    },
    initConfetti() {
      this.confettiCanvas = new JSConfetti();
    },
    addConfetti(props = {}) {
      if (this.confettiCanvas) {
        this.confettiCanvas.addConfetti({
          confettiColors: ['#009379', '#F2BFAF', '#3366FF', '#F8D57E', '#BFAFF2', '#FF6250'],
          ...props
        });
      }
    },
    closeOnboarding() {
      this.$trackEvent('personalized-onboarding.panel.close');
      this.setIsPersonalizedOnboardingOpen(false);
    },
    async initUserOnboarding() {
      try {
        const userOnboarding = await this.fetchUserOnboarding({ skipCache: true });
        if (userOnboarding) {
          this.setSocket();
        }
      } catch (err) {
        errorHandler.captureException(err);
      }
    },
    setSocket() {
      this.openSocket(this.user?.id);
      if (this.socket) {
        this.socket.on({ resource: 'userOnboarding', action: 'updated' }, this.setUserOnboarding);
      }
    },
    getClientIpAddress() {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then(({ ip }) => {
          this.setIpAddress(ip);
        })
        .catch(() => {
          this.setIpAddress('unknown');
        });
    }
  },
  watch: {
    user: {
      handler: 'initUserOnboarding',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  width: 100vw;
  .content {
    position: relative;
    width: 100%;
  }
}
</style>

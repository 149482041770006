<template>
  <transition name="fade">
    <div id="panel-paywall" class="paywall-container" v-if="show">
      <div class="close-button clickable-icon" ref="closeButton" @click.stop="close">
        <svg-icon name="close-white" :size="closeButtonSize" />
      </div>
      <div class="illustration"><img src="@/assets/illustrations/rocket-pro.svg" /></div>
      <div class="content">
        <div class="title">Unlock Pro features</div>
        <div class="text">
          Upgrade your plan to download code or access the Anima code components in HTML, React, or Vue.
        </div>
        <an-button variant="primary" @click="upgrade">{{ ctaText }}</an-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';
import { getNextScreenLimit } from '@/services/subscriptions';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      closeButtonSize: 24,
      show: false
    };
  },
  mounted() {
    EventBus.$on('show-paywall', this.showPaywall);
  },
  destroyed() {
    EventBus.$off('show-paywall', this.showPaywall);
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      isPricingScreenFlowActiveExperiment: 'experiments/isPricingScreenFlowActive',
      isTrialSupported: 'teams/isTeamTrialSupported'
    }),
    ctaText() {
      return this.isTrialSupported ? 'Start free trial' : 'Upgrade';
    }
  },
  methods: {
    showPaywall({ ...eventProps } = {}) {
      this.show = true;
      EventBus.$emit('open-panel');
      setTimeout(
        () =>
          this.$trackEvent('omniview.paywall.show', {
            ...eventProps,
            isHacked: !document.getElementById('panel-paywall')
          }),
        0
      );
    },
    upgrade() {
      const nextScreenOption = getNextScreenLimit(this.team.projects_components_count) || 'talk';
      const query = {
        interval: 'annually',
        ...(this.isPricingScreenFlowActiveExperiment
          ? {
              plan: 'proScreens',
              screen_qty: nextScreenOption
            }
          : {
              plan: 'pro'
            })
      };
      this.$router.push({ name: 'team-payment', query });
      this.$trackEvent('omniview-paywall.upgrade.click');
    },
    close() {
      this.$trackEvent('omniview-paywall.dismiss.click');
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.paywall-container {
  background-color: rgba(43, 43, 43, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000001;
  overflow: hidden;
}
.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin-left: 60px;
  .title {
    @include headline;
  }
  .text {
    max-width: 510px;
    margin: 22px 0;
  }
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  &.clickable-icon:hover {
    background-color: transparent;
  }
}
</style>

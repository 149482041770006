<template>
  <div v-if="show" :class="['container', theme]">
    <component :is="loaders[type]" :text="text" :theme="theme" :max="max" :timeConstant="timeConstant" />
  </div>
</template>

<script>
import DefaultLoader from '@/components/Loading/DefaultLoader';
import SyncingLoader from '@/components/Loading/SyncingLoader';
export default {
  data() {
    return {
      show: false,
      loaders: { default: DefaultLoader, syncing: SyncingLoader }
    };
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'syncing'].includes(v)
    },
    delay: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: 'light',
      validator: (v) => ['light', 'dark'].includes(v)
    },
    max: {
      type: Number
    },
    timeConstant: {
      type: Number
    }
  },
  mounted() {
    if (this.delay) {
      setTimeout(() => {
        this.show = true;
      }, 200);
    } else {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 800px;
  @include desktop {
    min-width: 500px;
  }
  &.dark {
    color: #ffffff;
  }
  &.light {
    color: var(--primary-text);
  }
}
</style>

<template>
  <button
    class="an-button"
    type="button"
    :style="{
      '--height': buttonSizes[size].height + 'px',
      '--padding': buttonSizes[size].padding,
      '--fontSize': buttonSizes[size].fontSize + 'px'
    }"
    :class="[variant, { iconOnly: !$slots.default, isActive, isWorking, rounded }]"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="listeners"
  >
    <div v-if="isWorking" class="three-dots loader">
      <div class="bounce1" />
      <div class="bounce2" />
      <div class="bounce3" />
    </div>
    <div :style="{ visibility: isWorking ? 'hidden' : 'visible', display: 'inline-flex', width: 'auto' }">
      <svg-icon v-if="icon && iconPosition == 'left'" :size="iconSize" :name="icon" />

      <span
        class="text"
        v-if="$slots.default"
        :class="{
          withPaddingLeft: icon && iconPosition == 'left',
          withPaddingRight: icon && iconPosition == 'right'
        }"
      >
        <slot />
      </span>
      <svg-icon v-if="icon && iconPosition == 'right'" :size="iconSize" :name="icon" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'an-button',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
      }
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'secondary', 'tertiary', 'empty'].indexOf(value) !== -1;
      }
    },
    icon: {
      type: String,
      default: undefined
    },
    iconSize: {
      type: Number,
      default: 16
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => {
        return ['left', 'right'].indexOf(value) !== -1;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isWorking: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      buttonSizes: {
        xs: { height: 24, fontSize: 12, padding: '0 20px' },
        sm: { height: 28, fontSize: 14, padding: '0 20px' },
        md: { height: 36, fontSize: 16, padding: '0 24px' },
        lg: { height: 40, fontSize: 16, padding: '0 24px' },
        xl: { height: 48, fontSize: 16, padding: '0 24px' }
      }
    };
  },
  computed: {
    listeners() {
      const click = this.isWorking ? () => {} : this.$listeners?.click;
      return {
        ...this.$listeners,
        click: click || (() => {})
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_loading.scss';
.text {
  display: inline-flex;
  height: 100%;
  align-items: center;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.an-button {
  position: relative;
  height: var(--height);
  padding: var(--padding);
  font-size: var(--fontSize);
  border-radius: 4px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 100ms;
  appearance: none;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  > div {
    width: 100%;
  }

  &.primary {
    background: var(--primary);
    color: #ffffff;
    &:hover:not(:disabled) {
      background: var(--primary-hover);
    }
  }
  &.secondary {
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
    .three-dots div {
      background: var(--primary);
    }
    &:hover:not(:disabled) {
      color: #ffffff;
      background: var(--primary);
      .three-dots div {
        background: #ffffff;
      }
    }
  }
  &.tertiary {
    background: transparent;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    .three-dots div {
      background: var(--secondary);
    }
    &:hover:not(:disabled) {
      color: #ffffff;
      background: var(--secondary);
      .three-dots div {
        background: #ffffff;
      }
    }
  }
  &.empty {
    background: transparent;
    outline: none;
    color: var(--primary);
    padding: 0;
    height: auto;
    .three-dots div {
      background: var(--primary);
    }
    &:focus,
    &:active {
      outline: none;
    }
  }

  &.rounded {
    border-radius: 100px;
  }

  &.iconOnly {
    padding: 0 8px;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.isWorking,
  &:disabled {
    cursor: default;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.withPaddingLeft {
  padding-left: 8px;
}
.withPaddingRight {
  padding-right: 8px;
}
</style>

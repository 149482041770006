<template>
  <div class="container">
    <div class="title">Payment</div>
    <div class="subtitle">Add a payment method and review your upgrade details.</div>

    <div class="body">
      <div class="billing-info">
        <div class="billing-plan">
          <div class="label">
            <span>Billing plan</span>
            <span v-if="isTrialSupported" class="trial-label">Includes a 7 day trial before charging</span>
          </div>
          <PricingRow
            v-for="_interval in ['annually', 'monthly']"
            :key="_interval"
            :price="getRowPrice(_interval)"
            :planName="planNameDisplay"
            :planInterval="_interval"
            :isCurrentPlan="_interval === interval"
            :disabled="isActivePlan(_interval) || loading"
            :showRibbon="_interval === 'annually' && isNewSubscription && isDesktop && !isActivePromoCode"
            :screenQty="screenQty"
            @click="changeInterval(_interval)"
          />
        </div>

        <div class="paid-seats">
          <div class="label">Select your team paid seats</div>
          <div class="number-of-contributors">
            <span>No. of contributors</span>
            <an-number-input
              v-model="quantity"
              :min="minSeats"
              :max="99"
              :disabled="loading"
              :minErrorTooltipText="minErrorTooltipText"
              @subtract="onSubtractQuantity"
            />
          </div>
        </div>

        <an-prompt v-if="showMinContributorsPrompt" @close="onCloseMinContributorsPrompt">
          <header>
            <h2 class="title">{{ team.name }}</h2>
            <h3 class="subtitle">{{ contributorsCount }} {{ contributorsCount === 1 ? 'paid seat' : 'paid seats' }}</h3>
          </header>

          <MembersForm class="team-members-form-prompt" hide-invite-input event-source="payment" />

          <footer class="footer">
            <p>Contributors and Admins require a paid seat</p>
          </footer>
        </an-prompt>

        <div class="separated">
          <div v-if="showScreensLogic()" class="paid-screens">
            <div class="number-of-screens">
              <span>Number of screens</span>
              <an-stepper :options="screenPriceOptions()" :default-value="screenQty" @select="onSelectScreens" />
            </div>
            <div class="paid-screens-description">
              For more than 30 screens -
              <an-link :href="businessPlanAnnualLink" target="_blank" variant="primary">talk to us</an-link>
            </div>
          </div>
          <!-- TODO disabled until PayPal gateway is active again
          <div class="payment-method-label">
            <div style="display: grid">
              <span>Payment method</span>
              <PopoverMenu
                :items="paymentMethodOptions"
                position="left"
                :selectedLabel="paymentMethodLabel"
                data-cy="payment-method-select"
                :popover-style="{ width: '225px' }"
              >
                <div slot="reference" class="payment-method-select">
                  {{ paymentMethodLabel }}
                  <svg-icon name="select-arrow-down" :size="24" />
                </div>
              </PopoverMenu>
            </div>
            <svg-icon v-if="isStripe" name="powered-by-stripe" :width="110" :height="24" />
          </div> -->

          <PaymentMethodFeedbackForm
            v-if="isPaymentMethodOther && !paymentMethodFeedbackThanks"
            @submit="trackPaymentMethodFeedback"
          />
          <p v-if="isPaymentMethodOther && paymentMethodFeedbackThanks" data-cy="payment-method-feedback-thanks">
            Thanks 🎉 We are currently exploring new payment methods, we will update you with the outcomes!
          </p>

          <template v-if="isStripe">
            <div v-if="showExistingCreditCardDetails" class="payment-method-label label">
              {{ creditCardLastDigits }}
              <an-link @click="clickedChange = true" variant="primary">Change</an-link>
            </div>
            <div class="payment" v-if="!showExistingCreditCardDetails && !isPaymentMethodOther">
              <StripeCardInput @mount="onStripeCardMount" @change="onStripeCardChange" />
              <div class="invoice-address-label">Invoice to (optional)</div>
              <an-textarea placeholder="Billing address" withBorder v-model="invoiceTo" allowMultiline />
            </div>
          </template>
        </div>

        <div class="separated" v-if="isStripe && isNewSubscription && !isPaymentMethodOther">
          <div class="promo-code-header" @click="toggleCouponCodeSection">
            Add a coupon code
            <div class="collapse-icon" :class="{ rotated: isPromoCodeOpen }">
              <svg-icon name="arrow-right" :size="30" />
            </div>
          </div>

          <div v-if="isPromoCodeOpen">
            <div class="disclaimer promo-code-details" v-if="isActivePromoCode">
              <div class="promo-code-details-name">
                {{ promoCode }}
                <an-link @click="resetPromoCode" variant="primary">Remove</an-link>
              </div>
              <div class="promo-code-details-saving">
                {{ promoCodeSaving }}
              </div>
            </div>

            <div v-else>
              <div class="label promo-code-input-label">Coupon code</div>
              <div class="promo-code-input-container">
                <an-input v-model="promoCode" class="promo-code-input" />
                <an-button
                  variant="secondary"
                  @click="applyPromoCode"
                  :isWorking="isPromoCodeWorking"
                  :invalid="!!promoCodeError"
                  :disabled="!promoCode.trim()"
                >
                  Apply code
                </an-button>
              </div>
              <div class="promo-code-input-error" v-if="promoCodeError">{{ promoCodeError }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="checkout">
        <div class="checkout-title">Check out</div>
        <Loader v-if="loading" />
        <div v-else>
          <div class="checkout-list">
            <div v-for="line in checkoutDetails" :key="line" class="checkout-list-item">
              <svg-icon name="circle-check" :size="20" />
              <div class="checkout-list-item-text">{{ line }}</div>
            </div>
          </div>
          <div class="total-price-container" style="margin-bottom: 16px" v-if="isActivePromoCode">
            <span>Discount</span>
            <transition name="slide-fade">
              <span class="total-price" :key="promoCodeSaving">
                {{ promoCodeSaving }}
              </span>
            </transition>
          </div>
          <div class="total-price-container">
            <span>Total</span>
            <transition name="slide-fade">
              <span class="total-price" :key="priceAfterDiscountDisplay">
                {{ priceAfterDiscountDisplay }}
              </span>
            </transition>
          </div>
          <div class="price-details" v-if="priceDetails && !isProScreenTier">
            {{ priceDetails }}
          </div>
          <div class="price-details" v-if="priceDetails && isProScreenTier">
            <div class="price-details__element">
              <div class="price-details__label">{{ screenQty }} screens</div>
              <div class="price-details__value">
                ${{ getPrice({ plan: planName, interval, quantity: screenQty, screenOption: 'limited' }) }}
                <span v-if="interval === 'annually'"> / mo × 12</span>
              </div>
            </div>
            <div v-if="quantity > 1" class="price-details__element">
              <div class="price-details__label">{{ quantity - 1 }} extra contributor</div>
              <div class="price-details__value">
                ${{ getPrice({ plan: planName, interval }) }}
                <span v-if="interval === 'annually'"> / mo × 12</span>
              </div>
            </div>
          </div>
          <div class="diff-disclaimer" v-if="diffDisclaimer">{{ diffDisclaimer }}</div>
          <div class="cta" v-if="hasChanges">
            <div class="paypal-button" v-if="isPaypal">
              <an-button
                v-if="showPaypalLoading || paypalSubscriptionId"
                @click="purchase"
                variant="tertiary"
                :isWorking="isWorking"
              >
                {{ ctaText }}
              </an-button>
              <PayPalButton
                v-else
                :plan="planName"
                :interval="interval"
                :quantity="isProScreenTier ? screenQty || quantity : quantity"
                :screenOption="screenOption"
                @click="onPaypalClick"
                @approve="onPaypalApproved"
                @error="onPaypalError"
              />
            </div>
            <div v-else-if="isStripe">
              <an-button
                data-cy="purchase-cta-btn"
                @click="purchase"
                :isWorking="isWorking"
                :disabled="stripePurchaseButtonDisabled"
              >
                {{ ctaText }}
              </an-button>
            </div>
          </div>
        </div>
        <div v-if="errorMessage" class="error" data-cy="purchase-error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { isEmpty, upperFirst } from 'lodash-es';

import { apiPlanName, formatPrice } from '@/utils/billing';
import { SubscriptionMixin } from '@/mixins';

import StripeCardInput from '@/components/Payment/StripeCardInput';
import PayPalButton from '@/components/Payment/PayPalButton';
import PricingRow from '@/components/Pricing/PricingRow';
import Loader from '@/components/Loading/DefaultLoader';
import MembersForm from '@/components/Team/Settings/MembersForm.vue';
import PaymentMethodFeedbackForm from '@/components/Payment/PaymentMethodFeedbackForm.vue';
import { openModal, EventBus, toastError, toastSuccess, showIntercomBubble } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import { readCookie } from '@/utils/cookie';
import { uuid } from '@/utils/uuid';
import { capitalizePlan } from '@/utils/capitalizePlan';
import { sendFacebookConversion, sendGoogleConversion } from '@/services/conversion';

export default {
  name: 'payment',
  data() {
    return {
      invoiceTo: '',
      quantity: 2,
      planName: '',
      interval: '',
      subscriptionId: '',
      stripeSubscriptionId: '',
      paypalSubscriptionId: '',
      promoCode: '',
      isPromoCodeOpen: false,
      promoCodeError: '',
      nextInvoiceData: {},
      isActivePromoCode: false,
      promoCodeDiscountPercent: 0,
      minBusinessPlanSeats: 3,
      service: 'stripe',
      creditCard: null,
      clickedChange: false,
      loading: false,
      isWorking: false,
      showPaypalLoading: false,
      isPromoCodeWorking: false,
      errorMessage: null,
      completedFillingPaymentDetails: false,
      showMinContributorsPrompt: false,
      paymentAttempts: 0,
      paymentMethod: 'credit_card',
      paymentMethodFeedbackThanks: false,
      screenOption: null,
      screenQty: null,
      businessPlanAnnualLink: 'https://www.animaapp.com/demo-request?source=anima-web&banner=pro-screens&type=payment'
    };
  },
  mixins: [SubscriptionMixin],
  components: {
    StripeCardInput,
    PayPalButton,
    PricingRow,
    Loader,
    MembersForm,
    PaymentMethodFeedbackForm
  },
  mounted() {
    const { promo_code } = this.$route.query;
    this.reset();
    this.resetQuantity();
    if (promo_code) {
      this.promoCode = promo_code;
      this.isPromoCodeOpen = true;
      this.applyPromoCode();
    }
    if (this.isPersonalizedOnboardingOpen) {
      this.setIsPersonalizedOnboardingOpen(false);
    }
    this.checkUsesStiggIntegration();
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapState('webappSystem', ['isPersonalizedOnboardingOpen', 'ipAddress']),
    ...mapGetters({
      contributorsCount: 'teamMemberships/contributorsCountInTeam',
      screensLimit: 'teamMemberships/screensLimit',
      activeSubscription: 'stripeSubscriptions/activeSubscription',
      isActiveExperiment: 'experiments/isActive',
      isTrialExperimentActive: 'experiments/isTrialExperimentActive',
      isTeamTrialSupported: 'teams/isTeamTrialSupported',
      isProScreenTier: 'teams/isProScreenTier',
      userEmail: 'users/userEmail',
      userFirstName: 'users/firstName',
      userLastName: 'users/lastName'
    }),
    isAnnual() {
      return this.interval === 'annually';
    },
    isMonthly() {
      return this.interval === 'monthly';
    },
    isNewSubscription() {
      return isEmpty(this.activeSubscription);
    },
    isBusinessPlan() {
      return this.planName === 'business';
    },
    hasChanges() {
      if (this.isNewSubscription) {
        return true;
      }
      const { planName, quantity, interval } = this;
      const {
        quantity: oldQuantity,
        interval: oldInterval,
        product_name: oldPlan = 'free',
        plan_id: planId
      } = this.activeSubscription;
      const hasChanges = oldInterval !== interval || oldPlan !== apiPlanName(planName);
      if (this.isProScreenTier && this.screensLimit) {
        const screenOption = this.getScreenOptionFromPlanId(planId);
        return hasChanges || this.screenOption !== screenOption || this.screensLimit !== this.screenQty;
      }
      return hasChanges || oldQuantity !== quantity;
    },
    isStripe() {
      return this.service === 'stripe';
    },
    isPaypal() {
      return this.service === 'paypal';
    },
    creditCardLastDigits() {
      const { credit_card_last_digits } = this.team;
      if (!credit_card_last_digits) return null;
      return `Credit card ending with ${credit_card_last_digits.padStart(8, '*')}`;
    },
    showExistingCreditCardDetails() {
      return this.creditCardLastDigits && !this.clickedChange;
    },
    ctaText() {
      const { id } = this.activeSubscription ?? {};
      return id ? 'Update plan' : 'Purchase plan';
    },
    showSwitchService() {
      return !this.activeSubscription?.id;
    },
    planNameDisplay() {
      return upperFirst(this.planName) || null;
    },
    checkoutDetails() {
      const { product_name = 'Free', quantity: oldQuantity } = this.activeSubscription;
      const planName = this.planNameDisplay;
      let actionLine;

      if (product_name === apiPlanName(planName)) {
        actionLine = `Update your ${planName} plan.`;
      } else if (product_name === 'Free' && planName === 'Pro') {
        actionLine = `${this.quantity} Pro seat.`;
      } else if (product_name === 'Free' && planName === 'Business') {
        actionLine = `3 Pro seats.`;
      } else {
        actionLine = `Change plan to ${planName}.`;
      }

      const details = [actionLine];

      if (this.isTrialSupported) {
        details.unshift('Charged after your trial ends.');
      }

      if (this.isProScreenTier) {
        details.push(`${this.screenQty} screens.`);
      } else {
        if (planName === 'Pro') {
          details.push(this.isActiveExperiment('limit-team-screens') ? '3 projects, 15 screens.' : '3 projects.');
        }

        if (planName === 'Business') {
          details.push('10 projects.');
        }
      }

      if (this.isNewSubscription) {
        !this.isTrialSupported && details.push('Risk free. 7 day money-back guarantee.');
      } else {
        const { partial_price: partialPrice } = this.nextInvoiceData;

        if (!this.screensLimit && oldQuantity && this.quantity !== oldQuantity) {
          const diff = this.quantity - oldQuantity;
          const absDiff = Math.abs(diff);
          const quantityMsg =
            diff < 0 ? `Reduce ${absDiff} seats from your team.` : `Pay for ${absDiff} additional contributor seats.`;
          details.push(quantityMsg);
        }

        if (partialPrice && partialPrice > 0) {
          details.push('You’ll only pay the difference between the plans.');
          // if (oldInterval === this.interval) {
          //   details.push('You’ll be charged on the next billing cycle.');
          // }
        } else if (partialPrice && partialPrice < 0) {
          const val =
            'The remaining amount will be saved as a credit on your account and put into effect at the beginning of your next billing cycle.';
          details.push(val);
        }
      }

      return details;
    },
    totalPrice() {
      const { planName: plan, interval, quantity, isMonthly: perMonth } = this;
      return this.getPrice({
        plan,
        interval,
        quantity: this.isProScreenTier ? this.screenQty : quantity,
        perMonth,
        screenOption: this.isProScreenTier && this.screenOption
      });
    },
    totalPriceDiscount() {
      return this.totalPrice * (this.promoCodeDiscountPercent / 100);
    },
    priceAfterDiscountDisplay() {
      const {
        planName: plan,
        interval,
        quantity,
        isMonthly: perMonth,
        promoCodeDiscountPercent: discountPercent,
        nextInvoiceData = {}
      } = this;
      const { partial_price: partialPrice } = nextInvoiceData;
      const price = this.getPrice({
        plan,
        interval,
        quantity: this.isProScreenTier ? this.screenQty || quantity : quantity,
        perMonth,
        discountPercent,
        screenOption: this.isProScreenTier && this.screenOption
      });
      return formatPrice(partialPrice || price);
    },
    priceDetails() {
      const { quantity, interval, planName: plan, isAnnual, nextInvoiceData = {} } = this;
      if (nextInvoiceData.partial_price) return;
      const price = formatPrice(
        this.getPrice({
          plan,
          interval,
          quantity: this.isProScreenTier ? this.screenQty || 1 : 1,
          screenOption: this.isProScreenTier && this.screenOption
        })
      );
      const quantityPhrase = `${quantity} seat${quantity === 1 ? '' : 's'}`;
      const pricePhrase = isAnnual ? `(${price} / mo × 12)` : price;
      return `${quantityPhrase} × ${pricePhrase}`;
    },
    promoCodeSaving() {
      const { promoCodeDiscountPercent, totalPriceDiscount } = this;
      return `Saving ${formatPrice(totalPriceDiscount)} (${promoCodeDiscountPercent}%)`;
    },
    planRequiredMin() {
      return ['Team', 'Business'].includes(apiPlanName(this.planName)) ? 3 : 1;
    },
    minSeats() {
      return Math.max(this.planRequiredMin, this.contributorsCount);
    },
    diffDisclaimer() {
      const { planName: newPlan = '' } = this;
      const { product_name: currentPlan = '' } = this.activeSubscription ?? {};
      const { partial_price } = this.nextInvoiceData ?? {};
      if (partial_price) {
        return '';
      }
      const plansOrder = ['prototype', 'basic', 'pro', 'team', 'business'];
      const lowercasePlan = currentPlan.toLowerCase();
      const lowercaseNewPlan = newPlan.toLowerCase();
      const planIndex = plansOrder.findIndex((plan) => plan === lowercasePlan);
      const newPlanIndex = plansOrder.findIndex((plan) => plan === lowercaseNewPlan);
      if (planIndex === -1 || newPlanIndex === -1) {
        return '';
      }
      if (newPlanIndex > planIndex) {
        return 'You’ll only pay the difference towards the new plan – you won’t lose the money which you’ve already paid for your current plan.';
      } else if (newPlanIndex < planIndex) {
        return 'The amount of the remaining pre-paid payment will be added to your account as a credit and will be used for the next payment.';
      }
      return '';
    },
    validPlanAndInterval() {
      const {
        query: { plan, interval }
      } = this.$route;
      return (
        ['basic', 'pro', 'proScreens', 'team', 'business'].includes(plan) && ['monthly', 'annually'].includes(interval)
      );
    },
    minErrorTooltipText() {
      const { minSeats, quantity, minBusinessPlanSeats } = this;
      if (quantity === minSeats && quantity === minBusinessPlanSeats && apiPlanName(this.planName) === 'Team') {
        return `Business plan requires at least ${minBusinessPlanSeats} seats.`;
      }
      return '';
    },
    stripePurchaseButtonDisabled() {
      return !this.completedFillingPaymentDetails && !this.showExistingCreditCardDetails;
    },
    isTrialSupported() {
      return this.planName === 'pro' && this.isStripe && this.isTeamTrialSupported;
    },
    paymentMethodOptions() {
      const options = [
        {
          label: 'Credit card',
          value: 'credit_card',
          dataCy: 'payment-method-credit-card',
          onClick: () => {
            this.switchService('stripe');
            this.setPaymentMethod('credit_card');
          }
        },
        {
          label: 'PayPal',
          value: 'paypal',
          dataCy: 'payment-method-paypal',
          onClick: () => {
            this.switchService('paypal');
            this.setPaymentMethod('paypal');
          }
        }
      ];

      const businessPlanAnnual = [
        {
          label: 'Alipay',
          value: 'alipay',
          dataCy: 'payment-method-alipay',
          onClick: () => this.businessPlanAnnualClick('alipay')
        },
        {
          label: 'ACH direct Debit',
          value: 'ach_direct_debit',
          dataCy: 'payment-method-ach-direct-debit',
          onClick: () => this.businessPlanAnnualClick('ach_direct_debit')
        },
        {
          label: 'SEPA Direct Debit',
          value: 'sepa_direct_debit',
          dataCy: 'payment-method-sepa-direct-debit',
          onClick: () => this.businessPlanAnnualClick('sepa_direct_debit')
        },
        {
          label: 'Giropay',
          value: 'giropay',
          dataCy: 'payment-method-giropay',
          onClick: () => this.businessPlanAnnualClick('giropay')
        },
        {
          label: 'iDeal',
          value: 'ideal',
          dataCy: 'payment-method-ideal',
          onClick: () => this.businessPlanAnnualClick('ideal')
        }
      ];

      const businessPlanMonthly = [
        {
          preText: 'For additional methods',
          label: 'switch to Annual',
          value: 'switch_to_annual',
          dataCy: 'payment-method-switch-to-annual',
          isMarked: true,
          isSeparated: true,
          onClick: () => this.switchToBusinessAnnual()
        }
      ];

      const proPlan = [
        {
          preText: 'For additional methods',
          label: 'switch to the Business annual plan',
          value: 'switch_to_business_annual',
          dataCy: 'payment-method-switch-to-business-annual',
          isMarked: true,
          isSeparated: true,
          onClick: () => this.switchToBusinessAnnual()
        }
      ];

      if (this.isBusinessPlan) {
        if (this.isAnnual) {
          options.push(...businessPlanAnnual);
        } else {
          options.push(...businessPlanMonthly);
        }
      } else {
        options.push(...proPlan);
      }

      return options;
    },
    paymentMethodLabel() {
      const { paymentMethod, paymentMethodOptions } = this;
      const option = paymentMethodOptions.find((so) => so.value === paymentMethod);
      return option?.label;
    },
    isPaymentMethodOther() {
      return this.paymentMethod === 'other';
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      fetchCoupon: 'coupons/fetchOne',
      updateTeam: 'teams/update',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    ...mapMutations({
      setShowPaymentBotError: 'webappSystem/setShowPaymentBotError',
      setIsPersonalizedOnboardingOpen: 'webappSystem/setIsPersonalizedOnboardingOpen'
    }),
    async onStripeCardMount(card) {
      this.creditCard = card;
      this.errorMessage = null;
    },
    onStripeCardChange({ error, complete }) {
      if (error) {
        return errorHandler.captureExceptionAndTrack(error, {
          name: 'payment.stripe-card-element.error',
          data: { message: error.message }
        });
      }
      this.completedFillingPaymentDetails = complete;
    },
    switchService(service) {
      this.service = service;
      if (service === 'paypal') {
        this.resetPromoCode();
      }
    },
    onSelectScreens(screen) {
      const qty = Number(screen.value);
      if (qty) {
        this.$trackEvent('payment_page::select_tier_button.clicked', {
          plan_name: 'pro',
          interval: this.interval,
          screen_tier: qty
        });
      }
      this.screenQty = qty;
    },
    onPaypalClick() {
      this.$trackEvent('payment.paypal-button.click');
      this.errorMessage = null;
    },
    onPaypalApproved(data) {
      this.paypalSubscriptionId = data?.subscriptionID;
      this.$trackEvent('payment.paypal.success');
      this.showPaypalLoading = true;
      this.purchase();
    },
    onPaypalError(data) {
      this.$trackEvent('payment.paypal.failure');
      this.errorMessage = data;
    },
    isActivePlan(_interval) {
      const { product_name = '', plan_id: planId, interval } = this.activeSubscription ?? {};
      this.checkDisplayedPrices(planId);
      const activePlan = apiPlanName(product_name);
      const isSamePlan = _interval === interval && activePlan === apiPlanName(this.planName);
      if (this.isProScreenTier && this.screensLimit) {
        const currentScreenOption = this.getScreenOptionFromPlanId(planId);
        return isSamePlan && this.screensLimit === this.screenQty && this.screenOption === currentScreenOption;
      }
      return isSamePlan;
    },
    getRowPrice(interval) {
      return this.getPrice({
        plan: this.planName,
        interval,
        quantity: this.isProScreenTier ? this.screenQty || 1 : 1,
        screenOption: this.isProScreenTier ? 'limited' : null
      });
    },
    showScreensLogic() {
      return this.isProScreenTier;
    },
    screenPriceOptions() {
      const options = this.getScreenPriceOptions('proScreens') || [];
      return Object.entries(options).map(([key, value]) => {
        value['value'] = Number(key);
        value['label'] = key;
        return value;
      });
    },
    reset() {
      const {
        query: { plan, interval, promo_code: promoCode, screen_qty: screenQty }
      } = this.$route;
      const { id, subscription_id: serviceSubscriptionId, _service: service } = this.activeSubscription ?? {};
      this.screenQty = Number(screenQty);

      if (id && promoCode) {
        return this.$emit('close', { redirect: { name: 'team' } });
      }

      if (!this.validPlanAndInterval) {
        return this.$router.push({ name: 'team-pricing' });
      }

      this.planName = plan === 'proScreens' ? 'Pro' : plan;
      this.interval = interval;
      this.subscriptionId = id;
      this.errorMessage = null;

      if (service) {
        this.service = service;
        if (service === 'stripe') {
          this.stripeSubscriptionId = serviceSubscriptionId;
        } else if (service === 'paypal') {
          this.paypalSubscriptionId = serviceSubscriptionId;
        }
      }

      if (this.stripeSubscriptionId) {
        this.creditCard = null;
      }
    },
    async fetchData() {
      const { query = {}, params = {} } = this.$route;
      try {
        EventBus.$emit('reload-team-info', { skipCache: false });
        await this.fetchTeam({ id: params.teamSlug, params: { is_slug: true } });
      } catch (err) {
        this.$trackEvent('payment.fetch-team.failure', {
          ...params,
          ...query,
          message: err.message,
          email: this.user.email
        });

        this.$emit('close');
      }
    },
    resetPromoCode(leaveOpen = false) {
      const {
        query: { promo_code = '' }
      } = this.$route;
      this.promoCode = promo_code;
      this.isPromoCodeOpen = leaveOpen || !!this.promoCode;
      this.isActivePromoCode = false;
      this.promoCodeError = '';
      this.promoCodeDiscountPercent = 0;
    },
    resetQuantity() {
      let activeSubscriptionQuantity = this.activeSubscription?.quantity || 0;
      if ((this.screenOption || this.screensLimit) && this.isProScreenTier) {
        this.quantity = this.screenOption === 'unlimited' ? this.contributorsCount : this.contributorsCount || 1;
        return;
      }
      this.quantity = Math.max(activeSubscriptionQuantity, this.minSeats) || this.quantity;
    },
    async resetSubscriptionData() {
      try {
        const { planName: plan, quantity, interval } = this;
        if (this.isProScreenTier && this.screenQty > 0) {
          if (quantity > 1) {
            this.screenOption = 'unlimited';
          } else {
            this.screenOption = 'limited';
          }
        }
        const { id: subscriptionId, _service: service } = this.activeSubscription ?? {};
        this.nextInvoiceData = {};
        if (subscriptionId) {
          this.loading = true;
          const { data } = await this.fetchSubscriptionData({
            service,
            subscriptionId,
            plan,
            quantity: this.isProScreenTier ? this.screenQty : quantity,
            interval,
            screenOption: this.screenOption
          });
          this.nextInvoiceData = data;
        }
      } catch (err) {
        errorHandler.captureException(err);
      } finally {
        this.loading = false;
      }
    },
    async changeInterval(newInterval) {
      const { query } = this.$route;
      await this.$router.replace({ query: { ...query, interval: newInterval } });
      this.applyPromoCode();
    },
    toggleCouponCodeSection() {
      this.isPromoCodeOpen = !this.isPromoCodeOpen;
    },
    async applyPromoCode() {
      const promoCode = this.promoCode?.trim();
      const { interval, planName } = this;
      if (!promoCode) return;

      try {
        this.$trackEvent('payment.apply-promo-code-button.click');

        if (!this.tempValidatePromoCode(promoCode, interval, planName)) {
          this.resetPromoCode(true);
          return toastError("Sorry, this coupon isn't valid for the plan you chose.");
        }

        this.promoCodeError = '';
        this.isPromoCodeWorking = true;
        const params = { primary_key: 'stripe_id' };

        const { percent_off, stripe_id } = await this.fetchCoupon({ id: promoCode, params, skipCache: true });
        this.isActivePromoCode = true;
        this.promoCode = stripe_id;
        this.promoCodeDiscountPercent = percent_off || 0;

        this.$trackEvent('payment.apply-promo-code.success', { promoCode });
      } catch (err) {
        this.promoCodeError = 'Oops! Invalid code. Please check and try again.';
        this.$trackEvent('payment.apply-promo-code.failure', { promoCode });
      } finally {
        this.isPromoCodeWorking = false;
      }
    },
    async updateInvoiceAddress() {
      const { team, service, invoiceTo } = this;
      try {
        if (service === 'stripe' && invoiceTo !== team?.invoice_to) {
          const { id } = team;
          const payload = { invoice_to: invoiceTo };
          await this.updateTeam({ id, payload });
          this.$trackEvent('payment.update-invoice-address.success');
        }
      } catch (err) {
        this.$trackEvent('payment.update-invoice-address.failure', {
          invoiceTo,
          service,
          teamId: team.id,
          errorMessage: err.message
        });
      }
    },
    async purchase() {
      const {
        team,
        interval,
        planName,
        planId,
        quantity,
        service,
        screenOption,
        subscriptionId,
        paypalSubscriptionId,
        promoCode,
        isActivePromoCode,
        isDesktop,
        isTrialSupported,
        promoCodeDiscountPercent: discountPercent
      } = this;
      const {
        id: teamId,
        credit_card_last_digits: creditCardLastDigits = null,
        stripe_source_id: stripeSourceId = null
      } = team;
      const newSubscription = !!this.isNewSubscription;
      const creditCard = creditCardLastDigits ? null : this.creditCard;
      const screenWidth = window?.innerWidth;
      this.errorMessage = null;
      this.isWorking = true;
      const eventProps = {
        interval,
        screen_tier: this.isProScreenTier && this.screenQty,
        number_of_contributors: this.isProScreenTier ? this.screenOption || quantity : quantity,
        teamId,
        planName: capitalizePlan(planName),
        quantity,
        screenOption,
        promoCode,
        service,
        newSubscription,
        isDesktop,
        screenWidth,
        isTrial: isTrialSupported,
        planPrice: this.getPrice({
          plan: planName,
          interval,
          perMonth: this.isMonthly,
          quantity: this.isProScreenTier ? this.screenQty : 1,
          screenOption: this.isProScreenTier && this.screenOption
        }),
        totalPaidPrice: this.getPrice({
          plan: planName,
          interval,
          perMonth: this.isMonthly,
          quantity: this.isProScreenTier ? this.screenQty : quantity,
          discountPercent,
          screenOption: this.isProScreenTier && this.screenOption
        }),
        triggerComponent: 'Not Stigg'
      };
      try {
        this.$trackEvent('payment.purchase-button.click', eventProps);

        if (isActivePromoCode && !this.tempValidatePromoCode(promoCode, interval, planName)) {
          return toastError("Sorry, this coupon isn't valid for the plan you chose.");
        }

        const transactionId = uuid();

        await this.createSubscription({
          interval,
          plan: apiPlanName(planName),
          service,
          teamId,
          quantity: this.isProScreenTier ? this.screenQty : quantity,
          screenOption,
          subscriptionId,
          paypalSubscriptionId,
          creditCard,
          isTrial: isTrialSupported,
          promoCode: this.isActivePromoCode ? promoCode : null,
          stripeSourceId
        });

        if (planId !== 'plan-anima-free') {
          this.$trackEvent('payment.purchase.success', eventProps);
        }

        sendGoogleConversion('purchase', transactionId, {
          email: this.userEmail,
          first_name: this.userFirstName,
          last_name: this.userLastName
        });

        sendFacebookConversion('Purchase', transactionId, {
          id: this.user.id,
          email: this.userEmail,
          first_name: this.userFirstName,
          last_name: this.userLastName
        });

        EventBus.$emit('reload-team-info');
        EventBus.$emit('reload-user-memberships');

        if (isTrialSupported) {
          this.$trackEvent('payment.trial.start', eventProps);
        }

        this.nextOnboardingStage({ currentStageSlug: 'purchase', disableConfetti: true });

        this.$gtm.trackEvent({
          event: 'purchases',
          fbEventID: `Purchase:${this.user.id}`,
          fbIpAddress: this.ipAddress,
          fbUserAgent: navigator.userAgent,
          fbc: readCookie('_fbc'),
          fbp: readCookie('_fbp'),
          fbCurrency: 'USD',
          fbValue: this.totalPrice,
          event_category: 'Purchases',
          event_action: this.user?.role,
          event_label: this.user?.email,
          transaction_id: transactionId,
          external_id: this.user?.id,
          first_name: this.user?.first_name,
          last_name: this.user?.last_name,
          email: this.user?.email
        });

        await this.updateInvoiceAddress();
        const { source } = this.$router.currentRoute.query;
        if (newSubscription) {
          openModal({ name: 'payment-success', props: { isTrial: isTrialSupported, source } });
        } else {
          this.$router.push({ name: 'team-settings-billing' });
          toastSuccess('Your plan updated successfully.');
        }
      } catch (error) {
        const exception =
          error?.response?.data?.message || error?.error || error.message || error.originalError || error;
        const errorCode = error?.response?.data?.error_code || error?.error_code;
        const declineCode = error?.response?.data?.decline_code || error?.decline_code;
        errorHandler.captureExceptionAndTrack(error, {
          name: 'payment.purchase.failure',
          data: { message: JSON.stringify(exception), error_code: errorCode, decline_code: declineCode, ...eventProps }
        });
        errorHandler.captureMessage(`[Payment failure] ${exception}`);

        if (error.response?.status !== 500 && this.service !== 'paypal' && this.showPaymentBotError) {
          this.$trackEvent('payment.suggest-paypal.show');
          this.paymentAttempts++;

          Vue.nextTick(() => {
            if (this.paymentAttempts >= 2) {
              this.setShowPaymentBotError(false);
            }
          });

          showIntercomBubble({
            props: {
              message: `Hmmm... there seems to be a payment issue with your credit card,
                why not try to checkout with PayPal instead!
                <button style="color: white; background-color: var(--primary); padding: 0.5rem 0.75rem; border-radius: 100px; margin-top: 1rem;">Pay with PayPal</button>
              `,
              onClick: () => {
                this.$trackEvent('payment.suggest-paypal.click', eventProps);
                this.switchService('paypal');
              },
              hideTimeout: 20 * 1000 // 20 seconds
            }
          });
        }

        this.errorMessage = exception;
      } finally {
        this.isWorking = false;
      }
    },
    onSubtractQuantity({ value }) {
      const isPlanWithSeatsRestriction = ['Team', 'Business'].includes(apiPlanName(this.planName));
      if (isPlanWithSeatsRestriction && value === this.planRequiredMin) {
        return toastError(`${this.planName} plan has a minimum of ${this.minSeats} seats.`);
      }
      if (value > 1 && value === this.minSeats) {
        this.showMinContributorsPrompt = true;
        this.$trackEvent('payment.min-contributors-prompt.show');
      }
    },
    onCloseMinContributorsPrompt() {
      this.showMinContributorsPrompt = false;
      this.$trackEvent('payment.min-contributors-prompt.close');
    },
    setPaymentMethod(value) {
      const paymentMethodOption = this.paymentMethodOptions.find((s) => s.value === value?.toLowerCase());
      if (paymentMethodOption) {
        this.$trackEvent('payment.payment-method-select.click', {
          payment_method: paymentMethodOption.value,
          user: this.user.id
        });
        this.paymentMethod = paymentMethodOption.value;
      }
    },
    trackPaymentMethodFeedback(feedback) {
      this.$trackEvent('payment.other-payment-method-request.submit', { feedback });
      this.paymentMethodFeedbackThanks = true;
    },
    switchToBusinessAnnual() {
      this.$router.replace({ query: { ...this.$route.query, plan: 'business', interval: 'annually' } });
    },
    businessPlanAnnualClick(paymentMethod) {
      this.$trackEvent('payment.payment-method-select.click', {
        payment_method: paymentMethod,
        user: this.user.id
      });
      window.open(this.businessPlanAnnualLink, '_blank');
    },
    checkUsesStiggIntegration() {
      if (this.team?.uses_stigg_integration) {
        this.$router.push({ name: 'team-pricing', query: this.$route.query });
      }
    }
  },
  watch: {
    '$route.path': {
      handler: 'fetchData',
      immediate: true
    },
    '$route.query'() {
      this.reset();
      this.resetSubscriptionData();
    },
    activeSubscription() {
      this.reset();
      this.resetQuantity();
      this.resetSubscriptionData();
    },
    quantity() {
      this.resetSubscriptionData();
    },
    screenOption() {
      this.resetSubscriptionData();
    },
    team({ invoice_to, uses_stigg_integration } = {}) {
      if (invoice_to) {
        this.invoiceTo = invoice_to;
      }
      if (uses_stigg_integration) {
        this.checkUsesStiggIntegration();
      }
    },
    teamMemberships() {
      this.resetQuantity();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
.body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 760px;
  margin-top: 40px;
  padding-top: 40px;
  border-top: var(--border);
  @include mobile {
    flex-direction: column;
  }
}
.plan,
.payment {
  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    &-text {
      font-size: 22px;
    }
  }
  &-name,
  &-amount {
    font-weight: bold;
  }
  &-table,
  &-billing-email-label {
    margin-top: 30px;
  }
}
.billing-info {
  width: 390px;
  @include mobile {
    max-width: 100%;
  }
}
.billing-plan {
  margin-bottom: 30px;
  .trial-label {
    color: var(--red);
    margin-left: 10px;
  }
}
.paypal-button {
  width: 154px;
}
.number-of-contributors,
.number-of-screens {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.paid-screens,
.paid-seats {
  margin-bottom: 10px;
  &-description {
    color: var(--secondary-text);
    font-size: 14px;
    ::v-deep .an-link {
      color: var(--secondary-text) !important;
      text-decoration: underline !important;
    }
  }
}
.checkout {
  background: var(--light-container-background);
  width: 330px;
  padding: 30px;
  border-radius: 10px;
  margin-top: 36px;
  &-title {
    @include secondary-title;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }
  &-list {
    padding: 0 0 30px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }
  &-list-item {
    display: flex;
    align-items: flex-start;
    .svg-container {
      margin: 4px 14px 0 0;
    }
    &-text {
      flex: 1;
    }
    + .checkout-list-item {
      margin-top: 20px;
    }
  }
}
.total-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .total-price {
    @include secondary-title;
    font-weight: 800;
  }
}
.price-details {
  color: var(--secondary-text);
  font-weight: 400;
  font-family: --base-font;
  &__element {
    display: flex;
    justify-content: space-between;
  }
}
.payment-method-label {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-address-label {
  margin: 30px 0 12px;
}
.cta {
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promo-code-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.collapse-icon {
  transition: transform 0.2s ease;
  transform: rotate(90deg);
  &.rotated {
    transform: rotate(-90deg);
  }
}
.promo-code-details {
  &-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-saving {
    color: var(--secondary-text);
  }
}
.promo-code-input {
  width: 235px;
  &-label {
    margin-top: 20px;
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-error {
    color: var(--red);
    margin-top: 5px;
  }
}
.diff-disclaimer {
  border-top: var(--border);
  margin-top: 30px;
  padding-top: 20px;
}
.error {
  margin-top: 30px;
  text-align: center;
  color: var(--red);
}
.subtitle {
  text-align: center;
}
.team-members-form-prompt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ::v-deep.owner {
    border-top: unset;
  }
}
.footer {
  margin-top: 2rem;
}
.payment-method-select {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}
</style>

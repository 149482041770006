<template>
  <div>
    <div class="shared-projects-header">
      <div class="details">
        <div class="title">Shared with me</div>
      </div>
    </div>
    <div class="navigation">
      <div class="search">
        <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
      </div>
      <PopoverMenu v-if="showSort" :items="sortingOptions" position="right" :selectedLabel="sortByLabel">
        <div slot="reference" class="sort-select">
          {{ sortByLabel }}
          <svg-icon name="select-arrow-down" :size="24" />
        </div>
      </PopoverMenu>
    </div>
    <div class="content">
      <ProjectsList type="shared" :searchQuery="searchQuery" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import ProjectsList from '@/components/Team/ProjectsList';
import SearchInput from '@/components/SearchInput/SearchInput';

export default {
  data() {
    return {
      sortingOptions: [
        { label: 'Last updated', value: 'updated', onClick: () => this.setSortBy('updated') },
        { label: 'Date created', value: 'created', onClick: () => this.setSortBy('created') },
        { label: 'Name A-Z', value: 'name', onClick: () => this.setSortBy('name') }
      ],
      sortBy: 'updated',
      searchQuery: '',
      searchBarOpen: false
    };
  },
  components: {
    PopoverMenu,
    ProjectsList,
    SearchInput
  },
  computed: {
    ...mapState('projectGuestAccess', { guestIn: 'items' }),
    sortByLabel() {
      const { sortBy, sortingOptions } = this;
      const option = sortingOptions.find((so) => so.value === sortBy);
      return option.label;
    },
    showSort() {
      return this.isDesktop || !this.searchBarOpen;
    }
  },
  methods: {
    setSortBy(value) {
      this.sortBy = value;
    },
    toggleSearchOpen(value) {
      this.searchBarOpen = value;
      if (value) {
        this.$trackEvent('shared-projects.search.open');
      }
    }
  },
  watch: {
    sortBy(newValue) {
      const { path } = this.$route;
      const query = { sort: newValue };
      this.$router.push({ path, query }, () => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_navigationBar.scss';
.shared-projects-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  .details {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      @include headline;
      letter-spacing: 1px;
      margin: 0 60px 0 16px;
    }
  }
}
.navigation {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  @include mobile {
    padding: 30px 20px 0;
  }
}
.navigation:after {
  @include mobile {
    background: transparent;
  }
}
.sort-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.content {
  margin-top: 50px;
  padding: 0 20px;
}
</style>

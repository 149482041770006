<template>
  <div class="container">
    <div class="title">Connect to Slack</div>
    <div class="body">
      <div class="label">Choose team</div>
      <div class="teams-list">
        <div
          v-for="(t, index) in userMemberships"
          :key="index"
          :class="['team-row-container', 'selectable', { selected: isSelected(t) }]"
          @click="selectedTeam = t"
        >
          <TeamRow :team="t" :members="t.members" :key="t.id" />
        </div>
      </div>

      <div class="actions" v-if="showActions">
        <an-button @click="connectToSlack" :isWorking="isWorking">Connect</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TeamRow from '@/components/Team/TeamRow';
import { SlackMixin } from '@/mixins';
import { toastError } from '@/services/bus';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'connect-slack',
  data() {
    return {
      userMemberships: [],
      selectedTeam: null,
      agree: false,
      isWorking: false
    };
  },
  components: {
    TeamRow
  },
  mixins: [SlackMixin],
  computed: {
    ...mapGetters('teamMemberships', { userTeamMemberships: 'userTeams' }),
    isSelected() {
      return ({ id } = {}) => this.selectedTeam?.id === id;
    },
    showActions() {
      return !!this.selectedTeam?.id;
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions({
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      createNotificationsSettings: 'notificationsSettings/create'
    }),
    reset() {
      this.selectedTeam = this.userTeamMemberships?.find((tm) => tm.id === this.selectedTeam?.id) || null;
      this.userMemberships = cloneDeep(this.userTeamMemberships || []);
    },
    async connectToSlack() {
      try {
        this.isWorking = true;
        const { id, slug: teamSlug } = this.selectedTeam;
        const payload = { channel: 'slack', is_disabled: false };
        const { href: redirect } = this.$router.resolve({ name: 'team-settings-general', params: { teamSlug } });

        this.$trackEvent('connect-slack.connect-button.click', { teamSlug });

        const settings = await this.createNotificationsSettings({ parent: 'teams', id, payload });
        const url = this.getSlackAppUrl({ id: settings.id, redirect });

        window.location.href = url;
      } catch (err) {
        this.$trackEvent('connect-slack.connect.failure', { message: err.message });
        toastError('Failed connecting to Slack :(');
      } finally {
        this.isWorking = false;
      }
    }
  },
  watch: {
    userTeamMemberships() {
      this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container,
.body {
  max-width: 750px;
}
.teams-list {
  margin: 20px 0 30px;
  max-height: 400px;
  overflow-y: auto;
}
.selectable {
  border-top: var(--border);
  padding: 30px 20px;
  cursor: pointer;
  &:last-child {
    border-bottom: var(--border);
  }
  &.current-team {
    border-top: none;
    border-bottom: none;
  }
}
.selectable:hover,
.selected {
  border-top: 1px solid transparent;
  background-color: var(--light-container-background);
  border-radius: 10px;
  + .selectable {
    border-top: 1px solid transparent;
  }
}
.check-box-move-team {
  margin-top: 30px;
}
</style>

<template>
  <div>
    <!-- Domains -->
    <div class="custom-domain-container">
      <div>
        <div class="header">Connect a custom domain<ProBadge /></div>
        <div class="secondary-text">Connect a custom domain you already purchased</div>
      </div>
      <div class="custom-domain-input">
        <an-input
          style="width: 470px"
          v-model="customDomain"
          placeholder="www.mydomain.com"
          @keyup.enter="addCustomDomain"
          @click="onCustomDomainInputClick"
        />
        <an-link @click="addCustomDomain" :isWorking="isDomainWorking" variant="primary">Connect</an-link>
      </div>
      <div v-if="showDnsComment">
        *Be sure to
        <an-link
          variant="primary"
          href="https://support.animaapp.com/en/articles/2593821-add-a-custom-domain-to-your-website"
          target="_blank"
        >
          set up your DNS settings
        </an-link>
        prior to adding your domain.
      </div>
    </div>

    <div v-if="!hideCustomDomainList && !!customDomainsList.length" class="existing-domains">
      <div class="label">Added domains:</div>
      <CustomDomainRow
        v-for="customDomain in customDomainsList"
        :key="customDomain.domain"
        :customDomain="customDomain"
        :isDeleted="isDeleted(customDomain.domain)"
        @remove="markCustomDomainRemoved(customDomain)"
        @un-remove="markCustomDomainNotRemoved(customDomain)"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep, keys, pick } from 'lodash-es';
import { mapActions, mapState, mapGetters } from 'vuex';
import CustomDomainRow from '@/components/Project/Settings/CustomDomainRow';
import { toastError, toastSuccess } from '@/services/bus';
import { normalizeDomain } from '@/utils/urls';
import CustomDomainMixin from '@/mixins/CustomDomainMixin';
import ProBadge from '@/components/Badges/ProBadge.vue';

export default {
  props: {
    hideCustomDomainList: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomDomainRow,
    ProBadge
  },
  data() {
    return {
      customDomain: '',
      newCustomDomains: [],
      domainsToDelete: new Set(),
      deleteCount: 0,
      projectFields: {
        subdomain: '',
        mobile_app_name: '',
        meta_title: '',
        meta_description: '',
        meta_custom_snippet: '',
        analytics_snippet: ''
      },
      isDomainWorking: false,
      isWorking: false,
      showDnsComment: false
    };
  },
  mixins: [CustomDomainMixin],
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('domains', { domains: 'items' }),
    ...mapGetters({
      isPro: 'teamMemberships/isPro',
      isViewer: 'teamMemberships/isViewer',
      isFree: 'teamMemberships/isFree'
    }),
    projectChanged() {
      const { projectFields } = this;
      return keys(projectFields).some((key) => projectFields[key] !== this.project[key]);
    },
    customDomainsChanged() {
      const nonRemovedNewDomains = this.newCustomDomains.filter((d) => !this.domainsToDelete.has(d.domain));
      return !!(nonRemovedNewDomains.length || this.deleteCount);
    },
    customDomainsList() {
      const { newCustomDomains, domains } = this;
      return cloneDeep(newCustomDomains).reverse().concat(domains);
    },
    isDeleted() {
      return (domain) => !!(this.deleteCount && this.domainsToDelete.has(domain));
    }
  },
  methods: {
    ...mapActions({
      fetchCustomDomains: 'domains/fetchAllOfParent',
      createDomain: 'domains/create',
      deleteDomain: 'domains/delete',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    reset() {
      this.onProjectChange();
    },
    onProjectChange() {
      this.projectFields = pick(this.project, ['subdomain']);
    },
    reloadProject() {
      const { short_id: id } = this.project;
      this.fetchProject({ id, skipCache: true });
    },
    createOrDeleteCustomDomains() {
      const domainsToCreate = this.newCustomDomains.filter(({ domain }) => !this.domainsToDelete.has(domain));
      const domainsToDelete = this.domains.filter(({ domain }) => this.domainsToDelete.has(domain));

      return Promise.all([...domainsToCreate.map(this.createCustomDomain), ...domainsToDelete.map(this.deleteDomain)]);
    },
    async markCustomDomainRemoved(customDomain) {
      // changed functionality due to Or's request. TODO: check what the UI/UX should be like
      try {
        const { short_id: id } = this.project;

        this.domainsToDelete.add(customDomain.domain);
        this.deleteCount++;

        this.$trackEvent('project-settings-meta.remove-domain-button.click');

        await this.deleteDomain(customDomain);
        await this.fetchCustomDomains({ parent: 'projects', id, skipCache: true });

        toastSuccess('Domain deleted successfully!');

        this.$trackEvent('project-settings-meta.remove-domain.success');
      } catch (err) {
        toastError('Failed deleting this custom domain :(');
        this.$trackEvent('project-settings-meta.remove-domain.failure');
      } finally {
        this.markCustomDomainNotRemoved(customDomain);
      }
    },
    markCustomDomainNotRemoved({ domain }) {
      this.domainsToDelete.delete(domain);
      this.deleteCount--;
    },
    async setPasswordProtected(is_password_protected) {
      const { short_id: id } = this.project;
      const payload = { is_password_protected };

      this.isWorking = true;
      await this.updateProject({ id, payload });
      await this.reloadProject();
      this.isWorking = false;
    },
    async save() {
      const { short_id: id } = this.project;
      try {
        this.isWorking = true;
        this.subdomainError = '';

        this.$trackEvent('project-settings-meta.save-button.click');

        // update project fields
        if (this.projectChanged) {
          let payload = keys(this.projectFields).reduce((acc, key) => {
            if (this.projectFields[key] !== this.project[key]) {
              return { ...acc, [key]: this.projectFields[key] || null };
            }
            return acc;
          }, {});

          payload.subdomain = normalizeDomain(payload.subdomain);

          await this.updateProject({ id, payload });
          await this.reloadProject();
        }

        toastSuccess('Project updated successfully');
        this.$trackEvent('project-settings-meta.save.success');
      } catch (err) {
        this.$trackEvent('project-settings-meta.save.failure', { message: err.message });
        toastError('Failed updating project');
      } finally {
        this.isWorking = false;
      }
    },
    onUpgradeClick() {
      this.$trackEvent('project-settings-meta.paywall-upgrade-button.click');
      this.$router.push({ name: 'pricing' });
    },
    onCustomDomainInputClick() {
      this.showDnsComment = true;
      if (this.isFree) {
        this.$trackEvent('project-settings-meta.add-domain-input.free-user-click', {
          team: this.team.id,
          user: this.user.id
        });
      }
      if (this.isPro) {
        this.$trackEvent('project-settings-meta.add-domain-input.pro-user-click', {
          team: this.team.id,
          user: this.user.id
        });
      }
    }
  },
  watch: {
    project() {
      this.onProjectChange();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_meta.scss';
</style>

<template>
  <div class="progress-bar" :style="backgroundStyle">
    <div :style="barStyle" />
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (p) => p >= 0 && p <= 1
    },
    barColor: {
      type: String,
      default: 'var(--red)'
    },
    backgroundColor: {
      type: String,
      default: 'var(--light-container-background)'
    },
    height: {
      type: Number,
      default: 6
    }
  },
  computed: {
    backgroundStyle() {
      const { height, backgroundColor } = this;
      return { height: `${height}px`, backgroundColor };
    },
    barStyle() {
      const { progress, height, barColor: backgroundColor } = this;
      const width = Math.min(progress * 100, 100);
      return { width: `${width}%`, height: `${height}px`, backgroundColor };
    }
  }
};
</script>

<style lang="scss" scoped>
$border-radius: 10px;
.progress-bar {
  width: 100%;
  max-width: 250px;
  border-radius: $border-radius;
  > div {
    border-radius: $border-radius;
    transition: width 0.2s ease;
    min-width: 10px;
  }
}
</style>

<template>
  <div class="iframe-wrapper">
    <div v-if="isLoading" class="loader">
      <LoadingScreen
        :type="loaderType"
        :text="currentStatus"
        :max="currentMax"
        :delay="false"
        :timeConstant="3000"
        theme="dark"
      />
    </div>
    <iframe
      v-else
      data-cy="main-iframe"
      data-hj-allow-iframe=""
      ref="iframeRef"
      id="iframeId"
      name="iframeName"
      class="iframe"
      frameborder="0"
      :src="playgroundURL"
    ></iframe>
  </div>
</template>

<script>
import { defineComponent, watch, computed, ref, onBeforeUnmount, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router/composables';
import { trackEvent } from '@/services/tracking';
import { useGenerateCode, useLoading, useCodePreferences, useProject, useScreens } from '@/hooks';
import { EventBus } from '@/services/bus';
import LoadingScreen from '@/components/Loading/LoadingScreen.vue';
import { useStore } from '@/store';

export default defineComponent({
  components: {
    LoadingScreen
  },
  setup() {
    const route = useRoute();
    const { getters } = useStore();
    const { isProjectAndReleaseReady } = useLoading();
    const { generatePlaygroundURLAsync } = useGenerateCode();
    const { screens } = useScreens();
    const { isPlaygroundOmniView, codegenFramework, codegenReactLanguage } = useCodePreferences();
    const { currentProject } = useProject();

    const playgroundURL = ref('');
    const max = ref(0);
    const status = ref('init');
    const isProcessingSandbox = ref(false);
    const similarScreensIdsTemp = computed(() => getters['components/similarScreensIdsTemp']);
    const screenIdsToScreenSlugsMap = computed(() => {
      const screenIdsToSlugs = {};
      const screenIdsToScreenData = {};
      for (const screen of screens.value) {
        screenIdsToScreenData[screen.id] = { slug: screen.slug, width: screen.width };
        screenIdsToSlugs[screen.id] = screen.slug;
      }

      similarScreensIdsTemp.value.forEach((screenGroup) => {
        const screenIdsInGroup = screenGroup.map((screen) => screen.id);
        const smallestWidthScreenIdInGroup = screenIdsInGroup.reduce((smallestWidthScreenId, screenId) => {
          if (screenIdsToScreenData[screenId].width < screenIdsToScreenData[smallestWidthScreenId].width) {
            return screenId;
          }
          return smallestWidthScreenId;
        }, screenIdsInGroup[0]);
        for (const screenId of screenIdsInGroup) {
          screenIdsToSlugs[screenId] = screenIdsToScreenData[smallestWidthScreenIdInGroup].slug;
        }
      });
      return screenIdsToSlugs;
    });

    const screenSlugsToScreenIdsMap = computed(() => {
      const screenSlugsToIds = {};
      for (const screen of screens.value) {
        screenSlugsToIds[screen.slug] = screen.id;
      }
      return screenSlugsToIds;
    });

    const isFullProjectPlaygroundActiveExperiment = computed(
      () => getters['experiments/isFullProjectPlaygroundActive']
    );

    const isLoading = computed(() => {
      return !isProjectAndReleaseReady.value || isProcessingSandbox.value;
    });

    const isSyncingRelease = computed(() => {
      return currentProject.value && currentProject.value.is_syncing;
    });

    const currentMax = computed(() => {
      return isSyncingRelease.value ? 30 : max.value;
    });
    const currentStatus = computed(() => {
      return isSyncingRelease.value ? 'Creating release' : status.value;
    });

    const loaderType = computed(() => {
      if (['init', 'preparing session'].includes(status.value)) return 'default';
      return 'syncing';
    });

    const screenSlug = computed(() => route.params.screenSlug);

    const generate = async () => {
      if (isProcessingSandbox.value) return;
      if (!isPlaygroundOmniView.value) {
        return;
      }
      try {
        isProcessingSandbox.value = true;
        const url = await generatePlaygroundURLAsync({
          onProgress: (data) => {
            status.value = data?.status ?? '';
            max.value = Math.max(data?.max ?? 0, max.value);
          }
        });
        const currentScreenId = screenSlugsToScreenIdsMap.value[screenSlug.value];
        const actualScreenSlug = screenIdsToScreenSlugsMap.value[currentScreenId];
        const playgroundURLWithQueryParam = new URL(url);
        playgroundURLWithQueryParam.searchParams.set('screen', actualScreenSlug);
        playgroundURL.value = playgroundURLWithQueryParam.toString();

        trackCodeSandboxSuccess({ playgroundURL: playgroundURL.value });
      } catch (error) {
        console.error(error);
        trackCodeSandboxFailure();
      } finally {
        isProcessingSandbox.value = false;
        max.value = 0;
        status.value = 'init';
      }
    };

    const trackCodeSandboxSuccess = ({ playgroundURL }) => {
      const trackEventParams = {
        playgroundURL: playgroundURL,
        framework: route.query.framework ?? codegenFramework.value,
        ...(codegenFramework.value === 'react' && { language: codegenReactLanguage.value })
      };
      trackEvent('inspect-code.success', trackEventParams);
    };

    const trackCodeSandboxFailure = () => {
      const trackEventParams = {
        framework: route.query.framework || codegenFramework.value,
        reason: 'playground',
        ...(codegenFramework.value === 'react' && { language: codegenReactLanguage.value })
      };

      trackEvent('inspect-code.failure', trackEventParams);
    };

    watch(
      isProjectAndReleaseReady,
      (r) => {
        if (!r) return;
        generate();
      },
      {
        immediate: true
      }
    );

    watch(screenSlug, (to, from) => {
      const screenId = screenSlugsToScreenIdsMap.value[to];
      const actualScreenSlug = screenIdsToScreenSlugsMap.value[screenId];
      if (!to || to === from) return;
      if (!isFullProjectPlaygroundActiveExperiment.value) {
        generate();
      } else {
        const messagePayload = {
          type: 'screenChange',
          data: {
            screenSlug: actualScreenSlug
          }
        };
        const iframe = document.getElementById('iframeId');
        if (!iframe) return;
        iframe.contentWindow.postMessage(messagePayload, 'https://playground.animaapp.com');
      }
    });

    onBeforeMount(() => {
      EventBus.$on('CODE_PREFERENCES_UPDATED', generate);
      EventBus.$on('ON_SCREEN_CHANGE', generate);
    });
    onBeforeUnmount(() => {
      EventBus.$off('CODE_PREFERENCES_UPDATED', generate);
      EventBus.$off('ON_SCREEN_CHANGE', generate);
    });

    return {
      playgroundURL,
      isLoading,
      loaderType,
      currentMax,
      currentStatus
    };
  }
});
</script>

<style lang="scss" scoped>
@import '../../components/OmniView/MainFrame.scss';

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe {
  width: 100%;
  height: 100%;
}
</style>

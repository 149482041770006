<template>
  <div class="component-card-container">
    <!-- <div
      class="component-card"
      @click="$emit('click')"
      :style="cardStyle"
      @mouseover="hoverThumb = true"
      @mouseout="hoverThumb = false"
    >
     
    </div> -->
    <div
      @mouseover="hoverThumb = true"
      @mouseout="hoverThumb = false"
      @click="$emit('click')"
      class="component-card-background"
    >
      <div :class="actionsClasses">
        <PopoverMenu :items="popoverActions" position="right">
          <div slot="reference">
            <svg-icon fill="currentColor" style="color: #fff" name="three-dots-horizontal" :size="30" />
          </div>
        </PopoverMenu>
      </div>
      <WebComponentThumb
        v-if="cardWidth && cardHeight"
        :maxWidth="cardWidth"
        :maxHeight="cardHeight"
        :webComponent="component"
      />
    </div>

    <div class="component-name">
      {{ component.name }}
    </div>
  </div>
</template>

<script>
import { ResizeObserver } from '@juggle/resize-observer';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import WebComponentThumb from '../OmniView/Code/WebComponentThumb.vue';

export default {
  components: {
    WebComponentThumb,
    PopoverMenu
  },
  data() {
    return { hoverThumb: false, cardWidth: 0, cardHeight: 0 };
  },

  props: {
    component: {
      type: Object,
      required: true
    },
    actions: {
      type: Array
    }
  },

  mounted() {
    const cardWidthObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.cardWidth = entry.contentRect.width;
        this.cardHeight = entry.contentRect.height - 60; // component name height;
      });
    });

    cardWidthObserver.observe(this.$el);
  },
  computed: {
    thumbnail() {
      const { screenshot_url, thumb_position } = this.component;

      let url = thumb_position['url'] ? thumb_position['url'] : screenshot_url;

      return {
        url: url.replace('https://anima-uploads.s3.amazonaws.com', 'https://image-cdn.animaapp.com'),
        thumb_position,
        is_component: !!thumb_position['url']
      };
    },
    cardStyle() {
      const {
        thumbnail: { url, is_component, thumb_position }
      } = this;

      const { x, y } = thumb_position;

      const backgroundColor = 'var(--light-container-background)';
      const backgroundImage = `url("${url}")`;

      return {
        backgroundImage,
        backgroundColor,
        ...(is_component && { backgroundPosition: ` -${x}px -${y}px` }),
        ...(!is_component && { backgroundSize: this.component.width > this.cardWidth ? 'contain' : null })
      };
    },
    actionsClasses() {
      const { hoverThumb } = this;
      return { actions: true, hide: !hoverThumb };
    },
    popoverActions() {
      const { component, actions } = this;
      return actions.map((action) => ({
        ...action,
        onClick: () => action.onClick(component)
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.component-card-container {
  min-height: 220px;
  max-height: 284px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  border-radius: 10px;
  overflow: hidden;
}
.component-card-background {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.component-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 100%;
  // background-color: var(--light-container-background);
  border-radius: 10px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
}
.component-name {
  // margin-top: 20px;
  flex-shrink: 0;
  padding: 20px 16px;
}
.actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.95);
  color: #ffffff;
  transition: all 0.2s ease-in;
  padding: 16px;
  cursor: pointer;
  z-index: 20;
  &.hide {
    opacity: 0;
    z-index: -1;
  }
  .actions-text {
    margin: 20px 0;
  }
  .popover-menu-wrapper {
    width: 100%;
  }
}
</style>

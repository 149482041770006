<template>
  <transition name="slide-in-out-right">
    <div
      v-if="show"
      class="intercom-bubble"
      :style="intercomBubbleStyle"
      @mouseover.self="onMouseOver"
      @mouseleave.self="onMouseLeave"
      data-cy="intercom-bubble"
    >
      <div class="avatar">
        <img :src="contactAvatarSrc" />
      </div>
      <div class="content" @click="onClick" data-cy="intercom-bubble-content">
        <div class="contact-details">
          <b>{{ contactName }}</b> from Anima
        </div>
        <div class="message" v-html="messageHtml" />
      </div>
      <div class="close-button" @click="hideBubble">
        <svg-icon name="close" :size="20" />
      </div>
    </div>
  </transition>
</template>

<script>
import { get } from 'lodash-es';
import { EventBus } from '@/services/bus';

export default {
  data() {
    const contactPresets = {
      support: {
        name: 'Shira',
        avatarSrc: require('../../assets/images/support-headshot.jpg')
      }
    };
    return {
      contactPresets,
      message: '',
      contact: 'support',
      show: false,
      click: null,
      hideTimeout: 0,
      hideTimeoutId: null
    };
  },
  mounted() {
    EventBus.$on('show-intercom-bubble', this.showBubble);
    EventBus.$on('hide-intercom-bubble', this.hideBubble);
  },
  destroyed() {
    EventBus.$off('show-intercom-bubble', this.showBubble);
    EventBus.$off('hide-intercom-bubble', this.hideBubble);
  },
  computed: {
    contactName() {
      return get(this.contactPresets, [this.contact, 'name']);
    },
    contactAvatarSrc() {
      return get(this.contactPresets, [this.contact, 'avatarSrc']);
    },
    messageHtml() {
      return this.message.replaceAll('\n', '<br />');
    },
    eventProps() {
      return { page: this.$route.name };
    }
  },
  methods: {
    showBubble({ props } = {}) {
      if (!props) return;
      this.contact = props.contact || 'support';
      this.message = props.message;
      this.click = props.onClick;
      this.hideTimeout = props.hideTimeout || 0;
      if (/*this.$intercom.ready && */ this.isDesktop) {
        this.show = true;
        this.$trackEvent('webapp.intercom-bubble.show', { message: this.message, ...this.eventProps });
      } else {
        this.$trackEvent('webapp.intercom-bubble.show-attempt-failure', {
          message: this.message,
          intercomReady: this.$intercom.ready,
          isDesktop: this.isDesktop,
          ...this.eventProps
        });
      }
    },
    onMouseOver() {
      clearTimeout(this.hideTimeoutId);
      this.$trackEvent('webapp.intercom-bubble.hover');
    },
    onMouseLeave() {
      this.hideTimeoutId = setTimeout(this.hideBubble, this.hideTimeout);
    },
    hideBubble() {
      if (this.show) {
        this.show = false;
        this.$trackEvent('webapp.intercom-bubble.hide', this.eventProps);
      }
      this.click = null;
    },
    onClick() {
      if (this.click) {
        this.click();
      } else {
        this.openIntercom();
      }
      this.hideBubble();
      this.$trackEvent('webapp.intercom-bubble.click', this.eventProps);
    },
    openIntercom() {
      this.$intercom.showNewMessage();
    }
  }
};
</script>

<style lang="scss" scoped>
.intercom-bubble {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  right: 10px;
  margin: 20px;
}
.avatar {
  margin-right: 14px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: var(--shadow);
  background-color: #ffffff;
  color: var(--primary-text);
  font-size: 14px;
  padding: 10px 20px;
  min-width: 200px;
  max-width: 300px;
  cursor: pointer;
  .contact-details {
    color: var(--secondary-text);
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}
</style>

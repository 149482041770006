import api from '@/api';
import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'teamInvitation',
  modelName: 'team_invitations',
  crud: true,
  endpoint: {
    default: '/v2/team_invitations'
  },
  actions: {
    acceptInvite: (_, { team_slug, invitation_code }) =>
      api.post('/rpc/accept-team-invitation', { team_slug, invitation_code })
  }
});

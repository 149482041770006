<template>
  <Modal @close="$emit('close')" variant="top" v-bind="$attrs" closeButton>
    <div class="prompt-container">
      <slot />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal';

export default {
  name: 'an-prompt',
  components: {
    Modal
  }
};
</script>

<style lang="scss" scoped>
.prompt-container {
  padding: 40px;
}
</style>

export const initHubSpot = () => {
  const scriptTag = document.createElement('script');
  scriptTag.src = '//js.hs-scripts.com/7205540.js';
  scriptTag.async = true;
  scriptTag.defer = true;
  scriptTag.id = 'hs-script-loader';
  document.body.appendChild(scriptTag);
};

export default initHubSpot;

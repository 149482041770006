<template>
  <svg-icon v-bind="props" />
</template>

<script>
export default {
  name: 'an-emoji',
  computed: {
    props() {
      return {
        size: 42,
        ...this.$attrs,
        name: `emoji/${this.$attrs.name}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  margin-bottom: -10px;
}
</style>

import api from '@/api';
import { EventBus, openModal, toastError } from '@/services/bus';
import { readCookie } from '@/utils/cookie';
import { SELECT_OVERRIDE_NODE, SEND_COMPONENT_MESSAGE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { isSameRoute, objectMap, updateArrayItemById, poll, downloadFile } from '@/utils/javascript';
import axios from 'axios';
import { get, has, isEqual } from 'lodash-es';
import StyleToObject from 'style-to-object';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getNodeMd5Array, findAndMatch } from './modelUtils';
import { analyzeCode, patchInstanceFromMaster, slugify, createZip } from './utils';
import TeamMixin from '@/mixins/TeamMixin';
import dayjs from 'dayjs';

import { uuid } from '@/utils/uuid';
import { getCdnUrl } from '@/utils/urls';
import { findNodeWorker } from './workerFunctions';
import promiseRetry from 'promise-retry';
import { getParameters } from 'codesandbox-import-utils/lib/api/define';
import { codegenCache } from '@/services/idb/codegenCache';
import errorHandler from '@/services/errorHandler';

const CODEGEN_BASE_URL = process.env.CODEGEN_BASE_URL || 'https://codegen.animaapp.com';

const codegenMixin = {
  mixins: [TeamMixin],
  data() {
    return {
      fontsMap: false,
      isFetchingFonts: false,
      firstLoad: true,
      slugifyLoading: false,
      generateController: { abort: () => {} }
    };
  },
  created() {
    EventBus.$on('generate-code', this.generateCode);
    EventBus.$on('generateCodeWithoutIframe', this.generateCodeWithoutIframe);
    EventBus.$on('add-component-to-library', this.addComponentToLibrary);
    EventBus.$on('open-component-in-library', this.openComponentInLibrary);
    EventBus.$on('pre-process', this.preProcessComponent);
    EventBus.$on('populate-component-frame', this.populateComponentFrame);
    EventBus.$on('reject-suggestion', this.rejectSuggestion);
    EventBus.$on('open-component-interface', this.openComponentInterface);
    EventBus.$on('update-component-preview', this.updateComponentPreview);
    EventBus.$on('select-node', this.selectNodeById);
    EventBus.$on('regenerate-previous-request', this.regeneratePreviousRequest);
    EventBus.$on('export-code', this.exportCode);
    EventBus.$on('export-component', this.exportComponent);
    EventBus.$on('export-current-screen', this.exportCurrentScreen);
    EventBus.$on('export-current-screen-to-codepen', this.exportCurrentScreenToCodepen);
    EventBus.$on('export-component-to-codesandbox', this.exportComponentToCodeSandbox);

    document.addEventListener('keydown', this.handleKeydown);
  },
  destroyed() {
    EventBus.$off('generate-code', this.generateCode);
    EventBus.$off('generateCodeWithoutIframe', this.generateCodeWithoutIframe);
    EventBus.$off('add-component-to-library', this.addComponentToLibrary);
    EventBus.$off('open-component-in-library', this.openComponentInLibrary);
    EventBus.$off('pre-process', this.preProcessComponent);
    EventBus.$off('populate-component-frame', this.populateComponentFrame);
    EventBus.$off('reject-suggestion', this.rejectSuggestion);
    EventBus.$off('open-component-interface', this.openComponentInterface);
    EventBus.$off('update-component-preview', this.updateComponentPreview);
    EventBus.$off('select-node', this.selectNodeById);
    EventBus.$off('regenerate-previous-request', this.regeneratePreviousRequest);
    EventBus.$off('export-code', this.exportCode);
    EventBus.$off('export-component', this.exportComponent);
    EventBus.$off('export-current-screen', this.exportCurrentScreen);
    EventBus.$off('export-current-screen-to-codepen', this.exportCurrentScreenToCodepen);
    EventBus.$off('export-component-to-codesandbox', this.exportComponentToCodeSandbox);

    document.removeEventListener('keydown', this.handleKeydown);
  },
  computed: {
    ...mapState('omniview', { loading: 'loading' }),
    ...mapState('projects', { assetsRegistry: 'assetsRegistry' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('omniview', { isWaitingForOverrides: 'isWaitingForOverrides' }),
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('projects', { registryUrl: 'registryUrl' }),
    ...mapState('componentsMetadata', { metadatas: 'items' }),
    ...mapState('webComponents', { DbWebComponents: 'items' }),

    ...mapGetters({
      playgroundCode: 'omniview/playgroundCode',
      isGeneratingPlaygroundCode: 'omniview/isGeneratingPlaygroundCode',
      activeMode: 'omniview/activeMode',
      nodes: 'omniview/nodes',
      currentNode: 'omniview/currentNode',
      codegenLang: 'codePreferences/codegenLang',
      codegenStylesheetLang: 'codePreferences/codeStyling',
      lastGeneratedId: 'omniview/lastGeneratedId',
      modes: 'omniview/modes',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      queue: 'omniview/queue',
      codegenReactSyntax: 'codePreferences/codegenReactSyntax',
      codegenReactLanguage: 'codePreferences/codegenReactLanguage',
      isGeneratingCode: 'omniview/isGeneratingCode',
      isExportingCodeComponent: 'omniview/isExportingCodeComponent',
      currentMasterSlug: 'omniview/currentMasterSlug',
      nodesWithOverrides: 'omniview/nodesWithOverrides',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      codegenLengthUnit: 'codePreferences/codegenLengthUnit',
      codegenAutoAnimateMode: 'codePreferences/codegenAutoAnimateMode',
      captureType: 'omniview/captureType',
      isGeneratingCapture: 'omniview/isGeneratingCapture',
      suggestedComponents: 'webComponents/suggestedComponents',
      getAllWebComponentsOverrides: 'webComponents/getAllWebComponentsOverrides',
      currentWebComponent: 'webComponents/currentWebComponent',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      isWebComponentsLoading: 'webComponents/isWebComponentsLoading',
      webInstancesMap: 'webComponents/webInstancesMap',
      isSuggestion: 'webComponents/isSuggestion',
      isSuggestionById: 'webComponents/isSuggestionById',
      currentWebComponentNode: 'webComponents/currentWebComponentNode',
      isComponentOrSuggestionById: 'webComponents/isComponentOrSuggestionById',
      currentStyleguide: 'styleguide/currentStyleguide',
      codegenReactStyle: 'codePreferences/codegenReactStyle',
      slugsMap: 'omniview/slugsMap',
      styleType: 'codePreferences/codeStyling',
      hasPixelEnabled: 'users/hasPixelEnabled',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      codegenVueStyle: 'codePreferences/codegenVueStyle',
      userBetaFeatures: 'users/betaFeatures',
      isExportAllowed: 'omniview/isExportAllowed',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    }),

    mappedRouteToMode() {
      let { mode } = this.$route.query;
      let m;
      switch (mode) {
        case 'play':
          m = this.modes[0];
          break;
        case 'comments':
          m = this.mode[1];
          break;
        case 'code':
          m = this.modes[2];
          break;
        default:
          m = this.modes[0];
      }
      return m;
    },

    isPreSync() {
      return (
        this.$route.name == 'syncPreview' || this.$route.name == 'releaseOmniview' || this.$route.name == 'syncWebsite'
      );
    },
    isReleaseOmni() {
      return this.$route.name == 'releaseOmniview';
    },

    getCodegenHeaders() {
      return {
        'Content-Type': 'application/json; charset=utf-8',
        'plugin-name': 'OmniView',
        'user-id': this.currentUser.id,
        'user-email': this.currentUser.email,
        'project-id': this.currentProject.id,
        'release-id': this.currentRelease.id,
        'origin-url': window.location.href
      };
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'omniview/setLoading',
      setPlaygroundCode: 'omniview/setPlaygroundCode',
      setIsGeneratingPlaygroundCode: 'omniview/setIsGeneratingPlaygroundCode',
      setIsExportingPlaygroundCode: 'omniview/setIsExportingPlaygroundCode',
      resetPlaygroundCode: 'omniview/resetPlaygroundCode',
      setComponentViewData: 'omniview/setComponentViewData',
      setCurrentWebComponent: 'webComponents/setCurrentWebComponent',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      setCurrentNode: 'omniview/setCurrentNode',
      setLastGeneratedId: 'omniview/setLastGeneratedId',
      setCurrentNodePath: 'omniview/setCurrentNodePath',
      setBreakpoints: 'omniview/setBreakpoints',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setIsFullScreen: 'omniview/setIsFullScreen',
      setCurrentNodeHTML: 'omniview/setCurrentNodeHTML',
      setCurrentNodeJSX: 'omniview/setCurrentNodeJSX',
      setCurrentNodeCSS: 'omniview/setCurrentNodeCSS',
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      setIsExportingCodeComponent: 'omniview/setIsExportingCodeComponent',
      selectScreen: 'components/setCurrentItem',
      setCurrentComponentData: 'components/setCurrentComponentData',
      setProjectAssetsRegistry: 'projects/setProjectAssetsRegistry',
      setRegistryUrl: 'projects/setRegistryUrl',
      setIsWaitingForOverrides: 'omniview/setIsWaitingForOverrides',
      setIsExportAllowed: 'omniview/setIsExportAllowed',
      setNodes: 'omniview/setNodes',
      setMetadata: 'componentsMetadata/setMetadata',
      setCommentsSubView: 'omniview/setCommentsSubView',
      setDomLoading: 'omniview/setDomLoading',
      setIframeLoading: 'omniview/setIframeLoading',
      setCurrentMasterSlug: 'omniview/setCurrentMasterSlug',
      setNodesWithOverrides: 'omniview/setNodesWithOverrides',
      setMultiSelectedNodes: 'omniview/setMultiSelectedNodes',
      setModelNodesMap: 'omniview/setModelNodesMap',
      setCurrentNodeMd5Map: 'omniview/setCurrentNodeMd5Map',
      setIsGeneratingCapture: 'omniview/setIsGeneratingCapture',
      setIsModelLoading: 'omniview/setIsModelLoading',
      setComponentViewSize: 'omniview/setComponentViewSize',
      setIsPreprocessing: 'webComponents/setIsPreprocessing',
      setIsPopulatingComponentFrame: 'webComponents/setIsPopulatingComponentFrame',
      setWebComponents: 'webComponents/setWebComponents',
      setIsWebComponentsLoading: 'webComponents/setIsWebComponentsLoading',
      setSlugsMap: 'omniview/setSlugsMap',
      resetCodePanels: 'omniview/resetCodePanels',
      setCodeDownloadPrefs: 'omniview/setCodeDownloadPrefs'
    }),
    ...mapActions({
      fetchReleaseModel: 'releases/fetchReleaseModel',
      fetchWebComponents: 'codegen/fetchWebComponents',
      fetchSingleComponent: 'components/fetchOne',
      fetchModelFromUrl: 'releases/fetchModelFromUrl',
      fetchProjectComments: 'comments/fetchComments',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchMetadata: 'componentsMetadata/fetchMetadata',
      updateMetadata: 'componentsMetadata/update',
      createMetadata: 'componentsMetadata/create',
      fetchProject: 'projects/fetchOne',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchRelease: 'releases/fetchOne',
      fetchComponents: 'components/fetchAllOfParent',
      handleModeChange: 'omniview/handleModeChange',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      cleanup: 'omniview/cleanup',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      getNodesWithOverridesData: 'omniview/getNodesWithOverridesData',
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      fetchDBWebComponents: 'webComponents/fetchDBWebComponents',
      updateDBWebComponent: 'webComponents/update',
      resetSelection: 'omniview/resetSelection',
      nextOnboardingStage: 'userOnboardings/nextStage',
      trackExportedCodeSuccess: 'tracking/trackExportedCodeSuccess',
      trackExportedCodeFailure: 'tracking/trackExportedCodeFailure',
      fetchCodePackageById: 'codePackages/fetchOne',
      fetchCodePackages: 'codePackages/fetchAllOfParent'
    }),

    // ------  MODEL UTILS ------

    getNodeMd5Array,
    findAndMatch,

    // ------

    selectNodeById(data) {
      EventBus.$emit(SEND_MESSAGE, {
        action: SELECT_OVERRIDE_NODE,
        data
      });
    },

    async slugifyScreensNames(text) {
      if (this.slugifyLoading) return;
      try {
        if (text.every((x) => has(this.slugsMap, x))) return;
        let map = {};
        this.slugifyLoading = true;
        const { data } = await axios.post('rpc/slugify', {
          text
        });

        let slugs = data.result;
        for (let i = 0; i < text.length; i++) {
          map[text[i]] = slugs[i];
        }
        this.setSlugsMap(map);
      } catch (e) {
        this.$trackEvent('omniview.slugify.failed');
      } finally {
        this.slugifyLoading = false;
      }
    },

    async populateComponentFrame({ iframeName = 'componentIframe', nodeId = null } = {}) {
      try {
        const iframe = window.frames[iframeName];
        if (!iframe) return;

        const docFrame = iframe.document;

        const currentNodeId = iframe.frameElement.getAttribute('data-id');

        let { instance } = this.currentWebComponent;
        const { layer } = this.$route.query;

        let _nodeId = layer || this.currentNode.id;

        if (!instance && nodeId) {
          instance = { model_id: nodeId };
        }
        if (!instance && !nodeId) {
          instance = { model_id: _nodeId };
        }

        if (nodeId) {
          _nodeId = nodeId;
        }

        if (currentNodeId && currentNodeId === '_nodeId') return;

        this.setIsPopulatingComponentFrame({ iframeName, flag: true });
        iframe.frameElement.setAttribute('data-id', _nodeId);

        docFrame.open();
        // docFrame.write('');
        const { subModel, modelNode } = await this.getNodeSubModel({ nodeId: _nodeId });

        let { width = 0, height = 0 } = modelNode;

        let padding = 2;

        if (iframeName == 'componentIframeInterface') {
          height = height + 20;
          padding = 0;
        }

        if (iframeName == 'previewIframe') {
          padding = 0;
        }
        this.setComponentViewSize({ iframeName, data: { width: width + padding, height: height + padding } });

        const [model_overrides, md5_map] = await Promise.all([this.getModelOverrides(), this.getModelMd5Map()]);
        const settings = await this.getCodegenSettings({
          framework: 'html',
          fontsMap: this.fontsMap || {},
          md5Map: md5_map,
          overrides: model_overrides,
          presetSettings: 'high_fidelity'
        });

        const res = await this.makeCodegenRequest({
          mode: 'hosted',
          model: subModel,
          settings: {
            ...settings,
            preset_settings: 'high_fidelity'
          }
        });

        const slug = res['homepage_slug'];
        const html = res.files[`${slug}.html`];
        const doc = new DOMParser().parseFromString(html, 'text/html');

        // remove scripts
        doc.querySelector('#anima-hotspots-script')?.remove();
        doc.querySelector('#anima-overrides-script')?.remove();
        doc.querySelector('script[src="https://animaapp.s3.amazonaws.com/js/timeline.js"]')?.remove();
        doc.querySelector('script[src="https://animaapp.s3.amazonaws.com/static/restart-btn.min.js"]')?.remove();
        doc.querySelector('script[src="launchpad-js/launchpad-banner.js"]')?.remove();

        if (iframeName != 'previewIframe') {
          const animaScripts = doc.createElement('script');
          animaScripts.setAttribute('src', 'https://animaapp.s3.amazonaws.com/static/anima.min.js');
          animaScripts.setAttribute('defer', '');
          doc.head.appendChild(animaScripts);
        } else {
          const images = doc.querySelectorAll('[anima-src]');

          images.forEach((image) => {
            let animaSrc = image.getAttribute('anima-src');
            if (animaSrc) {
              image.setAttribute('src', animaSrc);
              image.removeAttribute('anima-src');
            }
          });
        }

        let iframeBodyStyle = {};
        if (iframeName === 'componentIframeInterface') {
          const { width: componentWidth = 1, height: componentHeight = 1 } = modelNode || {};
          const [iframeWidth, iframeHeight] = [550, 375];
          const widthRatio = iframeWidth / (componentWidth + 20);
          const heightRatio = iframeHeight / (componentHeight + 20);

          const zoom = Math.min(widthRatio, heightRatio, 1);
          iframeBodyStyle = { zoom, '-moz-transform': `scale(${zoom})` };
        }
        if (iframeName === 'previewIframe') {
          const { width: componentWidth = 1, height: componentHeight = 1 } = modelNode || {};
          const [iframeWidth, iframeHeight] = [340, 450];
          const padding = componentWidth * 0.1;
          const widthRatio = iframeWidth / (componentWidth + padding);
          const heightRatio = iframeHeight / (componentHeight + 20);

          const zoom = Math.min(widthRatio, heightRatio, 1);
          iframeBodyStyle = { zoom, '-moz-transform': `scale(${zoom})` };
          iframeBodyStyle['overflow'] = 'hidden';
          iframeBodyStyle['pointer-events'] = 'none';

          this.setComponentViewSize({
            iframeName,
            data: { width: componentWidth * zoom, height: componentHeight * zoom }
          });
        }

        let body = doc.querySelector('body');
        if (body) {
          const documentBg = body.style.backgroundColor;
          const backgroundColor = iframeName === 'previewIframe' ? 'transparent' : documentBg ? documentBg : '#fff';
          Object.assign(body.style, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor,
            ...iframeBodyStyle
          });
        }

        let style = doc.createElement('style');
        let styleString = `
        [class*="intercom"]{
          display:none
        }
        `;

        iframeName === 'previewIframe' &&
          (styleString += `
          [data-id]{
            pointer-events:none;
          }
        `);

        style.innerHTML = styleString;
        doc.head.appendChild(style);

        let artboard = doc.querySelector('.artboard');
        if (artboard) {
          Object.assign(artboard.style, {
            minWidth: 'auto'
            // minHeight: 'auto'
          });
        }

        docFrame.write(doc.documentElement.innerHTML);

        docFrame.close();
      } catch (error) {
        errorHandler.captureException(error);
      } finally {
        this.setIsPopulatingComponentFrame({ iframeName, flag: false });
      }
    },

    async startMiniProcessing({ customSettings = {} } = {}) {
      const { screenSlug } = this.$route.params;
      const { layer } = this.$route.query;
      const { subModel } = await this.getNodeSubModel();

      const [model_overrides, md5_map] = await Promise.all([this.getModelOverrides(), this.getModelMd5Map()]);

      const settings = await this.getCodegenSettings({
        framework: this.codegenLang,
        fontsMap: this.fontsMap || {},
        md5Map: md5_map,
        overrides: model_overrides,
        customSettings,
        original_screen_slug: screenSlug,
        presetSettings: 'clean_code'
      });

      let propEnableMap = {};
      let preProcessDataCopy = JSON.parse(JSON.stringify(settings.pre_process_data));

      const { master: currentSelectedMaster } = this.getMasterAndInstanceByNodeId(layer);

      Object.keys(settings.pre_process_data)
        .filter((masterId) => !!settings['pre_process_data'][masterId]['props'])
        .forEach((masterId) => {
          const data = settings['pre_process_data'][masterId];

          const rProps = data.props['regular'];
          const nProps = data.props['nested'];

          const props = [...rProps, ...nProps];

          if (masterId == currentSelectedMaster['master_id']) {
            for (let i = 0; i < props.length; i++) {
              const prop = props[i];
              propEnableMap[prop['name']] = {
                master_id: masterId,
                is_enable: prop['is_enable']
              };
            }
          }

          preProcessDataCopy[masterId] = {
            ...preProcessDataCopy[masterId],
            props: {
              regular: rProps.map((p) => ({ ...p, is_enable: true })),
              nested: nProps.map((p) => ({ ...p, is_enable: true }))
            }
          };
        });

      settings['pre_process_data'] = preProcessDataCopy;
      settings['web_components_enable'] = true;
      let res;
      try {
        res = await this.makeCodegenRequest({
          mode: 'pre_process',
          model: subModel,
          settings
        });
      } catch (error) {
        errorHandler.captureException(error);
      }

      const { master_components } = res;

      const new_master_components = master_components.map((master) => {
        const rProps = master.props['regular'];
        const nProps = master.props['nested'];

        return {
          ...master,
          props: {
            regular: rProps.map((prop) => {
              return {
                ...prop,
                ...(propEnableMap[prop['name']] && { is_enable: propEnableMap[prop['name']]['is_enable'] })
              };
            }),
            nested: nProps.map((prop) => ({
              ...prop,
              ...(propEnableMap[prop['name']] && { is_enable: propEnableMap[prop['name']]['is_enable'] })
            }))
          }
        };
      });

      res['master_components'] = new_master_components;
      this.setTeamSlugForAllInstances(res['components_instances']);
      this.setTeamSlugForAllInstances(res['master_components']);
      return res;
    },
    setTeamSlugForAllInstances(instances) {
      const { screenSlug } = this.$route.params;
      for (let instance of instances) {
        instance.screen_slug = screenSlug;
      }
    },
    async checkForProjectProcessing() {
      const { projectId } = this.$route.params;
      const { is_pre_process_running } = await this.fetchProject({ id: projectId, skipCache: true });
      return is_pre_process_running;
    },

    async preProcessComponent({ forcePreProcess = false, customSettings = {} } = {}) {
      try {
        const { projectId } = this.$route.params;
        const { layer } = this.$route.query;
        const { id: model_id } = this.currentNode;
        const nodeId = layer || model_id;

        if (!nodeId) return;

        this.setIsPreprocessing(true);

        let is_pre_process_running;

        if (forcePreProcess) {
          is_pre_process_running = true;
        } else {
          is_pre_process_running = await this.checkForProjectProcessing();
        }

        let preProcessData = { components_instances: [], master_components: [] };

        const makeCodegenPreprocessCall = async () => {
          preProcessData = await this.startMiniProcessing({
            customSettings
          });
          const { components_instances, master_components } = preProcessData;

          const instance = components_instances.find((c) => c.model_id == nodeId);

          if (!instance) {
            throw new Error('web component instance not found');
          }

          let master = master_components.find((c) => c.master_id == instance.master_id);

          if (!master) {
            throw new Error('web component master not found');
          }
          master = { ...get(this.currentWebComponent, 'master', {}), ...master, subsIds: [] };

          this.setCurrentWebComponent({
            master,
            instance: patchInstanceFromMaster(instance, master)
          });
        };

        if (!is_pre_process_running) {
          await this.fetchDBWebComponents({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: {
              skip_cache: true,
              get_all: true
            }
          });

          const { master: foundMaster, instance: foundInstance } = this.getMasterAndInstanceByNodeId(nodeId);
          if (!foundMaster) {
            await makeCodegenPreprocessCall();
          } else {
            const { instances } = foundMaster;
            preProcessData = {
              components_instances: instances,
              master_components: [foundMaster]
            };

            let master = {
              ...get(this.currentWebComponent, 'master', {}),
              ...foundMaster,
              component_name: foundMaster.name
            };

            this.setCurrentWebComponent({
              master,
              instance: patchInstanceFromMaster(foundInstance, master)
            });
          }
        } else {
          await makeCodegenPreprocessCall();
        }

        this.setIsPreprocessing(false);

        return preProcessData;
      } catch (error) {
        this.setIsPreprocessing(false);
        errorHandler.captureException(error);
      }
    },

    async rejectSuggestion({ onDoneEvent = false }) {
      // const { projectId } = this.$route.params;
      const { id } = this.currentNode;
      const { layer } = this.$route.query;
      const nodeId = id || layer;

      const { master } = this.getMasterAndInstanceByNodeId(nodeId);

      const newItems = updateArrayItemById(this.DbWebComponents, master.id, {
        is_live: false,
        is_suggestion: false
      });

      this.setWebComponents(newItems);

      await promiseRetry(
        (doRetry) => {
          return this.updateDBWebComponent({
            id: master.id,
            payload: {
              is_live: false,
              is_suggestion: false
            }
          }).catch(doRetry);
        },
        { retries: 3 }
      );

      if (onDoneEvent) {
        EventBus.$emit(onDoneEvent);
      }
    },

    updateComponentPreview({ id = null, iframeName = 'main' } = {}) {
      let nodeId = id;
      if (!nodeId) {
        nodeId = this.$route.query.layer;
      }

      let msg = iframeName == 'main' ? SEND_MESSAGE : SEND_COMPONENT_MESSAGE;
      EventBus.$emit(msg, {
        action: 'get-preview',
        iframeName,
        data: {
          nodeId,
          isLocal: true
        }
      });
    },

    async openComponentInterface() {
      this.$trackEvent('omniview.component-edit.click');
      openModal({
        name: 'component-interface',
        variant: 'center',
        opacity: 0.4,
        width: 1168,
        closeButton: true,
        props: { eventSource: 'omniview' }
        // onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
      });

      await this.$nextTick();
      this.populateComponentFrame({ iframeName: 'componentIframeInterface' });
    },

    CreateFakeWebComponentOrUpdate(id) {
      const { layer } = this.$route.query;
      const nodeId = id ? id : layer;

      const { master } = this.getMasterAndInstanceByNodeId(nodeId);

      let newItems = [];

      if (!master) {
        const master_id = uuid();
        newItems = [
          ...this.DbWebComponents,
          {
            id: uuid(),
            master_id,
            is_live: true,
            is_suggestion: false,
            screenshot_url: '',
            name: '',
            instances: [
              {
                master_id,
                is_primary: true,
                model_id: nodeId,
                props: { regular: [], nested: [] },
                screen_slug: '',
                usage_code: ''
              }
            ],
            subsIds: []
          }
        ];
      } else {
        newItems = updateArrayItemById(
          this.DbWebComponents,
          master.master_id,
          {
            is_live: true,
            is_suggestion: false
          },
          'master_id'
        );
      }
      this.setWebComponents(newItems);
    },
    async addComponentToLibrary({
      onDoneEvent = false,
      preProcessParams = {
        forcePreProcess: true,
        customSettings: {
          auto_detect_component: true,
          generate_hosted_html_for_primary_instances: 'true'
        }
      }
    } = {}) {
      const { projectId } = this.$route.params;
      this.setIsPreprocessing(true);
      this.updateComponentPreview();
      this.CreateFakeWebComponentOrUpdate();

      const pre_process_data = await this.preProcessComponent(preProcessParams);

      // send add to library request and refetch components
      if (!Object.keys(pre_process_data).length) {
        this.$sentry.captureMessage('addComponentToLibrary: pre_process_data is empty');
        return;
      }
      axios
        .post(`/project/${projectId}/web_component`, {
          pre_process_data
        })
        .finally(() => {
          if (onDoneEvent) {
            EventBus.$emit(onDoneEvent);
          }
        });
    },

    showSuggestionsPeriodically({ scope = 'project' } = {}) {
      const savedTime = localStorage.getItem(`${scope}_lastShownSuggestionTimestamp`);
      if (savedTime) {
        const hoursSince = dayjs(new Date(Date.now()).toISOString()).diff(
          dayjs(new Date(parseInt(savedTime)).toISOString()),
          'hour'
        );
        if (hoursSince >= 0) {
          this.checkSuggestedComponents({ scope });
        }
      } else {
        this.checkSuggestedComponents({ scope });
      }
    },

    async checkSuggestedComponents({ scope }) {
      await this.$waitFor(() => this.isWebComponentsLoading, false);

      const instancesIds = [];

      for (let i = 0; i < this.suggestedComponents.length; i++) {
        const wc = this.suggestedComponents[i];
        const wci = wc.instances || [];
        const primaryInstance = wci.find((i) => i.is_primary);
        if (primaryInstance) {
          instancesIds.push(primaryInstance.model_id);
        } else {
          instancesIds.push(wci[0].model_id);
        }
      }

      let matches = [];

      if (scope == 'nested') {
        const { modelNode } = await this.getNodeSubModel();
        matches = this.findAndMatch(modelNode, instancesIds);
      } else {
        matches = instancesIds;
      }

      if (matches.length > 0) {
        openModal({
          name: 'component-interface',
          variant: 'center',
          opacity: 0.4,
          width: 660,
          closeButton: true,
          props: { eventSource: 'omniview', isSuggestions: true, suggestedNodeIds: matches, suggestionsScope: scope }
        });
      }
    },

    async openComponentInLibrary({
      nodeId = null,
      preProcessParams = {},
      openComponentInterface = false,
      onDoneEvent = null
    } = {}) {
      return new Promise((resolve, reject) => {
        let model_id;
        const q = { ...this.$route.query };

        model_id = nodeId ? nodeId : q.layer;

        const { primaryInstance } = this.getMasterAndInstanceByNodeId(model_id);

        model_id = primaryInstance ? primaryInstance.model_id : model_id;

        let newRoute = { ...this.$route, query: { ...q, component: model_id, layer: model_id } };

        const done = async () => {
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [this.$route.query.layer]: true });
          this.populateComponentFrame({ nodeId: model_id });
          this.generateCode({ _nodeId: model_id });
          const data = await this.preProcessComponent(preProcessParams);
          // this.checkSuggestedComponents({ scope: 'nested' });
          if (onDoneEvent) {
            const { name, params } = onDoneEvent;
            EventBus.$emit(name, params);
          }
          openComponentInterface && this.openComponentInterface();

          resolve(data);
        };

        if (isSameRoute(newRoute, this.$route)) {
          done();
          return;
        }

        this.$router
          .push(newRoute)
          .then(() => {
            done();
          })
          .catch((e) => {
            reject(e);
            errorHandler.captureException(e);
          });
      });
    },

    getBaseCodegenSettings() {
      const { id: releaseId } = this.currentRelease;
      const { cdn_distribution_domain: cdnDomain } = this.currentProject;

      const codegenSettings = {
        base_dir_images: `https://${cdnDomain || 'anima-uploads.s3.amazonaws.com'}/releases/${releaseId}/img/`,
        web_components_enable: false,
        vendor_prefix: '',
        is_for_playground_editor: false,
        md5_to_url: {},
        model_overrides: {},
        fonts_map: {}
      };

      return codegenSettings;
    },
    async getCodegenSettings({
      overrides = {},
      md5Map = {},
      fontsMap = {},
      framework = 'html',
      isCodeSandbox = false,
      isCodePen = false,
      customSettings = {},
      presetSettings = 'clean_code',
      useModel = true
    } = {}) {
      let settings = this.getBaseCodegenSettings();

      let md5_to_url = {};
      Object.keys(md5Map).forEach((key) => {
        md5_to_url[key] = md5Map[key].url || '';
      });

      settings['md5_to_url'] = md5_to_url;
      settings['fonts_map'] = fontsMap;
      settings['model_overrides'] = overrides;
      settings['is_display_data_id'] = !isCodePen && !isCodeSandbox;
      settings['async_src'] = false;
      settings['engine'] = 'legacy';

      if (!isCodePen && presetSettings === 'clean_code' && this.hasPixelEnabled) {
        settings['include_pixel'] = true;
        settings['pixel_data'] = {
          user_id: this.currentUser.id,
          team_id: this.currentProject.team,
          project_id: this.currentProject.id
        };
      }

      // possible values: 'clean_code' | 'high_fidelity'
      // clean_code: for code displayed by the users.
      // high_fidelity: for prototypes in iframes
      settings['preset_settings'] = presetSettings;

      const { layer } = this.$route.query;
      if (useModel) {
        let _nodeId = layer || this.currentNode.id;
        let { modelNode } = await this.getNodeSubModel({ nodeId: _nodeId });
        settings['pre_process_data'] = this.getAllWebComponentsOverrides(modelNode);
      }

      settings['styleguide_data'] = {
        classes: get(this.currentStyleguide, 'classes', {}),
        tokens: get(this.currentStyleguide, 'tokens', {})
      };

      if (framework === 'react') {
        settings['language'] = this.codegenReactLanguage || 'javascript';
        settings['engine'] = 'athena';
        settings['athena_react_file_generation_mode'] = 'full_project';
      }

      settings = {
        ...settings,
        ...customSettings
      };

      // External Editor Settings
      settings['is_for_playground_editor'] = isCodePen;
      settings['use_url_from_md5'] = isCodeSandbox;

      switch (framework) {
        case 'html':
          settings = {
            ...settings,
            auto_flexbox_enabled: this.codegenHTMLLayout == 'flexbox',
            length_unit: this.codegenLengthUnit,
            auto_animate_mode: this.codegenAutoAnimateMode
          };
          break;

        case 'react':
          settings = {
            ...settings,
            auto_flexbox_enabled: true,
            length_unit: 'px',
            web_components_enable: true,
            web_framework: 'React',
            web_framework_settings: {
              component_type: this.codegenReactSyntax,
              styled_components: this.codegenReactStyle == 'styled',
              stylesheet_syntax: this.codegenStylesheetLang
            }
          };
          break;
        case 'vue':
          settings = {
            ...settings,
            auto_flexbox_enabled: true,
            length_unit: 'px',
            web_components_enable: true,
            web_framework: 'Vue',
            web_framework_settings: {
              stylesheet_syntax: this.codegenStylesheetLang
            }
          };
          break;
      }
      return settings;
    },

    async getNodeSubModel({ nodeId = null, screenNodeId = null, includeBreakpoints = false } = {}) {
      const { model } = await this.getModelAndScreen({ slim: false, waitForSlugifyCall: true });

      let _nodeId = nodeId;
      if (!_nodeId) {
        !screenNodeId && (screenNodeId = await this.getCurrentScreenNodeId());
        _nodeId = this.$route.query.layer || this.currentNode.id || screenNodeId;
      }

      let found =
        _nodeId == screenNodeId
          ? model.screens.find((s) => s['modelID'] == screenNodeId)
          : await this.$worker.run(findNodeWorker, [model, _nodeId]);

      let breakpointsArray = [];
      if (includeBreakpoints) {
        const similarScreenIds = model.similarScreenIds || [];
        const modelScreens = model.screens || [];

        for (let i = 0; i < similarScreenIds.length; i++) {
          const ids = similarScreenIds[i];
          if (ids.includes(screenNodeId)) {
            for (let j = 0; j < ids.length; j++) {
              let id = ids[j];
              if (id !== screenNodeId) {
                const screen = modelScreens.find((s) => s.modelID == id);
                if (screen) {
                  breakpointsArray.push(screen);
                }
              }
            }
          }
        }
      }

      if (!found) {
        throw new Error('Node not found');
      }

      const subModel = {
        ...model,
        screens: [found, ...breakpointsArray]
      };

      return { subModel, modelNode: found, model };
    },
    //get the node without the iframe
    async generateCodeWithoutIframe({ nodeId = '', fileId = '', figma_access_token = '' }) {
      const transaction = this.$sentry.startTransaction({ name: 'generate-code-for-chrome-extension' });

      this.setCurrentNode(nodeId);
      this.lastLoadNodeId = nodeId;
      try {
        const codegenSettings = await this.getCodegenSettings({
          framework: this.codegenLang,
          fontsMap: this.fontsMap || {},
          md5Map: {},
          overrides: {},
          isCodePen: false,
          isCodeSandbox: false,
          presetSettings: this.codegenHTMLLayout === 'absolute' ? 'high_fidelity' : 'clean_code',
          useModel: false
        });

        this.setIsGeneratingCode(true);
        const codegenResult = await this.makeCodegenRequestWithoutIframe(
          nodeId,
          fileId,
          figma_access_token,
          codegenSettings
        );
        if (nodeId !== this.lastLoadNodeId) {
          return;
        }
        // span.finish();

        this.populateCodePanels(codegenResult);
        this.setIsGeneratingCode(false);
      } catch (error) {
        errorHandler.captureException(error);
        this.setIsGeneratingCode(false);
        if (this.generateController && this.generateController.signal && this.generateController.signal.aborted) {
          this.setIsGeneratingCode(true);
        }
      } finally {
        this.setIsGeneratingPlaygroundCode(false);
        this.setIsExportingPlaygroundCode(false);
      }
      transaction.finish();
    },

    async getCurrentScreenNodeId() {
      const { currentScreen } = await this.getModelAndScreen();
      return currentScreen['modelID'];
    },

    initLoadingState(isExternalEditor, isExportingCode) {
      isExternalEditor
        ? isExportingCode
          ? this.setIsExportingPlaygroundCode(true)
          : this.setIsGeneratingPlaygroundCode(true)
        : this.setIsGeneratingCode(true);

      isExternalEditor ? this.resetPlaygroundCode() : this.resetCodePanels();
    },

    trackExternalEditorEvents({ framework, externalEditor, isFromExportModal }) {
      this.$trackEvent('omniview.code-mode.external-editor', {
        type: framework,
        editor: externalEditor,
        'style-type': this.styleType,
        framework,
        isFromExportModal
      });
      this.$gtm.trackEvent({
        event: 'external_edit',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: framework
      });
    },

    trackGetCleanSnippetEvent({ framework, elementType }) {
      let eventProps = {
        'style-type': this.styleType,
        framework,
        defaultFramework: 'html',
        panels: this.panelsState,
        element_type: elementType,
        html_tag: get(this.currentNode, 'tagName', null),
        classname: get(this.currentNode, 'classes', null)
      };

      if (framework === 'react') {
        eventProps['language'] = this.codegenReactLanguage;
      }

      this.$trackEvent('omni.code-mode.get-code', eventProps);
    },
    getCorrectNodeId(_nodeId) {
      const { layer } = this.$route.query;
      return _nodeId ? _nodeId : layer;
    },
    async getCodegenSettingsWithOverrides({
      modelNode,
      framework,
      presetSettings,
      externalEditorSettings = { isCodePen: false, isCodeSandbox: false }
    }) {
      const nodeId = modelNode.modelID;
      const [overrides, md5Map] = await Promise.all([this.getModelOverrides(), this.getModelMd5Map()]);
      this.setCurrentNodeMd5Map(md5Map);
      const isWebComponent = this.isComponentOrSuggestionById(nodeId);
      const codegenSettingsParams = {
        framework,
        fontsMap: this.fontsMap || {},
        md5Map,
        overrides,
        isWebComponent,
        presetSettings,
        ...externalEditorSettings
      };
      const codegenSettings = await this.getCodegenSettings(codegenSettingsParams);
      return codegenSettings;
    },
    async waitForProjectDataToLoad() {
      return this.$waitFor(() => !this.loading.project && !this.loading.release && !this.loading.assets, true);
    },
    abortPrevGenerationCall() {
      if (this.isGeneratingCode) {
        this.generateController.abort();
      }
    },
    async exportNode({ nodeId } = {}) {
      const screenNodeId = await this.getCurrentScreenNodeId();
      const framework = this.codegenLang;
      const externalEditorSettings = { isCodePen: false, isCodeSandbox: true };

      this.setIsGeneratingCode(true);
      this.setIsExportingCodeComponent(true);
      this.setIsGeneratingPlaygroundCode(true);
      this.abortPrevGenerationCall();
      await this.waitForProjectDataToLoad();

      let { modelNode, subModel } = await this.getNodeSubModel({
        nodeId,
        screenNodeId
      });
      const codegenSettings = await this.getCodegenSettingsWithOverrides({
        modelNode,
        framework,
        externalEditorSettings,
        presetSettings: 'clean_code'
      });
      const md5ToUrl = codegenSettings.md5_to_url;
      codegenSettings.base_dir_css = 'css/';
      codegenSettings.base_dir_images = 'img/';
      codegenSettings.md5_to_url = {};

      const codeGenerationParams = {
        model: subModel,
        settings: codegenSettings,
        mode: 'package',
        options: {
          onabort: () => {
            this.setIsGeneratingCode(true);
          },
          md5ToUrl
        }
      };

      const unSlugifiedName = `${this.currentProject.name} ${modelNode.viewName} ${framework}`;
      const zipName = slugify(unSlugifiedName) + '.zip';
      await this.exportZip(zipName, subModel.screens[0], codeGenerationParams);
      this.setLastGeneratedId(nodeId);
    },
    async exportCurrentScreen() {
      const nodeId = await this.getCurrentScreenNodeId();
      try {
        await this.exportNode({ nodeId });
        this.trackExportedCodeSuccess({
          action: 'exported_current_screen'
        });
      } catch (error) {
        this.trackExportedCodeFailure();
        errorHandler.captureException(error);
      } finally {
        this.setIsGeneratingPlaygroundCode(false);
        this.setIsExportingCodeComponent(false);
        this.setIsGeneratingCode(false);
      }
    },
    async exportComponent({ _nodeId } = {}) {
      const nodeId = await this.getCorrectNodeId(_nodeId);
      try {
        await this.exportNode({ nodeId });
        this.trackExportedCodeSuccess({
          action: 'exported_component'
        });
      } catch (error) {
        this.trackExportedCodeFailure();
        errorHandler.captureException(error);
      } finally {
        this.setIsGeneratingPlaygroundCode(false);
        this.setIsExportingCodeComponent(false);
        this.setIsGeneratingCode(false);
      }
    },
    async exportComponentToCodeSandbox() {
      this.setIsExportingCodeComponent(true);
      await this.generateCode({
        isCodePen: false,
        isCodeSandbox: true,
        forceGenerate: true,
        isFromExportModal: false,
        isExported: true
      });
    },
    async exportCurrentScreenToCodepen() {
      this.setIsExportingCodeComponent(true);
      const nodeId = await this.getCurrentScreenNodeId();
      EventBus.$emit('OPEN_IN_CODEPEN', { nodeId });
    },

    async exportZip(
      zipName,
      screenModel,
      { model, settings, mode, options: { requestParams = {}, md5ToUrl = {}, onabort = () => {} } } = {}
    ) {
      let codegenResponse;
      try {
        codegenResponse = await this.makeCodegenRequest({
          model,
          settings,
          mode,
          options: { onabort, requestParams }
        });
      } catch (error) {
        errorHandler.captureException(error);
      }

      const cssFilenames = Object.keys(codegenResponse.files).filter(
        (file) => file.endsWith('.css') || file.endsWith('.scss')
      );
      const codeFilenames = Object.keys(codegenResponse.files).filter((file) => !cssFilenames.includes(file));
      const cssFiles = Object.keys(codegenResponse.files)
        .filter((key) => cssFilenames.includes(key))
        .reduce((obj, key) => {
          obj[key] = codegenResponse.files[key];
          return obj;
        }, {});
      const codeFiles = Object.keys(codegenResponse.files)
        .filter((key) => codeFilenames.includes(key))
        .reduce((obj, key) => {
          obj[key] = codegenResponse.files[key];
          return obj;
        }, {});
      const assets = this.getAssets(screenModel);
      const assetToUrl = assets.reduce((a, v) => ({ ...a, [v.fileName]: md5ToUrl[v.md5] }), {});
      const images = await this.fetchScreenAssets(assetToUrl);
      const staticFiles = { img: images, css: cssFiles };
      let files;
      switch (settings.web_framework) {
        case 'React':
          files = { '': codeFiles, static: staticFiles };
          break;
        case 'Vue':
          files = { '': codeFiles, public: staticFiles };
          break;
        default:
          files = { '': codeFiles, ...staticFiles };
          break;
      }

      this.nextOnboardingStage({ currentStageSlug: 'export-code' });

      return createZip({ files, zipName });
    },

    async handleDownloadCodePackage(settings) {
      this.codePackages = await this.fetchAllCodePackages();
      this.currentPrefCodePackage = this.getCurrentPrefCodePackage(settings);

      if (this.$route.query['delete_existing_code_packages']) {
        try {
          await api.delete(`/v2/code_packages/`, this.currentPrefCodePackage.id);
          this.codePackages = await this.fetchAllCodePackages();
          this.currentPrefCodePackage = this.getCurrentPrefCodePackage(settings);
        } catch (err) {
          console.error(err);
          errorHandler.captureException(err);
        }
      }

      const eventPayload = this.omniviewFrameworkPayload;
      if (this.isPackageWithCurrentSettingReady()) {
        this.trackExportedCodeSuccess({
          action: 'downloaded_existing_package'
        });
        this.$trackEvent('omniview.download-existing-package.success', eventPayload);
        this.trackExportCodeEvent();
        downloadFile(this.currentPrefCodePackage.download_url);
        this.openDownloadPackageModal(this.currentPrefCodePackage.download_url, true);
        EventBus.$emit('downloadComplete');
        return 'success';
      }
      let codePackage, waitForPackageTimeout;
      if (this.isPackageWithCurrentSettingsProcessing()) {
        codePackage = await this._pollCodePackage(this.currentPrefCodePackage);
      } else if (this.isPackageWithCurrentSettingsInvalidOrNotExists()) {
        this.exportCodeLoading = true;
        const res = await this.requestPackage({ settings });
        const codePackageId = res.data.package;
        waitForPackageTimeout = this.openWaitForPackageModal(codePackageId);
        codePackage = await this._pollCodePackage({ id: codePackageId });
      }
      const { status, download_url: downloadUrl, notify_when_done: mailSentToUser } = codePackage || {};
      if (status === 'finished' && downloadUrl && !mailSentToUser) {
        clearTimeout(waitForPackageTimeout);
        this.exportCodeLoading = false;
        EventBus.$emit('close-package-waiting-modal', { isPackageReady: true });
        this.openDownloadPackageModal(downloadUrl, false);
        this.trackExportCodeEvent();
        this.trackExportedCodeSuccess({
          action: 'download_package'
        });
        this.$trackEvent('omniview.package-on-demand.success', eventPayload);
        EventBus.$emit('downloadComplete');
      } else if (status === 'failed') {
        clearTimeout(waitForPackageTimeout);
        this.$trackEvent('omniview.package-on-demand.failed', { type: this._type });
        this.trackExportedCodeFailure();
        this.openPackageFailedModal();
        EventBus.$emit('downloadComplete');
      }
      this.nextOnboardingStage({ currentStageSlug: 'export-code' });
      return status;
    },
    async exportCode(node = null) {
      if (!this.isExportAllowed) {
        EventBus.$emit('close');
        const eventPayload = this.omniviewFrameworkPayload;
        const eventData = {
          ...eventPayload,
          panel: 'export_code_modal',
          action: 'download_package',
          count_screens: this.countExportedScreens,
          exported: this.selectedScopeCta
        };
        if (this.shouldShowPaywall) {
          return this.openUpgradeDownloadCodeModal({ eventData });
        }
        return openModal({
          name: 'export-code-as-viewer',
          variant: 'center',
          opacity: 0.3,
          mode: 'dark',
          whiteOverlay: true,
          background: '#2d2d2d',
          width: 500,
          closeButton: true
        });
      }
      const lengthUnit = this.lengthUnit;
      const framework = this.codegenLang;
      const layout = framework == 'html' ? this.codegenHTMLLayout : 'absolute';
      const newCodePrefs = { framework, layout, lengthUnit };
      this.setCodeDownloadPrefs(newCodePrefs);
      localStorage.setItem('codeDownloadPrefs', JSON.stringify(newCodePrefs));
      await this.exportFullZip(node);
    },
    async exportFullZip(node) {
      try {
        const settings = this.getCurrentPrefCodePackageSettings(node);
        const status = await this.handleDownloadCodePackage(settings);
        if (status && !['outdated', 'failed'].includes(status)) {
          this.codePackages = await this.fetchAllCodePackages();
        }
      } catch (err) {
        console.error(err);
        this.trackExportedCodeFailure();
        errorHandler.captureException(err);
        toastError('Something went wrong here, please try again.');
      }
      EventBus.$emit('close');
    },
    getCurrentPrefCodePackage(settings) {
      if (this.codePackages) {
        const [codePackage] = this.codePackages?.filter((codePackage) =>
          isEqual(JSON.parse(JSON.stringify(codePackage.settings)), settings)
        );
        return codePackage;
      }
      return null;
    },
    getCurrentPrefCodePackageSettings(node) {
      const {
        codeDownloadPrefs,
        codegenReactSyntax,
        codegenReactStyle,
        codegenVueStyle,
        codegenAutoAnimateMode,
        codegenLengthUnit
      } = this;
      return this._getCodegenFrameworkSettings({
        node,
        framework: codeDownloadPrefs.framework,
        codegenHTMLLayout: codeDownloadPrefs.layout,
        codegenReactSyntax,
        codegenReactStyle,
        codegenVueStyle,
        codegenAutoAnimateMode,
        codegenLengthUnit
      });
    },
    _getCodegenFrameworkSettings({
      node,
      framework,
      codegenReactStyle,
      codegenReactSyntax,
      codegenHTMLLayout,
      codegenVueStyle,
      codegenAutoAnimateMode,
      codegenLengthUnit
    }) {
      let settings = {
        ...(node && { node }),
        auto_animate_mode: codegenAutoAnimateMode,
        length_unit: codegenLengthUnit
      };
      if (framework === 'html') {
        const isFlex = codegenHTMLLayout === 'flexbox' || codegenHTMLLayout === 'auto_flexbox';
        settings = {
          ...settings,
          preset_settings: isFlex ? 'clean_code' : 'high_fidelity'
        };
      } else if (framework === 'react') {
        settings = {
          ...settings,
          preset_settings: 'clean_code',
          web_components_enable: true,
          web_framework: 'React',
          web_framework_settings: {
            component_type: codegenReactSyntax,
            styled_components: codegenReactStyle === 'styled',
            stylesheet_syntax: codegenReactStyle === 'styled' ? 'css' : codegenReactStyle
          }
        };
        if (framework === 'react') {
          settings['language'] = this.codegenReactLanguage;
          settings['engine'] = 'athena';
          settings['athena_react_file_generation_mode'] = 'full_project';
        }
      } else if (framework === 'vue') {
        settings = {
          ...settings,
          preset_settings: 'clean_code',
          web_components_enable: true,
          web_framework: 'Vue',
          web_framework_settings: {
            stylesheet_syntax: codegenVueStyle
          }
        };
      }

      return settings;
    },
    isPackageWithCurrentSettingReady() {
      return this.currentPrefCodePackage?.status === 'finished' && this.currentPrefCodePackage?.download_url;
    },
    isPackageWithCurrentSettingsProcessing() {
      return this.currentPrefCodePackage?.status === 'processing';
    },
    isPackageWithCurrentSettingsInvalidOrNotExists() {
      return !this.currentPrefCodePackage || ['failed', 'outdated'].includes(this.currentPrefCodePackage?.status);
    },
    async requestPackage({ settings }) {
      const { id } = this.currentProjectRelease;
      this._setType();
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.package-on-demand.start', eventPayload);
      // notify_when_done: false -> For legacy webapp - can remove when old webapp cache will be invalidated
      const payload = {
        package_types: this._type,
        settings,
        notify_when_done: false
      };
      return await api.post(`/project_release/${id}/package`, payload);
    },
    _setType() {
      const { layout, framework } = this.codeDownloadPrefs;

      this._type = 'Play';
      if (framework === 'react') {
        this._type = 'React';
      } else if (framework === 'vue') {
        this._type = 'Vue';
      } else if (layout === 'auto_flexbox' || layout === 'flexbox') {
        this._type = 'Flex';
      }
    },
    openWaitForPackageModal(codePackageId) {
      this.waitForPackageTimeout = setTimeout(() => {
        this.showGeneratingOnBackgroundToast = false;
        openModal({
          name: 'package-wait',
          variant: 'center',
          width: 500,
          closeButton: false,
          mode: 'dark',
          props: { codePackageId }
        });
      }, 10000);
      return this.waitForPackageTimeout;
    },
    async _pollCodePackage({ id }) {
      this.exportCodeLoading = true;

      const codePackage = await poll({
        fn: () => this.fetchCodePackageById({ id, skipCache: true }),
        validate: this.checkCodePackage,
        interval: 3000,
        maxAttempts: 200
      });
      return codePackage;
    },
    checkCodePackage(codePackage) {
      return codePackage?.status !== 'processing';
    },
    openDownloadPackageModal(downloadUrl, cached) {
      openModal({
        name: 'package-ready',
        variant: 'center',
        width: 500,
        closeButton: true,
        mode: 'dark',
        props: { downloadUrl, cached }
      });
    },
    openPackageFailedModal() {
      openModal({
        name: 'package-failed',
        variant: 'center',
        width: 500,
        closeButton: true,
        mode: 'dark'
      });
    },
    trackExportCodeEvent() {
      const { layout, framework } = this.codeDownloadPrefs;
      this.$trackEvent('omniview.download-code-button.clicked', {
        framework,
        layout,
        page: 'omniview'
      });
      this.$gtm.trackEvent({
        event: 'html_code_download',
        event_category: 'HTML Code Download',
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: framework
      });
    },
    async fetchAllCodePackages() {
      try {
        const { id: projectReleaseId } = this.currentProjectRelease;
        const { results: codePackages = [] } = await this.fetchCodePackages({
          parent: 'project_releases',
          id: projectReleaseId,
          skipCache: true
        });
        return codePackages;
      } catch (err) {
        console.error(err);
        this.exportCodeLoading = false;
        return null;
      }
    },
    getAssets(model) {
      const addedLayersIds = [];
      const assets = [];

      const findAssets = (currentNode) => {
        if (currentNode.model_class === 'ADModelImageView') {
          if (addedLayersIds.indexOf(currentNode.modelID) == -1) {
            addedLayersIds.push(currentNode.modelID);
            assets.push(currentNode);
          }
        } else {
          for (let i = 0; i < currentNode.subviews.length; i++) {
            findAssets(currentNode.subviews[i]);
          }
        }
      };

      findAssets(model);
      return assets;
    },
    async fetchScreenAssets(assetsToUrl) {
      const images = await Promise.all(
        Object.entries(assetsToUrl).map(([filename, url]) => {
          if (url) {
            return this.fetchFile(getCdnUrl(url));
          } else {
            console.warn(`No url found for ${filename}`);
          }
        })
      );
      return Object.keys(assetsToUrl).reduce(
        (a, fileName, i) => (images[i] ? { ...a, [fileName]: images[i] } : { ...a }),
        {}
      );
    },
    // the most important function in the omniview
    async generateCode({
      isCodePen = false,
      isCodeSandbox = false,
      _nodeId,
      forceGenerate = false,
      fullModel = false,
      framework = null,
      isFromExportModal = null,
      isExported = false,
      removeCache = false
    } = {}) {
      let isExportingCode = false;
      try {
        const { layer } = this.$route.query;
        let nodeId = _nodeId ? _nodeId : layer;
        const screenNodeId = await this.getCurrentScreenNodeId();
        const externalEditorSettings = { isCodePen, isCodeSandbox };
        framework = framework ? framework : this.codegenLang;

        console.log('start generate code', {
          isCodePen,
          isCodeSandbox,
          _nodeId,
          forceGenerate,
          fullModel,
          framework,
          isFromExportModal
        });

        if (isFromExportModal && isCodePen) {
          EventBus.$emit('OPEN_IN_CODEPEN', { nodeId: screenNodeId });
          return;
        }

        if (!isCodeSandbox && this.codegenLang == 'html' && !isFromExportModal) {
          this.handleHTMLCodegen({ id: nodeId || screenNodeId });
          return;
        }

        if (isFromExportModal) {
          await this.resetSelection();
        }

        if (fullModel) {
          isExportingCode = true;
          nodeId = screenNodeId;
        }

        if (!nodeId) {
          if (!screenNodeId) {
            return;
          }
          nodeId = screenNodeId;
        }

        if (!forceGenerate && nodeId == this.lastGeneratedId) {
          return;
        }
        if (forceGenerate) {
          await codegenCache.clearAll();
        }

        await this.waitForProjectDataToLoad();
        this.abortPrevGenerationCall();

        const isExternalEditor = isCodeSandbox || isCodePen;
        let start = window.performance.now();

        const isWebComponent = this.isComponentOrSuggestionById(nodeId);

        this.initLoadingState(isExternalEditor, isExportingCode);

        await this.$waitFor(() => !this.loading.project && !this.loading.release && !this.loading.assets, true);

        let { modelNode, subModel } = await this.getNodeSubModel({
          nodeId,
          screenNodeId,
          includeBreakpoints: isCodeSandbox
        });

        this.setCurrentNode({ ...this.currentNode, viewName: modelNode?.viewName || '' });

        const codegenSettings = await this.getCodegenSettingsWithOverrides({
          modelNode,
          framework,
          externalEditorSettings,
          presetSettings: 'clean_code'
        });

        const codeCallParams = {
          model: subModel,
          settings: codegenSettings,
          mode: isCodeSandbox ? 'package' : 'clean_snippet',
          options: {
            onabort: () => {
              this.setIsGeneratingCode(true);
            }
          }
        };

        if (isExternalEditor) {
          this.trackExternalEditorEvents({
            framework,
            externalEditor: isCodeSandbox ? 'codesandbox' : 'codepen',
            isFromExportModal
          });
        }

        if (isCodeSandbox) {
          this.setIsGeneratingCode(true);
          console.log('making request');
          const params = await this.makeCodeSandboxRequest(codeCallParams);
          console.log('making request done');

          EventBus.$emit('open-in-codesandbox', { params, removeCache });
        } else {
          this.trackGetCleanSnippetEvent({
            framework,
            elementType: isWebComponent ? 'component' : 'element'
          });
          const codegenResult = await this.makeCodegenRequest({
            mode: 'clean_snippet',
            model: subModel,
            settings: codegenSettings,
            options: {
              onabort: () => {
                this.setIsGeneratingCode(true);
              }
            }
          });
          let end = window.performance.now();

          // codegenResult = this.processCodegenResult(codegenResult);

          let eventProps = { duration: (end - start).toFixed(2), engine: codegenSettings.engine, framework };
          if (framework === 'react') {
            eventProps['language'] = this.codegenReactLanguage;
          }
          this.$trackEvent('omniview.code-mode.get-code-done', eventProps);
          isCodePen ? this.populatePlaygroundCode(codegenResult) : this.populateCodePanels(codegenResult);
          this.setIsGeneratingCode(false);
        }

        this.setLastGeneratedId(nodeId);
      } catch (error) {
        if (isExported) {
          this.trackExportedCodeFailure();
        }
        errorHandler.captureException(error);
        if (this.generateController && this.generateController.signal && this.generateController.signal.aborted) {
          this.setIsGeneratingCode(true);
        }
      } finally {
        this.setIsGeneratingCode(false);
        this.setIsExportingCodeComponent(false);
        isExportingCode ? this.setIsExportingPlaygroundCode(false) : this.setIsGeneratingPlaygroundCode(false);
      }
    },
    async regeneratePreviousRequest() {
      this.setIsGeneratingCode(true);
      try {
        const { layer } = this.$route.query;
        const nodeId = layer;
        const screenNodeId = await this.getCurrentScreenNodeId();

        if (!nodeId) return;

        const isWebComponent = this.isComponentOrSuggestionById(nodeId);
        let { subModel } = await this.getNodeSubModel({
          nodeId,
          screenNodeId
        });

        const [overrides, md5Map] = await Promise.all([this.getModelOverrides(), this.getModelMd5Map()]);

        const codegenSettings = await this.getCodegenSettings({
          framework: this.codegenLang,
          fontsMap: this.fontsMap || {},
          md5Map,
          overrides,
          isCodePen: false,
          isCodeSandbox: false,
          isWebComponent,
          presetSettings: 'clean_code'
        });
        const codegenRequestParams = {
          mode: 'clean_snippet',
          model: subModel,
          settings: codegenSettings
        };

        const codegenResult = await this.makeNonCachingCodegenRequest(codegenRequestParams);

        this.populateCodePanels(codegenResult);
      } catch (e) {
        console.error('Request failed: ', e);
      }
      this.setIsGeneratingCode(false);
    },
    failedCodeError() {
      this.$trackEvent('chrome-extension.generate-code.failed');
      return {
        components: [],
        files: {
          'index.html': 'Failed to export code',
          'index.vue': 'Failed to export code',
          'index.jsx': 'Failed to export code',
          'style.css': 'Failed to export code'
        },
        homepage_slug: '',
        forms: []
      };
    },
    processCodegenResult(result) {
      if (this.codegenLang != 'html') {
        return result;
      }

      function indexInParent(node) {
        let children = node.parentNode.childNodes;
        let num = 0;
        for (let i = 0; i < children.length; i++) {
          if (children[i] == node) return num;
          if (children[i].nodeType == 1) num++;
        }
        return -1;
      }

      const doc = new DOMParser().parseFromString(result['files']['index.html'], 'text/html');
      let map = {};
      const elements = doc.querySelectorAll('[data-id]');
      elements.forEach((el) => {
        let id = el.getAttribute('data-id');
        let key = indexInParent(el) + el.className.replace(' ', '');
        if (!has(map, key)) {
          map[key] = id;
        }
      });

      return result;
    },
    async makeCodegenRequestWithoutIframe(nodeId = '', fileId = '', figma_access_token = '', settings) {
      //get the code only for specific node without the iframe
      let codegenBaseURL = localStorage.getItem('figmaServiceBaseURL') || 'https://figma-service.animaapp.com';
      let codegenURL = `${codegenBaseURL}/generate/node`;

      this.generateController = new AbortController();
      this.generateController.signal.onabort = onabort;

      delete settings.base_dir_images;
      const codeRes = await fetch(codegenURL, {
        method: 'POST',
        body: JSON.stringify({
          file_key: fileId,
          mode: 'clean_code',
          node_id: nodeId,
          figma_access_token: figma_access_token ? figma_access_token : this.currentUser.figma_auth_token,
          settings: JSON.stringify(settings)
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      });

      if (codeRes && codeRes.status != 200 && codeRes.status != 201) {
        let error = await codeRes.json();

        if (window.top && window.top.postMessage && error && error.errors && error.errors === 'Invalid token') {
          //if your token is not valid anymore
          window.top.postMessage('access-token-is-not-valid', '*');
        }

        return this.failedCodeError();
      }
      let codegenResult = {};
      try {
        codegenResult = await codeRes.json();
      } catch (e) {
        return this.failedCodeError();
      }
      return codegenResult;
    },
    async fetchFile(url) {
      const res = await fetch(url, { method: 'GET' });

      if (res && res.status != 200) {
        return null;
      }

      return await res.blob();
    },
    async makeNonCachingCodegenRequest({
      model,
      settings,
      mode,
      options: { requestParams = {}, onabort = () => {} } = {}
    }) {
      const codegenBaseURL = localStorage.getItem('codegenBaseURL') || CODEGEN_BASE_URL;
      const codegenURL = readCookie('X-CodegenURL') || `${codegenBaseURL}/generate`;

      this.generateController = new AbortController();
      this.generateController.signal.onabort = onabort;

      const res = await fetch(codegenURL, {
        method: 'POST',
        body: JSON.stringify({
          mode,
          model,
          settings
        }),
        headers: this.getCodegenHeaders,
        credentials: 'same-origin',
        ...requestParams,
        signal: this.generateController.signal
      });

      if (res && res.status != 200) {
        throw new Error(`Something went wrong. Error: ${res.status}: ${res.statusText}`);
      }

      const codegenResult = await res.json();
      return codegenResult;
    },
    async makeCodegenRequest({ model, settings, mode, options: { requestParams = {}, onabort = () => {} } = {} }) {
      const cacheKey = codegenCache.generateKeyFromObj({ mode, nodeId: model?.screens[0]?.modelID, ...settings });
      const cachedData = await codegenCache.getByKey(cacheKey);
      let codegenResult;

      if (cachedData) {
        codegenResult = cachedData;
      } else {
        const codegenRequestParams = {
          model,
          settings,
          mode,
          options: { requestParams, onabort }
        };
        codegenResult = await this.makeNonCachingCodegenRequest(codegenRequestParams);
      }

      codegenCache.setByKey(cacheKey, codegenResult);
      return codegenResult;
    },
    async makeCodeSandboxRequest({ model, settings, mode, options: { requestParams = {}, onabort = () => {} } } = {}) {
      try {
        const codegenResponse = await this.makeCodegenRequest({
          model,
          settings,
          mode,
          options: { onabort, requestParams }
        });

        const files = objectMap(codegenResponse.files, (val) => ({
          content: val
        }));

        console.log('____FROM_makeCodeSandboxRequest', files);

        if (!settings.web_framework) {
          files['package.json'] = {
            content: {}
          };

          files['sandbox.config.json'] = {
            content: { template: 'static' }
          };
        }

        const parameters = getParameters({
          files
        });

        return parameters;
      } catch (error) {
        errorHandler.captureExceptionAndTrack(error, {
          name: 'omniview.codesandbox-export.failed',
          data: { projectId: this.$route.params.projectId }
        });
      }
    },

    populatePlaygroundCode(codegenResult) {
      switch (this.codegenLang) {
        case 'html':
          this.setPlaygroundCode({ ...this.playgroundCode, html: codegenResult.files['index.html'] });
          break;
        case 'react':
          this.setPlaygroundCode({
            ...this.playgroundCode,
            jsx:
              this.codegenReactLanguage === 'typescript'
                ? codegenResult.files['index.tsx']
                : codegenResult.files['index.jsx'],
            html: codegenResult.files['index.html']
          });

          break;

        case 'vue':
          // nothing for now
          break;

        default:
          break;
      }

      const extension = this.codegenStylesheetLang || 'css';

      let styles = get(codegenResult, ['files', `style.${extension}`], '');
      let globals = get(codegenResult, ['files', `globals.${extension}`], '');
      let styleguide = get(codegenResult, ['files', `styleguide.${extension}`], '');

      let css = `${globals}\n${styleguide}${styleguide ? '\n' : ''}${styles}`;

      this.setPlaygroundCode({ ...this.playgroundCode, css });
      EventBus.$emit('OPEN_IN_CODEPEN');
    },

    populateCodePanels(codegenResult) {
      let html, css;
      switch (this.codegenLang) {
        case 'html':
          this.setCurrentNodeHTML(codegenResult.files['index.html']);
          html = codegenResult.files['index.html'];

          break;
        case 'react':
          html =
            this.codegenReactLanguage === 'typescript'
              ? codegenResult.files['index.tsx']
              : codegenResult.files['index.jsx'];
          this.setCurrentNodeJSX(html);

          break;

        case 'vue':
          this.setCurrentNodeJSX(codegenResult.files['index.vue']);
          html = codegenResult.files['index.vue'];

          break;

        default:
          break;
      }

      const extension = this.codegenStylesheetLang || 'css';

      let styles = get(codegenResult, ['files', `style.${extension}`], '');
      let globals = get(codegenResult, ['files', `globals.${extension}`], '');

      css = `${globals}\n${styles}`;

      this.setCurrentNodeCSS(css);
      this.reportCodeState(html, css);
    },

    resetCodePanels() {
      this.resetPlaygroundCode();
      this.setCurrentNodeHTML('');
      this.setCurrentNodeJSX('');
      this.setCurrentNodeCSS('');
    },

    async getModelMd5Map() {
      await this.$waitFor(() => !this.loading.assets, true);
      return this.assetsRegistry ?? {};
    },

    async getModelOverrides() {
      await this.$waitFor(() => !this.isWaitingForOverrides, true);
      const nodes = { ...this.nodes };
      let currentNodeOverrides = this.currentComponentMetadata.overrides || {};
      let overrides = { ...currentNodeOverrides };
      const ids = Object.keys(nodes);
      for (let i = 0; i < ids.length; i++) {
        let nodeId = ids[i];
        let nodeData = nodes[nodeId];

        if (!nodeData.isModifed) continue;
        const nodeClassObject = nodeData.originalAttributes.find((o) => o.name == 'class');
        let nodeName = '';
        if (nodeClassObject) {
          nodeName = nodeClassObject.value;
        }

        const styleObject = StyleToObject(nodeData.inlineCSS) || {};
        overrides[nodeId] = {
          ...(overrides[nodeId] || {}),
          css: styleObject,
          tagName: nodeData.tagName,
          nodeName
        };
      }

      if (overrides[this.currentNode.id] && overrides[this.currentNode.id]['capture_url']) {
        let url = overrides[this.currentNode.id]['capture_url'];
        let pos = url.lastIndexOf('.');
        url = url.substr(0, pos < 0 ? url.length : pos) + `.${this.captureType}`;
        overrides[this.currentNode.id]['capture_url'] = url;
      }

      return overrides;
    },

    async getModelAndScreen({ slim = false, waitForSlugifyCall = false } = {}) {
      try {
        if (this.isPreSync) {
          await this.$waitFor(() => !this.loading.model, true);

          this.setLoading({ key: 'model', value: true });

          const model = await this.fetchReleaseModel({ slim });
          this.setLoading({ key: 'model', value: false });

          if (model) {
            model.screens = model.screens.map((s) => ({
              ...s,
              thumbnail_url: s.thumbURLString
            }));
          }
          const { screenSlug } = this.$route.params;

          await this.slugifyScreensNames((model.screens || []).map((s) => s.variableID));

          let currentScreen = model.screens.find((s) => this.slugsMap[s.variableID] == screenSlug);

          return { model, currentScreen };
        }

        await this.$waitFor(
          () => !this.loading.release && !this.loading.fetching && this.currentProject.is_syncing === false,
          true
        );
        let { model_file_url } = this.currentRelease;
        const isProgressiveUpload = model_file_url.includes('None');
        if (isProgressiveUpload) {
          model_file_url = this.currentComponent.model_url;
        }

        if (has(this.loading.model, model_file_url)) {
          await this.$waitFor(() => this.loading.model, false, {
            key: model_file_url
          });
        }

        this.setLoading({
          key: 'model',
          value: {
            ...this.loading.model,
            [model_file_url]: true
          }
        });

        let model;
        if (isProgressiveUpload) {
          model = await this.fetchModelFromUrl(model_file_url);
        } else {
          model = await this.fetchReleaseModel({ slim });
        }

        this.setLoading({
          key: 'model',
          value: {
            ...this.loading.model,
            [model_file_url]: false
          }
        });

        if (!this.currentComponent) {
          const currentScreen = model.screens[0];
          return { model, currentScreen };
        }
        const { id: cId, model_id: cModelId } = this.currentComponent;
        let currentScreen = model.screens.find((s) => s.modelID == cModelId);
        if (!currentScreen) {
          const { release_model_file_url } = await this.fetchSingleComponent({ id: cId });
          if (release_model_file_url) {
            if (has(this.loading.model, release_model_file_url)) {
              await this.$waitFor(() => this.loading.model, false, {
                key: release_model_file_url
              });
            }

            this.setLoading({
              key: 'model',
              value: {
                ...this.loading.model,
                [release_model_file_url]: true
              }
            });

            model = await this.fetchModelFromUrl(release_model_file_url);
            this.setLoading({
              key: 'model',
              value: {
                ...this.loading.model,
                [release_model_file_url]: false
              }
            });

            currentScreen = model.screens.find((s) => s.modelID == cModelId);
          }
        }

        this.fetchFontsMap(model);
        const slugifyCall = async () => this.slugifyScreensNames((model.screens || []).map((s) => s.variableID));
        waitForSlugifyCall ? await slugifyCall() : slugifyCall();
        return { model, currentScreen };
      } catch (error) {
        errorHandler.captureExceptionAndTrack(error, { name: 'omniview.fetch-model.failed' });
      }
    },
    async fetchFontsMap(model) {
      if (this.isFetchingFonts) return;
      // only in the model is loaded and there is no fontsMap defined yet
      if (model && !this.fontsMap) {
        try {
          this.isFetchingFonts = true;
          const { data } = await axios.post('rpc/get_fonts_map', {
            theme: model.theme || {},
            use_fonts_server_url: true
          });
          this.fontsMap = data.fonts_map || {};
        } catch (error) {
          // if the request fails define the map to an empty object
          this.fontsMap = {};
          this.$trackEvent('omniview.fetch-fonts.failed');
        } finally {
          this.isFetchingFonts = false;
        }
      }
    },

    reportCodeState(html, css) {
      const flags = analyzeCode(html, css);
      this.$trackEvent('omniview.clean-code.code-properties', { ...flags });
    },

    checkForModeInRoute() {
      return new Promise((resolve) => {
        let { mode } = this.$route.query;
        if (!mode) {
          mode = 'play'; //default mode
        }
        const done = (arg) => resolve(arg);
        switch (mode) {
          case 'play':
            this.handleModeChange({
              mode: this.modes[0],
              fromRoute: true
            }).then(done);
            break;
          case 'comments':
            this.handleModeChange({
              mode: this.modes[1],
              fromRoute: true
            }).then(done);

            break;
          case 'code':
            this.handleModeChange({
              mode: this.modes[2],
              fromRoute: true
            }).then(done);
            break;

          default:
            break;
        }
      });
    },

    async handleCodeModeNodeMessage(data) {
      // const { id: currentNodeId } = this.currentNode;

      const callbackEvent = get(data, 'metadata.callbackEvent', false);
      // const source = get(data, 'metadata.source', false);
      if (callbackEvent) {
        EventBus.$emit(callbackEvent['name'], callbackEvent['params']);
      }

      this.setCurrentNode(data);
      this.setCurrentNodePath(data.path);
      this.generateCode();

      this.$waitFor(() => this.isWebComponentsLoading, false).then(() => {
        if (this.isComponentOrSuggestionById(data.id)) {
          this.preProcessComponent({
            forcePreProcess: true
          }).then(() => {
            EventBus.$emit(SEND_MESSAGE, {
              action: 'update-nodes-data',
              data: {
                [data.id]: { viewName: this.currentWebComponent['master']['component_name'] }
              }
            });
          });
        }
      });
    },
    handleKeydown(e) {
      if (e.key === 'Escape') {
        this.handleEscapeClicked();
      }
    },
    handleEscapeClicked() {
      this.setIsFullScreen(false);
      this.setIsCompareEnabled(false);
      this.resetSelection();
    }
  }
};

export default codegenMixin;

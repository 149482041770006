import store from '@/store';

export const setupEventListeners = ({ mode, language, $el }) => {
  if (mode == 'default') {
    if (['markup', 'jsx', 'html'].includes(language)) {
      let nodes = Array.from($el.querySelectorAll('[data-id]'));

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        let tagId = node.getAttribute('tag-id');
        let open, close;

        if (tagId) {
          const pair = $el.querySelectorAll(`[tag-id='${tagId}']`);

          if (pair.length == 1) {
            open = pair[0];
          } else if (pair.length == 2) {
            open = pair[0];
            close = pair[1];
          }
        }

        // eslint-disable-next-line
        const handleMouseOver = () => {
          const ev = new CustomEvent('markup-node-mouseover', {
            detail: {
              nodeId: stripComponentInstanceSuffix(node.getAttribute('data-id'))
            }
          });

          document.dispatchEvent(ev);
        };
        // eslint-disable-next-line
        const handleMouseLeave = () => {
          const ev = new CustomEvent('markup-node-mouseleave');
          document.dispatchEvent(ev);
        };

        // TODO ADD this from the worker as a string :brain:
        open && open.addEventListener('mouseover', handleMouseOver);
        open && open.addEventListener('mouseleave', handleMouseLeave);

        close && close.addEventListener('mouseover', handleMouseOver);
        close && close.addEventListener('mouseleave', handleMouseLeave);
      }
    }
  }
};

let stripComponentInstanceSuffix = (dataId) => dataId.replace(/:an-component-instance$/, '');

export const handleStyleguideClick = ({ $emit = () => {}, el }) => {
  if ([...el.classList].includes('token-type')) {
    enterEditMode({
      el,
      type: 'token',
      $emit
    });
  }
  if ([...el.classList].includes('class-type')) {
    enterEditMode({
      el,
      type: 'class',
      $emit
    });
  }
};

const enterEditMode = ({ el, type, $emit }) => {
  const tokenId = el.getAttribute('token-id');
  createInput({
    tokenId,
    type,
    $emit
  });
};

const createInput = ({ tokenId, type, $emit }) => {
  const styleguidePane = document.querySelector('#styleguidePane');
  if (!styleguidePane) return;
  const el = styleguidePane.querySelector(`[token-id="${tokenId}"]`);
  const input = document.createElement('input');
  input.setAttribute('spellcheck', false);
  input.setAttribute('autocomplete', 'off');
  input.classList.add(`styleguide-input`, `${type}-type`, 'fadeInFast');
  input.value = tokenId;
  input.setAttribute('size', tokenId.length);

  let wr = document.createElement('div');
  wr.classList.add('input-sizer', `${type}-type`);
  el.innerHTML = '';
  el.classList = '';

  wr.appendChild(input);
  el.appendChild(wr);

  input.addEventListener('blur', (e) => {
    handleUpdateValue({
      event: e,
      tokenId,
      type,
      $emit
    });
  });
  input.addEventListener('keydown', function (e) {
    if (e.key === ' ') {
      e.preventDefault();
    }
    if (e.key == 'Enter') {
      input.blur();
    }
  });

  input.addEventListener('input', (e) => {
    let value = e.target.value;
    e.target.parentNode.dataset.value = value;
    e.target.setAttribute('size', Math.max(value.length, 1));
  });

  // moveCursorToStart(input);

  input.focus();
};

const handleUpdateValue = ({ event, tokenId, type, $emit }) => {
  let newTokenId = event.target.value;

  if (newTokenId && newTokenId != tokenId) {
    updateClassTokenValue({
      tokenId,
      newTokenId,
      type
    });
    $emit('update', type);
    store.dispatch('styleguide/updateStyleguide', { tokenId, newTokenId, type });
  } else {
    updateClassTokenValue({
      tokenId,
      type
    });

    $emit('cancel', type);
  }
};

const updateClassTokenValue = (payload) => {
  const styleguidePane = document.querySelector('#styleguidePane');
  if (!styleguidePane) return;
  const { type, tokenId, newTokenId } = payload;
  let token = tokenId;
  const el = styleguidePane.querySelector(`[token-id="${tokenId}"]`);
  token = newTokenId ? newTokenId : tokenId;
  let u = document.createElement('div');
  u.classList.add('underline-div');
  el.classList = getTokenClasses(type);
  el.style.padding = '0';
  el.style.margin = '0';
  el.style.display = 'inline';
  if (type == 'token') {
    el.innerHTML = `--${token}`;
  } else if (type == 'class') {
    el.innerHTML = `.${token}`;
  }
  el.appendChild(u);
  el.setAttribute('token-id', token);
};

const getTokenClasses = (type) => {
  return `token ${type == 'class' ? 'class' : 'variable'} element-clickable ${type}-type fadeInFast`;
};

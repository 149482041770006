import auth from '@/auth';
import { openModal } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import store from '@/store';

/**
 * @type {NavigationGuard}
 */
export const beforeSignupOrLogin = async (to, from, next) => {
  const tokenExists = auth.isLoggedIn();
  const isAnonymousUser = auth.isAnonymousUser();
  const isPairing = !!to.query.pairing;
  const isFigmaAuthRequired = !!to.query.request_oauth;

  // wait a second, there might just be a user over there...
  if (tokenExists) {
    try {
      // let's see... can we login?
      const user = await store.dispatch('users/fetchOne', { id: 'me' });
      let name = 'root';
      let query = {};

      // redirect to relevant page.
      if (!isAnonymousUser && isPairing) {
        if (isFigmaAuthRequired && !user.enabled_figma_api) {
          return next({ name: 'figma-generate-token', query: { callbackUrl: to.fullPath } });
        }

        name = 'paired';
        query = to.query;
      }
      return next({ name, query });
    } catch (err) {
      // nope!
      errorHandler.captureException(err);
    }
  }

  if (['figma-panel', 'dev-mode'].includes(to.query.figma_mode)) {
    store.dispatch('webappSystem/updateOriginDevMode', true);
  }

  if (to.query.intent === 'devmode') {
    store.dispatch('webappSystem/updateIntentDevMode', true);
  }

  // ALWAYS KEEP IN THE END OF THE FUNCTION
  next();
};

/**
 * @type {NavigationGuard}
 */
export const beforeEnterModal = (props = {}) => {
  return (to, from, next) => {
    let onCloseRedirect = props.onCloseRedirect || from || '/';
    if (props.calcRedirect) {
      const calcResult = props.calcRedirect(to, from);
      onCloseRedirect = calcResult || onCloseRedirect;
      props.forceCloseRedirect = !!calcResult;
    }
    openModal({ ...props, onCloseRedirect });
    next();
  };
};

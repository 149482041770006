<template>
  <div class="container">
    <div class="text-container">
      <div class="percentage">
        {{ Math.floor(timer.progress * 100) + ' %' }}
      </div>
      <div class="text" v-if="text">
        <div>{{ text }}</div>
        <div style="margin-left: 10px" class="dot-loader">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </div>
    <div ref="loaderSvg">
      <svg-icon :width="206" :height="24" name="loader-line" />
    </div>
  </div>
</template>

<script>
import FakeProgress from '@/services/fake-progress';
import { colorPathChildren } from '@/utils/colors';

export default {
  props: {
    text: {
      type: String,
      default: null
    },
    theme: {
      type: String
    },
    max: {
      type: Number,
      default: 100
    },
    timeConstant: {
      type: Number,
      default: 6000
    }
  },
  data() {
    return {
      timer: null
    };
  },
  mounted() {
    if (this.theme === 'dark') {
      colorPathChildren(this.$refs.loaderSvg, '#ffffff');
    }
    if (this.theme === 'light') {
      colorPathChildren(this.$refs.loaderSvg, 'var(--secondary)');
    }
    this.timer = new FakeProgress({
      timeConstant: this.timeConstant,
      autoStart: true
    });
  },
  watch: {
    timer: {
      handler(val) {
        if (val) {
          if (Math.floor(val.progress * 100) > this.max - 1) {
            this.timer.pause();
          }
        }
      },
      deep: true
    },
    max: {
      handler(to, from) {
        if (to != from) {
          if (this.timer._paused && Math.floor(this.timer.progress * 100) < to) {
            this.timer.resume();
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.timer && this.timer.end();
  }
};
</script>

<style lang="scss" scoped>
// FULL SCREEN LOADING
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 200px auto;
  width: 100%;
}
.text-container {
  @include secondary-title;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 50px;
  .percentage {
    text-align: center;
    margin-bottom: 40px;
  }
  .text {
    font-size: 30px;
    display: flex;
    align-items: center;
  }
  margin-bottom: 40px;
}

.light {
  .text {
    color: var(--secondary);
  }
}
.dark {
  .text {
    color: white;
  }
}

$dotSize: 10px;
$animDelay: 0.25s;
.dot-loader {
  margin: 0 auto;
  width: $dotSize * 4;
  .dot {
    width: $dotSize;
    height: $dotSize;
    border-radius: $dotSize;
    background-color: white;
    transform: scale(0);
    display: inline-block;
    animation: dotanim 1.6s infinite ease-in-out;
    @for $i from 0 to 4 {
      &:nth-child(#{$i + 1}) {
        animation-delay: $animDelay * $i;
      }
    }
  }
}

@keyframes dotanim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  90% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
</style>

<template>
  <div class="iframe-wrapper">
    <div v-if="isProcessingSandbox" class="loader">
      <LoadingScreen :delay="false" theme="dark" />
    </div>
    <iframe
      v-else
      data-cy="main-iframe"
      data-hj-allow-iframe=""
      ref="iframeRef"
      id="iframeId"
      name="iframeName"
      class="iframe"
      frameborder="0"
      :src="codeSandboxEmbedUrl"
    ></iframe>
  </div>
</template>

<script>
import { defineComponent, watch, computed, ref, onBeforeUnmount, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router/composables';
import { trackEvent } from '@/services/tracking';
import { useGenerateCode, useLoading, useCodePreferences, useProjectRelease } from '@/hooks';
import { EventBus } from '@/services/bus';
import LoadingScreen from '@/components/Loading/LoadingScreen';

const codesandboxDefineUrl = 'https://codesandbox.io/api/v1/sandboxes/define';

export default defineComponent({
  components: {
    LoadingScreen
  },
  setup() {
    const route = useRoute();
    const { currentProjectRelease } = useProjectRelease();
    const { isProjectAndReleaseReady } = useLoading();
    const { generateCodeSandboxCode } = useGenerateCode();
    const { isPlaygroundOmniView, codegenFramework, codegenReactLanguage } = useCodePreferences();

    const currentSandboxId = ref('');
    const isProcessingSandbox = ref(false);

    const screenSlug = computed(() => route.params.screenSlug);

    const codeSandboxEmbedUrl = computed(() => {
      if (!currentSandboxId.value) return '';
      return `https://codesandbox.io/embed/${currentSandboxId.value}?fontsize=14&hidenavigation=0&theme=dark&moduleview=1`;
    });

    const generate = async () => {
      if (isProcessingSandbox.value) return;
      if (!isPlaygroundOmniView.value) {
        return;
      }
      try {
        isProcessingSandbox.value = true;
        const params = await generateCodeSandboxCode();
        const codesandboxId = await handleOpenInCodeSandbox({ params });
        currentSandboxId.value = codesandboxId;
        trackCodeSandboxSuccess({ codesandboxId });
      } catch (error) {
        console.error(error);
        trackCodeSandboxFailure();
      } finally {
        isProcessingSandbox.value = false;
      }
    };

    const handleOpenInCodeSandbox = async ({ params }) => {
      const formData = new FormData();
      formData.append('parameters', params);
      formData.append('json', 1);
      const res = await fetch(codesandboxDefineUrl, {
        method: 'POST',
        mode: 'cors',
        body: formData
      });
      if (!res.ok) {
        throw new Error(`Error generating codesandbox for project release: ${currentProjectRelease.value?.id}`);
      }

      const { sandbox_id: codesandboxId } = await res.json();

      return codesandboxId;
    };

    const trackCodeSandboxSuccess = ({ codesandboxId }) => {
      const trackEventParams = {
        sandboxId: codesandboxId,
        framework: route.query.framework ?? codegenFramework.value,
        ...(codegenFramework.value === 'react' && { language: codegenReactLanguage.value })
      };
      trackEvent('inspect-code.success', trackEventParams);
    };

    const trackCodeSandboxFailure = () => {
      const trackEventParams = {
        framework: route.query.framework || codegenFramework.value,
        reason: 'codesandbox',
        ...(codegenFramework.value === 'react' && { language: codegenReactLanguage.value })
      };

      trackEvent('inspect-code.failure', trackEventParams);
    };

    watch(
      isProjectAndReleaseReady,
      (r) => {
        if (!r) return;
        generate();
      },
      {
        immediate: true
      }
    );

    watch(screenSlug, (to, from) => {
      if (!to || to === from) return;
      generate();
    });

    onBeforeMount(() => {
      EventBus.$on('CODE_PREFERENCES_UPDATED', generate);
      EventBus.$on('ON_SCREEN_CHANGE', generate);
    });
    onBeforeUnmount(() => {
      EventBus.$off('CODE_PREFERENCES_UPDATED', generate);
      EventBus.$off('ON_SCREEN_CHANGE', generate);
    });

    return {
      isProcessingSandbox,
      codeSandboxEmbedUrl
    };
  }
});
</script>

<style lang="scss" scoped>
@import '../../components/OmniView/MainFrame.scss';

.iframe {
  width: 100%;
  height: 100%;
}
</style>

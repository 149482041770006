import errorHandler from '@/services/errorHandler';

export const formatPrice = (
  number = 0,
  { locale = 'en-US', currency = 'USD', minimumFractionDigits = 0, maximumFractionDigits = 0 } = {}
) => {
  const options = { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits };
  return Intl.NumberFormat(locale, options).format(number);
};

export const apiPlanName = (plan) => {
  if (!plan) plan = 'Prototype';
  const apiMapPlanName = {
    prototype: 'Prototype',
    basic: 'Prototype',
    pro: 'Pro',
    pro3: 'Pro',
    proScreens: 'Anima Pro Screens',
    team: 'Team',
    business: 'Business'
  };
  return apiMapPlanName[plan.toLowerCase()] || apiMapPlanName[plan] || plan;
};

export const readablePlanName = (plan) => {
  if (!plan) plan = 'Free';
  const apiMapPlanName = {
    free: 'Free',
    prototype: 'Basic',
    basic: 'Basic',
    pro: 'Pro',
    proscreens: 'Pro',
    animaproscreens: 'Pro',
    pro3: 'Pro',
    team: 'Team',
    business: 'Business'
  };
  return apiMapPlanName[plan.replaceAll(' ', '').toLowerCase()] || apiMapPlanName[plan] || plan;
};

export const getUserLocalization = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const payload = await response.json();
    return payload;
  } catch (error) {
    errorHandler.captureException(`Couldn't get user localization data from ipapi.co: ${error}`);
  }
};

import api from '@/api';
import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'announcement',
  modelName: 'announcements',
  endpoint: '/v2/announcements',
  state: {
    items: []
  },
  mutations: {
    setItems: (state, { results, total, count, page }) => {
      state.items = results;
      state.total = total;
      state.count = count;
      state.page = page;
    }
  },
  actions: {
    async fetchAll({ commit, rootState }) {
      const currentUser = rootState.users?.currentItem ?? {};
      const params = { order_by: '-created_at', page: 0, page_size: 10 };
      const { data: publishedData } = await api.list('/v2/announcements', { params });
      const data = publishedData;
      if (currentUser.is_admin) {
        params.is_published_as_draft = true;
        const { data: draftsData } = await api.list('/v2/announcements', { params });
        data.results = draftsData.results.concat(data.results);
      }
      commit('setItems', data);
    }
  }
});

<template>
  <div class="divider" />
</template>

<script>
export default {
  name: 'FileUploadDivider'
};
</script>

<style scoped>
.divider {
  height: 1px;
  background: var(--tertiary);
  width: 100%;
  margin: 30px 00;
}
</style>

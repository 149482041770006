<template>
  <div class="container" data-cy="purchase-success-modal">
    <div class="purchase-message-container">
      <div class="img-container">
        <img src="@/assets/illustrations/successful-payment.svg" />
      </div>
      <h2 class="title">{{ title }}</h2>
      <p class="text" v-html="textMarkdown" />
      <an-button data-cy="navigate" class="button" @click="navigate">{{ ctaText }}</an-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import { confirmPayment, getCustomer } from '@/services/stigg';
import { uuid } from '@/utils/uuid';
import { mapActions, mapState } from 'vuex';
import { readCookie } from '@/utils/cookie';
import { capitalizePlan } from '@/utils/capitalizePlan';

export default {
  name: 'payment-success',
  props: {
    nextPage: {
      type: Object,
      default: () => ({ name: 'team' })
    },
    isTrial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDowngrade: false
    };
  },
  mounted() {
    const { params = {} } = this.$route;
    this.isDowngrade = this.$route.query['type'] === 'downgrade';
    confirmPayment(params.teamSlug);
    this.trackSuccess();
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      fetchTeam: 'teams/fetchOne',
      fetchMemberships: 'teamMemberships/fetchAllTeamMemberships'
    }),
    navigate() {
      const { params = {} } = this.$route;
      const { afterClosing } = this.$route.params;
      const redirect = this.nextPage ?? this.$route.params.nextPage ?? { name: 'team', params };
      this.$emit('close', { redirect, afterClosing });
    },
    async trackSuccess() {
      if (this.isDowngrade) {
        this.$trackEvent('downgrade-success.page.view');
      } else {
        this.$trackEvent('payment-success.page.view');
      }

      const user = await this.getUser({ id: 'me', skipCache: true });
      const transactionId = uuid();
      const { teamSlug } = this.$route.params;
      const team = await this.fetchTeam({ id: teamSlug, params: { is_slug: true } });
      this.fetchMemberships({ parent: 'team', id: team.slug, params: { is_slug: true } });
      if (team.uses_stigg_integration) {
        const customer = await getCustomer(team.id);
        const lastSubscription = customer.getActiveSubscriptions()[0];

        const { plan, price } = lastSubscription;
        this.$gtm.trackEvent({
          event: 'purchases',
          fbEventID: `Purchase:${user.id}`,
          fbIpAddress: this.ipAddress,
          fbUserAgent: navigator.userAgent,
          fbc: readCookie('_fbc'),
          fbp: readCookie('_fbp'),
          fbCurrency: 'USD',
          fbValue: price?.amount,
          event_category: 'Purchases',
          event_action: user?.role,
          event_label: user?.email,
          transaction_id: transactionId,
          external_id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email
        });
        const eventProps = {
          interval: price?.billingPeriod,
          number_of_contributors: price?.feature?.unitQuantity || 1,
          teamId: team.id,
          planId: plan.id,
          planName: capitalizePlan(plan.displayName),
          planPrice: price?.amount,
          triggerComponent: 'Stigg'
        };
        if (plan.id !== 'plan-anima-free') {
          this.$trackEvent('payment.purchase.success', eventProps);
        }
      }
      if (!this.isDowngrade) {
        EventBus.$emit('confetti');
      }
    }
  },
  computed: {
    ...mapState('webappSystem', ['ipAddress']),
    title() {
      if (this.isTrial) {
        return 'A 7 day trial started';
      }
      if (this.isDowngrade) {
        return 'Downgrade complete!';
      }
      return 'Upgrade complete!';
    },
    textMarkdown() {
      const purchaseText = 'Thank you! Your Anima plan was successfully upgraded.';
      const downgradeText = 'Thank you! Your Anima plan was successfully downgraded.';
      const trialText = `${purchaseText}<br />You can cancel before the trial period ends`;
      if (this.isTrial) {
        return trialText;
      }
      if (this.isDowngrade) {
        return downgradeText;
      }
      return purchaseText;
    },
    ctaText() {
      let target = 'projects';
      switch (this.$route.params.source) {
        case 'omniview':
          target = 'Get code';
          break;
        case 'omniview_get_pro':
          target = 'project';
          break;
        case 'figma':
          target = 'Figma';
          break;
        default:
          break;
      }
      return `Back to ${target}`;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.container {
  height: 100vh;
  margin-top: auto;
}

.img-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(10px);
  width: 268px;
  height: 221px;
}

img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}

.title {
  @include headline;
  margin-top: 65px;
}

.text {
  margin-top: 20px;
}

.button {
  margin-top: 40px;
}
</style>

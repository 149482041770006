<template>
  <transition name="codeFeedback">
    <div v-if="show" class="feedback">
      <div class="wrapper">
        <div @click="handleClose" class="close">
          <svg-icon :size="20" name="close" fill="currentColor"></svg-icon>
        </div>

        <template v-if="step == 'rate'">
          <div class="title">How would you rate the code quality?</div>
          <div data-cy="feedback-ratings" class="numbers flex items-center">
            <div @click="rate(n)" class="number" v-for="n in 10" :key="n">
              {{ n }}
            </div>
          </div>
          <div class="text flex items-center">
            <div>Low</div>
            <div style="margin-left: auto">High</div>
          </div>
        </template>
        <template v-if="step == 'msg'">
          <div class="title">
            You chose <span data-cy="chosen-rating" class="rating">{{ this.rating }}</span>
            <an-link variant="primary" data-cy="change-rating" @click="step = 'rate'">&nbsp;(change)</an-link>. What
            could we do to improve your experience?
          </div>
          <an-textarea
            style="width: 100%"
            @enter="handleFeedbackMsg"
            v-model="feedbackText"
            :className="'code-feedback-textarea'"
            autoFocus
            placeholder="Add your feedback here"
            data-cy="feedback-textarea"
          />
          <button data-cy="submit-feedback" @click="handleFeedbackMsg" class="submit-btn">Submit</button>
        </template>
        <template v-if="step == 'done'">
          <div data-cy="feedback-thank-you" class="flex items-center justify-center" style="height: 100%; width: 100%">
            Thank you !
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import { toastError } from '@/services/bus';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
  props: ['show', 'channelFeedback'],
  data() {
    return {
      step: 'rate',
      rating: -1,
      feedbackText: '',
      feedbackAlreadySent: false
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' })
  },
  methods: {
    rate(n) {
      this.rating = n;
      this.$trackEvent('omniview-code-rating-clicked', { rating: n });
      this.step = 'msg';
    },
    done() {
      this.$emit('close');
      this.step = 'rate';
      this.rating = -1;
      localStorage.setItem('lastFeedbackTimestamp', Date.now().toString());
    },
    handleClose() {
      if (this.show) {
        if (this.feedbackAlreadySent || this.rating == -1) {
          this.done();
          return;
        }
        // if the user only rated and closed the popup send the rating
        this.$trackEvent('omniview.clean-code-rating', {
          rating: this.rating
        });

        this.done();
      }
    },
    async handleFeedbackMsg() {
      const feedback = this.feedbackText.trim() + ` (rating: ${this.rating})`;
      try {
        const data = {
          feedback,
          source: 'Code Panel',
          email: this.currentUser.email,
          channel: this.channelFeedback || 'feedback'
        };
        this.$trackEvent('omniview.clean-code-rating', {
          rating: this.rating
        });
        this.feedbackAlreadySent = true;

        this.step = 'done';
        setTimeout(() => {
          this.done();
        }, 1500);
        if (!window.Cypress) {
          await axios.post('/feedback', data);
        }
      } catch (e) {
        toastError('Something went wrong on our side... Try again?');
        this.done();
      } finally {
        this.feedbackText = '';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.feedback {
  background: white;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
  border-radius: 10px;
  z-index: 10;
}
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close {
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
.title {
  margin-bottom: 12px;
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 700;
  span {
    font-weight: 900;
    font-style: 16px;
  }
  button {
    color: var(--primary);
    font-size: 12px;
  }
}
.numbers {
  margin-bottom: 8px;
}
.text {
  font-size: 14px;
  width: 100%;
  color: #3b3b3b;
  opacity: 0.4;
}
.number {
  width: 32px;
  height: 32px;
  border: 1px solid #a1a1a1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b3b3b;
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 8px;
  }
  &:hover {
    color: white;
    background: var(--primary);
    cursor: pointer;
  }
}

.submit-btn {
  background: var(--primary);
  border: 1px solid;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 16px;
  color: white;
  transition: background 0.2s;
  padding: 5px 15px;
  font-size: 14px;
  margin-top: 12px;
  transition: opacity 0.2s;
  cursor: pointer;
}
</style>

<style lang="scss">
.code-feedback-textarea {
  background: white;
  border-radius: 3px;
  padding: 8px 24px;
  border: 1px solid;
  border-color: var(--primary);
  min-height: 85px;
  color: #3b3b3b;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0;
  }

  &::placeholder {
    font-size: 14px;
    color: #3b3b3b;
    opacity: 0.4;
  }
}
</style>

import { render, staticRenderFns } from "./Overrides.vue?vue&type=template&id=30481c37&scoped=true&"
import script from "./Overrides.vue?vue&type=script&lang=js&"
export * from "./Overrides.vue?vue&type=script&lang=js&"
import style0 from "./Overrides.vue?vue&type=style&index=0&id=30481c37&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30481c37",
  null
  
)

export default component.exports
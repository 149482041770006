<template>
  <div class="container">
    <div class="title">Anima Plugin</div>
    <div class="subtitle">Download Anima plugin and start syncing your design</div>
    <div class="body">
      <div class="plugins-container">
        <div v-for="plugin in plugins" :key="plugin.name" class="plugin-box">
          <div class="plugin-icon">
            <svg-icon :name="plugin.icon" :size="100" />
          </div>
          <div class="plugin-title">Anima for {{ plugin.name }}</div>
          <div class="plugin-description">
            Sync your {{ plugin.name }} designs to Anima, share outstanding prototypes &amp; convert your design to
            HTML, React, Vue &amp; CSS code.
          </div>
          <div class="learn-more-link">
            <an-link
              variant="primary"
              :href="plugin.learnUrl"
              @click.native="$trackEvent('plugins.learn-more.click', { plugin: plugin.name })"
              target="_blank"
            >
              Learn more
            </an-link>
          </div>
          <div class="download-button">
            <an-button @click="navigateToDownload(plugin)" variant="primary">Install plugin</an-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'plugins',
  data() {
    return {
      plugins: [
        {
          icon: 'plugin-figma',
          name: 'Figma',
          learnUrl: this.getLearnMoreUrl('figma'),
          downloadUrl: 'https://www.figma.com/community/plugin/857346721138427857/Anima'
        },
        {
          icon: 'plugin-sketch',
          name: 'Sketch',
          learnUrl: this.getLearnMoreUrl('sketch'),
          downloadUrl: 'https://animaapp.s3.amazonaws.com/sketchplugin/toolkit/AnimaToolkitPlugin.zip'
        },
        {
          icon: 'plugin-xd',
          name: 'XD',
          learnUrl: this.getLearnMoreUrl('adobe-xd'),
          downloadUrl: 'https://adobe.com/go/xd_plugins_discover_plugin?pluginId=542412cd'
        }
      ]
    };
  },
  computed: mapState('users', { user: 'currentItem' }),
  methods: {
    getLearnMoreUrl(plugin) {
      return `https://docs.animaapp.com/v3/${plugin}/getting-started`;
    },
    navigateToDownload({ name, downloadUrl }) {
      this.$trackEvent('plugins.download.click', { plugin: name });
      this.$gtm.trackEvent({
        event: `download_${name.toLowerCase()}_plugin`,
        event_category: 'Download',
        event_action: `${name} Plugin`,
        event_label: this.user?.role
      });
      window.open(downloadUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_mixins.scss';
.body {
  width: auto;
}
.plugins-container {
  display: flex;
}
.plugin-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 340px;
  height: 422px;
  + .plugin-box {
    margin-left: 40px;
  }
  .plugin-title {
    @include secondary-title;
    font-size: 28px;
    line-height: 40px;
  }
  .plugin-description {
    text-align: center;
  }
}
</style>

<template>
  <div class="top-bar-container">
    <div class="left-hand">
      <div v-if="isMobile && showBurger" class="burger" @click="$emit('open-sidebar')">
        <svg-icon name="mobile-burger" :size="30" />
      </div>
    </div>
    <div class="center">
      <div v-if="isMobile" class="logo">
        <an-logo />
      </div>
    </div>
    <div class="right-hand">
      <div class="top-bar-item" v-if="isDesktop">
        <an-link @click="upgrade" variant="primary" class="thick-af" v-if="showUpgrade"> {{ upgradeCTA }} </an-link>
      </div>
      <transition name="fade">
        <div class="top-bar-item" v-if="shouldShowPersonalizedOnboarding">
          <PersonalizedOnboardingInlineProgress :userOnboarding="userOnboarding" @click="openOnboardingSidebar" />
        </div>
      </transition>
      <div class="top-bar-item" />
      <div class="top-bar-item" v-if="0">
        <AnnouncementsPopover :iconColor="iconColor" />
      </div>
      <div class="top-bar-item" v-show="isDesktop">
        <NotificationsPopover :iconColor="iconColor" />
      </div>
      <div class="top-bar-item" v-show="isDesktop">
        <AccountPopover />
      </div>
      <div class="headway-changelog"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AccountPopover from '@/components/Account/AccountPopover';
import AnnouncementsPopover from '@/components/Announcements/AnnouncementsPopover';
import NotificationsPopover from '@/components/Notifications/NotificationsPopover';
import PersonalizedOnboardingInlineProgress from '@/components/PersonalizedOnboarding/InlineProgress';
import { EventBus } from '@/services/bus';
import { isEmpty } from 'lodash-es';

export default {
  props: {
    showBurger: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PersonalizedOnboardingInlineProgress,
    AccountPopover,
    AnnouncementsPopover,
    NotificationsPopover
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    ...mapGetters({
      onboardingDetails: 'webappSystem/onboardingDetails',
      isPersonalizedOnboardingOpen: 'webappSystem/isPersonalizedOnboardingOpen',
      isTeamTrialSupported: 'teams/isTeamTrialSupported'
    }),
    showUpgrade() {
      if (this.$route.name == 'shared' || !this.teamMembership.id || !!process.env.ORGANIZATION_NAME) {
        return false;
      }
      return (
        !this.teamMembership.team_plan ||
        this.teamMembership?.team_plan?.toLocaleLowerCase() === 'free' ||
        this.teamMembership?.team_plan?.toLocaleLowerCase() === 'trial'
      );
    },
    upgradeCTA() {
      if (this.isTeamTrialSupported) return 'Start free trial';
      return 'Upgrade';
    },
    iconColor() {
      return `var(--${this.isMobile ? 'white' : 'secondary'})`;
    },
    shouldShowPersonalizedOnboarding() {
      return (
        this.isDesktop &&
        !isEmpty(this.userOnboarding) &&
        this.userOnboarding.status !== 'done' &&
        !this.isPersonalizedOnboardingOpen
      );
    }
  },
  mounted() {
    window.Headway?.init({
      selector: '.headway-changelog',
      account: process.env.HEADWAY_ACCOUNT_ID
    });
  },
  methods: {
    upgrade() {
      this.$trackEvent('top-nav-bar.upgrade.click');
      if (this.isTrialSupported) {
        const query = {
          interval: 'annually',
          plan: 'pro'
        };
        return this.$router.push({ name: 'team-payment', query });
      } else {
        this.$router.push({ name: 'team-pricing' });
      }
    },
    openOnboardingSidebar() {
      this.$trackEvent('top-nav-bar.personalized-onboarding-progress.click');
      EventBus.$emit('open-onboarding-sidebar');
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: #ffffff;
  height: var(--top-bar-height);
  width: 100%;
  padding: 0 40px;
  @include mobile {
    color: #ffffff;
    background: var(--secondary);
    padding: 0 16px;
    width: 100%;
  }
}
.right-hand,
.left-hand {
  display: flex;
  align-items: center;
  position: relative;
}
.right-hand {
  justify-content: flex-end;
  @include mobile {
    width: 30px;
  }
}
.burger {
  position: absolute;
  left: 0;
}
.center {
  justify-content: center;
}
.left-hand {
  justify-content: flex-start;
}
.top-bar-item {
  margin-left: 16px;
  display: flex;
  align-items: center;
  .thick-af {
    font-weight: 600;
  }
}
.logo {
  margin-left: 20px;
}
</style>

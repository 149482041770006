import { hideBanner, showBanner } from '@/services/bus';

export const showAppStatusBanner = (props) =>
  showBanner({
    name: 'app-status-banner',
    variant: 'fail',
    ...props
  });

export const showUpgradeTeamBanner = () =>
  showBanner({
    name: 'upgrade-team-plan-banner',
    variant: 'fail'
  });

export const showUpdatePaymentBanner = (props) =>
  showBanner({
    name: 'update-payment-banner',
    variant: 'fail',
    ...props
  });

export const hideUpgradeTeamBanner = () => hideBanner({ name: 'upgrade-team-plan-banner' });
export const hideAppStatusBanner = () => hideBanner({ name: 'app-status-banner' });
export const hideUpdatePaymentBanner = () => hideBanner({ name: 'update-payment-banner' });

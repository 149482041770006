import { render, staticRenderFns } from "./SyncHeader.vue?vue&type=template&id=e4603994&scoped=true&"
import script from "./SyncHeader.vue?vue&type=script&lang=js&"
export * from "./SyncHeader.vue?vue&type=script&lang=js&"
import style0 from "./SyncHeader.vue?vue&type=style&index=0&id=e4603994&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4603994",
  null
  
)

export default component.exports
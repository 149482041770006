<template>
  <div class="sub-stage-container">
    <div :class="['header', { clickable: hasContent, done, selected }]" @click="onClickHeader">
      <div :class="['dot', { done, selected }]" />
      <div :class="['title', { underline: hasAction }]">{{ title }}</div>
    </div>
    <transition name="accordion" mode="out-in">
      <div v-if="selected && hasContent" class="body">
        <div class="text" v-html="text" v-if="text" />
        <div class="image" v-if="imgSrc">
          <img :src="imgSrc" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';

export default {
  props: {
    stage: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      return this.stage.title;
    },
    text() {
      return this.stage.text;
    },
    imgSrc() {
      return this.stage.image_src;
    },
    hasContent() {
      return this.imgSrc || this.text;
    },
    hasAction() {
      isEmpty;
      return !!this.stage.data?.action && !isEmpty(this.stage.data.data);
    },
    done() {
      return this.stage.data?.is_done;
    }
  },
  methods: {
    onClickHeader() {
      let event = 'click:header';
      if (this.hasAction) {
        event = 'click:action';
      }
      this.$emit(event, this.stage);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding: 6px 0;
  &.done:not(.selected) {
    opacity: 0.6;
  }
}
.dot {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid var(--secondary);
  background-color: white;
  transition: all 0.2s ease;
  &.done {
    background-color: var(--green);
    border-color: white;
  }
  &.selected:not(.done) {
    background-color: var(--yellow);
  }
}
.title {
  margin-left: 10px;
  &.underline {
    text-decoration: underline;
    cursor: pointer;
  }
}
.clickable {
  cursor: pointer;
}
.body {
  margin-top: 6px;
  padding-left: 10px;
  .image {
    img {
      max-width: 100%;
    }
  }
}
</style>

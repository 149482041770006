<template>
  <div class="horizontal-card" @mouseover="showButton = true" @mouseleave="showButton = false" :data-cy="dataCy">
    <div class="card-body">
      <!-- <div class="image" :style="imageStyle" /> -->
      <div class="content" :class="disabledClass">
        <div class="title">{{ title }}</div>
        <div>{{ text }}</div>
      </div>
    </div>
    <div class="disabled-text" v-if="disabled && disabledText">{{ disabledText }}</div>
    <div v-if="!disabled && showButton">
      <an-button variant="secondary" @click="$emit('click')">{{ ctaText }}</an-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledText: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: '@/assets/illustrations/falling-woman.png'
    },
    ctaText: {
      type: String,
      default: 'Edit'
    },
    dataCy: {
      type: String
    }
  },
  methods: {
    onClick() {
      const { disabled } = this;
      if (!disabled) this.$emit('click');
    }
  },
  computed: {
    disabledClass() {
      const { disabled } = this;
      return {
        disabled
      };
    },
    imageStyle() {
      const background = `url('${require(`@/assets/illustrations/falling-woman.png`)}') no-repeat`;
      return {
        width: '105px',
        height: '60px',
        background,
        'background-position': 'center center',
        backgroundSize: 'contain'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.horizontal-card {
  width: 100%;
  padding: 0 30px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}
.horizontal-card + .horizontal-card {
  padding-top: 30px;
  border-top: var(--border);
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
.card-body {
  display: flex;
}
.image {
  height: 60px;
  width: 60px;
  flex-shrink: 0;
  background-color: #eae9e9;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .title {
    @include secondary-title;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>

import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'consent',
  modelName: 'consent',
  state: {
    adStorageConsent: localStorage.getItem('ad_storage') || 'denied',
    analyticsStorageConsent: localStorage.getItem('analytics_storage') || 'denied',
    adUserDataConsent: localStorage.getItem('ad_user_data') || 'denied',
    adPersonalizationConsent: localStorage.getItem('ad_personalization') || 'denied',
    hasConsent: localStorage.getItem('hasConsent'),
    consentClosed: false
  },
  mutations: {
    setAdStorageConsent(state, consent) {
      state.adStorageConsent = consent;
      localStorage.setItem('ad_storage', consent);
    },
    setAnalyticsStorageConsent(state, consent) {
      state.analyticsStorageConsent = consent;
      localStorage.setItem('analytics_storage', consent);
    },
    setAdUserDataConsent(state, consent) {
      state.adUserDataConsent = consent;
      localStorage.setItem('ad_user_data', consent);
    },
    setAdPersonalizationConsent(state, consent) {
      state.adPersonalizationConsent = consent;
      localStorage.setItem('ad_personalization', consent);
    },
    setHasConsent(state, hasConsent) {
      state.hasConsent = hasConsent;
      localStorage.setItem('hasConsent', hasConsent);
    },
    setConsentClosed(state, consentClosed) {
      state.consentClosed = consentClosed;
    }
  },
  actions: {
    grantConsent({ commit }) {
      commit('setAdStorageConsent', 'granted');
      commit('setAnalyticsStorageConsent', 'granted');
      commit('setAdUserDataConsent', 'granted');
      commit('setAdPersonalizationConsent', 'granted');
      commit('setHasConsent', true);
      commit('setConsentClosed', true);
    },
    denyConsent({ commit }) {
      commit('setAdStorageConsent', 'denied');
      commit('setAnalyticsStorageConsent', 'denied');
      commit('setAdUserDataConsent', 'denied');
      commit('setAdPersonalizationConsent', 'denied');
      commit('setHasConsent', false);
      commit('setConsentClosed', true);
    }
  },
  getters: {
    consentData: (state) => ({
      ad_storage: state.hasConsent ? state.adStorageConsent : 'denied',
      analytics_storage: state.hasConsent ? state.analyticsStorageConsent : 'denied',
      ad_user_data: state.hasConsent ? state.adUserDataConsent : 'denied',
      ad_personalization: state.hasConsent ? state.adPersonalizationConsent : 'denied'
    }),
    shouldShowConsent: (state) => {
      return state.hasConsent === null && !state.consentClosed;
    }
  }
});

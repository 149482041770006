<template>
  <component @resolve="resolveComment" @change="handleViewChange" :is="commentsSubView"></component>
</template>

<script>
import { updateArrayItemById } from '@/utils/javascript';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Comments from './Comments.vue';
import Replies from './RepliesView';
import { EventBus } from '@/services/bus';
import { HIGHLIGHT_COMMENT, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import errorHandler from '@/services/errorHandler';

export default {
  name: 'comments-view',
  components: {
    Comments,
    Replies
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapGetters({
      currentComment: 'comments/currentComment',
      breakpoints: 'omniview/breakpoints',
      commentsSubView: 'omniview/commentsSubView',
      queue: 'omniview/queue'
    })
  },
  mounted() {
    EventBus.$on('open-comment-thread', (c) => {
      this.handleViewChange({ comment: c, view: 'replies' });
    });
  },
  methods: {
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      updateComment: 'comments/update'
    }),
    ...mapMutations({
      setComments: 'comments/setComments',
      setCurrentComment: 'comments/setCurrentComment',
      setCurrentNode: 'omniview/setCurrentNode',
      setCommentsSubView: 'omniview/setCommentsSubView'
    }),
    highlightComment(comment, view = 'comments') {
      const { component_slug } = comment;
      const { slug: currentComponentSlug, slug: currentComponentMasterSlug } = this.currentComponent;

      if (component_slug !== currentComponentSlug) {
        const commmentComponent = this.allCurrentComponents.find((e) => e.slug === component_slug);
        if (commmentComponent) {
          // check if the master slug match
          if (commmentComponent.master_slug === currentComponentMasterSlug) {
            // if the master slug matches just find the breakpoint
            let br = this.breakpoints.find((b) => {
              return b.component.id === commmentComponent.id;
            });
            if (br) {
              EventBus.$emit('handleBreakpointChange', br);
            }
          } else {
            // otherwise switch the component
            EventBus.$emit('handleScreenChange', {
              component: commmentComponent
            });
            // we should wait fot the load event
            const job = () => {
              EventBus.$emit(SEND_MESSAGE, {
                action: HIGHLIGHT_COMMENT,
                data: {
                  nodeId: comment.node_id,
                  view
                }
              });
            };
            this.queue.enqueue(job);
          }
        } else {
          // this commnet is an orphan
        }
      }
      EventBus.$emit(SEND_MESSAGE, {
        action: HIGHLIGHT_COMMENT,
        data: {
          nodeId: comment.node_id,
          view
        }
      });
    },
    handleViewChange({ view, comment }) {
      this.setCurrentComment(comment);
      this.setCommentsSubView(view);

      if (view === 'replies') {
        this.highlightComment(comment, view);
      }
      // if (view == 'comments') {
      // }
    },
    async resolveComment(comment) {
      try {
        const newFakeComments = updateArrayItemById(this.comments, comment?.id, {
          status: 'RESOLVED'
        });
        this.setComments(newFakeComments);

        if (comment?.id === this.currentComment?.id) {
          const newCurrentComment = newFakeComments.find((c) => c.id === this.currentComment?.id);
          this.setCurrentComment(newCurrentComment);
        }

        // update api call
        await this.updateComment({
          parent: 'projects',
          parentId: this.currentProject?.id,
          id: comment?.id,
          payload: {
            status: 'RESOLVED'
          }
        });
        // Refresh comments
        const { results: newComments } = await this.fetchProjectComments({
          cachePolicy: 'no-cache',
          id: this.currentProject?.id,
          params: {
            page_size: 100
          }
        });

        this.setComments(newComments);
      } catch (error) {
        errorHandler.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss">
@import './comments.scss';
</style>

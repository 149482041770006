<template>
  <div class="pro-badge">Pro</div>
</template>

<script>
export default {
  name: 'pro-badge'
};
</script>

<style lang="scss" scoped>
.pro-badge {
  width: 41px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
  line-height: 22px;
  color: white;
  background-color: var(--green);
}
</style>

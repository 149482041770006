<template>
  <header @mouseleave="handleNavigatorMouseLeave" :style="getHeaderStyle" class="header">
    <!-- NAVIGATOR -->
    <NetflixMenu @handleScreenChange="handleScreenChange" />
    <!-- LOGO -->
    <div class="header-router">
      <router-link tag="div" :to="{ name: 'team', params: { teamSlug: $route.params.teamSlug } }" class="logo">
        <an-logo type="mini" />
      </router-link>

      <!-- SCREEN -->
      <div :style="getHeaderTitleStyle" class="titles">
        <div class="project-screen">
          <router-link
            tag="div"
            :to="{ name: 'project', params: { teamSlug: $route.params.teamSlug, projectId: $route.params.projectId } }"
            class="project-title"
            >{{ currentProject.name }}</router-link
          >
          <svg-icon :size="16" class="t-icon" fill="currentColor" name="arrow-right"></svg-icon>
          <div id="netflix-menu-trigger" class="screen-title" @click="toggleNavigator({})">
            <span>{{ currentComponent.name }}</span>
            <svg-icon fill="currentColor" class="icon" :size="10" name="select-arrow-down-white" />
          </div>
        </div>
        <div v-show="!isMobile" class="info" v-tip="lastUpdateInfo">
          Screen {{ getScreenNumber }} of {{ this.currentComponents.length }}. {{ updatedAt }}
        </div>
      </div>
    </div>
    <!-- MODE -->

    <div class="mode-breakpoints" v-if="!isPlaygroundOmniView">
      <div class="mode-selector">
        <div
          :class="{ selected: activeMode.name == mode.name }"
          @click="handleModeChange({ mode })"
          class="mode-container"
          v-for="(mode, i) in currentModes"
          :key="i"
          :data-cy="mode.name"
        >
          <template>
            <svg-icon fill="currentColor" class="mode-icon" :size="20" :name="mode.icon"></svg-icon>
            <div class="mode-text">{{ mode.displayName }}</div>
          </template>
        </div>
      </div>
      <!-- BREAKPOINTS -->
      <BreakpointsSelector
        v-if="!isComponentView"
        @breakpointChange="handleBreakpointChange"
        @handleScreenChange="handleScreenChange"
      />

      <v-tip placement="bottom-center" :trigger="'click'" offset="12">
        <div class="flex items-center justify-center zoom-icon">
          <svg-icon fill="currentColor" name="select-arrow-down-white" :size="8"></svg-icon>
        </div>

        <template slot="popover">
          <div class="br-pop">
            <div class="br-pop-body">
              <ul class="zoom-options">
                <li
                  v-close-popover
                  @click="handleZoomOptionChange(op.id)"
                  :class="['zoom-option', { active: zoomOption == op.id }]"
                  v-for="op in zoomOptions"
                  :key="op.id"
                >
                  {{ op.label }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </v-tip>
    </div>

    <div class="actions">
      <v-tip :trigger="'hover'" offset="10">
        <an-button :isWorking="isPublishing" v-if="isAbleToPublish" @click="handlePublish" variant="secondary" size="sm"
          >Publish</an-button
        >

        <template slot="popover">
          <div class="publish-tip">Publish your latest changes to your custom domain.</div>
        </template>
      </v-tip>

      <an-button v-if="isPublished" @click="handleOpenCustomDomain" variant="secondary" size="sm">
        Visit site
      </an-button>
      <an-link v-else-if="!isPro" class="go-pro-button" variant="primary" @click="goPro">
        <div class="go-pro-button">
          <svg-icon name="rocket-pro" :size="20" />
          <span>Go Pro</span>
        </div>
      </an-link>
      <v-tip :trigger="'hover'" :delay="{ show: 400, hide: 0 }">
        <an-link style="margin-left: 24px; margin-top: 5px" @click="copyUrlToClipboard" data-cy="copy-link-button">
          <svg-icon name="link" fill="white" :size="24"></svg-icon>
        </an-link>
        <template slot="popover">
          <div class="br-tip">
            <div class="br-tip-title">Copy link</div>
          </div>
        </template>
      </v-tip>
      <v-tip :trigger="'hover'" :delay="{ show: 400, hide: 0 }" v-if="isPlaygroundOmniView">
        <an-link style="margin-left: 24px; margin-top: 5px" @click="handleShare" data-cy="omniview-header-share">
          <svg-icon name="share-2" fill="white" :size="18"></svg-icon>
        </an-link>
        <template slot="popover">
          <div class="br-tip">
            <div class="br-tip-title">Share</div>
          </div>
        </template>
      </v-tip>

      <div style="position: relative">
        <an-button
          @click="onClickExportCode"
          style="margin-left: 24px"
          :isWorking="isExportLoading"
          size="sm"
          variant="primary"
          class="export-button"
          data-cy="new-export-button"
          :disabled="isInvalidRoute || isProjectLocked"
        >
          Export code <svg-icon fill="currentColor" class="icon" :size="10" name="select-arrow-down-white" />
        </an-button>
        <PersonalizedOnboardingBubble v-if="showOnboardingBubble" @dismiss="dismissOnboardingBubble = false" />
      </div>
      <an-button
        v-if="!isPlaygroundOmniView"
        style="margin-left: 15px"
        @click="handleShare"
        variant="primary"
        size="sm"
        data-cy="omniview-header-share"
      >
        Share
      </an-button>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import { formatDateTimeConversational } from '@/utils/date';
import BreakpointsSelector from '@/components/OmniView/Header/BreakpointsSelector.vue';
import copy from '@/utils/copyToClp';
import { EventBus, openModal, toastError, toastSuccess } from '@/services/bus';
import { mapWidthToBreakpointText } from '@/components/OmniView/utils';
import downloadCodeMixin from '@/components/OmniView/downloadCodeMixin';
import downloadCodeMixinLegacy from '@/components/OmniView/downloadCodeMixinLegacy';
import NetflixMenu from '@/components/OmniView/Header/NetflixMenu';
import PersonalizedOnboardingBubble from '@/components/PersonalizedOnboarding/ProgressBubble.vue';
import { getCdnUrl } from '@/utils/urls';
import { TeamMixin } from '@/mixins';
import errorHandler from '@/services/errorHandler';
import { getNextScreenLimit } from '@/services/subscriptions';

export default {
  components: {
    BreakpointsSelector,
    NetflixMenu,
    PersonalizedOnboardingBubble
  },
  mixins: [downloadCodeMixin, downloadCodeMixinLegacy, TeamMixin],
  data() {
    return {
      isPublishing: false,
      codePrefsOpen: false,
      dismissOnboardingBubble: false,
      zoomOptions: [
        { id: 'normal', label: 'Zoom to 100%' },
        { id: 'fit', label: 'Zoom to fit' },
        { id: 'width', label: 'Zoom to width' }
      ]
    };
  },
  mounted() {
    window.Headway?.init({
      selector: '.headway-changelog',
      account: process.env.HEADWAY_ACCOUNT_ID
    });
    this._type = 'Flex';
    EventBus.$on('handleScreenChange', this.handleScreenChange);
    if (this.$route.query.utm_source === 'copylink') {
      if (!this.isUserDeveloper) {
        // Redirecting a non developer user to the play mode
        this.$router.replace(this.$route.path);
      }
    }
    if (this.$route.query.popup === 'next' && this.isPlaygroundOmniView) {
      this.handleWhatsNext();
    }
  },
  destroyed() {
    EventBus.$off('handleScreenChange', this.handleScreenChange);
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('components', { currentComponents: 'items' }),
    ...mapState('omniview', { compareOpacity: 'compareOpacity' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('domains', { domains: 'items' }),
    ...mapState('teamMemberships', { teamMemberships: 'currentItem' }),
    ...mapState('webappSystem', ['isBannerOpen', 'bannerHeight']),
    ...mapGetters({
      currentComponent: 'components/currentComponent',
      modes: 'omniview/modes',
      breakpoints: 'omniview/breakpoints',
      activeMode: 'omniview/activeMode',
      activeBreakpoint: 'omniview/activeBreakpoint',
      currentFrameWidth: 'omniview/currentFrameWidth',
      isPricingScreenFlowActiveExperiment: 'experiments/isPricingScreenFlowActive',
      hasPermissions: 'teamMemberships/hasPermissions',
      isExportAllowed: 'omniview/isExportAllowed',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      zoomOption: 'omniview/zoomOption',
      isComponentView: 'webComponents/isComponentView',
      isPro: 'teamMemberships/isPro',
      isUserDeveloper: 'users/isUserDeveloper',
      screenBounds: 'components/screenBounds',
      isViewer: 'teamMemberships/isViewer',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps',
      currentOnboardingStage: 'userOnboardings/currentStage',
      onboardingStatus: 'userOnboardings/onboardingStatus',
      screensLimit: 'teamMemberships/screensLimit',
      isPlaygroundOmniView: 'omniview/isPlaygroundOmniView',
      paymentStatus: 'stigg/paymentStatus'
    }),
    isRegularPro() {
      return this.isPro && !this.team?.uses_stigg_integration;
    },

    currentModes() {
      return [];
    },

    generateShareUrl() {
      const { model_id: layer, slug: screenSlug } = this.currentComponent;
      const { short_id: projectId, slug: teamSlug } = this.currentProject;
      const path = {
        name: 'omniview',
        params: { teamSlug, projectId, screenSlug },
        query: { mode: 'code', layer: layer, utm_source: 'copylink' }
      };
      const resolved = this.$router.resolve(path);
      return `${process.env.APP_BASE_URL}${resolved.href}`;
    },
    getHeaderTitleStyle() {
      return {
        'font-size': this.isMobile ? '12px' : '14px'
      };
    },
    isInvalidRoute() {
      const { screenSlug } = this.$route.params;
      if (screenSlug === 'null') {
        return true;
      }
      return false;
    },
    isProjectLocked() {
      const { is_locked: isLocked } = this.currentProject;
      return isLocked;
    },
    getHeaderStyle() {
      const style = {};
      if (this.isBannerOpen) {
        style.top = `${this.bannerHeight}px`;
      }
      return {
        ...style,
        height: this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)',
        'min-height': this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)',
        transition: 'all 0.2s ease'
      };
    },
    isAbleToPublish() {
      const { domain_project_release, live_project_release } = this.currentProject;
      return this.domains.length > 0 && live_project_release != domain_project_release;
    },
    isPublished() {
      const { domain_project_release, live_project_release } = this.currentProject;
      return this.domains.length > 0 && live_project_release == domain_project_release;
    },
    isStorybookReady() {
      return this.currentProjectRelease.storybook_preview_status === 'ready';
    },
    storybookMessage() {
      switch (this.currentProjectRelease.storybook_preview_status) {
        case 'failed':
          return 'Failed to generate Storybook';
        case 'ready':
          return 'Your automatically generated Storybook';
        default:
          return this.currentProjectRelease.is_react_preview
            ? 'Generating your Storybook'
            : 'Storybook feature unavailable (please re-sync)';
      }
    },
    getScreenNumber() {
      const index = this.currentComponents.map((c) => c.id).indexOf(this.currentComponent.id);
      if (index == -1) {
        return 1;
      }
      return index + 1;
    },
    lastUpdateInfo() {
      if (this.currentComponent.plugin_name && this.currentComponent.owner_name) {
        return `Last updated by ${this.currentComponent.owner_name} from ${this.currentComponent.plugin_name}`;
      } else {
        return null;
      }
    },
    updatedAt() {
      return this.currentComponent.updated_at
        ? 'Last updated ' + formatDateTimeConversational(this.currentComponent.updated_at, true)
        : '';
    },
    isExportLoading() {
      return this.exportCodeLoading;
    },
    showOnboardingBubble() {
      const { currentOnboardingStage, dismissOnboardingBubble } = this;
      const isExportCodeStage = currentOnboardingStage?.slug === 'export-code';
      const isInProgress = this.onboardingStatus === 'in-progress';
      return isExportCodeStage && isInProgress && !dismissOnboardingBubble;
    }
  },
  methods: {
    mapWidthToBreakpointText,
    ...mapMutations({
      setCompareOpacity: 'omniview/setCompareOpacity',
      selectScreen: 'components/setCurrentItem',
      setCodeDownloadPrefs: 'omniview/setCodeDownloadPrefs',
      setZoomOption: 'omniview/setZoomOption'
    }),
    ...mapActions({
      resetSelection: 'omniview/resetSelection',
      handleModeChange: 'omniview/handleModeChange',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    handleZoomOptionChange(option) {
      const rs = document.querySelector('#resizable-iframe');
      const { homepageScreen } = this.screenBounds;

      rs && rs.style.setProperty('--width', option === 'normal' ? homepageScreen?.width + 'px' : '100%');
      setTimeout(() => {
        EventBus.$emit('update-offset');
      }, 300);

      this.setZoomOption({
        option,
        save: true
      });

      this.$trackEvent('omniview.zoom-option.changed', { option });
    },
    onClickExportCode() {
      this.dismissOnboardingBubble = true;

      if (this.team?.uses_stigg_integration) {
        if (this.paymentStatus?.status === 'REQUIRED') {
          openModal({
            name: 'update-payment-modal',
            variant: 'center',
            width: 500,
            closeButton: true,
            mode: 'dark',
            props: { paymentUrl: this.paymentStatus?.paymentUrl }
          });
          return;
        }
      }
      EventBus.$emit('OPEN_EXPORT_PANEL');
    },
    handleDownloadCodePopup() {
      const { projectId, teamSlug, screenSlug } = this.$route.params;
      if (screenSlug === 'null') {
        toastError("Couldn't find the project screens. Please try to resync the project.");
        setTimeout(() => {
          this.$router.replace({ name: 'project', params: { teamSlug, projectId } }, () => {});
        }, 1000);

        return;
      }
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.export-code.button-clicked', eventPayload);
      if (this.isPro && this.isViewer) {
        openModal({
          name: 'export-code-as-viewer',
          variant: 'center',
          opacity: 0.3,
          mode: 'dark',
          whiteOverlay: true,
          background: '#2d2d2d',
          width: 500,
          closeButton: true
        });
      } else {
        openModal({
          name: 'export-code',
          variant: 'center',
          opacity: 0.3,
          mode: 'dark',
          whiteOverlay: true,
          background: '#2d2d2d',
          width: 500,
          closeButton: true,
          onClose: () => this.nextOnboardingStage({ currentStageSlug: 'export-code' }),
          props: {
            eventSource: 'omniview',
            upgradePlanPageParams: {
              source: 'omniview',
              nextPage: this.$route,
              afterClosing: this.handleDownloadCodePopup
            }
          }
        });
      }

      this.$gtm.trackEvent({
        event: 'export_code_button',
        event_category: 'Export Code Button',
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: this.codeDownloadPrefs.framework
      });
    },
    goPro() {
      const nextScreenOption = getNextScreenLimit(this.team.projects_components_count) || 'talk';
      const query = {
        interval: 'annually',
        ...(this.isPricingScreenFlowActiveExperiment
          ? {
              plan: 'proScreens',
              screen_qty: nextScreenOption
            }
          : {
              plan: 'pro'
            })
      };
      this.$router.push({
        name: 'team-payment',
        query,
        params: {
          source: 'omniview_get_pro',
          nextPage: this.$route
        }
      });
      this.$trackEvent('omniview.header-upgrade-button.click');
    },
    handleNavigatorMouseLeave(e) {
      if (e.toElement && !this.$el.contains(e.toElement) && ![...e.toElement.classList].includes('tooltip-inner')) {
        this.toggleNavigator({ forceClose: true });
      }
    },
    toggleNavigator(params = {}) {
      EventBus.$emit('toggle-netflix-memu', params);
    },
    async handlePublish() {
      if (this.isPublishing) return;
      try {
        const { projectId } = this.$route.params;
        const { live_project_release } = this.currentProject;
        this.isPublishing = true;
        await this.updateProject({
          id: projectId,
          payload: {
            is_public: true,
            domain_project_release: {
              type: 'project_releases',
              id: live_project_release
            }
          }
        });
        await this.fetchProject({ id: projectId, skipCache: true });
      } catch (error) {
        errorHandler.captureException(error);
      } finally {
        this.isPublishing = false;
      }
    },
    handleOpenCustomDomain() {
      const url = 'http://' + this.domains[0].domain;
      const win = window.open(url, '_blank');
      win.focus();
    },
    handleShare() {
      this.$trackEvent('omniview.share-button.click');
      openModal({
        name: 'project-invite',
        props: { eventSource: 'omniview' },
        onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
      });
    },
    changeMode(mode) {
      const eventName = `omniview.${mode === 'code' ? 'code' : 'preview'}.click`;
      if (mode === 'code' || mode === 'play') {
        this.$trackEvent(eventName);
      }
      let newRoute = {
        ...this.$router.currentRoute,
        query: { ...this.$router.currentRoute.query, mode }
      };
      this.$router.push(newRoute);
    },
    handleWhatsNext() {
      this.$trackEvent('omniview.whats-next.show');
      openModal({
        name: 'whats-next',
        props: { eventSource: 'omniview' },
        onCloseRedirect: {
          name: this.$route.query.back ? 'project' : 'omniview',
          params: { ...this.$route.params },
          query: { ...this.$route.query }
        }
      });
    },
    handleBreakpointChange(e) {
      this.$emit('breakpointChange', e);
    },
    handleScreenChange({ component = null, screenSlug = '', fetchBreakpoints = true, callbackEvent = false }) {
      const { projectId } = this.$route.params;
      const { mode } = this.$route.query;

      if (!component && screenSlug) {
        component = this.currentComponents.find((c) => c.slug == screenSlug);
      }

      if (component.is_locked) {
        this.$trackEvent('omniview.locked-screen-paywall.show');
        const content = this.isPro
          ? 'Your team has reached the screens limit on the current plan.'
          : 'Your team has run out of screen on the free plan.\nUpgrade to unlock more!';
        this.openUpgradeLockedScreensModal({
          content,
          title: 'Unlock more screens',
          ctaTitle: this.isRegularPro ? 'Schedule a call' : 'See plans',
          illustrationType: this.isPro ? 'gauge' : 'clock',
          calendlyLink: this.isRegularPro
            ? 'https://calendly.com/anima-demo/adjust-plan?source=anima-web&banner=pro-screens&type=modal'
            : null
        });
        return;
      }

      this.resetSelection().then(() => {
        this.$router
          .replace({
            name: 'omniview',
            params: { projectId: projectId, screenSlug: component.slug },
            query: { mode: mode ? mode : 'play' }
          })
          .then(() => {
            this.selectScreen(component);
            EventBus.$emit('update-layer-whiteList');
            this.$emit('screenChange', {
              fetchBreakpoints
            });
            if (callbackEvent) {
              EventBus.$emit(callbackEvent.name, callbackEvent.params);
            }
          })
          .catch(() => {});
      });
    },

    getScreenThumb(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      let thumb = thumbnails_urls && thumbnails_urls['640x640'];
      thumb = getCdnUrl(thumb || thumb_url || thumbnail_url, { width: 160, height: 90 });
      return {
        'background-image': `url('${thumb}')`
      };
    },
    copyUrlToClipboard() {
      this.$trackEvent('omniview.copy-link-button.click');
      copy(this.generateShareUrl);
      toastSuccess('The link to this screen was copied to the clipboard.');
    },
    goToStorybook() {
      this.$trackEvent('omniview.storybook-preview.click');
      const url = `https://${this.currentProject.subdomain}-storybook.animaapp.io`;
      window.open(url, '_blank');
    }
  },
  watch: {
    activeMode({ displayName } = {}) {
      if (displayName) this.$trackEvent('omniview.mode.switch', { mode: displayName });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Header';

.publish-tip {
  width: 218px;
  background: #333333;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  color: white;
  border-radius: 10px;
  padding: 20px;
}

.go-pro-button {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 4px;
  }
}

.br-tip {
  margin-right: 20px;
  max-width: 207px;
  padding: 10px;
  background: #333333;
  color: white;
  border-radius: 10px;
}

.br-tip-title {
  font-size: 14px;
}

.titles {
  .project-title {
    padding-right: 3px;
  }

  .screen-title span {
    padding-right: 3px;
  }

  .info {
    font-size: 12px;
  }

  .project-screen {
    max-width: 260px;
  }

  .project-screen .icon {
    margin: 8px 5px 0 5px;
    opacity: 1;
  }

  .project-screen .t-icon {
    opacity: 1;
    margin: 0;
  }
}

.br-pop {
  background: #3b3b3b;
  width: auto;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);

  &-title {
    padding: 12px 30px;
    box-shadow: 0 1px 0 0 #2d2d2d;
    font-size: 14px;
  }

  &-body {
    font-size: 12px;
    color: rgba(#fff, 0.4);

    .learn-more {
      color: var(--primary);
      margin-left: auto;
    }
  }
}

.zoom-icon {
  color: rgba(#fff, 0.3);
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
    color: #fff;
  }
}

.zoom-options {
  padding: 12px 30px;
  display: flex;
  flex-direction: column;

  .zoom-option {
    font-size: 14px;
    color: white;
    margin-bottom: 12px;

    &:hover {
      color: var(--primary);
      cursor: pointer;
    }

    &.active {
      color: var(--primary);
    }
  }
}

.export-button {
  .icon {
    margin: 7px -6px 0px 7px;
  }
}

.badge {
  display: flex;
  height: 14px;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  background: var(--light-tomato-5, #ffd1c9);
  color: var(--light-tomato-11, #d43029);
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  font-family: Mulish;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 8px;
}

.header {
  justify-content: space-between;
}

.header-router {
  display: flex;
  align-items: center;
}
</style>

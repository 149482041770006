<template>
  <Mentionable
    :style="{ display: 'flex', alignItems: 'center' }"
    @stateChange="handleStateChange"
    :keys="['@']"
    :items="items"
    offset="6"
    insertSpace
  >
    <textarea
      @input="handleInput"
      @keydown="handleKeydown"
      @blur="
        $emit('blur', $event);
        focused = false;
      "
      :class="['base-textarea', className, textareaClasses]"
      :value="value"
      v-bind="$attrs"
      @focus="focused = true"
      :style="{ maxHeight: allowMultiline ? 'auto' : '42px' }"
      ref="elementRef"
    />
  </Mentionable>
</template>

<script>
import autosize from 'autosize';
import { sortBy, uniqBy } from 'lodash-es';
import Mentionable from './Mention';
import { mapState } from 'vuex';
import { uuid } from '@/utils/uuid';
export default {
  name: 'an-textarea',
  components: {
    Mentionable
  },
  props: {
    value: {
      type: String
    },
    className: {
      type: String,
      default: undefined
    },
    customMentionItems: {
      type: Boolean,
      default: false
    },
    mentionItems: {
      type: Array,
      default: () => []
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    autosize: {
      type: Boolean,
      default: true
    },
    allowMultiline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
      isMentionsOpen: false
    };
  },
  mounted() {
    const el = this.$refs.elementRef;
    if (this.autosize) {
      autosize(el);
    }
    if (this.autoFocus) {
      if (el) {
        el.focus();
      }
    }
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projectGuests', { guests: 'items' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    textareaClasses() {
      const { withBorder } = this;
      return {
        'with-border': withBorder
      };
    },
    items() {
      if (this.customMentionItems) return this.mentionItems;
      let everyone = { name: 'Everyone', color: '#66727f', id: uuid() };
      const teamMembers = (this.teamMemberships || []).map((m) => m.user);
      const guestUsers = (this.guests || []).map((o) => o.user).filter(Boolean);
      let rv = [...(teamMembers || []), ...guestUsers, this.currentUser];
      rv = sortBy(uniqBy(rv, 'name'), 'name');
      rv = [everyone, ...rv].filter((obj) => obj.name);
      return rv.map((obj) => ({ value: obj.name, label: obj.name }));
    }
  },
  methods: {
    handleStateChange(f) {
      this.isMentionsOpen = f;
    },
    focus() {
      this.$nextTick(() => {
        if (this.focused) return;
        this.$refs.elementRef.focus();
      });
    },
    updateAutosize() {
      this.$refs.elementRef.style.height = '42px';
      this.$nextTick(() => {
        autosize(this.$refs.elementRef);
      });
    },
    handleKeydown(e) {
      if (e.which == 13 && !e.shiftKey && !this.isMentionsOpen && !this.allowMultiline) {
        this.$emit('enter');
        e.preventDefault();
      }
    },
    handleInput(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.base-textarea {
  caret-color: var(--primary);
  resize: none;
  overflow: hidden !important;
  width: 100%;
  appearance: none;
  &:focus {
    outline: none;
  }
}
.with-border {
  border-radius: 10px;
  padding: 12px 22px;
  border: var(--border);
  &:focus {
    border: 1px solid var(--secondary);
  }
}
</style>
<style lang="scss">
.mention-item {
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  background-color: #333333;
  &:first-child {
    border-radius: 10px 10px 0 0;
  }
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
}

.mention-selected {
  background: #2d2d2d;
}
</style>

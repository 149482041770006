<template>
  <div class="root" data-cy="root-page">
    <div data-cy="cypress-experiment" style="display: none" v-if="isActiveCypressTest" />
    <div class="another-wrapper">
      <div class="side-bar-wrapper">
        <SidebarLayout :isOpen="isSidebarOpen" :width="sidebarWidth">
          <sidebar @close="closeSidebar" />
        </SidebarLayout>
      </div>
      <div class="content">
        <div class="top-bar-wrapper">
          <top-bar @open-sidebar="openSidebar" showBurger />
        </div>
        <div class="view-container">
          <div class="view">
            <transition :name="transitionName" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Sidebar from '@/components/Layout/Sidebar/Sidebar';
import SidebarLayout from '@/components/Layout/Sidebar/SidebarLayout';
import TopBar from '@/components/Layout/TopBar';
import { EventBus, openModal, showBanner } from '@/services/bus';
import initHubSpot from '@/services/hubspot.js';
import { io } from 'socket.io-client';
import { isDev, isTest } from '@/utils/environment';
import mixpanel from 'mixpanel-browser';
import { setToLocalStorageWithExpiry, getFromLocalStorageWithExpiry } from '@/utils/localStorage';
import * as Sentry from '@sentry/vue';

const STORYBOOK_BETA_KEY = 'sb_beta_closed';
const COMMUNITY_SURVEY_KEY = 'community_survey_closed';
const MIXPANEL_PEOPLE_SET_KEY = 'mp_det';
const USERS_FEEDBACK_INTERVIEW_KEY = 'users_feedback_interview_closed';
const PLAN_UPGRADE_NEEDED_KEY = 'plan_upgrade_needed_closed';

export default {
  name: 'root',
  data() {
    return {
      transitionName: '',
      isSidebarOpen: false,
      figmaWs: null
    };
  },
  components: {
    Sidebar,
    SidebarLayout,
    TopBar
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    ...mapState('webappSystem', ['shouldOpenOnboarding']),
    ...mapState('webappSystem', ['isPersonalizedOnboardingOpen', 'ipAddress']),
    ...mapGetters('experiments', {
      isActiveExperiment: 'isActive'
    }),
    isActiveCypressTest() {
      return this.isActiveExperiment('cypress-test');
    },
    sidebarWidth() {
      return this.isMobile ? '100vw' : null;
    }
  },
  created() {
    this.createFigmaWebsocket();
  },
  mounted() {
    this.applyTheme('light');
    if (!localStorage.getItem(PLAN_UPGRADE_NEEDED_KEY) && this.isActiveExperiment('upgrade-plan-modal')) {
      this.showPlanUpgradeNeededBanner();
    }

    EventBus.$on('open-onboarding-sidebar', this.openOnboardingSidebar);
    if (this.shouldOpenOnboarding && this.userOnboarding.status !== 'done') {
      this.openOnboardingSidebar();
    }

    this.$router.beforeEach((to, from, next) => {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      next();
    });

    this.performUglyInits();
  },
  destroyed() {
    EventBus.$off('open-onboarding-sidebar', this.openOnboardingSidebar);
    this.figmaWs = null;
    this.closeOnboardingSidebar();
  },
  methods: {
    ...mapActions({
      trackIntercomShow: 'tracking/trackIntercomShow',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    ...mapMutations({
      setIsPersonalizedOnboardingOpen: 'webappSystem/setIsPersonalizedOnboardingOpen',
      setShouldOpenOnboarding: 'webappSystem/setShouldOpenOnboarding'
    }),
    showStorybookBetaBanner() {
      const shownStorybookBetaBanner = localStorage.getItem(STORYBOOK_BETA_KEY);
      if (shownStorybookBetaBanner) return;

      showBanner({
        name: 'storybook-beta-banner',
        variant: 'info',
        onClose: () => {
          localStorage.setItem(STORYBOOK_BETA_KEY, true);
        }
      });
    },
    showCommunitySurveyBanner() {
      const shownCommunitySurveyBanner = localStorage.getItem(COMMUNITY_SURVEY_KEY);
      if (shownCommunitySurveyBanner) return;

      showBanner({
        name: 'community-survey-banner',
        variant: 'warn',
        onClose: () => localStorage.setItem(COMMUNITY_SURVEY_KEY, true)
      });
    },
    showUpgradePlanModal() {
      const shownUpgradePlanModal = getFromLocalStorageWithExpiry(USERS_FEEDBACK_INTERVIEW_KEY);
      if (shownUpgradePlanModal) return;

      openModal({
        name: 'upgrade-plan-modal',
        onClose: ({ isActionClicked }) => {
          if (isActionClicked) {
            localStorage.setItem(USERS_FEEDBACK_INTERVIEW_KEY, true);
            return;
          }
          setToLocalStorageWithExpiry(
            USERS_FEEDBACK_INTERVIEW_KEY,
            true,
            24 * 60 * 60 // 1 day
          );
        }
      });
    },
    showPlanUpgradeNeededBanner() {
      const shownPlanUpgradeNeededBanner = localStorage.getItem(PLAN_UPGRADE_NEEDED_KEY);
      if (shownPlanUpgradeNeededBanner) return;

      showBanner({
        name: 'plan-upgrade-needed-banner',
        variant: 'warn',
        onClose: () => localStorage.setItem(PLAN_UPGRADE_NEEDED_KEY, true)
      });
    },
    createFigmaWebsocket() {
      this.figmaWs = null;
      const figmaWsURL = `${process.env.FIGMA_WEBSOCKET_URL}`;
      this.figmaWs = io(figmaWsURL, {
        transports: ['websocket'],
        query: {
          userId: this.user.id
        }
      });

      this.figmaWs.on('connect', () => {
        if (1 || isDev()) {
          console.log('Figma connected');
        }
      });
      this.figmaWs.on('disconnect', () => {
        if (1 || isDev()) {
          console.log('Figma disconnected');
        }
      });
      this.figmaWs.on(`figma-ws-message`, (message) => {
        console.log(`figma-ws-message`, message);
        EventBus.$emit('on-figma-ws-message', message);
      });
    },
    performUglyInits() {
      if (!localStorage.getItem('animp')) {
        this.$intercom.boot({
          user_id: this.user.id,
          name: this.user.name,
          email: this.user.email,
          is_in_paying_team: this.user.is_in_paying_team
        });
        this.$intercom._call('onShow', this.trackIntercomShow);
        this.$tracking.setUserEmail(this.user.email);
        this.$tracking.setUserId(this.user.id);
        this.$tracking.setContext({ latest_paired_design_tool: this.user.latest_paired_design_tool });
        this.$tracking.alias(this.user.id);

        Sentry.setUser({ email: this.user.email, id: this.user.id });

        const sentMixpanelInfo = localStorage.getItem(MIXPANEL_PEOPLE_SET_KEY);
        if (!sentMixpanelInfo && this.ipAddress) {
          mixpanel.identify(this.user.id);
          mixpanel.people.set({ $ip: this.ipAddress });
          localStorage.setItem(MIXPANEL_PEOPLE_SET_KEY, true);
        }

        if (!isTest()) {
          initHubSpot();
          var _hsq = (window._hsq = window._hsq || []);
          _hsq.push([
            'identify',
            {
              email: this.user.email,
              id: this.user.id
            }
          ]);
          _hsq.push(['setPath', this.$route.path]);
          _hsq.push(['trackPageView']);
        }

        if (this.user.id) {
          if (this.$hj) {
            this.$hj('identify', this.user.id, {
              email: this.user.email
            });
          }
          if (this.$FullStory) {
            this.$FullStory.identify(this.user.id, {
              email: this.user.email
            });
          }
        }
      }
    },
    openOnboardingSidebar() {
      if (this.isDesktop) {
        const props = {
          onboardingType: this.userOnboarding?.onboarding_flow?.slug
        };
        this.$trackEvent('personalized-onboarding.panel.open', props);
        this.setIsPersonalizedOnboardingOpen(true);
        if (this.user.latest_paired_design_tool === 'Figma') {
          this.nextOnboardingStage({ currentStageSlug: 'pair-figma' });
        }
        if (this.user.latest_paired_design_tool) {
          this.nextOnboardingStage({ currentStageSlug: 'pair-plugin' });
        }
      }
    },
    closeOnboardingSidebar() {
      if (this.isPersonalizedOnboardingOpen) {
        this.setIsPersonalizedOnboardingOpen(false);
      }
    },
    openSidebar() {
      this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    }
  },
  watch: {
    isDesktop: {
      handler(value) {
        if (value) this.openSidebar();
        else this.closeSidebar();
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  display: flex;
  height: 100vh;
}
.side-bar-wrapper {
  flex-shrink: 0;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.another-wrapper {
  display: flex;
  width: 100%;
}
.view-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  margin-top: 60px;
}
.view {
  margin: 0 40px;
  height: 100%;
  width: 100%;
  max-width: 1450px;
  @media screen and (max-width: $breakpoint) and (min-width: 600px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 600px) {
    margin: 0;
  }
}
.view::after {
  content: '';
  display: block;
  height: 100px;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.top-bar-wrapper {
  position: relative;
  width: 100%;
}
</style>

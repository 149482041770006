<template>
  <div class="custom-domain-row" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="details-container" :class="{ 'is-deleted': isDeleted }">
      {{ customDomain.domain }}
    </div>
    <div class="cta">
      <div @click="$emit('remove')">
        <transition name="fade" mode="out-in">
          <an-button v-if="hover || isDeleted" variant="empty" :isWorking="isDeleted">Remove</an-button>
          <svg-icon v-else name="circle-check" :size="36" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false
    };
  },
  props: {
    customDomain: {
      type: Object,
      required: true
    },
    isDeleted: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-domain-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: var(--border);
  &:last-child {
    border-bottom: none;
  }
  div {
    display: flex;
    align-items: center;
  }
  .cta {
    cursor: pointer;
    height: 36px;
  }
}
.details-container {
  transition: opacity 1s ease;
  &.is-deleted {
    opacity: 0.3;
  }
}
</style>

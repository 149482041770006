<template>
  <div class="project-limit-wrapper">
    <div class="calendar-form">
      <Calendly url="https://calendly.com/anima-demo/adjust-plan" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Calendly from '@/components/Calendly/Calendly';

export default {
  components: {
    Calendly
  },
  mounted() {
    this.$trackEvent('omniview:project_limit_reached_calendly_popup.show');
  },
  data() {
    return {
      baseSlug: ''
    };
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      screensLimit: 'teamMemberships/screensLimit'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_mixins.scss';
.project-limit-wrapper {
  background-color: #2d2d2d;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-x: auto;
  text-align: center;
  padding-top: 15vh;
  .calendar-form {
    width: 100%;
  }
}
</style>

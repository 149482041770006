<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">Now just a minute</div>
    <div class="subtitle">
      Are you sure you want to delete your team? All your team data will be permanently deleted for all users and you’ll
      not be able to undo this action.
    </div>
    <div class="disclaimer">
      <div class="disclaimer-title">Consider other options</div>
      Only want to stop paying for this team?
      <an-link variant="primary">Change plan</an-link>
    </div>
    <div class="body">
      <Checkbox v-model="agreeDeleted" dataCy="agree-checkbox-1">
        All my team's design projects, assets and comments will be deleted.
      </Checkbox>
      <Checkbox v-model="agreeLinks" dataCy="agree-checkbox-2">
        All shared and public links will be inaccessible.
      </Checkbox>
    </div>
    <transition name="fade">
      <div class="actions" v-if="showActions">
        <an-button @click="deleteTeam" :isWorking="isWorking" data-cy="delete-team-button">Delete team</an-button>
        <an-link @click="cancel">Cancel</an-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Checkbox from '@/components/Checkbox/Checkbox';
import { EventBus, toastError } from '@/services/bus';

export default {
  name: 'team-settings-delete',
  data() {
    return {
      agreeDeleted: false,
      agreeLinks: false,
      isWorking: false
    };
  },
  components: {
    Checkbox
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    showActions() {
      return this.agreeDeleted && this.agreeLinks;
    }
  },
  methods: {
    ...mapActions({
      _deleteTeam: 'teams/delete',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships'
    }),
    async deleteTeam() {
      const { id, name } = this.team;

      this.$trackEvent('team-settings-delete.delete-team-button.click');

      try {
        this.isWorking = true;
        await this._deleteTeam({ id });

        EventBus.$emit('reload-user-memberships');

        this.$trackEvent('team-settings-delete.delete.success');

        this.$emit('close', { redirect: '/' });
      } catch (err) {
        toastError(`Failed deleting team ${name}`);
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('team-settings-delete.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.subtitle {
  margin: 20px 0 0 0;
}
</style>

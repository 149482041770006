import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'webappSystem',
  modelName: 'webappSystem',
  state: {
    adBlockerEnabled: false,
    originDevMode: false,
    intentDevMode: false,
    onboardingDetails: null,
    showDemoSuggestions: false,
    // should open in future tense
    shouldOpenOnboarding: false,
    // is currently open
    isPersonalizedOnboardingOpen: false,
    showConfettiOnDone: true,
    personalizedOnboardingProps: {
      width: 260,
      height: 'auto',
      backgroundColor: 'white',
      side: 'right',
      theme: 'light'
    },
    // banners
    isBannerOpen: false,
    bannerHeight: 60,
    // general settings
    ipAddress: null,
    showPaymentBotError: true
  },
  mutations: {
    setAdBlockerEnabled: (state, val) => (state.adBlockerEnabled = val),
    setShowDemoSuggestions: (state, val) => (state.showDemoSuggestions = val),
    setOriginDevMode: (state, val) => (state.originDevMode = val),
    setIntentDevMode: (state, val) => (state.intentDevMode = val),
    setOnboardingDetails: (state, val) => (state.onboardingDetails = val),
    setShouldOpenOnboarding: (s, val) => (s.shouldOpenOnboarding = val),
    setIsPersonalizedOnboardingOpen: (state, val) => (state.isPersonalizedOnboardingOpen = val),
    setIsBannerOpen: (state, val) => (state.isBannerOpen = val),
    setIpAddress: (state, val) => (state.ipAddress = val),
    setShowPaymentBotError: (state, val) => (state.showPaymentBotError = val),
    setBannerHeight: (state, val) => (state.bannerHeight = val),
    setShowConfettiOnDone: (state, val) => (state.showConfettiOnDone = val)
  },
  actions: {
    updateOriginDevMode({ commit }, value) {
      commit('setOriginDevMode', value);
    },
    updateIntentDevMode({ commit }, value) {
      commit('setIntentDevMode', value);
    }
  },
  getters: {
    adBlockerEnabled: (state) => state.adBlockerEnabled,
    originDevMode: (state) => state.originDevMode,
    intentDevMode: (state) => state.intentDevMode,
    onboardingDetails: (state) => state.onboardingDetails,
    showDemoSuggestions: (state) => state.showDemoSuggestions,
    isPersonalizedOnboardingOpen: (state) => state.isPersonalizedOnboardingOpen
  }
});

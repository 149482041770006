<template>
  <div :style="containerStyle">
    <Upload
      :url="url"
      :accept="accept"
      @success="$emit('success', $event)"
      @start="$emit('start', $event)"
      @failure="$emit('failure', $event)"
      :context="context"
    >
      <RoundedImage v-if="src" :src="src" :height="height" :width="width" :key="src" :circle="circle" />
      <RoundedImage slot="init" :height="height" :width="width" :circle="circle">
        <svg-icon name="plus" />
      </RoundedImage>
      <RoundedImage slot="loading" :height="height" :width="width" :circle="circle">
        <div class="bubble" :style="loadingStyle" />
      </RoundedImage>
    </Upload>
  </div>
</template>

<script>
import Upload from '@/components/Upload/Upload';
import RoundedImage from '@/components/Upload/RoundedImage';

export default {
  props: {
    src: {
      type: String,
      default: null
    },
    url: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 50
    },
    circle: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    },
    context: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Upload,
    RoundedImage
  },
  computed: {
    containerStyle() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`
      };
    },
    loadingStyle() {
      const size = Math.min(this.height, this.width);
      return {
        height: `${Math.round(0.5 * size)}px`,
        width: `${Math.round(0.5 * size)}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.bubble {
  width: 20px;
  height: 20px;
  margin: 100px auto;
  background-color: var(--primary);

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inputContainer",class:_vm.classes,style:({
    '--height': _vm.inputSizes[_vm.size] + 'px',
    '--iconContainerWidth': _vm.iconSize * 2 + 5 + 'px'
  })},[_vm._t("outter-left"),(_vm.icon)?_c('div',{staticClass:"inputIconContainer"},[_c('svg-icon',{attrs:{"size":_vm.iconSize,"name":_vm.icon}})],1):_vm._e(),_vm._t("inner-left"),_c('input',_vm._g(_vm._b({ref:"input",class:[
      'input',
      {
        invalid: _vm.invalid,
        hasIcon: _vm.icon,
        noBorder: _vm.noBorder,
        [_vm.theme]: true
      }
    ],attrs:{"type":_vm.inputType},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.hasSlot('inner-right'))?_c('div',{staticClass:"slot-inner-right"},[_vm._t("inner-right")],2):_vm._e(),(_vm.type === 'password')?_c('div',{ref:"passIcon",class:_vm.passwordIconClasses,on:{"click":_vm.toggleShowPassword}},[_c('svg-icon',{attrs:{"size":24,"name":"eye"}})],1):_vm._e(),_vm._t("outter-right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
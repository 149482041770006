<template>
  <div v-if="loadedTeam" data-cy="pricing-page" class="container">
    <h1 class="title">Choose your plan</h1>
    <div v-if="!usesStiggIntegration" class="billing-cycle-toggle">
      <an-toggle offTitle="Monthly" onTitle="Yearly" class="toggle-wrapper" v-model="isAnnual" />
      <div class="discount">
        Save 20%
        <svg-icon name="quirky-arrow" :size="36" />
      </div>
    </div>
    <div v-if="usesStiggIntegration" class="body">
      <PricingStigg theme="light" />
    </div>
    <div v-else class="body">
      <PricingColumn
        v-for="plan in plans"
        :key="plan.name"
        :planName="plan.name"
        :screenPriceOptions="plan.showScreensSelect && plan.screenQtyOptions"
        :screenPriceOptionSelected="screenQty"
        :price="getPrice(plan)"
        :priceText="getPriceText(plan)"
        :icon="plan.icon"
        :subtitle="plan.subtitle"
        :description="plan.description"
        :features="plan.features"
        :minQuantity="plan.minQuantity"
        :ctaText="getCtaText(plan)"
        :billingCycle="isAnnual ? 'annual' : 'month'"
        :isCurrentPlan="isSameAsActiveSubscription(plan)"
        :theme="theme"
        :ribbonName="plan.ribbonName"
        :trialSupported="plan.trialSupported"
        :showDetailsLink="plan.showDetailsLink"
        @click:details="scrollToDetails(plan)"
        @select-plan="onSelectPlan(plan)"
        @select-screens="onSelectScreens"
      />
    </div>

    <p v-if="!usesStiggIntegration">
      <span v-if="isPricingScreenFlowActiveExperiment">Need more screens?&nbsp;</span>
      <span v-else>Need more projects?&nbsp;</span>
      <an-link @click="onContactUsClick" target="_blank" variant="primary" :href="contactSalesUrl">
        Contact us
      </an-link>
    </p>

    <PricingFeatures
      ref="pricingFeatures"
      :theme="theme"
      :topmost="pricingFeaturesTopmost"
      @select-plan="onSelectPlan"
    />

    <div class="faq-title-wrapper">
      <h2 class="faq-title">Frequently asked questions</h2>
    </div>
    <CollapseFAQ v-for="(faq, index) in faqList" :key="index" :title="faq.title">
      <p v-for="(paragraph, pIndex) in faq.content" :key="pIndex">{{ paragraph }}</p>
    </CollapseFAQ>
  </div>
</template>

<script>
import PricingColumn from '@/components/Pricing/PricingColumn';
import PricingFeatures from '@/components/Pricing/PricingFeatures.vue';
import CollapseFAQ from '@/components/Collapse/CollapseFAQ.vue';
import { getPrice, checkDisplayedPrices, getScreenPriceOptions, getNextScreenLimit } from '@/services/subscriptions';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { pluralize } from '@/utils/language';
import PricingStigg from '@/views/PricingStigg.vue';

const getFeatures = ({
  activeFeaturesIndex = 0,
  numberOfProSeats = -1,
  numberOfProjects,
  numberOfConvertWebsite2Figma = -1
} = {}) => {
  let numberOfProSeatsText = numberOfProSeats === -1 ? 'Unlimited free' : `${numberOfProSeats} Pro`;
  const proSeatsFeature = `${numberOfProSeatsText} ${pluralize(numberOfProSeats, 'seat')} ${
    numberOfProSeats !== -1 ? 'included' : ''
  }`;
  const projectsFeature = `${numberOfProjects} ${pluralize(numberOfProjects, 'project')}`;
  const website2Figma =
    numberOfConvertWebsite2Figma === -1
      ? 'Convert website to Figma'
      : `${numberOfConvertWebsite2Figma} Convert website to Figma`;

  const newFeatures = ['Open source components', website2Figma];

  const features = [
    proSeatsFeature,
    projectsFeature,
    ...newFeatures,
    'High fidelity prototype',
    'Sharing permissions',
    'Team collaboration',
    'Export React/VueJS components',
    'Export HTML code',
    'Custom domain',
    'Support'
  ];
  if (!activeFeaturesIndex) {
    activeFeaturesIndex = features.length;
  }
  return features.map((item, index) => ({
    label: item,
    isActive: index < activeFeaturesIndex,
    isNew: newFeatures.includes(item)
  }));
};

const getProFeatures = () => {
  return [
    { label: 'Everything in Free, plus', isActive: true, hideCheckbox: true },
    { label: '1 Pro seat included', isActive: true },
    { label: 'Export React/VueJS/ HTML', isActive: true },
    { label: 'Export Open source React components', isActive: true },
    { label: 'Custom domain', isActive: true },
    { label: 'Priority support', isActive: true }
  ];
};

const getFreeFeatures = () => {
  return [
    { label: 'Unlimited free seats', isActive: true },
    { label: 'Live code prototypes', isActive: true },
    { label: 'Open source React components', isActive: true, isNew: true },
    { label: 'Email support', isActive: true },
    { label: 'Export React/VueJS/ HTML', isActive: false },
    { label: 'Custom domain', isActive: false }
  ];
};

const getCustomFeatures = () => {
  return [
    { label: 'Everything in Pro, plus', isActive: true, hideCheckbox: true },
    { label: 'Storybook integration', isActive: true, isNew: true },
    { label: 'Custom number of screens', isActive: true },
    { label: 'Custom number of seats', isActive: true },
    { label: 'Option for advanced security & SSO', isActive: true },
    { label: 'Audit logs', isActive: true }
  ];
};

export default {
  name: 'pricing',
  data() {
    return {
      faqList: [
        {
          title: 'Do you have a free plan?',
          content: [
            'Yes! You can try Anima without committing to a plan - no credit card required. Our free plan gives you the chance to evaluate whether Anima is a good fit for you.',
            'You can create a design and sync as many projects or screens as you like, and review the code for your design and your product live in an Anima-hosted environment.',
            'When you are happy with the result, you can upgrade and grab the code.'
          ]
        },
        {
          title: 'What does a limit of the number of screens mean?',
          content: [
            'A screen is a single page in your design. For example, a website with a homepage, about page, and contact page. A mobile app with a login screen, feed, and profile.',
            'Each plan provides a certain number of screens you can sync to Anima. The limit of screens is per team, not per project. For example, if you have 2 projects with 5 screens screens each, you have 10 screens in total.'
          ]
        },
        {
          title: 'What does a limit of the number of projects mean?',
          content: [
            'A project is a collection of screens that are connected to each other. For example, a website with a homepage, about page, and contact page is a project. A mobile app with a login screen, feed, and profile is a project.',
            'Each plan provides a certain number of projects you can create.'
          ]
        },
        {
          title: 'Where can I download invoices?',
          content: ['All your team invoices are available under your team billing page.']
        },
        {
          title: 'Will I pay if I invite another contributor to my team?',
          content: [
            'Each contributor you invite to your team will be added to your plan. You will be charged for each contributor. Contributor seats are $49 per month per contributor when paid monthly or $39 per month per contributor when paid annually.'
          ]
        },
        {
          title: 'Where can I see a breakdown of my team members and costs?',
          content: [
            'You can manage your team contributors and viewers from the team settings. You can add or remove team members at any time.'
          ]
        },
        {
          title: 'Can I change plans after I purchase?',
          content: [
            'Sure, go to your team settings, choose plan and billing, and change your plan. You will only be billed the difference between the plans.'
          ]
        },
        {
          title: 'How will I be charged?',
          content: [
            'Anima subscriptions renew automatically. Monthly plans are billed every month on the day it was purchased. Annual plans are billed a year from the purchase date.'
          ]
        },
        {
          title: 'What forms of payment do you accept?',
          content: ['We support payment through credit cards.']
        },
        {
          title: 'Can I cancel my plan at anytime?',
          content: ['Yes, you can cancel at any time and use your plan’s features until the end of the billing cycle.']
        },
        {
          title: 'What happens if I stop paying?',
          content: [
            "You will be automatically downgraded to the free plan. Hosted websites with custom domain will no longer work, you won't be able to export code, and you'll be limited to 1 project and 5 screens."
          ]
        }
      ],
      isAnnual: true,
      email: '',
      password: '',
      errorMessage: '',
      loading: false,
      createdTime: null,
      intervalId: null,
      contactSalesUrl: 'https://anima-storybook.typeform.com/to/Vkt8zmCd?source=anima-web&banner=pricing&type=modal',
      screenQty: null
    };
  },
  props: {
    onboardingFlow: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value)
    }
  },
  components: {
    PricingColumn,
    PricingStigg,
    PricingFeatures,
    CollapseFAQ
  },
  created() {
    this.createdTime = new Date();
  },
  mounted() {
    this.$trackEvent('pricing-page.view', { is_stigg: this.usesStiggIntegration });
    this.setInterval();
    this.setSelectedScreenOption();
    if (this.isPersonalizedOnboardingOpen) {
      this.setIsPersonalizedOnboardingOpen(false);
    }
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      isPricingScreenFlowActiveExperiment: 'experiments/isPricingScreenFlowActive',
      activeSubscription: 'stripeSubscriptions/activeSubscription',
      isTrialSupported: 'teams/isTeamTrialSupported',
      screensLimit: 'teamMemberships/screensLimit',
      isProScreenTier: 'teams/isProScreenTier'
    }),
    ...mapState('webappSystem', ['isPersonalizedOnboardingOpen']),
    ...mapGetters('experiments', ['isCustomPlanNewActive']),
    usesStiggIntegration() {
      return this.team?.uses_stigg_integration;
    },
    selectedInterval() {
      return this.isAnnual ? 'annually' : 'monthly';
    },
    isOnboardingFlow() {
      const { onboarding } = this.$route.query;
      return this.onboardingFlow || onboarding;
    },
    loadedTeam() {
      return this.team?.id;
    },
    showProScreen() {
      return this.isProScreenTier;
    },
    plans() {
      const plansData = {
        free: {
          id: 'free',
          name: 'Free',
          description: 'Ideal for individuals who build and share live code prototypes',
          subtitle: this.isActiveExperiment('limit-team-screens') ? '1 project, 5 screens' : '1 project',
          features: getFreeFeatures(),
          showDetailsLink: true
        },
        pro: {
          id: 'pro',
          name: 'Pro',
          description: 'Ideal for individuals and growing teams who wants to publish & export code',
          subtitle: this.isActiveExperiment('limit-team-screens') ? '3 projects, 15 screens' : '3 projects',
          features: getProFeatures(),
          ribbonName: 'popular-ribbon',
          showDetailsLink: true,
          trialSupported: this.isTrialSupported
        },
        proScreens: {
          id: 'proScreens',
          name: 'Pro',
          description: 'Ideal for individuals and growing teams who wants to publish & export code',
          subtitle: this.isActiveExperiment('limit-team-screens') ? '3 projects, 15 screens' : '3 projects',
          features: getProFeatures(),
          ribbonName: 'popular-ribbon',
          showDetailsLink: true,
          showScreensSelect: true,
          screenQtyOptions: this.getScreenPriceOptions('proScreens'),
          trialSupported: this.isTrialSupported
        },
        business: {
          id: 'business',
          name: 'Business',
          description: 'for teams looking to boost\n' + 'their collaboration',
          subtitle: '10 projects',
          features: getFeatures({ numberOfProjects: 10, numberOfProSeats: 3 }),
          showDetailsLink: true,
          minQuantity: 3
        },
        custom: {
          id: 'custom',
          name: this.isCustomPlanNewActive ? 'Enterprise' : 'Made for you',
          priceText: 'Custom',
          description: 'Ideal for mature teams with a design-system, or existing code',
          subtitle: 'Based on your needs',
          features: getCustomFeatures(),
          isCustom: true,
          ctaText: 'Contact us'
        }
      };
      let plansList;
      const proPlan = this.showProScreen ? plansData.proScreens : plansData.pro;

      if (this.isDesktop) {
        plansList = [plansData.free, proPlan, plansData.business, plansData.custom];
      } else {
        plansList = [proPlan, plansData.business, plansData.free, plansData.custom];
      }

      plansList = plansList.filter((plan) => plan.name !== 'Business');
      return plansList;
    },
    pricingFeaturesTopmost() {
      return this.onboardingFlow ? 80 : 0;
    }
  },
  watch: {
    screensLimit() {
      this.setSelectedScreenOption();
    },
    isPricingScreenFlowActiveExperiment() {
      this.setSelectedScreenOption();
    }
  },
  methods: {
    getQueryFromUrl(url) {
      const urlSplited = url.split('?');
      urlSplited.shift();
      if (!urlSplited) {
        return '';
      }
      return urlSplited[0];
    },
    ...mapActions({
      getDefaultTeamSlug: 'users/getDefaultTeamSlug',
      trackIdleTime: 'tracking/trackIdleTime'
    }),
    ...mapMutations({
      setIsPersonalizedOnboardingOpen: 'webappSystem/setIsPersonalizedOnboardingOpen'
    }),
    setSelectedScreenOption() {
      const { screen_qty: screenQty } = this.$route.query;
      if (screenQty || this.screensLimit || this.isPricingScreenFlowActiveExperiment) {
        const nextScreenOption = getNextScreenLimit(this.team.projects_components_count || this.screensLimit) || 'talk';
        this.screenQty = screenQty || nextScreenOption;
      } else {
        this.screenQty = null;
      }
    },
    setInterval() {
      this.createdTime = new Date();
      this.intervalId = setInterval(() => this.trackIdleTime({ startTime: this.createdTime }), 6e5); // every one minute
    },
    getPrice(plan) {
      const interval = this.selectedInterval;
      return getPrice({
        plan: plan.name,
        interval,
        ...(plan.showScreensSelect
          ? {
              screenOption: 'limited',
              quantity: this.screenQty
            }
          : {})
      });
    },
    getCtaText(plan) {
      const showScreensSelect = plan.showScreensSelect && this.screenQty === 'talk' && 'Contact us';
      const isDifferentScreenActive =
        plan.showScreensSelect && this.isSameAsActiveScreenSelection(plan) && 'Current screens';
      return plan.ctaText || showScreensSelect || isDifferentScreenActive || '';
    },
    getPriceText(plan) {
      return plan.priceText || (plan.showScreensSelect && this.screenQty === 'talk' && 'Custom') || '';
    },
    getScreenPriceOptions(planName) {
      let options = getScreenPriceOptions(planName) || [];
      options = Object.entries(options).map(([key, value]) => {
        value['value'] = key;
        value['label'] = key;
        return value;
      });
      if (options.length > 0) {
        options.push({
          value: 'talk',
          label: '31+'
        });
      }
      return options;
    },
    isSameAsActiveScreenSelection(plan) {
      const { interval, product_name = 'Free' } = this.activeSubscription ?? {};
      let productName = product_name === 'Prototype' ? 'Basic' : product_name;
      productName = productName === 'Anima Pro Screens' ? 'Pro' : productName;
      return (
        plan.name === productName && interval === this.selectedInterval && String(this.screensLimit) === this.screenQty
      );
    },
    isSameAsActiveSubscription(plan) {
      const { interval, product_name = 'Free', plan_id: planId } = this.activeSubscription ?? {};
      checkDisplayedPrices(planId);
      const productName = product_name === 'Prototype' ? 'Basic' : product_name;

      if (this.isOnboardingFlow) {
        return false;
      }

      if (plan.name === 'Free' && productName === 'Free') {
        return true;
      }

      if (this.isProScreenTier) {
        return this.isSameAsActiveScreenSelection(plan);
      }
      return plan.name === productName && interval === this.selectedInterval;
    },
    onContactUsClick() {
      this.$trackEvent('pricing.contact-us.click');
    },
    scrollToDetails() {
      const element = this.$refs.pricingFeatures?.$el;
      if (!element) return;
      element.scrollIntoView({ behavior: 'smooth' });
      this.$trackEvent('pricing.scroll-to-details.click');
    },
    onSelectScreens({ screen, skipTracking = false }) {
      const qty = screen.value;
      if (qty !== 'talk' && !skipTracking) {
        this.$trackEvent('pricing_page::select_tier_button.clicked', {
          plan_name: 'pro',
          interval: this.selectedInterval,
          screen_tier: qty
        });
      }
      this.screenQty = qty;
    },
    async onSelectPlan(plan) {
      const interval = this.selectedInterval;
      const planName = plan.name;
      const teamSlug = this.isOnboardingFlow ? await this.getDefaultTeamSlug() : this.$route.params.teamSlug;

      this.$trackEvent('pricing.select-plan.click', { planName, interval });
      this.$gtm.trackEvent({
        event: 'team_pricing',
        event_category: 'team_pricing',
        event_action: planName,
        event_label: this.user?.role
      });

      if (plan.isCustom || (plan.showScreensSelect && this.screenQty === 'talk')) {
        this.$trackEvent('pricing_page::custom_plan_button.clicked', {
          plan_name: plan.name,
          interval: this.selectedInterval,
          screen_tier: '+31'
        });
        return window.open(this.contactSalesUrl, '_blank');
      } else if (planName === 'Free') {
        let routeName = 'team-downgrade';
        if (this.isOnboardingFlow) {
          this.$emit('close');
          routeName = 'team';
        }
        this.$router.push({ name: routeName, params: { teamSlug } }, () => {});
      } else {
        const rawQuery = this.getQueryFromUrl(this.$router.history._startLocation);
        const query = new URLSearchParams(rawQuery);
        let source;

        if (this.$route.params['source']) {
          source = this.$route.params['source'];
        } else {
          source = query.get('utm_source');
        }
        this.$trackEvent('pricing_page::pro_pay_button.clicked', {
          plan_name: plan.name,
          interval: this.selectedInterval,
          screen_tier: this.screenQty
        });
        this.$router.push({
          name: 'team-payment',
          params: {
            teamSlug,
            source,
            nextPage: this.$route.params['nextPage'],
            afterClosing: this.$route.params['afterClosing']
          },
          query: { plan: plan.id, interval, screen_qty: this.screenQty }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';

.container {
  max-width: 1440px;
}
.body {
  display: flex;
  justify-content: center;
  width: 100%;
  @include mobile {
    align-items: center;
    flex-direction: column;
  }
}
.billing-cycle-toggle {
  display: flex;
  align-items: flex-end;
  margin-top: 25px;
}
.discount {
  @include quirky-text;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  margin-left: -30px;
}
.faq-title-wrapper {
  margin-top: 135px;
  width: 100%;

  @include desktop {
    padding-inline: 2.5rem;
  }

  .faq-title {
    font-family: 'Roslindale';
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    width: 60%;
  }
}
</style>

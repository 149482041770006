import auth from '@/auth';
import { mapActions, mapGetters, mapState } from 'vuex';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { kebabCase } from 'lodash-es';

/**
 * @type {Vue.Component}
 */
const assetsMixin = {
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projects', { assetsRegistry: 'assetsRegistry' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('components', { pngAssets: 'pngAssets' }),
    ...mapState('components', { svgAssets: 'svgAssets' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapGetters({
      currentComponentAssets: 'components/currentComponentAssets',
      codegenLang: 'codePreferences/codegenLang'
    }),
    getRemain() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return Math.floor((h - 150 - 74 - 230) / 68);
    }
  },
  methods: {
    ...mapActions({
      fetchReleaseModel: 'releases/fetchReleaseModel'
    }),
    getAssetURL(layer) {
      return this.assetsRegistry && this.assetsRegistry[layer.md5] ? this.assetsRegistry[layer.md5].url : '';
    },
    getAssetBg(layer) {
      return {
        'background-image': `url('${
          this.assetsRegistry && this.assetsRegistry[layer.md5] ? this.assetsRegistry[layer.md5].url : ''
        }')`,
        'background-size': layer.width > 40 ? 'contain' : 'auto'
      };
    },
    getAssetDownloadURL(layer) {
      return `${process.env.API_BASE_URL1}/v2/projects/${
        this.$route.params.projectId || this.currentProject.id
      }/assets/${layer.md5}?t=${auth.getToken()}`;
    },
    getExtFromFileName(fileName) {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(fileName)[1];
    },
    getLayerName(layer) {
      return layer.fileName.replace(/\.[^/.]+$/, '');
    },
    trackAssetDownload({ layer, isOriginComponentTab = false, isPackage = false }) {
      const downloadType = isPackage ? 'package' : 'individual';
      const origin = isOriginComponentTab ? 'component_tab' : 'assets_tab';
      const type = this.getExtFromFileName(layer.fileName);

      this.$trackEvent('omni.code-mode.download-asset', {
        'asset-type': type,
        framework: this.codegenLang,
        origin,
        download_type: downloadType
      });
    },
    downloadZip(type) {
      if (this.isDownloadingZip[type]) return;

      let source = type == 'svg' ? this.svgAssets : this.pngAssets;

      const fileURLs = source.map((layer) => ({ fileName: layer.fileName, url: this.getAssetDownloadURL(layer) }));

      this.isDownloadingZip[type] = true;
      this.doExport(fileURLs, type, `assets(${type}).zip`);
      this.trackAssetDownload({ type, isPackage: true });
    },
    doExport(fileURLs, type, zipName) {
      let zip = new JSZip();
      fileURLs
        .reduce((prev, { url, fileName }) => {
          return prev.then(() => {
            let promise = fetch(url).then((res) => res.arrayBuffer());
            zip.file(fileName, promise);

            return promise;
          });
        }, Promise.resolve())
        .then(() => zip.generateAsync({ type: 'blob' }))
        .then((blob) => {
          this.isDownloadingZip[type] = false;
          return saveAs(blob, zipName);
        });
    },
    async DownloadModel() {
      if (this.isDownloadingZip['model']) return;
      let zip = new JSZip();

      await this.$waitFor(() => this.currentRelease, null, { notEmpty: true });

      this.isDownloadingZip['model'] = true;
      let res = await this.fetchReleaseModel();
      const model = JSON.stringify(res);
      zip.file('model.json', model);
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const { short_id, name } = this.currentProject;
        this.isDownloadingZip['model'] = false;
        return saveAs(blob, `${kebabCase(name)}#${short_id}.anima.zip`);
      });
    },
    async DownloadAllAssets() {
      if (this.isDownloadingZip['all']) return;

      const fileURLs = this.currentComponentAssets.map((layer) => ({
        fileName: layer.fileName,
        url: this.getAssetDownloadURL(layer)
      }));

      this.isDownloadingZip['all'] = true;
      const { name } = this.currentProject;
      this.doExport(fileURLs, 'all', `${name}.assets.zip`);
    }
  }
};

export default assetsMixin;

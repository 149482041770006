<template>
  <StiggProvider :apiKey="apiKey" :customerId="team.id">
    <CustomerPortal v-bind="customerPortalConfig">
      <template v-slot:paywallComponent>
        <PricingStigg @close="$emit('close')" theme="light" :withinCustomerPortal="true" />
      </template>
    </CustomerPortal>
  </StiggProvider>
</template>

<script>
import { applyReactInVue } from 'vuereact-combined';

import { StiggProvider, CustomerPortal } from '@stigg/react-sdk';
import PricingStigg from '@/views/PricingStigg';

export default {
  components: {
    StiggProvider: applyReactInVue(StiggProvider),
    CustomerPortal: applyReactInVue(CustomerPortal),
    PricingStigg
  },
  mounted() {},
  props: ['team'],
  data() {
    return {
      apiKey: process.env.STIGG_API_KEY,
      customerPortalConfig: {
        textOverrides: {
          paywallSectionTitle: 'Manage plan'
        }
      }
    };
  }
};
</script>
<style>
* > .stigg-overview-layout .MuiBox-root {
  max-height: 100% !important;
}

.stigg-plan-anima-pro-promotion {
  display: none !important;
}
</style>

import router from '@/router';
import { EventBus } from '@/services/bus';
import store from '@/store';
import { camelCase, get } from 'lodash';
import Prism from 'prismjs';
import { JAVASCRIPT_KEYWORDS } from '../Code/constants';

function assign(obj) {
  for (let i = 1; i < arguments.length; i++) {
    for (const p in arguments[i]) obj[p] = arguments[i][p];
  }
  return obj;
}

let cleanTag = (t) => t.replace('/', '').replace('<', '').replace('>', '');
let isOpeningTag = (t, tag) => t.startsWith('<' + tag);
let isClosingTag = (t, tag) => t.startsWith(`</${tag}`);
let isSelfClosingTag = (rawElement, tag) =>
  ['img', 'hr', 'input', 'link', 'meta', 'embed', 'br'].includes(tag) || rawElement.trim().endsWith('/>');
let stripComponentInstanceSuffix = (dataId) => dataId.replace(/:an-component-instance$/, '');

export default {
  functional: true,
  props: {
    code: {
      type: String
    },
    inline: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'markup'
    },
    mode: {
      type: String,
      default: 'default'
    },
    componentData: {
      type: Object,
      default: () => ({ master: {}, instance: {} })
    },
    masterId: {
      type: String,
      requried: true
    },
    plugins: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    }
  },

  render(h, ctx) {
    let code = ctx.props.code || (ctx.children && ctx.children.length > 0 ? ctx.children[0].text : '');
    const language = ctx.props.language;
    const prismLanguage = Prism.languages[language];
    const className = `language-${language}`;
    const mode = ctx.props.mode;
    const componentData = ctx.props.componentData;
    const masterId = ctx.props.masterId;
    const type = ctx.props.type;
    let propNamesLowerCased = [];
    // const popovers = ctx.props.popovers;

    const getTokenClasses = (type) => {
      return `token ${type == 'class' ? 'class' : 'variable'} element-clickable ${type}-type fadeInFast`;
    };
    const getPropClasses = (type) => {
      return `token attr-name element-clickable ${type}-type prop-type`;
    };

    const getClass = (el) => {
      let valid = ['class', 'className'];
      if (!el) return false;
      if (![...el.classList].includes('attr-value')) return false;
      if (!el.parentNode) return false;
      const keys = el.parentNode.querySelectorAll(':scope > .attr-name');
      let key = [...keys].find((k) => valid.includes(k.textContent.trim()));
      if (key) {
        let value = key.nextElementSibling;
        let dirtyClassname = value.textContent;
        return dirtyClassname.replaceAll('"', '').replace('=', '');
      } else {
        return false;
      }
    };

    const updateClassTokenValue = (payload) => {
      const { type, tokenId, newTokenId } = payload;
      let token = tokenId;
      const el = document.querySelector(`[token-id="${tokenId}"]`);
      token = newTokenId ? newTokenId : tokenId;
      let u = document.createElement('div');
      u.classList.add('underline-div');
      el.classList = getTokenClasses(type);
      el.style.padding = '0';
      el.style.margin = '0';
      el.style.display = 'inline';
      if (type == 'token') {
        el.innerHTML = `--${token}`;
      } else if (type == 'class') {
        el.innerHTML = `.${token}`;
      }
      el.appendChild(u);
      el.setAttribute('token-id', token);
    };

    const handleUpdateValue = (e, tokenId, type) => {
      let newTokenId = e.target.value;

      if (newTokenId && newTokenId != tokenId) {
        updateClassTokenValue({
          tokenId,
          newTokenId,
          type
        });
        ctx.listeners['update']({ type });
        store.dispatch('styleguide/updateStyleguide', { tokenId, newTokenId, type });
      } else {
        updateClassTokenValue({
          tokenId,
          type
        });

        ctx.listeners['cancel']({ type });
      }
    };

    // /**
    //  * @param {HTMLInputElement} input
    //  */
    // const moveCursorToStart = input => {
    //   if (!input) return;
    //   if (input.createTextRange) {
    //     var part = input.createTextRange();
    //     part.move('character', 0);
    //     part.select();
    //   } else if (input.setSelectionRange) {
    //     input.setSelectionRange(0, 0);
    //   }
    // };

    const createInput = (tokenId, type) => {
      const el = document.querySelector(`[token-id="${tokenId}"]`);
      const input = document.createElement('input');
      input.setAttribute('spellcheck', false);
      input.setAttribute('autocomplete', 'off');
      input.classList.add(`styleguide-input`, `${type}-type`, 'fadeInFast');
      input.value = tokenId;
      input.setAttribute('size', tokenId.length);

      let wr = document.createElement('div');
      wr.classList.add('input-sizer', `${type}-type`);
      el.innerHTML = '';
      el.classList = '';

      wr.appendChild(input);
      el.appendChild(wr);

      input.addEventListener('blur', (e) => {
        handleUpdateValue(e, tokenId, type);
      });
      input.addEventListener('keydown', function (e) {
        if (e.key === ' ') {
          e.preventDefault();
        }
        if (e.key == 'Enter') {
          input.blur();
        }
      });

      input.addEventListener('input', (e) => {
        let value = e.target.value;
        e.target.parentNode.dataset.value = value;
        e.target.setAttribute('size', Math.max(value.length, 1));
      });

      // moveCursorToStart(input);

      input.focus();
    };

    const updatePropString = (data) => {
      const { propName, propFingerprint, propType, newPropName, propEnable, propPrefix } = data;
      let name = propName;
      const el = document.querySelector(`[prop-fingerprint="${propFingerprint}"]`);
      name = newPropName ? newPropName : propName;
      let u = document.createElement('div');
      let classes = getPropClasses(propType) + ` ${propEnable ? 'prop-enabled' : 'prop-disabled'}`;

      u.classList.add('underline-div');
      el.classList = classes;
      el.style.padding = '0';
      el.style.margin = '0';
      el.style.display = 'inline';

      el.innerHTML = `${propPrefix}${name}`;
      el.appendChild(u);
      if (propType == 'nested') {
        let w = createCollapse();
        w.style.visibility = 'hidden';
        let collapseState = el.getAttribute('collapse');

        if (collapseState && collapseState == 'close') {
          w.classList.add('close');
        }
        el.appendChild(w);
      }
      el.setAttribute('prop-name', name);

      createPropCheckbox(el.parentElement);
      addPropsElementsEvents();

      if (newPropName && propNamesLowerCased.includes(propName.toLowerCase())) {
        propNamesLowerCased[propNamesLowerCased.indexOf(propName.toLowerCase())] = newPropName;
      }
    };

    const updateCnameString = (data) => {
      const { oldName, newName, prefix } = data;
      let name = oldName;
      const el = document.querySelector(`[cname="${name}"]`);
      name = newName ? newName : oldName;
      let u = document.createElement('div');
      u.classList.add('underline-div');
      el.classList = `token ${
        language == 'jsx' ? 'class-name' : 'tag'
      } element-clickable cname-type fadeInFast ${language}`;
      el.style.padding = '0';
      el.style.margin = '0';
      el.style.display = 'inline';

      if (prefix) {
        el.innerHTML = '';
        let s = document.createElement('span');
        s.classList = 'token punctuation';
        s.innerHTML = prefix;
        el.appendChild(s);
        let txtNode = document.createTextNode(name);
        el.appendChild(txtNode);
      } else {
        el.innerHTML = name;
      }

      el.appendChild(u);
      el.setAttribute('cname', name);
    };

    let callbacks = {};

    const updateProp = (data) => {
      const { propName, newPropName: name, propFingerprint, propType, propEnable, propPrefix } = data;

      if (callbacks[propFingerprint]) {
        document.removeEventListener('click', callbacks[propFingerprint]);
        delete callbacks[propFingerprint];
      }

      let newPropName = getValidatePropName(name);

      newPropName = getUniquePropName(propName, newPropName);

      if (newPropName && newPropName != propName) {
        updatePropString({
          ...data,
          newPropName
        });

        ctx.listeners['update-component-prop-name']({
          ...data,
          newPropName,
          masterId,
          usageCode: code.replace(new RegExp(`\\s${propName}=`), ` ${newPropName}=`)
        });
      } else {
        updatePropString({
          propName,
          propType,
          propFingerprint,
          propEnable,
          propPrefix
        });
      }
    };

    const validateName = (new_name) => {
      let specialCharsTest = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let alphaTest = /^[A-Z]$/i;

      if (language == 'jsx') {
        new_name = camelCase(new_name);

        if (specialCharsTest.test(new_name)) {
          return false;
        }
      }

      if (JAVASCRIPT_KEYWORDS.includes(new_name.toLowerCase())) {
        new_name = 'x' + new_name;
      }
      if (!alphaTest.test(new_name.charAt(0))) {
        new_name = 'x' + new_name;
      }
      if (new_name.length > 35) {
        new_name = new_name.slice(0, 35);
      }

      if (language == 'jsx') {
        new_name = new_name.charAt(0).toUpperCase() + new_name.slice(1);
      }

      return new_name;
    };

    const getUniquePropName = (propName, newPropName) => {
      if (propName == newPropName) {
        return propName;
      }

      let i = 2;
      const origNewName = newPropName;
      while (propNamesLowerCased.includes(newPropName.toLowerCase())) {
        newPropName = origNewName + i++;
      }
      return newPropName;
    };

    const getValidatePropName = (new_name) => {
      let og_name = `${new_name}`;
      let specialCharsTest = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let alphaTest = /^[A-Z]$/i;

      new_name = camelCase(new_name);
      new_name = og_name.charAt(0) + new_name.slice(1);

      if (specialCharsTest.test(new_name)) {
        return false;
      }

      if (JAVASCRIPT_KEYWORDS.includes(new_name.toLowerCase())) {
        new_name = 'x' + new_name;
      }
      if (!alphaTest.test(new_name.charAt(0))) {
        new_name = 'x' + new_name;
      }
      if (new_name.length > 35) {
        new_name = new_name.slice(0, 35);
      }
      return new_name;
    };

    const updateComponentName = (data) => {
      const { oldName, newName: _newName, prefix } = data;

      let newName = validateName(_newName);

      if (newName && newName != oldName) {
        updateCnameString({
          oldName,
          newName,
          prefix
        });
        ctx.listeners['update-component-name']({
          oldName,
          newName,
          masterId,
          usageCode: code.replaceAll(oldName, newName)
        });
      } else {
        updateCnameString({
          oldName,
          prefix
        });
      }
    };

    const createCNameInput = (data) => {
      const { name, prefix } = data;
      const el = document.querySelector(`[cname="${name}"]`);
      const input = document.createElement('input');
      input.setAttribute('spellcheck', false);
      input.setAttribute('autocomplete', 'off');
      input.classList.add(`cname-input`, `name-type`, 'fadeInFast', language);
      input.value = name;

      input.setAttribute('size', name.length);

      let wr = document.createElement('div');
      wr.classList.add('input-sizer', 'cname', language);

      el.style.margin = '0';
      el.style.display = 'inline-block';

      el.innerHTML = '';

      wr.appendChild(input);
      el.appendChild(wr);
      el.classList = '';

      input.addEventListener('blur', (e) => {
        updateComponentName({ oldName: name, newName: e.target.value, prefix });
      });
      input.addEventListener('keydown', function (e) {
        if (e.key === ' ') {
          e.preventDefault();
        }
        if (e.key == 'Enter') {
          input.blur();
        }
      });

      input.addEventListener('input', (e) => {
        let name = e.target.value;
        name = validateName(name);
        e.target.parentNode.dataset.value = validateName(name);
        e.target.setAttribute('size', Math.max(e.target.value.length, 1));
      });

      input.focus();
    };

    const createPropInput = (data) => {
      const { propName, propFingerprint, propType } = data;

      const el = document.querySelector(`[prop-fingerprint="${propFingerprint}"]`);
      const input = document.createElement('input');
      input.setAttribute('spellcheck', false);
      input.setAttribute('autocomplete', 'off');
      input.classList.add('prop-input', `${propType}-type`, 'fadeInFast');
      input.value = propName;
      input.setAttribute('size', propName.length);
      // input.setAttribute('disabled', true);

      let wr = document.createElement('div');
      wr.classList.add('input-sizer', 'prop');

      wr.appendChild(input);

      el.style.margin = '0';
      el.style.display = 'inline-flex';
      el.style.alignItems = 'center';
      el.style.position = 'relative';

      el.innerHTML = '';
      // el.appendChild(box);
      el.appendChild(wr);
      el.classList = '';

      let callback = (event) => {
        var isClickInside = el.contains(event.target);

        if (!isClickInside) {
          updateProp({ ...data, newPropName: input.value });
        }
      };

      callbacks[propFingerprint] = callback;

      document.addEventListener('click', callback);

      input.addEventListener('keydown', function (e) {
        if (e.key === ' ') {
          e.preventDefault();
        }
        if (e.key == 'Enter') {
          // el.parentElement.click();
          document.body.click();
        }
      });
      input.addEventListener('input', (e) => {
        e.target.parentNode.dataset.value = e.target.value;
        e.target.setAttribute('size', Math.max(e.target.value.length, 1));
      });

      input.focus();
    };

    const enterEditMode = (el, type) => {
      const tokenId = el.getAttribute('token-id');
      createInput(tokenId, type);
    };
    const handleCodeDblClick = (e) => {
      if (mode !== 'default') return;
      if (language == 'markup' && language !== 'jsx' && language !== 'html') return;
      const className = e.target.textContent;
      const styleguideClasses = get(store.state['styleguide'], 'styleguide.classes', {});
      const isStyleguideClass = !!Object.values(styleguideClasses).find((s) => s.name == className);
      if (className) {
        EventBus.$emit('handle-class-dbl-click', { className, isStyleguideClass });
      }
    };
    const handleCodeclick = (e) => {
      switch (mode) {
        case 'styleguide':
          if ([...e.target.classList].includes('token-type')) {
            enterEditMode(e.target, 'token');
          }
          if ([...e.target.classList].includes('class-type')) {
            enterEditMode(e.target, 'class');
          }
          break;

        case 'usageCode':
          if ([...e.target.classList].includes('prop-type')) {
            const propName = e.target.getAttribute('prop-name');
            const propFingerprint = e.target.getAttribute('prop-fingerprint');
            const propType = e.target.getAttribute('prop-type');
            const propEnable = e.target.getAttribute('prop-enable') == 'true' ? true : false;
            const propPrefix = e.target.getAttribute('prop-prefix');
            createPropInput({
              propName,
              propFingerprint,
              propType,
              propEnable,
              propPrefix
            });
          }
          if ([...e.target.classList].includes('cname-type')) {
            const name = e.target.getAttribute('cname');
            const lang = e.target.getAttribute('lang');
            const prefix = e.target.getAttribute('cname-prefix');

            createCNameInput({
              name,
              lang,
              prefix
            });
          }

          if ([...e.target.classList].includes('usage-code-collpase')) {
            let prop = e.target.parentNode;
            if (!prop) return;
            let value = prop.nextSibling;
            if (!value) return;

            if ([...e.target.classList].includes('close')) {
              let code = value.getAttribute('code');

              value.innerHTML = code;
              value.setAttribute('code', '');
              prop.setAttribute('collapse', 'open');
            } else {
              value.setAttribute('code', value.innerHTML);
              value.innerHTML = `<span class="token script-punctuation punctuation">=</span><span class="token punctuation">{...<span class="token punctuation">}</span>`;
              prop.setAttribute('collapse', 'close');
              // e.target.visibility = 'visible';
            }

            e.target.classList.toggle('close');
          }

          break;
        case 'default':
          if (language == 'markup' || language == 'jsx' || language == 'html') {
            const className = e.target.textContent;
            const styleguideClasses = get(store.state['styleguide'], 'styleguide.classes', {});
            const isStyleguideClass = !!Object.values(styleguideClasses).find((s) => s.name == className);
            if (className) {
              // EventBus.$emit('handle-class-click', { className, isStyleguideClass });
              ctx.listeners['handle-class-click'] &&
                ctx.listeners['handle-class-click']({ className, isStyleguideClass });
            }

            if (language == 'jsx') {
              let model_id = e.target.getAttribute('model_id');
              if (model_id) {
                EventBus.$emit('open-component-in-library', {
                  nodeId: model_id,
                  openComponentInterface: false,
                  preProcessParams: {
                    forcePreProcess: true
                  }
                });
              }
            }
          }
          break;

        default:
          break;
      }
    };

    if (process.env.NODE_ENV === 'development' && !prismLanguage) {
      throw new Error(
        `Prism component for language "${language}" was not found, did you forget to register it? See all available ones: https://cdn.jsdelivr.net/npm/prismjs/components/`
      );
    }

    const wrapSpan = (node, index) => {
      if (node.nodeName === '#text') {
        var text = node.textContent.split(' ');

        let spans = [];

        text.forEach((t, i) => {
          var s = document.createElement('span');
          spans.push(s);
          s.classList = `${index}-${i}`;
          s.textContent = t;
          node.parentElement.insertBefore(s, node.parentElement.childNodes[index + i]);
        });
        node.remove();
        return spans;
      }
    };

    const markClassnamesClickable = (el) => {
      let valid = ['class', 'className'];
      let attNames = el.querySelectorAll('.attr-name');
      let attNameArray = [...attNames];
      for (let i = 0; i < attNameArray.length; i++) {
        const element = attNameArray[i];
        if (valid.includes(element.textContent)) {
          let value = element.nextElementSibling || {};
          if (!value) continue;
          value.classList.add('mark-class-clickable');
          let classText = getClass(value);
          if (!classText) continue;
          let i = [...value.childNodes].findIndex((n) => n.textContent.trim() == classText.trim());
          if (i != -1) {
            let spans = wrapSpan(value.childNodes[i], i);
            spans.forEach((s, i) => {
              s.classList.add('class-value-underline');
              s.setAttribute('order', i);
            });
          }
        }
      }
    };

    const markStyleguideClassClickable = (el) => {
      if (!el) return;
      let sId = language === 'css' ? '.token.class' : '.token.selector';
      let selectors = Array.from(el.querySelectorAll(sId));
      // This class filter is not solid, better to match with db token instead
      const classFilter = (el) => {
        let text = el.textContent;
        return text.startsWith('.');
      };
      let tokenSelectors = selectors.filter(classFilter);
      for (let i = 0; i < tokenSelectors.length; i++) {
        const token = tokenSelectors[i];
        const id = token.textContent.trim().slice(1);
        token.setAttribute('token-id', id);
        if (language == 'css') token.parentNode.setAttribute('classname', id);
        else token.setAttribute('classname', id);
        token.classList.add('element-clickable', 'class-type');
        let u = document.createElement('div');
        u.classList.add('underline-div');
        token.appendChild(u);
      }
      // return doc.body.innerHTML;
    };
    const markStyleguideTokenClickable = (el) => {
      if (!el) return;
      // const doc = new DOMParser().parseFromString(html, 'text/html');
      const variableIdentifier = language === 'css' ? '--' : '$';
      let selectors = Array.from(el.querySelectorAll('.token.variable'));

      // This classfilter is not solid, match with db token later
      const tokenFilter = (el) => {
        let text = el.textContent;
        return text.startsWith(variableIdentifier);
      };
      let tokenSelectors = selectors.filter(tokenFilter);
      for (let i = 0; i < tokenSelectors.length; i++) {
        const token = tokenSelectors[i];
        const id = token.textContent.trim().slice(variableIdentifier.length);
        token.setAttribute('token-id', id);
        token.classList.add('element-clickable', 'token-type');
        let u = document.createElement('div');
        u.classList.add('underline-div');
        token.appendChild(u);
      }
      // return doc.body.innerHTML;
    };

    const createPropCheckbox = (el) => {
      let selectors = Array.from(el.querySelectorAll('.token.attr-name.prop-type'));

      for (let i = 0; i < selectors.length; i++) {
        const el = selectors[i];

        const propType = el.getAttribute('prop-type');
        const propEnable = el.getAttribute('prop-enable') == 'true' ? true : false;
        // const propFingerprint = el.getAttribute('prop-fingerprint');

        const boxWrap = document.createElement('div');
        boxWrap.classList = `c-box-wrap`;
        let box = document.createElement('div');
        box.classList.add('c-box', `${propType == 'nested' ? 'disabled' : 'enabled'}`);
        propEnable && box.classList.add('checked');

        box.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="#222" width="24" height="24" viewBox="0 0 24 24"><path d="M10 15.586L6.707 12.293 5.293 13.707 10 18.414 19.707 8.707 18.293 7.293z"/></svg>`;
        boxWrap.appendChild(box);
        el.appendChild(boxWrap);
      }
    };

    const markPropsClickable = (el) => {
      let selectors = Array.from(el.querySelectorAll('.token.attr-name'));

      const regular = get(componentData, 'instance.props.regular', []);
      const nested = get(componentData, 'instance.props.nested', []);
      propNamesLowerCased = [...regular, ...nested].map((p) => (p.name || '').toLowerCase());
      const propFilter = (el) => {
        let text = el.textContent.toLowerCase();

        if (language == 'jsx') {
          return propNamesLowerCased.includes(text);
        }
        // vue
        if (language == 'html') {
          return [
            ...propNamesLowerCased,
            ...propNamesLowerCased.map((p) => `:${p}`),
            ...propNamesLowerCased.map((p) => `v-bind:${p}`)
          ].includes(text);
        }
      };
      let tokenSelectors = selectors.filter(propFilter);
      for (let i = 0; i < tokenSelectors.length; i++) {
        const token = tokenSelectors[i];
        let propName = token.textContent.trim();

        let prefix = '';

        // vue
        if (language == 'html') {
          if (propName.startsWith('v-bind:')) {
            propName = propName.replace('v-bind:', '');
            prefix = 'v-bind:';
          }
          if (propName.startsWith(':')) {
            propName = propName.replace(':', '');
            prefix = ':';
          }
        }

        const prop = [...regular, ...nested].find((p) => p.name == propName);
        token.setAttribute('prop-fingerprint', prop.prop_fingerprint);
        token.setAttribute('prop-name', prop.name);
        token.setAttribute('prop-type', prop.type == 'dict' ? 'nested' : 'regular');
        token.setAttribute('prop-enable', prop.is_enable);
        token.setAttribute('prop-prefix', prefix);
        token.classList.add('element-clickable', 'prop-type', `${prop.is_enable ? 'prop-enabled' : 'prop-disabled'}`);
        let u = document.createElement('div');
        u.classList.add('underline-div');
        token.appendChild(u);

        if (prop.type == 'dict') {
          let w = createCollapse(token);
          w.style.visibility = 'hidden';
          token.appendChild(w);
        }
      }
    };
    const createCollapse = () => {
      let w, cr, cd;
      w = document.createElement('div');
      cr = document.createElement('div');
      cd = document.createElement('div');
      w.classList.add('usage-code-collpase');
      cr.classList.add('icon-right');
      cd.classList.add('icon-down');
      cr.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style="fill:currentColor;transform:;-ms-filter:"><path d="M10.707 17.707L16.414 12 10.707 6.293 9.293 7.707 13.586 12 9.293 16.293z"></path></svg>';
      cd.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style="fill:currentColor;transform:;-ms-filter:"><path d="M16.293 9.293L12 13.586 7.707 9.293 6.293 10.707 12 16.414 17.707 10.707z"></path></svg>';
      w.appendChild(cr);
      w.appendChild(cd);

      return w;
    };
    const markComponentNameClickable = (el) => {
      if (language == 'jsx') {
        let selectors = Array.from(el.querySelectorAll('.token.class-name')).concat(
          Array.from(el.querySelectorAll('.token.tag'))
        );

        const componentName = get(componentData, 'master.component_name', get(componentData, 'master.name'));

        const cNameFilter = (el) => {
          let text = el.textContent.trim();
          return text == componentName;
        };
        let tokenSelectors = selectors.filter(cNameFilter);
        for (let i = 0; i < tokenSelectors.length; i++) {
          const token = tokenSelectors[i];
          const cName = token.textContent.trim();

          token.setAttribute('cname', cName);
          token.setAttribute('lang', language);
          token.classList.add('element-clickable', 'cname-type', language);
          let u = document.createElement('div');
          u.classList.add('underline-div');
          token.appendChild(u);
        }
      }
      // VUE
      else if (language == 'html') {
        let selectors = Array.from(el.querySelectorAll('.token.tag'));

        const componentName = get(componentData, 'master.component_name', get(componentData, 'master.name'));

        let prefix = '';

        const cNameFilter = (el) => {
          let text = el.textContent.trim();
          if ('<' + componentName == text) {
            prefix = '<';
            return true;
          }
          return text == componentName;
        };

        let tokenSelectors = selectors.filter(cNameFilter);
        for (let i = 0; i < tokenSelectors.length; i++) {
          const token = tokenSelectors[i];
          let rawName = token.textContent.trim();
          const cName = prefix ? rawName.replace(prefix, '') : rawName;

          token.setAttribute('cname', cName);
          token.setAttribute('cname-prefix', prefix);
          token.classList.add('element-clickable', 'cname-type', language);
          let u = document.createElement('div');
          u.classList.add('underline-div');
          token.appendChild(u);
        }
      }
    };

    const addPropsElementsEvents = () => {
      if (mode == 'usageCode') {
        const els = document.querySelectorAll('[prop-type]');

        els.forEach((_el) => {
          const el = _el.cloneNode(true);
          _el.parentNode.replaceChild(el, _el);

          const isNested = el.getAttribute('prop-type') == 'nested';
          const t = el.querySelector('.usage-code-collpase');
          const boxWrap = el.querySelector('.c-box-wrap');

          const propFingerprint = el.getAttribute('prop-fingerprint');
          const propType = el.getAttribute('prop-type');
          if (boxWrap) {
            const clickEventHandler = (e) => {
              let classes = [...e.target.classList];
              let newPropEnable = !classes.includes('checked');
              e.target.classList.toggle('checked');
              ctx.listeners['update-component-prop-enable']({
                propType,
                propFingerprint,
                newPropEnable,
                masterId,
                usageCode: code
              });
              if (callbacks[propFingerprint]) {
                document.removeEventListener('click', callbacks[propFingerprint]);
                delete callbacks[propFingerprint];
              }
            };

            boxWrap.firstElementChild.addEventListener('click', clickEventHandler);
          }

          el.addEventListener('mouseover', () => {
            const fingerprint = el.getAttribute('prop-fingerprint');
            const propType = el.getAttribute('prop-type');
            let prop = componentData['instance']['props'][propType].find((p) => p['prop_fingerprint'] == fingerprint);

            ctx.listeners['propMouseOver'] &&
              ctx.listeners['propMouseOver']({
                nodeId: prop['model_id']
              });
            if (boxWrap) {
              boxWrap.style.display = 'flex';
            }
            if (isNested) {
              if (t) {
                t.style.visibility = 'visible';
              }
            }
          });
          el.addEventListener('mouseout', () => {
            ctx.listeners['propMouseLeave'] && ctx.listeners['propMouseLeave']({});
            if (boxWrap) {
              boxWrap.style.display = 'none';
            }
            if (isNested) {
              if (t) {
                if (![...t.classList].includes('close')) {
                  t.style.visibility = 'hidden';
                }
              }
            }
          });
        });
      }

      if (mode == 'styleguide' && type == 'class') {
        let classBlocks = document.querySelectorAll('[class-block]');
        let maxWidth = 0;

        for (let i = 0; i < classBlocks.length; i++) {
          const span = classBlocks[i];
          span.style.width = 'fit-content';

          // const span = _span.cloneNode(true);
          // _span.parentNode.replaceChild(span, _span);

          let rect = span.getBoundingClientRect();
          maxWidth = Math.max(maxWidth, rect.width);

          span.style.width = '100%';

          // let l = document.querySelector('[class-block-actions]');
          span.addEventListener('mouseenter', () => {
            // if (l.contains(e.relatedTarget)) return;
            // const rect = span.getBoundingClientRect();
            span.classList.add('active');
            for (let j = 0; j < classBlocks.length; j++) {
              if (j == i) continue;
              classBlocks[j].classList.remove('active');
            }

            ctx.listeners['classBlockMouseEnter'] &&
              ctx.listeners['classBlockMouseEnter']({
                el: span,
                rect,
                classData: { className: span.getAttribute('class-block'), text: span.textContent }
              });
          });
          span.addEventListener('mouseleave', () => {
            span.classList.remove('active');
            const rect = span.getBoundingClientRect();
            ctx.listeners['classBlockMouseLeave'] &&
              ctx.listeners['classBlockMouseLeave']({
                el: span,
                rect
              });
          });
        }
        ctx.listeners['onWidthUpdate'] &&
          ctx.listeners['onWidthUpdate']({
            width: maxWidth
          });
      }

      if (mode == 'default') {
        if (['markup', 'jsx', 'html'].includes(language)) {
          let nodes = Array.from(document.querySelectorAll('[data-id]'));

          for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            let tagId = node.getAttribute('tag-id');
            let open, close;
            let single = false;

            if (tagId) {
              const pair = document.querySelectorAll(`[tag-id='${tagId}']`);

              if (pair.length == 1) {
                open = pair[0];
                single = true;
              } else if (pair.length == 2) {
                open = pair[0];
                close = pair[1];
                single = false;
              }
            }

            const setExtenderHeight = (node, inverseNode) => {
              if (!node) return;

              const nodeRect = node.getBoundingClientRect();

              // 14 == 1em
              if (nodeRect.x == 14) {
                node.style.transform = 'translateY(0)';
              }

              const nodeExtender = node.querySelector('.tag-extender');
              nodeExtender && (nodeExtender.style.height = nodeRect.height + 'px');
              nodeExtender && (nodeExtender.style.height = nodeRect.height + 'px');

              if (inverseNode) {
                const inverseNodeRect = inverseNode.getBoundingClientRect();
                if (Math.round(inverseNodeRect.y) != Math.round(nodeRect.y)) {
                  const inverseNodeExtender = inverseNode.querySelector('.tag-extender');
                  inverseNodeExtender && (inverseNodeExtender.style.height = inverseNodeRect.height + 'px');

                  if (inverseNodeRect.x == 14) {
                    node.style.transform = 'translateY(0)';
                  }
                }
              }
            };

            setExtenderHeight(open, close);

            const isRoot = (el) => {
              if (!el || !el.parentElement) {
                return false;
              }
              return (
                el.getAttribute('data-id') === el.parentElement.querySelector('.token.tag').getAttribute('data-id')
              );
            };
            const handleMouseOver = () => {
              let h, top;
              if (single) {
                const rect = open.getBoundingClientRect();
                h = rect.height;
                top = rect.y;
              } else {
                const rect1 = open.getBoundingClientRect();
                const rect2 = close.getBoundingClientRect();

                top = rect1.y;
                h = Math.abs(rect1.y - rect2.y) + rect2.height;
              }
              ctx.listeners['nodeMouseOver'] &&
                ctx.listeners['nodeMouseOver']({
                  nodeId: stripComponentInstanceSuffix(node.getAttribute('data-id')),
                  height: h,
                  top,
                  left: 0,
                  isRoot: isRoot(open)
                });
            };
            const handleMouseLeave = () => {
              ctx.listeners['nodeMouseLeave'] && ctx.listeners['nodeMouseLeave']({});
            };

            open && open.addEventListener('mouseover', handleMouseOver);
            open && open.addEventListener('mouseleave', handleMouseLeave);

            close && close.addEventListener('mouseover', handleMouseOver);
            close && close.addEventListener('mouseleave', handleMouseLeave);
          }
        }
      }
    };

    const wrapStyleguideClasses = (rootEl) => {
      if (!rootEl) return;
      let els = Array.from(rootEl.querySelectorAll('[classname]'));
      els.forEach((el) => {
        const span = document.createElement('span');
        span.setAttribute('class-block', el.getAttribute('classname'));
        span.classList.add('class-block');
        // hack for full width span
        let l = document.createElement('div');
        let r = document.createElement('div');
        l.classList.add('side', 'l');
        r.classList.add('side', 'r');
        span.appendChild(l);
        span.appendChild(r);

        let root = el.previousSibling ? el.previousSibling : el;
        let current = el.nextSibling;
        let done = false;
        span.appendChild(el.cloneNode(true));
        while (!done && current) {
          if (current.innerHTML == '}') {
            done = true;
          }
          if (current.getAttribute && current.getAttribute('classname')) {
            break;
          }
          span.appendChild(current.cloneNode(true));
          if (language === 'sass') el.remove();
          let temp = current.nextSibling;
          if (current) {
            current.remove();
            current = temp;
          }
        }
        root.parentNode.insertBefore(span, root.nextSibling);

        if (language === 'css') el.remove();
      });
      // return doc.body.innerHTML;
    };

    const markComponentsNameClickable = (el) => {
      const { component } = router.currentRoute.query;

      let selectors = Array.from(el.querySelectorAll('.token.class-name'));

      const items = get(store.state, 'webComponents.items', []);
      const names = items.map((i) => i.name);

      const cNameFilter = (el) => {
        let text = el.textContent.trim();
        return names.includes(text);
      };
      const tokenSelectors = selectors.filter(cNameFilter);
      for (let i = 0; i < tokenSelectors.length; i++) {
        const token = tokenSelectors[i];
        const cName = token.textContent.trim();

        const master = items.find((i) => i.name == cName);
        if (!master) continue;
        const pi = master.instances.find((i) => i.is_primary);
        if (!pi) continue;

        if (component == pi.model_id) continue;

        token.setAttribute('model_id', pi.model_id);
        token.classList.add('element-clickable', 'cname-type', 'clean_snippet');
        let u = document.createElement('div');
        u.classList.add('underline-div');
        token.appendChild(u);
      }
    };

    const mapElementToDataId = (el) => {
      const elements = Array.from(el.querySelectorAll('.attr-name')).filter((el) => el.textContent === 'data-id');
      let viewsMap = {};
      for (let i = 0; i < elements.length; i++) {
        let e = elements[i];
        let dataIdValueElement = e.nextElementSibling;
        let id = dataIdValueElement.textContent.trim().replace(/^\=\"(.+)\"$/, '$1');
        let p = e.parentNode;

        const removeDataIdAttribute = (dataIdElement) => {
          const prevElement = dataIdElement.previousSibling;
          const nextElement = dataIdElement.nextElementSibling;

          // remove 'data-id' attribute node
          dataIdElement.remove();
          // remove 'data-id' value node
          nextElement.remove();
          // remove leading space text node.
          if (prevElement.nodeType === 3 && /^\s*$/.test(prevElement.textContent)) {
            prevElement.remove();
          }
        };

        const createExtender = (node) => {
          if (!node) return;

          let div = document.createElement('div');
          div.classList.add('tag-extender');
          Object.assign(div.style, {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 'var(--html-pane-width)'
          });
          node.style.position = 'relative';
          node.prepend(div);
        };

        p.setAttribute('data-id', id);
        const tagId = id.replace('|', '');
        let tag = cleanTag(p.firstElementChild.textContent);
        let elementTextContent = p.textContent;

        if (tag && isSelfClosingTag(elementTextContent, tag)) {
          p.setAttribute('tag-id', tagId);
          createExtender(p);
          removeDataIdAttribute(e);
          continue;
        }

        if (id.startsWith('an|')) {
          viewsMap[id] = [];
        }

        if (tag) {
          let n = p.nextElementSibling;

          let seen = 1;
          while (seen > 0 && n) {
            if (![...n.classList].includes('tag')) {
              n = n.nextElementSibling;
              continue;
            }

            const rawTag = n.firstElementChild.textContent;
            const nTag = cleanTag(rawTag);

            if (nTag && isOpeningTag(rawTag, nTag)) {
              if (id.startsWith('an|')) {
                viewsMap[id].push(n.dataset);
              }
            }

            if (nTag == tag && isOpeningTag(rawTag, tag)) {
              seen++;
            }
            if (nTag == tag && isClosingTag(rawTag, tag)) {
              seen--;
            }

            if (seen > 0) {
              n = n.nextElementSibling;
            }
          }
          p && p.setAttribute('tag-id', tagId);
          n && n.setAttribute('tag-id', tagId);
          createExtender(p);
          createExtender(n);
        }

        removeDataIdAttribute(e);
      }

      Object.keys(viewsMap).forEach((key) => {
        viewsMap[key] = viewsMap[key].map((obj) => obj['id']);
      });

      ctx.listeners['update-generated-views'] && ctx.listeners['update-generated-views']({ viewsMap });
    };

    addPropsElementsEvents();

    const onAfterEnter = (el) => {
      ctx.listeners['onRender'] && ctx.listeners['onRender']({});
      addPropsElementsEvents();
      if (['markup', 'jsx', 'html'].includes(language)) {
        let nodes = el.childNodes[0].childNodes;
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (node.nodeType === 3 && node.textContent.trim().length > 1) {
            const span = document.createElement('span');
            node.after(span);
            span.appendChild(node);
          }
        }
      }
    };
    const TransformLayout = (html) => {
      const el = new DOMParser().parseFromString(html, 'text/html');
      switch (mode) {
        case 'styleguide':
          if (language == 'css' || language == 'sass') {
            markStyleguideTokenClickable(el);
            if (type == 'class') {
              markStyleguideClassClickable(el);
              wrapStyleguideClasses(el);
            }
          }
          break;

        case 'usageCode':
          if (language == 'html' || language == 'jsx') {
            markPropsClickable(el);
            createPropCheckbox(el);
            markComponentNameClickable(el);
          }
          break;

        case 'default':
          if (language == 'markup' || language == 'jsx' || language == 'html') {
            // REACT & HTML & VUE
            markClassnamesClickable(el);
            mapElementToDataId(el);

            // REACT & VUE
            if (language == 'jsx' || language == 'html') {
              markComponentsNameClickable(el);
            }
          }
          break;

        default:
          break;
      }

      return el.body.innerHTML;
    };

    return h(
      'transition',
      {
        props: {
          appear: true,
          name: 'fadeIn'
        },
        on: {
          afterEnter: onAfterEnter
        }
      },
      [
        h(
          'pre',
          assign({}, ctx.data, {
            class: [ctx.data.class, className, ctx.props.plugins.join(' ')]
          }),
          [
            h('code', {
              class: [className, ctx.props.plugins.join(' ')],
              attrs: {
                id: mode == 'styleguide' ? 'contentArea' : ''
              },
              ref: 'code',
              on: {
                click: handleCodeclick,
                dblclick: handleCodeDblClick
              },
              domProps: {
                innerHTML: TransformLayout(Prism.highlight(code, prismLanguage))
              }
            })
          ]
        )
      ]
    );
  }
};

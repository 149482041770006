import AnimaSocket from '@/plugins/webSocket';

export default {
  data() {
    return {
      socket: null
    };
  },
  destroyed() {
    this.closeSocket();
  },
  methods: {
    openSocket(channel) {
      this.closeSocket();
      if (channel) {
        this.socket = new AnimaSocket(channel);
      }
    },
    closeSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    }
  }
};

<template>
  <div :style="getStyles" class="two-dots">
    <div class="dot1" />
    <div class="dot2" />
  </div>
</template>

<script>
export default {
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 60
    }
  },
  computed: {
    getStyles() {
      return {
        margin: this.noMargin ? '0' : '50px auto',
        '--dot-size': this.size + 'px',
        '--container-size': this.size + 40 + 'px'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
// FULL SCREEN LOADING
.two-dots {
  width: var(--container-size);
  height: var(--container-size);
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: var(--dot-size);
  height: var(--dot-size);
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--primary);
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>

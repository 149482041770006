<template>
  <header class="header">
    <div @click="handleViewChange(view.name)" v-for="view in views" :key="view.name" class="view-selector">
      <svg-icon :name="view.icon"></svg-icon>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      views: [
        { name: 'overrides', icon: 'code' },
        { name: 'comments', icon: 'comments' },
        { name: 'assets', icon: 'assets' }
      ]
    };
  },
  methods: {
    handleViewChange(name) {
      this.$emit('viewChange', name);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: var(--omniview-topbar-height);
  min-height: var(--omniview-topbar-height);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #404040;
  flex-shrink: 0;
}
.view-selector {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <div :class="['checkbox-container', { full, inversed, noMargin }]" @click="handleContainerClick">
    <input type="checkbox" :checked="value" hidden />
    <div
      :class="['checkbox', { disabled, noMargin }, { checked: value }]"
      v-html="require(`!html-loader!./${checkedStatus}.svg`)"
      @click.stop="onChange"
      :data-cy="dataCy"
    />
    <div v-if="$slots.default" class="checkbox-label" :style="labelStyle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-checkbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    inversed: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String
    },
    labelStyle: {
      type: Object,
      default: () => ({ marginLeft: '20px' })
    }
  },
  computed: {
    checkedStatus() {
      let suffix = this.variant == 'dark' ? '-dark' : '';
      return this.value ? `checked${suffix}` : `unchecked${suffix}`;
    }
  },
  methods: {
    handleContainerClick() {
      if (this.full) {
        this.onChange();
      }
    },
    onChange() {
      if (!this.disabled) {
        this.$emit('input', !this.value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;

  align-items: flex-start;
  justify-content: flex-start;
  + .checkbox-container {
    margin-top: 20px;
  }

  &.full {
    cursor: pointer;
  }
  &.inversed {
    cursor: pointer;
    flex-direction: row-reverse;
    .checkbox-label {
      margin-left: 0px;
    }
    .checkbox {
      margin-left: auto;
    }
  }
}
.checkbox {
  margin-top: 5px;
  &.noMargin {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
  &.disabled:not(.checked) {
    background: rgba(255, 255, 255, 0.4);
  }
}
</style>

<template>
  <v-tip trigger="click" offset="4" :open="isMounted">
    <slot></slot>
    <template slot="popover">
      <div class="container">
        <div v-for="item in items" :key="item.familyName" class="item">
          <div class="title">
            {{ item.familyName }}
          </div>
          <div class="action">
            <svg-icon v-if="isUploaded[item.familyName]" name="circle-check" :size="24" />
            <Upload
              v-else
              :url="uploadUrl"
              :accept="uploadAccept"
              :context="{ familyName: item.familyName }"
              @start="onUploadStart"
              @success="onUploadSuccess"
              @failure="onUploadFailure"
            >
              <an-button class="upload-btn" variant="empty" size="sm" :isWorking="isLoading[item.familyName]">
                Upload
              </an-button>
            </Upload>
          </div>
        </div>
        <div class="done">
          <an-button @click="doneUploading" variant="secondary" size="sm" v-close-popover>Done</an-button>
        </div>
      </div>
    </template>
  </v-tip>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { toastError } from '@/services/bus';
import Upload from '@/components/Upload/Upload';
import errorHandler from '@/services/errorHandler';

export default {
  components: {
    Upload
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isMounted: false,
      isLoading: {},
      isUploaded: {}
    };
  },
  mounted() {
    this.items.forEach((font) => {
      this.$set(this.isLoading, font.familyName, false);
      this.$set(this.isUploaded, font.familyName, false);
    });
    setTimeout(() => {
      this.isMounted = true;
    }, 3000);
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    uploadUrl() {
      return `/v2/uploads/projects/${this.project.id}/font`;
    },
    uploadAccept() {
      return '.otf,.ttf,.woff,.woff2,.eot,.svg';
    }
  },
  methods: {
    ...mapActions({
      createFont: 'fonts/create'
    }),
    onUploadStart(context) {
      this.isLoading[context.familyName] = true;
      this.$trackEvent('missing-fonts.upload-file-button.click');
    },
    onUploadSuccess(responseData) {
      this.$trackEvent('missing-fonts.upload-file.success');
      const familyName = responseData.context.familyName;
      const filename = responseData.filename;
      const fileURL = responseData.public_url;
      this.createFontResource(familyName, filename, fileURL);
    },
    onUploadFailure({ error, context }) {
      errorHandler.captureExceptionAndTrack(error, { name: 'missing-fonts.upload-file.failure' });

      this.isLoading[context.familyName] = false;
      toastError('Failed uploading file :(');
    },
    async createFontResource(familyName, filename, fileURL) {
      try {
        this.isCreatingFont = true;
        await this.createFont({
          parent: 'projects',
          id: this.project.id,
          payload: {
            family: familyName,
            file_name: filename,
            file_url: fileURL
          }
        });
        this.isUploaded[familyName] = true;
      } catch (error) {
        errorHandler.captureException(error);
        let errorMsg = error?.response?.data?.message || 'Failed uploading font...';
        toastError(errorMsg);
      } finally {
        this.isCreatingFont = false;
        this.isLoading[familyName] = false;
      }
    },
    doneUploading() {
      this.$emit('done');
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  background: var(--dark-onboarding-background);
  width: 336px;
  color: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  flex-direction: column;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 20px;
  }

  .title {
    font-size: 14px;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
    opacity: 0.4;
  }

  .action {
    color: var(--primary);
    cursor: pointer;
    font-size: 12px;
    z-index: 999;
  }
  .hr {
    height: 1px;
    width: 100%;
    background: #767676;
  }

  .upload-btn {
    padding: 0;
  }

  .done {
    margin: 20px;
  }
}
</style>

<template>
  <div>
    <div class="container" v-if="!isLoading">
      <div class="illustration">
        <img src="@/assets/illustrations/telescope.svg" />
      </div>
      <div class="title">You are a viewer</div>
      <div class="subtitle">Only <span class="bold">contributors &amp; admins</span> can sync a design.</div>
      <div class="actions">
        <an-button @click="onDisclaimerCtaClick" :isWorking="isWorking">{{ disclaimerCTA }}</an-button>
        <an-link @click="onCancel" primaryHover>Nevermind</an-link>
      </div>
    </div>
  </div>
</template>

<script>
import { toastError, toastSuccess } from '@/services/bus';
import { TeamMembershipMixin } from '@/mixins';
import errorHandler from '@/services/errorHandler';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'viewer-access',
  data() {
    return {
      isLoading: false,
      isWorking: false
    };
  },
  props: {
    context: {
      type: String,
      default: 'viewer-access'
    },
    load: {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    if (this.load) {
      await this.loadTeamMemberships();
    }
    if (!this.isUserViewer) {
      this.$emit('close', { redirect: { name: 'team' } });
    }
  },
  mixins: [TeamMembershipMixin],
  computed: {
    ...mapGetters({ hasPermissions: 'teamMemberships/hasPermissions' }),
    isUserViewer() {
      const { teamSlug } = this.$route.params;
      const hasViewerPermissions = this.hasPermissions({ teamSlug, accessLevel: 'viewer' });
      const hasContributorPermissions = this.hasPermissions({ teamSlug, accessLevel: 'contributor' });
      return hasViewerPermissions && !hasContributorPermissions;
    },
    isUserTeamOwner() {
      const { teamSlug } = this.$route.params;
      return this.hasPermissions({ teamSlug, role: 'owner' });
    },
    disclaimerCTA() {
      if (this.isUserTeamOwner) return 'Change your role';
      return 'Request access';
    }
  },
  methods: {
    ...mapActions({ fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships' }),
    async handleRequestAccess() {
      try {
        this.isWorking = true;

        this.$trackEvent(`${this.context}.request-access.click`);
        await this.askForAccess();

        this.$trackEvent(`${this.context}.request-access.success`);
        toastSuccess('Successfully sent request for access!');
        this.$emit('close');
      } catch (err) {
        if (err?.response?.status === 409) {
          toastError('You already requested access over the past week.');
        } else {
          toastError("Failed to request access. We've been notified and we will investigate!");
        }

        errorHandler.captureExceptionAndTrack(err, {
          name: `${this.context}.request-access.failure`,
          data: { message: err.message }
        });
      } finally {
        this.isWorking = false;
      }
    },
    onDisclaimerCtaClick() {
      if (this.isUserTeamOwner) {
        this.$trackEvent(`${this.context}.change-role.click`);
        return this.$router.push({ name: 'team-members' });
      }
      return this.handleRequestAccess();
    },
    async loadTeamMemberships() {
      try {
        this.setLoading(true);
        await this.fetchUserMemberships({ id: 'me', skipCache: true });
      } finally {
        this.setLoading(false);
      }
    },
    setLoading(value) {
      this.isLoading = value;
      this.$emit('loading', value);
    },
    onCancel() {
      this.$trackEvent(`${this.context}.change-role.cancel`);
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  align-self: center;
  justify-self: center;
}
.illustration {
  height: 180px;
}
</style>

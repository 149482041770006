import { render, staticRenderFns } from "./PricingColumn.vue?vue&type=template&id=57975846&scoped=true&"
import script from "./PricingColumn.vue?vue&type=script&lang=js&"
export * from "./PricingColumn.vue?vue&type=script&lang=js&"
import style0 from "./PricingColumn.vue?vue&type=style&index=0&id=57975846&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57975846",
  null
  
)

export default component.exports
import errorHandler from '@/services/errorHandler';
import { uuid } from '@/utils/uuid';
import { createStoreModule } from '../utils';

const DEVICE_ID_LOCAL_STORAGE_KEY = 'andid';

const currentDeviceExists = (devices, deviceId) => {
  if (!devices) return false;
  return devices.some((device) => device.device_id === deviceId);
};

const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);
  if (!deviceId) {
    deviceId = uuid({ withPrefix: false, length: 36 });
    localStorage.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, deviceId);
  }
  return deviceId;
};

export default createStoreModule({
  name: 'devices',
  modelName: 'devices',
  crud: true,
  endpoint: '/v2/devices',
  state: {
    isCreatingDevice: false,
    currentDeviceID: getDeviceId(),
    devicesLimit: 2
  },
  mutations: {
    setCurrentDeviceId: (s, deviceId) => (s.currentDeviceID = deviceId),
    setIsCreatingDevice: (s, isCreatingDevice) => (s.isCreatingDevice = isCreatingDevice)
  },
  actions: {
    async createDevice({ state, commit, dispatch }) {
      if (state.isCreatingDevice) return;
      commit('setIsCreatingDevice', true);
      const currentDeviceID = state.currentDeviceID;
      const newDevice = await dispatch('create', {
        id: 'me',
        parent: 'users',
        payload: { device_id: currentDeviceID },
        storeResult: true
      });
      await dispatch('fetchAllOfParent', { id: 'me', parent: 'users', skipCache: true });
      commit('setIsCreatingDevice', false);
      return newDevice;
    },
    deleteCurrentDevice({ state, dispatch }) {
      const currentDeviceID = state.currentDeviceID;
      const currentDevice = state.items.find((device) => device.device_id === currentDeviceID);
      if (!currentDevice) return;
      return dispatch('delete', currentDevice);
    },
    async isDeviceLimitReached({ state, dispatch, rootGetters }) {
      try {
        // under a/b test for now
        const isDevicesLimitActive = rootGetters['experiments/isDevicesLimitActive'];
        if (!isDevicesLimitActive) return false;

        // omg i hate edge cases!!!!!
        // don't run on cypress tests
        // don't create two devices the same time kthx
        // don't count impersonated devices
        if (window.Cypress || state.isCreatingDevice || localStorage.getItem('animp')) {
          return false;
        }

        // get current device id
        let { currentDeviceID } = state;
        if (!currentDeviceID) return false;

        // fetch list of the user's devices
        await dispatch('fetchAllOfParent', { id: 'me', parent: 'users' });

        // if the device is already in the list, return false
        if (currentDeviceExists(state.items, currentDeviceID)) {
          return false;
        }

        // if the user is eligible for another device, return false
        if (state.count < state.devicesLimit) {
          await dispatch('createDevice');
          return false;
        }

        // user has reached the limit of devices
        return true;
      } catch (error) {
        errorHandler.captureExceptionAndTrack(error, { name: 'webapp.devices-limit-check.failure' });
        return false;
      }
    }
  }
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"rootCont",staticClass:"code-container"},[_c('div',{staticClass:"side-panel"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRegenerateCodegenReq && _vm.isPanelOpen),expression:"showRegenerateCodegenReq && isPanelOpen"}],staticClass:"regenerate-codegen-req",on:{"click":_vm.regenerateCodegenReq}},[_c('img',{attrs:{"src":require("@/assets/svg/refresh-icon.svg")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentNodeCSS && _vm.isPanelOpen),expression:"currentNodeCSS && isPanelOpen"}],staticClass:"feedback",on:{"click":_vm.handleSendFeedback}},[_c('span',{attrs:{"data-cy":"give-feedback"}},[_vm._v("Feedback")])])]),_c('Splitpanes',{on:{"resized":_vm.handlePanelResized}},[_c('Pane',{staticClass:"flex flex-col",attrs:{"size":_vm.paneSize.HTML,"max-size":"60","min-size":"25","data-cy":"code-panel-lang"}},[_c('CodeTab',{attrs:{"codeIcon":`code-icons/${_vm.codegenLangFramework}`,"text":_vm.codegenLangLabel,"menuOptions":_vm.getMenuOptionsForTab(_vm.codegenLang, _vm.codegenLangLabel),"data-cy":"framework-tab-name"},on:{"menu-item-selected":function($event){return _vm.handleTabMenuAction($event, 'lang')},"menu-opened":function($event){return _vm.handleTabMenuOpened('lang')}}}),_c('div',{staticClass:"flex flex-col hide-scroll",style:({ position: 'relative', overflowY: 'auto', flex: 1, '--html-pane-width': _vm.htmlPaneWidth + 'px' }),on:{"copy":function($event){return _vm.onCopy($event, 'html')}}},[_c('div',{ref:"tagBg",staticClass:"tag-bg"}),_c('div',{ref:"html-code",staticClass:"code-scroller",style:({
            flex: 1,
            width: '100%',
            overflowY: _vm.currentNodeCleanMarkup ? 'auto' : 'hidden'
          })},[_c('Prism2',{ref:"prismHTML",attrs:{"data-cy":"html-code","code":_vm.currentNodeCleanMarkup,"isEmpty":!_vm.selected,"language":_vm.getLanguage,"loading":_vm.selected && _vm.isGeneratingCode && !_vm.isExportingCodeComponent,"disableSelection":_vm.shouldShowPaywall},on:{"nodeMouseOver":_vm.handleNodeMouseOver,"nodeMouseLeave":_vm.handleNodeMouseLeave,"update-generated-views":_vm.updateGeneratedViews,"onRender":_vm.updateHTMLPanelWidth,"handle-class-click":_vm.handleClassClick}})],1)])],1),_c('Pane',{staticClass:"flex flex-col",attrs:{"size":_vm.paneSize.CSS,"min-size":"25","data-cy":"code-panel-css"}},[_c('CodeTab',{attrs:{"codeIcon":`code-icons/${this.styleType === 'css' ? 'css' : 'sass'}`,"text":_vm.styleTypeLabel,"menuOptions":_vm.getMenuOptionsForTab(_vm.styleType, _vm.styleTypeLabel),"data-cy":"css-tab-name"},on:{"menu-item-selected":function($event){return _vm.handleTabMenuAction($event, 'css')},"menu-opened":function($event){return _vm.handleTabMenuOpened('css')}}}),_c('div',{staticClass:"flex flex-col",style:({ position: 'relative', flex: 1, overflowY: 'auto' })},[_c('div',{ref:"css-code",staticClass:"code-scroller",style:({
            flex: 1,
            width: '100%',
            overflowY: _vm.currentNodeCleanCSS ? 'auto' : 'hidden'
          }),on:{"copy":function($event){return _vm.onCopy($event, 'css')}}},[_c('Prism2',{ref:"prismCSS",attrs:{"data-cy":"css-code","mode":"css","plugins":['inline-color'],"language":_vm.codegenStylesheetLang,"isEmpty":!_vm.selected,"loading":_vm.selected && _vm.isGeneratingCode && !_vm.isExportingCodeComponent,"code":_vm.currentNodeCleanCSS,"disableSelection":_vm.shouldShowPaywall},on:{"css-variable-click":_vm.handleCSSVariableClick}})],1)])],1),(_vm.isStyleguideOpen)?_c('Pane',{staticClass:"flex flex-col",attrs:{"size":_vm.paneSize.styleguide,"min-size":"33.33","data-cy":"code-panel-styleguide"}},[_c('CodeTab',{attrs:{"isExpanded":"","showExpandButton":"","codeIcon":"code-icons/css","text":"Styleguide","menuOptions":_vm.getMenuOptionsForTab('styleguide', 'Styleguide')},on:{"expand-clicked":function($event){return _vm.setIsStyleguideOpen(!_vm.isStyleguideOpen)},"menu-item-selected":function($event){return _vm.handleTabMenuAction($event, 'styleguide')},"menu-opened":function($event){return _vm.handleTabMenuOpened('styleguide')}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isStyleguideOpenCopy),expression:"isStyleguideOpenCopy"}],staticClass:"flex flex-col",style:({ position: 'relative', flex: 1, overflow: 'hidden' }),attrs:{"id":"styleguidePane"},on:{"copy":function($event){return _vm.onCopy($event, 'styleguide')}}},[_c('div',{ref:"styleguide-code",staticClass:"code-scroller",style:({
            flex: 1,
            width: '100%',
            overflowY: _vm.currentStylesheet.full ? 'auto' : 'hidden'
          })},[_c('Prism2',{ref:"prismStyleguide",attrs:{"mode":"styleguide","plugins":['inline-color'],"language":"css","code":_vm.currentStylesheet.full,"disableSelection":_vm.shouldShowPaywall}})],1)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
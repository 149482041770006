<template>
  <div class="container">
    <div class="title">Your code is ready!</div>
    <div class="body">
      If your download doesn't start automatically,
      <br />
      click on the download button. <br />
      <br />
      <Divider theme="dark" />
      <div data-cy="package-url" class="hidden-url">{{ downloadUrl }}</div>
      <div class="click">
        <an-button variant="secondary" @click="download">Download</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@/utils/javascript';
import { timeout } from '@/utils/sugar';
import Divider from '@/components/Divider/Divider';
import { mapGetters } from 'vuex';

export default {
  name: 'package-ready',
  props: {
    downloadUrl: {
      type: String,
      required: true
    },
    cached: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Divider
  },
  async mounted() {
    const eventPayload = this.omniviewFrameworkPayload;
    this.$trackEvent('omniview.package-download-ready-modal.show', eventPayload);
    if (!this.cached) {
      await timeout(1000);
      this.download();
    }
  },
  methods: {
    download() {
      downloadFile(this.downloadUrl);
    },
    close() {
      this.$trackEvent('omniview.package-download-ready-modal.close');
      this.$emit('close');
    }
  },
  computed: {
    ...mapGetters({
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    })
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  @include headline;
  font-weight: 500;
}

.body {
  margin: 20px 0 30px;
  color: var(--dark-secondary-text);
}
.click {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;

  > .an-button {
    justify-content: center;
  }
}

.hidden-url {
  display: none;
}
</style>

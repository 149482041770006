import { slugify } from './javascript';

// match one hex character (a digit between 0-9 or a letter between A-F)
const hex = '[\\dA-F]';
// will match strings of format: 5C8C414D-F8F3-4B4D-BBDD-30522E6439AE
const hashRegex = `${hex}{8}-${hex}{4}-${hex}{4}-${hex}{4}-${hex}{12}`;
// will match strings of format: @1x, @2x
const imageSizeRegex = '@\\dx';
// will match string that ends with: .png, .jpg, .svg, .Donald_J_Trump
const extensionRegex = '\\.\\w+$';
// will match: something@2x.jpg
const imageSizeAndExtRegex = new RegExp(`(?:${imageSizeRegex})(${extensionRegex})`);
// will match: something-5C8C414D-F8F3-4B4D-BBDD-30522E6439AE.jpg
const hashAndExtRegex = new RegExp(`-(?:${hashRegex})(${extensionRegex})`);
// will match: -.png
const dashExtSuffix = new RegExp(`-(${extensionRegex})`);

// turns `something-5C8C414D-F8F3-4B4D-BBDD-30522E6439AE@1x.jpg` to `something.jpg`
export const prettifyAssetFilename = (filename, screenName) => {
  if (!filename) return;
  let newName = filename?.replace(imageSizeAndExtRegex, '$1').replace(hashAndExtRegex, '$1');

  const slug = screenName && slugify(screenName);
  if (slug && !newName.match(`^${slug}-${extensionRegex}$`)) {
    newName = newName.replace(slug, '');
  }

  // remove dashes from start and end, and turn multiple dashes into single ones.
  return newName.replace(/^-/, '').replace(dashExtSuffix, '$1').replace(/-+/g, '-');
};

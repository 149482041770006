<template>
  <UploadImage :url="uploadUrl" :src="src" :height="size" :width="size" circle @success="$emit('success')" />
</template>

<script>
import UploadImage from '@/components/Upload/UploadImage';
import { getCdnUrl } from '@/utils/urls';

export default {
  data() {
    return {
      uploadUrl: '/v2/uploads/users/me/profile_picture_url'
    };
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 160
    }
  },
  components: {
    UploadImage
  },
  computed: {
    src() {
      const { user, size } = this;
      const pos = 'centre';
      return getCdnUrl(user?.profile_picture_url, { size, pos });
    }
  }
};
</script>

import api from '@/api';
import Stigg from '@stigg/js-client-sdk';
import Sentry from '@/plugins/sentry';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

let _stiggClient = null;

export const getStiggClient = async () => {
  if (!_stiggClient) {
    _stiggClient = Stigg.initialize({
      apiKey: process.env.STIGG_API_KEY
    });
  }
  await _stiggClient.waitForInitialization();
  return _stiggClient;
};

export const StiggPlan = {
  FREE: 'plan-anima-free',
  PRO: 'plan-anima-pro',
  BUSINESS: 'plan-anima-business',
  CUSTOM: 'plan-anima-custom'
};

export const withCustomerId = async (customerId, callback) => {
  const stiggClient = await getStiggClient();
  const release = await mutex.acquire();
  try {
    await stiggClient.setCustomerId(customerId);
    return await callback(stiggClient);
  } finally {
    release();
  }
};

export const getActivePlanName = async (team_id) => {
  return (await getActiveSubscription(team_id))?.plan?.displayName;
};

export const getActiveSubscription = async (team_id) => {
  return await withCustomerId(team_id, async (stiggClient) => {
    try {
      const activeSubscriptions = await stiggClient.getActiveSubscriptions();
      return activeSubscriptions?.[0];
    } catch (err) {
      Sentry?.captureException(err);
      return {};
    }
  });
};

export const getCustomer = async (team_id) => {
  return await withCustomerId(team_id, async (stiggClient) => {
    try {
      return await stiggClient.getCustomer();
    } catch (err) {
      Sentry?.captureException(err);
      return {};
    }
  });
};

export const provisionSubscription = async ({
  customerId,
  planId,
  unitQuantity,
  addons = [],
  billingPeriod,
  billableFeatures,
  countryCode = 'US',
  successUrl
}) => {
  const payload = {
    team_id: customerId,
    plan_id: planId,
    unit_quantity: unitQuantity,
    billing_period: billingPeriod,
    cancel_url: window.location.href,
    success_url: successUrl,
    addons,
    country_code: countryCode,
    billable_features: billableFeatures
  };

  const { data: result } = await api.post('/rpc/provision-subscription', payload);
  if (!result) {
    throw new Error('No result returned from provision subscription');
  }
  window.location = result.status === 'PAYMENT_REQUIRED' ? result.checkoutUrl : payload.success_url;
};

export const cancelUpdates = async (teamId) => {
  const payload = {
    team_id: teamId
  };
  await api.post('/rpc/cancel-plan-update', payload);
};

export const updateSubscription = async (subscriptionId, unitQuantity) => {
  const payload = {
    subscription_id: subscriptionId,
    quantity: unitQuantity
  };

  await api.post('/rpc/update-subscription', payload);
};

export const cancelSubscription = async (teamId) => {
  const payload = {
    team_id: teamId
  };

  await api.post('/rpc/cancel-subscription', payload);
};

export const confirmPayment = async (teamSlug) => {
  const payload = {
    team_slug: teamSlug
  };
  await api.post('/rpc/confirm-subscription', payload);
};

export const estimateSubscription = async ({
  customerId,
  planId,
  billingPeriod,
  unitQuantity,
  addons = [],
  subscriptionId = null,
  countryCode,
  billableFeatures
}) => {
  if (!planId) {
    return null;
  }

  const payload = {
    customerId,
    planId,
    unitQuantity,
    billingPeriod,
    addons,
    billingCountryCode: countryCode,
    billableFeatures
  };

  if (subscriptionId) {
    payload.subscriptionId = subscriptionId;
  }

  const stiggClient = await getStiggClient();
  return await stiggClient.estimateSubscription(payload);
};

export const getStiggFeatureLimit = async (teamId, featureId) => {
  return await withCustomerId(teamId, async (stiggClient) => {
    try {
      await stiggClient.getCustomer();
      const entitlements = await stiggClient.getEntitlements();
      const matchingEntitlement = entitlements.find((e) => e.feature.id === featureId);
      if (matchingEntitlement) {
        return matchingEntitlement.usageLimit;
      }
    } catch (err) {
      Sentry?.captureException(err);
      return {};
    }
    return 0;
  });
};

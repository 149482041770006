<template>
  <div ref="bound" class="popover-container" tabindex="-1">
    <div ref="reference" @click.stop="open" :data-cy="dataCy">
      <slot name="reference" />
    </div>
    <Popover
      v-if="items.length > 0"
      :popperClass="popperClass"
      :position="position"
      :isOpen="isOpen"
      :style="popoverStyle"
      :theme="theme"
      closeOnOutsideClick
      @close="close"
    >
      <div v-for="(item, index) in items" :key="index" :class="getStyle(item)" @click.stop="onClick(item)">
        <span v-if="item.preText" class="pre-text">{{ item.preText }}</span>
        <an-link
          :data-cy="item.dataCy"
          :variant="item.isMarked ? 'primary' : 'secondary'"
          :theme="theme"
          :disabled="item.disabled"
        >
          <span v-html="item.label" />
        </an-link>
      </div>
    </Popover>
  </div>
</template>

<script>
import Popover from '@/components/Popovers/Popover';
export default {
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    items: {
      type: Array
    },
    position: {
      type: String,
      default: 'left'
    },
    selectedLabel: {
      type: String,
      default: null
    },
    popperClass: {
      type: String,
      default: null
    },
    popoverStyle: {
      type: Object,
      default: () => ({})
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['dark', 'light'].includes(value)
    },
    dataCy: {
      type: String
    }
  },
  components: {
    Popover
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(e) {
      const bound = this.$refs.bound;
      const reference = this.$refs.reference;
      if (!bound || !reference) return;

      if (bound && !bound.contains(e.target)) {
        this.close();
      }
    },
    open() {
      this.isOpen = true;
    },
    onClick(item) {
      if (item.onClick) {
        item.onClick();
      } else {
        this.$emit('click', item);
      }
      this.close();
    },
    close() {
      this.isOpen = false;
    },
    getStyle(item) {
      return {
        item: true,
        separate: item.isSeparated,
        selected: item.label === this.selectedLabel,
        boldOnHover: !item.preText
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.popover-container {
  display: inline-block;
  position: relative;
  outline: none;
}
.item {
  width: 100%;
  cursor: pointer;
  &.selected,
  &.boldOnHover:hover {
    font-weight: bold;
  }
  + .item {
    margin-top: 16px;
  }
  &.separate {
    border-top: var(--border);
    padding-top: 16px;
  }
  &.marked {
    color: var(--primary);
  }
}
.pre-text {
  cursor: text;
  font-weight: normal;
}
</style>

<template>
  <div class="note-container">
    <div class="header">{{ header }}</div>
    <div class="subheader">{{ subheader }}</div>
    <div class="actions flex">
      <div @click="handleSendFeedback">{{ ctaFeedbackText }}</div>
      <div @click="clickOnWhatsNew">{{ ctaNewText }}</div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapMutations } from 'vuex';
export default {
  name: 'an-note',
  data() {
    return {
      header: 'You are using our new React Code-gen! 🎉',
      subheader: 'We’d love to get your feedback & requests',
      ctaFeedbackText: 'Provide feedback',
      ctaNewText: 'See what’s new',
      url: 'https://www.animaapp.com/blog/design-to-code/introducing-codegen-2-0-reusable-react-components-and-typescript/'
    };
  },
  methods: {
    ...mapMutations({
      setIsCodePreferencesRequested: 'omniview/setIsCodePreferencesRequested',
      setConsiderUrlForCodePreferencesBePresented: 'omniview/setConsiderUrlForCodePreferencesBePresented'
    }),
    async handleSendFeedback() {
      EventBus.$emit('TOGGLE_CODE_FEEDBACK', { channel: 'athena-feedback' });
      this.setConsiderUrlForCodePreferencesBePresented(false);
      this.setIsCodePreferencesRequested(false);
    },
    clickOnWhatsNew() {
      window.open(this.url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.note-container {
  display: inline-block;
  position: relative;
  background-color: var(--darker-blue);
  border: 1px solid var(--blue);
  border-radius: 4px;
  text-align: left;
  padding: 12px;
  margin-top: 24px;
  font-size: 12px;
  line-height: 18px;
  .header {
    font-weight: bold;
  }

  .actions {
    cursor: pointer;
    color: var(--dark-blue);
    font-weight: bold;
    display: flex;
    gap: 15px;
    margin-top: 10px;
    flex-direction: row;
    align-items: flex-start;
  }
}
</style>

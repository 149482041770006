<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <div class="container">
      <img class="illustration-paired" src="@/assets/illustrations/figma-dev-mode.png" />
      <div class="title">Welcome to Anima Dev Mode</div>
      <div class="subtitle">
        Turn your Figma into React JSX/TSX components.<br />
        <span v-if="!isMobile">To get started, run Anima’s plugin in Figma Dev Mode.</span>
      </div>
      <div class="actions">
        <an-button @click="handleOpen">Run the Figma plugin</an-button>
      </div>
      <div v-if="isMobile" class="not-supported-mobile">*Figma plugins are not yet supported on mobile</div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';

export default {
  components: {
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.$trackEvent('signup.intent');
  },
  methods: {
    handleOpen() {
      this.$trackEvent('intent.open-platform-link.click', {
        platform: 'Figma',
        device: this.isMobile ? 'mobile' : 'desktop'
      });
      window.location.replace('https://www.figma.com/community/plugin/857346721138427857');
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
}
.illustration-paired {
  max-width: 720px;
  width: 80%;
  @include mobile {
    margin-top: 50px;
  }
}
.title {
  margin-top: 40px;
  @include headline;
  font-weight: 400;
}

.not-supported-mobile {
  position: absolute;
  bottom: 50px;
  background-color: #f8d57e;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  color: black;
}
</style>

<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/ashamed-man.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="body">
      <an-input
        v-model="teamName"
        placeholder="Name your new team"
        @keyup.enter="create"
        data-cy="new-team-name"
        focus
      />
    </div>
    <div class="actions" v-if="teamName.trim().length > 0">
      <an-button @click="create" :isWorking="isWorking" data-cy="create-team-button">Create team</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/bus';
import { mapActions, mapState, mapMutations } from 'vuex';
import errorHandler from '@/services/errorHandler';

export default {
  name: 'new-team',
  data() {
    return {
      title: 'Create a new team',
      teamName: '',
      isWorking: false
    };
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ctaData() {
      return {
        label: 'Create',
        loading: this.isWorking
      };
    }
  },
  methods: {
    ...mapActions({
      createTeam: 'teams/create',
      createProject: 'projects/create',
      reloadUserTeams: 'teamMemberships/fetchAllUserMemberships',
      fetchMemberships: 'teamMemberships/fetchAllTeamMemberships'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    async create() {
      const parent = 'users';
      const id = 'me';
      const payload = { name: this.teamName };

      const trimmedName = this.teamName.trim();

      if (trimmedName === '' || !this.teamName) {
        toastError('Cannot create a team without a team name');
        errorHandler.captureException('cannot create a team without a team name');
        return;
      }

      this.teamName = trimmedName;
      payload.name = trimmedName;

      this.$trackEvent('new-team.save-button.click');

      try {
        this.isWorking = true;
        const team = await this.createTeam({ parent, id, payload });
        const projectName = this.user?.name?.split(' ')[0] + "'s project";
        this.setCurrentTeam(team);
        await Promise.all([
          this.reloadUserTeams({ parent, id, skipCache: true }),
          this.createProject({
            parent: 'teams',
            id: team.id,
            payload: { name: projectName, is_default_project: true },
            storeResult: true
          }),
          this.fetchMemberships({ parent: 'team', id: team.slug, params: { is_slug: true } })
        ]);
        this.$trackEvent('new-team.save.success');
        this.$router.push({ name: 'team-members', params: { teamSlug: team.slug, eventSource: 'new-team' } });
      } catch (err) {
        this.$trackEvent('new-team.save.failure', { message: err.message });
        toastError('Failed creating new team');
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.body {
  width: 400px;
}
</style>

<template>
  <div class="an-select" :tabindex="0" @blur="open = false">
    <div
      class="selected"
      :class="{
        open: open,
        placeholder: !selected
      }"
      @click="open = !open"
    >
      {{ selected ? getSelectedString() : placeholder }}
    </div>
    <div class="options" :class="{ hide: !open }">
      <div
        class="option"
        v-for="(option, index) of _options"
        :key="index"
        :data-cy="`option-${option.value.replace(/\s+/g, '-').toLowerCase()}`"
        @click="selectItem(option)"
      >
        <span>{{ option.label }}</span>
        <svg-icon v-if="selected === option.label" :size="18" class="check-icon" name="plus"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-select',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    value: {
      type: String
    },
    prefix: {
      type: String
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: '',
      open: false
    };
  },
  watch: {
    value(val) {
      this.selected = val;
    }
  },
  mounted() {
    if (this.value) {
      const value = this._options.find((option) => option.value === this.value);
      this.selected = value.label;
    }
  },
  computed: {
    _options() {
      return this.options.map((option) => {
        if (typeof option === 'string') {
          return { label: option, value: option.toLowerCase() };
        } else return option;
      });
    }
  },
  methods: {
    getSelectedString() {
      if (this.prefix) {
        return this.prefix + ' ' + this.selected;
      }
      return this.selected;
    },

    selectItem(option) {
      this.selected = option.label;
      this.open = false;
      this.$emit('input', option.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.an-select {
  position: relative;
  text-align: left;
  outline: none;
}

.selected {
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: space-between;
  text-indent: 10px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid #ebeef5;
  color: #272833;
  cursor: pointer;
  user-select: none;

  &.placeholder {
    font-size: 14px;
    color: #c1c7cd;
  }
  &.open {
    border-radius: 2px;
    border: 1px solid #409eff;
    transition: border-color 0.2s linear;
  }
  &::after {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url('~@/assets/svg/chevron-down.svg');
  }
}
.options {
  color: #272833;
  border-radius: 2px;
  overflow: hidden;
  border-color: #ebeef5;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-indent: 21px;
  max-height: 192px;
  overflow: auto;
}

.option {
  color: #272833;
  cursor: pointer;
  user-select: none;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ecf5ff;
  }
  .check-icon {
    color: #409eff;
    margin: 0 15px;
  }
}

.hide {
  display: none;
}
</style>

<template>
  <form v-show="false" ref="codepenFormRef" method="post" action="https://codepen.io/pen/define/" target="_blank">
    <input type="hidden" name="data" :value="codepenParams" />
    <input type="submit" />
  </form>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, nextTick } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useScreens, useTracking } from '@/hooks';
import { EventBus } from '@/services/bus';
import { codegenCleanCode } from '@/services/codegen/cleanCode';
import { toastError } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import { useCheckExportPermissions, useExportCode } from '@/hooks';

const route = useRoute();
const { currentNode } = useScreens();
const { trackExportedCodeFailure, trackExportedCodeSuccess } = useTracking();
const { isExportAllowed } = useCheckExportPermissions();
const { handleExportNotAllowed } = useExportCode();

const codepenFormRef = ref(null);
const codepenParams = ref(null);

onBeforeMount(() => {
  EventBus.$on('OPEN_IN_CODEPEN', handleOpenInCodepen);
});
onBeforeUnmount(() => {
  EventBus.$off('OPEN_IN_CODEPEN', handleOpenInCodepen);
});

const handleOpenInCodepen = async ({ nodeId = null } = {}) => {
  if (!isExportAllowed.value) {
    return handleExportNotAllowed({}, route);
  }
  const form = codepenFormRef.value;
  if (!form) return;
  const { screenSlug } = route.params;

  try {
    const { HTML, CSS } = await codegenCleanCode.getCodepenCode({
      nodeId: nodeId ?? currentNode.value.id,
      screenSlug
    });

    const data = {
      title: `Anima-${screenSlug}`,
      css: CSS,
      html: HTML
    };

    codepenParams.value = JSON.stringify(data);
    await nextTick();
    codepenFormRef.value?.submit();
    trackExportedCodeSuccess({ action: 'codepen' });
  } catch (error) {
    console.log(error);
    errorHandler.captureException(error);
    trackExportedCodeFailure({ action: 'codepen' });
    toastError('Something went wrong, please try again later');
  }
};
</script>

<style lang="scss" scoped></style>

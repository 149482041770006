import Vue from 'vue';
import { has, pick } from 'lodash-es';
import { createStoreModule } from '../utils';
import { updateArrayItemById } from '@/utils/javascript';
import { patchInstanceFromMaster } from '@/components/OmniView/utils';
import router from '@/router';
import { EventBus } from '@/services/bus';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import store from '..';
import { get } from 'lodash-es';

function getAllRelevantNodes(currentNode) {
  if (currentNode == null) {
    return [];
  }
  const matches = [];
  matches.push(currentNode.modelID);
  const getAllRelevantNodesRecursive = (_node) => {
    for (let i = 0; i < _node.subviews.length; i++) {
      let currentChild = _node.subviews[i];
      matches.push(currentChild.modelID);
      getAllRelevantNodesRecursive(currentChild);
    }
  };
  getAllRelevantNodesRecursive(currentNode);
  return matches;
}

export default createStoreModule({
  name: 'webComponent',
  modelName: 'web_components',
  crud: true,
  endpoint: '/v2/web_components',
  state: {
    isWebComponentsLoading: true,
    componentsIdsArray: [],
    isPopulatingComponentFrame: {
      componentIframe: true,
      componentIframeInterface: true
    },
    currentWebComponent: {},
    currentWebComponentNode: {},
    isPreprocessing: false,
    nestedComponentsStack: [],
    isFetchingWebComponents: false
  },
  getters: {
    suggestedComponents: (state) => state.items.filter((c) => !c.is_live && c.is_suggestion),
    isWebComponentsLoading: (state) => state.isWebComponentsLoading,
    currentDBWebComponent: (state, getters) => {
      const { master } = getters['currentWebComponent'];
      if (!master) return {};
      let found;
      found = state.items.find((c) => c['master_id'] == master['master_id']);
      if (!found) found = state.items.find((c) => c.name == c.component_name) || {};
      // found =
      //   state.items.find(
      //     c => get(c, 'files', {})[`${c.name}.jsx`] == get(master, 'files', {})[`${master.component_name}.jsx`]
      //   ) || {};
      return found;
    },
    currentWebComponent: (state) => state.currentWebComponent,
    isFetchingWebComponents: (state) => state.isFetchingWebComponents,
    currentWebComponentNode: (state) => state.currentWebComponentNode,
    nestedComponentsStack: (state) => state.nestedComponentsStack,
    webComponentsOverrides: (state) => state.webComponentsOverrides,
    isPreprocessing: (state) => state.isPreprocessing,
    isPopulatingComponentFrame: (state) => state.isPopulatingComponentFrame,
    componentsIdsArray: (state) => state.componentsIdsArray,
    webInstancesMap: (state) => {
      let map = {};
      const masters = state.items;
      for (let i = 0; i < masters.length; i++) {
        const master = masters[i];
        for (let j = 0; j < master.instances.length; j++) {
          const instance = master.instances[j];
          map[instance.model_id] = instance;
        }
      }
      return map;
    },
    componentInstances: (state) => {
      let instances = [];
      const masters = state.items;
      for (let i = 0; i < masters.length; i++) {
        const master = masters[i];
        for (let j = 0; j < master.instances.length; j++) {
          const instance = master.instances[j];
          instances.push(instance);
        }
      }
      return instances;
    },
    isComponentView(_state, getters, _rootState) {
      const { component, mode, layer } = _rootState.route.query;
      const webInstancesMap = getters['webInstancesMap'];
      return mode == 'code' && !!component && !!layer && has(webInstancesMap, layer);
    },
    isWebComponent: (_state, getters, _rootState, rootGetters) => {
      const currentNode = rootGetters['omniview/currentNode'];
      const webInstancesMap = getters['webInstancesMap'];
      const { id } = currentNode;
      const { layer } = _rootState.route.query;
      const nodeId = layer || id;
      const is_component = has(webInstancesMap, nodeId);
      if (!is_component) return false;
      let { master } = getters['getMasterAndInstanceByNodeId'](nodeId);
      return is_component && master['is_live'] && !master['is_suggestion'];
    },
    isWebComponentById: (_state, getters) => (nodeId) => {
      if (!nodeId) return false;
      const webInstancesMap = getters['webInstancesMap'];
      const is_component = has(webInstancesMap, nodeId);

      if (!is_component) return false;
      let { master } = getters['getMasterAndInstanceByNodeId'](nodeId);
      return is_component && master['is_live'] && !master['is_suggestion'];
    },
    isSuggestion: (state, getters, _rootState, rootGetters) => {
      const currentNode = rootGetters['omniview/currentNode'];
      const webInstancesMap = getters['webInstancesMap'];
      const { id } = currentNode;
      const { layer } = _rootState.route.query;
      const nodeId = layer || id;

      const is_component = has(webInstancesMap, nodeId);
      if (!is_component) return false;
      let { master } = getters['getMasterAndInstanceByNodeId'](nodeId);
      if (!master) return;

      return !master['is_live'] && master['is_suggestion'];
    },
    isSuggestionById: (_state, getters) => (nodeId) => {
      let { master } = getters['getMasterAndInstanceByNodeId'](nodeId);

      let component_name = '';
      let is_suggestion = master ? !master['is_live'] && master['is_suggestion'] : false;

      master && (component_name = master.name);

      return {
        component_name,
        is_suggestion,
        master
      };
    },
    isComponentOrSuggestion(_state, getters, rootState, rootGetters) {
      const { layer } = rootState.route.query;
      const codegenLang = rootGetters['codePreferences/codegenLang'];

      if (codegenLang == 'html') return false;

      const isWebComponentById = getters['isWebComponentById'];
      const isSuggestionById = getters['isSuggestionById'];

      const { is_suggestion } = isSuggestionById(layer);

      return isWebComponentById(layer) || is_suggestion;
    },
    isComponentOrSuggestionById: (_state, getters, _rootState, rootGetters) => (nodeId) => {
      const codegenLang = rootGetters['codePreferences/codegenLang'];

      if (codegenLang == 'html') return false;

      const isWebComponentById = getters['isWebComponentById'];
      const isSuggestionById = getters['isSuggestionById'];

      const { is_suggestion } = isSuggestionById(nodeId);

      return isWebComponentById(nodeId) || is_suggestion;
    },
    currentWebComponentOverrides: (state) => {
      const { instance } = state.currentWebComponent;
      if (!instance) return {};
      return {
        [instance.model_id]: instance
      };
    },
    getAllWebComponentsOverrides: (state) => (currentNode) => {
      const webComponents = state.items;
      let data = {};
      let allRelevantNodesID = getAllRelevantNodes(currentNode);

      for (let i = 0; i < webComponents.length; i++) {
        let master = webComponents[i];
        let slim_master = pick(master, ['fingerprint', 'props']);
        let is_enable = true;
        if (!master['is_live'] && !master['is_suggestion']) {
          is_enable = false;
        }

        let currentInstancesID = [];
        for (let i = 0; i < master.instances.length; i++) {
          currentInstancesID.push(master.instances[i]['model_id']);
        }
        let allRelevantInstanceModelIds = [];
        for (let i = 0; i < allRelevantNodesID.length; i++) {
          if (currentInstancesID.includes(allRelevantNodesID[i])) {
            allRelevantInstanceModelIds.push({ model_id: allRelevantNodesID[i] });
          }
        }

        data[master.master_id] = {
          ...slim_master,
          component_name: master.name,
          is_enable,
          instances: allRelevantInstanceModelIds
        };
      }
      return data;
    },
    getMasterAndInstanceByNodeId: (state) => (nodeId, items) => {
      let foundMaster = false;
      let foundInstance = false;
      let foundPrimaryInstance = false;
      let components = items ? items : state.items;
      for (let i = 0; i < components.length; i++) {
        const wc = components[i];
        let wci = wc.instances || [];

        let instance = wci.find((instance) => instance.model_id == nodeId);
        if (instance) {
          foundMaster = components[i];
          foundInstance = instance;
          break;
        }
      }

      if (foundMaster) {
        foundPrimaryInstance = foundMaster.instances.find((i) => i.is_primary);
      }

      return { master: foundMaster, instance: foundInstance, primaryInstance: foundPrimaryInstance };
    }
  },
  mutations: {
    setWebComponents: (state, data) => (state.items = data),
    setCurrentWebComponent: (state, data) => {
      state.currentWebComponent = data;
    },
    setIsPreprocessing: (state, f) => (state.isPreprocessing = f),
    setIsWebComponentsLoading: (state, f) => (state.isWebComponentsLoading = f),
    setIsFetchingWebComponents: (state, f) => (state.isFetchingWebComponents = f),
    setCurrentWebComponentNode: (state, node) => (state.currentWebComponentNode = node),
    setNestedComponentsStack: (state, s) => {
      state.nestedComponentsStack = s;
    },

    setIsPopulatingComponentFrame: (state, { iframeName, flag }) => {
      if (iframeName) {
        Vue.set(state.isPopulatingComponentFrame, iframeName, flag);
      } else {
        state.isPopulatingComponentFrame = {
          componentIframe: flag,
          componentIframeInterface: flag
        };
      }
    }
  },
  crudMutations: {
    setItems: (state, { results, total, count, page }) => {
      state.total = total;
      state.count = count;
      state.page = page;
      const codegenLang = store.getters['codePreferences/codegenLang'];

      const newItems = results.map((master) => {
        master.instances = master.instances.map((instance) => {
          return patchInstanceFromMaster(instance, master);
        });

        return {
          ...master,
          subsIds: []
        };
      });

      state.items = newItems;
      state.isWebComponentsLoading = false;

      const ids = newItems
        .map((wc) => {
          const wci = wc.instances || [];
          const primaryInstance = wci.find((i) => i.is_primary);
          return primaryInstance ? primaryInstance.model_id : wci[0]?.model_id;
        })
        .filter(Boolean);

      let job = () => {
        state.componentsIdsArray = ids;
        EventBus.$emit(SEND_MESSAGE, {
          action: 'set_component_ids',
          ids: codegenLang == 'html' ? [] : ids
        });
      };

      let domLoading = store.getters['omniview/domLoading'];
      let queue = store.getters['omniview/queue'];

      if (domLoading) {
        queue.enqueue(job);
      } else {
        job();
      }
    }
  },
  actions: {
    async fetchDBWebComponents({ commit, dispatch }, payload) {
      commit('setIsFetchingWebComponents', true);
      await dispatch('fetchAllOfParent', payload);

      commit('setIsFetchingWebComponents', false);
    },
    async updateWebComponentOverride({ state, commit, dispatch, getters }, payload) {
      try {
        const { type, requestParams, usageCode } = payload;
        const { new_override_value, override_key, prop_fingerprint, master_id } = requestParams;

        let keyMap = {
          component_name: 'component_name',
          prop_name: 'name',
          prop_enable: 'is_enable'
        };

        const master = state.items.find((m) => m.master_id == master_id);

        let newRegularProps = master.props['regular'];
        let newNestedProps = master.props['nested'];

        let newProps = {
          regular: newRegularProps,
          nested: newNestedProps
        };

        let newMasterName = master.name;

        if (type == 'name') {
          newMasterName = new_override_value;
        } else {
          newProps[type] = updateArrayItemById(
            master.props[type],
            prop_fingerprint,
            {
              [keyMap[override_key]]: new_override_value
            },
            'prop_fingerprint'
          );
        }

        const newItems = updateArrayItemById(state.items, master.id, {
          name: newMasterName,
          props: newProps
        });

        const newMaster = {
          ...master,
          props: newProps,
          name: newMasterName
        };

        commit('setWebComponents', newItems);

        const slimInstance = newMaster.instances.find((i) => i.model_id == router.currentRoute.query.layer);
        let newInstance = patchInstanceFromMaster(slimInstance, newMaster);

        if (usageCode) {
          newInstance['usage_code'] = usageCode;
        }

        commit('setCurrentWebComponent', {
          master: {
            ...get(getters['currentWebComponent'], 'master', {}),
            component_name: newMaster['name'],
            ...newMaster
          },
          instance: newInstance
        });

        dispatch('componentsMetadata/updateAnimaScripts', null, { root: true });

        return true;
      } catch (error) {
        console.log('FAILED UPDATING COMPONENT OVERRIDE', error);
      }
    }
  }
});

import { createStoreModule } from '../utils';
import { fetchApiWithCache } from '@/api';
export default createStoreModule({
  name: 'release',
  modelName: 'releases',
  crud: true,
  endpoint: '/v2/releases',
  actions: {
    async fetchModelFromUrl(_ctx, model_file_url) {
      try {
        const model = await fetchApiWithCache(decodeURI(model_file_url), { retry: true }, true);
        return model;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchReleaseModel({ state, dispatch }, { slim = false } = {}) {
      try {
        const { model_file_url, slim_model_file_url } = state.currentItem;
        const file_url = slim && slim_model_file_url ? slim_model_file_url : model_file_url;
        const model = await dispatch('fetchModelFromUrl', file_url);

        return model;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
});

export const gtagPlugin = (store) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  const defaultConsent = store.getters['consent/consentData'];
  gtag('consent', 'default', defaultConsent);
  gtag('js', new Date());
  gtag('config', process.env.GA_MEASUREMENT_ID);

  const hasConsent = store.state.consent.hasConsent;
  if (hasConsent) {
    loadGtagScript();
  }

  store.subscribe((mutation) => {
    if (mutation.type === 'consent/setHasConsent') {
      const consent = store.getters['consent/consentData'];
      gtag('consent', 'update', consent);

      const hasConsent = store.state.consent.hasConsent;
      if (hasConsent) {
        loadGtagScript();
      }
    }
  });
};

const loadGtagScript = () => {
  var gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`;

  var firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(gtagScript, firstScript);
};

<template>
  <div data-cy="import-page" class="container" :class="containerClass">
    <LoadingScreen v-if="isLoading" />
    <PermissionsLayout accessLevel="contributor" @permission-denied="onPermissionDenied" v-else>
      <div class="options">
        <div class="import-option" :containerClass="containerClass">
          <FigmaImport @updateFigmaPickArtboards="updateFigmaPickArtboards" @close="handleClose" />
        </div>
      </div>
    </PermissionsLayout>
  </div>
</template>

<script>
import FigmaImport from './FigmaFileImport/index.vue';
import PermissionsLayout from '@/components/Layout/PermissionsLayout.vue';
import LoadingScreen from '@/components/Loading/LoadingScreen.vue';
import { mapActions } from 'vuex';

export default {
  name: 'design-import',
  components: { LoadingScreen, FigmaImport, PermissionsLayout },
  data() {
    return {
      disclaimerText: 'Only contributors and admins can sync a design.',
      isLoading: false,
      isWorking: false,
      figmaPickArtboards: false
    };
  },
  mounted() {
    this.loadTeamMemberships();
  },
  computed: {
    containerClass() {
      if (this.figmaPickArtboards) {
        return 'full';
      }
      return '';
    }
  },
  methods: {
    ...mapActions({ fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships' }),
    handleClose() {
      this.$emit('close');
    },
    toggleLoading(value) {
      this.isLoading = !!value;
    },
    updateFigmaPickArtboards(value) {
      this.figmaPickArtboards = value;
    },
    onPermissionDenied() {
      this.$trackEvent('design-import.no-permission.redirect');
      this.$router.push({ name: 'team-viewer-access' });
    },
    async loadTeamMemberships() {
      try {
        this.toggleLoading(true);
        await this.fetchUserMemberships({ id: 'me', skipCache: true });
      } finally {
        this.toggleLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  min-height: 100vh;
}
.container.figma {
  margin-top: 120px;
  margin-bottom: 120px;
}
.options {
  display: flex;
  justify-content: center;
  width: 1014px;
  position: relative;
}
.import-option {
  width: 50%;
}
.import-option.full {
  width: 100%;
}
.option-divider {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d8d8d8;
  p {
    background-color: white;
    z-index: 1;
    padding: 20px 0;
  }
  &::after {
    height: 100%;
    position: absolute;
    background: #d8d8d8;
    display: block;
    content: '';
    width: 1px;
    left: 50%;
  }
}
</style>

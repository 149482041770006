import { createStoreModule } from '../utils';
import { get, isEmpty } from 'lodash-es';
import errorHandler from '@/services/errorHandler';

export default createStoreModule({
  name: 'userOnboarding',
  modelName: 'user_onboardings',
  crud: true,
  endpoint: '/v2/user_onboardings',
  getters: {
    onboardingFlow(state) {
      return get(state.currentItem, 'onboarding_flow', null);
    },
    onboardingStatus(state) {
      return get(state.currentItem, 'status', null);
    },
    onboardingSlug(_, getters) {
      return get(getters.onboardingFlow, 'slug', null);
    },
    onboardingInProgress(_, getters) {
      return getters.onboardingStatus === 'in-progress';
    },
    onboardingStages(state, getters) {
      const { currentItem: userOnboarding } = state;
      const { onboardingFlow } = getters;
      if (!onboardingFlow || !userOnboarding?.stages_data) return [];
      const stages = get(onboardingFlow, 'stages', []);
      const mapSubStages = (stage, stageIndex) => {
        const subStages = get(stage, 'sub_stages', []);
        return subStages.map((subStage, subStageIndex) => ({
          ...subStage,
          index: subStageIndex,
          data: {
            ...get(subStage, 'initial_data', {}),
            ...get(userOnboarding, `stages_data.${stageIndex}.sub_stages_data.${subStageIndex}`, {})
          }
        }));
      };

      return stages.map((stage, index) => ({
        ...stage,
        index,
        number: index + 1,
        sub_stages: mapSubStages(stage, index),
        data: {
          ...stage.initial_data,
          ...userOnboarding.stages_data[index]
        }
      }));
    },
    currentStage(state, getters) {
      const { currentItem: userOnboarding } = state;
      const { onboardingStages } = getters;
      return onboardingStages.find((stage) => stage.index === userOnboarding.active_stage_index) || {};
    }
  },
  actions: {
    async fetchUserOnboarding({ state, rootState, dispatch, commit }) {
      if (isEmpty(rootState.users.currentItem)) {
        return;
      }

      try {
        await dispatch('fetchAllOfParent', { parent: 'users', id: 'me' });
        commit('setCurrentItem', get(state, 'items[0]', {}));

        if (isEmpty(state.currentItem)) {
          return;
        }

        return state.currentItem;
      } catch (err) {
        errorHandler.captureException(err);
      }
    },
    async resetOnboarding({ state, dispatch, commit }) {
      const { currentItem } = state;
      if (!currentItem?.id) return;
      const payload = { active_stage_index: 0, status: 'in-progress' };
      dispatch('update', { id: currentItem.id, payload });
      commit('setCurrentItem', { ...currentItem, ...payload });
    },
    async nextStage(
      { state, dispatch, commit, getters },
      { currentStageSlug, disableConfetti, stagePayload = {} } = {}
    ) {
      const { currentItem } = state;
      const { onboardingFlow } = getters;
      if (!onboardingFlow || !currentItem?.id) return;
      const { stages_data: stagesData } = currentItem;
      const { id, active_stage_index: activeStageIndex } = currentItem;
      const onboardingFlowStage = onboardingFlow.stages[activeStageIndex];

      let status = currentItem.status;
      if (onboardingFlowStage?.slug === currentStageSlug) {
        const nextStageIndex = activeStageIndex + 1;
        if (nextStageIndex < stagesData.length) {
          const initialData = stagesData[nextStageIndex];
          if (initialData.data && stagePayload.data) {
            stagePayload.data = { ...initialData.data, ...stagePayload.data };
          }
          stagesData[nextStageIndex] = { ...initialData, ...stagePayload };
        }

        commit('webappSystem/setShowConfettiOnDone', !disableConfetti, { root: true });

        const payload = { active_stage_index: nextStageIndex, stages_data: stagesData };
        dispatch('update', { id, payload });

        if (nextStageIndex >= onboardingFlow.stages?.length) status = 'done';
        commit('setCurrentItem', { ...currentItem, ...payload, status });
      }
    }
  }
});

<template>
  <div class="divider-container" :class="{ [theme]: true }">
    <div class="divider" />
    <div class="text" :class="{ [theme]: true }" :style="{ color: textColor }">{{ text }}</div>
    <div class="divider" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['dark', 'light'].includes(value)
    }
  },
  computed: {
    textColor() {
      const color = this.theme === 'dark' ? 'var(--dark-border-color)' : 'var(--secondary-text)';
      return this.color || color;
    }
  }
};
</script>

<style lang="scss" scoped>
.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 1px;
  width: 100%;
  margin: 12px 0;
  &.light {
    background-color: var(--divider-color);
  }
  &.dark {
    background-color: var(--dark-border-color);
  }
}
.text {
  padding: 0 12px;
  &.dark {
    background: var(--dark-onboarding-background);
  }
  &.light {
    background: #ffffff;
  }
}
</style>

import { createStoreModule } from '../utils';

const getDefaultTeamSlug = async (ctx) => {
  let user = {};
  try {
    // shouldn't be slow as the user object is already cached.
    user = await ctx.dispatch('fetchOne', { id: 'me' });
    if (!user.default_team_slug) {
      let teamSlug = null;
      let teams = await ctx.dispatch(
        'teamMemberships/fetchAllUserMemberships',
        { id: 'me', skipCache: true },
        { root: true }
      );
      if (!teams.results.length) {
        // old users may not have a default team yet
        const team = await ctx.dispatch('teams/createDefaultTeam', 'My Team', { root: true });
        teamSlug = team.slug;
      } else {
        teamSlug = teams.results[0].team_slug;
      }
      ctx.dispatch('update', { id: 'me', payload: { default_team_slug: teamSlug } });
      user.default_team_slug = teamSlug;
    }
    ctx.dispatch('getDefaultTeamDetails', user);
    return user.default_team_slug;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export default createStoreModule({
  name: 'user',
  modelName: 'users',
  crud: true,
  endpoint: '/v2/users',
  state: {
    defaultTeamId: null,
    defaultTeamSlug: null,
    includePixel: localStorage.getItem('include-pixel') !== '0'
  },
  mutations: {
    setDefaultTeam: (s, { id, slug } = {}) => {
      s.defaultTeamId = id;
      s.defaultTeamSlug = slug;
    },
    setIncludePixel: (s, includePixel) => {
      s.includePixel = includePixel;
      localStorage.setItem('include-pixel', includePixel ? '1' : '0');
    }
  },
  actions: {
    getDefaultTeamSlug,
    async getDefaultTeamDetails({ state, dispatch, commit }, { default_team_slug: defaultTeamSlug }) {
      if (state.defaultTeamId || state.defaultTeamSlug) return;
      try {
        const team = await dispatch(
          'teams/fetchOne',
          { id: defaultTeamSlug, params: { is_slug: true }, storeResult: false },
          { root: true }
        );
        commit('setDefaultTeam', team);
      } catch (err) {
        console.error(err);
      }
    }
  },
  getters: {
    role: (s) => s.currentItem?.role,
    workplaceType: (s) => s.currentItem?.workplace_type,
    intent: (s) => s.currentItem?.intent,
    firstName: (s) => {
      const { currentItem: user } = s;
      return user?.first_name || user?.name?.trim().split(' ')[0];
    },
    lastName: (s) => {
      const { currentItem: user } = s;
      return user?.last_name || user?.name?.trim().split(' ')[1];
    },
    userEmail: (s) => {
      const { currentItem: user } = s;
      return user?.email;
    },
    isUserDeveloper: (s) => s.currentItem?.role === 'developer',
    isUserAdmin: (s) => s.currentItem?.is_admin === true,
    isAnimaUser: (s) => s.currentItem?.email?.endsWith('@animaapp.com'),
    hasPixelEnabled: (s) => s.includePixel,
    hasAccessToProFeatures(_state, _getters, _rootState, _rootGetters) {
      const isPro = _rootGetters['teamMemberships/isPro'];
      const isAdmin = !!_rootState?.users?.currentItem?.is_admin;

      return isPro || isAdmin;
    },
    betaFeatures: (s) => {
      const { currentItem: user } = s;
      return user?.beta_features || [];
    },
    figmaAuthToken: (s) => s.currentItem?.figma_auth_token
  }
});

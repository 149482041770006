import { isEmpty, map } from 'lodash-es';
import api from '@/api';

const extractQueryParam = (paramKey) => {
  // extract query params
  // eslint-disable-next-line no-unused-vars
  const [_, query] = decodeURIComponent(window.location.toString()).split('?');
  const params = (query ?? '')
    .split('&')
    .filter((p) => p)
    .map((p) => p.split('='));

  // find param
  return params.find((param) => param[0] === paramKey);
};

// this string: exp1:value1;exp2;exp3:false;exp4:true
// will result this: {exp1: 'value1', exp2: true, exp3: false, exp4: true}
const overrideStringToObject = (queryItem) => {
  // separate each experiment by ';'
  if (!isEmpty(queryItem)) {
    const arr = (queryItem[1] || '').split(';');
    return arr.reduce((acc, curr) => {
      // separate key-values by ':'
      let [name, value] = curr.split(':');
      if (!name) return acc;
      if (!value || value === 'true') value = true;
      else if (value === 'false') value = false;
      return { ...acc, [name]: value };
    }, {});
  }
  return {};
};

const checklocalStorageExperiment = (queryItem) => {
  if (!isEmpty(queryItem)) {
    map(queryItem, (val, key) => {
      localStorage.setItem(key, val);
    });
  }
};

// extract experiment overrides from url.
// this query: <url>?exp.ovr=exp1:value1;exp2;exp3:false;exp4:true
// will result this: {exp1: 'value1', exp2: true, exp3: false, exp4: true}
export const getOverridesFromURL = () => {
  try {
    const expOverridesStore = extractQueryParam('exp.str');
    checklocalStorageExperiment(overrideStringToObject(expOverridesStore));
    const expOverrides = extractQueryParam('exp.ovr');
    return overrideStringToObject(expOverrides);
  } catch (e) {
    // eslint-disable-next-line
    console.debug(e);
  }
  return {};
};

export const getFeatureTogglesFromUrl = () => {
  try {
    const featureToggles = extractQueryParam('feat.tgl');
    return overrideStringToObject(featureToggles);
  } catch (e) {
    // eslint-disable-next-line
    console.debug(e);
  }
  return {};
};

export const getTimeZoneExperiments = () => {
  const url = '/get_time_zone_feature_flags';
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const hour = (-1 * offset) / 60;
  const params = {
    hour,
    feature: 'tz.show.pricing-screen-based-flow'
  };
  return api.get(url, null, { params });
};

import { isEmpty } from 'lodash-es';
import { createStoreModule } from '../utils';
import { PRO_SCREENS_TIERS } from '@/services/subscriptions';

export default createStoreModule({
  name: 'team',
  modelName: 'teams',
  crud: true,
  endpoint: {
    default: '/v2/teams',
    create: '/v2/users/me/teams'
  },
  getters: {
    isTeamTrialSupported(state, getters, rootState, rootGetters) {
      const { currentItem: team } = state;
      const activeSubscription = rootGetters['stripeSubscriptions/activeSubscription'];
      const isTrialActive = rootGetters['experiments/isTrialActive'];
      return !!team?.id && !team.did_start_trial && isEmpty(activeSubscription) && isTrialActive;
    },
    isProScreenTier(state, getters, rootState, rootGetters) {
      const { currentItem: team } = state;
      const activeSubscription = rootGetters['stripeSubscriptions/activeSubscription'];
      return !!team?.id && !isEmpty(activeSubscription) && PRO_SCREENS_TIERS.includes(activeSubscription.plan_id);
    }
  },
  actions: {
    async createDefaultTeam({ commit, dispatch }, teamName = 'My team') {
      const newTeam = await dispatch('create', { payload: { name: teamName, is_default: true } });
      commit('setCurrentItem', newTeam);
      return newTeam;
    },
    setCurrentTeamProps({ state, commit }, payload) {
      const { currentItem: team } = state;
      commit('setCurrentItem', { ...team, ...payload });
      if (payload.name) {
        const membershipsPayload = { team_name: payload.name };
        commit(
          'teamMemberships/updateTeamMemberships',
          { teamId: team.id, payload: membershipsPayload },
          { root: true }
        );
      }
    }
  }
});

import { loadStripe } from '@stripe/stripe-js';
import { trackEvent } from '@/services/tracking';
import api from '@/api';

let Stripe;
let elements;
const style = {
  base: {
    fontSmoothing: 'antialiased',
    padding: '12px 22px',
    '::placeholder': {
      color: '#aab7c4'
    }
  }
};

export const initStripe = async () => {
  Stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  elements = Stripe.elements({ locale: 'en' });
};

export const createCardInstance = async (ref, onChange) => {
  if (!Stripe || !elements) await initStripe();
  let card = elements.getElement('card');
  if (!card) {
    card = elements.create('card', { style });
  }
  card.mount(ref);
  card.on('change', onChange);
  return card;
};

export const getStripeToken = async (card) => {
  const result = await Stripe.createSource(card);
  if (result.error) {
    throw new Error(result.error.message || result.error.type || result.error);
  } else {
    return result.source;
  }
};

export const confirmStripePayment = async (clientSecret, subscriptionId, stripeSourceId = null) => {
  if (!Stripe) await initStripe();
  const payementMethod = stripeSourceId ? stripeSourceId : { card: elements.getElement('card') };
  const { error: stripeError } = await Stripe.confirmCardPayment(clientSecret, {
    payment_method: payementMethod
  });
  if (stripeError) {
    trackEvent('payment.purchase.3ds.failed');
    throw new Error('3D Secure failed, you need to reach to your bank to complete the payment');
  } else {
    trackEvent('payment.purchase.3ds.completed');
    const url = `/v2/stripe/complete`;
    const params = { subscription_id: subscriptionId };
    return api.post(url, params);
  }
};

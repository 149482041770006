<template>
  <div>
    <div class="disclaimer">
      Only Admins and Owners can cancel a plan. Check your
      <an-link variant="primary" @click="goToTeamSettings"> team’s settings </an-link>
      to find out who these roles belong to
    </div>

    <div class="actions">
      <an-button @click="dismiss">Back to {{ team.name }}</an-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('teams', { team: 'currentItem' })
  },
  methods: {
    dismiss() {
      this.$trackEvent('team-downgrade.no-permissions-dismiss.click', { page: this.$route.name });
      this.$emit('close', { redirect: { name: 'team' } });
    },
    goToTeamSettings() {
      this.$emit('close', { redirect: { name: 'team-settings' } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>

<template>
  <div class="view">
    <div class="title-container">
      <div class="title">assets</div>
    </div>
    <!-- <div class="">
      <ul>
        <li class="asset" v-for="(entry, idx) in currentComponentData" :key="`${entry}${idx}`">
          <picture data-name="Group" class="preview-img"
            ><img :src="entry.url" style="max-width: 44px; object-fit: cover;"
          /></picture>
          <div>
            <h3>{{ entry.filename }}</h3>
          </div>
          <a href="#"
            ><button type="button" class="el-button el-button--default el-button--mini is-circle">
              <i class="el-icon-download"></i
              >
            </button></a
          >
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'assets-view',
  computed: {
    ...mapGetters({
      currentComponentData: 'components/currentComponentData'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '../View.scss';

.title-container {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  z-index: 10;
  .title {
    font-size: 16px;
    color: white;
  }
}
</style>

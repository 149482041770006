/* eslint-disable */

import { createStoreModule } from '../utils';
export default createStoreModule({
  name: 'codegen',
  crud: false,

  state: {
    webComponents: [],
    suggestedComponents: [],
    currentNestedComponents: [],
  },

  getters: {
    webComponents: state => state.webComponents,
    currentNestedComponents: state => state.currentNestedComponents,
    suggestedComponents: state => state.suggestedComponents
  },

  mutations: {
    setWebComponents: (state, data) => (state.webComponents = data),
    setCurrentNestedComponents: (state, data) => (state.currentNestedComponents = data),
    setSuggestedComponents: (state, data) => (state.suggestedComponents = data)
  },

  actions: {}
});

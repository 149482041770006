<template>
  <div
    :style="{
      '--height': inputSizes[size] + 'px',
      '--iconContainerWidth': iconSize * 2 + 5 + 'px'
    }"
    class="inputContainer"
    :class="classes"
  >
    <slot name="outter-left"></slot>

    <div v-if="icon" class="inputIconContainer">
      <svg-icon :size="iconSize" :name="icon" />
    </div>

    <slot name="inner-left"></slot>

    <input
      ref="input"
      :class="[
        'input',
        {
          invalid,
          hasIcon: icon,
          noBorder,
          [theme]: true
        }
      ]"
      v-bind="$attrs"
      :type="inputType"
      :value="value"
      v-on="listeners"
    />

    <div v-if="hasSlot('inner-right')" class="slot-inner-right">
      <slot name="inner-right"></slot>
    </div>

    <div ref="passIcon" v-if="type === 'password'" :class="passwordIconClasses" @click="toggleShowPassword">
      <svg-icon :size="24" name="eye" />
    </div>

    <slot name="outter-right"></slot>
  </div>
</template>

<script>
import { colorPathChildren } from '@/utils/colors';
export default {
  name: 'an-input',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'xl',
      validator: (value) => {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
      }
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 16
    },
    invalid: {
      type: Boolean,
      default: false
    },
    filter: {
      default: undefined
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        return ['email', 'text', 'number', 'password'].indexOf(value) !== -1;
      }
    },
    focus: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['dark', 'light'].includes(value)
    }
  },
  data() {
    return {
      inputSizes: {
        sm: 28,
        md: 36,
        lg: 40,
        xl: 48
      },
      showPassword: false
    };
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
    if (this.theme === 'dark') {
      colorPathChildren(this.$refs.passIcon, '#ffffff');
    }
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.type;
    },
    passwordIconClasses() {
      return {
        'password-icon': true,
        active: this.showPassword
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.handleInput,
        blur: this.handleBlur,
        keyup: this.handleKeyup
      };
    },
    classes() {
      return {
        'inputContainer--flex': this.hasSlot('outter-left') || this.hasSlot('outter-right'),
        'inputContainer--no-right-borders': this.hasSlot('inner-right')
      };
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
    handleBlur(e) {
      this.$emit('blur', e.target.value);
    },
    handleKeyup(e) {
      this.$emit('keyup', e);
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    hasSlot(name) {
      return !!this.$slots[name];
    }
  }
};
</script>

<style lang="scss" scoped>
.inputContainer {
  position: relative;
  display: inline-block;
  height: var(--height);
  width: 100%;
}
.input {
  height: 100%;
  width: 100%;
  padding: 12px 22px;
  border-radius: 10px;
  outline: none;
  border: var(--border);
  caret-color: var(--primary);
  transition: border-color 1s ease;
  background: transparent;
  &:focus {
    border: 1px solid var(--secondary);
    outline: 0;
  }
  &::placeholder {
    color: var(--secondary-text);
  }
  &.dark {
    color: #ffffff;
    &::placeholder {
      color: var(--dark-secondary-text);
    }
  }
  &.noBorder {
    border: none;
    padding: 0;
  }
  &.dark {
    border-color: var(--dark-border-color);
    &:focus {
      border-color: #ffffff;
      outline: 0;
    }
  }
}
.invalid {
  &,
  &.dark,
  &:focus {
    border: 1px solid var(--red);
    box-shadow: none;
  }
}
.hasIcon {
  padding-left: var(--iconContainerWidth);
}
.inputIconContainer,
.password-icon {
  position: absolute;
  width: var(--iconContainerWidth);
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-icon {
  right: 20px;
  left: auto;
  width: auto;
  cursor: pointer;
  opacity: 0.4;
  &.active {
    opacity: 1;
  }
}
.inputContainer--flex {
  display: flex;
  align-items: center;
  margin-right: 10px;

  & > * + * {
    margin-left: 5px;
  }

  .slot-inner-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    padding-inline: 15px;
    margin: 0;
    border: var(--border);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.inputContainer--no-right-borders {
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"export_panel",on:{"click":_vm.closeIfTarget}},[_c('div',{staticClass:"panel",attrs:{"data-cy":"export-panel"}},[_c('form',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"codesandboxFormRef",attrs:{"action":"https://codesandbox.io/api/v1/sandboxes/define","method":"POST","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"parameters"},domProps:{"value":_vm.codesandboxParams}}),_c('input',{attrs:{"type":"submit","value":"Open in sandbox"}})]),_c('div',{staticClass:"export_scope"},[_c('p',{staticClass:"title small-text"},[_vm._v("Export scope")]),_c('div',{staticClass:"cards"},[_c('div',{staticClass:"card enabled",class:[_vm.selectedScope == 1 ? 'active' : ''],attrs:{"data-cy":"project-scope"},on:{"click":function($event){return _vm.selectScope(1)}}},[_c('img',{attrs:{"src":require("@/assets/svg/full-project.svg"),"alt":"Full project"}}),_c('p',{staticClass:"small-text"},[_vm._v("Full project")]),_c('div',{staticClass:"overlay"})]),_c('div',{staticClass:"card enabled",class:[_vm.selectedScope == 2 ? 'active' : ''],attrs:{"data-cy":"screen-scope"},on:{"click":function($event){return _vm.selectScope(2)}}},[_c('img',{attrs:{"src":require("@/assets/svg/current-screen.svg"),"alt":"Current screen"}}),_c('p',{staticClass:"small-text"},[_vm._v("Current screen")]),_c('div',{staticClass:"overlay"})]),_c('div',_vm._g({staticClass:"card",class:[_vm.showSelection() ? 'enabled' : 'disabled', _vm.selectedScope == 3 ? 'active' : ''],attrs:{"data-cy":"selection-scope"}},
            _vm.showSelection()
              ? {
                  click: () => {
                    return _vm.selectScope(3);
                  }
                }
              : {}
          ),[_c('img',{attrs:{"src":require("@/assets/svg/selection.svg"),"alt":"Selection"}}),_c('p',{staticClass:"small-text"},[_vm._v("Selection")]),_c('div',{staticClass:"overlay"})])])]),_c('SelectedFramework',{staticClass:"selected-framework",on:{"close":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"export-options"},[_c('an-button',{staticStyle:{"margin-left":"20px"},attrs:{"size":"sm","data-cy":"zip-option","isWorking":_vm.zipClicked,"variant":"primary"},on:{"click":_vm.exportZip}},[_vm._v(" Download ZIP ")]),(_vm.selectedScope != 1)?_c('an-button',{staticStyle:{"margin-left":"15px"},attrs:{"size":"sm","data-cy":"sandbox-option","variant":"secondary","isWorking":_vm.isGeneratingPlaygroundCode},on:{"click":_vm.exportSandbox}},[_vm._v(" Open "+_vm._s(_vm.sandboxCTA)+" ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main data-cy="classic-omniview" :style="{ paddingTop: getPaddingTop }" id="omniview-content">
    <ComponentsSidebar v-if="showComponentsSidebar" v-show="!showOnlyCode" />
    <div
      :style="{
        position: 'relative'
      }"
      ref="mainContent"
      class="main-content flex flex-col"
    >
      <ProjectLimitReached v-if="ready && currentProject.is_locked && isPro" />
      <MainFrame :key="codegenLang" v-show="!showOnlyCode" v-else :ready="ready" />

      <!-- ARROWS -->
      <ScreenNavArrow side="right" @click="reactToScreenChange" :show="showNavigationArrows" />
      <ScreenNavArrow side="left" @click="reactToScreenChange" :show="showNavigationArrows" />

      <!-- SIDEBAR -->
      <!-- PANEL -->

      <Panels
        :style="{ width: mainContentWidth + 'px', ...(!showPanel ? { maxHeight: 0 } : {}) }"
        @generateCode="generateCode"
        @toggle="togglePanel"
      />
    </div>
    <Sidebar v-show="!showOnlyCode" />
  </main>
</template>

<script>
import api from '@/api';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { uuid } from '@/utils/uuid';
import { EventBus, openModal } from '@/services/bus';
import {
  HIGHLIGHT_COMMENT,
  OPEN_PANEL,
  SELECT_OVERRIDE_NODE,
  SEND_COMPONENT_MESSAGE,
  SEND_MESSAGE
} from '@/utils/events/omniviewEvents';
import Sidebar from '@/components/OmniView/Sidebar/Sidebar.vue';
import ComponentsSidebar from '@/components/OmniView/Sidebar/ComponentsSidebar.vue';
import MainFrame from '@/components/OmniView/MainFrame.vue';
import ProjectLimitReached from '@/components/OmniView/ProjectLimitReached.vue';
import Panels from '@/components/OmniView/Panels/Panels.vue';
import ScreenNavArrow from '@/components/OmniView/ScreenNavArrow';
import { dataURLtoFile, filterObject, isSameRoute } from '@/utils/javascript';
import axios from 'axios';
import { get, has, isEmpty } from 'lodash-es';
import codegenMixin from '@/components/OmniView/codegenMixin';
import panelMixin from '@/components/OmniView/panelMixin';
import { SocketMixin } from '@/mixins';
import { generateModelNodesMapWorker } from '@/components/OmniView/workerFunctions';
import errorHandler from '@/services/errorHandler';
import { codegenCleanCode } from '@/services/codegen/cleanCode';
import { SET_CODE_PREFERENCES_ACTION } from '@/store/modules/codePreferences/constants';

export default {
  components: {
    Sidebar,
    ComponentsSidebar,
    MainFrame,
    ProjectLimitReached,
    Panels,
    ScreenNavArrow
  },
  mixins: [panelMixin, SocketMixin, codegenMixin],
  data() {
    return {
      notFoundError: false,
      componentNotFoundError: false,
      isExportPanelOpen: false,
      errors: {
        major: false,
        comments: false,
        assets: false,
        breakpoints: false,
        release: false
      },
      isSidebarMinimized: false,
      whitelist: []
    };
  },
  computed: {
    ...mapState('omniview', { loading: 'loading' }),
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('omniview', { isChromeExtension: 'isChromeExtension' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('projects', { registryUrl: 'registryUrl' }),
    ...mapState('componentsMetadata', { metadatas: 'items' }),
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    ...mapGetters({
      isPricingScreenFlowActiveExperiment: 'experiments/isPricingScreenFlowActive',
      activeMode: 'omniview/activeMode',
      nodes: 'omniview/nodes',
      currentNode: 'omniview/currentNode',
      modes: 'omniview/modes',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      queue: 'omniview/queue',
      isAnimaScriptReady: 'omniview/isAnimaScriptReady',
      codegenLang: 'codePreferences/codegenLang',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      codegenLengthUnit: 'codePreferences/codegenLengthUnit',
      codegenAutoAnimateMode: 'codePreferences/codegenAutoAnimateMode',
      codegenReactStyle: 'codePreferences/codegenReactStyle',
      codegenVueStyle: 'codePreferences/codegenVueStyle',
      codegenReactSyntax: 'codePreferences/codegenReactSyntax',
      codegenEngine: 'codePreferences/codegenEngine',
      isGeneratingCode: 'omniview/isGeneratingCode',
      currentMasterSlug: 'omniview/currentMasterSlug',
      nodesWithOverrides: 'omniview/nodesWithOverrides',
      captureType: 'omniview/captureType',
      isComponentView: 'webComponents/isComponentView',
      projectSlugs: 'omniview/projectSlugs',
      hasPermissions: 'teamMemberships/hasPermissions',
      screensLimit: 'teamMemberships/screensLimit',
      panelHeight: 'omniview/panelHeight',
      similarScreensIdsTemp: 'components/similarScreensIdsTemp',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps',
      codePreferences: 'codePreferences/getCodePreferences',
      shouldCodePreferencesBePresented: 'codePreferences/shouldCodePreferencesBePresented',
      convertDBPrefsToLocalStorage: 'codePreferences/convertDBPrefsToLocalStorage',
      isScreenLimitReached: 'omniview/isScreenLimitReached',
      isPro: 'teamMemberships/isPro',
      isCodePreferencesRequested: 'omniview/isCodePreferencesRequested',
      considerUrlForCodePreferencesBePresented: 'omniview/considerUrlForCodePreferencesBePresented'
    }),
    isCodePreferencesModalOpen() {
      return (
        (this.shouldCodePreferencesBePresented && this.considerUrlForCodePreferencesBePresented) ||
        this.isCodePreferencesRequested
      );
    },
    ready() {
      const { component } = this.$route.query;
      const baseCondition = !this.loading.fetching && !this.currentProject.is_syncing;
      if (component) {
        return baseCondition && !this.isWebComponentsLoading && !this.componentNotFoundError;
      }
      return baseCondition;
    },
    selected() {
      const { layer } = this.$route.query;
      return !!this.currentNode.id || !!layer;
    },
    getPaddingTop() {
      if (this.isFullScreen) return 0;
      const p = this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)';
      return p;
    },
    isPlayMode() {
      return this.activeMode.name === 'In';
    },
    showNavigationArrows() {
      const { isPlayMode, allCurrentComponents, ready } = this;
      return ready && isPlayMode && allCurrentComponents?.length > 1;
    },

    showComponentsSidebar() {
      const { activeMode, codegenLang } = this;
      return activeMode.name === 'C' && codegenLang !== 'html';
    }
  },
  created() {
    const { traceId } = this.$route.query;
    if (traceId) {
      this.omniviewLoadTransaction = this.$sentry.startTransaction({
        traceId,
        op: 'omniview-load',
        name: 'OmniView Load'
      });
    }

    this.init();
  },
  mounted() {
    const { breakpoint } = this.$route.params;
    if (breakpoint && breakpoint == 'res') {
      this.setActiveBreakpoint({ id: 'res' });
    }
    this.$intercom.boot({
      user_id: this.currentUser.id,
      name: this.currentUser.name,
      email: this.currentUser.email,
      is_in_paying_team: this.currentUser.is_in_paying_team
    });

    this.$intercom._call('onShow', this.trackIntercomShow);

    this.$intercom.update({ hide_default_launcher: true });
    window.addEventListener('message', this.messageListener, false);
    EventBus.$on('regenerate', this.handleRegenerate);
    EventBus.$on('generate-component-code', this.generateComponentCode);
    EventBus.$on('autosize', this.autosize);

    if (!localStorage.getItem('animp')) {
      this.$tracking.setUserEmail(this.currentUser.email);
      this.$tracking.setUserId(this.currentUser.id);
      this.$tracking.setContext({ latest_paired_design_tool: this.currentUser.latest_paired_design_tool });
      this.$tracking.alias(this.currentUser.id);
    }
  },
  destroyed() {
    this.$intercom.update({ hide_default_launcher: false });
    this.cleanup();
    window.removeEventListener('message', this.messageListener, false);
    EventBus.$off('regenerate', this.handleRegenerate);
    EventBus.$off('generate-component-code', this.generateComponentCode);
    EventBus.$off('autosize', this.autosize);

    this.resetTrackingData();
  },
  watch: {
    codegenLang: {
      handler(lang) {
        const ids = lang == 'html' ? [] : Object.keys(this.webInstancesMap);
        EventBus.$emit(SEND_MESSAGE, {
          action: 'set_component_ids',
          ids
        });
      },
      immediate: false
    },
    ready: function (ready) {
      if (ready && this.omniviewLoadTransaction) {
        this.omniviewLoadTransaction.finish();
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'omniview/setLoading',
      setCodegenLang: 'codePreferences/setCodegenLang',
      setBreakpoints: 'omniview/setBreakpoints',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setIsAnimaScriptReady: 'omniview/setIsAnimaScriptReady',
      setCurrentProject: 'projects/setCurrentItem',
      setCurrentNode: 'omniview/setCurrentNode',
      setIsFullScreen: 'omniview/setIsFullScreen',
      setShowOnlyCode: 'omniview/setShowOnlyCode',
      setCurrentNodeHTML: 'omniview/setCurrentNodeHTML',
      setCurrentNodeName: 'omniview/setCurrentNodeName',
      setCurrentNodeJSX: 'omniview/setCurrentNodeJSX',
      setCurrentNodeCSS: 'omniview/setCurrentNodeCSS',
      setCurrentNodePath: 'omniview/setCurrentNodePath',
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      selectScreen: 'components/setCurrentItem',
      setCurrentComponentData: 'components/setCurrentComponentData',
      setProjectAssetsRegistry: 'projects/setProjectAssetsRegistry',
      setRegistryUrl: 'projects/setRegistryUrl',
      setIsWaitingForOverrides: 'omniview/setIsWaitingForOverrides',
      setIsExportAllowed: 'omniview/setIsExportAllowed',
      setNodes: 'omniview/setNodes',
      setMetadata: 'componentsMetadata/setMetadata',
      setCommentsSubView: 'omniview/setCommentsSubView',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      setDomLoading: 'omniview/setDomLoading',
      setIframeLoading: 'omniview/setIframeLoading',
      setCurrentMasterSlug: 'omniview/setCurrentMasterSlug',
      setNodesWithOverrides: 'omniview/setNodesWithOverrides',
      setCurrentNodeMd5Map: 'omniview/setCurrentNodeMd5Map',
      setIsModelLoading: 'omniview/setIsModelLoading',
      setSuggestedComponents: 'codegen/setSuggestedComponents',
      setBase64Screenshot: 'omniview/setBase64Screenshot',
      setProjectSlugs: 'omniview/setProjectSlugs',
      setIframeBackgrounds: 'omniview/setIframeBackgrounds',
      setIsScreenLimitReached: 'omniview/setIsScreenLimitReached',
      setExtraTrackingData: 'tracking/setExtraData'
    }),
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      fetchStyleguide: 'styleguide/fetchAllOfParent',
      fetchTeam: 'teams/fetchOne',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchMetadata: 'componentsMetadata/fetchMetadata',
      updateMetadata: 'componentsMetadata/update',
      createMetadata: 'componentsMetadata/create',
      fetchProject: 'projects/fetchOne',
      updateProject: 'projects/update',
      fetchSingleComponent: 'components/fetchOne',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchRelease: 'releases/fetchOne',
      fetchComponents: 'components/fetchAllOfParent',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      cleanup: 'omniview/cleanup',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      getNodesWithOverridesData: 'omniview/getNodesWithOverridesData',
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      trackIntercomShow: 'tracking/trackIntercomShow',
      pollSyncingProject: 'projects/pollSyncingProject',
      handleModeChange: 'omniview/handleModeChange',
      setCodePreferences: SET_CODE_PREFERENCES_ACTION
    }),
    async init() {
      try {
        const { projectId, screenSlug, teamSlug } = this.$route.params;

        this.loadMetadataOrCreate();
        this.populateComments();
        this.populateCustomDomains();
        this.populateGuests();
        this.loadStyleguide();

        // fetch web components from db (live and suggested)
        this.fetchDBWebComponents({
          parent: 'projects',
          id: projectId,
          skipCache: true,
          params: { skip_cache: true, get_all: true }
        });

        await this.$waitFor(
          () => !this.loading.fetching && this.currentProject && this.currentProject.is_syncing === false,
          true
        );
        this.$trackEvent('omniview.project-synced');

        const projectSlugs = this.allCurrentComponents.map((s) => s.slug);
        this.setProjectSlugs(projectSlugs);

        if (this.isAnimaScriptReady) {
          EventBus.$emit(SEND_MESSAGE, {
            action: 'update-slugs',
            slugs: projectSlugs
          });
        }

        this.getBreakpoints();
        this.initSocket();

        codegenCleanCode.setLayoutMd5({
          preset: this.codegenHTMLLayout == 'flexbox' ? 'clean_code' : 'high_fidelity',
          length_unit: this.codegenLengthUnit,
          auto_animate_mode: this.codegenAutoAnimateMode
        });

        this.errors.major = null;
        this.$trackEvent('omniview.project-view.open', {
          project_id: projectId,
          screen_slug: screenSlug,
          team_slug: teamSlug,
          screens_count: this.allCurrentComponents.length,
          locked_screens_count: this.allCurrentComponents.filter((c) => c.is_locked).length,
          screens_limit: this.team.screens_limit_count
        });
      } catch (error) {
        this.errors.major = 'Sorry, something went wrong.';
        this.notFoundError = true;
        this.checkIfAccessError(error);
      }
    },

    checkForModeInRoute() {
      return new Promise((resolve) => {
        let { mode } = this.$route.query;
        if (!mode) {
          mode = 'play'; //default mode
        }

        const done = (arg) => resolve(arg);
        switch (mode) {
          case 'play':
            this.handleModeChange({
              mode: this.modes[0],
              fromRoute: true
            }).then(done);
            break;
          case 'comments':
            this.handleModeChange({
              mode: this.modes[1],
              fromRoute: true
            }).then(done);

            break;
          case 'code':
            this.handleModeChange({
              mode: this.modes[2],
              fromRoute: true
            }).then(done);
            break;

          default:
            break;
        }
      });
    },

    async initSocket() {
      await this.$waitFor(() => !this.loading.project, true);
      const { projectId } = this.$route.params;
      this.openSocket(this.currentProject.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'project', action: 'updated' }, (project) => {
        if (!project || !project.id) return;

        const { is_pre_process_running, is_syncing } = this.currentProject;

        if (is_pre_process_running && !project.is_pre_process_running) {
          this.fetchDBWebComponents({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: { skip_cache: true, get_all: true }
          });
        }

        if (isEmpty(this.currentStyleguide) && project.is_styleguide_generated) {
          this.fetchStyleguide({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: { skip_cache: true }
          });
        }

        // set a delay if the project done syncing
        if (is_syncing && !project.is_syncing) {
          setTimeout(() => {
            this.setCurrentProject(project);
          }, 1500);
        } else {
          this.setCurrentProject(project);
        }
      });
    },

    handleRegenerate({ refresh }) {
      this.regenerateCode().then(() => {
        if (refresh) {
          EventBus.$emit('refresh-iframe');
        }
      });
    },
    autosize() {
      const c = document.querySelectorAll('textarea');
      [...c].forEach((t) => (t.style.maxHeight = 'unset'));
    },

    async populateModelNodes({ reset = false, iframe = '' } = {}) {
      const { currentScreen } = await this.getModelAndScreen();

      await this.$waitFor(() => this.isWebComponentsLoading, false);

      const modelNodesMap = await this.$worker.run(generateModelNodesMapWorker, [
        {
          model: currentScreen,
          webInstancesMap: this.webInstancesMap || [],
          components: this.DbWebComponents || [],
          metadata: this.currentComponentMetadata || { overrides: {} },
          codegenLang: this.codegenLang,
          currentWebComponent: this.currentWebComponent
        }
      ]);

      if (iframe == 'componentIframe') {
        EventBus.$emit(SEND_COMPONENT_MESSAGE, {
          action: 'set_model_nodes',
          iframeName: 'all',
          modelNodes: reset ? {} : modelNodesMap,
          allowInnerComponentSelection: true
        });
        return;
      }

      EventBus.$emit(SEND_MESSAGE, {
        action: 'set_model_nodes',
        modelNodes: reset ? {} : modelNodesMap,
        allowInnerComponentSelection: this.codegenLang == 'html'
      });
    },

    async checkForNodeInRoute(mode) {
      // mode here is an object

      const { layer: queryLayer, component: queryComponent } = this.$route.query;

      if (queryComponent) {
        await this.$waitFor(() => !this.loading.project && !this.isWebComponentsLoading, true);

        let model_id = queryComponent;

        const { master } = this.getMasterAndInstanceByNodeId(model_id);
        if (!master) {
          this.componentNotFoundError = true;
        }

        this.selectNodeById({
          nodeId: model_id,
          metadata: {
            source: 'client',
            callbackEvent: {
              name: 'open-component-in-library',
              params: { preProcessParams: { forcePreProcess: true } }
            }
          }
        });
      } else {
        if (!queryLayer) return;

        switch (mode.name) {
          case 'In':
            // no layers in play mode
            break;
          case 'Co':
            // TODO: implement me
            EventBus.$emit(SEND_MESSAGE, {
              action: HIGHLIGHT_COMMENT,
              data: {
                nodeId: queryLayer,
                metadata: {
                  source: 'route'
                }
              }
            });
            break;
          case 'C':
            EventBus.$emit(SEND_MESSAGE, {
              action: SELECT_OVERRIDE_NODE,
              data: {
                nodeId: queryLayer,
                metadata: {
                  source: 'route'
                }
              }
            });
            break;

          default:
            break;
        }
      }
    },

    reactToScreenChange({ fetchBreakpoints }) {
      const { master_slug } = this.currentComponent;

      this.setCurrentMasterSlug(master_slug);

      if (fetchBreakpoints) {
        this.getBreakpoints();
      }
    },
    async populateGuests() {
      try {
        const { projectId } = this.$route.params;
        const response = await this.fetchProjectGuests({
          parent: 'projects',
          id: projectId,
          params: { page_size: 200 }
        });
        return response;
      } catch (error) {
        this.checkIfAccessError(error);
      }
    },

    async loadStyleguide() {
      const { projectId } = this.$route.params;

      try {
        const data = await this.fetchStyleguide({
          parent: 'projects',
          id: projectId
        });

        const results = get(data || {}, 'results', []);
        if (results.length == 0) {
          axios.post(`/project/${projectId}/trigger_styleguide`);
        } else {
          const classes = get(results[0], 'classes', {});
          if (!isEmpty(classes)) {
            const sampleClass = classes[Object.keys(classes)[0]];
            if (!has(sampleClass, 'usage')) {
              axios.post(`/project/${projectId}/trigger_styleguide`);
            }
          }
        }
      } catch (error) {
        this.checkIfAccessError(error);
      }
    },

    loadNodesWithOverrides() {
      let ids = [];

      for (let index = 0; index < this.metadatas.length; index++) {
        const screenMetadata = this.metadatas[index];

        let sids = Object.keys(screenMetadata.overrides || {});
        ids = [...ids, ...sids];
      }

      this.getNodesWithOverridesData(ids);
    },

    async loadMetadataOrCreate() {
      try {
        this.setLoading({ key: 'metadata', value: true });

        const { projectId, screenSlug } = this.$route.params;
        const { results: metadatas } = await this.fetchMetadata({
          id: projectId
        });
        this.setLoading({ key: 'metadata', value: false });

        const currentComponentMetadata = metadatas?.find((m) => m.component_slug == screenSlug) || {};

        if (!currentComponentMetadata.id) {
          const newMetadata = await this.createMetadata({
            parent: 'projects',
            id: projectId,
            payload: {
              component_slug: screenSlug
            }
          });

          this.setMetadata([...this.metadatas, newMetadata]);
        }
      } catch (error) {
        this.checkIfAccessError(error);
      }
    },

    async populateComments() {
      const { projectId } = this.$route.params;
      try {
        this.setLoading({ key: 'comments', value: true });
        await this.fetchProjectComments({
          id: projectId,
          params: {
            page_size: 100
          }
        });
      } catch (error) {
        this.checkIfAccessError(error);
      } finally {
        this.setLoading({ key: 'comments', value: false });
      }
    },
    async populateCustomDomains() {
      if (this.loading.domains) return;

      const { projectId } = this.$route.params;

      try {
        this.setLoading({ key: 'domains', value: true });

        await this.fetchCustomDomains({
          parent: 'projects',
          id: projectId
        });
      } catch (error) {
        this.checkIfAccessError(error);
      } finally {
        this.setLoading({ key: 'domains', value: false });
      }
    },

    handleHTMLCodegen(data) {
      this.$trackEvent('omniview.code-mode.get-code.start');
      const { screenSlug } = this.$route.params;
      codegenCleanCode.setLayoutMd5({
        preset: this.codegenHTMLLayout == 'flexbox' ? 'clean_code' : 'high_fidelity',
        length_unit: this.codegenLengthUnit,
        auto_animate_mode: this.codegenAutoAnimateMode
      });

      this.populateComponentFrame({ iframeName: 'previewIframe', nodeId: data.id });

      const getHTML = codegenCleanCode.getNodeHTML({ nodeId: data.id, screenSlug });
      const getCSS = codegenCleanCode.getNodeCSS({ nodeId: data.id, screenSlug, ignoreStyleguideClasses: true });
      const getPathNodesNames = Promise.all(
        data.path?.map(
          (node) =>
            node.nodeId &&
            codegenCleanCode.getNodeName({ nodeId: node.nodeId, screenSlug: this.$route.params.screenSlug })
        ) || []
      );
      this.$store.commit('omniview/setIsGeneratingCode', true);
      this.trackGetCleanSnippetEvent({ framework: 'html', elementType: 'element' });
      let start = window.performance.now();
      Promise.all([getHTML, getCSS, getPathNodesNames]).then(([HTML, CSS, nodesNames]) => {
        this.$store.commit('omniview/setIsGeneratingCode', false);
        this.setCurrentNodeHTML(HTML);
        this.setCurrentNodeCSS(CSS);
        this.reportCodeState(HTML, CSS);

        // in case of framework switch, there will be no "data.path".
        if (nodesNames && data.path) {
          this.setCurrentNode({ isRoot: data.path[1]?.nodeId == data.id, ...data });
          // in the following line we remove nodes that we don't have code for (probably
          // because they are redunant and been removed by an optimizer).
          this.setCurrentNodePath(data.path.filter((none, index) => nodesNames[index]));
        }
        let end = window.performance.now();

        const eventProps = { duration: (end - start).toFixed(2), engine: this.codegenEngine, framework: 'html' };

        this.$trackEvent('omniview.code-mode.get-code-done', eventProps);
      });
    },

    onDomLoad() {
      // eslint-disable-next-line
      console.log('DOM-load');
      this.checkForModeInRoute();
      EventBus.$emit(SEND_MESSAGE, {
        action: 'set_whitelist',
        whitelist: this.codegenLang == 'html' ? [] : this.whitelist
      });

      this.getCurrentScreenNodeId().then((currentScreenNodeId) => {
        codegenCleanCode
          .getSelectableLayers(this.$route.params.screenSlug, currentScreenNodeId)
          .then((selectableLayers) => {
            EventBus.$emit(SEND_MESSAGE, {
              action: 'populate-selectable-layers',
              nodes: selectableLayers
            });
          });
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: 'dom-load'
      });

      this.$waitFor(() => this.loading.metadata, false).then(() => {
        this.loadNodesWithOverrides();
      });

      if (this.firstLoad) {
        this.$waitFor(() => this.isAnimaScriptReady, true).then(() => {
          EventBus.$emit(SEND_MESSAGE, {
            action: 'update-slugs',
            slugs: this.projectSlugs.length > 0 ? this.projectSlugs : this.currentProject.page_names
          });
          this.populateModelNodes();
          while (!this.queue.isEmpty()) {
            let job = this.queue.dequeue();
            if (job) {
              job();
            }
          }
          this.setDomLoading(false);
        });
      }

      this.firstLoad = false;
    },

    messageListener(e) {
      if (e && e.data && e.data.sender === 'fullScreen') {
        //if the chrome extension wants to change the full screen
        let showOnlyCode = e.data.message.showOnlyCode;
        this.setShowOnlyCode(showOnlyCode);
        return;
      } else if (e && e.data && e.data.sender === 'selectLayer' && e.data.message && e.data.message.layerId) {
        //the chrome extension wants to change the layerId
        let layerId = e.data.message.layerId;
        this.checkForNodeInRoute(this.activeMode, layerId);
        return;
      }

      if (!e.data.action) return;
      if (!e.origin.includes('animaapp.io')) return;
      const data = e.data.data;
      let shouldRegenerate = true;

      const handleCommentsModeNode = () => {
        let { view } = e.data.info;
        if (!view) view = 'comments';
        if (this.currentNode.id && this.currentNode.id == data.id) {
          return;
        }
        this.setIsSidebarMinimized(false);

        this.setCurrentNode({ ...data, viewName: '' });
        this.setCurrentNodePath(data.path);

        const outerComments = (this.comments || []).filter((c) => !has(c, 'reply_to'));
        const outerCommentsForThisNode = outerComments.filter((c) => c.node_id == data.id);

        // drill into replies if there is only one comment
        if (outerCommentsForThisNode.length == 1) {
          EventBus.$emit('open-comment-thread', outerCommentsForThisNode[0]);
        } else {
          this.setCommentsSubView(view);
          EventBus.$emit('focus-comment-input');
        }
      };

      switch (e.data.action) {
        case 'edit-node':
          {
            if (codegenCleanCode.loadingMap.schema) {
              this.$store.commit('omniview/setIsGeneratingCode', true);
            }

            const newRoute = { ...this.$route, query: { ...this.$route.query, layer: data.id } };
            const { screenSlug } = this.$route.params;

            codegenCleanCode.getNodeName({ nodeId: data.id, screenSlug }).then((name) => {
              this.setCurrentNodeName(name);
              this.setCurrentNode({ ...data, viewName: name });
            });

            EventBus.$emit(OPEN_PANEL);
            // change the tab
            EventBus.$emit('code-panel-tab-change', { tab: 'cleanCode' });

            const codegenHandler =
              this.codegenLang === 'html' ? this.handleHTMLCodegen : this.handleCodeModeNodeMessage;

            if (isSameRoute(this.$route, newRoute)) {
              codegenHandler(data);
            } else {
              this.$router
                .replace(newRoute)
                .then(() => {
                  codegenHandler(data);
                })
                .catch((e) => {
                  errorHandler.captureException(e);
                });
            }
          }
          break;

        case 'node-subNodes-map':
          {
            let subNodesMap = data;
            let newDbItems = this.DbWebComponents.map((master) => {
              const wci = get(master, 'instances', []);
              const pi = wci.find((i) => i.is_primary) || {};
              let id = get(pi, 'model_id', wci[0]?.model_id);
              let matches = id ? get(subNodesMap, id, []) : [];
              let subsIds = matches.map((mId) => {
                const { master } = this.getMasterAndInstanceByNodeId(mId);
                return master.id;
              });
              return {
                ...master,
                subsIds
              };
            });
            this.setWebComponents(newDbItems);
          }

          break;

        case 'on-preview-start':
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: true });
          break;
        case 'on-preview-end':
          if (data.error) {
            this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
          } else {
            if (data.isLocal) {
              this.setBase64Screenshot(data.base64);
              this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              break;
            }
            const file = dataURLtoFile(data.base64, `${data.nodeId}.png`);
            const formData = new FormData();
            formData.append('file', file, file.name);
            api
              .post(`/v2/uploads/projects/${this.currentProject.id}/component_capture`, formData)
              .then((res) => {
                let url = '';
                if (res.data.public_url) {
                  url = res.data.public_url.replace(
                    'https://anima-uploads.s3.amazonaws.com',
                    'https://image-cdn.animaapp.com'
                  );
                }

                this.updateNodeOverrides({
                  nodeId: data.nodeId,
                  fields: { preview_url: url }
                });
              })
              .catch((e) => {
                errorHandler.captureException(e);
              })
              .finally(() => {
                this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              });
          }
          break;
        case 'on-capture-start':
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: true });
          break;
        case 'on-capture-end':
          if (data.error) {
            this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
          } else {
            const file = dataURLtoFile(data.base64, `${data.nodeId}.${this.captureType}`);
            const formData = new FormData();
            formData.append('file', file, file.name);
            api
              .post(`/v2/uploads/projects/${this.currentProject.id}/component_capture`, formData)
              .then((res) => {
                let url = '';
                if (res.data.public_url) {
                  url = res.data.public_url.replace(
                    'https://anima-uploads.s3.amazonaws.com',
                    'https://image-cdn.animaapp.com'
                  );
                }

                this.updateNodeOverrides({
                  nodeId: data.nodeId,
                  fields: { capture_url: url }
                });
              })
              .catch((e) => {
                errorHandler.captureException(e);
              })
              .finally(() => {
                this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              });
          }

          break;

        case 'comment-node-change':
          {
            let newRoute = { ...this.$route, query: { ...this.$route.query, layer: data.id } };
            if (isSameRoute(this.$route, newRoute)) {
              handleCommentsModeNode();
            } else {
              this.$router
                .push(newRoute)
                .then(() => {
                  handleCommentsModeNode();
                })
                .catch((e) => {
                  errorHandler.captureException(e);
                });
            }
          }
          break;

        case 'save-overrides':
          if (has(e.data.info, 'regenerate')) {
            shouldRegenerate = e.data.info.regenerate;
          }

          this.setNodes(data);

          this.setNodesWithOverrides({ ...this.nodesWithOverrides, ...filterObject(data, (node) => node.isModifed) });
          this.setIsWaitingForOverrides(false);
          this.saveComponentOverrides(data, {
            regenerate: shouldRegenerate
          });
          // this.handleOverrideAdded(data);
          break;
        case 'turbo-ready':
          this.setIsAnimaScriptReady(true);
          EventBus.$emit(SEND_MESSAGE, {
            action: 'create-hotspots'
          });

          break;
        case 'link-click':
          {
            if (e.data?.data) {
              let slug = e.data.data.url.replace(`https://${this.currentProject.subdomain}.animaapp.io/`, '');
              if (this.projectSlugs.includes(slug)) {
                EventBus.$emit(SEND_MESSAGE, {
                  action: 'change-screen',
                  slug
                });
              }
            }
          }

          break;
        case 'turbo-render':
        case 'dom-load':
          this.onDomLoad();
          break;

        case 'background-info':
          this.setIframeBackgrounds(e.data.backgrounds);
          break;

        case 'nodes-data-map':
          this.setNodesWithOverrides(e.data.data);
          break;
        case 'escape-clicked':
          this.handleEscapeClicked();
          break;
        case 'document-clicked':
          {
            const ev = new Event('click');
            document.dispatchEvent(ev);
          }
          break;
        case 'overrides-activated':
          this.checkForNodeInRoute(this.activeMode);
          break;
        case 'comments-activated':
          this.checkForNodeInRoute(this.activeMode);

          break;

        default:
          break;
      }
    },

    async saveComponentOverrides(data, { regenerate = true } = {}) {
      const { projectId } = this.$route.params;

      const overrides = await this.getModelOverrides(data);
      const { id } = this.currentComponentMetadata;
      await this.updateMetadata({
        id,
        payload: {
          overrides
        }
      });

      this.fetchMetadata({
        id: projectId,
        skipCache: true
      });

      if (regenerate) {
        this.regenerateCode();
      }
    },

    async regenerateCode() {
      const singleComponent = await this.fetchSingleComponent({
        id: this.currentComponent.id
      });

      await api.post('/rpc/project/regenerate_code', {
        project_id: this.currentProject.id,
        release_short_id: singleComponent.release.short_id,
        project_release_id: singleComponent.project_release
      });
    },

    async reachedScreensLimit() {
      if (this.isPricingScreenFlowActiveExperiment) {
        await this.fetchTeam({
          id: this.currentProject.team_slug,
          params: { is_slug: true },
          skipCache: true
        });
        const oldPro = this.isPro && !this.screensLimit;
        if (oldPro) {
          return false;
        }
        const reached =
          this.screensLimit && this.team.projects_components_count > this.screensLimit && this.screensLimit !== -1;
        if (reached) {
          this.setIsScreenLimitReached(true);
          this.$trackEvent('omniview.code-mode.screens.locked', {
            number_of_screens: this.team.projects_components_count,
            plan: this.teamMembership.team_plan.toLowerCase(),
            plan_screens: this.screensLimit,
            project_id: this.currentProject.id
          });
        } else {
          this.setIsScreenLimitReached(false);
        }
      }
    },

    async getBreakpoints() {
      const { breakpoint } = this.$route.params;
      try {
        this.setLoading({ key: 'breakpoints', value: true });

        await this.$waitFor(() => !this.loading.project && this.ready, true);

        this.setBreakpoints([]);

        const { similar_screens_id } = this.currentProject;

        const { id: currentComponentId } = this.currentComponent;

        const sizes = [];
        let currentSizeIndex = -1;
        let currentSize = -1;
        for (let i = 0; i < (similar_screens_id || this.similarScreensIdsTemp).length; i++) {
          const group = similar_screens_id[i];
          const ids = group.map((g) => g.id);
          if (ids.includes(currentComponentId)) {
            for (let j = 0; j < group.length; j++) {
              const { id } = group[j];
              if (id == currentComponentId) {
                currentSizeIndex = j;
              }
              const foundComponent = this.allCurrentComponents.find((c) => c.id == id);
              sizes.push({ width: foundComponent.width, component: foundComponent });
            }
            break;
          }
        }

        const sizesOnly = sizes.map((s) => s.width);

        if (currentSizeIndex != -1) {
          currentSize = sizesOnly[currentSizeIndex];
        }

        sizes.sort((a, b) => (a.width < b.width ? 1 : -1));

        let breakpoints = sizes.map(({ width, component }) => ({ id: uuid(), width, component }));

        if (breakpoints.length == 0) {
          breakpoints = [{ id: uuid(), width: this.currentComponent.width, component: this.currentComponent }];
        }

        this.setBreakpoints(breakpoints);

        // ffrom route params
        if (!breakpoint || breakpoint != 'res') {
          if (currentSize == -1) {
            this.setActiveBreakpoint(breakpoints[0]);
          } else {
            this.setActiveBreakpoint(breakpoints.find((br) => br.width == currentSize));
          }
        }
      } catch (error) {
        this.setBreakpoints([]);
        this.errors.breakpoints = true;
        this.checkIfAccessError(error);
      } finally {
        this.setLoading({ key: 'breakpoints', value: false });
      }
    },

    setTrackingData() {
      const { currentProject: project } = this;
      const extraData = { sampled_project_id: null };
      if (project?.is_sample_project) {
        extraData.sampled_project_id = project.source_project;
      }
      this.setExtraTrackingData(extraData);
    },
    resetTrackingData() {
      this.setExtraTrackingData({});
    },

    async checkIfAccessError(error) {
      if (error?.response?.status == 403) {
        openModal({ name: 'project-request-access', onCloseRedirect: '/', mode: 'dark' });
      } else {
        errorHandler.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.omniview-container {
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#omniview-content {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  // background-color: #3b3b3b;
}

.main-content {
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #2d2d2d;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>

<template>
  <div class="c slideInLeft">
    <!-- <div class="header">
      <div class="title">Enable overrides</div>
      <div class="toggle">
        <an-toggle v-model="isOverridesEnabled" @change="toggleEnableOverrides" />
      </div>
    </div> -->
    <div style="padding-top: 0" class="body-wrapper">
      <!-- NO OVERRIDES -->
      <EmptyState
        :src="require(`@/assets/illustrations/computer-overrides.svg`)"
        v-if="noOverrides"
        title="No overrides yet"
      >
        Select an element to apply your own CSS and code customizations.
      </EmptyState>
      <!-- OVERRIDES -->
      <div v-else>
        <div v-for="(item, idx) in currentComponentOverrides" :key="idx">
          <div @click="handleOverrideClick(item)" class="override">
            <div class="override-title truncate">
              {{ getNodeName(idx, item) }}
            </div>
            <div class="override-text">
              <svg-icon
                v-if="item.br && item.br.width"
                v-tip="{
                  content: `Override made on ${mapWidthToBreakpointText(item.br.width)} ${item.br.width}px breakpoint`,
                  placement: 'bottom-center',
                  offset: 4
                }"
                style="margin-right: 10px; flex-shrink: 0"
                :name="mapWidthToBreakpointIcon(item.br.width)"
                :size="18"
                fill="currentColor"
              />
              <div class="truncate" style="flex: 1">
                <span class="override-item" v-if="item.viewName">name : {{ item.viewName }}</span>
                <span class="override-item" v-if="item.tagName">tag : {{ item.tagName }}</span>
                <span class="override-item" v-for="(value, key) in item.css" :key="key">{{ key }} : {{ value }};</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import EmptyState from '@/components/OmniView/EmptyState.vue';
import { EventBus } from '@/services/bus';
import { isEmpty, has } from 'lodash-es';
import { SELECT_OVERRIDE_NODE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { mapWidthToBreakpointIcon, mapWidthToBreakpointText } from '@/components/OmniView/utils';

export default {
  components: {
    EmptyState
  },
  data() {
    return {
      isOverridesEnabled: false
    };
  },
  mounted() {
    this.readValues();
  },

  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('componentsMetadata', { projectMetadata: 'items' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('components', { allComponents: 'items' }),
    ...mapState('omniview', { breakpoints: 'breakpoints' }),

    ...mapGetters({
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      nodesWithOverrides: 'omniview/nodesWithOverrides'
    }),

    currentComponentOverrides() {
      let overrides = [];

      for (let index = 0; index < this.projectMetadata.length; index++) {
        const m = this.projectMetadata[index];

        let metadataComponent = this.allComponents.find((c) => c.slug == m.component_slug);

        if (!metadataComponent) continue;
        // we only display overrides for the current component or its breakpoints

        if (metadataComponent.master_slug == this.currentComponent.master_slug) {
          const br = this.breakpoints.find((b) => b.component.id == metadataComponent.id);

          if (m.overrides && !isEmpty(m.overrides)) {
            Object.keys(m.overrides).map((nodeKey) => {
              let nodeOverrides = m.overrides[nodeKey];

              let isInIFrame = has(this.nodesWithOverrides, nodeKey);

              // we don't want to show overrides that does not exist anymore in the iframe

              if (!isEmpty(nodeOverrides) && isInIFrame) {
                let ov = {
                  id: nodeKey,
                  componentId: metadataComponent.id,
                  br,
                  css: [],
                  tagName: false,
                  nodeName: nodeOverrides.nodeName || false
                };
                const isCSSOverride = nodeOverrides.css && !isEmpty(nodeOverrides.css);
                if (isCSSOverride) {
                  ov['css'] = nodeOverrides.css;
                }
                if (nodeOverrides.tagName) {
                  ov['tagName'] = nodeOverrides.tagName;
                }
                if (nodeOverrides.viewName) {
                  ov['viewName'] = nodeOverrides.viewName;
                }
                if (nodeOverrides.tagName || isCSSOverride) {
                  overrides.push(ov);
                }
              }
            });
          }
        }
      }

      return overrides;
    },

    noOverrides() {
      return this.currentComponentOverrides.length == 0;
    }
  },
  methods: {
    mapWidthToBreakpointIcon,
    mapWidthToBreakpointText,
    ...mapActions({
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    getNodeName(idx, item) {
      let name = item.viewName
        ? item.viewName
        : this.nodesWithOverrides[item.id].textContent
        ? `Element ${idx + 1} : ${this.nodesWithOverrides[item.id].textContent}`
        : item.nodeName
        ? item.nodeName.split(' ')[0]
        : `Element ${idx + 1}`;

      return name;
    },
    handleOverrideClick(item) {
      // we check if we are on the right breakpoint no need to change br

      const shouldChangeBreakpoint = item.componentId != this.currentComponent.id;

      if (shouldChangeBreakpoint && item.br) {
        EventBus.$emit('handleBreakpointChange', item.br);
      }

      EventBus.$emit(SEND_MESSAGE, {
        action: SELECT_OVERRIDE_NODE,
        data: {
          nodeId: item.id,
          metadata: {
            source: 'client',
            scrollIntoView: true
          }
        }
      });
    },
    readValues() {
      this.isOverridesEnabled = this.project.is_overrides_enabled || false;
    },
    async toggleEnableOverrides(isOverridesEnabled) {
      const { projectId } = this.$route.params;
      this.isOverridesEnabled = isOverridesEnabled;

      await this.updateProject({
        id: projectId,
        payload: {
          is_overrides_enabled: isOverridesEnabled
        }
      });
      EventBus.$emit('regenerate', { refresh: false });
      this.fetchProject({ id: projectId, skipCache: true });
    }
  },
  watch: {
    project() {
      this.readValues();
    }
  }
};
</script>

<style lang="scss" scoped>
.c {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;
  z-index: 10;
  .title {
    font-size: 16px;
    color: white;
  }
  .toggle {
    margin-left: auto;
  }
}

.body-wrapper {
  flex: 1 1 auto;
  padding-top: 60px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.override {
  padding: 24px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #2d2d2d;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: #333333;
  }

  &-title {
    color: white;
    font-size: 14px;
    margin-bottom: 4px;
  }

  &-text {
    display: flex;
    align-items: center;
    opacity: 0.4;
    color: white;
    font-size: 12px;
  }

  &-item {
    margin-right: 5px;
    flex-shrink: 0;
  }
}
</style>

<template>
  <div class="toggle-container" @click="onClick">
    <input type="checkbox" :checked="value" hidden aria-hidden="true" />
    <div class="toggle">
      <div class="toggle-inner">
        <div class="text" :class="{ active: value }">{{ onText }}</div>
        <div class="text" :class="{ active: !value }">{{ offText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-toggle',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    onText: {
      type: String,
      default: 'Yes'
    },
    offText: {
      type: String,
      default: 'No'
    }
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
      this.$emit('change', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
$border-radius: 120px;
.toggle-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  height: 36px;
  border-radius: 12px;
  transition: all 400ms;
  background-color: var(--light-container-background);
  border-radius: $border-radius;
}
.toggle .toggle-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 100%;
  transition: all 400ms;
  .text {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: var(--primary);
    height: 100%;
    padding: 0 24px;
  }
  .active {
    color: #ffffff;
    background-color: var(--primary);
    border-radius: $border-radius;
  }
}
input:checked + .toggle .toggle-inner .active {
  margin-left: 0;
}
</style>

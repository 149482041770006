<template>
  <form class="payment-method-feedback-form" data-cy="payment-method-feedback-form" @submit.prevent="submit">
    <label>
      If you require a different payment method, please indicate the method of your prefence
      <an-textarea
        v-model="feedback"
        placeholder="e.g. Bank transfer"
        data-cy="payment-method-feedback-input"
        withBorder
        allowMultiline
      />
    </label>

    <an-button type="submit">Submit</an-button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      feedback: ''
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.feedback);
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-method-feedback-form {
  display: grid;
  gap: 20px;

  ::v-deep textarea {
    margin-top: 20px;
  }
  .an-button.primary {
    width: max-content;
  }
}
</style>

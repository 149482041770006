<template>
  <div>
    <!-- Files section -->
    <div class="separated">
      <div class="upload-header">
        <div class="label">Media</div>
      </div>

      <DefaultLoader v-if="isFilesLoading" />

      <!-- Empty state -->
      <div v-else-if="!computedFiles.length" class="empty-state">
        <div class="empty-state-title">No files yet</div>
        <div>
          Upload videos and GIFs to use in your prototype and get a URL you can paste in the plugin.
          <an-link variant="primary" href="https://docs.animaapp.com/v3/sketch/prototype/videos.html">
            Learn more
          </an-link>
        </div>
        <div class="empty-state-upload-button">
          <Upload
            :url="uploadUrl('file')"
            :accept="uploadAccept"
            :context="{ type: 'file' }"
            @start="onUploadStart"
            @success="onUploadSuccess"
            @failure="onUploadFailure"
          >
            <an-button variant="secondary" :isWorking="isWorking">Add files</an-button>
          </Upload>
        </div>
      </div>

      <!-- Files list -->
      <div v-else class="files-list">
        <an-tooltip text="Upload a new file">
          <div class="upload-image">
            <UploadImage
              :url="uploadUrl('file')"
              :accept="uploadAccept"
              :width="110"
              :height="64"
              @success="onUploadSuccess"
              @start="onUploadStart"
            />
          </div>
        </an-tooltip>
        <FileRow
          v-for="item in computedFiles"
          :key="item.id"
          :item="item"
          :fileName="item.name"
          :uploadDate="item.updated_at"
          :actions="fileActions"
        >
          <div slot="thumbnail" class="file-thumbnail">
            <video v-if="isVideo(item.ext)" :src="item.url" muted />
            <RoundedImage v-else :width="110" :height="64" :src="getThumbnail(item)" :key="item.url">
              <div>{{ item.ext }}</div>
            </RoundedImage>
          </div>
        </FileRow>
      </div>
    </div>

    <!-- Fonts section -->
    <div class="separated">
      <div class="label">Fonts</div>

      <DefaultLoader v-if="isFontsLoading" />

      <!-- Empty state -->
      <div v-else-if="!fontsList.length" class="empty-state">
        <div class="empty-state-title">No fonts yet</div>
        <div>Here will be a list of the fonts this project members have uploaded.</div>
      </div>

      <!-- Fonts list -->
      <div v-else class="files-list">
        <FileRow
          v-for="item in fontsList"
          :key="item.id"
          :item="item"
          :fileName="item.family"
          :uploadDate="item.updated_at"
          :actions="fontActions"
        >
          <div slot="thumbnail" class="font-thumbnail" :style="fontThumbnailStyle(item)">Aa</div>
        </FileRow>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { mapState, mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/utils/date';
import { toastError, toastSuccess } from '@/services/bus';
import DefaultLoader from '@/components/Loading/DefaultLoader';
import Upload from '@/components/Upload/Upload';
import UploadImage from '@/components/Upload/UploadImage';
import FileRow from '@/components/Project/Settings/Files/FileRow';
import RoundedImage from '@/components/Upload/RoundedImage';
import { deleteArrayItemById } from '@/utils/javascript';
import copy from '@/utils/copyToClp';
import api from '@/api';
import { getCdnUrl } from '@/utils/urls';
import errorHandler from '@/services/errorHandler';

export default {
  data() {
    return {
      uploadAccept: 'video/*,image/*,application/JSON,application/pdf',
      filesList: [],
      fontsList: [],
      isFilesLoading: false,
      isFontsLoading: false,
      isWorking: false,
      fileActions: [
        { label: 'Copy URL', onClick: this.copyUrl },
        { label: 'Remove', isSeparated: true, isMarked: true, onClick: this.removeFile }
      ],
      fontActions: [{ label: 'Remove', isMarked: true, onClick: this.removeFont }]
    };
  },
  components: {
    DefaultLoader,
    Upload,
    UploadImage,
    FileRow,
    RoundedImage
  },
  mounted() {
    this.fetchData();
  },
  beforeDestroy() {
    this.clearFonts();
  },
  computed: {
    ...mapState('files', { files: 'items' }),
    ...mapState('fonts', { fonts: 'items' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapGetters({ isActiveExperiment: 'experiments/isActive' }),
    uploadUrl() {
      return (type) => `/v2/uploads/projects/${this.project.id}/${type}`;
    },
    deleteUrl() {
      return (type) => `/v2/uploads/projects/${this.project.id}/${type}`;
    },
    fontThumbnailStyle() {
      return (font) => ({
        fontFamily: `${font.family}, 'Mulish', sans-serif`
      });
    },
    computedFiles() {
      return this.filesList.map((f) => ({
        ...f,
        ext: this.getFileExtension(f)
      }));
    }
  },
  methods: {
    ...mapActions({
      fetchFiles: 'files/fetchAllOfParent',
      fetchFonts: 'fonts/fetchAllOfParent',
      createFont: 'fonts/create',
      deleteFont: 'fonts/delete'
    }),
    formatDate,
    async fetchData({ skipCache = false, type } = {}) {
      try {
        const { short_id: id } = this.project;
        if (!id) return;
        this.isFilesLoading = !type || type === 'file';
        this.isFontsLoading = !type || type === 'font';
        if (this.isFilesLoading) {
          await this.fetchFiles({ parent: 'projects', id, skipCache });
          this.filesList = cloneDeep(this.files);
        }
        if (this.isFontsLoading) {
          await this.fetchFonts({ parent: 'projects', id, skipCache });
          this.fontsList = cloneDeep(this.fonts);
          this.loadFonts();
        }
      } catch (err) {
        errorHandler.captureException(err);

        toastError('Failed fetching project files');
      } finally {
        this.isFilesLoading = false;
        this.isFontsLoading = false;
      }
    },
    async loadFonts() {
      this.clearFonts();
      this.fonts.forEach(async (f) => {
        const fontFace = new FontFace(f.family, `url(${f.file_url})`);
        const loaded = await fontFace.load();
        document.fonts.add(loaded);
      });
    },
    clearFonts() {
      document.fonts.clear();
    },
    copyUrl({ url }) {
      this.$trackEvent('project-settings-files.copy-file-url-button.click');
      copy(url);
      toastSuccess('URL copied to clipboard.');
    },
    isImage(extension) {
      const imageRegex = new RegExp('\\.(svg|jpg|jpeg|png|gif)$', 'i');
      return imageRegex.test(extension);
    },
    isVideo(extension) {
      const videoRegex = new RegExp('\\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|mka|m4a|aac|ogg)$', 'i');
      return videoRegex.test(extension);
    },
    isPdfOrJson(extension) {
      const regex = new RegExp('\\.(pdf|json)$', 'i');
      return regex.test(extension);
    },
    getFileExtension({ name }) {
      const extRegex = new RegExp('[^\\\\]*\(\\.\\w+)$');
      const match = name?.match(extRegex);
      return match && match[1]?.toUpperCase();
    },
    getThumbnail({ ext, url }) {
      if (this.isImage(ext)) {
        return getCdnUrl(url, { size: 300 });
      } else if (this.isPdfOrJson(ext)) {
        const e = ext.substr(1).toLowerCase(); // omit '.' + lowercase
        return require(`@/components/Project/Settings/Files/${e}-ext-thumbnail.svg`);
      } else {
        return null;
      }
    },
    downloadFile() {
      this.$trackEvent('project-settings-files.download-file-button.click');
    },
    onUploadStart() {
      this.isWorking = true;
      this.$trackEvent('project-settings-files.upload-file-button.click');
    },
    async onUploadSuccess() {
      this.$trackEvent('project-settings-files.upload-file.success');
      this.isWorking = false;
      await this.fetchData({ skipCache: true, type: 'file' });
      toastSuccess('File was successfully uploaded');
    },
    onUploadFailure() {
      this.$trackEvent('project-settings-files.upload-file.failure');
      this.isWorking = false;
      toastError('Failed uploading file :(');
    },
    async removeFileFromBucket({ name, type = 'file' }) {
      await api.delete(this.deleteUrl(type), name);
    },
    async removeFile(file) {
      try {
        this.$trackEvent('project-settings-files.remove-file-button.click');

        this.filesList = deleteArrayItemById(this.filesList, file.url, 'url');
        await this.removeFileFromBucket({ name: file.name, type: 'file' });

        this.$trackEvent('project-settings-files.remove-file.success');
      } catch (err) {
        this.$trackEvent('project-settings-files.remove-file.failure');
        toastError('Failed removing file');
        this.filesList.unshift(file);
      }
    },
    async removeFont(font) {
      try {
        this.$trackEvent('project-settings-files.remove-font-button.click');

        this.fontsList = deleteArrayItemById(this.fontsList, font.id);
        await this.removeFileFromBucket({ name: font.file_name, type: 'font' });
        await this.deleteFont(font);

        this.$trackEvent('project-settings-files.remove-font.success');
      } catch (err) {
        this.$trackEvent('project-settings-files.remove-font.failure');
        toastError('Failed removing font');
        this.fontsList.unshift(font);
      }
    }
  },
  watch: {
    project() {
      this.fetchData({ skipCache: true });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
.separated {
  &:first-child {
    padding-top: 0;
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
}
.empty-state {
  text-align: center;
  width: 375px;
  margin: 0 auto;
  color: var(--secondary-text);
  margin-top: 20px;
  &-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  &-upload-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.files-list {
  padding: 30px 0;
  .upload-image {
    margin-bottom: 30px;
    opacity: 0.4;
  }
}
.clickable-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    margin-bottom: 0;
  }
}
.files-title {
  font-weight: bold;
  font-size: 22px;
}
.align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * + * {
    margin-left: 10px;
  }
}
.live {
  color: var(--green);
}
.font-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  font-size: 36px;
  line-height: 50px;
  user-select: none;
  box-shadow: var(--shadow);
  background-color: #ffffff;
}
.file-thumbnail {
  width: 110px;
  height: 64px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>

import { readCookie } from '@/utils/cookie';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { isDev } from '@/utils/environment';
import errorHandler from '@/services/errorHandler';

class AnimaSocket {
  constructor(channel, options = {}) {
    const url = `${process.env.WEBSOCKET_URL}/ws/${channel}`;

    this.instance = new ReconnectingWebSocket(url, [], options);
    this.debug = isDev() || !!readCookie('anima-debug');

    if (this.debug) {
      this.instance.addEventListener('message', ({ data }) =>
        this.console('[SOCKET] Message arrived', JSON.parse(data))
      );
    }
  }

  console() {
    if (this.debug) {
      // eslint-disable-next-line
      console.debug(...arguments);
    }
  }

  on({ resource, action, page, res_id }, cb) {
    const resource_type = resource.toLowerCase();
    const operation_type = action?.toLowerCase();
    const page_id = page?.toLowerCase();
    const resource_id = res_id?.toLowerCase();
    this.instance.addEventListener('message', (event) => {
      try {
        const { data } = JSON.parse(event?.data) ?? {};
        const relevantResource = resource_type === data.resource_type?.toLowerCase();
        const relevantOperation = !operation_type || operation_type === data.operation_type?.toLowerCase();
        const relevantPageId = !page_id || page_id === data.page_id?.toLowerCase();
        const relevantResourceId = !resource_id || resource_id === data.resource_id?.toLowerCase();

        if (relevantResource && relevantOperation) {
          cb(data.resource, data.operation_type?.toLowerCase(), {
            resourceType: data.resource_type?.toLowerCase(),
            operationType: data.resource_type?.toLowerCase(),
            pageId: data.page_id?.toLowerCase(),
            resourceId: data.resource_id?.toLowerCase(),
            relevantResourceId,
            relevantPageId
          });
        }
      } catch (err) {
        errorHandler.captureException(err);
      }
    });
  }

  close() {
    this.instance.close();
  }
}

export default AnimaSocket;

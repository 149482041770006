export const formatAddonPrice = (addonPrices, countryCode, interval) => {
  let preferredPrices = addonPrices?.filter((point) => point.billingCountryCode === countryCode);

  if (preferredPrices.length === 0) {
    preferredPrices = addonPrices?.filter((point) => point.currency === 'USD');
  }

  const pricePoint = preferredPrices?.find((point) => point.billingPeriod === interval);

  return pricePoint;
};

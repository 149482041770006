<template>
  <v-tip
    placement="bottom-right"
    :trigger="'click'"
    offset="0"
    @update:open="handleMenuOpen"
    data-cy="code-tab-more-menu"
  >
    <slot></slot>
    <template slot="popover">
      <div class="code-tab-menu-pop">
        <div
          :class="item.divider ? 'code-tab-menu-divider' : 'code-tab-menu-item'"
          @click="handleMenuClick(index)"
          v-for="(item, index) in menuOptions"
          :key="item.id ? item.id : index"
          v-close-popover
        >
          {{ item.name }}
        </div>
      </div>
    </template>
  </v-tip>
</template>

<script>
export default {
  props: {
    menuOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleMenuClick(index) {
      const item = this.menuOptions[index];
      if (item.divider) {
        return;
      }

      this.$emit('menu-item-selected', item.id);
    },
    handleMenuOpen(open) {
      if (open) {
        this.$emit('menu-opened');
      }
    }
  }
};
</script>

<style scoped>
.code-tab-menu-pop {
  width: 207px;
  padding: 8px 0;
  background: var(--secondary);
  color: var(--dark-primary-text);
  box-shadow: 0px 4px 12px rgba(20, 20, 20, 0.5);
  border-radius: 8px;
  top: 100%;
}
.code-tab-menu-item {
  font-size: 12px;
  line-height: 20px;
  font-family: var(--base-font);
  padding: 8px 16px;
  cursor: pointer;
}
.code-tab-menu-item:hover {
  background: #333333;
}
.code-tab-menu-divider {
  border-bottom: 1px solid #2d2d2d;
}
</style>

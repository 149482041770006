<template>
  <div class="toasts" :style="{ zIndex: !!toasts.length ? 50000 : -1 }" data-cy="toast">
    <Toast
      v-for="toast in toasts"
      :key="toast.id"
      :variant="toast.variant"
      :content="toast.content"
      :componentProps="toast.componentProps"
      @close="removeToast(toast)"
    />
  </div>
</template>
<script>
import { EventBus } from '@/services/bus';
import Toast from '@/components/Toast/Toast.vue';
import { uuid } from '@/utils/uuid';

export default {
  components: {
    Toast
  },
  data() {
    return {
      toasts: []
    };
  },
  mounted() {
    EventBus.$on('toast', this.addToast);
  },
  destroyed() {
    EventBus.$off('toast', this.addToast);
  },
  methods: {
    addToast(payload) {
      if (!this.isDesktop) return;

      this.$trackEvent('webapp.toast.display', payload);

      const id = uuid();
      let toast = {
        ...payload,
        id
      };
      this.toasts.unshift(toast);
      setTimeout(() => this.removeToast(toast), toast.timeout || 5000);
    },
    removeToast({ id }) {
      this.toasts = this.toasts.filter((t) => t.id !== id);
    }
  }
};
</script>
<style lang="scss">
.toasts {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  margin: 0 auto;
  padding-top: 15px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<template>
  <div class="container" data-cy="upgrade-team-modal">
    <div class="illustration">
      <img :src="illustrationSrc" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="subtitle" v-html="contentHtml" />
    <div class="actions">
      <an-button data-cy="upgrade-team-btn" @click="openPlans">
        <Calendly v-if="this.calendlyLink" :url="this.calendlyLink" displayType="popup">
          {{ this.ctaTitle || 'See upgrade plans' }}
        </Calendly>
        <span v-else>
          {{ this.ctaTitle || 'See upgrade plans' }}
        </span>
      </an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import Calendly from '@/components/Calendly/Calendly';

export default {
  name: 'upgrade-team',
  components: {
    Calendly
  },
  props: {
    title: {
      type: String,
      required: true
    },
    calendlyLink: {
      type: String
    },
    ctaTitle: {
      type: String
    },
    illustrationType: {
      type: String,
      default: 'clock',
      validator: (v) => ['clock', 'gauge'].includes(v)
    },
    content: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'light',
      validator: (v) => ['light', 'dark'].includes(v)
    },
    teamSlug: {
      type: String
    },
    source: {
      type: String
    },
    nextPage: {
      type: Object
    },
    afterClosing: {
      type: Function
    }
  },
  computed: {
    contentHtml() {
      return this.content.replaceAll('\n', '<br />');
    },
    illustrationSrc() {
      return this.illustrationType === 'clock'
        ? require(`@/assets/illustrations/quirky-clock-${this.mode}.svg`)
        : require(`@/assets/illustrations/dash-gauge.svg`);
    }
  },
  methods: {
    openPlans() {
      const params = {
        teamSlug: this.$route.params.teamSlug || this.teamSlug,
        source: this.source,
        nextPage: this.nextPage,
        afterClosing: this.afterClosing
      };
      this.$trackEvent('upgrade-team-modal.pricing-button.click', { page: this.$route.name });
      if (!this.calendlyLink) {
        this.$router.push({ name: 'team-pricing', params });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  margin: 40px 35px;
  @include mobile {
    margin: 40px auto;
    padding: 0;
    max-width: 295px;
  }
}
.container::after {
  height: 0;
}
.subtitle {
  width: 100%;
  @include mobile {
    margin-bottom: 0;
  }
}
.body {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  .text {
    margin-top: 30px;
  }
}
.illustration {
  height: 120px;
  width: 198px;
}

.actions {
  margin-top: 30px;
}
</style>

export const SEND_MESSAGE = 'send-message';
export const SEND_COMPONENT_MESSAGE = 'send-component-message';
export const DEACTIVATE_ALL = 'deactivate-all';
export const ACTIVATE_HOTSPOTS = 'activate-hotspots';
export const DEACTIVATE_HOTSPOTS = 'deactivate-hotspots';
// OVERRIDES
export const ACTIVATE_OVERRIDES = 'activate-overrides';
export const DEACTIVATE_OVERRIDES = 'deactivate-overrides';
export const SELECT_OVERRIDE_NODE = 'select-override-node';
export const CLEAR_CODE_SELECTION = 'clear-selection';
export const SIMULATE_CODE_MOUSEOVER = 'simulate-code-mouseover';
export const SIMULATE_CODE_MOUSOUT = 'simulate-code-mouseout';

// COMMENTS
export const ACTIVATE_COMMENTS = 'activate-comments';
export const DEACTIVATE_COMMENTS = 'deactivate-comments';
export const RESET_COMMENTS = 'reset-comments';
export const SEND_COMMENTS_DATA = 'comments-data';
export const HIGHLIGHT_COMMENT = 'select-comment-node';
export const SIMULATE_MOUSEOVER = 'simulate-mouseover';
export const SIMULATE_MOUSEOUT = 'simulate-mouseout';
// PANEL
export const CLOSE_PANEL = 'close-panel';
export const OPEN_PANEL = 'open-panel';
export const PANEL_DRAG = 'panel-drag';

<template>
  <div class="addon-row" :class="{ selected: isSelected, disabled }" @click="onClick">
    <div class="content">
      <div class="plan-type">{{ addon.displayName }}</div>
      <ul class="plan-entitlements">
        <span v-for="(entitlement, index) in addon.entitlements" :key="entitlement.id">
          <span v-if="index > 0">, </span>
          <span v-if="entitlement.hasUnilimitedUsage">Unlimited {{ entitlement.feature.displayName }}</span>
          <span v-else>{{ entitlement.usageLimit * (quantity || 1) }} {{ entitlement.feature.displayName }}</span>
        </span>
      </ul>
      <div class="price-container">
        <div class="price">{{ formattedPrice }}</div>
        <div class="per-amount">/ {{ formattedInterval }}</div>
      </div>
      <div v-if="priceTags.length" class="price-badges-container">
        <Badge v-for="tag in priceTags" :key="tag" :name="tag" />
      </div>
    </div>
    <an-number-input
      v-if="shouldShowQuantity"
      v-model="quantity"
      :min="minUnitQuantity"
      :max="maxUnitQuantity"
      minErrorTooltipText=""
    />
  </div>
</template>

<script>
import { formatPrice } from '@/utils/billing';
import { formatAddonPrice } from '@/utils/formatAddonPrice';
import Badge from '@/components/Pricing/Badge';

export default {
  data() {
    return {
      quantity: this.selectedValue || 0
    };
  },
  props: {
    interval: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    selectedValue: {
      type: Number
    },
    disabled: {
      type: Boolean
    },
    addon: {
      required: true
    },
    countryCode: {
      type: String,
      default: 'US'
    },
    shouldShowQuantity: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedPrice() {
      const addonPrices = this.addon.pricePoints;
      const countryCode = this.countryCode.toLowerCase();

      const pricePoint = formatAddonPrice(addonPrices, countryCode, this.interval);

      return formatPrice(pricePoint.amount * (this.quantity || 1), { currency: pricePoint.currency });
    },
    priceTags() {
      try {
        const priceTags = JSON.parse(this.addon?.metadata?.priceTags || '[]');
        const tagsForBillingPeriod = priceTags[this.interval];
        return tagsForBillingPeriod || [];
      } catch (e) {
        return [];
      }
    },
    formattedInterval() {
      return this.interval.toLowerCase();
    },
    minUnitQuantity() {
      return this.addon?.pricePoints?.find((point) => point.billingPeriod === this.interval)?.minUnitQuantity || 0;
    },
    maxUnitQuantity() {
      return this.addon?.metadata?.maxQuantity || 99;
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click', { id: this.addon.id, quantity: this.quantity });
      }
    }
  },
  components: {
    Badge
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

.addon-row {
  display: flex;
  justify-content: space-between;
  border: var(--border);
  border-radius: 10px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;

  padding-right: 40px;

  .plan-entitlements {
    font-size: 13px;
  }

  .content {
    margin-left: 40px;
  }

  .ribbon .svg-container svg {
    border-radius: 0 0 10px;
  }

  &:hover,
  &.selected {
    border: var(--primary-border);
  }

  &.disabled {
    opacity: 0.4;
  }

  + .addon-row {
    margin-top: 20px;
  }
}

.plan-type {
  font-weight: bold;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.price {
  @include headline;
  line-height: 28px;
  font-size: 17px;
}

.per-amount {
  font-size: 14px;
}

.price-badges-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 4px;
  gap: 4px;
}
</style>

<template>
  <div
    v-if="displayType === 'inline'"
    ref="calendlyForm"
    class="calendly-inline-widget"
    :data-url="url"
    style="min-width: 320px; height: 830px"
    scrolling="no"
    frameborder="0"
    allowtransparency="true"
  />
  <div @click="openCalendly" v-else>
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    displayType: {
      type: String,
      default: 'inline',
      validator: (v) => ['inline', 'popup'].includes(v)
    }
  },
  data() {
    return {
      calendlyUr: ''
    };
  },
  computed: {
    ...mapState('users', { user: 'currentItem' })
  },
  mounted() {
    const url = new URL(this.url);
    url.searchParams.append('hide_gdpr_banner', '1');
    const prefillDetails = {
      email: this.user?.email,
      name: this.user?.name
    };
    Object.entries(prefillDetails).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
    this.calendlyUr = `${url.href}`;
    if (this.displayType === 'inline') {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      document.head.appendChild(recaptchaScript);
      const calendlyFormRef = this.$refs.calendlyForm;
      if (calendlyFormRef?.childElementCount > 0) {
        calendlyFormRef.querySelector('iframe').src = url.href;
      } else {
        calendlyFormRef.dataset.url = url.href;
      }
    } else {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://calendly.com/assets/external/widget.js');
      document.head.appendChild(recaptchaScript);
      const style = document.createElement('link');
      style.type = 'text/css';
      style.rel = 'stylesheet';
      style.href = 'https://calendly.com/assets/external/widget.css';
      document.head.appendChild(style);
    }
  },
  methods: {
    openCalendly() {
      // eslint-disable-next-line no-undef
      Calendly.showPopupWidget(this.calendlyUr);
      this.$trackEvent('calendly.link.clicked', {
        url: this.calendlyUr
      });
      return false;
    }
  }
};
</script>

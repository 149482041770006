import Vue from 'vue';
import mixpanel from 'mixpanel-browser';
import TrackingService from '@animaapp/anima-tracking';
import store from '@/store';
import { addDebugEvent } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import { isDev } from '@/utils/environment';

const config = {
  web: true,
  clientId: 'com.animaapp.web',
  baseURL: process.env.TRACKING_BASE_URL
};

export const tracking = new TrackingService(config);
export const trackEvent = (eventName, params = {}) => {
  if (!eventName) {
    return;
  } else if (localStorage.getItem('animp')) {
    // if impersonating - don't track
    return;
  }

  const extraTrackingData = store.getters['tracking/extraData'];
  const isChromeExtension = store.state.omniview?.isChromeExtension;

  if (isChromeExtension) {
    let extensionName = store.state['omniview'].extensionName;

    // add this flag so we can detect if this event is coming from the chrome extension or not
    params.extensionName = extensionName;
    params.fromChromeExtension = true;
  }
  try {
    tracking.trackEvent(eventName, { ...extraTrackingData, ...params });
    Vue.prototype?.$hj && Vue.prototype?.$hj('event', eventName);
  } catch (err) {
    errorHandler.captureException(err);
  }

  addDebugEvent({ eventName, params: { ...extraTrackingData, ...params } });
};

export const trackUserProps = (props) => {
  try {
    tracking.setUserProperties(props);
  } catch (err) {
    errorHandler.captureException(err);
  }
};

export const debugEvent = (eventName, params = {}) => {
  const extraTrackingData = store.getters['tracking/extraData'];
  addDebugEvent({ eventName, params: { ...extraTrackingData, ...params } });
};

// remove timeout until refactor for better measuring
export const measure = (label) => tracking.measureStart(`web-app.${label}`);
export const measureEnd = (label, props) => tracking.measureEnd(`web-app.${label}`, props);

export const initMixpanel = () => {
  const options = { debug: isDev() };
  mixpanel.init(process.env.MIXPANEL_TOKEN, options);
};

import api from '@/api';
import { EventBus } from '@/services/bus';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { updateArrayItemById } from '@/utils/javascript';
import { cloneDeep } from 'lodash-es';
import { createStoreModule } from '../utils';
import errorHandler from '@/services/errorHandler';
export default createStoreModule({
  name: 'componentsMetadata',
  modelName: 'components_metadata',
  crud: true,
  endpoint: '/v2/components_metadata',
  state: {
    items: []
  },
  mutations: {
    setMetadata: (state, metadata) => (state.items = metadata),
    setCurrentComponentMetadata: (state, { componentId, fields }) => {
      let newItems = updateArrayItemById(state.items, componentId, fields);
      state.items = newItems;
    }
  },
  getters: {
    currentComponentMetadata: (state, _getters, rootState, rootGetters) => {
      const metadatas = state.items;
      const currentComponentSlug = rootState.components.currentItem.slug;
      const webInstancesMap = rootGetters['webComponents/webInstancesMap'];
      const metadata = metadatas.find((m) => m.component_slug == currentComponentSlug) || { overrides: {} };

      let serverOverrides = cloneDeep(metadata.overrides || {});

      Object.keys(webInstancesMap).forEach((key) => {
        if (!serverOverrides[key]) {
          serverOverrides[key] = {};
        }

        serverOverrides[key] = {
          ...serverOverrides[key],
          viewName: webInstancesMap[key]['component_name'],
          is_component: true
        };
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: 'set_server_overrides',
        serverOverrides
      });

      return metadata;
    }
  },
  actions: {
    fetchMetadata: async (ctx, { id, params, skipCache = false }) => {
      const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
      try {
        const url = `/v2/projects/${id}/components_metadata/`;
        const { data } = await api.list(url, { params: { ...params, page_size: 500 }, cachePolicy });
        ctx.commit(`setMetadata`, data.results);
        return data;
      } catch (error) {
        // eslint-disable-next-line
        errorHandler.captureException(error);
        return error;
      }
    },
    updateAnimaScripts({ state, rootState, rootGetters }) {
      const metadatas = state.items;
      const currentComponentSlug = rootState.components.currentItem.slug;
      const webInstancesMap = rootGetters['webComponents/webInstancesMap'];

      const metadata = metadatas.find((m) => m.component_slug == currentComponentSlug) || {};

      let serverOverrides = cloneDeep(metadata.overrides || {});

      Object.keys(webInstancesMap).forEach((key) => {
        if (!serverOverrides[key]) {
          serverOverrides[key] = {};
        }

        serverOverrides[key] = {
          ...serverOverrides[key],
          viewName: webInstancesMap[key]['component_name'],
          is_component: true
        };
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: 'set_server_overrides',
        serverOverrides
      });
      EventBus.$emit('update-anima-scripts-modelNodes');
    },
    updateNodeOverrides: async ({ getters, dispatch, commit }, { nodeId, fields }) => {
      // const { short_id: projectId } = rootState['projects']['currentItem'];

      const currentNodeOverrides = getters.currentComponentMetadata.overrides[nodeId] || {};
      const overridesPayload = {
        ...getters.currentComponentMetadata.overrides,
        [nodeId]: {
          ...currentNodeOverrides,
          ...fields
        }
      };

      const payload = {
        componentId: getters.currentComponentMetadata.id,
        fields: {
          overrides: overridesPayload
        }
      };

      dispatch('updateAnimaScripts');
      commit('setCurrentComponentMetadata', payload);
      dispatch('update', {
        id: getters.currentComponentMetadata.id,
        payload: {
          overrides: overridesPayload
        }
      });
      return payload;
    }
  }
});

<template>
  <div>
    <div class="omniview-container">
      <div class="loader">
        <PageNotFound v-if="is404Error" />
        <LoadingScreen
          v-else-if="!isProjectAndReleaseReady"
          :delay="false"
          theme="dark"
          :text="currentProject && currentProject.is_syncing ? 'creating Release' : null"
          :type="currentProject && currentProject.is_syncing ? 'syncing' : 'default'"
        />
      </div>

      <transition name="fadeIn">
        <div class="wrapper" v-show="isProjectAndReleaseReady && !is404Error">
          <OmniviewHeader @screenChange="handleScreenChange"></OmniviewHeader>
          <CodegenMixinWrapper v-if="isPlaygroundOmniView">
            <OmniviewPlayground v-if="showPlayground" />
            <OmniviewCodesandbox v-else />
          </CodegenMixinWrapper>
          <ClassicOmniView v-else />
        </div>
      </transition>
    </div>
    <ExportPanel v-if="isExportPanelOpen" @close="toggleExportPanel" />
    <CodePreferencesPanel v-if="shouldCodePreferencesPanelOpen" />

    <CodeFeedback
      ref="codeFeedbackRef"
      :channelFeedback="channelFeedback"
      :show="Boolean(channelFeedback)"
      @close="toggleCodeFeedback"
    />

    <Codepen />
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import ClassicOmniView from '@/views/AnimaOmniView.vue';
import OmniviewPlayground from '@/views/OmniviewPlayground/OmniviewPlayground.vue';
import OmniviewCodesandbox from '@/views/OmniviewCodesandbox/CodeSandboxOmniView.vue';
import CodePreferencesPanel from '@/components/OmniView/Panels/CodePreferencesPanel.vue';
import ExportPanel from '@/components/OmniView/Panels/ExportPanel.vue';
import Codepen from '@/components/OmniView/Codepen.vue';
import OmniviewHeader from '@/components/OmniView/Header/OmniviewHeader.vue';
import LoadingScreen from '@/components/Loading/LoadingScreen.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import CodeFeedback from '@/components/OmniView/Code/CodeFeedback';
import CodegenMixinWrapper from '@/components/OmniView/CodegenMixinWrapper.vue';

import { get, isEmpty } from 'lodash-es';
import { useStore } from '@/store';
import {
  useOmniview,
  useProject,
  useTracking,
  useCodePreferences,
  useBreakpoints,
  useCheckExportPermissions,
  useLoading,
  useCodeFeedback
} from '@/hooks';
import { openModal, EventBus } from '@/services/bus';
import { isValidURL } from '@/utils/urls';
import { trackEvent } from '@/services/tracking';

const { commit } = useStore();
const route = useRoute();
const { currentProject } = useProject();
const { checkIfExportAllowed, checkIfScreensLimitReached } = useCheckExportPermissions();
const { omniviewFrameworkTrackingProps, trackOmniviewPageView } = useTracking();
const { isPlaygroundOmniView, shouldCodePreferencesPanelOpen } = useCodePreferences();
const { loadBreakpoints } = useBreakpoints(route);

const showPlayground = computed(() => isPlaygroundOmniView.value);

const { loadData, is404Error } = useOmniview(route);
const { isProjectAndReleaseReady } = useLoading();

const { channelFeedback, codeFeedbackRef, toggleCodeFeedback, handleOutsideClick } = useCodeFeedback();

const isExportPanelOpen = ref(false);

const toggleExportPanel = () => {
  if (!isExportPanelOpen.value) {
    checkIfScreensLimitReached();
    const eventPayload = omniviewFrameworkTrackingProps.value;
    trackEvent('omniview.export-code.button-clicked', eventPayload);
  }
  isExportPanelOpen.value = !isExportPanelOpen.value;
};

const setTrackingData = () => {
  const extraData = { sampled_project_id: null };
  if (currentProject.value?.is_sample_project) {
    extraData.sampled_project_id = currentProject.value.source_project;
  }
  commit('tracking/setExtraData', extraData);
};

const openDownloadExportCodeModalIfNeeded = () => {
  const { modal } = route.query;
  if (modal === 'export' && !isEmpty(currentProject.value) && currentProject.value.is_syncing === false) {
    isExportPanelOpen.value = true;
  }
};

const openDownloadPackageModalIfNeeded = () => {
  const { package_download: base64Url } = route.query;
  const downloadUrl = window?.atob(base64Url || '');
  if (isValidURL(downloadUrl)) {
    openModal({
      name: 'package-ready',
      variant: 'center',
      width: 500,
      closeButton: true,
      mode: 'dark',
      props: { downloadUrl }
    });
  }
};

const handleProjectChange = (project) => {
  if (!project?.id) return;
  setTrackingData();
  // is_syncing should be false and not undefined
  if (get(project, 'is_syncing') === false) {
    openDownloadExportCodeModalIfNeeded();
    openDownloadPackageModalIfNeeded();
  }
};

const handleScreenChange = (args) => {
  if (args?.fetchBreakpoints && !isPlaygroundOmniView.value) {
    loadBreakpoints();
  }
};

watch(currentProject, handleProjectChange);

onBeforeMount(() => {
  checkIfExportAllowed(route.params?.projectId);
  trackOmniviewPageView(route.query?.mode);
  loadData();
  EventBus.$on('OPEN_EXPORT_PANEL', toggleExportPanel);
  EventBus.$on('TOGGLE_CODE_FEEDBACK', toggleCodeFeedback);
  document.addEventListener('mousedown', handleOutsideClick);
});
onBeforeUnmount(() => {
  EventBus.$off('OPEN_EXPORT_PANEL', toggleExportPanel);
  EventBus.$off('TOGGLE_CODE_FEEDBACK', toggleCodeFeedback);
  document.removeEventListener('mousedown', handleOutsideClick);
});
</script>

<style lang="scss" scoped>
.omniview-container {
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#omniview-content {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  // background-color: #3b3b3b;
}

.main-content {
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #2d2d2d;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>

<template>
  <div class="an-card">
    <div v-if="$slots.header" class="an-card-head">
      <slot name="header" />
    </div>
    <div class="an-card-body">
      <slot v-if="$slots.body" name="body" />
      <slot v-else />
    </div>
    <div v-if="$slots.footer" class="an-card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
// this component is very dependant on design
export default {
  name: 'an-card'
};
</script>

<style lang="scss" scoped>
.an-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  max-width: 320px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &-head {
    border-bottom: 1px solid #ebeef5;
  }
  &-body {
    flex: 1 1 auto;
    min-height: 1px;
  }
  &-footer {
    border-top: 1px solid #ebeef5;
  }

  &-head,
  &-body,
  &-footer {
    padding: 15px;
  }
}
</style>

<template>
  <div :style="{ height: '100%' }" class="w-full flex items-center justify-center">
    <template v-if="isLoading">
      <DefaultLoader :size="28" />
    </template>

    <div v-show="showBorder && !isLoading" :style="getMaskSize" class="name-border">
      <span class="name">{{ currentWebComponentName }}</span>
    </div>
    <div :style="getMaskSize" v-if="mask && !isLoading" class="mask"></div>
    <transition name="fadeIn">
      <iframe
        v-show="!isLoading"
        ref="iframeRef"
        :style="getComponentFrameStyles"
        :name="name"
        :id="name"
        frameborder="0"
      ></iframe>
    </transition>
  </div>
</template>

<script>
import { ACTIVATE_OVERRIDES, CLEAR_CODE_SELECTION, SEND_COMPONENT_MESSAGE } from '@/utils/events/omniviewEvents';
import DefaultLoader from '@/components/Loading/DefaultLoader';
import { mapGetters, mapMutations } from 'vuex';
import { EventBus } from '@/services/bus';
import { get } from 'lodash-es';
export default {
  components: {
    DefaultLoader
  },
  props: {
    name: {
      type: String,
      required: true
    },
    mask: {
      type: Boolean,
      default: false
    },
    showBorder: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapGetters({
      componentViewSize: 'omniview/componentViewSize',
      isPopulatingComponentFrame: 'webComponents/isPopulatingComponentFrame',
      currentWebComponent: 'webComponents/currentWebComponent',
      isGeneratingCapture: 'omniview/isGeneratingCapture'
    }),
    isLoading() {
      return this.isPopulatingComponentFrame[this.name];
    },
    getMaskSize() {
      const { width, height } = this.componentViewSize[this.name];
      return {
        width: width + this.padding + 'px',
        height: height + this.padding + 'px'
      };
    },
    currentWebComponentName() {
      return get(this.currentWebComponent, 'master.component_name', '');
    },
    getComponentFrameStyles() {
      const { width, height } = this.componentViewSize[this.name];
      return {
        width: width + this.padding + 'px',
        height: height + this.padding + 'px',
        maxHeight: '100%',
        maxWeight: '100%',
        zIndex: 10
      };
    }
  },
  methods: {
    ...mapMutations({
      setCurrentWebComponentNode: 'webComponents/setCurrentWebComponentNode',
      setIsGeneratingCapture: 'omniview/setIsGeneratingCapture',
      setBase64Screenshot: 'omniview/setBase64Screenshot'
    }),

    resetNodeSelection() {
      this.setCurrentWebComponentNode({});
      this.sendMessage({
        iframeName: 'componentIframe',
        action: CLEAR_CODE_SELECTION
      });
    },

    sendMessage(message) {
      let { iframeName, ...msg } = message;

      if (iframeName == 'all') {
        iframeName = this.name;
      }

      if (iframeName != this.name) return;

      const iframe = document.getElementById(iframeName);
      if (iframe) {
        iframe.contentWindow.postMessage({ ...msg, version: 'v2' }, '*');
      }
    },
    messageListener(e) {
      if (!e.data.action) return;
      if (e.origin.includes('animaapp.io')) return;

      const data = e.data.data;

      let iframeName = '';

      if (e.source.frameElement) {
        iframeName = e.source.frameElement.getAttribute('id');
      }

      if (this.name != iframeName) return;

      switch (e.data.action) {
        case 'dom-load':
          this.sendMessage({
            action: ACTIVATE_OVERRIDES,
            iframeName: 'componentIframeInterface'
          });
          this.sendMessage({
            action: ACTIVATE_OVERRIDES,
            iframeName: 'componentIframe'
          });
          EventBus.$emit('update-anima-scripts-modelNodes', { iframe: 'componentIframe' });

          if (this.name == 'componentIframe') {
            if (this.isGeneratingCapture[this.$route.query.layer]) {
              EventBus.$emit('update-component-preview', { iframeName: 'componentIframe' });
            }
          }

          break;
        case 'edit-node':
          // if (this.name == 'componentIframeInterface') {
          this.setCurrentWebComponentNode(data);
          EventBus.$emit('generate-code', { _nodeId: data.id });
          // }
          break;

        case 'on-preview-end':
          if (data.error) {
            if (data.error.message == 'html2canvas is not defined') {
              setTimeout(() => {
                EventBus.$emit('update-component-preview', { iframeName: 'componentIframe' });
              }, 300);
            } else {
              this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
            }
          } else {
            if (data.isLocal) {
              this.setBase64Screenshot(data.base64);
              this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
            }
          }
          break;

        default:
          break;
      }
    }
  },
  mounted() {
    EventBus.$on(SEND_COMPONENT_MESSAGE, this.sendMessage);
    window.addEventListener('message', this.messageListener, false);
    document.addEventListener('click', this.resetNodeSelection);
  },
  destroyed() {
    EventBus.$off(SEND_COMPONENT_MESSAGE, this.sendMessage);
    window.removeEventListener('message', this.messageListener, false);
    document.removeEventListener('click', this.resetNodeSelection);
  }
};
</script>

<style lang="scss" scoped>
.mask {
  background-image: url('./../../assets/svg/image-mask.svg');
  max-height: 100%;
  max-width: 100%;
  z-index: 0;
  position: absolute;
}
.name-border {
  z-index: 0;
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0 0 0 1px #ff6250;
  .name {
    position: relative;
    height: 20px;
    top: -22px;
    left: -1px;
    background: #ff6250;
    color: white;
    padding: 0 8px;
    font-size: 14px;
  }
}
</style>

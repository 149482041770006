<template>
  <div
    :style="{ width: isSidebarVisible ? (isSidebarMinimized ? '40px' : 'var(--omniview-sidebar-width)') : 0 }"
    class="sidebar"
  >
    <div
      v-if="activeMode.name == 'C'"
      @click="handleToggleSidebar"
      :class="{ open: !isSidebarMinimized }"
      class="sidebar-toggle"
    >
      <svg-icon
        class="icon"
        :name="!isSidebarMinimized ? 'arrow-right' : 'arrow-left'"
        :size="25"
        fill="currentColor"
      ></svg-icon>
    </div>

    <div class="content">
      <transition name="fadeIn">
        <component v-if="showContent" v-show="!isSidebarMinimized" :is="currentView"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import SidebarHeader from '@/components/OmniView/Sidebar/SidebarHeader.vue';
import AssetsView from '@/components/OmniView/Assets/AssetsView.vue';
import commentsView from '@/components/OmniView/Comments/commentsView.vue';
import CodeView from '@/components/OmniView/Code/CodeView.vue';
export default {
  components: {
    SidebarHeader,
    AssetsView,
    commentsView,
    CodeView
  },
  data() {
    return {
      showContent: false
    };
  },
  watch: {
    isSidebarVisible: {
      // delay showing content only after the animation is finished
      handler(to) {
        const timeout = this.activeMode.name == 'C' ? 400 : 250;
        if (to) {
          setTimeout(() => {
            this.showContent = true;
          }, timeout);
        } else {
          this.showContent = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      activeMode: 'omniview/activeMode',
      isSidebarMinimized: 'omniview/isSidebarMinimized'
    }),
    isSidebarVisible() {
      return this.activeMode.name != 'In' && !this.isCompareEnabled;
    },
    currentView() {
      let view = 'comments-view';
      switch (this.activeMode.name) {
        case 'C':
          view = 'code-view';
          break;
        case 'Co':
          view = 'comments-view';
          break;

        default:
          break;
      }
      return view;
    }
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized'
    }),
    handleToggleSidebar() {
      this.setIsSidebarMinimized(!this.isSidebarMinimized);
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  flex-shrink: 0;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0 1px 0 #2d2d2d;
  will-change: width;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateZ(0);

  &:hover {
    .open {
      visibility: visible;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3b3b3b;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #2d2d2d;
}
.sidebar-toggle {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: 40px;
  background: #333333;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  color: white;

  &.open {
    visibility: hidden;
    .icon {
      left: 0;
    }
  }
  .icon {
    position: relative;
    left: -2px;
  }
}
.resizer {
  position: absolute;
  height: 100%;
  width: 14px;
  background: transparent;
  left: -10px;
  top: 0;
  z-index: 100;
  &:hover {
    div {
      background: #1a1a1a !important;
    }
  }

  div {
    height: 100%;
    width: 4px;
    margin-left: auto;
    background: transparent;
  }
}
</style>

import { codegenCache } from '@/services/idb/codegenCache';
import { trackEvent } from '@/services/tracking';
import handlebars from 'handlebars';
import prettierHTML from 'prettier/parser-html';
import prettierPostcss from 'prettier/parser-postcss';
import prettierBabel from 'prettier/parser-babel';
import prettier from 'prettier/standalone';
import { get } from 'lodash-es';
import md5 from 'object-hash';
import { prettifyAssetFilename } from '@/utils/prettify';
import auth from '@/auth';
import router from '@/router';
import { pollWrapper } from '@/utils/javascript';
import store from '@/store';
import { sortedCSSRules } from './utils';
import errorHandler from '@/services/errorHandler';
import { createHash } from 'crypto';

handlebars.registerHelper('get_tag', function (viewId, defaultTag) {
  return this[viewId] ? this[viewId] : defaultTag;
});
handlebars.registerHelper('get_token', function (tokenId, defaultValue) {
  return this[tokenId] ? this[tokenId].token : defaultValue;
});
handlebars.registerHelper('get_styleguide_class', function (styleguideClassId, defaultValue) {
  return this[styleguideClassId] ? this[styleguideClassId].name : defaultValue;
});

class CodegenCleanCode {
  constructor() {
    this.API_BASE_URL = localStorage.getItem('apiBaseURL') || process.env.API_BASE_URL || 'https://api.animaapp.com';
    this.activeScreenSlug = undefined;
    this.viewMd5Map = [];
    this.assetsRegistry = {};
    this.loadingMap = {
      overrides: false,
      schema: false
    };
    this.loadingPromises = {
      schema: undefined,
      overrides: undefined
    };

    this.generateController = new AbortController();
    this.generateController.signal.onabort = () => {
      console.warn('Aborted fetching schema');
    };

    let schemaPromiseResolve, overridesPromiseResolve;

    this.loadingMapProxy = new Proxy(this.loadingMap, {
      set: (obj, prop, value) => {
        if (prop === 'schema') {
          value
            ? (this.loadingPromises.schema = new Promise((resolve) => {
                schemaPromiseResolve = resolve;
              }))
            : schemaPromiseResolve();
        }
        if (prop === 'overrides') {
          value
            ? (this.loadingPromises.overrides = new Promise((resolve) => {
                overridesPromiseResolve = resolve;
              }))
            : overridesPromiseResolve();
        }
        obj[prop] = value;
        return true;
      }
    });
  }

  setAssetsRegistry(registry) {
    this.assetsRegistry = registry;
  }

  setLayoutMd5(layoutSettings) {
    const layout_string = JSON.stringify(layoutSettings, Object.keys(layoutSettings).sort());
    let md5sum = createHash('md5');
    md5sum.update(new Buffer(layout_string, 'utf8'));
    let md5val = md5sum.digest('hex');
    this.layoutMd5 = md5val;
  }
  getNodeCacheKey({ nodeId = '', screenSlug, language, mode, type } = {}) {
    const layout = this.layoutMd5;
    return md5({ nodeId, screenSlug, language, mode, type, layout });
  }
  getHeaders() {
    const token = auth.getToken();

    return {
      'X-Client-Id': 'com.animaapp.web',
      Authorization: `JWT ${token}`
    };
  }

  async addToCache(cacheKey, data, { parse = false } = {}) {
    return codegenCache.setByKey(cacheKey, data, { parse });
  }
  async removeFromCache(cacheKey) {
    return codegenCache.removeByKey(cacheKey);
  }
  async getFromCache(cacheKey, { parse = false } = {}) {
    return codegenCache.getByKey(cacheKey, { parse });
  }

  async getProjectSchema({ projectId = '', withCache = true } = {}) {
    if (!projectId) return;
    try {
      const cacheKey = `${projectId}-schema`;
      if (withCache) {
        const cachedSchema = await codegenCache.getByKey(cacheKey);
        if (cachedSchema) return cachedSchema;
      }

      await this.pollProjectSchema(projectId);
    } catch (error) {
      console.log(error);
    }
  }

  async fetchProjectSchema(projectId) {
    const URL = `${this.API_BASE_URL}/v2/projects/${projectId}/component_html_templates?get_all=true`;
    const res = await fetch(URL, {
      method: 'GET',
      headers: this.getHeaders()
    });

    if (res && res.status != 200) {
      throw new Error(`Error fetching templates for project : ${projectId}`);
    }
    return res.json();
  }

  pollTemplateForScreen(projectId, screenSlug, { interval = 3000 } = {}) {
    return new Promise((resolve) => {
      pollWrapper({
        pollingPeriod: interval,
        request: () => this.fetchProjectSchema(projectId),
        shouldStop: ({ results = [] }) => {
          if (!this.activeScreenSlug || this.activeScreenSlug !== screenSlug) return true;

          const screen = results.find((e) => e.slug === screenSlug);
          if (screen && screen?.templates[this.layoutMd5]) {
            resolve(screen?.templates[this.layoutMd5]);
            return true;
          }
          return false;
        }
      });
    });
  }

  async triggerSchemaGeneration(projectId) {
    if (!projectId) return;
    try {
      const URL = `${this.API_BASE_URL}/project/${projectId}/trigger_code_templates`;

      return fetch(URL, {
        method: 'POST',
        headers: this.getHeaders()
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getScreenSchema(screenSlug, { withCache = true, projectId = '' } = {}) {
    try {
      if (!projectId) {
        projectId = router.currentRoute.params.projectId;
      }

      const { screenSlug } = router.currentRoute.params;

      if (this.loadingMapProxy.schema) {
        if (this.activeScreenSlug && this.activeScreenSlug === screenSlug) {
          await this.loadingPromises.schema;
        } else {
          // cancel request
        }
      }

      this.loadingMapProxy.schema = true;

      this.activeScreenSlug = screenSlug;
      const cacheKey = `${screenSlug}-${this.layoutMd5}-schema`;
      if (withCache) {
        const cachedSchema = await codegenCache.getByKey(cacheKey);
        if (cachedSchema) return cachedSchema;
      }

      const response = await this.fetchProjectSchema(projectId);

      const entry = get(response, 'results', []).find((e) => e.slug === screenSlug);
      let schemaURL = entry?.templates[this.layoutMd5];
      if (!schemaURL) {
        trackEvent('omniview.tigger-schema-generation', { projectId, screenSlug });
        await this.triggerSchemaGeneration(projectId);
        schemaURL = await this.pollTemplateForScreen(projectId, screenSlug);
      }

      if (!schemaURL) {
        throw new Error('schema object returned with no template URL');
      }

      const res = await fetch(schemaURL);
      const schema = await res.json();
      await codegenCache.setByKey(cacheKey, schema);
      return schema;
    } catch (error) {
      console.log(error);
    } finally {
      this.activeScreenSlug = undefined;
      this.loadingMapProxy.schema = false;
    }
  }

  removeComponentChildrenFromSelectableLayer(componentId, schema, map) {
    const { subviews = [] } = get(schema, `views.${componentId}`, {});
    for (let i = 0; i < subviews.length; i++) {
      map[subviews[i]] = false;
      this.removeComponentChildrenFromSelectableLayer(subviews[i], schema, map);
    }
  }

  async getSelectableLayers(screenSlug, screenViewId = null) {
    const schema = await this.getScreenSchema(screenSlug);
    let componentsIdsArray = store.getters['webComponents/componentsIdsArray'];
    const views = get(schema, 'views', {});
    const map = {};
    Object.keys(views).forEach((key) => {
      map[key] = true;
    });
    componentsIdsArray = componentsIdsArray.filter((componentId) => componentId !== screenViewId);
    componentsIdsArray.forEach((componentId) => {
      this.removeComponentChildrenFromSelectableLayer(componentId, schema, map);
    });
    return map;
  }

  async getScreenOverrides() {
    const { tokens = {}, classes = {} } = store.getters['styleguide/currentStyleguide'];
    const componentData = store.getters['componentsMetadata/currentComponentMetadata'];
    let tags = {};
    for (const [nodeId, overrides] of Object.entries(componentData.overrides)) {
      tags = { ...tags, [nodeId]: overrides.tagName };
    }
    return { tokens, classes, tags };
  }

  async getScreenTemplateData(screenSlug) {
    const overrides = await this.getScreenOverrides(screenSlug);
    return this.screenOverridesToTemplateData(overrides);
  }

  async getScreenHTML(screenSlug) {
    // const cacheKey = `${screenSlug}-HTML`;

    // const cachedScreenHTML = await codegenCache.getByKey(cacheKey);
    // if (cachedScreenHTML) return cachedScreenHTML;

    const [schema, templateData] = await Promise.all([
      this.getScreenSchema(screenSlug),
      this.getScreenTemplateData(screenSlug)
    ]);

    const schemaHTML = get(schema, 'html', '');
    const HTMLTemplate = handlebars.compile(schemaHTML);
    const HTML = HTMLTemplate(templateData);

    // await codegenCache.setByKey(cacheKey, HTML);
    return HTML;
  }
  async getScreenCSSRules(screenSlug) {
    const cacheKey = `${screenSlug}-${this.layoutMd5}-CSS`;

    const cachedScreenCSSRules = await codegenCache.getByKey(cacheKey);
    if (cachedScreenCSSRules) return cachedScreenCSSRules;

    const [schema, templateData] = await Promise.all([
      this.getScreenSchema(screenSlug),
      this.getScreenTemplateData(screenSlug)
    ]);
    const CSSRules = get(schema, 'css', '');

    let parsedCSSRules = {};

    for (const key in CSSRules) {
      const CSSTemplate = handlebars.compile(CSSRules[key]);
      parsedCSSRules[key] = CSSTemplate(templateData);
    }

    await codegenCache.setByKey(cacheKey, parsedCSSRules);
    return parsedCSSRules;
  }

  screenOverridesToTemplateData(overrides = {}) {
    let templateData = {};
    const { tokens = {}, classes = {}, tags = {} } = overrides;

    templateData = { ...templateData, ...classes, ...tags, ...tokens };

    return templateData;
  }

  replaceUrlsWithFilenames(str, md5Map) {
    Object.values(md5Map).forEach((obj) => {
      const filename = prettifyAssetFilename(obj.filename);
      str = str.replaceAll(obj.url, filename);
    });
    return str;
  }

  async getNodeHTML({ nodeId = '', screenSlug = '', replaceImgUrl = true, prettify = true } = {}) {
    const HTML = await this.getScreenHTML(screenSlug);
    const DOM = new DOMParser().parseFromString(HTML, 'text/html');

    const node = DOM.querySelector(`[data-id="${nodeId}"]`);

    if (!node) {
      throw new Error('node not found');
    }

    let nodeHTML = '';

    if (replaceImgUrl) {
      const md5Map = await this.getNodeMd5Map({ nodeId, screenSlug });
      nodeHTML = this.replaceUrlsWithFilenames(node?.outerHTML, md5Map);
    } else {
      nodeHTML = node?.outerHTML;
    }

    return prettify ? this.prettify(nodeHTML, 'html') : nodeHTML;
  }
  async getNodeCSS({
    nodeId = '',
    screenSlug = '',
    ignoreStyleguideClasses = false,
    prettify = true,
    includeRoot = false
  } = {}) {
    const [schema, CSSKeyToRule] = await Promise.all([
      this.getScreenSchema(screenSlug),
      this.getScreenCSSRules(screenSlug)
    ]);
    const views = get(schema, `views`, {});
    const { css_keys: nodeCSSKeys } = get(schema, `views.${nodeId}`, {
      css_keys: [],
      token_keys: [],
      subviews: []
    });
    let CSSKeys = [...nodeCSSKeys];

    const getCSSKeys = (nodeId) => {
      const { subviews = [] } = get(schema, `views.${nodeId}`, {});

      for (let i = 0; i < subviews.length; i++) {
        const subId = subviews[i];
        const cssKeys = get(views, `${subId}.css_keys`, []);
        CSSKeys.push(...cssKeys);
        getCSSKeys(subId);
      }
    };

    getCSSKeys(nodeId);

    if (includeRoot) {
      CSSKeys.push('root');
    }

    CSSKeys = [...new Set(CSSKeys)];

    let renderedCSSRules = [];

    for (let i = 0; i < CSSKeys.length; i++) {
      const key = CSSKeys[i];
      if (ignoreStyleguideClasses && key.startsWith('class_')) continue;

      const rule = CSSKeyToRule[key];
      if (!rule) {
        console.error(`cleanCode:getNodeCSS:: key ${key} has no rule`);
        errorHandler.captureMessage('cleanCode.js:getNodeCSS: missing rule');
        continue;
      }
      renderedCSSRules.push(`${rule}`);
    }

    let nodeCSS = sortedCSSRules(renderedCSSRules).join('\n\n');

    return prettify ? this.prettify(nodeCSS, 'css') : nodeCSS;
  }

  prettify(text, language = 'html', options = {}) {
    try {
      if (language === 'sass') return text.trim();
      let parser = language;
      let plugins = [];
      if (['html', 'markup'].includes(language)) {
        parser = 'html';
        plugins = [prettierHTML, prettierBabel];
      }
      if (language === 'jsx') {
        parser = 'babel';
        plugins = [prettierBabel];
      }
      if (['css', 'sass', 'scss'].includes(language)) {
        parser = 'css';
        plugins = [prettierPostcss];
      }
      const prettifiedText = prettier.format(text, {
        parser,
        plugins,
        ...options
      });
      return prettifiedText;
    } catch (error) {
      return text;
    }
  }

  cleanAndPrettifyHTML(str, { removeDataId = true, prettify = true } = {}) {
    removeDataId && (str = str.replaceAll(/data-id="(.+?)"/g, ''));
    return prettify ? this.prettify(str, 'html') : str;
  }

  async getCodepenCode({ nodeId = '', screenSlug = '' } = {}) {
    const [HTML, CSS] = await Promise.all([
      this.getNodeHTML({ nodeId, screenSlug, replaceImgUrl: false, prettify: false }),
      this.getNodeCSS({ nodeId, screenSlug, prettify: false, includeRoot: true })
    ]);

    const finalCSSCode = CSS;

    const prettifiedCSS = this.prettify(finalCSSCode.replace(':root {   }', ''), 'css');
    const prettifiedHTML = this.cleanAndPrettifyHTML(HTML, { removeDataId: true, prettify: true });

    return {
      HTML: prettifiedHTML,
      CSS: prettifiedCSS
    };
  }

  async getNodeMd5Map({ screenSlug, nodeId } = {}) {
    const [schema] = await Promise.all([this.getScreenSchema(screenSlug)]);

    const views = get(schema, 'views', {});
    const addedLayersIds = [];
    const md5s = [];

    const node = views[nodeId];

    if (node['image_info']) {
      md5s.push(node['image_info'].md5);
    }

    const getMd5 = (_node) => {
      let i, currentChild, result;
      for (i = 0; i < _node.subviews.length; i++) {
        const id = _node.subviews[i];
        currentChild = views[id];

        if (currentChild['image_info'] && addedLayersIds.indexOf(id) == -1) {
          addedLayersIds.push(id);
          md5s.push(currentChild['image_info'].md5);
        }
        result = getMd5(currentChild);
        if (result !== false) {
          return result;
        }
      }
      return false;
    };

    getMd5(node);

    let md5Map = {};
    md5s.forEach((md5) => {
      md5Map[md5] = this.assetsRegistry[md5] ? this.assetsRegistry[md5] : {};
    });
    return md5Map;
  }

  async getNodeName({ nodeId = '', screenSlug = '' } = {}) {
    const [schema] = await Promise.all([this.getScreenSchema(screenSlug)]);
    const views = get(schema, 'views', {});
    return get(views, `${nodeId}.name`);
  }

  async populateCodepenIframe({ screenSlug, nodeId, modelNode, setIframeSize = () => {} }) {
    const getGeneratedPageURL = ({ html, css }) => {
      const getBlobURL = (code, type) => {
        const blob = new Blob([code], { type });
        return URL.createObjectURL(blob);
      };

      const { width: componentWidth = 1, height: componentHeight = 1 } = modelNode || {};
      const [iframeWidth, iframeHeight] = [340, 450];
      const padding = componentWidth * 0.1;
      const widthRatio = iframeWidth / (componentWidth + padding);
      const heightRatio = iframeHeight / (componentHeight + 20);

      const zoom = Math.min(widthRatio, heightRatio, 1);
      const customCSS = `
        body{
          overflow:hidden;
          pointer-events:none;
          zoom:${zoom};
          -moz-transform: scale(${zoom});
        }
      `;

      setIframeSize({
        iframeName: 'previewIframe',
        data: { width: componentWidth * zoom, height: componentHeight * zoom }
      });

      const cssURL = getBlobURL(css, 'text/css');
      const customCssURL = getBlobURL(customCSS, 'text/css');

      const source = `
        <html>
          <head>
            ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
            ${customCssURL && `<link rel="stylesheet" type="text/css" href="${customCssURL}" />`}
          </head>
          <body>
            ${html || ''}
          </body>
        </html>
      `;

      return getBlobURL(source, 'text/html');
    };

    const { CSS, HTML } = await this.getCodepenCode({ screenSlug, nodeId });

    const url = getGeneratedPageURL({
      html: HTML,
      css: CSS
    });

    const iframe = document.querySelector('#previewIframe');
    iframe && (iframe.src = url);
  }
}

export const codegenCleanCode = new CodegenCleanCode();

export default CodegenCleanCode;

<template>
  <div class="popover-container">
    <an-button class="export-button" :isWorking="isGeneratingPlaygroundCode" variant="secondary">
      <div @click="onAction" class="cta" data-cy="export-component-btn">{{ getCTA() }}</div>
      <div @click="toggleOpen" class="toggle-icon" data-cy="open-export-options">
        <svg-icon fill="currentColor" class="icon" :size="10" name="select-arrow-down-white" />
      </div>
    </an-button>
    <Popover closeOnOutsideClick :isOpen="isOpen" @close="close" :style="popoverStyles" data-cy="export-options">
      <div
        class="export-option"
        :class="{ selected: exportOption == 'zip' }"
        data-cy="zip-option"
        @click="selectExportOption('zip')"
      >
        Export component as ZIP
      </div>
      <div
        class="export-option"
        :class="{ selected: exportOption == 'codepen' }"
        data-cy="sandbox-option"
        @click="selectExportOption('codepen')"
      >
        Open in Codepen
      </div>
      <SelectedFramework />
    </Popover>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/services/bus';
import Popover from '@/components/Popovers/Popover';
import SelectedFramework from '../SelectedFramework.vue';

export default {
  components: {
    Popover,
    SelectedFramework
  },
  data() {
    return {
      isOpen: false,
      exportOption: 'zip',
      popoverStyles: {
        width: '220px',
        padding: '8px 0',
        background: 'var(--secondary)',
        color: 'var(--dark-primary-text)',
        boxShadow: '0px 4px 12px rgba(20, 20, 20, 0.5)',
        top: '100%'
      },
      projectName: ''
    };
  },
  methods: {
    getCTA() {
      if (this.exportOption === 'zip') {
        return 'Export component as ZIP';
      } else {
        return 'Open in Codepen';
      }
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    selectExportOption(option) {
      localStorage.setItem(this.projectName + 'export_option', option);
      this.exportOption = option;
      this.close();
    },
    onAction() {
      const { layer: node } = this.$route.query;

      switch (this.exportOption) {
        case 'zip':
          EventBus.$emit('export-code', { type: 'selection', layer: node });
          break;

        case 'codepen':
          EventBus.$emit('OPEN_IN_CODEPEN', { node });
          break;

        default:
          break;
      }
    }
  },
  computed: {
    ...mapGetters({
      codegenLang: 'codePreferences/codegenLang',
      isGeneratingPlaygroundCode: 'omniview/isGeneratingPlaygroundCode'
    }),
    ...mapState('projects', { currentProject: 'currentItem' })
  },
  mounted() {
    this.projectName = this.currentProject.name;
    const storedOption = localStorage.getItem(this.projectName + 'export_option');
    if (storedOption) {
      this.exportOption = storedOption;
    }
  }
};
</script>

<style lang="scss" scoped>
.popover-container {
  margin: 16px 8px;
  width: calc(100% - 16px);
  position: relative;
}
.export-button {
  width: 100%;
  border-radius: 100px;
  position: relative;
}
.toggle-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 1px solid currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  margin-top: 7px;
}
.cta {
  position: absolute;
  width: 100%;
  height: 34px;
  line-height: 34px;
  left: 0;
  top: 0;
  padding-right: 41px;
  border-radius: 100px;
}
.export-option {
  font-size: 12px;
  line-height: 20px;
  font-family: var(--base-font);
  padding: 8px 16px;
  cursor: pointer;
}
.export-option.selected {
  color: var(--primary);
}
.export-option:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>

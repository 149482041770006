<template>
  <transition name="fade">
    <div v-if="show && events.length > 0" class="debug-events-container">
      <div class="debug-events">
        <transition-group name="slide-in-left" tag="ol">
          <li v-for="event in events" :key="event.id" @click="copyEventName(event)">
            <code>
              <span class="time">{{ event.time }}</span>
              <span>{{ event.eventName }}</span>
              <br />
              <span class="props" v-html="event.propsHtml" />
            </code>
          </li>
        </transition-group>
      </div>
      <div class="footer">
        <div>
          <span style="font-weight: 700"> Events log </span>
          <span style="font-size: 12px">click an event to copy its name</span>
        </div>
        <an-link @click="removeAllEvents" variant="primary">clear</an-link>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';
import { uuid } from '@/utils/uuid';
import { mapState } from 'vuex';
import { formatDate } from '@/utils/date';
import copy from '@/utils/copyToClp';

export default {
  data() {
    return {
      hover: false,
      shouldShow: false,
      events: []
    };
  },
  mounted() {
    this.shouldShow = localStorage.getItem('debug-events');
    EventBus.$on('toggle-debug-events', this.toggleEvents);
    EventBus.$on('add-debug-event', this.addEvent);
  },
  destroyed() {
    EventBus.$off('toggle-debug-events', this.toggleEvents);
    EventBus.$off('add-debug-event', this.addEvent);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    show() {
      return this.user.is_admin && this.shouldShow;
    }
  },
  methods: {
    toggleEvents(value) {
      this.shouldShow = !!value;
    },
    mapProps(props) {
      const keys = Object.keys(props);
      const joined = keys.map((key) => `<b>${key}</b>: ${props[key]}`).join(', ');
      return `{ ${joined} }`;
    },
    addEvent({ eventName, params: props = {} }) {
      if (!this.show) return;
      const id = uuid();
      const time = formatDate(new Date(), 'hh:mm:ss');
      this.events = [{ id, eventName, propsHtml: this.mapProps(props), time }, ...this.events];
    },
    removeAllEvents() {
      this.events = [];
    },
    copyEventName({ eventName }) {
      copy(eventName);
    }
  }
};
</script>

<style lang="scss" scoped>
.debug-events-container {
  position: fixed;
  left: 10px;
  bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--shadow);
  z-index: 10000;
}
.debug-events {
  max-height: 100px;
  width: 500px;
  padding: 10px;
  background-color: #ffffff;
  color: var(--primary-text);
  overflow: scroll;
  transition: all 0.2s ease;
  &:hover {
    max-height: 500px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  padding: 10px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * + * {
    margin-left: 10px;
  }
}
code {
  font-size: 14px;
}
.time,
.props {
  font-size: 10px;
}
span + span {
  margin-left: 10px;
}
li {
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 5px 0;
  &:hover {
    background-color: var(--light-container-background);
  }
  &:not(:hover) {
    @include ellipsis;
  }
  &:last-child {
    margin-bottom: 30px;
  }
}
</style>

<template>
  <div class="project-empty-state">
    <div v-if="type === 'component'" class="components-empty-state">
      <div><img src="@/assets/illustrations/no-components.svg" /></div>
      <div class="title component-title">No components yet</div>
      <div>
        Components allow Anima to reuse React code across the project and make the code more consistent and clean.
        <br /><br />
        In code mode, select a component &amp; add it to the components library.
      </div>
      <!-- <div class="component-cta">
        <an-link variant="primary">Show me how to add components</an-link>
      </div> -->
    </div>
    <div v-else class="sync-with-quick-import">
      <div class="frame">
        <div class="section">
          <img class="above-section" src="@/assets/illustrations/design-tools-sync.svg" />
          <h2>Sync your design</h2>
          <p>Use the Anima plugin to sync your design to your project.</p>
          <an-button data-cy="install-plugin-button" @click="openPluginsModal">Install plugin</an-button>
        </div>
        <div class="divider"></div>
        <div class="section">
          <img class="above-section" src="@/assets/illustrations/sketch-figma-to-frontier.svg" />

          <h2>Quick import</h2>
          <p>
            Convert Figma designs to code in VSCode<br />
            using your components and code conventions.
          </p>
          <an-button data-cy="import-plugin-button" variant="secondary" @click="onClickInstallExtension"
            >Install extension</an-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from '@/services/bus';

export default {
  props: {
    type: {
      type: String,
      default: 'screen',
      validator: (v) => ['screen', 'component'].includes(v)
    }
  },
  methods: {
    openPluginsModal() {
      // this.$router.push({ name: 'plugins' });

      openModal({
        name: 'plugins',
        mode: 'dark'
      });
    },
    onClickInstallExtension() {
      this.$trackEvent('project-page.install-frontier.click', { platform: 'all' });
      window.open(
        'https://www.animaapp.com/an?url=vscode:extension/animaapp.vscode-anima&fallbackUrl=https://animaapp.com/frontier&mobileUrl=https://animaapp.com/frontier&utm_campaign=frontier&utm_medium=webapp&utm_source=webapp&utm_content=quick-import'
      );
    }
  }
};
</script>

<style lang="scss">
.select-import-menu {
  width: 200px !important;
}
</style>

<style lang="scss" scoped>
.sync-with-quick-import {
  width: 100%;
  .frame {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    min-height: 340px;
    justify-content: space-evenly;
    .divider {
      width: 1px;
      margin: 3% 0;
      background-color: #d8d8d8;
      align-self: stretch;
    }

    .section {
      margin-top: 190px;
      p {
        width: 360px;
        margin-bottom: 40px;
      }
      position: relative;
      .above-section {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
      h2 {
        @include headline;
        font-size: 28px;
      }
      > * {
        margin-bottom: 24px;
      }
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
      .divider {
        height: 1px;
        width: unset;
        margin: 0 3%;
      }
      .section {
        margin-top: 130px;
      }
    }
  }
}

.project-empty-state {
  display: flex;
  justify-content: center;
  text-align: center;
}
.components-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
}
.component-title,
.component-cta {
  margin: 40px 0 18px;
}
.title {
  @include headline;
  margin-bottom: 30px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  > * + * {
    margin-left: 30px;
  }
}
</style>

<template>
  <div class="login-page-container">
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="login-container">
      <LoadingScreen v-if="googleLoading" />
      <div class="form-container" v-else>
        <div class="title">Log in to Anima</div>
        <div class="subtitle">
          Don't have an account yet?
          <an-link @click="$router.push({ name: 'signup', query: $route.query })" variant="primary" data-cy="signup">
            Sign up for free
          </an-link>
        </div>
        <div class="social-logins">
          <div v-if="showGoogleLogin">
            <GoogleButton @success="onGoogleLoginSuccess" @failure="onGoogleLoginFailure" />
          </div>
          <div>
            <button class="github-button" @click="handleGitHubLogin">
              <img :src="require('@/assets/svg/github.svg')" width="20" height="20" />
              <span>Sign in with GitHub</span>
            </button>
          </div>
        </div>
        <div class="text-divider" v-if="showGoogleLogin">
          <Divider text="Or" :theme="theme" />
        </div>
        <form v-on:submit.prevent="login">
          <div class="property">
            <label class="input">
              <span class="sr-only">Email</span>
              <an-input
                id="email"
                type="email"
                v-model="email"
                :theme="theme"
                placeholder="name@work-email.com"
                data-cy="login-email"
                autocomplete="email"
              />
            </label>
          </div>
          <div class="property">
            <label>
              <span class="sr-only">Password</span>
              <an-input
                id="pass"
                type="password"
                v-model="password"
                :theme="theme"
                placeholder="Password"
                data-cy="login-password"
                autocomplete="current-password"
              />
            </label>
          </div>
          <div class="login-footer">
            <div class="item">
              <an-link @click="loginWithSSO" :theme="theme" primaryHover data-cy="login-sso">Log in with SSO</an-link>
            </div>
            <span style="opacity: 0.4">|</span>
            <div class="item">
              <an-link @click="forgotPassword" :theme="theme" primaryHover>Forgot password?</an-link>
            </div>
          </div>
          <div class="invalid-message" v-if="errorMessage">
            <svg-icon name="info" fill="var(--red)" />
            <span>{{ errorMessage }}</span>
          </div>
          <div class="actions">
            <an-button class="justify-center" type="submit" :isWorking="loading" size="xl" data-cy="login-submit">
              Log in
            </an-button>
          </div>
        </form>
      </div>
      <div class="login-illustration" v-if="!googleLoading" />
    </div>
    <div />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import GoogleButton from '@/components/Button/GoogleButton';
import Divider from '@/components/Divider/Divider';
import { openLink } from '@/components/OmniView/utils';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { SignupMixin } from '@/mixins';

import auth from '@/auth';
import { toastError } from '@/services/bus';
import errorHandler from '@/services/errorHandler';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      theme: 'dark',
      loading: false,
      googleLoading: false,
      showGoogleLogin: !process.env.ORGANIZATION_NAME
    };
  },
  mixins: [SignupMixin],
  components: {
    GoogleButton,
    Divider,
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.$trackEvent('webapp.login');
    this.applyTheme(this.theme, 'onboarding');
    if (window && window.top && window.top.postMessage) {
      //if it's chrome extension we will send a message so we can send the user to login
      window.top.postMessage('needs-to-login', '*');
    }
  },
  beforeDestroy() {
    this.removeTheme();
  },
  computed: {
    ...mapGetters({ firstName: 'users/firstName' }),
    ...mapState('devices', { currentDevice: 'currentItem' })
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      createTeam: 'teams/create',
      updateUser: 'users/update'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    async handleGitHubLogin() {
      const { query } = this.$route;
      const { platform = 'web' } = query;

      const state = await auth.signGithubMessage({
        platform,
        ...(query.redirect ? { redirect: query.redirect } : {}),
        ...(query.pt ? { pt: query.pt } : {})
      });

      const searchParams = new URLSearchParams({
        client_id: process.env.GITHUB_CLIENT_ID,
        state,
        scope: ['repo', 'user'].join(' ')
      });

      const url = new URL(`https://github.com/login/oauth/authorize?${searchParams.toString()}`);

      openLink({ link: url.href });
    },
    async onGoogleLoginSuccess(googleUser) {
      try {
        this.googleLoading = true;
        await this.onGoogleSuccess(googleUser);
      } catch (error) {
        this.googleLoading = false;
        errorHandler.captureException(error);
        toastError("We couldn't log you in with your Google account :(");
      }
    },
    async onGoogleLoginFailure(err) {
      errorHandler.captureExceptionAndTrack(err, { name: 'login.google-login.failure', cta: 'google-button' });
      toastError("We couldn't log you in with your Google account :(");
    },
    forgotPassword() {
      this.$router.replace({ name: 'forgot-password', query: this.$route.query });
    },
    loginWithSSO() {
      this.$trackEvent('login.sso-button.click');
      this.$router.push({ name: 'sso-login', query: this.$route.query });
    },
    async login() {
      try {
        this.$trackEvent('login.login-button.click', {
          cta: 'anima'
        });

        this.errorMessage = '';
        this.loading = true;

        const { access_token: token } = await auth.login(this.email, this.password);
        localStorage.setItem('token', token);

        const user = await this.getUser({ id: 'me', skipCache: true });

        this.$tracking.setUserEmail(user.email);
        this.$tracking.setUserId(user.id);
        this.$tracking.alias(user.id);

        this.$trackEvent('login.login.success', { cta: 'anima', device_id: this.currentDevice.device_id });

        await this.redirectAfterAuth(true);
      } catch (error) {
        if (error?.response?.status == 401) {
          this.errorMessage = 'Incorrect email, password or both.';
        } else if (error?.response?.status == 403) {
          this.errorMessage = error?.response?.data?.message || 'We failed to log you in! Please try again.';
        } else {
          this.errorMessage = 'We failed to log you in! Please try again.';

          errorHandler.captureExceptionAndTrack(error, {
            name: 'login.login.failure',
            data: { cta: 'anima', message: error?.response?.data?.message || error?.message, statusCode: error?.status }
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$topBarHeight: 80px;

.login-page-container {
  ::v-deep .top-bar {
    position: unset;
  }
}

.form-container {
  width: 343px;
  text-align: center;
  @include mobile {
    width: 375px;
    padding: 16px;
  }
}

.social-logins {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.github-button {
  width: 100%;
  display: flex;
  gap: 8px;
  color: #000;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  transition: background-color 0.2s;
}

.github-button:hover {
  background-color: color-mix(in srgb, white 90%, black);
}

.title {
  @include headline;
  font-weight: 500;
  text-align: center;
}
.subtitle {
  margin: 20px 0 40px;
  @include mobile {
    margin: 16px 0;
  }
}
.details {
  padding: 0;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - #{$topBarHeight});
  width: 100%;
  @include mobile {
    margin-top: 0px;
  }
  .container {
    margin: 0;
    width: 345px;
    @include mobile {
      width: 100%;
    }
  }
  .login-illustration {
    background: url('~@/assets/illustrations/signup.svg');
    background-size: cover;
    margin-left: 210px;
    width: 624px;
    height: 550px;
    @media screen and (max-width: 1280px) {
      width: 500px;
      height: 440px;
    }
    @include mobile {
      display: none;
    }
  }
}
.title {
  @include mobile {
    font-size: 32px;
    line-height: 50px;
  }
}
.social-signup,
.text-divider {
  margin: 40px 0;
  width: 100%;
}
.actions {
  height: 48px;
  margin-top: 10px;
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    width: 100%;
    &.an-button {
      @include mobile {
        border-radius: 0;
      }
    }
  }
}
.property + .property {
  margin-top: 24px;
}
.login-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 30px;

  .item {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}

.invalid-message {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: var(--red);
  > * + * {
    margin-left: 12px;
  }
}
</style>

import { createStoreModule } from '../../utils';
import { isEmpty, capitalize } from 'lodash-es';
import { trackEvent } from '@/services/tracking';
import {
  CODE_PREFERENCES_MODULE,
  FRAMEWORK_TO_LABEL,
  HTML_FLEXBOX,
  HTML_FRAMEWORK,
  STYLE_CSS,
  HTML_ABSOLUTE,
  HTML_AUTO_ANIMATE_ALLOWED,
  HTML_AUTO_ANIMATE_DISABLED,
  SYNTAX_CLASS,
  STYLE_SASS,
  STYLE_STYLED,
  SYNTAX_FUNCTIONAL,
  SYNTAX_TO_LABEL,
  STYLING_TO_LABEL,
  SET_CODEGEN_HTML_LAYOUT,
  SET_CODEGEN_LANG,
  SET_CODEGEN_REACT_STYLE,
  SET_CODEGEN_REACT_SYNTAX,
  SET_CODEGEN_AUTO_ANIMATE_MODE,
  SET_CODEGEN_REACT_LANGUAGE,
  SET_CODEGEN_LENGTH_UNIT,
  SET_CODEGEN_VUE_STYLE,
  SET_CODEGEN_ENGINE,
  SET_CODE_PREFERENCES,
  SET_DB_CODE_PREFERENCES,
  LENGTH_PX,
  LENGTH_VW,
  LANGUAGE_TYPESCRIPT,
  LANGUAGE_JAVASCRIPT,
  ENGINE_ATHENA,
  ENGINE_LEGACY
} from './constants';

export default createStoreModule({
  name: CODE_PREFERENCES_MODULE,
  crud: false,
  state: {
    codegenLang: HTML_FRAMEWORK,
    codegenReactSyntax: SYNTAX_FUNCTIONAL,
    codegenReactStyle: STYLE_CSS,
    codegenVueStyle: STYLE_CSS,
    codegenHTMLLayout: HTML_FLEXBOX,
    codegenAutoAnimateMode: HTML_AUTO_ANIMATE_ALLOWED,
    codegenLengthUnit: LENGTH_PX,
    codegenReactLanguage: '',
    codegenEngine: ENGINE_LEGACY,

    htmlLayoutOptions: [
      { value: HTML_FLEXBOX, label: 'Flexbox', dataCy: 'html-flexbox' },
      { value: HTML_ABSOLUTE, label: 'Absolute Position', dataCy: 'html-absolute' }
    ],
    lengthUnitOptions: [
      { value: LENGTH_PX, label: 'PX', dataCy: 'html-px' },
      { value: LENGTH_VW, label: 'VW', dataCy: 'html-vw' }
    ],
    autoAnimateModeOptions: [
      { value: HTML_AUTO_ANIMATE_ALLOWED, label: 'Allowed', dataCy: 'html-auto-animate-allowed' },
      { value: HTML_AUTO_ANIMATE_DISABLED, label: 'Disabled', dataCy: 'html-auto-animate-disabled' }
    ],
    reactLanguageOptions: [
      { value: LANGUAGE_JAVASCRIPT, label: 'Javascript', dataCy: 'react-javascript' },
      { value: LANGUAGE_TYPESCRIPT, label: 'Typescript', dataCy: 'react-typescript' }
    ],
    reactSyntaxOptions: [
      { value: SYNTAX_FUNCTIONAL, label: 'Functions', dataCy: 'syntax-function' },
      { value: SYNTAX_CLASS, label: 'Classes', dataCy: 'syntax-class' }
    ],
    reactStyleOptions: [
      { value: STYLE_CSS, label: 'Plain CSS', dataCy: 'react-css' },
      { value: STYLE_STYLED, label: 'Styled Components', dataCy: 'react-styled' },
      { value: STYLE_SASS, label: 'Sass', badgeText: 'Beta', dataCy: 'react-sass' }
    ],
    vueStyleOptions: [
      { value: STYLE_CSS, label: 'Plain CSS', dataCy: 'vue-css' },
      { value: STYLE_SASS, label: 'Sass', badgeText: 'Beta', dataCy: 'vue-sass' }
    ]
  },

  getters: {
    codegenLang: (state) => state.codegenLang,
    codegenReactSyntax: (state) => state.codegenReactSyntax,
    codegenReactStyle: (state) => state.codegenReactStyle,
    codegenVueStyle: (state) => state.codegenVueStyle,
    codegenHTMLLayout: (state) => state.codegenHTMLLayout,
    codegenLengthUnit: (state) => state.codegenLengthUnit,
    codegenEngine: (state) => state.codegenEngine,
    codegenAutoAnimateMode: (state) => {
      return state.codegenAutoAnimateMode || 'auto-animate-allowed';
    },
    codegenReactLanguage: (state) => {
      return state.codegenReactLanguage;
    },

    convertLocalStoragePrefsToDB: () => (preferences) => {
      const { framework, styling, syntax, autoAnimateMode, lengthUnit } = preferences;
      let prefs = { lang: framework };
      if (framework === 'html') {
        prefs = { html_layout: styling, auto_animate_mode: autoAnimateMode, length_unit: lengthUnit, ...prefs };
      } else if (framework === 'react') {
        prefs = { react_style: styling, react_syntax: syntax, ...prefs };
      } else if (framework === 'vue') {
        prefs = { vue_style: styling, ...prefs };
      }
      return prefs;
    },
    convertDBPrefsToLocalStorage: () => (state, dbPreferences) => {
      const { lang, react_style, react_syntax, html_layout, vue_style, auto_animate_mode, length_unit } = dbPreferences;
      let prefs = {};
      const framework = lang;
      prefs = { framework };
      if (framework === 'html') {
        prefs = {
          styling: html_layout || state.codegenHTMLLayout,
          autoAnimateMode: auto_animate_mode || state.codegenAutoAnimateMode,
          lengthUnit: length_unit || state.codegenLengthUnit,
          ...prefs
        };
      } else if (framework === 'react') {
        prefs = {
          styling: react_style || state.codegenReactStyle,
          syntax: react_syntax || state.codegenReactSyntax,
          ...prefs
        };
      } else if (framework === 'vue') {
        prefs = { styling: vue_style || state.codegenVueStyle, ...prefs };
      }
      return prefs;
    },
    getCodePreferences(state, getters, rootState) {
      let prefs = {};
      const currentProject = rootState['projects']['currentItem'];

      if (currentProject.id) {
        const { developer_preferences } = currentProject;
        if (!isEmpty(developer_preferences)) {
          prefs = getters.convertDBPrefsToLocalStorage(state, developer_preferences);
        }
      }

      return prefs;
    },
    isCodePreferencesEmpty(_state, _getters, rootState) {
      const { currentItem: currentProject } = rootState.projects;
      return isEmpty(currentProject?.developer_preferences ?? {});
    },
    shouldCodePreferencesBePresented(_state, getters, rootState) {
      const { currentItem: currentProject } = rootState.projects;
      if (!currentProject.id) return false;
      const { modal, mode, framework } = rootState.route.query;
      return getters.isCodePreferencesEmpty && modal !== 'export' && mode === 'code' && !framework;
    },

    frameworkLabel(state) {
      return FRAMEWORK_TO_LABEL[state.codegenLang];
    },
    syntaxLabel(state) {
      return SYNTAX_TO_LABEL[state.codegenReactSyntax];
    },

    codeStyling(state) {
      let type = 'css';
      switch (state.codegenLang) {
        case 'react':
          if (['css', 'sass', 'sass', 'scss'].includes(state.codegenReactStyle)) {
            type = state.codegenReactStyle;
            break;
          }
          type = 'css';
          break;
        case 'vue':
          type = state.codegenVueStyle;
          break;
        default:
          type = 'css';
      }

      return type;
    },

    stylingLabel(_state, getters) {
      return STYLING_TO_LABEL[getters.codeStyling];
    },
    frameworkAdditionalInfoLabel(state, getters) {
      if (state.codegenLang === 'react') {
        return `${getters.codegenReactLanguage}`;
      }
      return `${getters.stylingLabel}`;
    }
  },

  mutations: {
    [SET_CODEGEN_LANG]: (state, lang) => (state.codegenLang = lang),
    [SET_CODEGEN_REACT_SYNTAX]: (state, t) => (state.codegenReactSyntax = t),
    [SET_CODEGEN_REACT_STYLE]: (state, t) => (state.codegenReactStyle = t),
    [SET_CODEGEN_VUE_STYLE]: (state, t) => (state.codegenVueStyle = t),
    [SET_CODEGEN_HTML_LAYOUT]: (state, t) => (state.codegenHTMLLayout = t),
    [SET_CODEGEN_LENGTH_UNIT]: (state, t) => (state.codegenLengthUnit = t),
    [SET_CODEGEN_AUTO_ANIMATE_MODE]: (state, t) => (state.codegenAutoAnimateMode = t),
    [SET_CODEGEN_REACT_LANGUAGE]: (state, t) => (state.codegenReactLanguage = t),
    [SET_CODEGEN_ENGINE]: (state, t) => (state.codegenEngine = t)
  },

  actions: {
    [SET_CODE_PREFERENCES]: ({ commit, state }, preferences) => {
      if (preferences.framework) {
        commit(SET_CODEGEN_LANG, preferences.framework);
      }

      if (preferences.framework === 'react') {
        commit(SET_CODEGEN_REACT_LANGUAGE, LANGUAGE_JAVASCRIPT);
        commit(SET_CODEGEN_ENGINE, ENGINE_ATHENA);
      } else {
        commit(SET_CODEGEN_ENGINE, ENGINE_LEGACY);
      }

      switch (preferences.framework) {
        case 'html':
          {
            const style = preferences.styling;
            const autoAnimateMode = preferences.autoAnimateMode;
            const lengthUnit = preferences.lengthUnit;
            if (style != state.codegenHTMLLayout) {
              commit(SET_CODEGEN_HTML_LAYOUT, style);
              trackEvent('omniview.html-syntax.change', { style });
            }
            if (autoAnimateMode != state.codegenAutoAnimateMode) {
              commit(SET_CODEGEN_AUTO_ANIMATE_MODE, autoAnimateMode);
              trackEvent('omniview.auto-animate-mode.change', { autoAnimateMode });
            }
            if (lengthUnit != state.codegenLengthUnit) {
              commit(SET_CODEGEN_LENGTH_UNIT, lengthUnit);
              trackEvent('omniview.length-unit.change', { lengthUnit });
            }
          }
          break;
        case 'vue':
          {
            const style = preferences.styling;
            if (style != state.codegenVueStyle) {
              commit(SET_CODEGEN_VUE_STYLE, style);
              trackEvent('omniview.vue-style.change', { style });
            }
          }
          break;
        case 'react':
          {
            const style = preferences.styling;
            const syntax = preferences.syntax;
            const reactLanguage = preferences.reactLanguage;
            if (style != state.codegenReactStyle) {
              commit(SET_CODEGEN_REACT_STYLE, style);
              trackEvent('omniview.react-style.change', { style });
            }
            if (syntax != state.codegenReactSyntax) {
              commit(SET_CODEGEN_REACT_SYNTAX, syntax);
              trackEvent('omniview.react-syntax.change', { syntax });
            }

            if (state.codegenReactLanguage === '') {
              commit(SET_CODEGEN_REACT_LANGUAGE, preferences.language || LANGUAGE_JAVASCRIPT);
            } else if (reactLanguage && reactLanguage != state.codegenReactLanguage) {
              commit(SET_CODEGEN_REACT_LANGUAGE, reactLanguage);
              trackEvent('omniview.react-language.change', { reactLanguage });
            } else if (state.codegenReactLanguage) {
              commit(SET_CODEGEN_REACT_LANGUAGE, state.codegenReactLanguage);
            }
          }

          break;
      }
      return true;
    },

    [SET_DB_CODE_PREFERENCES]: ({ commit, dispatch, state, rootState }, payload) => {
      const {
        codegenLang,
        codegenReactSyntax,
        codegenReactStyle,
        codegenReactLanguage,
        codegenVueStyle,
        codegenHTMLLayout,
        codegenAutoAnimateMode,
        codegenLengthUnit
      } = state;
      const {
        lang,
        react_style,
        react_syntax,
        react_language,
        html_layout,
        vue_style,
        auto_animate_mode,
        length_unit
      } = payload;
      const { currentItem: currentProject } = rootState.projects;
      const { id } = currentProject;

      if (!id) return;

      const developer_preferences = {
        web_framework: capitalize(lang || codegenLang),
        lang: lang || codegenLang,
        react_style: react_style || codegenReactStyle,
        react_syntax: react_syntax || codegenReactSyntax,
        html_layout: html_layout || codegenHTMLLayout,
        vue_style: vue_style || codegenVueStyle,
        auto_animate_mode: auto_animate_mode || codegenAutoAnimateMode,
        length_unit: length_unit || codegenLengthUnit,
        react_language: react_language || codegenReactLanguage
      };

      commit('projects/setCurrentItem', { ...currentProject, developer_preferences }, { root: true });
      return dispatch('projects/update', { id, payload: { developer_preferences } }, { root: true });
    }
  }
});

<template>
  <div>
    <div v-for="team in userTeams" :key="team.id" class="team-details">
      <div data-cy="team-name" class="team-name">{{ team.name }}</div>
      <div class="plan-details">
        <span> {{ fixTeamPlan(team) }} </span>
        <div>
          <an-button
            data-cy="subscriptions-upgrade-team-btn"
            :variant="buttonVariant(team)"
            @click="onPlanCtaClick(team)"
          >
            {{ buttonText(team) }}
          </an-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { readablePlanName } from '@/utils/billing';
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    userTeams: 'teamMemberships/userTeams'
  }),
  methods: {
    buttonVariant({ plan = 'Free' }) {
      return plan === 'Free' ? 'primary' : 'secondary';
    },
    buttonText({ plan = 'Free' }) {
      return plan === 'Free' ? 'Upgrade' : 'View plan';
    },
    onPlanCtaClick({ plan = 'Free', slug: teamSlug }) {
      const name = plan === 'Free' ? 'team-pricing' : 'team-settings-billing';
      const params = { teamSlug };
      this.$router.push({ name, params });
    },
    fixTeamPlan({ plan }) {
      const planName = readablePlanName(plan);
      return `${planName} plan`;
    }
  }
};
</script>

<style lang="scss" scoped>
.team-details {
  border-bottom: var(--border);
  padding-bottom: 30px;
  + .team-details {
    padding-top: 30px;
  }
}
.team-name {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
}
.plan-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

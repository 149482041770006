var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({staticClass:"an-button",class:[_vm.variant, { iconOnly: !_vm.$slots.default, isActive: _vm.isActive, isWorking: _vm.isWorking, rounded: _vm.rounded }],style:({
    '--height': _vm.buttonSizes[_vm.size].height + 'px',
    '--padding': _vm.buttonSizes[_vm.size].padding,
    '--fontSize': _vm.buttonSizes[_vm.size].fontSize + 'px'
  }),attrs:{"type":"button","disabled":_vm.disabled}},'button',_vm.$attrs,false),_vm.listeners),[(_vm.isWorking)?_c('div',{staticClass:"three-dots loader"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})]):_vm._e(),_c('div',{style:({ visibility: _vm.isWorking ? 'hidden' : 'visible', display: 'inline-flex', width: 'auto' })},[(_vm.icon && _vm.iconPosition == 'left')?_c('svg-icon',{attrs:{"size":_vm.iconSize,"name":_vm.icon}}):_vm._e(),(_vm.$slots.default)?_c('span',{staticClass:"text",class:{
        withPaddingLeft: _vm.icon && _vm.iconPosition == 'left',
        withPaddingRight: _vm.icon && _vm.iconPosition == 'right'
      }},[_vm._t("default")],2):_vm._e(),(_vm.icon && _vm.iconPosition == 'right')?_c('svg-icon',{attrs:{"size":_vm.iconSize,"name":_vm.icon}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
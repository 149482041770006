import dayjs from 'dayjs';
import api from '@/api';

/**
 * Send a Google enhanced conversion event to the backend.
 * @param {('purchase'|'signup'|'paired_plugin'|'synced_design')} conversionName - The name of the conversion action.
 * @param {string} transactionId - The transaction id or order id. This is a generated id that is used to identify the transaction.
 * @typedef {{ email: string, first_name: string, last_name: string }} User
 * @param {User} user - The user who is making the conversion.
 */
export const sendGoogleConversion = (conversionName, transactionId, user) => {
  api.post('/conversions/google', {
    conversion_name: conversionName,
    transaction_id: transactionId,
    user,
    conversion_date_time: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ssZ')
  });
};

/**
 * Send a Facebook conversion event to the backend.
 * @param {('Purchase'|'CompleteRegistration'|'paired_plugin'|'Synced Design')} conversionName - The name of the conversion action.
 * @param {string} transactionId - The transaction id or external id. This is a generated id that is used to identify the transaction.
 * @typedef {{ id: string, email: string, first_name: string, last_name: string }} User
 * @param {User} user - The user who is making the conversion.
 */
export const sendFacebookConversion = (conversionName, transactionId, user) => {
  api.post(
    '/conversions/facebook',
    {
      conversion_name: conversionName,
      external_id: transactionId,
      user
    },
    { withCredentials: true }
  );
};

<template>
  <div class="login">
    <div class="details">
      <form>
        <div class="property">
          <div class="label">Email address</div>
          <div class="input">
            <an-input v-model="email" />
          </div>
        </div>
        <div class="property">
          <div class="label">Password</div>
          <div class="password-input">
            <an-input v-model="password" placeholder="********" type="password" autocomplete />
            <an-button :isWorking="loading" @click="updateDetails" variant="secondary">Update</an-button>
          </div>
        </div>
      </form>
    </div>
    <div class="separated meta-section" data-cy="meta-section" v-if="user.is_admin">
      <div>Admins</div>
      <an-checkbox v-model="includePixel">
        <span class="label">Include Anima pixel</span>
      </an-checkbox>
      <an-checkbox v-model="showDebugEvents">
        <span class="label">Show events</span>
      </an-checkbox>
      <an-checkbox v-model="showRegenerateCodegenReq">
        <span class="label">Show regenerate codegen request button</span>
      </an-checkbox>
      <div class="label top">Set experiment value</div>
      <div class="experiment">
        <an-input placeholder="experiment-name" v-model="experimentName" />
        <an-input placeholder="variant" v-model="experimentVariant" />
        <an-button @click="setExperiment" variant="secondary">Override</an-button>
      </div>
    </div>
    <div class="separated">
      <an-link @click="openDeleteAccountModal" primaryHover>Delete account</an-link>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { isValidEmail } from '@/utils/email';
import { EventBus, openModal, toastError, toastSuccess } from '@/services/bus';
import api from '@/api';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      showDebugEvents: false,
      showRegenerateCodegenReq: false,
      experimentName: '',
      experimentVariant: null
    };
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({
      hasPixelEnabled: 'users/hasPixelEnabled'
    }),
    includePixel: {
      get() {
        return this.hasPixelEnabled;
      },
      set(value) {
        this.setIncludePixel(value);
        EventBus.$emit('toggle-include-pixel', value);
      }
    }
  },
  methods: {
    ...mapMutations({
      addExperimentOverrides: 'experiments/addOverrides',
      setIncludePixel: 'users/setIncludePixel'
    }),
    reset() {
      this.email = this.user.email;
      if (this.user.is_admin) {
        this.showDebugEvents = !!localStorage.getItem('debug-events');
        this.showRegenerateCodegenReq = !!localStorage.getItem('showRegenerateCodegenReq');
      }
    },
    async updateDetails() {
      const { password, email } = this;
      if (!isValidEmail(email)) {
        toastError('Please insert a valid email');
        return;
      }
      if (password && password.length < 8) {
        toastError('Your new password is too short!');
        return;
      }
      this.loading = true;
      try {
        if (email !== this.user.email) {
          await api.post('/users/me/send_email_verification', { email });
          this.$trackEvent('request-verify-email.success', { newEmail: email, oldEmail: this.user.email });
          toastSuccess('To verify your new email, please check you inbox');
        }
        if (password) {
          await api.post('/rpc/set_password', { new_password: password });
          this.$trackEvent('update-password.success');
          toastSuccess('New password saved');
        }
      } catch (err) {
        this.$trackEvent('update-user-details.error');
        toastError('Failed saving changes :(');
      } finally {
        this.loading = false;
      }
    },
    setExperiment() {
      const { experimentName, experimentVariant } = this;
      let value = experimentVariant;
      if (experimentVariant === 'true') value = true;
      if (experimentVariant === 'false') value = false;
      this.addExperimentOverrides({ [experimentName]: value });
      this.experimentName = '';
      this.experimentVariant = null;
      toastSuccess('Experiment updated');
    },
    openDeleteAccountModal() {
      openModal({ name: 'account-delete', onCloseRedirect: { name: 'account' } });
    }
  },
  watch: {
    user: {
      handler: 'reset',
      immediate: true
    },
    showDebugEvents(value) {
      localStorage.setItem('debug-events', value ? '1' : '');
      EventBus.$emit('toggle-debug-events', value);
    },
    showRegenerateCodegenReq(value) {
      localStorage.setItem('showRegenerateCodegenReq', value ? '1' : '');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.details,
form {
  width: 100%;
}
.separated {
  width: 100%;
}
.meta-section {
  .label.top,
  > * + * {
    margin-top: 15px;
  }
}
.experiment {
  display: flex;
  align-items: center;
  margin-top: 8px;
  > * {
    max-width: 50%;
  }
  > * + * {
    margin-left: 5px;
  }
}
</style>

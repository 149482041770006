import { get, isEmpty } from 'lodash-es';
import api from '@/api';
import { getStripeToken, confirmStripePayment } from '@/services/payment/stripe';
import { trackEvent } from '@/services/tracking';
import { apiPlanName } from '@/utils/billing';
import { isDev } from '@/utils/environment';

const OLD_PRO3_PLAN_IDS = [
  'anima-pro3-monthly-39',
  'anima-pro3-annually-31',
  'anima-monthly-39',
  'anima-pro-annually-31'
];

const PLAN_IDS = {
  stripe: {
    prototype: {
      monthly: 'anima-prototype-monthly-29',
      annually: 'anima-prototype-annually-25-1'
    },
    pro: {
      monthly: 'anima-pro3-monthly-49',
      annually: 'anima-pro3-annually-468'
    },
    proScreens: {
      limited: {
        monthly: 'anima-pro-screens-monthly-limited',
        annually: 'anima-pro-screens-annually-limited'
      },
      unlimited: {
        monthly: 'anima-pro-screens-monthly-unlimited',
        annually: 'anima-pro-screens-annually-unlimited'
      }
    },
    pro3: {
      monthly: 'anima-pro3-monthly-49',
      annually: 'anima-pro3-annually-468'
    },
    team: {
      monthly: 'anima-team-monthly-32',
      annually: 'anima-team-annually-26'
    },
    business: {
      monthly: 'anima-business-monthly-39',
      annually: 'anima-business-annually-31'
    }
  },
  paypal: {
    prototype: {
      monthly: isDev() ? 'P-7Y423058HL6858901L36NMVY' : 'P-9L615356WX143364PL4A62EY',
      annually: isDev() ? 'P-19K066423P254805UL36NNEA' : 'P-8WS045422U1104428L4A62WI'
    },
    pro: {
      monthly: isDev() ? 'P-1NX83750GJ912353EMIY7FTA' : 'P-8CP056908H713461XMIY67RY',
      annually: isDev() ? 'P-4DH87115YE117270PMIY7DEQ' : 'P-0U159539HD2987801MIY7AKI'
    },
    proScreens: {
      limited: {
        monthly: isDev() ? 'P-2KM50764UV362173VML52ARI' : 'P-3KB62517BM241820FML3FT5Y',
        annually: isDev() ? 'P-1M0828594B456710AML52EPY' : 'P-58U999631M7528937ML3F3YQ'
      },
      unlimited: {
        monthly: isDev() ? 'P-8GP845460T261064GML52DFA' : 'P-0VR22155T0115930NML3FU7Q',
        annually: isDev() ? 'P-7W222402E6720154NML52DXA' : 'P-8TW81805K9718142HML3F27A'
      }
    },
    team: {
      monthly: isDev() ? 'P-1HD93160J38597406MAJLL4A' : 'P-4NM64581XH2866313MIXARRA',
      annually: isDev() ? 'P-7AE59105GV4041848MAIDPZA' : 'P-01W25285KU396003KMIXAUEA'
    },
    business: {
      monthly: isDev() ? 'P-8AR16793RT558823NMIXUHTI' : 'P-03S1914673997181UMIXT2SY',
      annually: isDev() ? 'P-3PL33771YR898653BMIXUIAI' : 'P-01W25285KU396003KMIXAUEA'
    }
  }
};

export const PRO_SCREENS_TIERS = [
  'anima-pro-screens-monthly-limited',
  'anima-pro-screens-annually-limited',
  'anima-pro-screens-monthly-unlimited',
  'anima-pro-screens-annually-unlimited'
];

const PRICES = {
  prototype: {
    monthly: 29,
    annually: 25
  },
  pro: {
    monthly: 49,
    annually: 39
  },
  proScreens: {
    5: {
      unlimited: { monthly: 78, annually: 62 },
      limited: { monthly: 39, annually: 31 }
    },
    10: {
      unlimited: { monthly: 88, annually: 70 },
      limited: { monthly: 49, annually: 39 }
    },
    15: {
      unlimited: { monthly: 103, annually: 82 },
      limited: { monthly: 64, annually: 51 }
    },
    30: {
      unlimited: { monthly: 148, annually: 118 },
      limited: { monthly: 109, annually: 87 }
    }
  },
  pro3: {
    monthly: 49,
    annually: 39
  },
  team: {
    monthly: 32,
    annually: 26
  },
  business: {
    monthly: 39,
    annually: 31
  }
};

export const checkDisplayedPrices = (planId) => {
  if (OLD_PRO3_PLAN_IDS.includes(planId)) {
    PRICES['pro3'] = {
      monthly: 39,
      annually: 31
    };
    PRICES['pro'] = {
      monthly: 39,
      annually: 31
    };
    PLAN_IDS['pro'] = {
      monthly: 'anima-pro3-monthly-39',
      annually: 'anima-pro3-annually-31'
    };
    PLAN_IDS['pro3'] = {
      monthly: 'anima-pro3-monthly-39',
      annually: 'anima-pro3-annually-31'
    };
  }
};

export const getScreenOptionFromPlanId = (planId) => {
  let limited = Object.values(PLAN_IDS.stripe.proScreens.limited).find((plan) => plan === planId);
  let unlimited = Object.values(PLAN_IDS.stripe.proScreens.unlimited).find((plan) => plan === planId);
  if (!limited && !unlimited) {
    limited = Object.values(PLAN_IDS.paypal.proScreens.limited).find((plan) => plan === planId);
    unlimited = Object.values(PLAN_IDS.paypal.proScreens.unlimited).find((plan) => plan === planId);
  }
  if (limited) {
    return 'limited';
  }
  if (unlimited) {
    return 'unlimited';
  }
};

const fixInterval = (interval) => (interval.startsWith('month') ? 'monthly' : 'annually');

export const getScreenPriceOptions = (plan) => {
  return get(PRICES, plan, null);
};

export const getNextScreenLimit = (currentLimit) => {
  const screenOptions = getScreenPriceOptions('proScreens');
  const keys = Object.keys(screenOptions);
  return keys.find((key) => {
    if (currentLimit <= Number(key)) {
      return key;
    }
  });
};

export const getPrice = ({ plan, interval, quantity = 1, perMonth = true, discountPercent = 0, screenOption }) => {
  let pricePerMonth = get(PRICES, [apiPlanName(plan).toLowerCase(), fixInterval(interval)], 0);
  if (screenOption || plan === 'proScreens') {
    // Default price for extra-colaborator
    if (quantity === 1) {
      screenOption = 'limited';
      quantity = 5;
    }
    pricePerMonth = get(PRICES, ['proScreens', Number(quantity), screenOption, fixInterval(interval)], 0);
  } else {
    pricePerMonth *= quantity;
  }
  if (discountPercent && discountPercent > 0) {
    const absoluteDiscount = pricePerMonth * (discountPercent / 100);
    pricePerMonth -= absoluteDiscount;
  }
  return perMonth ? pricePerMonth : pricePerMonth * 12;
};

export const getPlanId = ({ service, plan = '', interval, screenOption }) => {
  let path = null;
  if (screenOption) {
    path = [service, 'proScreens', screenOption, interval.toLowerCase()];
  } else {
    path = [service, apiPlanName(plan), interval].map((p) => p?.toLowerCase());
  }
  return get(PLAN_IDS, path);
};

const getSubscriptionsUrl = ({ parentResource = 'teams', parentId, service }) => {
  const childResource = `${service === 'paypal' ? 'paypal_' : ''}subscriptions`;
  return `/v2/${parentResource}/${parentId}/${childResource}`;
};

const getSubscriptionPayload = (action, service, { planId, quantity, isTrial, paypalSubscriptionId, promoCode }) => {
  const subscription = { plan_id: planId, quantity, is_trial: isTrial };
  if (service === 'paypal') {
    subscription.last_requested_plan_id = planId;
    subscription.subscription_id = paypalSubscriptionId;
  } else if (action === 'create' && promoCode) {
    // currently paypal doesn't support promoCode, that's why the else-if.
    subscription.discount_promo_code = promoCode;
  }
  return subscription;
};

export const updateStripeSource = async (creditCard, teamId) => {
  const token = await getStripeToken(creditCard);
  const payload = { token: token.id, team: { id: teamId } };
  return api.post('/rpc/update_stripe_source', payload);
};

export const deleteStripeSource = (teamId) => {
  const payload = { team: { id: teamId } };
  return api.post('/rpc/delete_stripe_source', payload);
};

export const activateCancellationOffer = ({ teamSlug, offer }) => {
  const url = '/rpc/cancel_offer';
  const payload = { team_slug: teamSlug, offer };
  return api.post(url, payload);
};

export const createSubscription = async (options) => {
  const service = options.service.toLowerCase();
  const {
    interval,
    plan,
    quantity,
    screenOption,
    subscriptionId,
    paypalSubscriptionId,
    promoCode,
    teamId,
    isTrial,
    creditCard: creditCardElement,
    stripeSourceId
  } = options;
  const planId = getPlanId({ service, interval, plan, screenOption });

  if (!planId) {
    throw `Unknown plan: ${interval} ${plan}`;
  }

  // set new token
  if (service === 'stripe' && !isEmpty(creditCardElement)) await updateStripeSource(creditCardElement, teamId);

  // generate payload and url
  const subscriptionData = { planId, quantity, isTrial, paypalSubscriptionId, promoCode };
  const payload = getSubscriptionPayload(subscriptionId ? 'edit' : 'create', service, subscriptionData);
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  let subscription;

  if (subscriptionId) {
    const { data } = await api.put(url, subscriptionId, payload);
    subscription = data;
  } else {
    const { data } = await api.post(url, payload);

    subscription = data;
  }
  if (subscription.status === 'incomplete') {
    trackEvent('payment.purchase.3ds.started');
    const { payment_intent_client_secret: paymentIntentSecret } = subscription;
    await confirmStripePayment(paymentIntentSecret, subscription.id, stripeSourceId);
  }
};

export const tempValidatePromoCode = (promoCode, _interval, _plan) => {
  const interval = _interval?.toLowerCase();
  const plan = _plan?.toLowerCase();
  const monthlyCoupons = [
    'one-dollar-support-Pbl0',
    'adobe-max-anima',
    'black-friday-2021',
    '3-MONTHS-FR33',
    'show-n-tell'
  ];
  if (promoCode === 'STUDENT-PRO-4923') {
    return plan === 'pro' && interval === 'annually';
  }
  if (monthlyCoupons.includes(promoCode) || promoCode?.startsWith('animaFeedbackGift')) {
    return interval === 'monthly';
  }
  return true;
};

export const pauseSubscription = ({ service, teamId, subscriptionId, start, end }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_paused: true, pause_start: start, pause_end: end };
  return api.put(url, subscriptionId, payload);
};

export const unpauseSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_paused: false, pause_start: null, pause_end: null };
  return api.put(url, subscriptionId, payload);
};

export const cancelSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_cancelled: true, cancelled_requested_at_date: new Date() };
  return api.put(url, subscriptionId, payload);
};

export const renewSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_cancelled: false, cancelled_requested_at_date: null };
  return api.put(url, subscriptionId, payload);
};

export const fetchSubscriptionData = ({ service, subscriptionId, plan = '', screenOption, quantity, interval }) => {
  const url = `/${service}_subscription/details`;
  const params = { plan: getPlanId({ service, plan, interval, screenOption }), quantity };
  return api.get(url, subscriptionId, { params, cachePolicy: 'no-cache' });
};

<template>
  <div>
    <slot v-if="hasPermission" />
    <slot name="no-permission" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    teamSlug: {
      type: String
    },
    role: {
      type: String
    },
    plan: {
      type: String
    },
    accessLevel: {
      type: String
    }
  },
  mounted() {
    this.fetchAllUserMemberships({ id: 'me' });
  },
  computed: {
    ...mapGetters({ checkPermissions: 'teamMemberships/hasPermissions' }),
    hasPermission() {
      const { teamSlug, role, plan, accessLevel } = this;
      const permissionsObj = {
        teamSlug: teamSlug || this.$route.params.teamSlug,
        role,
        plan,
        accessLevel
      };
      return this.checkPermissions(permissionsObj);
    }
  },
  methods: {
    ...mapActions({
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships'
    })
  },
  watch: {
    hasPermission: {
      handler(newValue) {
        const event = newValue ? 'permission-granted' : 'permission-denied';
        this.$emit(event);
      },
      immediate: true
    }
  }
};
</script>

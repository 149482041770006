<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">You need access</div>
    <div class="subtitle">
      Looks like you don't have permission to view this project.
      <br />
      Let's ask for access!
    </div>

    <div class="actions">
      <an-button @click="handleRequestAccess" :isWorking="isWorking" data-cy="request-access">Request access</an-button>
      <an-link @click="handleCancel" primaryHover variant="dark">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { toastError, toastSuccess } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import { TeamMembershipMixin } from '@/mixins';

export default {
  name: 'project-request-access',
  data() {
    return {
      isWorking: false
    };
  },
  mixins: [TeamMembershipMixin],
  methods: {
    async handleRequestAccess() {
      try {
        this.isWorking = true;
        this.$trackEvent('request-access.request-access-button.click');

        await this.askForAccess('project');

        this.$trackEvent('request-access.request-access.success');
        toastSuccess('Successfully sent request for access!');
        this.$emit('close');
      } catch (err) {
        toastError("Failed to request access. We've been notified and we will investigate!");

        errorHandler.captureExceptionAndTrack(err, {
          name: 'request-access.request-access.failure',
          data: { message: err.message }
        });
      } finally {
        this.isWorking = false;
      }
    },
    handleCancel() {
      this.$emit('close');
      this.$trackEvent('request-access.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>

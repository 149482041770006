import { keys } from 'lodash-es';
const prototypeUrlSuffix = 'animaapp.io';

const objectToQueryString = (obj) => {
  const queryString = keys(obj)
    .map((k) => `${k}=${obj[k]}`)
    .join('&');
  return queryString ? `?${queryString}` : '';
};

export const getPrototypeUrl = ({ username, password, subdomain, params, path = '' } = {}) => {
  let auth = '';
  const query = objectToQueryString(params);
  if (username && password) {
    auth = `${username}:${password}@`;
  }
  return `https://${auth}${subdomain}.${prototypeUrlSuffix}/${path}${query}`;
};

export const getCdnUrl = (_url, { size, width, height, pos = 'top' } = {}) => {
  let url = _url || null;
  if (url) {
    url = url.replace('https://anima-uploads.s3.amazonaws.com', 'https://image-cdn.animaapp.com');
    if (size || (width && height)) {
      url = `${url}?d=${width || size}x${height || size}&p=${pos}`;
    }
  }
  return url;
};

export const normalizeDomain = (value) => value?.trim().toLowerCase();

export const validateDomain = (value) => {
  const domain = normalizeDomain(value);
  return /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,27}$/.test(domain);
};

export const isValidURL = (str) => {
  let url;

  try {
    url = new URL(str);
  } catch (err) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

import { createStoreModule } from '../utils';
export default createStoreModule({
  name: 'paypalSubscription',
  modelName: 'paypal_subscriptions',
  crud: true,
  endpoint: '/v2/paypal_subscriptions',
  getters: {
    activeSubscription: (state, getters, rootState, rootGetters) => {
      return rootGetters['stripeSubscriptions/activeSubscription'];
    }
  }
});

<template>
  <div class="email-notifications-container">
    <div class="en-section">
      <div class="title">
        Activity updates
        <an-toggle v-model="enableActivitiesEmails" @change="toggleNotifications" />
      </div>
      <div class="description">New project syncs and updates.</div>
    </div>
    <div class="en-section separate">
      <div class="title">Required communication</div>
      <div class="description">Emails regarding your account like billing, security, and support.</div>
    </div>
    <div class="en-section">
      <div class="title">Comments notification</div>
      <div class="description">Configure the frequency in which comments are sent to your inbox.</div>
      <div class="toggle">
        <GiantToggle v-model="enableCommentEmails" @change="toggleNotifications" onText="Right away" offText="Never" />
      </div>
    </div>
  </div>
</template>

<script>
import GiantToggle from '@/components/Toggle/GiantToggle';
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash-es';

const COMMENTS = 'COMMENTS';
const ACTIVITIES = 'ACTIVITIES';

export default {
  data() {
    return {
      enableCommentEmails: true,
      enableActivitiesEmails: true
    };
  },
  components: {
    GiantToggle
  },
  computed: {
    ...mapGetters('notificationsSettings', ['emailSettings'])
  },
  methods: {
    ...mapActions({
      fetchNotificationsSettings: 'notificationsSettings/fetchAll',
      createNotificationsSettings: 'notificationsSettings/create',
      updateNotificationsSettings: 'notificationsSettings/update'
    }),
    reset() {
      const { disabled_notifications: disabled = [] } = this.emailSettings;
      this.enableCommentEmails = !disabled.includes(COMMENTS);
      this.enableActivitiesEmails = !disabled.includes(ACTIVITIES);
    },
    async toggleNotifications() {
      const { enableCommentEmails, enableActivitiesEmails } = this;
      const disabled = [];

      if (!enableCommentEmails) disabled.push(COMMENTS);
      if (!enableActivitiesEmails) disabled.push(ACTIVITIES);

      const payload = { disabled_notifications: disabled };

      this.$trackEvent('account-email-notifications.toggle-comment-emails.click', { ...payload });

      if (isEmpty(this.emailSettings)) {
        await this.create(payload);
      } else {
        await this.update(payload);
      }

      this.fetchNotificationsSettings();
    },
    update(payload) {
      const { id } = this.emailSettings;
      return this.updateNotificationsSettings({ id, payload });
    },
    create({ disabled_notifications }) {
      const payload = {
        channel: 'email',
        workspace_slug: 'all',
        is_disabled: false,
        disabled_notifications
      };
      return this.createNotificationsSettings({ parent: 'users', id: 'me', payload });
    }
  },
  watch: {
    emailSettings: {
      handler: 'reset',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.en-section {
  padding-bottom: 40px;
  &.separate {
    border-bottom: var(--border);
    + .en-section {
      margin-top: 40px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
  }
  .description {
    margin-top: 12px;
    color: var(--secondary-text);
  }
  > .toggle {
    margin-top: 30px;
  }
}
</style>

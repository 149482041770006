<template>
  <div class="container">
    <div class="title">We're packaging everything</div>
    <div class="body">
      Hmmm... this is taking longer than expected… <br />
      <div class="illustration">
        <img src="@/assets/illustrations/exporting.svg" />
      </div>
      <Divider theme="dark" />
      Our automated developers are typing as fast as they can!
      <div class="click">
        <an-link theme="dark" @click="notifyUserWhenPackageIsReady">Email me when it’s ready</an-link> <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Divider from '@/components/Divider/Divider';
import { EventBus, toastSuccess, toastWarning } from '@/services/bus';

export default {
  name: 'package-wait',
  onClose: () => this.close,
  props: {
    codePackageId: {
      type: String,
      required: true
    }
  },
  components: {
    Divider
  },
  data() {
    return {
      hasClickedOnGetEmail: false
    };
  },
  mounted() {
    EventBus.$on('close-package-waiting-modal', this.close);
    this.$trackEvent('omniview.waiting-for-package-modal.show');
  },
  destroyed() {
    EventBus.$off('close-package-waiting-modal', this.close);
  },
  methods: {
    ...mapActions({
      updateCodePackage: 'codePackages/update'
    }),
    notifyUserWhenPackageIsReady() {
      const payload = { notify_when_done: true };
      this.updateCodePackage({ id: this.codePackageId, payload });
      toastSuccess('Great, We’ll send you an email when your download is ready.');
      this.hasClickedOnGetEmail = true;
      this.$trackEvent('omniview.waiting-for-package-modal.send-mail.clicked');
      this.$emit('close');
    },
    close({ isPackageReady = false }) {
      if (!this.hasClickedOnGetEmail && !isPackageReady) {
        toastWarning('Your package is being prepared in the background. We’ll notify you when it’s done.');
      }
      this.$trackEvent('omniview.waiting-for-package-modal.close');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 30px 0px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title {
  @include headline;
  font-weight: 500;
}
.body {
  margin: 20px 0 30px;
  color: var(--dark-secondary-text);
}
</style>

import { createStoreModule } from '../utils';
import api from '@/api';
import { has } from 'lodash-es';

const fetchComments = async (ctx, { id, params, cachePolicy = 'cache-first' }) => {
  try {
    const url = `/v2/projects/${id}/comments/`;
    const { data } = await api.list(url, { params: { ...params, page_size: 500 }, cachePolicy });
    ctx.commit(`setComments`, data.results);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default createStoreModule({
  name: 'comment',
  modelName: 'comments',
  crud: true,
  endpoint: {
    create: false,
    update: false,
    delete: false
  },
  state: {
    items: [],
    selectedNode: {},
    currentComment: {},
    commentsFilter: 'open'
  },
  mutations: {
    setComments: (state, comments) => (state.items = comments),
    setSelectedNode: (state, node) => (state.selectedNode = node),
    setCurrentComment: (state, comment) => (state.currentComment = comment),
    setCommentsFilter: (state, filter) => (state.commentsFilter = filter)
  },
  actions: {
    fetchComments
  },
  getters: {
    commentsFilter: (s) => s.commentsFilter,
    replies: (state) => {
      const comments = state.items;
      const replies = comments.filter((c) => has(c, 'reply_to'));
      replies.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      return replies;
    },
    commentsWithReplies: (state) => {
      const comments = state.items;

      const commentsThatHaveReplies = comments.filter((c) => !has(c, 'reply_to'));
      const commentsThatHaveRepliesIds = commentsThatHaveReplies.map((c) => c.id);

      let commentsWithReplies = comments.map((c) => {
        if (commentsThatHaveRepliesIds.includes(c.id)) {
          const replies = comments.filter((r) => r.reply_to == c.id);
          return {
            ...c,
            replies
          };
        }
        return {
          ...c,
          replies: []
        };
      });

      commentsWithReplies = commentsWithReplies.filter((c) => !has(c, 'reply_to'));

      return commentsWithReplies;
    },
    currentComment: (state) => state.currentComment
  }
});

<template>
  <div
    @click="commentBodyClicked"
    class="c-container"
    :style="{ cursor: isReply ? 'default' : 'pointer' }"
    data-cy="comment"
  >
    <!-- user-avatar -->
    <an-user-avatar class="av" :user="comment.user" noBorder />
    <!-- content -->
    <div class="content">
      <div v-if="!isCreate" class="username-date">
        <div v-text="comment.user.name" class="username" />
        <div v-text="createdAt" class="date" />
        <div class="popover">
          <PopoverMenu
            ref="menu1"
            v-show="!isReply"
            popperClass="omni-menu"
            :items="commentActions"
            position="right"
            data-cy="comment-actions-menu"
          >
            <div class="y" slot="reference">
              <svg-icon class="icon" fill="currentColor" name="three-dots-horizontal" :size="18" />
            </div>
          </PopoverMenu>
          <PopoverMenu
            ref="menu2"
            v-show="isReply && !readOnly"
            popperClass="omni-menu"
            :items="replyActions"
            position="right"
          >
            <div class="y" slot="reference">
              <svg-icon class="icon" fill="currentColor" name="three-dots-horizontal" :size="18" />
            </div>
          </PopoverMenu>
        </div>
      </div>
      <!-- body-form -->
      <div class="create-container" v-if="isFormOpen">
        <an-textarea
          v-model="newCommentText"
          autoFocus
          rows="1"
          @enter="handleSubmit"
          placeholder=""
          :className="'comment-textarea'"
        />
        <div style="display: flex; align-items: center; padding-top: 4px; justify-content: flex-end">
          <an-button style="font-size: 16px; height: 30px" variant="secondary" @click="handleCancel">
            Cancel
          </an-button>
          <an-button
            style="margin-left: 8px; font-size: 16px; height: 30px"
            type="submit"
            variant="primary"
            @click="handleSubmit"
            :isWorking="isWorking"
          >
            Save
          </an-button>
        </div>
      </div>
      <div v-else>
        <!-- comment-body -->
        <p v-if="isCreate" @click="isFormOpen = true" class="fakeTa">Add a comment...</p>
        <p v-else v-text="comment.text" class="x"></p>
        <div v-if="!isReply" class="reply">
          <div class="no-hover" data-cy="reply-count">
            <svg-icon class="icon" fill="currentColor" :size="20" :name="commentBreakpointIcon"></svg-icon>
            <span>{{ comment.number_of_replies }} replies</span>
          </div>
          <div @click="openCommentReplies" class="hover" data-cy="reply-button">
            <svg-icon class="icon" fill="currentColor" :size="20" name="reply"></svg-icon>
            <span>reply</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { formatDateTimeConversational } from '@/utils/date';
import { deleteArrayItemById, updateArrayItemById } from '@/utils/javascript';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import { mapWidthToBreakpointIcon } from '@/components/OmniView/utils';
import errorHandler from '@/services/errorHandler';

export default {
  components: {
    PopoverMenu
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    isReply: {
      type: Boolean,
      default: false
    },
    isParent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newCommentText: '',
      isWorking: false,
      isDeleting: false,
      isFormOpen: false,
      commentActions: [{ label: 'Reply', onClick: this.openCommentReplies }],
      replyActions: [
        { label: 'Edit', onClick: () => (this.isFormOpen = true) },
        { label: 'Delete', onClick: this.handleCommentDelete }
      ]
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('components', { components: 'items' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentComment: 'comments/currentComment',
      replies: 'comments/replies'
    }),
    readOnly() {
      return this.currentUser.email !== this.comment.user?.email;
    },
    createdAt() {
      return this.comment.created_at ? formatDateTimeConversational(this.comment.created_at) : '';
    },
    commentBreakpointIcon() {
      const c = this.components.find((c) => c.slug === this.comment.component_slug) || {};
      return mapWidthToBreakpointIcon(c.width || 1024);
    }
  },
  mounted() {
    this.newCommentText = this.isCreate ? '' : this.comment.text;
    if (this.comment.status === 'OPEN') {
      this.commentActions.push({
        label: 'Resolve',
        dataCy: 'comment-actions-option-resolve',
        onClick: this.resolveComment
      });
    }
  },
  methods: {
    commentBodyClicked() {
      if (this.isReply) return;
      this.openCommentReplies();
    },
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      updateComment: 'comments/update',
      deleteComment: 'comments/delete'
    }),
    ...mapMutations({
      setComments: 'comments/setComments',
      setCurrentComment: 'comments/setCurrentComment'
    }),
    closepops() {
      this.$refs.menu1.isOpen = this.$refs.menu2 = false;
    },
    openCommentReplies() {
      this.setCurrentComment(this.comment);
      this.$emit('openReplies');
    },
    async resolveComment() {
      this.$emit('resolve', this.comment);
    },

    async handleCommentUpdate() {
      const { projectId } = this.$route.params;

      if (this.isWorking) return;
      try {
        this.isWorking = true;

        const newFakeComments = updateArrayItemById(this.comments, this.comment.id, {
          text: this.newCommentText
        });
        this.setComments(newFakeComments);

        if (this.comment.id === this.currentComment.id) {
          const newCurrentComment = newFakeComments.find((c) => c.id === this.currentComment.id);
          this.setCurrentComment(newCurrentComment);
        }
        this.isFormOpen = false;

        // update api call
        await this.updateComment({
          parent: 'projects',
          parentId: projectId,
          id: this.comment.id,
          payload: {
            text: this.newCommentText
          }
        });
        // Refresh comments
        const { results: newComments } = await this.fetchProjectComments({
          cachePolicy: 'no-cache',
          id: projectId,
          params: {
            page_size: 100
          }
        });

        this.setComments(newComments);

        this.isWorking = false;
      } catch (error) {
        errorHandler.captureException(error);
      }
    },
    async handleCommentDelete() {
      const { projectId } = this.$route.params;

      if (this.isDeleting) return;
      try {
        this.isDeleting = true;

        // Optimistic delete

        const newFakeComments = deleteArrayItemById(this.comments, this.comment.id);
        this.setComments(newFakeComments);

        if (this.isParent && this.isReply) {
          this.$emit('openComments');
        }

        // Delete api call
        await this.deleteComment({
          parent: 'projects',
          parentId: projectId,
          id: this.comment.id
        });
        // Refresh comments
        const { results: newComments } = await this.fetchProjectComments({
          cachePolicy: 'no-cache',
          id: projectId,
          params: {
            page_size: 100
          }
        });
        this.setComments(newComments);

        this.isDeleting = false;
      } catch (error) {
        errorHandler.captureException(error);
        this.isDeleting = false;
      }
    },
    handleSubmit() {
      this.handleCommentUpdate();
    },
    handleKeyDown(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.handleSubmit();
      }
    },
    handleCancel() {
      this.isFormOpen = false;
    },
    handleFakeTextareaClicked() {
      if (this.isCreate) {
        this.isFormOpen = true;
      }
    }
  }
};
</script>

<style lang="scss">
.omni-menu {
  width: 110px !important;
  padding: 20px !important;
  border-radius: 10px !important;
  background: #333333 !important;

  .an-link {
    color: white !important;
    font-size: 14px !important;
    &:hover {
      color: var(--primary) !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.fakeTa {
  color: #d9d9d9;
  background: #2b2b2b;
  cursor: text;
  user-select: none;
  padding: 12px 16px;
  border-radius: 4px;
}
.action {
  margin-right: 12px;
  display: inline-block;
  padding: 4px 0;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
  }
}

.create-container {
  padding-left: 20px;
  padding-top: 25px;
}

.popover {
  cursor: pointer;
  // visibility: hidden;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    opacity: 0.4;
  }
  &:hover {
    .icon {
      opacity: 1;
    }
  }
}

.c-container {
  position: relative;
  font-size: 15px;
  border-bottom: 1px solid #2d2d2d;

  &:hover {
    .popover {
      visibility: visible;
    }
  }
}

.content {
  padding: 24px 20px;
  padding-left: 0;
}

.av {
  position: absolute;
  top: 24px;
  left: 20px;
}

.username-date {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 72px;
}

.username {
  display: inline-block;
  margin-right: 12px;
  font-weight: 700;
  color: white;
  font-size: 14px;
}

.date {
  @include ellipsis;
  display: inline-block;
  flex: 1;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.4;
}
.x {
  margin-bottom: 8px;
  color: white;
  white-space: pre-wrap;
  font-size: 14px;
  padding-left: 72px;
}

.number {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: cornflowerblue;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
}

.reply {
  display: flex;
  align-items: center;
  color: white;
  opacity: 0.4;
  font-size: 14px;
  padding-left: 72px;
  .hover,
  .no-hover {
    display: flex;
    align-items: center;
  }
  .hover {
    display: none;
    cursor: pointer;
  }
  .icon {
    margin-right: 10px;
  }

  &:hover {
    .no-hover {
      display: none;
    }
    .hover {
      display: flex;
    }
  }
}
</style>

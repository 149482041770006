import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api';
import { sync } from 'vuex-router-sync';
import { registerSharedComponents, registerModalComponents, registerBannerComponents } from '@/plugins/register';
import { injectRecaptchaScript } from '@/plugins/recaptcha';
import { initStripe, initPaypal } from '@/services/payment';
import { initMixpanel } from './services/tracking';
import VueWorker from 'vue-worker';
import VueIntercom from 'vue-intercom';
import VueGtm from '@gtm-support/vue2-gtm';
import VueYouTube from 'vue-youtube';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import AsyncComputed from 'vue-async-computed';
import { isProd, isCanary } from '@/utils/environment';
import { codegenCache } from '@/services/idb/codegenCache';
import VueObserveVisibility from 'vue-observe-visibility';
import { waitFor } from '@/utils/javascript';
import 'splitpanes/dist/splitpanes.css';
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');

sync(store, router);

Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  }
});

Vue.mixin({
  methods: {
    $waitFor: waitFor
  }
});

Vue.use(VueObserveVisibility);
Vue.use(AsyncComputed);
Vue.use(VueWorker);

Vue.use(VueYouTube);
Vue.use(VueIntercom, { appId: 'id43h9l8' });
Vue.use(VueGtm, {
  id: 'GTM-5GH35Q5',
  defer: true,
  enabled: isProd() || isCanary(),
  debug: isCanary(),
  loadScript: true,
  trackOnNextTick: false
});

// Google analytics
// import '@/plugins/gtag';

// Event analytics
import '@/plugins/trackEvent';

// Tooltips
import '@/plugins/tippy';
import '@/plugins/tooltip';

// Main styles
import '@/styles/main.scss';

// sentry Vue integration
import '@/plugins/sentry';

// listen to screen size changes
import '@/plugins/screenSize';

// allow changing theme.
import '@/plugins/theme';

initStripe();
initPaypal();
// Recaptcha script
injectRecaptchaScript();
// Register shared components
registerSharedComponents();
// Register modal components
registerModalComponents();
// Register banner components
registerBannerComponents();
// Init Api config
api.init();
// Init Codegen cache
codegenCache.init();
// Init Mixpanel config
initMixpanel();

Vue.config.productionTip = false;
const app = new Vue({
  router,
  store,
  render: (h) => h(App)
});

if (window.Cypress) {
  window.app = app;
}

app.$mount('#app');

<template>
  <div>
    <div
      class="stripe-container"
      data-cy="stripe-card-input-container"
      :class="[{ 'stripe-container-error': this.error }]"
    >
      <div ref="stripeCardInput" />
    </div>
    <div class="error" v-show="error">{{ this.error }}</div>
  </div>
</template>

<script>
import { createCardInstance } from '@/services/payment/stripe';

export default {
  data() {
    return {
      error: ''
    };
  },
  mounted() {
    this.setStripeCardInput();
  },
  methods: {
    async setStripeCardInput() {
      const card = await createCardInstance(this.$refs.stripeCardInput, this.onChange);
      this.$emit('mount', card);
    },
    onChange(event) {
      this.$emit('change', event || {});
      if (event.error) {
        this.error = event.error.message;
      } else {
        this.error = null;
      }
    }
  }
};
</script>

<style scoped>
.stripe-container {
  margin: 10px auto;
  padding: 16px;
  border: var(--border);
  border-radius: 10px;
}
.error {
  color: var(--primary-hover);
}
.stripe-container-error {
  border-color: var(--primary-hover);
}
</style>

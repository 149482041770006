<template>
  <div class="collapse">
    <div class="title" @click="isOpen = !isOpen">
      <div class="desktop">
        <div v-if="isOpen">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="22" y="32" width="4" height="16" rx="2" transform="rotate(-90 22 32)" fill="#FF6250" />
          </svg>
        </div>
        <div v-else>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="28" y="22" width="4" height="16" rx="2" fill="#FF6250" />
            <rect x="22" y="32" width="4" height="16" rx="2" transform="rotate(-90 22 32)" fill="#FF6250" />
          </svg>
        </div>
      </div>
      <h3 class="text" :class="{ themelite: islight }">{{ title }}</h3>
      <div class="mobile">
        <div v-if="isOpen">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="22" y="32" width="4" height="16" rx="2" transform="rotate(-90 22 32)" fill="#FF6250" />
          </svg>
        </div>
        <div v-else>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="28" y="22" width="4" height="16" rx="2" fill="#FF6250" />
            <rect x="22" y="32" width="4" height="16" rx="2" transform="rotate(-90 22 32)" fill="#FF6250" />
          </svg>
        </div>
      </div>
    </div>
    <div class="content" v-if="isOpen" :class="{ themelite: islight }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    islight() {
      return this.$store.state.themelight;
    }
  }
};
</script>

<style scoped>
.mobile {
  display: none;
}

.themelite {
  color: #2b2b2b;
}

.collapse {
  width: 100%;
  padding: 25px 20px;
  border-bottom: 2px solid rgba(247, 247, 247, 0.2);
}
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-family: 'Roslindale';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.content {
  margin-left: 60px;
  font-family: 'Mulish';
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
@media screen and (max-width: 1070px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .title {
    font-size: 20px;
    line-height: 32px;
    justify-content: space-between;
    text-align: left;
  }
  .collapse {
    padding: 30px 0;
  }
  .content {
    margin-left: 0;
    font-size: 16px;
    line-height: 24px;
  }
  .title + .content {
    margin-top: 30px;
  }
}
</style>

<template>
  <div>
    <div class="project-header-container">
      <ProjectHeader :isWorking="loading" />
    </div>

    <div>
      <div class="navigation">
        <Tabs
          data-cy="project-view-tabs"
          v-if="tabs.length > 0"
          :activeTabLabel="activeTabLabel"
          :items="tabs"
          @change="onTabChange"
        />

        <div>
          <an-button
            v-if="isCustomDomainButtonActive && !isFigmaSyncing"
            variant="tertiary"
            class="connect-your-domain-button--desktop"
            data-cy="connect-your-domain-button"
            @click="connectYourDomain"
          >
            Connect your domain
          </an-button>

          <template v-if="!isFigmaSyncing">
            <PopoverMenu :items="sortingOptions" position="right" :selectedLabel="sortByLabel">
              <div slot="reference" class="sort-select">
                {{ sortByLabel }}
                <svg-icon name="select-arrow-down" :size="24" />
              </div>
            </PopoverMenu>
          </template>

          <div data-cy="figma-import-progress" v-else class="flex items-center progress-bar-container">
            <ProgressBar :progress="figmaImportProgress" :height="10" backgroundColor="rgba(255, 98, 80, 0.3)" />
            <div class="progress-bar-text flex items-center">
              <svg-icon
                style="margin-right: 10px"
                fill="currentColor"
                class="icon-spin"
                name="spin"
                :size="20"
              ></svg-icon>
              <span>{{ figmaImportStatus }}</span>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isFigmaSyncing">
        <div class="figma-grid">
          <ScreenCard
            :style="{ ...{ opacity: 1, pointerEvents: 'none', userSelect: 'none' }, minHeight: '100px' }"
            v-for="(frame, index) in figmaFrames"
            :key="index"
            :screen="frame"
            class="relative"
            :actions="[]"
            :cta="'Building screen...'"
            readOnly
          >
            <template slot="overlay">
              <div class="overlay flex items-center justify-center"></div>
            </template>
            <template slot="subtitle">
              <div class="flex items-center w-full">
                <!-- <div>Building screen...</div> -->
                <svg-icon
                  style="margin-left: auto; color: var(--primary)"
                  fill="currentColor"
                  class="wrench"
                  name="rocket"
                  :size="20"
                />
              </div>
            </template>
          </ScreenCard>
        </div>
      </template>

      <template v-else>
        <div class="filters-bar">
          <span>{{ totalItems }} {{ totalBreakpointItems }}</span>
          <div class="search" v-if="isDesktop && !!screens.length">
            <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
          </div>
        </div>
        <div class="project-page-content">
          <transition name="fadeIn" mode="out-in">
            <LoadingScreen
              v-if="loading"
              :type="project.is_syncing ? 'syncing' : 'default'"
              :text="project.is_syncing ? 'Syncing project' : null"
            />
            <CardsGrid
              v-else-if="!!cardItems.length"
              :context="{ project }"
              :itemsType="type"
              :items="cardItems"
              :actions="actions"
              :sortBy="sortBy"
              :filteredResults="!!searchQuery"
              :totalAmount="totalLiveComponents"
              :isLoadingMore="loadingMore"
              @load-more="loadMore"
              @click-screen="navigateToScreen"
              @click-component="navigateToComponent"
            />
            <NoSearchResults v-else-if="!!searchQuery" />
            <EmptyState :type="type" v-else />
          </transition>
        </div>

        <div class="multi-select-bar-container" v-if="selection.length > 0">
          <MultiSelectBar
            :selection="selection"
            :selectionState="selection.length < cardItems.length ? 'some' : 'all'"
            type="screen"
            @check-all="selectAll"
            @uncheck="clearSelection"
            @delete="deleteSelected"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isEmpty, omit } from 'lodash-es';
import errorHandler from '@/services/errorHandler';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import ProjectHeader from '@/components/Project/Header/Header';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import CardsGrid from '@/components/CardsGrid/CardsGrid';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import EmptyState from '@/components/Project/EmptyState';
import NoSearchResults from '@/components/Team/EmptyState/NoSearchResults';
import SearchInput from '@/components/SearchInput/SearchInput';
import Tabs from '@/components/Layout/Tabs/Tabs';
import copy from '@/utils/copyToClp';
import { getRecaptchaToken } from '@/utils/recaptcha';
import { EventBus, hideBanner, openModal, showBanner, toastError, toastSuccess } from '@/services/bus';
import { getStiggFeatureLimit } from '@/services/stigg';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import { filterArrayBySearchQuery, updateArrayItemById } from '@/utils/javascript';
import { SocketMixin, UserMixin } from '@/mixins';
import MultiSelectBar from '@/components/Project/MultiSelectBar.vue';
import ScreenCard from '@/components/Project/ScreenCard.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { pollWrapper } from '@/utils/javascript';

const defaultTabLabel = 'Screens';

const emptyStateBody =
  'Use the Anima plugin to sync your design to your project and share your awesome prototypes and code with your team.';
const emptyStateTitle = 'Sync a design to your project';

export default {
  data() {
    return {
      screenActions: [
        { label: 'Copy link', onClick: this.copyScreenLink },
        { label: 'Delete', onClick: this._deleteScreen, isMarked: true }
      ],
      componentActions: [
        { label: 'Copy link', onClick: this.copyComponentLink },
        { label: 'Delete', onClick: this._deleteComponent, isMarked: true }
      ],
      type: 'screen',
      searchQuery: '',
      sortBy: 'updated',
      activeTabLabel: defaultTabLabel,
      loading: false,
      loadingMore: false,
      isPollingSyncing: false,
      showDeleteModal: false,
      emptyStateTitle,
      emptyStateBody,
      selection: [],
      cancelFigmaStatusCheck: () => {},
      isCheckingForFigmaStatus: false,
      interval: null
    };
  },
  components: {
    ProjectHeader,
    Tabs,
    LoadingScreen,
    CardsGrid,
    EmptyState,
    SearchInput,
    NoSearchResults,
    MultiSelectBar,
    PopoverMenu,
    // FigmaCard,
    ScreenCard,
    ProgressBar
  },
  mixins: [SocketMixin, UserMixin],

  created() {
    EventBus.$on('on-figma-ws-message', this.handleFigmaWebSocketMessage);
    EventBus.$on('start_figma_import_polling', this.pollFigmaStatus);
  },

  async mounted() {
    EventBus.$on('reload-project-data', this.reloadData);
    EventBus.$on('select-card-item', this.modifySelection);
    const { params, query } = this.$route;
    const projectSort = localStorage.getItem('projectSort');

    if (query.sort) {
      this.setSortBy(query.sort);
    } else if (projectSort) {
      this.setSortBy(projectSort);
    }

    if (query.platform) this.trackUserSyncData(query.platform);

    if (!params.teamSlug) {
      this.loading = true;
      const project = await this.fetchProject({ id: params.projectId });
      let { short_id: projectId, team_slug: teamSlug, team: teamId } = project;
      if (!teamSlug) {
        const { slug } = await this.fetchTeam({ id: teamId });
        teamSlug = slug;
      }
      this.$router.push({ name: 'project', params: { teamSlug, projectId } });
    }
  },
  beforeDestroy() {
    this.hideUpgradeScreensBanner();
    EventBus.$off('reload-project-data', this.reloadData);
    EventBus.$off('select-card-item', this.modifySelection);
    EventBus.$off('on-figma-ws-message', this.handleFigmaWebSocketMessage);
    EventBus.$off('start_figma_import_polling', this.pollFigmaStatus);

    this.cancelFigmaStatusCheck();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'currentItem' }),
    ...mapState('webComponents', { _components: 'items' }),
    ...mapState('webComponents', { totalComponents: 'total' }),
    ...mapState('figmaIntegration', { isFigmaSyncingMap: 'isSyncing' }),
    ...mapState('figmaIntegration', { figmaFrames: 'frames' }),
    ...mapState('figmaIntegration', { figmaImportStatus: 'figmaImportStatus' }),
    ...mapState('figmaIntegration', { figmaImportProgress: 'figmaImportProgress' }),
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      isPricingScreenFlowActiveExperiment: 'experiments/isPricingScreenFlowActive',
      screens: 'components/linkedScreens',
      totalScreens: 'components/totalScreens',
      totalScreensWithBreakpoints: 'components/totalScreensWithBreakpoints',
      totalLinkedScreens: 'components/totalLinkedScreens',
      hasNewComments: 'notifications/hasNewComments',
      onboardingSlug: 'userOnboardings/onboardingSlug',
      onboardingInProgress: 'userOnboardings/onboardingInProgress',
      screensLimit: 'teamMemberships/screensLimit',
      isPro: 'teamMemberships/isPro',
      userIntent: 'users/intent'
    }),
    isCustomDomainButtonActive() {
      return !this.userIntent || this.userIntent === 'code-based-lp-websites';
    },
    isFigmaSyncing() {
      const { projectId } = this.$route.params;
      return this.isFigmaSyncingMap[projectId];
    },
    totalLiveComponents() {
      return (this.type === 'screen' ? this.totalLinkedScreens : this.cardItems.length) || 0;
    },
    tabs() {
      const screensTab = { label: 'Screens', type: 'screen', routeName: 'project' };
      const componentsTab = { label: 'Components', type: 'component', routeName: 'project-components' };

      if (this.isFigmaSyncing) return [];

      return [screensTab, componentsTab];
    },
    cardItems() {
      let list;
      const slugs = this.screens.map((s) => s.slug);
      if (this.type === 'screen') {
        list = this.screens.map(this.mapScreens);
        this.setDefaultProject(list?.[0]);
      } else {
        list = this._components.filter((c) => {
          let p = c.instances.find((i) => i['is_primary']);
          return p && slugs.includes(p['screen_slug']) && (c['is_live'] || c['is_suggestion']);
        });
      }

      if (this.searchQuery) {
        list = filterArrayBySearchQuery(list, ['name'], this.searchQuery);
      }

      return list;
    },
    sortingOptions() {
      const options = [
        { label: 'Last updated', value: 'updated', onClick: () => this.setSortBy('updated') },
        { label: 'Date created', value: 'created', onClick: () => this.setSortBy('created') },
        { label: 'Name A-Z', value: 'name', onClick: () => this.setSortBy('name') }
      ];
      if (this.type === 'screen') {
        options.push({ label: 'Sections', value: 'sections', onClick: () => this.setSortBy('sections') });
      }
      return options;
    },
    sortByLabel() {
      const { sortBy, sortingOptions } = this;
      const option = sortingOptions.find((so) => so.value === sortBy);
      return option?.label;
    },
    isExportCodeOnboardingInProgress() {
      return this.onboardingSlug === 'export-code' && this.onboardingInProgress;
    },
    omniviewRoute() {
      return ({ slug: screenSlug, model_id: modelId }, params = {}, query = {}) => {
        const { short_id: projectId } = this.project;
        const { slug: teamSlug } = this.team;
        const { role: userRole } = this.user;
        if (userRole === 'developer') {
          return {
            name: 'omniview',
            params: { teamSlug, projectId, screenSlug, ...params },
            query: { ...query, mode: 'code', layer: modelId }
          };
        }
        return { name: 'omniview', params: { teamSlug, projectId, screenSlug, ...params }, query };
      };
    },
    webComponentRoute() {
      return (wc) => {
        const { short_id: projectId } = this.project;
        const { slug: teamSlug } = this.team;

        let screenSlug;
        let model_id = -1;

        const primaryInstance = wc.instances.find((i) => i.is_primary);
        screenSlug = primaryInstance['screen_slug'];
        model_id = primaryInstance['model_id'];

        if (!screenSlug) {
          const { live_project_release_homepage_slug: homepageSlug } = this.project;
          const correctHomepageSlug = this.screens.some((screen) => screen.slug === homepageSlug);
          const screenSlug = correctHomepageSlug ? homepageSlug : this.screens[0]?.slug;

          if (!screenSlug) {
            return toastError('Please add screens to this project');
          }
        }

        return {
          name: 'omniview',
          params: { teamSlug, projectId, screenSlug },
          query: { mode: 'code', component: model_id, layer: model_id }
        };
      };
    },
    screenParams() {
      const { sort, v: project_release_version } = this.$route.query;
      return { get_all: true, order_by: this.getSortingType(sort), project_release_version };
    },
    componentParams() {
      return { ...omit(this.screenParams, 'project_release_version') };
    },
    actions() {
      if (this.type === 'screen') {
        return this.screenActions;
      }
      return this.componentActions;
    },
    totalBreakpointItems() {
      if (this.type === 'screen' && this.totalScreensWithBreakpoints) {
        return `(${this.totalScreensWithBreakpoints} with multiple breakpoints)`;
      }
      return '';
    },
    totalItems() {
      const amount = this.type === 'screen' ? this.totalScreens : this.totalLiveComponents;
      return `${amount} total ${this.type}s`;
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      fetchProject: 'projects/fetchOne',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchProjectReleases: 'projectReleases/fetchAllOfParent',
      fetchRelease: 'releases/fetchOne',
      fetchScreens: 'components/fetchAllOfParent',
      fetchComponents: 'webComponents/fetchAllOfParent',
      deleteScreen: 'components/delete',
      deleteWebComponent: 'webComponents/delete',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchReleaseModel: 'releases/fetchReleaseModel',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      pollSyncingProject: 'projects/pollSyncingProject',
      createGuest: 'projectGuests/create',
      getTaskCallback: 'figmaIntegration/getTaskCallback',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    ...mapMutations({
      selectScreen: 'components/setCurrentItem',
      removeScreenFromStore: 'components/removeFromItems',
      setTeam: 'teams/setCurrentItem',
      setProject: 'projects/setCurrentItem',
      setDefaultProject: 'projects/setDefaultProject',
      setTeamMemberships: 'teamMemberships/setTeamMemberships',
      setSections: 'components/setSections',
      setFigmaFrames: 'figmaIntegration/setFrames',
      setIsFigmaSyncing: 'figmaIntegration/setIsSyncing',
      setFigmaImportStatus: 'figmaIntegration/setFigmaImportStatus',
      setFigmaImportProgress: 'figmaIntegration/setFigmaImportProgress',
      setIsFetchingThumbnails: 'figmaIntegration/setIsFetchingThumbnails'
    }),

    pollFigmaStatus() {
      const { projectId } = this.$route.params;
      if (this.isCheckingForFigmaStatus) return;
      this.isCheckingForFigmaStatus = true;
      const { cancel } = pollWrapper({
        request: this.checkIfFigmaImportSyncing,
        pollingPeriod: 1000,
        shouldStop: (res) => {
          const { code } = res.data;
          if (code === 'not_found' || code === 'complete' || code === 'error') {
            this.isCheckingForFigmaStatus = false;
            return true;
          }
          return false;
        },
        callback: async (res) => {
          const { code, frames } = res.data || { frames: [] };

          if (code === 'not_found' || code === 'complete') {
            await this.pollSyncingProject({ id: projectId });
            this.setIsFigmaSyncing({
              value: false,
              projectId
            });
          }

          if (code === 'pending') {
            if (frames.length > 0) {
              const figmaFrames = frames
                .filter(Boolean)
                // .map(frame => ({ ...frame, thumb_url: frame.thumbnailUrl, isSyncing: true }));
                .map((frame) => ({
                  ...frame,
                  thumb_url: frame.thumbnailUrl,
                  isSyncing: true
                }));

              let map = {};
              this.figmaFrames.map((f) => {
                map[f.id] = f;
              });

              this.setFigmaFrames(
                figmaFrames.map((f) => ({ ...f, thumb_url: map[f.id]['thumb_url'] || map[f.id]['thumbnailUrl'] }))
              );
              this.setIsFigmaSyncing({
                value: true,
                projectId
              });
            }
          }

          if (code === 'error') {
            this.setIsFigmaSyncing({
              value: false,
              projectId
            });
          }
        }
      });
      this.cancelFigmaStatusCheck = () => {
        cancel();
        this.isCheckingForFigmaStatus = false;
      };
    },

    async handleFigmaWebSocketMessage(e) {
      const { projectId } = this.$route.params;
      if (!e.eventType) return;
      const data = JSON.parse(e.data);
      EventBus.$emit('on-figma-ws-message-received', e);
      switch (e.eventType) {
        case 'get_thumbnails_end':
          {
            const { thumbnails, pageId } = data || {};

            if (thumbnails && pageId) {
              this.setFigmaFrames(
                this.figmaFrames.map((f) => ({ ...f, thumb_url: thumbnails[f.id] ? thumbnails[f.id] : f.thumb_url }))
              );

              this.setIsFetchingThumbnails({
                value: false,
                pageId
              });
            }
          }

          break;

        case 'generate_file_start':
          {
            this.setFigmaImportProgress(0.15);
            this.setFigmaImportStatus('Building screens');
          }

          break;
        case 'build_assets_start':
          {
            this.setFigmaImportProgress(0.5);
            this.setFigmaImportStatus('Exporting assets');
          }

          break;

        case 'upload_assets_start':
          {
            this.setFigmaImportProgress(0.6);
            this.setFigmaImportStatus('Uploading assets');
          }

          break;
        case 'upload_assets_end':
          {
            this.setFigmaImportProgress(0.8);
            this.setFigmaImportStatus('Syncing release');
          }

          break;
        case 'build_child_done':
          {
            const { frameId } = data;
            const updatedFrames = updateArrayItemById(this.figmaFrames, frameId, { isSyncing: false }, 'id');
            this.setFigmaFrames(updatedFrames);
          }

          break;
        case 'import_end':
          {
            // const { frameId } = data;
            this.loading = true;
            try {
              await this.pollSyncingProject({ id: projectId, waitBeforeRun: true });
              this.setFigmaImportProgress(0.9);
              this.setFigmaImportStatus('Finishing things up');
              // await this.reloadData();
              await Promise.all([
                this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, skipCache: true }),
                this.fetchComponents({
                  parent: 'projects',
                  id: projectId,
                  params: this.componentParams,
                  skipCache: true
                })
              ]);
              this.setIsFigmaSyncing({
                value: false,
                projectId
              });
              this.$trackEvent('figma-file-import.sync-file.success');
              this.finishOnboardingSyncStage();
            } finally {
              this.loading = false;
            }
          }

          break;

        default:
          break;
      }
    },
    finishOnboardingSyncStage() {
      const { slug: teamSlug } = this.team;
      const { live_project_release_homepage_slug: homepageSlug, short_id: projectId } = this.project;
      const correctHomepageSlug = this.screens.some((screen) => screen.slug === homepageSlug);
      const screenSlug = correctHomepageSlug ? homepageSlug : this.screens[0]?.slug;

      const stagePayload = {
        action: 'navigate',
        data: { routeParams: { teamSlug, projectId, screenSlug } }
      };

      this.nextOnboardingStage({ currentStageSlug: 'sync-design', stagePayload });
    },
    reloadData() {
      return this.fetchData({ skipCache: true });
    },
    getSortingType(sortBy) {
      const fallbackSort = localStorage.getItem('projectSort');
      const sortMap = {
        updated: '-updated_at',
        created: '-created_at',
        name: 'name',
        sections: 'sections'
      };
      return sortMap[sortBy] || sortMap[fallbackSort] || sortMap.updated;
    },
    onTabChange(tab) {
      this.activeTabLabel = tab.label;
      this.type = tab.type;
      this.$router.replace({ name: tab.routeName }, () => {});
    },
    async fetchData({ skipCache = false, indicateLoading = true } = {}) {
      const { teamSlug, projectId } = this.$route.params;
      const { v, invite } = this.$route.query;
      const parent = 'projects';

      this.loading = indicateLoading;
      const transaction = this.$sentry.startTransaction({ name: 'project-page-loading' });

      try {
        // if user gets directly to project page, load team information, cannot be async as it contains
        // the number of components.
        await this.loadTeamInformation(teamSlug);

        // also load project guests I guess.
        this.loadGuests({ projectId, skipCache });

        // SYNCHRONOUS REQUESTS:
        // - project
        // - if project is syncing - poll request the project until is not syncing anymore.
        // - project components (screens)

        await this.fetchProjectIfHasAccess({ projectId, skipCache });

        if (invite) {
          this.handleInvite(invite, projectId);
        }

        if (isEmpty(this.project) || this.project.is_syncing) {
          await this.pollSyncingProject({ id: projectId }).catch(() => {
            this.$trackEvent('project.spinner-stuck');
          });
        }

        // now that the project is done syncing, fetch components.
        await Promise.all([
          this.fetchScreens({ parent, id: projectId, params: this.screenParams, skipCache }),
          this.fetchComponents({ parent, id: projectId, params: this.componentParams, skipCache })
        ]);

        this.reachedScreensLimit().then((reached) => {
          if (reached) {
            this.showUpgradeScreensBanner();
          } else {
            this.hideUpgradeScreensBanner();
          }
        });

        // if the user sorted by section in the old app change the sorting to sections
        const sortCheck = localStorage.getItem('sectionsSortCheck');
        if (!sortCheck && this.project.sections_sort.sections && this.project.sections_sort.sections.length > 0) {
          this.setSortBy('sections');
          localStorage.setItem('sectionsSortCheck', 'true');
        }
        // set sections
        this.setSections(this.project.sections_sort.sections || []);

        // rest of the data can be fetched asynchronously.
        // Will not throw an error in case of failure!
        this.fetchLiveProjectRelease({ version: v, skipCache });
        this.fetchCustomDomains({ parent, id: projectId, skipCache });
        this.fetchProjectReleases({ parent, id: projectId, skipCache });
      } catch (error) {
        errorHandler.captureException(error);
      } finally {
        this.loading = false;
        transaction.finish();
      }
    },
    async reachedScreensLimit() {
      const { isPro, screens, team } = this;
      try {
        let screensLimit;
        if (this.team.uses_stigg_integration) {
          screensLimit = await getStiggFeatureLimit(this.team.id, 'feature-screen');
        } else {
          screensLimit = await this.screensLimit;
        }
        if (isPro && team.projects_components_count <= screensLimit) {
          return false;
        }
        const isExceededScreens = team.projects_components_count > screensLimit;
        const hasLockedScreen = screensLimit && screens.some((screen) => screen.is_locked);
        return isExceededScreens || hasLockedScreen;
      } catch (err) {
        return false;
      }
    },

    async checkIfFigmaImportSyncing() {
      return new Promise((resolve, reject) => {
        const { projectId } = this.$route.params;
        this.getTaskCallback({
          payload: { taskId: projectId, taskDetails: true }
        })
          .then(resolve)
          .catch(reject);
      });
    },
    async loadMore() {
      try {
        const { projectId } = this.$route.params;
        this.loadingMore = true;
        this.$trackEvent('project-page.load-more.click');
        if (this.type === 'screen') {
          await this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, nextPage: true });
        } else {
          await this.fetchComponents({
            parent: 'projects',
            id: projectId,
            params: this.componentParams,
            nextPage: true
          });
        }
      } catch (err) {
        toastError('Failed loading more screens :(');
      } finally {
        this.loadingMore = false;
      }
    },
    async fetchProjectIfHasAccess({ projectId, skipCache } = {}) {
      try {
        await this.fetchProject({ id: projectId, skipCache });
      } catch (err) {
        const { response = {} } = err;
        if (response.status === 403) {
          openModal({ name: 'project-request-access', onCloseRedirect: '/' });
        }
        throw err;
      }
    },
    async fetchLiveProjectRelease({ version: revision_number, skipCache = false }) {
      const { live_project_release, short_id } = this.project;
      if (revision_number) {
        const params = { revision_number };
        const { results: projectReleases } = await this.fetchProjectReleases({
          parent: 'projects',
          id: short_id,
          params,
          skipCache
        });

        const { release } = projectReleases[0] ?? {};
        await this.fetchRelease({ id: release });
      } else if (live_project_release) {
        const { release } = await this.fetchProjectRelease({ id: live_project_release, skipCache });
        await this.fetchRelease({ id: release });
      }
      if (this.currentRelease.id) {
        this.fetchReleaseModel();
      }
    },
    loadGuests({ projectId, skipCache = true } = {}) {
      return this.fetchProjectGuests({
        parent: 'projects',
        id: projectId,
        params: { page_size: 200, order_by: 'created_at' },
        skipCache
      });
    },
    async handleInvite(inviteEmail, projectId) {
      const email = normalizeEmail(inviteEmail);
      try {
        this.$trackEvent('project.invite-guest-from-email');

        const { results: guests } = await this.loadGuests({ projectId, skipCache: false });
        const guestAlreadyExists = !!guests.find((g) => normalizeEmail(g.email) === email);

        if (guestAlreadyExists) {
          toastError(`It seems ${email} is already a guest in this project...`);
        } else if (isValidEmail(email)) {
          const newGuest = { email, access_level: 'viewer' };
          const captchaToken = await getRecaptchaToken('invite_email');
          newGuest.captcha_token = captchaToken;

          await this.createGuest({ parent: 'projects', id: projectId, payload: newGuest });
          this.$trackEvent('project.invite-guest-from-email.success');
          toastSuccess(`Successfully added ${email} as a guest on ${this.project.name}`);

          this.$router.replace({ query: {} });
          await this.loadGuests({ projectId, skipCache: true });
        }
      } catch (err) {
        errorHandler.captureExceptionAndTrack(err, { name: 'project.invite-guest-from-email.failure' });

        toastError(`Oh no! We failed inviting ${email} as a guest!`);
      }
    },
    async loadTeamInformation(teamSlug) {
      const teamParams = { is_slug: true };

      await Promise.allSettled([
        this.fetchTeam({ id: teamSlug, params: teamParams, skipCache: true }),
        this.fetchTeamMemberships({ id: teamSlug, params: teamParams })
      ]).then(([team, memberships]) => {
        if (team.status === 'rejected') {
          this.setTeam({});
        }
        if (memberships.status === 'rejected') {
          this.setTeamMemberships();
        }
      });
    },
    showUpgradeScreensBanner() {
      showBanner({
        name: 'screen-limit-reached-banner',
        onClose: () => {
          this.$trackEvent('webapp:screen_limit_reached_sales_banner.dismissed', {
            plan_id: this.teamMemberships.team_plan,
            plan_screens: this.screensLimit,
            number_of_screens: this.team.projects_components_count
          });
        }
      });
    },
    hideUpgradeScreensBanner() {
      hideBanner({ name: 'screen-limit-reached-banner', onClose: () => {} });
    },
    getThumbUrl(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      const thumb = thumbnails_urls && thumbnails_urls['640x640'];
      return thumb || thumb_url || thumbnail_url;
    },
    navigateToScreen(component) {
      let breakpoint;
      this.$trackEvent('screen-card.thumbnail.click');
      this.selectScreen(component);
      if (component.linkedScreens?.length) breakpoint = 'res';
      this.$router.push(this.omniviewRoute(component, { breakpoint }));
    },
    navigateToComponent(component) {
      this.$trackEvent('component-card.thumbnail.click');
      this.$router.push(this.webComponentRoute(component));
    },
    async copyScreenLink(component) {
      this.$trackEvent('screen-card.quick-action-copy-link.click');

      const resolved = this.$router.resolve(this.omniviewRoute(component));
      const link = `${process.env.APP_BASE_URL}${resolved.href}`;
      copy(link);

      toastSuccess('Linked copied.');
    },
    async copyComponentLink(component) {
      this.$trackEvent('component-card.quick-action-copy-link.click');

      const resolved = this.$router.resolve(this.webComponentRoute(component));
      const link = `${process.env.APP_BASE_URL}${resolved.href}`;
      copy(link);

      toastSuccess('Linked copied.');
    },
    async _deleteScreen(screen) {
      const { projectId } = this.$route.params;
      const { id } = screen;

      this.$trackEvent('screen-card.quick-action-delete.click', {
        deletedScreens: 1,
        screenCount: this.team.projects_components_count
      });

      try {
        this.loading = true;
        await this.deleteScreen({ id });
        this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, skipCache: true });
        setTimeout(() => this.fetchData({ skipCache: true, indicateLoading: false }), 500);
      } catch (err) {
        toastError('Failed deleting screen');
      } finally {
        this.loading = false;
      }
    },
    async _deleteComponent(component) {
      const { projectId } = this.$route.params;
      const { id } = component;

      this.$trackEvent('component-card.quick-action-delete.click');

      try {
        this.loading = true;
        await this.deleteWebComponent({ id });
        this.fetchComponents({ parent: 'projects', id: projectId, params: this.componentParams, skipCache: true });
      } catch (err) {
        toastError('Failed deleting component');
      } finally {
        this.loading = false;
      }
    },
    setSortBy(value) {
      const sortingOption = this.sortingOptions.find((s) => s.value === value?.toLowerCase());
      if (sortingOption) {
        this.sortBy = sortingOption.value;
      } else {
        this.sortBy = 'created';
      }
    },
    toggleSearchOpen(value) {
      if (value) {
        this.$trackEvent('project-page.search.open');
      }
    },
    isSelected(item) {
      return this.selection.includes(item.id);
    },
    modifySelection(item) {
      const selectionSet = new Set(this.selection || []);
      if (this.isSelected(item)) {
        selectionSet.delete(item.id);
      } else {
        const linkedScreens = item.linkedScreens || [];
        const ids = [item.id, ...linkedScreens.map((ls) => ls.id)];
        if (this.selection.length === 0) {
          this.$trackEvent('project-page.multi-select-bar.display');
        }
        ids.forEach((id) => selectionSet.add(id));
      }
      this.selection = Array.from(selectionSet);
    },
    selectAll() {
      this.$trackEvent('project-page.multi-select-bar-select-all.click');
      this.selection = this.cardItems.flatMap((item) => {
        return [item.id, ...item.linkedScreens?.map((ls) => ls.id)];
      });
    },
    clearSelection() {
      this.selection = [];
    },
    async deleteSelected() {
      try {
        this.loading = true;
        this.$trackEvent('project-page.multi-select-delete.click');
        const promises = this.selection.map(async (id) => {
          await this.deleteScreen({ id });
          this.removeScreenFromStore({ id });
        });
        await Promise.all(promises);
        this.$trackEvent('project-page.multi-select-delete.success', {
          deletedScreens: this.selection.length,
          screenCount: this.team.projects_components_count
        });
      } catch (err) {
        this.$trackEvent('project-page.multi-select-delete.failure', { message: err.message });
        toastError('Failed to delete some screens');
        errorHandler.captureException(err);
      } finally {
        this.loading = false;
        this.clearSelection();
        setTimeout(() => this.fetchData({ skipCache: true, indicateLoading: false }), 500);
      }
    },
    onProjectChange() {
      this.setSocket();
    },
    setSocket() {
      this.openSocket(this.project?.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'project', action: 'updated' }, this.setProject);
      this.socket.on({ resource: 'project', action: 'sync' }, () => {
        this.fetchScreens({
          parent: 'projects',
          id: this.project?.id,
          params: this.screenParams,
          skipCache: true
        });
      });
      this.socket.on({ resource: 'component', action: 'updated' }, () => {
        this.fetchScreens({
          parent: 'projects',
          id: this.project?.id,
          params: this.screenParams,
          skipCache: true
        });
      });
    },
    onRouteChange() {
      const { name } = this.$route;
      const activeTab = this.tabs.find((t) => t.routeName === name);
      if (activeTab) {
        this.onTabChange(activeTab);
        this.setSortBy(this.sortBy);
        this.fetchData();
      }
    },
    mapScreens(screen) {
      if (screen.linkedScreens?.length) {
        screen.linkedScreens = screen.linkedScreens.map(this.mapScreens);
      }
      return {
        ...screen,
        thumb_url: this.getThumbUrl(screen),
        isSelected: this.isSelected(screen),
        flags: {
          hasNewComments: this.hasNewComments({ id: screen.id, type: 'component' })
        }
      };
    },
    connectYourDomain() {
      this.$trackEvent('project-page.connect-your-domain.click', {
        user: this.user?.id,
        team: this.team?.id,
        project: this.project?.id
      });
      this.$router.push({ name: 'project-settings-public-link' });
    }
  },
  watch: {
    $route: {
      handler: 'onRouteChange',
      immediate: true
    },
    sortBy(newValue) {
      localStorage.setItem('projectSort', newValue);
      const query = { ...this.$route.query, sort: newValue };
      this.$router.replace({ query }, () => {});
    },
    project: {
      handler: 'onProjectChange',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_navigationBar.scss';
.project-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.filters-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  @include desktop {
    padding: 0 10px;
    margin: 30px 0 40px;
  }
  @include mobile {
    padding: 0 20px;
  }
  .search {
    margin-left: 30px;
  }
}
.project-page-content {
  @include mobile {
    padding: 20px;
  }
}
.screens {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
}
.screens > .screen {
  margin: 16px;
}
.sort-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.multi-select-bar-container {
  position: absolute;
  width: 450px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.figma-grid {
  margin-top: 30px;
  // display: grid;
  // grid-gap: 32px 32px;
  // user-select: none;
  // width: 100%;
  // box-sizing: border-box;
  // flex-flow: row wrap;
  // align-content: stretch;
  // cursor: default;
  // grid-template-columns: repeat(auto-fill, 260px);

  display: grid;
  justify-content: start;
  gap: 40px 30px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.progress-bar-container {
  width: 250px;
  flex-direction: column;
  padding: 40px 0;
  width: 100%;
  .progress-bar-text {
    margin-top: 20px;
    font-family: 'Roslindale Deck';
    opacity: 0.4;
    // opacity: 0;
  }
}

.wrench {
  transform-origin: center;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-name: shake;
}

@keyframes wrench {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shake {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-2px, -2px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.connect-your-domain-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px 10px 0;

  .connect-your-domain-button {
    @media (min-width: 768px) {
      &--mobile {
        display: none;
      }
    }
  }
}
.connect-your-domain-button--desktop {
  display: none !important;

  @media (min-width: 768px) {
    display: inline-flex !important;
    margin-right: 20px;
  }
}
</style>

import { createStoreModule } from '../utils';
import { poll } from '@/utils/javascript';

export default createStoreModule({
  name: 'project_release',
  modelName: 'project_releases',
  crud: true,
  endpoint: '/v2/project_releases',
  actions: {
    pollProcessingCodeParamsProjectRelease(
      { dispatch },
      { id, interval = 1000, maxAttempts = 100, waitBeforeRun = false, storeResult = true } = {}
    ) {
      return poll({
        fn: () => dispatch('fetchOne', { id, skipCache: true, storeResult }),
        validate: (projectRelease) => projectRelease?.codesandbox_params_file_status !== 'processing',
        interval,
        maxAttempts,
        waitBeforeRun
      });
    },
    pollProcessingReactProjectRelease(
      { dispatch },
      { id, interval = 1000, maxAttempts = 100, waitBeforeRun = false, storeResult = true } = {}
    ) {
      return poll({
        fn: () => dispatch('fetchOne', { id, skipCache: true, storeResult }),
        validate: (projectRelease) =>
          projectRelease?.react_preview_status !== undefined && projectRelease?.react_preview_status !== 'processing',
        interval,
        maxAttempts,
        waitBeforeRun
      });
    },
    pollProcessingMergedModelProjectRelease(
      { dispatch },
      { id, interval = 1000, maxAttempts = 300, waitBeforeRun = false, storeResult = true } = {}
    ) {
      return poll({
        fn: () => dispatch('fetchOne', { id, skipCache: true, storeResult }),
        validate: (projectRelease) => projectRelease?.model_url !== '',
        interval,
        maxAttempts,
        waitBeforeRun
      });
    }
  }
});

<template>
  <div class="guest-row" @mouseover="hover = true" @mouseleave="hover = false" data-cy="member-row">
    <div :class="{ 'is-deleted': isDeleted }"><an-user-avatar :user="avatarUser" />{{ guest.email }}</div>
    <div class="cta" data-cy="member-row-action">
      <div @click="$emit('remove')">
        <an-link v-if="hover" variant="primary" data-cy="remove-member">Remove</an-link>
        <svg-icon v-else name="circle-check" :size="36" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false
    };
  },
  props: {
    guest: {
      type: Object,
      required: true
    },
    isDeleted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    avatarUser() {
      const { guest } = this;
      if (guest.user?.email) {
        return guest.user;
      }
      const { email } = guest;
      return { email };
    }
  }
};
</script>

<style lang="scss" scoped>
.guest-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 12px;
  border-bottom: var(--border);
  div {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 16px;
    }
  }
  .cta {
    cursor: pointer;
    height: 36px;
  }
}
.is-deleted {
  opacity: 0.3;
}
</style>

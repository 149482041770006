<template>
  <div
    :class="['master-card-container', { spread: hover }]"
    @mouseover.prevent="hover = true"
    @mouseout.prevent="hover = false"
  >
    <ScreenCard
      v-for="linkedScreen in linkedScreens"
      :key="linkedScreen.id"
      :locked="shouldLock(index, screen)"
      :screen="linkedScreen"
      :selected="linkedScreen.isSelected"
      @click-thumb="$emit('click', $event)"
      @mouseover="hover = true"
      @mouseout="hover = false"
      linked-screen
      class="linked-screen"
    />
    <ScreenCard
      :screen="screen"
      :locked="shouldLock(index, screen)"
      :actions="actions"
      :flags="flags"
      :selected="selected"
      @mouseover="hover = true"
      @mouseout="hover = false"
      @click-thumb="$emit('click', $event)"
      class="main-screen"
    />
  </div>
</template>

<script>
import ScreenCard from '@/components/Project/ScreenCard.vue';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      hover: false
    };
  },
  props: {
    context: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    screen: {
      type: Object,
      required: true
    },
    actions: {
      type: Array
    },
    flags: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ScreenCard
  },
  computed: {
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      isFree: 'teamMemberships/isFree'
    }),
    linkedScreens() {
      return this.screen.linkedScreens?.slice(0, 4) || [];
    }
  },
  methods: {
    shouldLock(index, screen) {
      return screen.is_locked;
    }
  }
};
</script>

<style lang="scss" scoped>
$max-cards: 3;
.master-card-container {
  display: flex;
  min-height: 240px;
  max-height: 256px;
  max-width: 250px;
  // stack cards: the margin should be -5px per card
  @for $i from 0 to $max-cards {
    $margin-value: (5px * ($max-cards - ($i + 1)));
    .linked-screen:nth-child(#{$i + 1}) {
      margin-bottom: -$margin-value;
    }
  }
  .screen-card {
    width: 100%;
  }
  .screen-card + .screen-card {
    margin-left: -100%;
    transition: all 0.2s ease;
  }
  &.spread {
    z-index: 1;
    .screen-card {
      margin-bottom: 0;
      width: 100%;
      min-width: 100%;
    }
    .screen-card + .screen-card {
      margin-left: -175px;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-card-container",class:_vm.cardClass,attrs:{"data-cy":"list-card"}},[_c('div',{staticClass:"list-card"},[_c('div',{staticClass:"thumbnail",style:(_vm.thumbStyle),on:{"click":function($event){return _vm.$emit('click')},"mouseover":function($event){_vm.hoverThumb = true},"mouseout":function($event){_vm.hoverThumb = false}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDesktop || _vm.isLocked),expression:"isDesktop || isLocked"}],class:_vm.actionsClasses},[_c('div',{staticClass:"popover-menu-wrapper"},[(_vm.actions && _vm.actions.length)?_c('PopoverMenu',{attrs:{"items":_vm.popoverActions,"position":"right"}},[_c('div',{attrs:{"slot":"reference","data-cy":"project-cards-options"},slot:"reference"},[_c('svg-icon',{staticStyle:{"color":"#fff"},attrs:{"fill":"currentColor","name":"three-dots-horizontal","size":30}})],1)]):_vm._e()],1),_c('div',{staticClass:"centered-content"},[(_vm.isLocked)?_c('svg-icon',{attrs:{"name":"lock","size":30}}):_c('Members',{attrs:{"members":_vm.members,"peekAmount":4,"size":30,"avatarStyle":_vm.avatarStyle}})],1),_c('span',[_vm._v(_vm._s(_vm.thumbnailText))])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.project.name))]),_c('div',{staticClass:"subtitle"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('span',{key:`hover-subtitle-${_vm.hoverSubtitle}`,on:{"mouseover":function($event){_vm.hoverSubtitle = !(_vm.subtitle || '').endsWith('ago')},"mouseleave":function($event){_vm.hoverSubtitle = false}}},[_vm._v(" "+_vm._s(_vm.sortingText)+" ")])]),_c('div',{staticClass:"icons"},[(_vm.flags.domain || _vm.flags.hasNewComments || _vm.flags.views)?[(_vm.flags.domain)?_c('svg-icon',{directives:[{name:"tip",rawName:"v-tip",value:({
                content: 'Connected to domain',
                placement: 'bottom-end'
              }),expression:"{\n                content: 'Connected to domain',\n                placement: 'bottom-end'\n              }"}],attrs:{"name":"rocket-full","size":24}}):_vm._e(),(_vm.flags.hasNewComments)?_c('svg-icon',{attrs:{"name":"talking-bubble-alert","size":24}}):_vm._e(),(_vm.flags.views)?_c('svg-icon',{attrs:{"name":"eye","size":24}}):_vm._e()]:(_vm.isDesignFileActive)?[(_vm.project.figma_design_url)?_c('div',{directives:[{name:"tip",rawName:"v-tip",value:({
                content: 'Get Figma file',
                placement: 'bottom-end'
              }),expression:"{\n                content: 'Get Figma file',\n                placement: 'bottom-end'\n              }"}],staticClass:"design-url-icon",on:{"click":function($event){return _vm.onClickDesignToolIcon('figma')}}},[_c('svg-icon',{attrs:{"name":"project-figma-design","size":30}})],1):_vm._e(),(_vm.project.xd_design_url)?_c('div',{directives:[{name:"tip",rawName:"v-tip",value:({
                content: 'Get Adobe Xd file',
                placement: 'bottom-end'
              }),expression:"{\n                content: 'Get Adobe Xd file',\n                placement: 'bottom-end'\n              }"}],staticClass:"design-url-icon",on:{"click":function($event){return _vm.onClickDesignToolIcon('xd')}}},[_c('svg-icon',{attrs:{"name":"project-xd-design","size":30}})],1):_vm._e()]:_vm._e()],2)],1)])]),(_vm.project.inviter)?_c('div',{staticClass:"inviter"},[_c('div',[_c('div',{staticClass:"avatar"},[_c('an-user-avatar',{attrs:{"user":_vm.project.inviter,"size":30}})],1),_c('div',{staticClass:"shared-by"},[_vm._v("Shared by "+_vm._s(_vm.inviterDetails))])]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.invitationDate))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/ashamed-man.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="body">
      <an-input
        data-cy="create-project-input"
        v-model="projectName"
        placeholder="Name your new project"
        @keyup.enter="create"
        focus
      />
    </div>
    <div class="actions" v-if="projectName">
      <an-button data-cy="create-project-confirm" @click="create" :isWorking="isWorking">Create project</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { EventBus, toastError } from '@/services/bus';
import { mapActions, mapState } from 'vuex';
import { TeamMixin } from '@/mixins';

export default {
  name: 'new-project',
  data() {
    return {
      title: 'Create a new project',
      projectName: '',
      isWorking: false
    };
  },
  mixins: [TeamMixin],
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { totalProjects: 'total' }),
    ctaData() {
      return {
        label: 'Create',
        loading: this.isWorking
      };
    }
  },
  methods: {
    ...mapActions({
      createProject: 'projects/create',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    async create() {
      const {
        id,
        projects_limit_count: projectsLimitCount,
        active_projects_count,
        uses_stigg_integration: usesStiggIntegration
      } = this.team;
      const hasProjectsLimit = projectsLimitCount !== -1;

      if (!this.projectName || !this.projectName.trim()) {
        return toastError('Please provide a name');
      }

      this.$trackEvent('new-project.save-button.click', { active_projects_count: active_projects_count });

      try {
        this.isWorking = true;
        const { short_id: projectId } = await this.createProject({
          parent: 'teams',
          id,
          payload: { name: this.projectName, is_default_project: false },
          storeResult: true
        });

        EventBus.$emit('update-projects', { skipCache: true });
        EventBus.$emit('reload-team-info');
        this.nextOnboardingStage({ currentStageSlug: 'create-project' });

        this.$trackEvent('new-project.save.success', { active_projects_count: active_projects_count + 1 });

        if (hasProjectsLimit && active_projects_count > projectsLimitCount) {
          this.$trackEvent('new-project.user_has_more_active_projects_than_allowed');
        }
        if (hasProjectsLimit && active_projects_count >= projectsLimitCount && !usesStiggIntegration) {
          this.$emit('close');
          this.openUpgradeLockedModal();
          this.$trackEvent('new-project.locked-project-paywall.show');
        } else {
          this.$emit('close', { redirect: { name: 'project', params: { projectId } } });
        }
      } catch (err) {
        this.$trackEvent('new-project.save.failure', { message: err.message });
        toastError('Failed creating new project');
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.body {
  width: 400px;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="_width" :height="_height" :aria-labelledby="name" role="presentation">
    <title :id="name" lang="en">{{ name }} icon</title>
    <g :fill="color">
      <component :is="relevantIconComponent" />
    </g>
  </svg>
</template>

<script>
import iconMap from './Icons';
export default {
  name: 'an-icon',
  props: {
    name: {
      type: String,
      default: 'box'
    },
    size: {
      type: [Number, String],
      default: 18
    },
    width: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  },
  computed: {
    _width() {
      return this.width || this.size;
    },
    _height() {
      return this.height || this.size;
    },
    relevantIconComponent() {
      return iconMap[this.name];
    }
  }
};
</script>

import dayjs from 'dayjs';
import { get, maxBy } from 'lodash-es';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import errorHandler from '@/services/errorHandler';

const mapPlanByPrice = {
  free: 0,
  prototype: 1,
  basic: 1,
  pro: 2,
  team: 3,
  business: 3
};

export default {
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapGetters('teamMemberships', ['userTeams'])
  },
  methods: {
    ...mapActions({ updateUser: 'users/update', getDefaultTeamSlug: 'users/getDefaultTeamSlug' }),
    ...mapMutations({ setUser: 'users/setCurrentItem' }),
    async checkForDefaultTeam() {
      try {
        const defaultTeamSlug = await this.getDefaultTeamSlug();
        const memberOfDefaultTeam = this.userTeams?.find((team) => team.slug === defaultTeamSlug);
        if (!memberOfDefaultTeam) {
          const team = this.userTeams?.find((team) => team.role === 'owner');

          if (!team) {
            return;
          }

          const payload = { default_team_slug: team.slug };
          this.$trackEvent('webapp.missing-default-team-slug.update', { from: defaultTeamSlug, to: team.slug });

          await this.updateUser({ id: 'me', payload });
        }
      } catch (err) {
        errorHandler.captureExceptionAndTrack(err, {
          name: 'webapp.missing-default-team-slug.failure',
          data: { err: err.message }
        });
      }
    },
    async setMarketingActiveUser(type = 'developer') {
      if (!['developer', 'designer'].includes(type)) return;

      const key = `is_marketing_active_${type}`;
      const payload = { [key]: true };

      if (get(this.currentUser, key, true)) return;

      this.setUser({ ...this.currentUser, ...payload });
      return this.updateUser({ id: 'me', payload });
    },
    isActiveUserByRole() {
      const role = this.currentUser?.role?.toLowerCase();
      let activeUser = false;
      if (/designer/.test(role)) {
        activeUser = this.currentUser?.is_marketing_active_designer;
      } else if (/developer/.test(role)) {
        activeUser = this.currentUser?.is_marketing_active_developer;
      }
      return !!activeUser;
    },
    trackUserTeamsData() {
      const lsKey = 'teamDetailsReport';
      const lastReported = dayjs(localStorage.getItem(lsKey) || null);
      // if has been reported in the last 12 hours - don't report again.
      if (dayjs().diff(lastReported, 'h') < 12) return;

      try {
        const numberOfPayingTeams = this.userTeams?.filter(({ plan }) => plan && plan !== 'Free').length || 0;
        const highestAmountOfMembers = maxBy(this.userTeams, 'totalMembers')?.totalMembers || 0;
        const mostExpensivePlan = maxBy(this.userTeams, ({ plan }) => mapPlanByPrice[plan?.toLowerCase()] || 0)?.plan;

        this.$gtm.trackEvent({
          event: 'team_details',
          event_category: `${numberOfPayingTeams} Paying Teams`,
          event_action: `Largest Team Size: ${highestAmountOfMembers}`,
          event_label: mostExpensivePlan
        });

        // save report time
        localStorage.setItem(lsKey, dayjs().toString());
      } catch (error) {
        errorHandler.captureException(error);
      }
    },
    trackUserSyncData(platform) {
      const lsKey = 'hasSynced';
      const hasSynced = localStorage.getItem(lsKey);
      const event = `sync_${hasSynced ? 'not_' : ''}first_prototype`;
      const category = `Sync ${hasSynced ? 'Not ' : ''}First Prototype`;

      try {
        this.$gtm.trackEvent({
          event,
          event_category: category,
          event_action: this.currentUser?.role,
          event_label: platform || this.currentUser?.latest_paired_design_tool
        });

        // save report time
        localStorage.setItem(lsKey, true);
      } catch (error) {
        errorHandler.captureException(error);
      }
    }
  }
};

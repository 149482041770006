// implementation according to https://stackoverflow.com/a/38963456
export const detectAdBlock = async () => {
  let adBlockEnabled = false;
  const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  try {
    await fetch(new Request(googleAdUrl)).catch(() => (adBlockEnabled = true));
  } catch (e) {
    adBlockEnabled = true;
  }
  return adBlockEnabled;
};

<template>
  <v-tip :trigger="trigger" ref="tooltipBig" offset="10" :delay="{ show: 1000, hide: 0 }" :open="open">
    <slot name="popover-caller"></slot>

    <template slot="popover">
      <div class="tooltip-big" @click="$emit('close')">
        <div v-if="showBanner" class="tooltip-big-banner">
          <img :src="require('@/assets/svg/tooltip-new-banner.svg')" />
        </div>
        <div class="tooltip-big-title">
          <slot name="popover-title"></slot>
        </div>
        <div class="tooltip-big-text">
          <slot name="popover-text"></slot>
        </div>
        <div class="tooltip-big-footer">
          <slot name="popover-footer"></slot>
        </div>
      </div>
    </template>
  </v-tip>
</template>

<script>
export default {
  name: 'an-tip-big',
  props: {
    showBanner: {
      type: Boolean
    },
    open: {
      type: Boolean
    },
    trigger: {
      type: String,
      default: 'hover'
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-big {
  background: #333333;
  border-radius: 10px;
  color: white;
  padding: 40px 35px 25px 20px;
  max-height: 275px;
  max-width: 329px;

  &-banner {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-title {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 15px;
  }

  &-text {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.4;
  }

  &-footer {
    padding-top: 20px;
  }
}
</style>

/*
Functions that hook into the vuex store and provide a more readable interface and enable reusability of code instead of repeating the same code in different components
or the usage of mixins which is not recommended

Notice that the hooks are not reactive, they are just functions that return computed properties or functions that dispatch actions or commit mutations, and should only be used inside the setup function of a component

TODO: organize the hooks into different files
*/

import { useStore } from '@/store';
import { computed, ref } from 'vue';
import { has, get, isEqual } from 'lodash-es';
import { getParameters } from 'codesandbox-import-utils/lib/api/define';
import { trackEvent } from '@/services/tracking';
import errorHandler from '@/services/errorHandler';
import { waitFor, objectMap, downloadFile, poll, sleep } from '@/utils/javascript';
import { openModal, toastError } from '@/services/bus';
import { uuid } from '@/utils/uuid';
import { codegenCleanCode } from '@/services/codegen/cleanCode';
import axios from 'axios';
import { findNodeWorker } from '@/components/OmniView/workerFunctions';
import SWorker from 'simple-web-worker';
import { API_BASE_URL, CODEGEN_URL } from '@/api';
import { codegenCache } from '@/services/idb/codegenCache';
import auth from '@/auth';
import md5 from 'object-hash';

export const openUpgradeModal = (config) =>
  openModal({ name: 'upgrade-team', variant: 'center', width: 600, closeButton: true, ...config });

export const useProject = () => {
  const { state, commit, dispatch } = useStore();

  const currentProject = computed(() => state.projects.currentItem);

  const setCurrentProject = (payload) => commit('projects/setCurrentItem', payload);
  const fetchProject = (payload) => dispatch('projects/fetchOne', payload);
  const updateProject = (payload) => dispatch('projects/update', payload);

  return {
    currentProject,
    setCurrentProject,
    fetchProject,
    updateProject
  };
};

export const useUser = () => {
  const { state, getters, dispatch } = useStore();

  const currentUser = computed(() => state.users.currentItem);
  const isAnimaUser = computed(() => getters['users/isAnimaUser']);
  const fetchUser = (payload) => dispatch('users/fetchOne', payload);

  return {
    currentUser,
    isAnimaUser,
    fetchUser
  };
};

export const useLoading = () => {
  const { state, commit } = useStore();
  const { currentProject } = useProject();
  const { currentRelease } = useRelease();
  const setLoading = (payload) => commit('omniview/setLoading', payload);
  const isFetching = computed(() => state.omniview.loading.fetching);
  const isLoadingAssets = computed(() => state.omniview.loading.assets);
  const isModalLoading = computed(() => state.omniview.loading.model);

  const isProjectAndReleaseReady = computed(() => {
    return Boolean(!isFetching.value && currentProject.value.is_syncing === false && currentRelease.value?.id);
  });
  const isReady = computed(() => {
    return Boolean(isProjectAndReleaseReady.value && !isLoadingAssets.value);
  });

  return {
    setLoading,
    isFetching,
    isModalLoading,
    isLoadingAssets,
    isProjectAndReleaseReady,
    isReady
  };
};

export const useProjectRelease = () => {
  const { state, commit, dispatch } = useStore();

  const currentProjectRelease = computed(() => state.projectReleases.currentItem);

  const fetchProjectRelease = (payload) => dispatch('projectReleases/fetchOne', payload);
  const setProjectRelease = (payload) => commit('projectReleases/setCurrentItem', payload);

  return {
    currentProjectRelease,
    fetchProjectRelease,
    setProjectRelease
  };
};

export const useRelease = () => {
  const { state, dispatch } = useStore();

  const currentRelease = computed(() => state.releases.currentItem);

  const fetchRelease = (payload) => dispatch('releases/fetchOne', payload);

  return {
    currentRelease,
    fetchRelease
  };
};

export const useScreens = () => {
  const { state, dispatch, commit } = useStore();

  const screens = computed(() => state.components.items);
  const currentScreen = computed(() => state.components.currentItem);
  const currentNode = computed(() => state.omniview.currentNode);

  const fetchScreens = (payload) => dispatch('components/fetchAllOfParent', payload);
  const fetchScreen = (payload) => dispatch('components/fetchOne', payload);
  const setCurrentScreen = (payload) => commit('components/setCurrentItem', payload);
  const setCurrentScreenData = (payload) => commit('components/setCurrentComponentData', payload);

  return {
    screens,
    currentScreen,
    currentNode,
    fetchScreens,
    fetchScreen,
    setCurrentScreen,
    setCurrentScreenData
  };
};

export const useTeam = () => {
  const { dispatch, state } = useStore();
  const currentTeam = computed(() => state.teams.currentItem);
  const fetchTeam = (payload) => dispatch('teams/fetchOne', payload);

  return {
    currentTeam,
    fetchTeam
  };
};

export const useStigg = () => {
  const { state, getters } = useStore();
  const activeSubscription = computed(() => state.stigg.activeSubscription);
  const paymentStatus = computed(() => getters['stigg/paymentStatus']);
  const isPaid = computed(() => getters['stigg/isPaid']);

  return {
    activeSubscription,
    paymentStatus,
    isPaid
  };
};

export const useMemberships = () => {
  const { state, getters, dispatch } = useStore();
  const currentTeamMembership = computed(() => state.teamMemberships.currentItem);
  const isPro = computed(() => getters['teamMembership/isPro']);
  const screensLimit = computed(() => getters['teamMembership/screensLimit']);

  const fetchTeamMemberships = (payload) => dispatch('teamMemberships/fetchAllTeamMemberships', payload);
  const fetchUserMemberships = (payload) => dispatch('teamMemberships/fetchAllUserMemberships', payload);

  return {
    currentTeamMembership,
    isPro,
    screensLimit,
    fetchTeamMemberships,
    fetchUserMemberships
  };
};

const useExperiments = () => {
  const { getters } = useStore();
  const isPricingScreenFlowActiveExperiment = computed(() => getters['experiments/isPricingScreenFlowActive']);
  const isFullProjectPlaygroundActiveExperiment = computed(() => getters['experiments/isFullProjectPlaygroundActive']);
  return {
    isPricingScreenFlowActiveExperiment,
    isFullProjectPlaygroundActiveExperiment
  };
};

export const useTracking = () => {
  const { getters, dispatch } = useStore();

  const isPlaygroundOmniView = computed(() => getters['omniview/isPlaygroundOmniView']);

  const omniviewFrameworkTrackingProps = computed(() => getters['tracking/omniviewFrameworkProps']);
  const trackExportedCodeSuccess = (payload) => dispatch('tracking/trackExportedCodeSuccess', payload);
  const trackExportedCodeFailure = (payload) => dispatch('tracking/trackExportedCodeFailure', payload);
  const trackOmniviewPageView = (mode) => {
    trackEvent('omniview.page.view', { mode, isCodeSandbox: isPlaygroundOmniView.value });
  };

  return {
    omniviewFrameworkTrackingProps,
    trackExportedCodeSuccess,
    trackExportedCodeFailure,
    trackOmniviewPageView
  };
};

export const useCodePreferences = () => {
  const { getters, commit, dispatch } = useStore();

  const isPlaygroundOmniView = computed(() => getters['omniview/isPlaygroundOmniView']);

  const shouldCodePreferencesBePresented = computed(() => getters['codePreferences/shouldCodePreferencesBePresented']);
  const considerUrlForCodePreferencesBePresented = computed(
    () => getters['omniview/considerUrlForCodePreferencesBePresented']
  );
  const isCodePreferencesRequested = computed(() => getters['omniview/isCodePreferencesRequested']);

  const shouldCodePreferencesPanelOpen = computed(() => {
    return (
      (shouldCodePreferencesBePresented.value && considerUrlForCodePreferencesBePresented.value) ||
      isCodePreferencesRequested.value
    );
  });

  const codePreferences = computed(() => getters['codePreferences/getCodePreferences']);

  const codeDownloadPreferences = computed(() => getters['omniview/codeDownloadPrefs']);

  const getCurrentCodePreferences = (route) => {
    const { framework: queryFramework } = route.query;
    const framework = ['react', 'html', 'vue'].includes(queryFramework) ? queryFramework : null;
    if (framework) {
      const default_values = {
        lengthUnit: 'px',
        styling: framework === 'html' ? 'flexbox' : 'css',
        syntax: 'functional'
      };

      return {
        ...codePreferences.value,
        ...default_values,
        framework
      };
    }
    return codePreferences.value;
  };

  const codegenFramework = computed(() => getters['codePreferences/codegenLang']);
  const currentStyleguide = computed(() => getters['const styleguide/currentStyleguide']);
  const codegenReactLanguage = computed(() => getters['codePreferences/codegenReactLanguage']);
  const codegenReactSyntax = computed(() => getters['codePreferences/codegenReactSyntax']);
  const codegenReactStyle = computed(() => getters['codePreferences/codegenReactStyle']);
  const codegenStylesheetLang = computed(() => getters['codePreferences/codeStyling']);
  const codegenVueStyle = computed(() => getters['codePreferences/codegenVueStyle']);
  const codegenHTMLLayout = computed(() => getters['codePreferences/codegenHTMLLayout']);
  const codegenLengthUnit = computed(() => getters['codePreferences/codegenLengthUnit']);
  const codegenAutoAnimateMode = computed(() => getters['codePreferences/codegenAutoAnimateMode']);

  const getCodePackageSettings = (node) => {
    const framework = codeDownloadPreferences.value.framework;
    const HTMLLayout = codeDownloadPreferences.value.layout;

    let settings = {
      ...(node && { node }),
      auto_animate_mode: codegenAutoAnimateMode.value,
      length_unit: codegenLengthUnit.value
    };
    if (framework === 'html') {
      const isFlex = HTMLLayout === 'flexbox' || HTMLLayout === 'auto_flexbox';
      settings = {
        ...settings,
        preset_settings: isFlex ? 'clean_code' : 'high_fidelity'
      };
    } else if (framework === 'react') {
      settings = {
        ...settings,
        preset_settings: 'clean_code',
        web_components_enable: true,
        web_framework: 'React',
        web_framework_settings: {
          component_type: codegenReactSyntax.value,
          styled_components: codegenReactStyle.value === 'styled',
          stylesheet_syntax: codegenReactStyle.value === 'styled' ? 'css' : codegenReactStyle.value
        }
      };
      if (framework === 'react') {
        settings['language'] = codegenReactLanguage.value;
        settings['engine'] = 'athena';
        settings['athena_react_file_generation_mode'] = 'full_project';
      }
    } else if (framework === 'vue') {
      settings = {
        ...settings,
        preset_settings: 'clean_code',
        web_components_enable: true,
        web_framework: 'Vue',
        web_framework_settings: {
          stylesheet_syntax: codegenVueStyle.value
        }
      };
    }

    return settings;
  };

  const setCodePreferences = (payload) => dispatch('codePreferences/SET_CODE_PREFERENCES', payload);
  const setCodeDownloadPreferences = (payload) => commit('omniview/setCodeDownloadPrefs', payload);

  return {
    shouldCodePreferencesPanelOpen,
    codegenFramework,
    currentStyleguide,
    codegenReactLanguage,
    codegenReactSyntax,
    codegenReactStyle,
    codegenVueStyle,
    codegenStylesheetLang,
    codegenHTMLLayout,
    codegenLengthUnit,
    codegenAutoAnimateMode,
    codeDownloadPreferences,
    isPlaygroundOmniView,
    getCurrentCodePreferences,
    getCodePackageSettings,
    setCodePreferences,
    setCodeDownloadPreferences
  };
};

export const useBreakpoints = (route) => {
  const { getters, commit } = useStore();

  const { setLoading, isReady } = useLoading();
  const { currentProject } = useProject();
  const { screens, currentScreen } = useScreens();

  const similarScreensIdsTemp = computed(() => getters['components/similarScreensIdsTemp']);

  const setBreakpoints = (payload) => commit('omniview/setBreakpoints', payload);
  const setActiveBreakpoint = (payload) => commit('omniview/setActiveBreakpoint', payload);

  const loadBreakpoints = async () => {
    const { breakpoint } = route.params;

    try {
      setLoading({ key: 'breakpoints', value: true });
      await waitFor(() => isReady.value, true);
      setBreakpoints([]);
      const { similar_screens_id: similarScreensId } = currentProject.value;
      const { id: currentScreenId } = currentScreen.value;

      const sizes = [];
      let currentSizeIndex = -1;
      let currentSize = -1;
      for (let i = 0; i < (similarScreensId || similarScreensIdsTemp.value).length; i++) {
        const group = similarScreensId[i];
        const ids = group.map((g) => g.id);
        if (ids.includes(currentScreenId)) {
          for (let j = 0; j < group.length; j++) {
            const { id } = group[j];
            if (id == currentScreenId) {
              currentSizeIndex = j;
            }
            const foundComponent = screens.value.find((c) => c.id == id);
            sizes.push({ width: foundComponent.width, component: foundComponent });
          }
          break;
        }
      }

      const sizesOnly = sizes.map((s) => s.width);
      if (currentSizeIndex != -1) {
        currentSize = sizesOnly[currentSizeIndex];
      }
      sizes.sort((a, b) => (a.width < b.width ? 1 : -1));
      let breakpoints = sizes.map(({ width, component }) => ({ id: uuid(), width, component }));
      if (breakpoints.length == 0) {
        breakpoints = [{ id: uuid(), width: currentScreen.value.width, component: currentScreen }];
      }
      setBreakpoints(breakpoints);
      // from route params
      if (!breakpoint || breakpoint != 'res') {
        if (currentSize == -1) {
          setActiveBreakpoint(breakpoints[0]);
        } else {
          setActiveBreakpoint(breakpoints.find((br) => br.width == currentSize));
        }
      }
    } catch (error) {
      setBreakpoints([]);
      errorHandler.captureException(error);
    } finally {
      setLoading({ key: 'breakpoints', value: false });
    }
  };

  return {
    loadBreakpoints,
    setBreakpoints,
    setActiveBreakpoint
  };
};

export const useCheckExportPermissions = () => {
  const { getters, commit } = useStore();
  const { currentTeam, fetchTeam } = useTeam();
  const { currentProject } = useProject();
  const { currentTeamMembership, isPro, screensLimit } = useMemberships();
  const { isPricingScreenFlowActiveExperiment } = useExperiments();
  const { isProjectAndReleaseReady } = useLoading();

  const isExportAllowed = computed(() => getters['omniview/isExportAllowed']);
  const shouldShowPaywall = computed(() => getters['omniview/shouldShowPaywall']);
  const setIsExportAllowed = (payload) => commit('omniview/setIsExportAllowed', payload);

  const isProjectLimitReached = computed(() => {
    return isProjectAndReleaseReady.value && isPro.value && currentProject.value.is_locked;
  });

  const checkIfExportAllowed = async (projectId) => {
    if (!projectId) return false;
    const { data } = await axios.get(`/rpc/export/allowed?project_id=${projectId}`);
    setIsExportAllowed(data.result || false);
  };

  const openUpgradeDownloadCodeModal = (route, { customSettings = {}, eventData = {} }) => {
    const mode = 'dark';
    const props = {
      title: 'Unlock Pro features',
      content:
        'Upgrade to download project code package in a zip file including HTML & CSS files, images & fonts, with absolute position or Auto flex.',
      mode,
      source: 'omniview',
      nextPage: route,
      ...customSettings
    };
    trackEvent('omniview.paywall.show', eventData);
    openUpgradeModal({ props, mode, onCloseRedirect: route });
  };

  const checkIfScreensLimitReached = async () => {
    if (isPricingScreenFlowActiveExperiment) {
      await fetchTeam({
        id: currentProject.value.team_slug,
        params: { is_slug: true },
        skipCache: true
      });
      const oldPro = isPro.value && !screensLimit.value;
      if (oldPro) {
        return false;
      }
      const reached =
        screensLimit.value &&
        currentTeam.value.projects_components_count > screensLimit.value &&
        screensLimit.value !== -1;
      if (reached) {
        commit('omniview/setIsScreenLimitReached', true);
        trackEvent('omniview.code-mode.screens.locked', {
          number_of_screens: currentTeam.value.projects_components_count,
          plan: currentTeamMembership.value.team_plan.toLowerCase(),
          plan_screens: screensLimit.value,
          project_id: currentProject.value.id
        });
      } else {
        commit('omniview/setIsScreenLimitReached', false);
      }
    }
  };

  return {
    isExportAllowed,
    isProjectLimitReached,
    shouldShowPaywall,
    openUpgradeDownloadCodeModal,
    checkIfExportAllowed,
    checkIfScreensLimitReached
  };
};

export const useCodeFeedback = () => {
  const channelFeedback = ref(null);
  const codeFeedbackRef = ref(null);

  const toggleCodeFeedback = ({ channel = null } = {}) => {
    channelFeedback.value = channel;
  };

  const handleOutsideClick = (e) => {
    if (!codeFeedbackRef.value) return;
    if (!codeFeedbackRef.value.$el.contains(e.target)) {
      codeFeedbackRef.value.handleClose();
    }
  };

  return {
    codeFeedbackRef,
    channelFeedback,
    handleOutsideClick,
    toggleCodeFeedback
  };
};

export const useOmniview = (route) => {
  const { projectId, screenSlug, teamSlug } = route.params;

  const is404Error = ref(false);

  const { dispatch } = useStore();
  const { fetchTeam } = useTeam();
  const { setLoading } = useLoading();
  const { fetchScreens, setCurrentScreen } = useScreens();
  const { fetchTeamMemberships, fetchUserMemberships } = useMemberships();
  const { currentProject, setCurrentProject, fetchProject, updateProject } = useProject();
  const { currentProjectRelease, fetchProjectRelease } = useProjectRelease();
  const { fetchRelease } = useRelease();
  const { setCodePreferences, getCurrentCodePreferences } = useCodePreferences();
  const { fetchAssets } = useAssets();

  const fetchProjectAndReleases = async () => {
    try {
      setLoading({ key: 'project', value: true });
      await fetchProject({ id: projectId, skipCache: true });
      const { live_project_release, is_syncing } = currentProject.value;

      if (is_syncing) {
        dispatch('projects/pollSyncingProject', { id: projectId, storeResult: true });
      }

      await fetchProjectRelease({ id: live_project_release });

      const { framework } = route?.query ?? {};
      const currentCodePreferences = getCurrentCodePreferences(route);

      if (framework) {
        const payload = {
          initial_framework: framework
        };
        setCurrentProject({ ...currentProject.value, ...payload });
        updateProject({ id: currentProject.value.id, payload });
      }

      setCodePreferences(currentCodePreferences);

      setLoading({ key: 'project', value: false });
      setLoading({ key: 'release', value: true });
      await fetchRelease({ id: currentProjectRelease.value.release });
      setLoading({ key: 'release', value: false });
    } catch (error) {
      errorHandler.captureException(error);
    } finally {
      setLoading({ key: 'project', value: false });
      setLoading({ key: 'release', value: false });
    }
  };

  const loadData = async () => {
    is404Error.value = false;

    try {
      setLoading({ key: 'fetching', value: true });
      fetchProjectAndReleases();
      fetchTeam({ id: teamSlug, params: { is_slug: true }, skipCache: true });
      fetchTeamMemberships({ id: teamSlug, params: { is_slug: true } });
      fetchUserMemberships({ id: 'me' });

      await waitFor(() => currentProject.value?.is_syncing === false, true);

      // Fetch screens & select
      const { results: screens } = await fetchScreens({
        parent: 'projects',
        id: projectId,
        params: { page_size: 100, skip_cache: true }
      });

      const screen = screens.find((c) => c.slug == screenSlug);
      setCurrentScreen(screen);

      setLoading({ key: 'fetching', value: false });

      // fetch assets
      fetchAssets(projectId);
    } catch (error) {
      if (error?.response?.status == 403) {
        openModal({ name: 'project-request-access', onCloseRedirect: '/', mode: 'dark' });
      } else {
        is404Error.value = true;
      }
      errorHandler.captureException(error);
    } finally {
      setLoading({ key: 'fetching', value: false });
    }
  };

  return {
    loadData,
    is404Error
  };
};

const useFontsMap = () => {
  const isFetchingFonts = ref(false);

  const { state, commit } = useStore();

  const fontsMap = computed(() => state.omniview.fontsMap);
  const setFontsMap = (payload) => commit('omniview/setFontsMap', payload);

  const fetchFontsMap = async (model) => {
    if (isFetchingFonts.value) return;
    // only in the model is loaded and there is no fontsMap defined yet
    if (model && !fontsMap.value) {
      try {
        isFetchingFonts.value = true;
        const { data } = await axios.post(`rpc/get_fonts_map`, {
          theme: model.theme || {},
          use_fonts_server_url: true
        });
        setFontsMap(data.fonts_map || {});
      } catch (error) {
        // if the request fails define the map to an empty object
        setFontsMap({});
        trackEvent('omniview.fetch-fonts.failed');
      } finally {
        isFetchingFonts.value = false;
      }
    }
  };

  return {
    fontsMap,
    setFontsMap,
    fetchFontsMap
  };
};

const useSlugsMap = () => {
  const { state, commit } = useStore();

  const isFetchingSlugs = ref(false);
  const slugsMap = computed(() => state.omniview.slugsMap);

  const setSlugsMap = (payload) => commit('omniview/setSlugsMap', payload);

  const fetchSlugsMap = async (text) => {
    if (isFetchingSlugs.value) return;
    try {
      if (text.every((t) => has(slugsMap.value, t))) return;
      let map = {};
      isFetchingSlugs.value = true;
      const { data } = await axios.post(`/rpc/slugify`, { text });

      let slugs = data.result;
      for (let i = 0; i < text.length; i++) {
        map[text[i]] = slugs[i];
      }
      setSlugsMap(map);
    } catch (e) {
      console.log(e);
      trackEvent('omniview.slugify.failed');
    } finally {
      isFetchingSlugs.value = false;
    }
  };

  return {
    slugsMap,
    setSlugsMap,
    fetchSlugsMap
  };
};

export const useAnimaModel = () => {
  const { state, dispatch } = useStore();
  const { isProjectAndReleaseReady, isModalLoading, setLoading, isLoadingAssets } = useLoading();
  const { currentProject } = useProject();
  const { currentRelease } = useRelease();
  const { currentProjectRelease } = useProjectRelease();
  const { currentScreen, currentNode, fetchScreen } = useScreens();
  const { fetchFontsMap } = useFontsMap();
  const { fetchSlugsMap } = useSlugsMap();

  const assetsRegistry = computed(() => state.projects.assetsRegistry);

  const fetchReleaseModel = (payload) => dispatch('releases/fetchReleaseModel', payload);
  const fetchModelFromURL = (payload) => dispatch('releases/fetchModelFromUrl', payload);
  const pollProcessingMergedModelProjectRelease = (payload) =>
    dispatch('projectReleases/pollProcessingMergedModelProjectRelease', payload);

  const getModelMd5Map = async () => {
    await waitFor(() => !isLoadingAssets.value, true);
    return assetsRegistry.value;
  };

  const getModelAndScreen = async ({ slim = false, waitForSlugifyCall = false, fullProject = false } = {}) => {
    try {
      await waitFor(() => isProjectAndReleaseReady.value, true);
      let model_file_url = currentRelease.value.model_file_url;
      if (fullProject) {
        await pollProcessingMergedModelProjectRelease({ id: currentProject.value.live_project_release });
        model_file_url = currentProjectRelease.value.model_url;
      }
      const isProgressiveUpload = model_file_url.includes('None');
      if (isProgressiveUpload) {
        model_file_url = currentScreen.value.model_url;
      }

      if (has(isModalLoading.value, model_file_url)) {
        await waitFor(() => isModalLoading.value, false, {
          key: model_file_url
        });
      }

      setLoading({
        key: 'model',
        value: {
          ...isModalLoading.value,
          [model_file_url]: true
        }
      });

      let model;
      if (fullProject) {
        model = await fetchModelFromURL(model_file_url);
      } else if (isProgressiveUpload) {
        model = await fetchModelFromURL(model_file_url);
      } else {
        model = await fetchReleaseModel({ slim });
      }

      setLoading({
        key: 'model',
        value: {
          ...isModalLoading.value,
          [model_file_url]: false
        }
      });

      if (!currentScreen.value) {
        return { model, currentScreen: model.screens[0] };
      }
      const { id: cId, model_id: cModelId } = currentScreen.value;
      let screen = model.screens.find((s) => s.modelID == cModelId);
      if (!screen) {
        const { release_model_file_url } = await fetchScreen({ id: cId });
        if (release_model_file_url) {
          if (has(isModalLoading.value, release_model_file_url)) {
            await waitFor(() => isModalLoading.value, false, {
              key: release_model_file_url
            });
          }

          setLoading({
            key: 'model',
            value: {
              ...isModalLoading.value,
              [release_model_file_url]: true
            }
          });

          model = await fetchModelFromURL(release_model_file_url);
          setLoading({
            key: 'model',
            value: {
              ...isModalLoading.value,
              [release_model_file_url]: false
            }
          });

          screen = model.screens.find((s) => s.modelID == cModelId);
        }
      }

      fetchFontsMap(model);
      const fn = async () => fetchSlugsMap((model.screens || []).map((s) => s.variableID));
      waitForSlugifyCall ? await fn() : fn();
      return { model, currentScreen: screen };
    } catch (error) {
      errorHandler.captureExceptionAndTrack(error, { name: 'omniview.fetch-model.failed' });
    }
  };

  const getCurrentScreenNodeId = async () => {
    const { currentScreen } = await getModelAndScreen();
    return currentScreen['modelID'];
  };

  const getNodeSubModel = async ({ nodeId = null, screenNodeId = null, includeBreakpoints = false } = {}) => {
    const { model } = await getModelAndScreen({ slim: false, waitForSlugifyCall: true });

    let _nodeId = nodeId;
    if (!_nodeId) {
      !screenNodeId && (screenNodeId = await getCurrentScreenNodeId());
      _nodeId = currentNode.value?.id || screenNodeId;
    }

    let found =
      _nodeId == screenNodeId
        ? model.screens.find((s) => s['modelID'] == screenNodeId)
        : await SWorker.run(findNodeWorker, [model, _nodeId]);

    let breakpointsArray = [];
    if (includeBreakpoints) {
      const similarScreenIds = model.similarScreenIds || [];
      const modelScreens = model.screens || [];

      for (let i = 0; i < similarScreenIds.length; i++) {
        const ids = similarScreenIds[i];
        if (ids.includes(screenNodeId)) {
          for (let j = 0; j < ids.length; j++) {
            let id = ids[j];
            if (id !== screenNodeId) {
              const screen = modelScreens.find((s) => s.modelID == id);
              if (screen) {
                breakpointsArray.push(screen);
              }
            }
          }
        }
      }
    }

    if (!found) {
      throw new Error('Node not found');
    }

    const subModel = {
      ...model,
      screens: [found, ...breakpointsArray]
    };

    return { subModel, modelNode: found, model };
  };

  return {
    getModelAndScreen,
    getNodeSubModel,
    getCurrentScreenNodeId,
    getModelMd5Map
  };
};

const useAssets = () => {
  const { commit } = useStore();
  const { getModelAndScreen } = useAnimaModel();
  const { setLoading } = useLoading();
  const { setCurrentScreenData } = useScreens();

  const setRegistryUrl = (payload) => commit('projects/setRegistryUrl', payload);
  const setProjectAssetsRegistry = (payload) => commit('projects/setProjectAssetsRegistry', payload);

  const fetchAssets = async (projectId) => {
    try {
      setLoading({ key: 'assets', value: true });
      const { currentScreen: currentScreenModel } = await getModelAndScreen();
      setCurrentScreenData(currentScreenModel);

      const { data } = await axios.get(`v2/rpc/projects/${projectId}/generate_assets_registry_url`);
      const { assets_registry_url } = data;
      setRegistryUrl(assets_registry_url);
      const registryResponse = await fetch(decodeURI(assets_registry_url));
      const assetsJson = await registryResponse.json();
      setProjectAssetsRegistry(assetsJson);
      codegenCleanCode.setAssetsRegistry(assetsJson);
    } catch (error) {
      errorHandler.captureException(error);
    } finally {
      setLoading({ key: 'assets', value: false });
    }
  };

  return {
    fetchAssets
  };
};

const useCodegenSettings = () => {
  const { getters } = useStore();
  const { currentRelease } = useRelease();
  const { currentProject } = useProject();
  const { currentUser } = useUser();
  const {
    currentStyleguide,
    codegenHTMLLayout,
    codegenAutoAnimateMode,
    codegenLengthUnit,
    codegenReactLanguage,
    codegenReactSyntax,
    codegenReactStyle,
    codegenStylesheetLang
  } = useCodePreferences();

  const hasPixelEnabled = computed(() => getters['users/hasPixelEnabled']);

  const getCodegenSettings = ({
    overrides = {},
    md5Map = {},
    fontsMap = {},
    framework = 'html',
    isCodeSandbox = false,
    customSettings = {},
    presetSettings = 'clean_code'
  } = {}) => {
    const { id: releaseId } = currentRelease.value;
    const { cdn_distribution_domain: cdnDomain } = currentProject.value;

    let settings = {
      base_dir_images: `https://${cdnDomain || 'anima-uploads.s3.amazonaws.com'}/releases/${releaseId}/img/`,
      web_components_enable: false,
      vendor_prefix: '',
      is_for_playground_editor: false,
      md5_to_url: {},
      model_overrides: {},
      fonts_map: {},
      async_src: true,
      engine: 'legacy'
    };

    const md5_to_url = Object.keys(md5Map).reduce((acc, key) => {
      acc[key] = md5Map[key].url ?? '';
      return acc;
    }, {});

    settings['md5_to_url'] = md5_to_url;
    settings['fonts_map'] = fontsMap;
    settings['model_overrides'] = overrides;
    settings['is_display_data_id'] = !isCodeSandbox;
    settings['preset_settings'] = presetSettings;

    if (presetSettings === 'clean_code' && hasPixelEnabled.value) {
      settings['include_pixel'] = true;
      settings['pixel_data'] = {
        user_id: currentUser.value.id,
        team_id: currentProject.value.team,
        project_id: currentProject.value.id
      };
    }

    settings['styleguide_data'] = {
      classes: get(currentStyleguide.value, 'classes', {}),
      tokens: get(currentStyleguide.value, 'tokens', {})
    };

    if (framework === 'react') {
      settings['language'] = codegenReactLanguage.value || 'javascript';
      settings['engine'] = 'athena';
      settings['athena_react_file_generation_mode'] = 'full_project';
    }

    settings = {
      ...settings,
      ...customSettings
    };

    // External Editor Settings
    settings['use_url_from_md5'] = isCodeSandbox;

    switch (framework) {
      case 'html':
        settings = {
          ...settings,
          auto_flexbox_enabled: codegenHTMLLayout.value == 'flexbox',
          length_unit: codegenLengthUnit.value,
          auto_animate_mode: codegenAutoAnimateMode.value
        };
        break;

      case 'react':
        settings = {
          ...settings,
          auto_flexbox_enabled: true,
          length_unit: 'px',
          web_components_enable: true,
          web_framework: 'React',
          web_framework_settings: {
            component_type: codegenReactSyntax.value,
            styled_components: codegenReactStyle.value == 'styled',
            stylesheet_syntax: codegenStylesheetLang.value
          }
        };
        break;
      case 'vue':
        settings = {
          ...settings,
          auto_flexbox_enabled: true,
          length_unit: 'px',
          web_components_enable: true,
          web_framework: 'Vue',
          web_framework_settings: {
            stylesheet_syntax: codegenStylesheetLang.value
          }
        };
        break;
    }
    return settings;
  };

  return {
    getCodegenSettings
  };
};

export const useCodegen = () => {
  const { currentProject } = useProject();
  const { currentRelease } = useRelease();
  const { currentUser } = useUser();

  const codegenRequestController = ref(null);

  const codegenHeaders = computed(() => {
    return {
      'Content-Type': 'application/json; charset=utf-8',
      'plugin-name': 'OmniView',
      'user-id': currentUser.value.id,
      'user-email': currentUser.value.email,
      'project-id': currentProject.value.id,
      'release-id': currentRelease.value.id,
      'origin-url': window.location.href
    };
  });

  const makeCodegenRequest = async ({
    model,
    settings,
    mode,
    options: { requestParams = {}, onabort = () => {} } = {}
  }) => {
    const cacheKey = codegenCache.generateKeyFromObj({ mode, nodeId: model?.screens[0]?.modelID, ...settings });
    const cachedData = await codegenCache.getByKey(cacheKey);
    let codegenResult;

    if (cachedData) {
      codegenResult = cachedData;
    } else {
      codegenRequestController.value = new AbortController();
      codegenRequestController.value.signal.onabort = onabort;
      const res = await fetch(CODEGEN_URL, {
        method: 'POST',
        body: JSON.stringify({
          mode,
          model,
          settings
        }),
        headers: codegenHeaders.value,
        credentials: 'same-origin',
        ...requestParams,
        signal: codegenRequestController.value.signal
      });
      if (!res.ok) {
        throw new Error(`Something went wrong. Error: ${res.status}: ${res.statusText}`);
      }
      codegenResult = await res.json();
    }
    codegenCache.setByKey(cacheKey, codegenResult);
    return codegenResult;
  };

  const makeCodeSandboxRequest = async ({
    model,
    settings,
    mode,
    options: { requestParams = {}, onabort = () => {} } = {}
  } = {}) => {
    try {
      const codegenResponse = await makeCodegenRequest({
        model,
        settings,
        mode,
        options: { onabort, requestParams }
      });

      if (!codegenResponse) {
        throw new Error('codegenResponse is undefined');
      }

      const files = objectMap(codegenResponse.files, (val) => ({
        content: val
      }));

      if (!settings.web_framework) {
        files['package.json'] = {
          content: {}
        };
        files['sandbox.config.json'] = {
          content: { template: 'static' }
        };
      }

      const parameters = getParameters({
        files
      });

      return parameters;
    } catch (error) {
      console.log(error);
      // TODO: track codesandbox request error
    }
  };

  return {
    codegenRequestController,
    makeCodegenRequest,
    makeCodeSandboxRequest
  };
};

export const useGenerateCode = () => {
  const { isReady } = useLoading();
  const { getModelAndScreen, getNodeSubModel, getCurrentScreenNodeId, getModelMd5Map } = useAnimaModel();
  const { isFullProjectPlaygroundActiveExperiment } = useExperiments();
  const { getCodegenSettings } = useCodegenSettings();
  const { fontsMap } = useFontsMap();
  const { codegenFramework, codegenReactLanguage, codegenStylesheetLang, codegenLengthUnit, codegenHTMLLayout } =
    useCodePreferences();
  const { currentTeam } = useTeam();
  const { paymentStatus, isPaid } = useStigg();
  const { currentRelease } = useRelease();
  const { currentProjectRelease } = useProjectRelease();
  const { currentScreen, screens } = useScreens();
  const { makeCodeSandboxRequest } = useCodegen();
  const { currentUser, isAnimaUser } = useUser();

  const generateCodeSandboxCode = async () => {
    const screenNodeId = await getCurrentScreenNodeId();
    if (!screenNodeId) {
      throw new Error('generateCodeSandboxCode: screenNodeId is undefined');
    }
    await waitFor(() => isReady.value, true);

    const { subModel } = await getNodeSubModel({
      screenNodeId,
      includeBreakpoints: true
    });

    const md5Map = await getModelMd5Map();
    const settings = getCodegenSettings({
      framework: codegenFramework.value,
      md5Map,
      fontsMap: fontsMap.value ?? {},
      isCodeSandbox: true
    });

    const params = await makeCodeSandboxRequest({
      model: subModel,
      settings,
      mode: 'package'
    });

    return params;
  };

  const getSessionByKey = async (sessionKey) => {
    if (sessionKey) {
      const resp = await fetch(`${API_BASE_URL}/generation_sessions/session_key/${sessionKey}/sandpack`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp.ok) {
        const data = await resp.json();
        if (data?.id && data?.status === 'success') return data;
      }
    }
    return null;
  };

  const createSession = async (sessionArgs) => {
    const resp = await fetch(`${API_BASE_URL}/generation_sessions`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${auth.getToken()}`,
        'Content-Type': 'application/json',
        'X-Client-Id': 'com.animaapp.web',
        'X-Team-Id': currentTeam.value.id
      },
      body: JSON.stringify(sessionArgs)
    });

    if (resp.ok) {
      const data = await resp.json();
      return data;
    }
    return null;
  };

  const uploadModelJson = async (url, model) => {
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
  };

  const triggerGenerateAsync = async (sessionId) => {
    if (!sessionId) return false;
    const resp = await fetch(`${API_BASE_URL}/generation_sessions/${sessionId}/generate/async`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${auth.getToken()}`,
        'X-Team-Id': currentTeam.value.id
      }
    });
    return resp.ok;
  };

  const getSessionProgress = async (sessionId) => {
    const resp = await fetch(`${API_BASE_URL}/generation_sessions/${sessionId}/progress`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${auth.getToken()}`
      }
    });
    if (resp.ok) {
      const data = await resp.json();
      return data;
    }

    return {};
  };

  class GenerateAsyncServerError extends Error {
    constructor() {
      super();
      this.name = 'Generate playground Async Server Error';
    }
  }

  const waitForGenerationSession = async (sessionId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const startTime = new Date().getTime();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { status } = await getSessionProgress(sessionId);
        if (status === 'success') {
          break;
        }
        if (status === 'failure') {
          reject(new GenerateAsyncServerError());
          return;
        }
        const elapsed = new Date().getTime() - startTime;
        // 5 minutes timeout
        if (elapsed > 300000) {
          reject(new GenerateAsyncServerError());
          return;
        }
        await sleep(1000);
      }

      resolve();
    });
  };

  const generatePlaygroundURLAsync = async ({ onProgress, screenOnly = false }) => {
    const generateFullProject = !screenOnly && isFullProjectPlaygroundActiveExperiment.value;
    const screenNodeId = generateFullProject ? null : await getCurrentScreenNodeId();
    if (!generateFullProject && !screenNodeId) {
      throw new Error('generatePlaygroundURLAsync: screenNodeId is undefined');
    }

    const isReact = codegenFramework.value === 'react';
    const isHTML = codegenFramework.value === 'html';
    const isReactTypeScript = codegenReactLanguage.value === 'typescript';
    const isTypeScript = isReact && isReactTypeScript;
    const language = isTypeScript ? 'typescript' : 'javascript';
    const stylingMode = codegenStylesheetLang.value === 'css' ? 'plain_css' : codegenStylesheetLang.value;
    const lengthUnit = isHTML ? codegenLengthUnit.value : 'px';
    const isAutoFlexboxEnabled = isHTML && codegenHTMLLayout.value === 'flexbox';

    const generateSessionKey = ({ sessionArgs = {}, screenNodeId = '' }) => {
      if (generateFullProject) {
        const availableScreens = screens.value.filter((screen) => !screen.is_locked).map((screen) => screen.id);
        return md5({
          releaseId: currentRelease.value.id,
          availableScreens,
          updatedAt: currentProjectRelease.value.updated_at,
          ...sessionArgs
        });
      }
      return md5({
        screenId: currentScreen.value.id,
        screenNodeId,
        releaseId: currentRelease.value.id,
        ...sessionArgs
      });
    };

    const sessionArgs = {
      framework: codegenFramework.value,
      language,
      styling_mode: stylingMode,
      length_unit: lengthUnit,
      auto_flexbox_enabled: isAutoFlexboxEnabled,
      target_artifact: 'sandpack'
    };

    const sessionKey = generateSessionKey({ sessionArgs, screenNodeId });

    onProgress({
      status: 'preparing session'
    });

    const isReadonlyMode = () => {
      if (isAnimaUser.value) return false;

      if (currentTeam.value.uses_stigg_integration) {
        return !isPaid.value || paymentStatus.value?.status === 'REQUIRED';
      }
      return !currentUser.value.is_in_paying_team; // fallback to old logic
    };

    const getURL = async (_url) => {
      const url = new URL(_url);
      if (isReadonlyMode()) {
        // make the playground readOnly for non paying users and non anima users
        url.searchParams.append('t', true);
      }
      return url.href;
    };

    const { id } = (await getSessionByKey(sessionKey)) ?? {};
    if (id) {
      return getURL(`https://playground.animaapp.com/${id}`);
    }

    onProgress({
      status: 'Preparing code generation',
      max: 30
    });

    let model, md5Map;
    if (generateFullProject) {
      [{ model }, md5Map] = await Promise.all([
        getModelAndScreen({ fullProject: generateFullProject }),
        getModelMd5Map()
      ]);
    } else {
      [{ subModel: model }, md5Map] = await Promise.all([
        getNodeSubModel({
          screenNodeId
        }),
        getModelMd5Map()
      ]);
    }
    const md5ToURL = {};
    for (const key in md5Map) {
      md5ToURL[key] = md5Map[key].url;
    }
    model['md5_to_url'] = md5ToURL;

    sessionArgs['session_key'] = sessionKey;

    const { id: sessionId, model_upload_url } = await createSession(sessionArgs);

    onProgress({
      status: 'uploading assets',
      max: 50
    });
    await uploadModelJson(model_upload_url, model);

    const ok = await triggerGenerateAsync(sessionId);

    if (!ok) {
      throw new Error('triggerGenerateAsync failed');
    }

    onProgress({
      status: 'Generating code',
      max: 100
    });

    await waitForGenerationSession(sessionId, onProgress);

    onProgress({
      status: 'Generating code',
      max: -1
    });

    return getURL(`https://playground.animaapp.com/${sessionId}`);
  };

  return {
    generateCodeSandboxCode,
    generatePlaygroundURLAsync
  };
};

// TODO: not finished, this hook should replace export code logic in `codegenMixin.js`
/**
 * @ignore
 */
// eslint-disable-next-line no-unused-vars
export const useExportCode = () => {
  const { dispatch } = useStore();
  const { currentProject } = useProject();
  const { currentProjectRelease } = useProjectRelease();
  const { isExportAllowed, shouldShowPaywall, openUpgradeDownloadCodeModal } = useCheckExportPermissions();
  const { omniviewFrameworkTrackingProps, trackExportedCodeFailure, trackExportedCodeSuccess } = useTracking();
  const { codegenFramework, codegenLengthUnit, codegenHTMLLayout, setCodeDownloadPreferences, getCodePackageSettings } =
    useCodePreferences();

  const projectScopeType = 1;
  const projectScreenType = 2;
  const projectSelectionType = 3;

  const isExportLoading = ref(false);

  const codePackages = ref([]);
  const currentCodePackage = ref(null);

  const getScopeInfo = (scope) => {
    switch (scope) {
      case projectScopeType:
        return {
          type: 'project',
          screenCount: currentProject.value.components_count || 1
        };
      case projectScreenType:
        return {
          type: 'screen',
          screenCount: 1
        };
      case projectSelectionType:
        return {
          type: 'selection',
          screenCount: 0
        };
    }
    return {
      type: 'selection',
      screenCount: 0
    };
  };

  const handleExportNotAllowed = (scope = {}, route) => {
    // EventBus.$emit('close');
    const { screenCount, type } = getScopeInfo(scope);
    const eventPayload = omniviewFrameworkTrackingProps.value;
    const eventData = {
      ...eventPayload,
      // panel: 'export_code_modal', TODO should be included in eventPayload
      // action: 'download_package', TODO should be included in eventPayload
      count_screens: screenCount,
      exported: type
    };
    if (shouldShowPaywall.value) {
      return openUpgradeDownloadCodeModal(route, { eventData });
    }
    return openModal({
      name: 'export-code-as-viewer',
      variant: 'center',
      opacity: 0.3,
      mode: 'dark',
      whiteOverlay: true,
      background: '#2d2d2d',
      width: 500,
      closeButton: true
    });
  };

  const fetchAllCodePackages = async () => {
    try {
      const { id: projectReleaseId } = currentProjectRelease.value;
      const { results: codePackages = [] } = await dispatch('codePackages/fetchAllOfParent', {
        parent: 'project_releases',
        id: projectReleaseId,
        skipCache: true
      });
      return codePackages;
    } catch (err) {
      console.error(err);
      isExportLoading.value = false;
      return null;
    }
  };

  const pollCodePackage = async ({ id }) => {
    isExportLoading.value = true;

    const codePackage = await poll({
      fn: () => dispatch('codePackages/fetchOne', { id, skipCache: true }),
      validate: (codePackage) => codePackage?.status !== 'processing',
      interval: 3000,
      maxAttempts: 200
    });
    return codePackage;
  };

  const getCurrentCodePackage = (settings) => {
    if (codePackages.value) {
      const [codePackage] = codePackages.value?.filter((codePackage) =>
        isEqual(JSON.parse(JSON.stringify(codePackage.settings)), settings)
      );
      return codePackage;
    }
    return null;
  };

  const isPackageWithCurrentSettingReady = computed(() => {
    return currentCodePackage.value?.status === 'finished' && currentCodePackage.value?.download_url;
  });

  const isPackageWithCurrentSettingsProcessing = computed(() => {
    return currentCodePackage.value?.status === 'processing';
  });

  const isPackageWithCurrentSettingsInvalidOrNotExists = computed(() => {
    return !currentCodePackage.value || ['failed', 'outdated'].includes(currentCodePackage.value?.status);
  });

  const handleDownloadCodePackage = async (settings) => {
    codePackages.value = await fetchAllCodePackages();
    currentCodePackage.value = getCurrentCodePackage(settings);
    const eventPayload = omniviewFrameworkTrackingProps.value;
    if (isPackageWithCurrentSettingReady.value) {
      trackExportedCodeSuccess();
      trackEvent('omniview.download-existing-package.success', eventPayload);
      // this.trackExportCodeEvent();
      downloadFile(currentCodePackage.value.download_url);
      // EventBus.$emit('downloadComplete');
      return 'success';
    }
    let codePackage, waitForPackageTimeout;
    if (isPackageWithCurrentSettingsProcessing.value) {
      codePackage = await pollCodePackage(currentCodePackage.value);
    } else if (isPackageWithCurrentSettingsInvalidOrNotExists.value) {
      isExportLoading.value = true;
      const res = await this.requestPackage({ settings });
      const codePackageId = res.data.package;
      waitForPackageTimeout = this.openWaitForPackageModal(codePackageId);
      codePackage = await pollCodePackage({ id: codePackageId });
    }
    const { status, download_url: downloadUrl, notify_when_done: mailSentToUser } = codePackage || {};
    if (status === 'finished' && downloadUrl && !mailSentToUser) {
      clearTimeout(waitForPackageTimeout);
      isExportLoading.value = false;
      // EventBus.$emit('close-package-waiting-modal', { isPackageReady: true });
      this.openDownloadPackageModal(downloadUrl);
      this.trackExportCodeEvent();
      trackExportedCodeSuccess();
      trackEvent('omniview.package-on-demand.success', eventPayload);
      // EventBus.$emit('downloadComplete');
    } else if (status === 'failed') {
      clearTimeout(waitForPackageTimeout);
      trackEvent('omniview.package-on-demand.failed', { type: this._type });
      trackExportedCodeFailure();
      this.openPackageFailedModal();
      // EventBus.$emit('downloadComplete');
    }
    this.nextOnboardingStage({ currentStageSlug: 'export-code' });
    return status;
  };

  const exportFullZip = async (node) => {
    try {
      const settings = getCodePackageSettings(node);
      const status = await handleDownloadCodePackage(settings);
      if (status && !['outdated', 'failed'].includes(status)) {
        codePackages.value = await fetchAllCodePackages();
      }
    } catch (err) {
      console.error(err);
      trackExportedCodeFailure();
      errorHandler.captureException(err);
      toastError('Something went wrong here, please try again.');
    }
    // EventBus.$emit('close');
  };

  const exportCode = async (scope, route, node = null) => {
    if (!isExportAllowed.value) return handleExportNotAllowed(scope, route);
    const layout = codegenFramework.value == 'html' ? codegenHTMLLayout.value : 'absolute';
    const newCodePrefs = { framework: codegenFramework.value, layout, lengthUnit: codegenLengthUnit.value };
    setCodeDownloadPreferences(newCodePrefs);
    localStorage.setItem('codeDownloadPrefs', JSON.stringify(newCodePrefs));
    await exportFullZip(node);
  };

  return {
    handleExportNotAllowed,
    exportCode
  };
};

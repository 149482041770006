<template>
  <div class="container">
    <div class="title">Update Payment</div>
    <div class="body">
      <div>A payment is required for your subscription to remain active.&nbsp;</div>
      <br />
      <an-button variant="secondary" @click="updatePaymentClick">Update Payment</an-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'update-payment-modal',
  props: {
    paymentUrl: {
      type: String,
      required: true
    }
  },
  async mounted() {
    this.$trackEvent('update-payment-modal.show');
  },
  methods: {
    updatePaymentClick() {
      this.$trackEvent('update-payment-modal.button.click');
      window.open(this.paymentUrl, '_blank');
      this.$emit('close');
    },
    close() {
      this.$trackEvent('update-payment-modal.close');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  @include headline;
  font-weight: 500;
}

.body {
  margin: 20px 0 30px;
  color: var(--dark-secondary-text);
}
</style>

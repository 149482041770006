<template>
  <div ref="rootCont" class="code-container">
    <div class="side-panel">
      <div
        v-show="showRegenerateCodegenReq && isPanelOpen"
        @click="regenerateCodegenReq"
        class="regenerate-codegen-req"
      >
        <img src="@/assets/svg/refresh-icon.svg" />
      </div>
      <div @click="handleSendFeedback" v-show="currentNodeCSS && isPanelOpen" class="feedback">
        <span data-cy="give-feedback">Feedback</span>
      </div>
    </div>
    <Splitpanes @resized="handlePanelResized">
      <Pane :size="paneSize.HTML" class="flex flex-col" max-size="60" min-size="25" data-cy="code-panel-lang">
        <CodeTab
          :codeIcon="`code-icons/${codegenLangFramework}`"
          :text="codegenLangLabel"
          :menuOptions="getMenuOptionsForTab(codegenLang, codegenLangLabel)"
          @menu-item-selected="handleTabMenuAction($event, 'lang')"
          @menu-opened="handleTabMenuOpened('lang')"
          data-cy="framework-tab-name"
        />

        <div
          :style="{ position: 'relative', overflowY: 'auto', flex: 1, '--html-pane-width': htmlPaneWidth + 'px' }"
          class="flex flex-col hide-scroll"
          @copy="onCopy($event, 'html')"
        >
          <div ref="tagBg" class="tag-bg"></div>
          <div
            ref="html-code"
            class="code-scroller"
            :style="{
              flex: 1,
              width: '100%',
              overflowY: currentNodeCleanMarkup ? 'auto' : 'hidden'
            }"
          >
            <Prism2
              data-cy="html-code"
              :code="currentNodeCleanMarkup"
              ref="prismHTML"
              :isEmpty="!selected"
              :language="getLanguage"
              :loading="selected && isGeneratingCode && !isExportingCodeComponent"
              :disableSelection="shouldShowPaywall"
              @nodeMouseOver="handleNodeMouseOver"
              @nodeMouseLeave="handleNodeMouseLeave"
              @update-generated-views="updateGeneratedViews"
              @onRender="updateHTMLPanelWidth"
              @handle-class-click="handleClassClick"
            />
          </div>
        </div>
      </Pane>
      <Pane :size="paneSize.CSS" class="flex flex-col" min-size="25" data-cy="code-panel-css">
        <CodeTab
          :codeIcon="`code-icons/${this.styleType === 'css' ? 'css' : 'sass'}`"
          :text="styleTypeLabel"
          :menuOptions="getMenuOptionsForTab(styleType, styleTypeLabel)"
          @menu-item-selected="handleTabMenuAction($event, 'css')"
          @menu-opened="handleTabMenuOpened('css')"
          data-cy="css-tab-name"
        />
        <div :style="{ position: 'relative', flex: 1, overflowY: 'auto' }" class="flex flex-col">
          <div
            ref="css-code"
            class="code-scroller"
            @copy="onCopy($event, 'css')"
            :style="{
              flex: 1,
              width: '100%',
              overflowY: currentNodeCleanCSS ? 'auto' : 'hidden'
            }"
          >
            <Prism2
              data-cy="css-code"
              mode="css"
              :plugins="['inline-color']"
              ref="prismCSS"
              :language="codegenStylesheetLang"
              :isEmpty="!selected"
              :loading="selected && isGeneratingCode && !isExportingCodeComponent"
              :code="currentNodeCleanCSS"
              :disableSelection="shouldShowPaywall"
              @css-variable-click="handleCSSVariableClick"
            />
          </div>
        </div>
      </Pane>
      <Pane
        :size="paneSize.styleguide"
        class="flex flex-col"
        min-size="33.33"
        v-if="isStyleguideOpen"
        data-cy="code-panel-styleguide"
      >
        <CodeTab
          @expand-clicked="setIsStyleguideOpen(!isStyleguideOpen)"
          isExpanded
          showExpandButton
          codeIcon="code-icons/css"
          text="Styleguide"
          :menuOptions="getMenuOptionsForTab('styleguide', 'Styleguide')"
          @menu-item-selected="handleTabMenuAction($event, 'styleguide')"
          @menu-opened="handleTabMenuOpened('styleguide')"
        />
        <div
          v-show="isStyleguideOpenCopy"
          id="styleguidePane"
          :style="{ position: 'relative', flex: 1, overflow: 'hidden' }"
          class="flex flex-col"
          @copy="onCopy($event, 'styleguide')"
        >
          <div
            ref="styleguide-code"
            class="code-scroller"
            :style="{
              flex: 1,
              width: '100%',
              overflowY: currentStylesheet.full ? 'auto' : 'hidden'
            }"
          >
            <Prism2
              ref="prismStyleguide"
              mode="styleguide"
              :plugins="['inline-color']"
              language="css"
              :code="currentStylesheet.full"
              :disableSelection="shouldShowPaywall"
            />
          </div>
        </div>
      </Pane>
    </Splitpanes>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import Prism2 from './Prism.vue';
import { Splitpanes, Pane } from 'splitpanes';
import { EventBus, toastWarning } from '@/services/bus';
import { UserMixin } from '@/mixins';
import { prettifyAssetFilename } from '@/utils/prettify';
import { SEND_COMPONENT_MESSAGE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { codegenCleanCode } from '@/services/codegen/cleanCode';
import CodeTab from '@/components/OmniView/Panels/codeTab.vue';
import { get } from 'lodash-es';

export default {
  name: 'cleanCode',
  components: {
    Prism2,
    Splitpanes,
    Pane,
    CodeTab
  },
  data() {
    return {
      paneSize: {
        HTML: 33.33,
        CSS: 33.33,
        styleguide: 33.33
      },
      feedbackText: '',
      showRegenerateCodegenReq: false,
      tagBackgroundStyles: {
        height: 0,
        top: 0
      },
      htmlPaneWidth: 0,
      htmlPaneSize: 33.33,
      isOpen: false,
      top: 0,
      classEl: null,
      popoverPosition: { top: 0, left: 0 },
      isStyleguideOpenCopy: true,
      hideStyleguide: false
    };
  },
  watch: {
    isStyleguideOpen: {
      handler(v) {
        if (!v) {
          this.isStyleguideOpenCopy = v;
        } else {
          setTimeout(() => {
            this.isStyleguideOpenCopy = this.isStyleguideOpen;
          }, 200);
        }
      },
      immediate: true
    }
  },
  mixins: [UserMixin],
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapGetters({ isUserAdmin: 'users/isUserAdmin' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode', isChromeExtension: 'isChromeExtension' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentNodeHTML: 'omniview/currentNodeHTML',
      currentNodeJSX: 'omniview/currentNodeJSX',
      currentNodeCSS: 'omniview/currentNodeCSS',
      currentNodeMd5Map: 'omniview/currentNodeMd5Map',
      codegenLang: 'codePreferences/codegenLang',
      codegenReactLanguage: 'codePreferences/codegenReactLanguage',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      isGeneratingCode: 'omniview/isGeneratingCode',
      isExportingCodeComponent: 'omniview/isExportingCodeComponent',
      playgroundCode: 'omniview/playgroundCode',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      userTeams: 'teamMemberships/userTeams',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      isCodeDisplayed: 'omniview/isCodeDisplayed',
      styleType: 'codePreferences/codeStyling',
      codegenStylesheetLang: 'codePreferences/codeStyling',
      isPanelOpen: 'omniview/isPanelOpen',
      currentStylesheet: 'styleguide/currentStylesheet',
      isStyleguideOpen: 'styleguide/isStyleguideOpen',
      currentClassData: 'styleguide/currentClassData',
      styleguide: 'styleguide/currentStyleguide',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    }),

    selectedClassPageSlugs() {
      if (!this.currentClassData.className) return [];
      let classes = Object.values(get(this.styleguide, 'classes', {}));
      let classObj = classes.find((c) => c.name == this.currentClassData.className) || {};
      return Object.keys(get(classObj, 'usage', {}));
    },

    htmlPaneSize2() {
      return 100 - this.paneSize.CSS - this.paneSize.styleguide;
    },
    cssPaneSize() {
      return 100 - this.paneSize.CSS - this.paneSize.styleguide;
    },
    styleTypePaneSize() {
      return 100 - this.paneSize.CSS - this.paneSize.HTML;
    },
    proUser() {
      // return true//remove this row when you want to activate this feature
      if (!this.isChromeExtension) {
        return true;
      }
      return this.userTeams &&
        this.userTeams.find((team) => {
          return team.plan !== 'Free';
        })
        ? true
        : false;
    },
    overrides() {
      return this.currentComponentMetadata.overrides || {};
    },
    nodeOverrides() {
      const componentOverrides = this.currentComponentMetadata.overrides || {};
      const nodeOverrides = componentOverrides[this.currentNode.id];
      if (!nodeOverrides) return {};

      return nodeOverrides;
    },
    selected() {
      if (this.showOnlyCode && (this.currentNodeCleanMarkup || this.isGeneratingCode)) {
        return true;
      }
      return !!this.currentNode.id;
    },
    nodeHTML() {
      let h = '';
      let shouldPrettify = false;
      switch (this.codegenLang) {
        case 'react':
          h = this.currentNodeJSX;
          break;

        case 'html':
          h = this.currentNodeHTML;
          shouldPrettify = true;
          break;

        case 'vue':
          h = this.currentNodeJSX;
          break;

        default:
          break;
      }

      if (!h) {
        h = '';
      }

      return {
        copyHTML: codegenCleanCode.cleanAndPrettifyHTML(h, { removeDataId: true, prettify: shouldPrettify }),
        rawHTML: h
      };
    },
    getLanguage() {
      let lang = '';
      switch (this.codegenLang) {
        case 'react':
          lang = 'jsx';
          break;

        case 'html':
          lang = 'html';
          break;

        case 'vue':
          lang = 'html';
          break;

        default:
          break;
      }
      return lang;
    },
    getLangDisplayName() {
      let lang = '';
      switch (this.codegenLang) {
        case 'react':
          lang = 'jsx';
          break;

        case 'html':
          lang = 'html';
          break;

        case 'vue':
          lang = 'vue';
          break;

        default:
          break;
      }
      return lang;
    },
    currentNodeCleanCSS() {
      if (!this.currentNodeCSS) return '';
      const resetCssImportStatement = new RegExp(/^.*\/reset\.min\.css.*$/gm);
      const variablesImportStatement = new RegExp(/@import .*\/variables.*$/gm);
      const multiLineBreak = new RegExp(/\n\n+/g);
      let cleanCss = this.currentNodeCSS
        .replace(resetCssImportStatement, '')
        .replace(variablesImportStatement, '')
        .replace(`* {\n  box-sizing: border-box;\n}\n`, '')
        .replace(`*\n  box-sizing: border-box`, '')
        .replace(multiLineBreak, '\n\n');
      cleanCss = this.replaceUrlsWithFilenames(cleanCss);
      return cleanCss;
    },
    currentNodeCleanMarkup() {
      if (!this.nodeHTML.rawHTML) return '';
      let cleanMarkup = `${this.nodeHTML.rawHTML}`;
      cleanMarkup = this.replaceUrlsWithFilenames(cleanMarkup);

      return cleanMarkup;
    },
    codegenLangLabel() {
      switch (this.codegenLang) {
        case 'react':
          if (this.codegenReactLanguage === 'typescript') {
            return 'TSX';
          }
          return 'JSX';
        case 'vue':
          return 'VUE';
        default:
          return 'HTML';
      }
    },
    codegenLangFramework() {
      return this.codegenLang === 'react' ? 'react' : this.codegenLang === 'vue' ? 'vue' : 'html';
    },
    styleTypeLabel() {
      return this.styleType === 'css' ? 'CSS' : 'SASS';
    }
  },
  mounted() {
    document.addEventListener('markup-node-mouseover', this.handleNodeMouseOver);
    document.addEventListener('markup-node-mouseleave', this.handleNodeMouseLeave);
    document.addEventListener('class-block-copy', this.handleClassBlokCopy);
    document.addEventListener('class-block-inspect', this.handleClassBlockInspect);
    if (this.isUserAdmin) {
      this.showRegenerateCodegenReq = !!localStorage.getItem('showRegenerateCodegenReq');
    }

    EventBus.$on('toggle-styleguide', this.toggleStyleguide);
    EventBus.$on('open-in-codesandbox', this.handleOpenInCodeSandbox);
    EventBus.$on('handle-class-dbl-click', this.handleClassDblClick);

    this.$refs['html-code'].addEventListener('mouseup', this.handleHTMLCodeTextSelection);
    this.$refs['html-code'].addEventListener('mouseleave', this.handleNodeMouseLeave);
    this.$refs['html-code'].addEventListener('keyup', this.handleHTMLCodeTextSelection);
    this.$refs['css-code'].addEventListener('mouseup', this.handleCSSCodeTextSelection);
    this.$refs['css-code'].addEventListener('keyup', this.handleCSSCodeTextSelection);
    this.$refs['styleguide-code']?.addEventListener('mouseup', this.handleStyleguideCodeTextSelection);
    this.$refs['styleguide-code']?.addEventListener('keyup', this.handleStyleguideCodeTextSelection);
  },
  destroyed() {
    document.removeEventListener('markup-node-mouseover', this.handleNodeMouseOver);
    document.removeEventListener('markup-node-mouseleave', this.handleNodeMouseLeave);
    document.removeEventListener('class-block-copy', this.handleClassBlokCopy);
    document.removeEventListener('class-block-inspect', this.handleClassBlockInspect);
    EventBus.$off('open-in-codesandbox', this.handleOpenInCodeSandbox);
    EventBus.$off('handle-class-dbl-click', this.handleClassDblClick);
    EventBus.$off('toggle-styleguide', this.toggleStyleguide);

    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('mouseup', this.handleHTMLCodeTextSelection);
    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('mouseleave', this.handleNodeMouseLeave);
    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('keyup', this.handleHTMLCodeTextSelection);
    this.$refs['css-code'] && this.$refs['css-code'].removeEventListener('mouseup', this.handleCSSCodeTextSelection);
    this.$refs['css-code'] && this.$refs['css-code'].removeEventListener('keyup', this.handleCSSCodeTextSelection);
    this.$refs['styleguide-code']?.removeEventListener('mouseup', this.handleStyleguideCodeTextSelection);
    this.$refs['styleguide-code']?.removeEventListener('keyup', this.handleStyleguideCodeTextSelection);
  },

  methods: {
    ...mapMutations({
      setIsStyleguideOpen: 'styleguide/setIsStyleguideOpen',
      setCurrentClassData: 'styleguide/setCurrentClassData'
    }),
    ...mapActions({
      trackExportedCodeInitiated: 'tracking/trackExportedCodeInitiated',
      trackExportedCodeSuccess: 'tracking/trackExportedCodeSuccess'
    }),

    async handleClassBlokCopy(e) {
      EventBus.$emit('copy-code', {
        text: e.detail.classData?.text?.trim(),
        type: 'styleguide-class',
        panel: 'styleguide'
      });
    },
    handleClassBlockInspect(e) {
      const { inspectEl, classData, classWidth } = e.detail;
      this.setCurrentClassData(classData);

      let st = this.$refs.st;

      if (st) {
        const { top: stTop } = st.getBoundingClientRect();
        const { top: inspectElTop } = inspectEl.getBoundingClientRect();
        let _top = inspectElTop - stTop;
        this.top = _top;
        this.popoverPosition = {
          top: _top,
          left: classWidth
        };
      }

      this.isOpen = !this.isOpen;
    },
    regenerateCodegenReq() {
      EventBus.$emit('regenerate-previous-request');
    },
    updateHTMLPanelWidth() {
      const root = this.$el.getBoundingClientRect();
      this.htmlPaneWidth = (root.width * this.htmlPaneSize) / 100;
    },
    handlePanelResized(e) {
      this.paneSize = {
        HTML: e[0].size,
        CSS: e[1].size,
        styleguide: e[2] ? e[2].size : 33.335
      };

      if (e[0]) {
        this.htmlPaneSize = e[0].size || 50;
        const root = this.$el.getBoundingClientRect();
        this.htmlPaneWidth = (root.width * this.htmlPaneSize) / 100;
      }
    },

    toggleStyleguide() {
      const isOpen = !this.isStyleguideOpen;
      this.setIsStyleguideOpen(!this.isStyleguideOpen);
      this.$nextTick(() => {
        requestAnimationFrame(async () => {
          if (isOpen) {
            const styleguideSize = 100 / 3;
            const cssSize = Math.max(100 - this.paneSize.CSS - styleguideSize, 25);
            const htmlSize = 100 - cssSize - styleguideSize;
            this.paneSize = {
              styleguide: styleguideSize,
              CSS: cssSize,
              HTML: htmlSize
            };
          } else {
            const cssSize = 100 - this.paneSize.HTML;
            const htmlSize = 100 - cssSize;
            this.paneSize = {
              styleguide: 0,
              CSS: cssSize,
              HTML: htmlSize
            };
          }
        });
      });
    },

    handleNodeMouseOver(x) {
      const {
        detail: { nodeId }
      } = x;
      if (!nodeId) return;
      const { component } = this.$route.query;

      if (component) {
        EventBus.$emit(SEND_COMPONENT_MESSAGE, {
          action: 'highlight-nodes',
          ids: [nodeId],
          iframeName: 'all',
          animate: false,
          scrollViewport: true
        });
      } else {
        EventBus.$emit(SEND_MESSAGE, {
          action: 'highlight-nodes',
          ids: [nodeId],
          animate: false,
          scrollViewport: true
        });
      }
    },
    updateGeneratedViews(viewsMap) {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'update-generated-views',
        viewsMap
      });
    },

    handleNodeMouseLeave() {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'clear-highlighted-nodes'
      });
    },

    handleClassDblClick({ className, isStyleguideClass }) {
      this.$trackEvent('omniview.code-mode.double-click-classname', { className, isStyleguideClass });
    },
    async handleCSSVariableClick({ tokenId }) {
      const el = this.$refs['prismStyleguide'].$el.querySelector(`[token-id="${tokenId}"]`);
      if (el) {
        let timeout = 0;
        if (!this.isStyleguideOpen) {
          this.setIsStyleguideOpen(!this.isStyleguideOpen);
          await this.$nextTick();
          timeout = 200;
        }

        requestAnimationFrame(() => {
          setTimeout(() => {
            el.click();
          }, timeout);
        });
      }
    },
    async handleClassClick({ className, isStyleguideClass }) {
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.code-mode.single-click-classname', { className, isStyleguideClass, ...eventPayload });
      let timeout = 0;
      if (isStyleguideClass && !this.isStyleguideOpen) {
        this.setIsStyleguideOpen(!this.isStyleguideOpen);
        await this.$nextTick();
        timeout = 200;
      }

      requestAnimationFrame(() => {
        const ref = isStyleguideClass ? 'prismStyleguide' : this.codegenLang == 'vue' ? 'prismHTML' : 'prismCSS';
        setTimeout(() => {
          this.scrollToClass({
            className,
            ref
          });
        }, timeout);
      });
    },
    scrollToClass({ className, ref, refId }) {
      let refEl;
      if (!ref) {
        refEl = document.querySelector(`#${refId}`);
      } else {
        refEl = this.$refs[ref].$el;
      }

      if (!refEl) return;
      const selectors = refEl.querySelectorAll('.token.selector');
      let found = false;
      [...selectors].map((s) => {
        let text = s.textContent;
        if (text.includes(`.${className}`)) {
          if (!found) {
            found = s;
          }
        }
      });
      if (found) {
        found.scrollIntoView();
      }
    },
    onCopy(e, panel) {
      // Prevent copying from chrome extension
      if (!this.proUser && this.isChromeExtension) {
        e.preventDefault();

        return false;
      }
      this.trackCopyEvent(panel, 'manual');
    },
    trackCopyEvent(panel, source) {
      this.trackExportedCodeSuccess({
        type: this.codegenLang,
        framework: this.codegenLang,
        layout: this.codegenHTMLLayout,
        action: 'copy_to_clipboard',
        panel,
        source
      });
    },
    getSelectedText() {
      var text = '';
      if (typeof window.getSelection != 'undefined') {
        text = window.getSelection().toString();
      } else if (typeof document.selection != 'undefined' && document.selection.type == 'Text') {
        text = document.selection.createRange().text;
      }
      return text;
    },
    handleHTMLCodeTextSelection() {
      this.handleCodeTextSelection(this.codegenLang);
    },
    handleCSSCodeTextSelection() {
      this.handleCodeTextSelection('css');
    },
    handleStyleguideCodeTextSelection() {
      this.handleCodeTextSelection('styleguide');
    },
    handleCodeTextSelection(panel) {
      setTimeout(() => {
        var selectedText = this.getSelectedText();

        if (this.shouldShowPaywall && this.isCodeDisplayed) {
          return EventBus.$emit('show-paywall', { action: 'select-code', framework: this.codegenLang });
        }
        if (selectedText) {
          this.trackExportedCodeInitiated({
            exported: 'panel_code',
            panel,
            action: 'copy_to_clipboard'
          });
          this.$trackEvent('omniview.code-mode.select-code', {
            type: this.codegenLang,
            'style-type': this.styleType,
            panel,
            framework: this.codegenLang
          });
          this.setMarketingActiveUser('developer');
          EventBus.$emit('code-select', { panel });
        }
      }, 100);
    },
    async handleSendFeedback() {
      this.$emit('open-feedback');
    },
    replaceUrlsWithFilenames(str) {
      Object.values(this.currentNodeMd5Map).forEach((obj) => {
        const filename = prettifyAssetFilename(obj.filename, this.currentComponent?.name);
        str = str.replaceAll(obj.url, filename);
      });
      return str;
    },
    getMenuOptionsForTab(tab, label) {
      // Dynamically create the context menu options based on the current status
      const options = [];

      if (this.selected || tab === 'styleguide') {
        options.push({ id: 'copy_to_clipboard', name: `Copy ${label} to clipboard` });
      }

      if (this.selected) {
        options.push({ divider: true }, { id: 'download_package', name: 'Export component as ZIP' });

        const isCodePen = this.codegenLang == 'html';
        if (isCodePen) {
          options.push({ id: 'codepen', name: 'Open in CodePen' });
        } else {
          options.push({ id: 'codesandbox', name: 'Open in CodeSandbox' });
        }
      }

      return options;
    },
    convertTabToPanel(tab) {
      if (tab === 'css') {
        return 'css';
      } else if (tab === 'lang') {
        return this.codegenLang;
      } else if (tab === 'styleguide') {
        return 'styleguide';
      } else {
        this.$sentry.captureMessage('Invalid tab name: ' + tab);
      }
    },
    handleTabMenuOpened(tab) {
      const panel = this.convertTabToPanel(tab);
      this.$trackEvent('omniview.code-mode.open-submenu', { panel });
    },
    handleTabMenuAction(action, tab) {
      if (this.shouldShowPaywall) {
        return EventBus.$emit('show-paywall', { action, framework: this.codegenLang });
      }

      // Copying the styleguide is always possible
      if (!this.selected && tab !== 'styleguide') {
        toastWarning(`Please select an element first`);
        return;
      }

      this.trackExportedCodeInitiated({
        exported: action === 'copy_to_clipboard' ? 'panel_code' : 'component',
        panel: this.convertTabToPanel(tab),
        action
      });

      if (action === 'copy_to_clipboard') {
        if (tab === 'lang') {
          EventBus.$emit('copy-code', {
            text: this.nodeHTML.copyHTML,
            type: this.codegenLang,
            panel: 'html',
            emitExportedCodeEvent: true
          });
        } else if (tab === 'css') {
          EventBus.$emit('copy-code', {
            text: this.currentNodeCSS,
            type: this.codegenLang,
            panel: 'css',
            emitExportedCodeEvent: true
          });
        } else if (tab === 'styleguide') {
          EventBus.$emit('copy-code', {
            text: this.currentStylesheet.full,
            type: 'styleguide',
            panel: 'styleguide',
            emitExportedCodeEvent: true
          });
        }
      } else if (action === 'download_package') {
        EventBus.$emit('export-component');
      } else if (action === 'codepen') {
        EventBus.$emit('OPEN_IN_CODEPEN');
      } else if (action === 'codesandbox') {
        EventBus.$emit('generate-code', {
          isCodePen: false,
          isCodeSandbox: true,
          forceGenerate: true,
          isFromExportModal: false,
          isExported: true
        });
      } else {
        this.$sentry.captureMessage('Invalid tab menu action: ' + action);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.code-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.side-panel {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  display: flex;
}
.regenerate-codegen-req {
  cursor: pointer;
  padding: 3px;
  margin-right: 10px;
  img {
    width: 20px;
  }
}
.feedback {
  background: #3b3b3b;
  border-radius: 100px;
  width: 85px;
  height: 24px;
  padding: 0 12px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.feedback-popover {
  width: 270px;
  background: #3b3b3b;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  padding: 10px 16px;
  color: white;
  outline: none;
  &:focus {
    outline: none;
  }
}
.send-feedback {
  margin-left: auto;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>

<style lang="scss">
.feedback-textarea {
  background: #333333;
  border-radius: 6px;
  padding: 8px 8px;
  border: 1px solid;
  border-color: transparent;
  height: 110px;
  color: white;
  caret-color: var(--primary);

  &:focus {
    border-color: var(--primary);
  }

  &.active {
    border-color: var(--primary);
  }

  &::placeholder {
    color: white;
    opacity: 0.4;
    font-size: 14px;
  }
}

.tag-bg {
  background: #212122;
  width: calc(100% - 3px);
  position: absolute;
  pointer-events: none;
  z-index: 0;
}

.class-preview {
  user-select: none;
  .header {
    padding: 18px 20px;
    font-size: 14px;
    box-shadow: 0 1px 0 0 #2a2a2a;
    color: #ffcb6b;
    .text {
      color: white;
      margin-right: 5px;
    }
    .classname {
      color: #ffcb6b;
    }
  }

  .pages-list {
    padding: 9px 0px;
    max-height: 146px;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      cursor: pointer;
      background-color: #303030;
    }
    &::-webkit-scrollbar-thumb {
      background: #b3b2b2;
      border-radius: 200px;
    }
    // padding-top: 9px;
    .page-item {
      // border-radius: 6px;
      height: 32px;
      flex-shrink: 0;
      padding: 0 20px;
      &:hover {
        cursor: pointer;
        background: #2a2a2a;
      }
    }
  }
}
</style>

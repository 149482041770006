<template>
  <div class="loader">
    <div class="dot dot-1"></div>
    <div class="dot dot-2"></div>
    <div class="dot dot-3"></div>
  </div>
</template>

<script>
export default {
  name: 'InlineLoader'
};
</script>

<style lang="scss" scoped>
.loader {
  display: inline-block;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  border-radius: 100%;
  display: inline-block;
  animation: inline-loader 1.4s infinite ease-in-out both;
  margin: 0 1px;
}

.dot-1 {
  animation-delay: -0.32s;
}

.dot-2 {
  animation-delay: -0.16s;
}

@keyframes inline-loader {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>

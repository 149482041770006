import api from '@/api';

export default {
  methods: {
    askForAccess(type = 'team') {
      const { teamSlug: team_slug, projectId: project_short_id } = this.$route.params;
      let payload;
      if (type === 'project') {
        payload = { project_short_id };
      } else if (type === 'team') {
        payload = { team_slug };
      } else {
        throw new Error('Invalid Request access type');
      }

      return api.post('/rpc/request_access', payload);
    },
    approveContributorAccess({ email, teamSlug }) {
      if (!teamSlug) {
        teamSlug = this.$route.params.teamSlug;
      }
      let payload = { team_slug: teamSlug, email };

      return api.post('/rpc/request_access_approved', payload);
    }
  }
};

<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">Sorry to see you go... hope to see you again!</div>

    <PermissionsLayout role="admin">
      <div class="body" slot="no-permission">
        <NoAccessDisclaimer @close="(data) => $emit('close', data)" />
      </div>

      <div class="body">
        <div class="disclaimer">
          <div v-for="(row, index) of benefits" :key="index" class="disclaimer-content">
            <div v-if="row.isDivider" class="divider" />
            <div v-else class="disclaimer-row"><svg-icon :name="row.icon" :size="24" /> {{ row.text }}</div>
          </div>
        </div>

        <div class="checkbox">
          <an-checkbox v-model="agree">
            {{ checkboxText }}
          </an-checkbox>
        </div>

        <transition name="fade">
          <div class="actions" v-if="agree">
            <an-button id="barecancel-trigger" @click="onClickCancel" :isWorking="isWorking"> Cancel plan </an-button>
            <an-link @click="cancelCancellation">Dismiss</an-link>
          </div>
        </transition>
      </div>
    </PermissionsLayout>

    <FeedbackPrompt
      :show="showFeedbackPrompt"
      @submit="cancelPlan"
      @close="closeFeedbackPrompt"
      @dismiss="dismissFeedbackPrompt"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { get } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';
import { formatDate } from '@/utils/date';
import { SubscriptionMixin, UserMixin } from '@/mixins';
import { toastError, toastSuccess, showIntercomBubble, hideIntercomBubble } from '@/services/bus';
import { cancelSubscription } from '@/services/stigg';

import FeedbackPrompt from '@/views/Downgrade/FeedbackPrompt';
import NoAccessDisclaimer from '@/views/Downgrade/NoAccessDisclaimer';
import PermissionsLayout from '@/components/Layout/PermissionsLayout';

export default {
  name: 'cancel-plan',
  data() {
    return {
      currentSubscriptionEnd: null,
      agree: false,
      isWorking: false,
      intercomBubbleTimeoutId: null,
      showFeedbackPrompt: false,
      showNoPermissions: false
    };
  },
  mounted() {
    this.setIntercomTimeout();
    this.fetchNextBillingDate();
  },
  destroyed() {
    this.clearIntercomTimeout();
  },
  mixins: [SubscriptionMixin, UserMixin],
  components: {
    FeedbackPrompt,
    PermissionsLayout,
    NoAccessDisclaimer
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({ isActiveExperiment: 'experiments/isActive', isPro: 'teamMemberships/isPro' }),
    ...mapGetters('stripeSubscriptions', ['activeSubscription']),
    planName() {
      const { product_name = 'Free' } = this.activeSubscription;
      const _planName = product_name === 'Prototype' ? 'Basic' : product_name;
      return `${_planName} plan`;
    },
    isTrialing() {
      return !!this.activeSubscription?.is_trial;
    },
    title() {
      return this.isTrialing ? `Cancel ${this.planName} Trial` : 'Cancel subscription';
    },
    benefits() {
      let list = [
        'Export code feature will be disabled.',
        'Existing team projects will be locked.',
        'Public link & custom domains will be disabled.'
      ].map((text) => ({ icon: 'circle-x', text }));

      if (!this.isPro) {
        list = list.filter(({ text }) => text !== 'Export code feature will be disabled.');
      }
      return list;
    },
    checkboxText() {
      const { planName, currentSubscriptionEnd, isTrialing, activeSubscription } = this;
      let text;
      let dateStatement = currentSubscriptionEnd ? ` on ${currentSubscriptionEnd}` : '';

      if (this.usesStiggIntegration) {
        return `I understand that I will lose access to Anima paid and my projects.`;
      }
      if (isTrialing) {
        dateStatement = formatDate(activeSubscription.trial_end, 'MMM DD, YYYY');
        text = `I understand that my Anima ${planName} remain active until ${dateStatement}. Once my trial ends, I’ll not have full access to all my projects.`;
      } else {
        text = `I understand that I will lose access to Anima ${planName} and my projects${dateStatement}.`;
      }
      return text;
    },
    usesStiggIntegration() {
      return this.team?.uses_stigg_integration;
    }
  },
  methods: {
    setIntercomTimeout() {
      this.intercomBubbleTimeoutId = setTimeout(this.promptIntercomBubble, 2000);
    },
    clearIntercomTimeout() {
      clearTimeout(this.intercomBubbleTimeoutId);
      hideIntercomBubble();
    },
    promptIntercomBubble() {
      const { created_at } = this.activeSubscription || {};
      const timePassedInMonths = dayjs().diff(created_at, 'M');
      const message = `${timePassedInMonths < 1 ? 'Leaving so soon??' : 'Hey,'}\nWe'd love to help, what went wrong?`;

      showIntercomBubble({ props: { message } });
      this.intercomBubbleTimeoutId = null;
    },
    getCustomerId() {
      const {
        team,
        activeSubscription: { _service: service } // service === 'stripe' || 'paypal'
      } = this;
      const key = `${service}_customer_id`;
      const testKey = `test_${key}`;
      return get(team, key) || get(team, testKey);
    },
    async cancelPlan() {
      const { id: teamId } = this.team;
      const { id: subscriptionId, _service: service } = this.activeSubscription ?? {};
      const eventProps = { teamId, subscriptionId, service };
      try {
        this.showFeedbackPrompt = false;
        this.$trackEvent('cancel-plan-page.cancel-plan-button.click');

        this.isWorking = true;
        if (this.usesStiggIntegration) {
          await cancelSubscription(teamId);
        } else {
          await this.cancelSubscription({ service, teamId, subscriptionId });
        }

        this.$trackEvent('cancel-plan-page.cancel-plan.success', eventProps);
        this.$gtm.trackEvent({
          event: 'cancel_account',
          event_category: 'Cancel Account',
          event_action: this.user?.role,
          event_label: this.isActiveUserByRole() ? 'Activated' : 'Not Activated',
          external_id: this.user?.id,
          first_name: this.user?.first_name,
          last_name: this.user?.last_name,
          email: this.user?.email
        });
        this.$router.push({ name: 'team-settings-billing' });
        toastSuccess('Your Anima plan was successfully downgraded to free');
      } catch (err) {
        this.$trackEvent('cancel-plan-page.cancel-plan.failure', { message: err.message, ...eventProps });
        toastError('Failed cancelling your plan.');
      } finally {
        this.isWorking = false;
      }
    },
    async fetchNextBillingDate() {
      const { id: subscriptionId, _service: service } = this.activeSubscription ?? {};
      const {
        data: { current_period_end }
      } = await this.fetchSubscriptionData({ service, subscriptionId });
      this.currentSubscriptionEnd = formatDate(current_period_end, 'MMM DD, YYYY');
    },
    cancelCancellation() {
      this.$trackEvent('cancel-plan-page.nevermind.click');
      this.$router.push({ name: 'team-settings-billing' });
    },
    onClickCancel() {
      this.$trackEvent('cancel-plan-page.feedback-form.show');
      this.clearIntercomTimeout();
      this.showFeedbackPrompt = true;
    },
    closeFeedbackPrompt() {
      this.$trackEvent('cancel-plan-page.feedback-form.close');
      this.showFeedbackPrompt = false;
    },
    dismissFeedbackPrompt() {
      const { params } = this.$route;
      this.$trackEvent('cancel-plan-page.feedback-form.dismiss');
      this.showFeedbackPrompt = false;
      this.$emit('close', { redirect: { name: 'team', params } });
      toastSuccess('Yay! Happy to have you with us :)');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.illustration {
  height: 140px;
}
.body {
  width: 550px;
  border-top: var(--border);
  > .checkbox {
    margin-top: 30px;
  }
}
.disclaimer-content {
  + .disclaimer-content {
    margin-top: 20px;
  }
}
.disclaimer-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .svg-container {
    margin-right: 12px;
  }
}
.divider {
  height: 1px;
  width: 100%;
  background: var(--divider-color);
  margin: 30px 0;
}
</style>

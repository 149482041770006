import { createStoreModule } from '../utils';

const BREAKPOINT = 1070;

const MOBILE_MATCH_MEDIA = `(max-width: ${BREAKPOINT - 1}px)`;
const DESKTOP_MATCH_MEDIA = `(min-width: ${BREAKPOINT}px)`;

const mobileMatch = window.matchMedia(MOBILE_MATCH_MEDIA);
const desktopMatch = window.matchMedia(DESKTOP_MATCH_MEDIA);

export default createStoreModule({
  name: 'screenSize',
  state: {
    isMobile: mobileMatch.matches,
    isDesktop: desktopMatch.matches
  },
  mutations: {
    setIsMobile: (state, { matches }) => (state.isMobile = matches),
    setIsDesktop: (state, { matches }) => (state.isDesktop = matches)
  },
  actions: {
    listen({ commit }) {
      try {
        // Chrome & Firefox
        mobileMatch.addEventListener('change', (e) => commit('setIsMobile', e));
        desktopMatch.addEventListener('change', (e) => commit('setIsDesktop', e));
      } catch (e1) {
        try {
          // Safari
          mobileMatch.addListener('change', (e) => commit('setIsMobile', e));
          desktopMatch.addListener('change', (e) => commit('setIsDesktop', e));
        } catch (e2) {
          console.error(e2);
        }
      }
    }
  }
});

<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">
      All of your screens, prototypes, assets and comments will be permanently deleted and you’ll not be able to undo
      this action.
    </div>
    <div class="actions">
      <an-button @click="_delete" data-cy="delete-project" :isWorking="isWorking">Delete</an-button>
      <an-link @click="cancel">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EventBus, toastError, toastSuccess } from '@/services/bus';

export default {
  name: 'delete-project',
  data() {
    return {
      isWorking: false
    };
  },
  computed: {
    ...mapState('projects', { project: 'currentItem', allProjects: 'items' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({
      isViewer: 'teamMemberships/isViewer'
    }),
    ctaData() {
      return {
        label: 'Delete',
        loading: this.isWorking,
        withCancel: true
      };
    },
    title() {
      const { name } = this.project;
      return `Delete project - ${name}`;
    }
  },
  methods: {
    ...mapActions({
      createProject: 'projects/create',
      deleteProject: 'projects/delete',
      fetchProjects: 'projects/fetchAllOfParent'
    }),
    async _delete() {
      const { id, name } = this.project;
      this.isWorking = true;

      this.$trackEvent('delete-project.delete-button.click');

      try {
        await this.deleteProject({ id });
        EventBus.$emit('update-projects', { skipCache: true });
        EventBus.$emit('reload-team-info');

        let remainingProjects = {};
        try {
          remainingProjects = await this.fetchProjects({
            parent: 'teams',
            id: this.team.slug,
            params: { is_slug: true },
            skipCache: true
          });
        } catch (e) {
          // Fetching projects failed for some reason, fallback to unsafe cached value
          this.$sentry.captureException(e);
          remainingProjects = { total: this.allProjects.length - 1 };
        }
        this.$trackEvent('delete-project.delete.success', { remainingProjects: remainingProjects.total });

        if (remainingProjects.total === 0) {
          // Create a hidden default project if there are none
          const projectName = this.user?.name?.split(' ')[0] + "'s project";
          await this.createProject({
            parent: 'teams',
            id: this.team.id,
            payload: { name: projectName, is_default_project: true },
            storeResult: true
          });
        }
        this.$emit('close', { redirect: { name: 'team' } });
        toastSuccess(`${name} was successfully deleted.`);
      } catch (err) {
        this.$emit('close', { redirect: { name: 'team' } });

        if (err.response && err.response.status === 403 && this.isViewer) {
          toastError(`Failed deleting "${name}". Viewer role can't delete projects.`);
        } else {
          toastError(`Failed deleting "${name}"`);
        }
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('delete-project.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>

<template>
  <div class="code-tab">
    <svg-icon :size="16" class="code-icon" :name="codeIcon" />
    <span class="text">{{ text }}</span>
    <div class="actions">
      <!-- <svg-icon
        @click="$emit('menu-clicked')"
        fill="currentColor"
        :size="20"
        class="dots-icon"
        name="three-dots-vertical"
      /> -->

      <CodeTabMenu
        :menuOptions="menuOptions"
        class="code-tab-menu"
        v-if="menuOptions.length > 0"
        @menu-item-selected="$emit('menu-item-selected', $event)"
        @menu-opened="$emit('menu-opened')"
      >
        <svg-icon class="more-icon" :size="20" fill="currentColor" name="three-dots-vertical"></svg-icon>
      </CodeTabMenu>

      <div
        class="expand-icon flex items-center justify-center"
        v-if="showExpandButton"
        @click="$emit('expand-clicked')"
      >
        <svg-icon
          fill="currentColor"
          stroke="currentColor"
          :size="20"
          :name="isExpanded ? 'code-icons/collapse' : 'code-icons/expand'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CodeTabMenu from '@/components/OmniView/Panels/codeTabMenu.vue';

export default {
  components: {
    CodeTabMenu
  },
  props: {
    text: {
      type: String
    },
    codeIcon: {
      type: String
    },
    showExpandButton: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: true
    },
    menuOptions: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.code-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: -40px;
  background: #333333;
  box-shadow: inset -1px 0px 0px #2d2d2d;
  color: #fff;

  .text {
    margin-right: 8px;
    font-size: 12px;
  }

  .code-icon {
    margin-right: 8px;
  }
  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .dots-icon,
  .expand-icon,
  .more-icon,
  .collapse-icon {
    color: rgba(white, 0.4);
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .expand-icon,
  .collapse-icon {
    margin-left: 16px;
  }

  .code-tab-menu {
    display: flex;
    justify-content: center;
  }
}
</style>

<template>
  <div class="import-container" :class="containerClass">
    <img v-show="stage === 'upload-file'" :src="require('@/assets/illustrations/fimga-import-icon.svg')" />
    <h1 class="title">Import from Figma</h1>
    <h2 v-if="subtitle">{{ subtitle }}</h2>
    <div v-show="stage === 'upload-file'" class="stage">
      <span v-if="!grantedFigmaAPI">We'll need permission to access your Figma file first</span>

      <span class="import-error-message" v-if="errorMessage">{{ errorMessage }}</span>

      <div v-if="grantedFigmaAPI">
        <div class="input">
          <an-input
            data-cy="figma-import-input"
            v-model="figmaFileUrl"
            placeholder="Paste Figma URL here"
            @change="resetFigmaFileUrl"
            @keyup.enter="getFigmaFrames"
            focus
          />
        </div>
      </div>
      <div class="actions">
        <template v-if="!grantedFigmaAPI">
          <an-button data-cy="integrate-with-figma-button" @click="grantFigmaAccess"
            >Integrate Anima with Figma</an-button
          >
          <an-link @click="$emit('close')">Cancel</an-link>
        </template>
        <template v-else>
          <an-button
            data-cy="figma-import-get-frames-button"
            :disabled="!validFigmaUrl()"
            @click="getFigmaFrames"
            :isWorking="isWorking"
            >Import</an-button
          >
          <div v-show="isWorking" class="flex items-center">
            <svg-icon
              style="margin-right: 10px"
              fill="currentColor"
              class="icon-spin"
              name="spin"
              :size="20"
            ></svg-icon>
            <span>Getting file details</span>
          </div>
          <!-- <an-link @click="$emit('close')">Cancel</an-link> -->
        </template>
      </div>
    </div>

    <FigmaSelectFrames
      v-if="stage === 'pick-artboards'"
      :figma-pages="this.frameOptions"
      :project-data="this.projectData"
      :isWorking="this.isWorking"
      @submit="syncFigmaFrames"
      @close="handleClose"
      @get-thumbnails="getThumbnails"
    />

    <div v-if="stage === 'syncing-project'">
      <default-loader />
      <div>{{ figmaProcessStatus }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FigmaSelectFrames from './FigmaSelectFrames.vue';
import DefaultLoader from '@/components/Loading/DefaultLoader.vue';
import { EventBus, toastError } from '@/services/bus';
import pickBy from 'lodash-es/pickBy';
import { updateArrayItemById } from '@/utils/javascript';
import errorHandler from '@/services/errorHandler';
import { useStore } from '@/store';

function encodeFramesByPagesToServer(framesByPages, frameOptions) {
  const res = {};
  for (let pageId in framesByPages) {
    const allFalse = Object.values(framesByPages[pageId]).every((v) => !v);
    if (allFalse) {
      continue;
    }
    res[pageId] = Object.keys(pickBy(framesByPages[pageId]));
  }
  let map = {};
  Object.keys(res).forEach((pageId) => {
    const frameIds = res[pageId];
    const page = frameOptions.find((page) => page.id === pageId);
    const frames = page.frames.filter((frame) => frameIds.includes(frame.id));
    map[pageId] = frames;
  });
  return map;
}

export default {
  components: { FigmaSelectFrames, DefaultLoader },
  name: 'FigmaFileSync',
  data() {
    return {
      grantedFigmaAPI: false,
      figmaFileUrl: '',
      figmaFrame: null,
      isFigmaJamFile: false,
      isWorking: false,
      projectData: {},
      frameOptions: [],
      figmaProcessStatus: null,
      uploadState: 'init'
    };
  },
  created() {
    EventBus.$on('on-figma-ws-message-received', this.handleFigmaWebSocketMessage);
  },
  beforeDestroy() {
    EventBus.$off('on-figma-ws-message-received', this.handleFigmaWebSocketMessage);
  },
  async mounted() {
    this.grantedFigmaAPI = this.user.enabled_figma_api;
    if (!this.currentProject?.id) {
      const { params } = this.$route;
      await this.fetchProject({ id: params.projectId });
    }
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    stage() {
      if (this.figmaProcessStatus !== null) {
        return 'syncing-project';
      }
      if (this.uploadState === 'init' || this.uploadState === 'uploading' || this.uploadState === 'failed') {
        return 'upload-file';
      }
      return 'pick-artboards';
    },
    subtitle() {
      if (this.stage === 'pick-artboards' || this.stage === 'pick-artboards') {
        return 'Select the pages you’d like to sync to your project';
      }
      return '';
    },
    containerClass() {
      if (this.stage === 'pick-artboards') {
        return 'full';
      }
      return '';
    },
    showValidationError() {
      return this.grantedFigmaAPI && this.figmaFileUrl.length > 0 && !this.validFigmaUrl() && !this.isFigmaJamFile;
    },
    showFigmaJamError() {
      return this.grantedFigmaAPI && this.figmaFileUrl.length > 0 && this.isFigmaJamFile;
    },
    errorMessage() {
      if (this.showValidationError) {
        return 'Please provide a valid Figma Design file';
      }
      if (this.showFigmaJamError) {
        return 'This seems like a FigJam file. Please provide a Figma design file';
      }
      return '';
    }
  },
  methods: {
    ...mapMutations({
      setIsFigmaSyncing: 'figmaIntegration/setIsSyncing',
      setFigmaFrames: 'figmaIntegration/setFrames',
      setIsFetchingThumbnails: 'figmaIntegration/setIsFetchingThumbnails'
    }),
    ...mapActions({
      generateModel: 'figmaIntegration/generateModel',
      generateModelAsync: 'figmaIntegration/generateModelAsync',
      getTaskCallback: 'figmaIntegration/getTaskCallback',
      getPages: 'figmaIntegration/getPages',
      getFrames: 'figmaIntegration/getFrames',
      getFramesAsync: 'figmaIntegration/getFramesAsync',
      getFigmaThumbnails: 'figmaIntegration/getThumbnails',
      fetchProject: 'projects/fetchOne'
    }),
    getThumbnails({ frameIds, pageId }) {
      const { projectId } = this.$route.params;
      const payload = { fileId: this.figmaFile, userId: this.user.id, projectId, frameIds, pageId };
      this.getFigmaThumbnails({ payload });
    },
    handleFigmaWebSocketMessage(e) {
      if (!e.eventType) return;
      const data = JSON.parse(e.data);
      switch (e.eventType) {
        case 'get_thumbnails_end':
          {
            const { thumbnails, pageId } = data || {};

            if (thumbnails && pageId) {
              const page = this.frameOptions.find((p) => p.id == pageId);
              if (page) {
                const newFrames = (page.frames || []).map((frame) => {
                  if (thumbnails[frame.id]) {
                    return {
                      ...frame,
                      thumbnailUrl: thumbnails[frame.id]
                    };
                  }
                  return frame;
                });
                const newFrameOptions = updateArrayItemById(this.frameOptions, pageId, { frames: newFrames }, 'id');
                this.frameOptions = newFrameOptions;

                this.setIsFetchingThumbnails({
                  value: false,
                  pageId
                });
              }
            }
          }
          break;

        case 'get_frames_end':
          {
            if (data.status && data.status == 'error') {
              this.isWorking = false;

              if (data.error == 'Invalid token') {
                this.grantedFigmaAPI = false;
                this.uploadState = 'init';
              } else {
                this.uploadState = 'failed';
                toastError('Failed fetching figma pages');
                this.$trackEvent('figma-file-import.get-frames.failed');
              }
            } else {
              this.frameOptions = data.pages || [];
              this.projectData = data.project || {};

              const specificPage = (this.frameOptions || []).find((page) =>
                page.frames.find((frame) => frame.id === this.figmaFrame)
              );
              if (specificPage && this.figmaFrame) {
                this.$trackEvent('figma-file-import.sync-page.view');
                this.isWorking = false;
                return this.syncFigmaFrames({
                  [specificPage.id]: {
                    [this.figmaFrame]: true
                  }
                });
              } else {
                this.isWorking = false;
                this.uploadState = 'pick-artboards';
                this.$emit('updateFigmaPickArtboards', true);
              }
            }
          }

          break;

        default:
          break;
      }
    },
    resetFigmaFileUrl() {
      this.selectedFrames = [];
      this.frameOptions = [];
      this.selectPageLabel = 'Select which figma page to import';
      this.figmaFile = null;
      this.figmaFrame = null;
      this.showFrames = false;
    },
    async grantFigmaAccess() {
      this.$trackEvent('figma-file-import.grant-figma-access.start');
      const { platform } = this.$route.query;
      if (!platform) {
        await this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, platform: 'web' }
        });
      }
      const { dispatch } = useStore();
      const callbackUrl = this.$route.fullPath;
      const figmaOAuthUrl = await dispatch('figmaOAuth/generateFigmaOAuthUrl', { callbackUrl });
      window.open(figmaOAuthUrl, '_self');
    },
    validFigmaUrl() {
      try {
        const url = new URL(this.figmaFileUrl);
        const params = new URLSearchParams(url.search);
        const path = url.pathname;
        const typeValue = params.get('type');
        this.isFigmaJamFile = false;

        if (url.origin !== 'https://www.figma.com') {
          return false;
        }

        if (typeValue === 'whiteboard') {
          this.isFigmaJamFile = true;
          return false;
        }

        this.figmaFile = path.split('/')[2];
        this.figmaFrame = params.get('node-id') || null;

        return path.startsWith('/file/') && this.figmaFile.length === 22;
      } catch (error) {
        return false;
      }
    },
    async getFigmaFrames() {
      if (this.isWorking) return;
      const { projectId } = this.$route.params;
      if (!this.validFigmaUrl()) return;
      this.$trackEvent('figma-file-import.file-upload.start');
      const payload = { fileId: this.figmaFile, userId: this.user.id, projectId };

      try {
        this.isWorking = true;
        this.showFrames = false;
        await this.getFramesAsync({ payload });
      } catch (err) {
        this.uploadState = 'failed';
        errorHandler.captureExceptionAndTrack(err, {
          name: 'figma-file-import.get-frames.failed',
          data: { figmaFileUrl: this.figmaFileUrl }
        });
        this.isWorking = false;
        toastError('Failed fetching figma pages');
      }
    },
    async syncFigmaFrames(framesByPages) {
      if (this.isWorking) return;
      const { projectId } = this.$route.params;
      this.$trackEvent('figma-file-import.sync-file.start');
      this.isWorking = true;
      this.setIsFigmaSyncing({
        projectId,
        value: true
      });
      const payload = {
        userId: this.user.id,
        fileId: this.figmaFile,
        project: this.currentProject.id,
        framesByPages: encodeFramesByPagesToServer(framesByPages, this.frameOptions),
        returnProjectUrl: true,
        projectId
      };

      try {
        const { frames } = await this.generateModelAsync({ payload });
        EventBus.$emit('start_figma_import_polling');
        const framesMap = {};
        this.frameOptions.map((p) => {
          let frames = p.frames || [];
          for (let i = 0; i < frames.length; i++) {
            const frame = frames[i];
            framesMap[frame.id] = frame;
          }
          return p;
        });

        const figmaFrames = frames
          .filter(Boolean)
          // .map(frame => ({ ...frame, thumb_url: frame.thumbnailUrl, isSyncing: true }));
          .map((frame) => {
            let obj = { ...frame };
            if (framesMap[frame.id]) {
              obj = framesMap[frame.id];
            }
            return {
              ...obj,
              thumb_url: obj.thumbnailUrl,
              isSyncing: true
            };
          });
        this.setFigmaFrames(figmaFrames);
        this.handleClose();
        // const { taskId } = await this.generateModelAsync({ payload });
        // const taskPayload = { taskId, taskDetails: true };

        // const getModelInterval = setInterval(async () => {
        //   const { data: taskData, status } = await this.getTaskCallback({ payload: taskPayload });
        //   if (status === 200) {
        //     this.$trackEvent('figma-file-import.sync-file.success');

        //     EventBus.$emit('reload-project-data');
        //     clearInterval(getModelInterval);
        //     this.handleClose();
        //   } else {
        //     this.figmaProcessStatus = taskData.details;
        //   }
        // }, 5000);
      } catch (err) {
        this.figmaProcessStatus = null;
        this.isWorking = false;
        this.setIsFigmaSyncing({
          projectId,
          value: false
        });
        errorHandler.captureException(err);

        toastError('Failed generating the Anima model');
      }
    },
    handleError() {
      this.$trackEvent('figma-file-import.sync-file.failure');
      toastError('Oops. Something went wrong. Please try again.');
      this.handleClose();
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_mixins.scss';
.title {
  margin-bottom: 30px;
  margin-top: 5px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 40px;
}
.import-container {
  text-align: center;
  width: 400px;
  max-width: unset;
  img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  .input {
    width: 400px;
  }
  .stage {
    align-items: center;
  }
}
.import-container.full {
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.file-name {
  margin: 84px 0;
}
.divider {
  height: 1px;
  background: var(--tertiary);
  width: 100%;
  margin: 30px 00;
}
.stage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-align: left;
  &.pick-artboards p {
    margin: 8px 0;
  }
}
.import-error-message {
  margin-bottom: 30px;
}
</style>

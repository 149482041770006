<template>
  <div class="container">
    <OnboardingTopBar homepageUrl="/" />
    <LoadingScreen />
    <div class="title">Completing integration...</div>
    <div class="subtitle">We are completing the integration with Figma. This may take a few seconds.</div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/Loading/LoadingScreen';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import auth from '@/auth';
import { toastError } from '@/services/bus';
import { useStore } from '@/store';

export default {
  data() {
    return {
      email: '',
      error: false
    };
  },
  components: {
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.onMounted();
  },
  methods: {
    async generateFigmaAccessToken() {
      const { dispatch } = useStore();
      const code = this.$route.query?.code;
      const OAuthState = this.$route.query?.state;
      return dispatch('figmaOAuth/generateFigmaAccessToken', { code, OAuthState });
    },
    async fetchUserData() {
      const { dispatch } = useStore();
      const { email } = dispatch('users/fetchOne', { id: 'me' });
      this.email = email;
    },
    async onMounted() {
      const { state } = useStore();
      try {
        await this.generateFigmaAccessToken();
        await this.fetchUserData();
        this.$trackEvent('figma.oauth.success');

        const callbackUrl = state.figmaOAuth.callbackUrl;

        if (callbackUrl) {
          this.$router.replace(callbackUrl);
        }
      } catch (err) {
        const { message } = err?.response?.data || { message: 'Figma integration failed' };
        this.$trackEvent('figma.oauth.failed', { message });
        toastError(`Oh no, something went wrong! ${message}`);
      }
    },
    handleOpen() {
      this.$trackEvent('integrated.open-platform-link.click', { platform: this.getPlatformName() });
      if (this.$route.query.platform === 'web') {
        this.$router.push({ name: 'root' });
      } else {
        window.location.replace('figma://animaapp');
      }
    },
    getPlatformName() {
      if (this.$route.query.platform === 'web') {
        return 'Anima';
      } else {
        return 'Figma';
      }
    },
    switchAccount() {
      const { query } = this.$route;

      this.$trackEvent('integrated.switch-account-link.click');

      auth.logout();
      this.$router.replace({ name: 'login', query });
      window.location.reload();
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';

.container {
  max-width: 100%;
}
</style>

<template>
  <div ref="lottieAnimation" :style="style" @click="onClick" />
</template>

<script>
import lottie from 'lottie-web';

export default {
  data() {
    return {
      animObj: null
    };
  },
  props: {
    fileName: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: '100%'
    },
    renderer: {
      type: String,
      default: 'svg'
    },
    playOnClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    animationData() {
      return require(`@/assets/lottie/${this.fileName}.json`);
    },
    style() {
      const { width: _w, height: _h } = this;
      const width = typeof _w === 'number' ? `${_w}px` : _w;
      const height = typeof _h === 'number' ? `${_h}px` : _h;
      return { width, height };
    }
  },
  mounted() {
    this.updateAnimation();
  },
  methods: {
    updateAnimation() {
      const { loop, autoplay, animationData, renderer } = this;
      const container = this.$refs.lottieAnimation;
      const rendererSettings = { preserveAspectRatio: 'xMidYMid meet' };
      this.animObj = lottie.loadAnimation({
        container,
        renderer,
        loop,
        autoplay,
        animationData,
        rendererSettings
      });
    },
    onClick() {
      this.$emit('click');
      if (this.playOnClick) {
        this.play();
      }
    },
    play() {
      this.animObj?.play();
    },
    stop() {
      this.animObj?.stop();
    }
  },
  watch: {
    animationData() {
      this.updateAnimation();
    }
  }
};
</script>

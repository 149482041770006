<template>
  <div class="list-card-container" data-cy="list-card" :class="cardClass">
    <div class="list-card">
      <div
        class="thumbnail"
        :style="thumbStyle"
        @click="$emit('click')"
        @mouseover="hoverThumb = true"
        @mouseout="hoverThumb = false"
      >
        <div :class="actionsClasses" v-show="isDesktop || isLocked">
          <div class="popover-menu-wrapper">
            <PopoverMenu :items="popoverActions" position="right" v-if="actions && actions.length">
              <div data-cy="project-cards-options" slot="reference">
                <svg-icon fill="currentColor" style="color: #fff" name="three-dots-horizontal" :size="30" />
              </div>
            </PopoverMenu>
          </div>
          <div class="centered-content">
            <svg-icon name="lock" :size="30" v-if="isLocked" />
            <Members :members="members" :peekAmount="4" :size="30" :avatarStyle="avatarStyle" v-else />
          </div>
          <span>{{ thumbnailText }}</span>
        </div>
      </div>
      <div class="content">
        <div class="title">{{ project.name }}</div>
        <div class="subtitle">
          <transition name="slide-fade">
            <span
              @mouseover="hoverSubtitle = !(subtitle || '').endsWith('ago')"
              @mouseleave="hoverSubtitle = false"
              :key="`hover-subtitle-${hoverSubtitle}`"
            >
              {{ sortingText }}
            </span>
          </transition>
          <div class="icons">
            <template v-if="flags.domain || flags.hasNewComments || flags.views">
              <svg-icon
                name="rocket-full"
                :size="24"
                v-if="flags.domain"
                v-tip="{
                  content: 'Connected to domain',
                  placement: 'bottom-end'
                }"
              />
              <svg-icon v-if="flags.hasNewComments" name="talking-bubble-alert" :size="24" />
              <svg-icon name="eye" :size="24" v-if="flags.views" />
            </template>
            <template v-else-if="isDesignFileActive">
              <div
                class="design-url-icon"
                v-if="project.figma_design_url"
                v-tip="{
                  content: 'Get Figma file',
                  placement: 'bottom-end'
                }"
                @click="onClickDesignToolIcon('figma')"
              >
                <svg-icon name="project-figma-design" :size="30" />
              </div>
              <div
                class="design-url-icon"
                v-if="project.xd_design_url"
                v-tip="{
                  content: 'Get Adobe Xd file',
                  placement: 'bottom-end'
                }"
                @click="onClickDesignToolIcon('xd')"
              >
                <svg-icon name="project-xd-design" :size="30" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="inviter" v-if="project.inviter">
      <div>
        <div class="avatar"><an-user-avatar :user="project.inviter" :size="30" /></div>
        <div class="shared-by">Shared by {{ inviterDetails }}</div>
      </div>
      <div class="date">{{ invitationDate }}</div>
    </div>
  </div>
</template>

<script>
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import Members from '@/components/Members/Members';
import { formatDateTimeConversational, formatDateTimeShort } from '@/utils/date';
import { getCdnUrl } from '@/utils/urls';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      hoverThumb: false,
      hoverSubtitle: false,
      inviterNameMaxLength: 15,
      avatarStyle: {
        borderColor: 'rgba(59, 59, 59, 0.95)'
      }
    };
  },
  components: {
    PopoverMenu,
    Members
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    subtitle: {
      type: String
    },
    actions: {
      type: Array
    },
    flags: {
      type: Object,
      default: () => ({})
    },
    forceShowActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('experiments', ['isDesignFileActive']),
    thumbStyle() {
      const { thumb_url: thumbUrl } = this.project;
      const backgroundSrc =
        getCdnUrl(thumbUrl, { width: 400, height: 200 }) || require('@/assets/illustrations/empty-project.svg');
      return {
        background: `url("${backgroundSrc}") no-repeat`,
        backgroundSize: '100%'
      };
    },
    actionsClasses() {
      const { hoverThumb, isLocked } = this;
      return { actions: true, hide: !hoverThumb && !isLocked };
    },
    popoverActions() {
      const { project, actions } = this;
      return actions.map((action) => ({
        ...action,
        onClick: () => action.onClick(project)
      }));
    },
    sortingText() {
      const { project, hoverSubtitle, subtitle } = this;
      const timeAgo = formatDateTimeConversational(project.updated_at);
      if (hoverSubtitle) {
        return timeAgo;
      }
      return subtitle;
    },
    inviterDetails() {
      const { inviter = {} } = this.project;
      const name = inviter.name || inviter.email;
      if (name?.length > this.inviterNameMaxLength) {
        return `${name.slice(0, this.inviterNameMaxLength)}...`;
      }
      return name || '🤷‍♀️';
    },
    invitationDate() {
      const { invitation_date } = this.project;
      return invitation_date ? formatDateTimeShort(invitation_date) : '';
    },
    isLocked() {
      return !!this.project.is_locked;
    },
    thumbnailText() {
      return this.isLocked ? 'Project locked' : 'Select project';
    },
    cardClass() {
      return this.isLocked ? 'locked' : 'not-locked';
    }
  },
  methods: {
    onClickDesignToolIcon(designTool) {
      const { project } = this;
      this.$emit('click:open-design-file', { project, designTool });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.list-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  background-color: #ffffff;
  box-shadow: var(--shadow);
  border-radius: 10px;
}
.thumbnail {
  border-radius: 10px 10px 0 0;
  height: 192px;
  width: 100%;
}
.content {
  width: 100%;
  background-color: var(--card-background);
  border-radius: 0 0 10px 10px;
}
.title {
  @include secondary-title;
  @include ellipsis;
  font-size: 18px;
  padding: 20px;
  width: 100%;
  border-bottom: var(--border);
}
.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.design-url-icon {
  cursor: pointer;
  + .design-url-icon {
    margin-left: 10px;
  }
}
.subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px 20px;
  width: 100%;
  cursor: default;
}
.inviter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    > .avatar {
      margin-right: 10px;
    }
    > .shared-by {
      @include ellipsis;
    }
  }
  > .date {
    justify-self: flex-end;
    text-align: right;
    color: var(--secondary-text);
  }
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.9);
  border-radius: 10px 10px 0 0;
  color: #fff;
  padding: 16px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &.hide {
    opacity: 0;
  }
  .popover-menu-wrapper {
    height: 30px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .centered-content {
    margin: 20px 0;
    .avatar {
      margin-left: -5px;
    }
  }
}
</style>

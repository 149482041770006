import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';

const getThemeName = (themeName, _variant) => {
  const variant = _variant ? `-${_variant}` : '';
  return `${themeName}${variant}`;
};

const defaultTheme = 'light';

Vue.mixin({
  computed: mapState('theme', { $theme: 'themeName' }),
  methods: {
    ...mapMutations({ setTheme: 'theme/setThemeName' }),
    applyTheme(_themeName, variant) {
      // themeName can be either 'light' or 'dark';
      this.removeTheme();
      const themeName = getThemeName(_themeName, variant);
      document?.body?.setAttribute('theme', themeName);

      this.setTheme(_themeName);
    },
    removeTheme() {
      document?.body?.removeAttribute('theme');

      this.setTheme(defaultTheme);
    }
  }
});

<template>
  <div class="file-row-container">
    <div class="thumbnail">
      <slot name="thumbnail" />
    </div>
    <div class="content">
      <div class="text">
        <div class="file">
          <div class="file-name">{{ fileNameDisplay }}</div>
          <div class="file-extension">{{ fileExtension }}</div>
        </div>
        <div class="upload-date" v-if="uploadDate">Uploaded on {{ date }}</div>
      </div>
      <div>
        <PopoverMenu :items="popoverActions" position="right" v-if="actions && actions.length">
          <div slot="reference" class="clickable-icon actions">
            <svg-icon name="three-dots-horizontal" :size="30" />
          </div>
        </PopoverMenu>
      </div>
    </div>
  </div>
</template>

<script>
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import { formatDate } from '@/utils/date';
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    uploadDate: {
      type: [String, Date],
      default: null
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    PopoverMenu
  },
  computed: {
    date() {
      return formatDate(this.uploadDate, 'DD/MM/YYYY');
    },
    popoverActions() {
      const { item, actions } = this;
      return actions.map((action) => ({
        ...action,
        onClick: () => action.onClick(item)
      }));
    },
    fileNameDisplay() {
      const extRegex = new RegExp(`${this.fileExtension}$`, 'i');
      let name = this.fileName.replace(extRegex, '');
      if (name.length > 20) {
        name = name.substr(0, 20) + '..';
      }
      return name;
    },
    fileExtension() {
      return this.item?.ext?.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.file-row-container {
  display: flex;
  align-items: center;
  + .file-row-container {
    margin-top: 30px;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.thumbnail {
  margin-right: 20px;
}
.file {
  display: flex;
  font-weight: 700;
  margin-bottom: 5px;
}
.file-name {
  @include ellipsis;
}
.upload-date {
  font-size: 12px;
  opacity: 0.4;
}
.actions {
  opacity: 0.3;
  &.clickable-icon:hover {
    background-color: none;
  }
}
</style>

<template>
  <transition name="fade" mode="out-in">
    <div :class="['personalized-onboarding-container', theme]" data-cy="personalized-onboarding">
      <div class="nav">
        <div class="back" @click="$emit('close')">
          <svg-icon name="arrow-right" :size="24" :fill="theme === 'light' ? 'currentColor' : 'white'" />
        </div>
        <div :class="['progress', theme]">
          <span>{{ activeStageIndex }}/{{ stages.length }}</span>
          <div class="bar">
            <ProgressBar :progress="progress" :backgroundColor="progressBackgroundColor" :barColor="progressBarColor" />
          </div>
        </div>
      </div>
      <div class="header">
        <div class="emoji"><span class="waving-hand">👋</span></div>
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="stages">
        <Stages
          :theme="theme"
          :list="stages"
          :selectedIndex="selectedStageIndex"
          :activeIndex="activeStageIndex"
          :eventProps="eventProps"
          @click:stage="onClickStage"
          @click:cta="onClickCta"
          @click:skip="onSkipStage"
        />
      </div>
      <div class="done-section" v-if="status === 'done'">
        <div class="done-icon">
          <svg-icon name="circle-check-inverse" :size="60" />
        </div>
        <div class="title">{{ doneTitle }}</div>
        <div class="done-text">{{ doneText }}</div>
        <an-button @click="onDoneButtonClick">{{ flow.done_cta_text }}</an-button>
      </div>
    </div>
  </transition>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import Stages from '@/components/PersonalizedOnboarding/Stages.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash-es';
import { EventBus } from '@/services/bus';

export default {
  name: 'personalized-onboarding-panel',
  data() {
    return {
      selectedStageIndex: 0,
      turnedToDone: false
    };
  },
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: (v) => ['dark', 'light'].includes(v)
    }
  },
  components: {
    ProgressBar,
    Stages
  },
  computed: {
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    ...mapState('webappSystem', ['showConfettiOnDone']),
    ...mapGetters({
      flow: 'userOnboardings/onboardingFlow',
      stages: 'userOnboardings/onboardingStages',
      status: 'userOnboardings/onboardingStatus'
    }),
    activeStageIndex() {
      return this.userOnboarding.active_stage_index;
    },
    totalStages() {
      return this.stages.length;
    },
    progress() {
      return Math.min(this.activeStageIndex / this.totalStages, 1);
    },
    progressBarColor() {
      return this.theme === 'light' ? 'white' : 'var(--green)';
    },
    progressBackgroundColor() {
      return this.theme === 'light' ? 'rgba(216, 216, 216, 0.5)' : 'white';
    },
    title() {
      return this.flow?.title;
    },
    subtitle() {
      return this.status === 'done' ? '' : "You're almost there!";
    },
    doneTitle() {
      return 'All done!';
    },
    doneText() {
      return this.flow?.done_text;
    },
    eventProps() {
      return {
        onboardingType: this.flow?.slug,
        context: 'main-panel'
      };
    }
  },
  methods: {
    ...mapActions({
      nextStage: 'userOnboardings/nextStage'
    }),
    onClickStage(stage) {
      this.$trackEvent('personalized-onboarding.stage.click', { stage: stage.title, ...this.eventProps });
      this.selectedStageIndex = stage.index;
    },
    onClickCta(stage) {
      const { data: stageData, slug } = stage;
      if (!isEmpty(stageData)) {
        switch (stageData.action) {
          case 'navigate':
            this.onNavigation(stageData.data);
            break;
          case 'next-stage':
            this.nextStage({ currentStageSlug: slug });
            break;
          default:
            break;
        }
      }
    },
    onSkipStage(stage) {
      const { slug } = stage;
      this.nextStage({ currentStageSlug: slug, stagePayload: { skipped_previous_stage: true } });
    },
    onNavigation({ route = '', route_name: name = '', route_params: params = {}, route_query: query = {} }) {
      if (route.startsWith('http')) {
        window.open(route, '_blank');
      } else {
        const routeToResolve = route || { name, params, query };
        const resolved = this.$router.resolve(routeToResolve);
        this.$router.push(resolved.href);
      }
    },
    onDoneButtonClick() {
      this.$trackEvent('personalized-onboarding.done-cta.click', { cta: this.flow?.done_cta_text, ...this.eventProps });
      this.onNavigation({ route: this.flow?.done_cta_link });
      this.$emit('close');
    },
    onActiveStageIndexChange(newIndex, previousIndex) {
      const previousStageSubStages = get(this.stages, [previousIndex, 'sub_stages'], []);
      const currentStageData = get(this.stages, [newIndex, 'data'], {});
      const finishedAllPreviousStages = previousStageSubStages.every((subStage) => subStage.data?.is_done);

      // this is only to prevent the else-if.
      if ('skipped_previous_stage' in currentStageData) {
        // advance to next stage
      } else if (!finishedAllPreviousStages) {
        // do not advance to next stage only if:
        // 1. not all previous sub-stages are not finished
        // 2. AND previous stage is not skipped
        return;
      }

      // any other case:
      this.selectedStageIndex = newIndex;
    }
  },
  watch: {
    activeStageIndex: {
      handler: 'onActiveStageIndexChange',
      immediate: true
    },
    status(newStatus, oldStatus) {
      if (newStatus === 'done' && oldStatus && oldStatus !== 'done') {
        this.turnedToDone = true;
        this.selectedStageIndex = null;
        this.$trackEvent('personalized-onboarding.flow.complete', { ...this.eventProps });
        if (this.showConfettiOnDone) {
          EventBus.$emit('confetti');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.personalized-onboarding-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 10000;
  overflow-y: auto;
  color: white;
  cursor: default;
  border-radius: 10px;
  &.dark {
    padding-bottom: 100px;
  }
  &.light {
    color: var(--primary-text);
    box-shadow: var(--slightly-stronger-shadow);
    border: 1px solid rgba(216, 216, 216, 0.5);
  }
}
.done-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 24px 0;
  .back {
    cursor: pointer;
  }
  .progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    &.light {
      background-color: var(--green);
      color: white;
      padding: 0 12px;
      border-radius: 16px;
    }
    .bar {
      margin-left: 10px;
      width: 64px;
    }
  }
}
.header {
  padding: 30px 24px;
}
.emoji {
  font-size: 40px;
  margin-bottom: 20px;
}
.waving-hand {
  animation: wave-animation 1.5s 3;
  transform-origin: 70% 70%;
  display: inline-block;
}
.title {
  font-family: 'Roslindale';
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}
.subtitle,
.done-text {
  font-size: 12px;
  margin-top: 10px;
}
.stages {
  width: 100%;
}
.done-icon {
  margin: 25px 0;
}
.done-text {
  text-align: center;
  margin: 20px 0 30px;
  padding: 0 24px;
  width: 100%;
}
</style>

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { noop } from '@/utils/sugar';
import { isProd } from '@/utils/environment';

let sentryInstance;
if (isProd()) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: ['Non-Error exception captured'],
    denyUrls: [/sc\.lfeeder.com\/lftracker_v1/],
    Vue,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [process.env.APP_BASE_URL, /^\//]
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    debug: false,
    tracesSampleRate: 0.03,
    replaysOnErrorSampleRate: 0.05,
    replaysSessionSampleRate: 0.001,
    tracingOptions: {
      trackComponents: true
    },
    logErrors: false,
    attachProps: true,
    attachStacktrace: true
  });
  Vue.prototype.$sentry = Sentry;
  sentryInstance = Sentry;
} else {
  sentryInstance = {
    //eslint-disable-next-line
    captureException: console.error,
    //eslint-disable-next-line
    captureMessage: console.error,
    startTransaction: () => ({ finish: noop }),
    transaction: {
      startChild: () => ({ finish: noop })
    }
  };
  Vue.prototype.$sentry = sentryInstance;
}

export default sentryInstance;

<template>
  <div class="container">
    <div class="illustration">
      <svg-icon name="community-survey" :width="50" :height="40" />
    </div>
    <div class="text">
      {{ text }}
      <an-link :href="url" target="_blank" variant="info" @click="onClick">{{ ctaText }}</an-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'community-survey-banner',
  data() {
    return {
      text: '2022 Product design community survey is live!',
      ctaText: 'Click to participate',
      url: 'https://csn1y04.na1.hubspotlinks.com/Btc/DM+113/cSN1Y04/VWvPKC3VHcY5N7mPtNSY9J-XW7jDgJ34FhcdxN2lhp1c3q8_QV1-WJV7CgJPjW1hT7Q_970S_7W676T7g1hp3sqW27VGGP8jrjkZW1q92tG4_Z79NW92cNDC325v02W6JmqN35T4ZWbW36ZKly17XfjtW2WF4hQ1ZF20GN3pS7ML4lgN9W8J_tBF4V-W9vW7LGRCm3kK_tyW7Gp-pk7x67LwVKbj_M93BjSrW1N7rd25P985HW38ZlFx5BCspSVW2D1v3MBx2rW8NbWLc3-jdt8W53S__l2NZ_2WW1Rb_ZB76KPnXW5StrQC8Xy6pRW2qZyMv7tYr_9W1j5b-B6x09QQ38Yd1'
    };
  },
  methods: {
    onClick() {
      this.$trackEvent('community-survey-banner.cta.click');
    }
  }
};
</script>

<style>
.warn {
  background-color: rgba(255, 223, 144, 1) !important;
}
</style>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .illustration {
    margin-right: 30px;
  }
}
</style>

<template>
  <div>
    <!-- Site information -->
    <div>
      <div class="section-label">Site images</div>
      <div class="upload-icon">
        <UploadImage
          :url="uploadImageUrl('meta_favicon_url')"
          :src="project.meta_favicon_url"
          @success="reloadProject"
          @click.native="$trackEvent('project-settings-meta.upload-favicon.click')"
        />
        <div class="upload-icon-text">
          <div>Favicon</div>
          <div class="secondary-text small">Upload a logo 32x32 pixels, PNG or JPG formats.</div>
        </div>
      </div>
      <div class="upload-icon">
        <div class="upload-cover-image">
          <div class="label">Cover image</div>
          <UploadImage
            :url="uploadImageUrl('meta_image_url')"
            :src="project.meta_image_url"
            :height="140"
            :width="240"
            @success="reloadProject"
            @click.native="$trackEvent('project-settings-meta.upload-meta-image.click')"
          />
          <div class="secondary-text">
            This image shows when your site is shared on social networks like Facebook, Twitter, etc.
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- SEO -->
    <div class="pad">
      <div class="section-label">SEO settings</div>
      <div class="label">Site title</div>
      <div class="input"><an-input placeholder="Add site title" v-model="projectFields.meta_title" /></div>
      <div class="label">Site description</div>
      <div class="input">
        <an-textarea
          :placeholder="siteDesctptionPlacholder"
          v-model="projectFields.meta_description"
          :rows="3"
          withBorder
          allowMultiline
        />
      </div>
    </div>
    <hr />
    <!-- Analytics -->
    <div class="pad">
      <div class="section-label">Custom code</div>
      <div class="label">Custom HTML tags</div>
      <div class="input">
        <an-textarea
          :placeholder="customHtmlTagsPlaceholder"
          v-model="projectFields.meta_custom_snippet"
          withBorder
          allowMultiline
        />
      </div>
      <div class="label">Analytics code</div>
      <div class="input">
        <an-textarea
          :placeholder="analyticsCodePlaceholder"
          v-model="projectFields.analytics_snippet"
          withBorder
          allowMultiline
        />
      </div>
    </div>
    <hr />
    <!-- Mobile web app -->
    <div class="pad">
      <div class="section-label">Mobile web app</div>
      <div class="mwa">
        <div class="description">Get an app icon for a mobile quick access with public shared links.</div>
        <div class="input">
          <an-input placeholder="Mobile web app name" v-model="projectFields.mobile_app_name" />
        </div>
        <div class="upload-icon">
          <UploadImage
            :url="uploadImageUrl('mobile_app_icon_url')"
            :src="project.mobile_app_icon_url"
            @success="reloadProject"
            @click.native="$trackEvent('project-settings-meta.upload-mobile-app-icon.click')"
          />
          <div class="upload-icon-text">
            <div>App icon</div>
            <div class="secondary-text small">Upload an icon 180x180 pixels, in PNG format.</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Submissions -->
    <Collapse title="Form submissions" @click.native="$trackEvent('project-settings-meta.submissions-section.toggle')">
      <div class="submissions pad">
        <div class="label">
          <an-link
            :href="downloadCsvUrl"
            variant="primary"
            @click.native="$trackEvent('project-settings-meta.download-csv.click')"
            download
          >
            Download CSV
          </an-link>
        </div>
      </div>
    </Collapse>
    <div class="actions fixed" v-if="projectChanged">
      <an-button :isWorking="isWorking" @click="save">Save changes</an-button>
    </div>
  </div>
</template>

<script>
import { keys, pick } from 'lodash-es';
import { mapActions, mapState, mapGetters } from 'vuex';
import auth from '@/auth';
import Collapse from '@/components/Collapse/Collapse';
import UploadImage from '@/components/Upload/UploadImage';
import { toastError, toastSuccess } from '@/services/bus';

export default {
  props: {},
  components: {
    Collapse,
    UploadImage
  },
  data() {
    return {
      siteDesctptionPlacholder: 'Add a description of your web page',
      customHtmlTagsPlaceholder: '<meta... />',
      analyticsCodePlaceholder: '<script type="text/javascript" ... />',
      projectFields: {
        subdomain: '',
        mobile_app_name: '',
        meta_title: '',
        meta_description: '',
        meta_custom_snippet: '',
        analytics_snippet: ''
      },
      isWorking: false
    };
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapGetters({
      isPro: 'teamMemberships/isPro',
      isViewer: 'teamMemberships/isViewer',
      isFree: 'teamMemberships/isFree'
    }),
    projectChanged() {
      const { projectFields } = this;
      return keys(projectFields).some((key) => projectFields[key] !== this.project[key]);
    },
    uploadImageUrl() {
      return (field) => `/v2/uploads/projects/${this.project.id}/${field}`;
    },
    downloadCsvUrl() {
      return `${process.env.API_BASE_URL}/rpc/get_form_entries?project_id=${this.project.id}&t=${auth.getToken()}`;
    }
  },
  methods: {
    ...mapActions({
      fetchCustomDomains: 'domains/fetchAllOfParent',
      createDomain: 'domains/create',
      deleteDomain: 'domains/delete',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    reset() {
      this.onProjectChange();
    },
    onProjectChange() {
      this.projectFields = pick(this.project, [
        'mobile_app_name',
        'meta_title',
        'meta_description',
        'meta_custom_snippet',
        'analytics_snippet'
      ]);
    },
    reloadProject() {
      const { short_id: id } = this.project;
      this.fetchProject({ id, skipCache: true });
    },
    async save() {
      const { short_id: id } = this.project;
      try {
        this.isWorking = true;

        this.$trackEvent('project-settings-meta.save-button.click');

        // update project fields
        if (this.projectChanged) {
          let payload = keys(this.projectFields).reduce((acc, key) => {
            if (this.projectFields[key] !== this.project[key]) {
              return { ...acc, [key]: this.projectFields[key] || null };
            }
            return acc;
          }, {});

          await this.updateProject({ id, payload });
          await this.reloadProject();
        }

        toastSuccess('Project updated successfully');
        this.$trackEvent('project-settings-meta.save.success');
      } catch (err) {
        this.$trackEvent('project-settings-meta.save.failure', { message: err.message });
        toastError('Failed updating project');
      } finally {
        this.isWorking = false;
      }
    }
  },
  watch: {
    project() {
      this.onProjectChange();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.pad {
  padding: 30px 0;
  &.submissions {
    padding-bottom: 0;
  }
}
.upload {
  &-icon {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &-text {
      margin-left: 24px;
    }
  }
  &-cover-image {
    .secondary-text {
      margin-top: 20px;
    }
  }
}
.mwa {
  .input {
    margin: 20px 0 40px;
  }
}
.error-message {
  color: var(--red);
  margin: 10px 0 30px;
}
.label,
.section-label {
  display: flex;
  align-items: center;
  color: var(--primary-text) !important;

  & > * + * {
    margin-left: 10px;
  }
}
.section-label {
  color: var(--secondary-text) !important;
  margin-bottom: 12px;
}
</style>

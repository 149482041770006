<template>
  <div class="container">
    <div class="wrapper" v-show="showThankYou">
      <div class="title">Thank you! We really appreciate your feedback</div>
    </div>
    <div class="wrapper" v-show="!showThankYou">
      <!-- <img :src="require(`@/assets/svg/anima-logo-text-black.svg`)" /> -->
      <div class="title">Help us understand what didn't work for you</div>
      <div class="subTitle">Why did you install Anima Chrome Extenstion?</div>
      <div class="row" v-for="item in questions" @click="changeSelected(item.value)" :key="item.value">
        <div class="checkbox" v-bind:class="{ checked: selected === item.value }">
          <div class="checkbox_inner"></div>
        </div>
        {{ item.text }}
      </div>
      <span v-show="selected == 'other'">
        <an-textarea
          withBorder
          autoFocus
          v-model="textarea"
          style="max-height: auto"
          placeholder="Add your comment"
          allowMultiline
          rows="3"
        ></an-textarea>
      </span>
      <div class="button">
        <an-button :disabled="!selected" @click="sendForum">Send</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    showTopBar: {},
    resource: {}
  },
  computed: mapState('users', { user: 'currentItem' }),
  components: {},
  data: function () {
    return {
      selected: '',
      textarea: '',
      showThankYou: false,
      questions: [
        { value: 'problem/bug', text: 'There was a problem / bug' },
        { value: 'dont need it', text: "Don't need it" },
        { value: 'didnt undersntad how to use', text: "Wasn't clear how to use it" },
        { value: 'security', text: 'Security reasons' },
        { value: 'other', text: 'Other' }
      ]
    };
  },
  mounted() {
    let email = this.getEmail();
    this.$trackEvent('chrome.extension.uninstall', { email, reason: this.selected });
  },
  methods: {
    getEmail() {
      let url_string = location.href.toString(); //window.location.href
      let url = new URL(url_string);
      let email = url.searchParams.get('email');

      if (!email && this.user) {
        email = this.user.email;
      }
      return email;
    },
    sendForum() {
      if (this.selected) {
        let email = this.getEmail();
        this.showThankYou = true;
        this.$trackEvent('chrome.extension.uninstall.reason', { email, reason: this.selected, text: this.textarea });
      }
    },
    changeSelected(newSelected) {
      this.selected = newSelected;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  width: 100%;
  margin-top: -60px;
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  text-align: center;
  overflow: hidden;

  @include mobile {
    padding: 0 20px;
  }
}
.code-feedback-textarea {
  background: white;
  border-radius: 20px;
  padding: 8px 24px;
  border: 1px solid;
  border-color: var(--border);
  height: 42px;
  max-height: 66px;
  color: #3b3b3b;
  caret-color: var(--border);

  &::-webkit-scrollbar {
    width: 0;
  }

  &::placeholder {
    font-size: 14px;
    color: #3b3b3b;
    opacity: 0.4;
  }
}
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.wrapper {
  width: 598px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
  }
}
.checked .checkbox_inner {
  opacity: 1;
}
.checkbox_inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--primary);
  opacity: 0;
  transition: 0.2s all;
}
.row {
  width: 230px;
  cursor: pointer;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.checkbox {
  width: 20px;
  height: 20px;
  padding: 2px;
  border: 1px var(--primary) solid;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}
.title {
  font-family: Mulish;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 45px;
}
.subTitle {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="onboarding-container">
      <div v-if="!isStep0" class="small-title">Get started</div>
      <div class="skip">
        <an-link data-cy="skip-onboarding" @click="doneOnboarding('skip')" :theme="theme" :key="skipText">
          {{ skipText }}
        </an-link>
      </div>

      <div class="step-0" v-if="isStep0">
        <div class="title">Hello {{ firstName }},</div>
        <div class="text">Let’s personalize your Anima experience &amp; make it perfect for your needs.</div>
        <div class="actions">
          <an-button data-cy="start-onboarding-questions" @click="nextStep" size="xl">Let's do it</an-button>
        </div>
      </div>

      <transition-group name="slide-onboarding" tag="span">
        <span v-if="isRoleStep" class="text" key="role-step">
          <span>{{ roleText }}</span>
          <span :class="{ 'onboarding-select-container': true, selected: !!role }">
            <OnboardingSelect
              :value="role"
              :items="roleOptions"
              :isOpen="openStep === getStepNumber('role')"
              @choice="selectRole"
              @open="onOpen('role')"
              @close="onClose"
            />
          </span>
        </span>

        <span v-if="isWorkplaceTypeStep" class="text" key="workplaceType-step">
          <span v-if="!!workplaceType.title">, {{ workplaceType.title }} </span>
          <span v-else>, and I work for a </span>
          <br v-if="isMobile" />
          <span :class="['onboarding-select-container', 'break-line-mobile', { selected: !!workplaceType }]">
            <OnboardingSelect
              :value="workplaceType"
              :items="workplaceTypeOptions"
              :width="isDesktop ? '352px' : '295px'"
              :isOpen="openStep === getStepNumber('workplaceType')"
              @choice="selectWorkplaceType"
              @open="onOpen('workplaceType')"
              @close="onClose"
            />
          </span>
          <span v-if="!!workplaceType.value">.</span>
        </span>

        <span v-if="isUsageStep" class="text" key="usage-step">
          <br />
          <span>I want to use Anima to </span>
          <span :class="['onboarding-select-container', 'break-line-mobile', { selected: !!usage }]">
            <OnboardingSelect
              :value="usage"
              :items="usageOptions"
              :width="isDesktop ? '370px' : '305px'"
              :isOpen="openStep === getStepNumber('usage')"
              @choice="selectUsage"
              @open="onOpen('usage')"
              @close="onClose"
            />
          </span>
          <span v-if="!!usage.value">,</span>
        </span>

        <span v-if="isToolStep" class="text" key="tool-step">
          <br />
          <span> and I use </span>
          <span :class="{ 'onboarding-select-container': true, selected: !!tool }">
            <OnboardingSelect
              :items="toolOptions"
              :value="tool"
              :isOpen="openStep === getStepNumber('tool')"
              @choice="selectTool"
              @open="onOpen('tool')"
              @close="onClose"
            />
          </span>
          <span v-if="isDone">.</span>
        </span>

        <span v-if="isFrameworkStep" class="text" key="tool-step">
          <br />
          <span> and I use </span>
          <span
            v-if="!originDevMode && !intentDevMode"
            :class="{ 'onboarding-select-container': true, selected: !!tool }"
          >
            <OnboardingSelect
              :items="frameworkOptions"
              :value="framework"
              :isOpen="openStep === getStepNumber('framework')"
              @choice="selectFramework"
              @open="onOpen('framework')"
              @close="onClose"
            />
          </span>
          <span v-else class="selected-option"> React </span>
          <span v-if="isDone">.</span>
        </span>
      </transition-group>

      <div class="done actions" v-if="isDone">
        <an-button data-cy="finish-onboarding-questions" size="xl" @click="doneOnboarding" :isWorking="isWorking">
          Let's go!
        </an-button>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import OnboardingSelect from '@/components/Onboarding/Select';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { kebabCase, isEmpty } from 'lodash-es';
import { indefiniteArticle } from '@/utils/language';
import { TeamMixin } from '@/mixins';
import { isRecognizedEmailDomain } from '@/utils/email';

export default {
  data() {
    return {
      theme: 'dark',
      isWorking: false,
      step: 0,
      openStep: 0,
      role: {},
      workplaceType: {},
      usage: {},
      tool: {},
      framework: {},
      roleOptions: [
        { label: 'Developer', value: 'developer', emoji: 'joystick' },
        { label: 'Product designer', value: 'product designer', emoji: 'watch' },
        { label: 'Web designer', value: 'web designer', emoji: 'paint', datacy: 'option-developer' },
        { label: 'Team lead', value: 'team lead', emoji: 'magician' },
        { label: 'Founder/Executive', value: 'big deal kinda', emoji: 'ace' },
        { label: 'Other', value: 'mysterious stranger', emoji: 'ghost' }
      ],
      workplaceTypeOptions: [
        { label: 'Enterprise', value: 'enterprise', emoji: 'spaceship', title: 'and I work for an' },
        { label: 'Start-up (1-50)', value: 'start up (1-50)', emoji: 'submarine', datacy: 'option-start-up' },
        { label: 'Start-up (50+)', value: 'start up (50+)', emoji: 'submarine' },
        { label: 'UX \ UI agency', value: 'agency', emoji: 'bulb', title: 'and I work for an' },
        { label: 'Branding firm', value: 'branding firm', emoji: 'octopus' },
        { label: 'I’m a freelancer', value: 'freelancer', displayValue: 'I’m a freelancer', emoji: 'wfh', title: 'and' }
      ],
      usageOptions: [
        {
          label: 'Build a webapp (React)',
          value: 'build a webapp',
          short: 'export-code-prod',
          datacy: 'option-export-code'
        },
        {
          label: 'Publish a website (HTML)',
          value: 'publish a website',
          short: 'code-based-lp-websites'
        },
        { label: 'Create & manage a design system', value: 'create & manage a design system', short: 'design-system' },
        {
          label: 'Create live prototypes',
          value: 'create live prototypes',
          short: 'prototype',
          datacy: 'option-prototype'
        },
        { label: 'Handoff to developers', value: 'handoff to developers', short: 'dev-handover' },
        { label: 'Other', value: 'do something marvelous', short: 'other' }
      ],
      toolOptions: [
        { label: 'Figma', value: 'Figma', datacy: 'option-figma' },
        { label: 'Adobe XD', value: 'Adobe XD', datacy: 'option-xd' },
        { label: 'Sketch', value: 'Sketch', datacy: 'option-sketch' }
      ],
      frameworkOptions: [
        { label: 'React', value: 'React', datacy: 'option-react' },
        { label: 'HTML', value: 'HTML', datacy: 'option-html' }
      ]
    };
  },
  watch: {
    isDone: function () {
      if (this.isDone && (this.originDevMode || this.intentDevMode)) {
        this.selectFramework(this.frameworkOptions[0]);
      }
    }
  },
  components: {
    OnboardingTopBar,
    OnboardingSelect
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    document.body.style.overflowY = 'auto';
    this.fetchUser({ id: 'me' });
    this.fetchData();

    if (this.originDevMode || this.intentDevMode) {
      this.changeQuestionnaire();
    }
  },
  beforeDestroy() {
    this.removeTheme();
  },
  computed: {
    ...mapGetters({
      firstName: 'users/firstName',
      userEmail: 'users/userEmail',
      isPrototypeOnboardingActive: 'experiments/isPrototypeOnboardingActive',
      originDevMode: 'webappSystem/originDevMode',
      intentDevMode: 'webappSystem/intentDevMode'
    }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    skipText() {
      return `Skip ${this.isDesktop ? 'for now' : ''}`;
    },
    isStep0() {
      return this.step === 0;
    },
    isRoleStep() {
      return this.step >= this.getStepNumber('role');
    },
    isWorkplaceTypeStep() {
      return this.step >= this.getStepNumber('workplaceType');
    },
    isUsageStep() {
      return this.step >= this.getStepNumber('usage');
    },
    isToolStep() {
      return this.step >= this.getStepNumber('tool');
    },
    isFrameworkStep() {
      return this.step >= this.getStepNumber('framework');
    },
    isDone() {
      return (
        this.step >= this.getStepNumber('done') ||
        (this.step >= this.getStepNumber('framework') && (this.originDevMode || this.intentDevMode))
      );
    },
    roleText() {
      const { role } = this;
      const article = indefiniteArticle(role?.value);
      return `I am ${article}`;
    },
    extraStepName() {
      let stepName;
      // prioritize by roles of developer/designer
      // the rest will be chosen according to usage.
      if (this.role.value === 'product designer') stepName = 'tool';
      else if (this.role.value === 'developer' || this.usage.short === 'export-code-prod') stepName = 'framework';
      else stepName = 'tool';
      return stepName;
    }
  },
  mixins: [TeamMixin],
  methods: {
    ...mapActions({
      fetchUser: 'users/fetchOne',
      updateUser: 'users/update',
      updateTeam: 'teams/update',
      fetchTeam: 'teams/fetchOne',
      createTeam: 'teams/create',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      getDefaultTeamSlug: 'users/getDefaultTeamSlug',
      createPersonalizedOnboarding: 'userOnboardings/create'
    }),
    ...mapMutations({
      setOnboardingDetails: 'webappSystem/setOnboardingDetails',
      setShowDemoSuggestions: 'webappSystem/setShowDemoSuggestions',
      setShouldOpenOnboarding: 'webappSystem/setShouldOpenOnboarding',
      setUser: 'users/setCurrentItem'
    }),
    getStepNumber(stepName) {
      const stepsMap = {
        role: 1,
        workplaceType: 2,
        usage: 3,
        done: 5
      };
      stepsMap[this.extraStepName] = 4;
      return stepsMap[stepName];
    },
    getOnboardingFlowSlug({ role, usage, designTool, framework }) {
      const userTestingUsageAnswers = ['user-testing'];
      const exportCodeUsageAnswers = ['export-code-prod', 'other'];
      const buildWebsiteUsageAnswers = ['code-based-lp-websites'];
      const prototypeUsageAnswers = ['prototype'];
      const noAnswers = [role, usage, designTool, framework].every(isEmpty);

      if (userTestingUsageAnswers.includes(usage) && designTool === 'figma') {
        return 'user-testing-figma';
      } else if (noAnswers || exportCodeUsageAnswers.includes(usage)) {
        return 'export-code';
      } else if (buildWebsiteUsageAnswers.includes(usage)) {
        return 'build-website';
      } else if (prototypeUsageAnswers.includes(usage) && this.isPrototypeOnboardingActive) {
        return 'prototype';
      }
      return null;
    },
    changeQuestionnaire() {
      const thirdOption = this.roleOptions.splice(2, 1)[0]; // Remove the third option from the array
      this.roleOptions.unshift(thirdOption); // Add the third option at the beginning of the array

      const updatedUsageOptions = [
        {
          label: 'Build a webapp (React)',
          value: 'build a webapp',
          short: 'export-code-prod',
          datacy: 'option-export-code'
        },
        ...this.usageOptions.filter((option) => option.label !== 'Build a webapp (React)')
      ];

      this.usageOptions = updatedUsageOptions;
    },
    onOpen(stepName) {
      this.openStep = this.getStepNumber(stepName);
    },
    onClose() {
      this.openStep = null;
    },
    nextStep() {
      this.step++;
      this.openStep = this.step;
    },
    selectRole(role) {
      this.role = role;
      this.trackAnswer('role', role?.label);
      this.onClose();
      if (this.step === this.getStepNumber('role')) {
        this.nextStep();
      }
    },
    selectWorkplaceType(workplaceType) {
      this.workplaceType = workplaceType;
      this.trackAnswer('workplaceType', workplaceType?.value);
      this.onClose();
      if (this.step === this.getStepNumber('workplaceType')) {
        this.nextStep();
      }
    },
    selectUsage(usage) {
      this.usage = usage;
      this.trackAnswer('intent', usage?.short);
      this.onClose();
      if (this.step === this.getStepNumber('usage')) {
        this.nextStep();
      }
    },
    selectTool(tool) {
      this.tool = tool;
      this.framework = {};
      this.trackAnswer('designTool', tool?.label);
      this.onClose();
      if (this.step === this.getStepNumber('tool')) {
        this.nextStep();
      }
    },
    selectFramework(framework) {
      this.framework = framework;
      this.tool = {};
      this.trackAnswer('framework', framework?.label);
      this.onClose();
      if (this.step === this.getStepNumber('framework')) {
        this.nextStep();
      }
    },
    async fetchData() {
      this.fetchUser({ id: 'me', skipCache: true });
      const defaultTeamSlug = await this.getDefaultTeamSlug();
      if (!defaultTeamSlug) {
        return this.$trackEvent('onboarding.default-team-slug.missing', { userId: this.user?.id });
      }
      this.fetchTeam({ id: defaultTeamSlug, params: { is_slug: true }, skipCache: true });
    },
    trackAnswer(key, val) {
      const props = { field: key, value: kebabCase(val), [key]: val };
      this.$trackEvent('onboarding.questions.answer', props);
    },
    async doneOnboarding(action) {
      const { query } = this.$route;
      const role = kebabCase(this.role?.label);
      const workplaceType = kebabCase(this.workplaceType?.label);
      const usage = kebabCase(this.usage?.short);
      const designTool = this.extraStepName === 'tool' ? kebabCase(this.tool?.label) : null;
      const framework = this.extraStepName === 'framework' ? kebabCase(this.framework.label) : null;

      if (action === 'skip') {
        this.$trackEvent('onboarding.questions.skip', { role, workplaceType, usage, designTool, framework });
      } else {
        this.$trackEvent('onboarding.questions.submit', { role, workplaceType, usage, designTool, framework });
      }

      this.$gtm.trackEvent({
        event: 'singup_complete_data',
        event_category: role,
        event_label: usage
      });

      const payload = {
        role,
        intent: usage,
        workplace_type: workplaceType,
        design_tool: designTool,
        framework
      };
      try {
        await this.updateUser({ id: 'me', payload, storeResult: true });
      } catch (err) {
        this.$trackEvent('onboarding-questions.user-submit.failure');
      }
      this.setOnboardingDetails({ ...payload, intent: this.usage?.value });

      const onboardingFlowSlug = this.getOnboardingFlowSlug({ role, usage, designTool, framework });
      if (onboardingFlowSlug && isEmpty(this.userOnboarding)) {
        let onboardingPayload = { onboarding_flow_slug: onboardingFlowSlug };
        this.createPersonalizedOnboardingFlow(onboardingPayload);
        this.setShouldOpenOnboarding(true);
      } else if (!isRecognizedEmailDomain(this.user.email)) {
        this.setShowDemoSuggestions(true);
      }

      if (query.redirect && this.originDevMode) {
        return this.$router.push(query.redirect);
      }

      if (this.intentDevMode) {
        return this.$router.push({ name: 'figma-intent' });
      }

      this.$router.push({ name: 'onboarding-team', query });
    },
    async createPersonalizedOnboardingFlow(payload) {
      if (this.isWorking) return;
      try {
        this.isWorking = true;
        await this.createPersonalizedOnboarding({ parent: 'users', id: 'me', payload, storeResult: true });
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.onboarding-container {
  margin: 280px 128px 0 128px;
  @include mobile {
    margin: 220px 24px 0;
  }
}
.title {
  @include headline;
  font-weight: 800;
  font-size: 48px;
  line-height: 68px;
  margin-bottom: 30px;
}
.small-title {
  margin-bottom: 30px;
}
.skip {
  position: fixed;
  @include desktop {
    bottom: 40px;
    right: 120px;
  }
  @include mobile {
    top: 80px;
    right: 20px;
  }
}
.skip,
.small-title {
  @include secondary-title;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  opacity: 0.4;
  @include mobile {
    font-size: 16px;
    line-height: 24px;
  }
}
.break-line-desktop {
  display: inline;
  @include desktop {
    display: block;
  }
}
.text {
  @include secondary-title;
  font-size: 32px;
  line-height: 54px;
  + .text {
    margin-left: 5px;
  }
  &.break-line {
    display: block;
  }
  &.break-line-mobile {
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  @include mobile {
    font-size: 20px;
    line-height: 40px;
  }
}
.actions {
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  &.done {
    margin-top: 40px;
    animation: 1s ease 0s normal forwards 1 slide-up;
  }
  button.an-button {
    @include desktop {
      @include secondary-title;
      font-size: 24px;
      line-height: 30px;
    }
    @include mobile {
      width: 100%;
      border-radius: 0;
    }
  }
}
.onboarding-select-container {
  @include secondary-title;
  margin-left: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  @include mobile {
    margin-left: 0;
  }
  &.selected {
    margin-left: 0;
  }
}
.step {
  &-0 {
    max-width: 645px;
    .title {
      opacity: 0;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 0.5s;
    }
    .text {
      opacity: 0;
      margin-bottom: 40px;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 1s;
    }
    .actions {
      opacity: 0;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 2s;
    }
  }
}
.strike {
  text-decoration: line-through;
  color: var(--primary);
  .text {
    color: #ffffff;
  }
}

.selected-option {
  font-size: 32px;
  color: var(--primary);
  margin-left: 0;
}
.slide-onboarding-enter-active {
  animation: 1s ease 0s normal forwards 1 slide-up;
}
.slide-onboarding-enter,
.slide-onboarding-leave-to {
  opacity: 0;
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

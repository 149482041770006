<template>
  <span data-cy="project-limit-banner">
    You've hit your plan project limit!&nbsp;
    <an-link @click="onUpgradeClick" variant="primary">
      <Calendly v-if="isPro" url="https://calendly.com/anima-demo/adjust-plan" displayType="popup">
        Upgrade now
      </Calendly>
      <span v-else>Upgrade now</span>
    </an-link>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { TeamMixin } from '@/mixins';
import Calendly from '@/components/Calendly/Calendly';

export default {
  name: 'upgrade-team-plan-banner',
  components: {
    Calendly
  },
  mixins: [TeamMixin],
  computed: {
    ...mapGetters({
      isPro: 'teamMemberships/isPro'
    })
  },
  methods: {
    onUpgradeClick() {
      this.$trackEvent('upgrade-team-plan-banner.upgrade.click');
      if (!this.isPro) {
        this.openUpgradeLockedModal();
      }
    }
  }
};
</script>

<template>
  <div ref="bound" class="notifications-popover-container">
    <div ref="reference" @click.stop="toggleOpen">
      <div ref="icon" class="icon">
        <svg-icon :name="iconName" :size="30" />
      </div>
    </div>
    <Popover :isOpen="isOpen" :style="popoverStyles" @close="close">
      <div class="notifications-header">
        <span>Notifications</span>
        <an-link v-if="hasNewNotifications" variant="primary" @click="onMarkAllRead">Mark all as read</an-link>
      </div>
      <div class="notifications-body">
        <EmptyState v-if="!notifications.length" />
        <Notification
          v-else
          v-for="item in filteredNotifications"
          :key="item.id"
          :item="item"
          @click:body="onClick"
          @click:project="onClickProject"
          @click:team="onClickTeam"
          @close="close"
          @mark-read="onMarkRead"
          @mark-completed="onMarkCompleted"
        />
      </div>
    </Popover>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Popover from '@/components/Popovers/Popover';
import Notification from '@/components/Notifications/Notification';
import EmptyState from '@/components/Notifications/EmptyState';
import { colorPathChildren } from '@/utils/colors';
import { SocketMixin } from '@/mixins';
import errorHandler from '@/services/errorHandler';
import { EventBus, openModal, toastNotification } from '@/services/bus';

const COMMENT = 'COMMENT';
const SYNC = 'SYNC';
const PROJECT_CREATED = 'PROJECT_CREATED';
const ACCESS_REQUEST = 'ACCESS_REQUEST';
const ROLE_APPROVED = 'ROLE_APPROVED';
const PLAN_CHANGED = 'PLAN_CHANGED';

export default {
  data() {
    return {
      clicked: false,
      isOpen: false,
      socket: null,
      supportedNotifications: [COMMENT, SYNC, PROJECT_CREATED, ROLE_APPROVED, ACCESS_REQUEST, PLAN_CHANGED],
      actionNotifications: [ACCESS_REQUEST]
    };
  },
  props: {
    iconColor: {
      type: String,
      default: 'var(--secondary)'
    }
  },
  components: {
    Popover,
    Notification,
    EmptyState
  },
  mixins: [SocketMixin],
  mounted() {
    EventBus.$on('open-notifications-panel', this.open);
    this.reloadNotifications();
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  destroyed() {
    EventBus.$off('open-notifications-panel', this.open);
    document.removeEventListener('mousedown', this.handleClickOutside);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('notifications', { notifications: 'items' }),
    hasNewNotifications() {
      const { notifications } = this;
      return notifications.some((notification) => notification.status === 'UNREAD');
    },
    iconName() {
      const { clicked, hasNewNotifications } = this;
      return hasNewNotifications && !clicked ? 'notifications-alert' : 'notifications';
    },
    filteredNotifications() {
      const { notifications } = this;

      // all unattended action notifications that are unread go first.
      const activeActions = notifications.filter(
        (n) => this.actionNotifications.includes(n.event_type) && n.status === 'UNREAD' && !n.action_completed
      );

      // then the rest.
      const filtered = notifications.filter(
        (n) => this.supportedNotifications.includes(n.event_type) && activeActions.indexOf(n) === -1
      );

      return [...activeActions, ...filtered];
    },
    popoverStyles() {
      return {
        width: this.isDesktop ? '400px' : '320px',
        height: this.isDesktop ? '650px' : '550px',
        padding: 0,
        right: this.isDesktop ? '-50px' : 0,
        boxShadow: 'var(--slightly-stronger-shadow)'
      };
    }
  },
  methods: {
    ...mapActions({
      updateNotificationLocally: 'notifications/updateNotificationLocally',
      fetchNotifications: 'notifications/fetchAllOfParent',
      updateNotification: 'notifications/update',
      setAsRead: 'notifications/setAsRead'
    }),
    ...mapMutations({ addNotification: 'notifications/unshiftItems' }),
    handleClickOutside(e) {
      if (!this.isOpen) return;
      const bound = this.$refs.bound;
      const reference = this.$refs.reference;
      if (!bound || !reference) return;

      if (bound && !bound.contains(e.target)) {
        this.close();
      }
    },
    toggleOpen() {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
      this.clicked = true;
    },
    open() {
      this.isOpen = true;
      this.$trackEvent('notifications.panel.open');
    },
    close() {
      this.isOpen = false;
      this.$trackEvent('notifications.panel.close');
    },
    navigateToTeam({ workspace_slug: teamSlug }) {
      this.$router.push({ name: 'team', params: { teamSlug } });
    },
    navigateToProject({ workspace_slug: teamSlug, project: { id: projectId } }) {
      this.$router.push({ name: 'project', params: { teamSlug, projectId } }, () => {});
    },
    navigateToComment({ link: path }) {
      this.$router.push({ path }, () => {});
    },
    onClick(notification) {
      const { event_type: eventType } = notification;

      this.$trackEvent('notifications.notification.click');

      if (eventType === COMMENT) {
        this.navigateToComment(notification);
      } else if (eventType === SYNC) {
        this.navigateToProject(notification);
      }
      if (eventType !== ACCESS_REQUEST) {
        this.close();
      }
    },
    onClickProject(notification) {
      this.$trackEvent('notifications.notification-project-link.click');

      this.navigateToProject(notification);
      this.close();
    },
    onClickTeam(notification) {
      this.$trackEvent('notifications.notification-team-link.click');

      this.navigateToProject(notification);
      this.close();
    },
    async onMarkAllRead() {
      try {
        const { notifications } = this;
        const unread = notifications.filter((n) => n.status === 'UNREAD');

        this.$trackEvent('notifications.mark-all-read.click');

        await Promise.all(unread.map((n) => this.onMarkRead(n, false)));
        this.reloadNotifications();
      } catch (error) {
        errorHandler.captureException(error);
      }
    },
    onMarkRead({ id }, trackEvent = true) {
      const payload = { status: 'READ' };

      if (trackEvent) this.$trackEvent('notifications.mark-read.click');

      this.setAsRead({ id });
      return this.updateNotification({ id, payload });
    },
    onMarkCompleted({ id }) {
      const payload = { status: 'READ', action_completed: true };
      this.updateNotificationLocally({ id, payload });
    },
    reloadNotifications() {
      const params = { order_by: '-created_at', page_size: 50 };
      return this.fetchNotifications({ parent: 'users', id: 'me', skipCache: true, params });
    },
    changeIconColor() {
      const { $refs, iconColor } = this;
      colorPathChildren($refs.icon, iconColor);
    },
    setSocket() {
      this.openSocket(this.user?.id);
      this.socket.on({ resource: 'notification', action: 'created' }, this.onNewNotification);
    },
    onNewNotification(notification) {
      this.clicked = false;
      this.addNotification(notification);
      if ([ACCESS_REQUEST, ROLE_APPROVED, PLAN_CHANGED].includes(notification.event_type)) {
        this.$trackEvent('notifications.toast');
        toastNotification(notification);
      }
    }
  },
  watch: {
    iconColor: {
      handler: 'changeIconColor',
      immediate: true
    },
    user: {
      handler: 'setSocket',
      immediate: true
    },
    notifications() {
      const { notifications } = this;
      const planChanged = notifications.find((n) => n.status === 'UNREAD' && n.event_type === PLAN_CHANGED);
      if (planChanged) {
        openModal({
          name: 'plan-changes-modal',
          variant: 'center',
          opacity: 0.6,
          whiteOverlay: false,
          width: 570,
          closeButton: true,
          onClose: ({ clickedCta }) => {
            if (clickedCta) {
              this.onMarkRead(planChanged, false);
            } else {
              this.$trackEvent('webapp::transition_to_pro_3.dismissed');
            }
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-popover-container {
  display: inline-block;
  position: relative;
  outline: none;
}
.icon {
  height: 100%;
  cursor: pointer;
  outline: none;
}
.notifications-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: var(--border);
  width: 100%;
  a {
    font-size: 12px;
  }
}
.notifications-body {
  overflow-y: scroll;
  height: 580px;
}
</style>

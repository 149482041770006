<template>
  <div class="container">
    <h3 class="title">You’re now part of new Pro plan</h3>
    <p class="subtitle">
      The new Pro plan includes <strong>3 projects/15 screens</strong> in total, per team<br />
      Read all about the new pricing plan
      <an-link @click="onClickLink" target="_blank">here</an-link>.
    </p>
    <div class="actions">
      <an-button @click="handleCTA">Got it</an-button>
      <an-button variant="empty" class="disclaimer" @click="cancel">Cancel</an-button>
    </div>
  </div>
</template>

<script>
import { TeamMembershipMixin } from '@/mixins';

export default {
  name: 'plan-changes-modal',
  data() {
    return {
      planChangesLink: 'https://support.animaapp.com/en/articles/6521495-anima-s-updated-pricing-model'
    };
  },
  mixins: [TeamMembershipMixin],
  mounted() {
    this.$trackEvent('webapp::transition_to_pro_3.show');
  },
  methods: {
    onClickLink() {
      this.$trackEvent('webapp::transition_to_pro_3.read_more.clicked');
      window.open(`${this.planChangesLink}`, '_blank');
    },
    handleCTA() {
      this.$trackEvent('webapp::transition_to_pro_3.got_it.clicked');
      this.$emit('close', { clickedCta: true });
    },
    cancel() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

@include mobile {
  .modal {
    height: 280px;
  }
}

.container {
  margin: 20px 14px;
  height: 280px;

  &:after {
    height: unset;
  }

  .title {
    font-size: 36px;
    line-height: 50px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 22px;
    margin: 20px 10px;
  }
  .actions {
    margin-top: 10px !important;
  }

  .disclaimer {
    font-weight: 400;
    border: unset;
    color: var(--primary-text) !important;
    font-size: 16px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    &:hover {
      font-weight: 600;
    }
  }
}
</style>

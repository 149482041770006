<template>
  <div class="project-header">
    <router-link data-cy="back-to-team-btn" :to="backRouteObject" class="back-link">
      <svg-icon name="caret-left" :size="30" fill="var(--secondary-text)" />
      <span v-if="!isWorking">{{ backLinkLabel }}</span>
    </router-link>
    <div class="project-info">
      <div class="project-renamer-and-memebers">
        <div class="team-project-editor">
          <NameChangeGhostInput
            v-if="projectName"
            v-model="projectName"
            :value="project.name"
            :key="project.name"
            @blur="updateName"
            :disabled="loading"
            data-cy="project-name"
          />
        </div>
      </div>
      <div class="actions" v-if="isDesktop">
        <div
          v-if="isPlaygroundOmniView"
          v-tip="{ content: 'Review, export or publish your project', placement: 'top', classes: 'nav-item-tooltip' }"
        >
          <an-button
            @click="handleWhatsNext"
            variant="primary"
            size="md"
            data-cy="whats-next"
            style="margin-right: 15px"
          >
            What's next?
          </an-button>
        </div>
        <div v-for="item in pickNavItems" class="nav-item-container" :key="item.name">
          <PopoverMenu :items="item.popoverItems || []" position="right" popperClass="select-import-menu">
            <div slot="reference">
              <div
                v-if="item.tooltipText"
                v-tip="{ content: item.tooltipText, placement: 'top', classes: 'nav-item-tooltip' }"
                :class="['nav-item', 'clickable-icon', item.name, { disabled: item.disabled }]"
                @click="item.onClick"
                :data-cy="item.dataCy"
              >
                <div
                  :ref="`${item.name}Icon`"
                  v-html="require(`!html-loader!./icons/${item.name}.svg`)"
                  class="nav-item-svg-icon"
                />
              </div>
            </div>
          </PopoverMenu>
        </div>
      </div>
    </div>

    <div class="custom-domain-and-members">
      <an-tooltip text="Invite people to project">
        <Members :members="members" showMore @click="onClickMembers" />
      </an-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { toastError } from '@/services/bus';
import { TeamMixin } from '@/mixins';
import NameChangeGhostInput from '@/components/Input/NameChangeGhostInput';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import Members from '@/components/Members/Members';

export default {
  mixins: [TeamMixin],
  data() {
    return {
      projectName: '',
      loading: false
    };
  },
  props: {
    isWorking: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Members,
    PopoverMenu,
    NameChangeGhostInput
  },
  mounted() {
    this.projectName = this.project.name;
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('components', { screens: 'items' }),
    ...mapState('projectGuests', { guests: 'items' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMembers: 'team' }),
    ...mapGetters({
      defaultProject: 'projects/defaultProject',
      hasAccessToProFeatures: 'users/hasAccessToProFeatures',
      isPlaygroundOmniView: 'omniview/isPlaygroundOmniView'
    }),
    ...mapState('domains', { domains: 'items' }),
    pickNavItems() {
      if (this.isPlaygroundOmniView) {
        return this.navItemsSlim;
      }
      return this.navItems;
    },
    navItemsSlim() {
      const navItemsSlim = [
        {
          name: 'import',
          tooltipText: 'Import a file',
          dataCy: 'import',
          onClick: this.onClickOpenDesignImport
        },
        {
          name: 'share',
          tooltipText: 'Share project',
          onClick: this.onClickShare,
          dataCy: 'project-page-header-share'
        },
        { name: 'settings', tooltipText: 'Project settings', onClick: this.onClickSettings }
      ];
      return navItemsSlim;
    },
    navItems() {
      const modeNavItems = [
        {
          name: 'play',
          tooltipText: 'Play prototype',
          onClick: this.onClickPlay,
          disabled: !this.screens.length,
          color: '#ffffff'
        }
      ];

      const navItems = [...modeNavItems, ...this.navItemsSlim];
      return navItems;
    },
    backRouteObject() {
      const { slug: teamSlug } = this.team ?? {};
      return teamSlug ? { name: 'team', params: { teamSlug } } : { name: 'shared' };
    },
    backLinkLabel() {
      const { team } = this;
      return `Back to ${team?.name || 'Shared projects'}`;
    },
    members() {
      const teamMembers = this.teamMembers ?? [];
      const projectGuests = this.guests ?? [];
      return teamMembers.concat(projectGuests);
    }
  },
  methods: {
    ...mapActions({
      fetchProject: 'projects/fetchOne',
      updateProject: 'projects/update'
    }),
    onClickOpenDesignImport() {
      if (this.project.is_sample_project && !this.hasAccessToProFeatures) {
        this.openUpgradeLockedModal();
        return;
      }

      this.$trackEvent('project-page.import-design-button.click', { platform: 'all' });
      this.$router.push({ name: 'design-import' });
    },
    onOmniviewRedirect(query, breakpoint) {
      const { live_project_release_homepage_slug: homepageSlug } = this.project;
      const correctHomepageSlug = this.screens.some((screen) => screen.slug === homepageSlug);
      const screenSlug = correctHomepageSlug ? homepageSlug : this.screens[0]?.slug;

      if (!screenSlug) {
        return toastError('Please add screens to this project');
      }

      this.$router.push({
        name: 'omniview',
        params: { screenSlug, breakpoint },
        query
      });
    },
    async updateName() {
      const { id, name: oldName } = this.project;
      const projectName = this.projectName && this.projectName.trim();
      const payload = { name: projectName };

      if (!projectName || projectName === oldName) {
        this.projectName = oldName;
        return;
      }

      this.$trackEvent('project-page.inline-change-project-name.submit');

      try {
        this.loading = true;
        await this.updateProject({ id, payload });
        this.fetchProject({ id, skipCache: true });
      } catch (err) {
        toastError("We couldn't change your project's name");
      } finally {
        this.loading = false;
      }
    },
    trackNavigation(to) {
      this.$trackEvent('project-page.navigation-icon.click', { to });
    },
    onClickPlay() {
      if (this.screens.length) {
        const query = this.project.is_sample_project ? { tour: 'prototype' } : {};
        this.onOmniviewRedirect({ mode: 'play', ...query }, 'res');
        this.trackNavigation('omniview-play');
      }
    },
    onClickCode() {
      if (this.screens.length) {
        this.onOmniviewRedirect({ mode: 'code' });
        this.trackNavigation('omniview-code');
      }
    },
    onClickShare() {
      this.$router.push({ name: 'project-share' });
      this.trackNavigation('project-share');
    },
    handleWhatsNext() {
      this.$trackEvent('omniview.whats-next.show', { mode: 'automatically' });
      return this.$router.push({
        name: 'omniview',
        params: {
          teamSlug: this.team.slug,
          projectId: this.defaultProject.project_short_id,
          screenSlug: this.defaultProject.master_slug
        },
        query: {
          popup: 'next',
          mode: 'play',
          back: 'project'
        }
      });
    },
    onClickSettings() {
      this.$router.push({ name: 'project-settings' });
      this.trackNavigation('project-settings');
    },
    onClickMembers() {
      this.$trackEvent('project-page.project-members.click');
      this.$router.push({ name: 'project-invite' });
    }
  },
  watch: {
    project() {
      this.projectName = this.project.name;
    }
  }
};
</script>

<style lang="scss">
.nav-item-tooltip {
  .tooltip-inner {
    background: #ffffff !important;
    color: var(--black) !important;
    box-shadow: 0px 4px 12px rgb(59 59 59 / 10%) !important;
  }
}
.nav-item-svg-icon {
  svg {
    fill: currentColor;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.project-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 196px;
}
.back-link {
  display: flex;
  align-items: center;
  color: var(--secondary-text);
  margin-bottom: 50px;
}
.project-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.project-renamer-and-memebers {
  display: flex;
  align-items: center;
}
.team-project-editor {
  max-width: 500px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav-item-container + .nav-item-container {
  margin-left: 15px;
}
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  cursor: pointer;

  .nav-item-svg-icon {
    color: var(--secondary) !important;
  }
  &.clickable-icon:hover:not(.disabled) {
    background-color: var(--secondary);
    .nav-item-svg-icon {
      color: #ffffff !important;
    }
  }
  &.play:not(.disabled) {
    .nav-item-svg-icon {
      color: #ffffff !important;
    }
    background-color: var(--primary);
    border: 1px solid var(--primary);
    &:hover {
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
    }
  }
  &.disabled {
    opacity: 0.3;
  }
}
::v-deep .editable {
  @include huge-headline;
}
.custom-domain-and-members {
  display: flex;
  align-items: center;

  .inputConttainer {
    margin-right: 1rem;
  }
}
.paywall-illustration {
  margin-inline: auto;
  margin-bottom: 30px;
}
.paywall-title {
  font-family: 'Roslindale';
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
}
.paywall-subtitle {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: center;
}
.paywall-actions {
  display: flex;
  align-items: center;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
}
</style>

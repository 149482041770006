<template>
  <div :class="['stepper-container', theme]">
    <button class="button-less" :class="{ disabled: lessDisabled }" @click="previous">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.25 8.25H1.75"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <span>{{ getOption(index).label }}</span>
    <button class="button-more" :class="{ disabled: moreDisabled }" @click="next">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 1.75V14.25M14.25 8H1.75"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  name: 'an-stepper',
  props: {
    defaultValue: {
      type: [String, Number],
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value)
    }
  },
  data: () => ({
    index: 0
  }),
  computed: {
    moreDisabled() {
      return this.index === this.options.length - 1;
    },
    lessDisabled() {
      return this.index === 0;
    }
  },
  watch: {
    defaultValue: function (newVal) {
      this.index = this.options.findIndex((option) => option.value === newVal);
    }
  },
  mounted() {
    this.index = this.options.findIndex((option) => option.value === this.defaultValue);
  },
  methods: {
    next() {
      if (this.index < this.options.length - 1) {
        this.index++;
      }
      this.$emit('select', this.options[this.index]);
    },
    previous() {
      if (this.index > 0) {
        this.index--;
      }
      this.$emit('select', this.options[this.index]);
    },
    getOption() {
      return this.options[this.index] || {};
    }
  }
};
</script>
<style scoped lang="scss">
.stepper-container {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  color: #3b3b3b;
  &.dark {
    color: var(--dark-primary-text);
  }
  width: 132px;
  span {
    flex-grow: 2;
    border: none;
    height: 32px;
    text-align: center;
    border: 1px solid #52514c;
    font-family: 'Mulish';
    font-style: normal;
    border-left: none;
    border-right: none;
    width: 48px;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 32px;
  }
}
button {
  border: 1px solid #52514c;
  background: none;
  color: inherit;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f7;
    color: #706f6b;
  }
  &.button-less {
    border-radius: 5px 0 0 5px;
  }
  &.button-more {
    border-radius: 0 5px 5px 0;
  }
  &:hover {
    border-color: #91918d;
  }
  &:active {
    border: 1px solid #52514c;
    box-shadow: 0px 0px 0px 3px #e2e2df;
  }
  &.disabled {
    background-color: #dbdbd7;
    color: #c8c7c1;
    cursor: not-allowed;
  }
}
</style>

<template>
  <div class="container">
    <div class="title">Team settings</div>
    <div class="subtitle">Edit your team details and manage integrations</div>
    <div class="body">
      <div class="label">Team name</div>
      <an-input v-model="name" />
      <div class="upload">
        <UploadTeamLogo :team="team" @success="reloadTeam" @click.native="onUploadClick" />
        <div class="upload-text">
          <div>Change Icon</div>
          <div class="secondary-text small">Upload a logo 200 X 200px, PNG or JPG formats.</div>
        </div>
      </div>
      <div class="integrations">
        <div class="label">Integrations</div>
        <div class="integration">
          <div class="content">
            <div class="integration-name">
              <div class="icon"><svg-icon name="slack-logo" :size="24" /></div>
              <div class="name">{{ slackHeader }}</div>
            </div>
            <div class="secondary-text small">
              Connect Anima to your Slack workspace and notify your team with updates.
            </div>
          </div>
          <div class="btn">
            <an-button v-if="isConnectedWithSlack" variant="empty" @click="removeSlack" :isWorking="isWorkingSlack">
              Remove
            </an-button>
            <an-button v-else variant="secondary" @click="connectToSlack" :isWorking="isWorkingSlack">
              Connect
            </an-button>
          </div>
        </div>
      </div>
    </div>
    <div class="actions" v-if="hasChanges && name.trim().length > 0">
      <an-button @click="save" :isWorking="isWorking">Save changes</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { isEmpty, omit } from 'lodash-es';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import UploadTeamLogo from '@/components/Team/UploadTeamLogo';
import { SlackMixin } from '@/mixins';
import { toastError, toastSuccess } from '@/services/bus';

export default {
  name: 'team-settings-general',
  data() {
    return {
      name: '',
      isWorking: false,
      isWorkingSlack: false
    };
  },
  components: {
    UploadTeamLogo
  },
  mounted() {
    this.toastSlackStatus();
    this.loadTeam();
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('notificationsSettings', { nsItems: 'items' }),
    ...mapGetters('notificationsSettings', ['slackSettings']),
    hasChanges() {
      return this.team.name !== this.name;
    },
    isConnectedWithSlack() {
      return !isEmpty(this.slackSettings?.slack_webhook_url);
    },
    slackHeader() {
      if (this.isConnectedWithSlack) {
        const { slack_channel: slackChannel } = this.slackSettings ?? {};

        if (!slackChannel) {
          return 'Slack';
        }

        // if starts with # or @, keep it as is. otherwise, add '#' to the beginning
        const channelName = /^[#@]/.test(slackChannel) ? slackChannel : `#${slackChannel}`;
        return `Connected to ${channelName}`;
      }
      return 'Slack';
    }
  },
  mixins: [SlackMixin],
  methods: {
    ...mapActions({
      updateTeam: 'teams/update',
      fetchTeam: 'teams/fetchOne',
      createNotificationsSettings: 'notificationsSettings/create',
      deleteNotificationsSettings: 'notificationsSettings/delete',
      setCurrentTeamProps: 'teams/setCurrentTeamProps'
    }),
    ...mapMutations({
      setNotificationSettings: 'notificationsSettings/setItems'
    }),
    onTeamChange() {
      const trimmedName = this.team.name.trim();
      if (trimmedName === '' || !this.team.name) {
        return;
      }
      this.name = trimmedName;
    },
    onUploadClick() {
      this.$trackEvent('team-settings-general.upload-team-icon.click');
    },
    async save() {
      const { id } = this.team;
      const payload = { name: this.name };

      this.$trackEvent('team-settings-general.save.click');

      try {
        this.isWorking = true;
        await this.updateTeam({ id, payload });
        this.setCurrentTeamProps(payload);
        const { slug: teamSlug } = await this.reloadTeam();
        this.$emit('close', { redirect: { name: 'team-settings', params: { teamSlug } } });
      } catch (err) {
        toastError('Failed saving changes for team');
      } finally {
        this.isWorking = false;
      }
    },
    loadTeam() {
      const { teamSlug } = this.$route.params;
      this.fetchTeam({ id: teamSlug, props: { is_slug: true } });
    },
    reloadTeam() {
      const { id } = this.team;
      return this.fetchTeam({ id, skipCache: true });
    },
    async connectToSlack() {
      try {
        this.isWorkingSlack = true;
        const { id } = this.team;
        const payload = {
          channel: 'slack',
          is_disabled: false
        };

        this.$trackEvent('team-settings-general.connect-slack-button.click');

        const settings = await this.createNotificationsSettings({ parent: 'teams', id, payload });
        const url = this.getSlackAppUrl({ id: settings.id, redirect: this.$route.path });

        window.location.href = url;
      } catch (err) {
        this.$trackEvent('team-settings-general.connect-slack.failure', { message: err.message });
        toastError('Failed connecting to Slack :(');
      } finally {
        this.isWorkingSlack = false;
      }
    },
    async removeSlack() {
      try {
        this.isWorkingSlack = true;
        this.$trackEvent('team-settings-general.remove-slack.click');

        await this.deleteNotificationsSettings(this.slackSettings);

        this.$trackEvent('team-settings-general.remove-slack.success');
        toastSuccess('You have successfully opted out from Slack');
        this.setNotificationSettings([]);
      } catch (err) {
        this.$trackEvent('team-settings-general.remove-slack.failure', { message: err.message });
        toastError('Failed disconnecting from Slack :(');
      } finally {
        this.isWorkingSlack = false;
      }
    },
    toastSlackStatus() {
      const { query } = this.$route;
      const { slackStatus } = query;
      if (slackStatus === 'ok') {
        toastSuccess('Successfully connected to Slack!');
        this.$trackEvent('team-settings-general.slack-integration.success');
      } else if (slackStatus === 'failed') {
        toastError('Oops.. we failed connecting to Slack... :(');
        this.$trackEvent('team-settings-general.slack-integration.failure');
      }

      this.$router.replace({ query: omit(query, 'slackStatus') });
    }
  },
  watch: {
    team: {
      handler: 'onTeamChange',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.body {
  .upload {
    display: flex;
    margin-top: 40px;
    .upload-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 24px;
    }
  }
}
.integrations {
  margin-top: 40px;
  border-top: var(--border);
  padding-top: 40px;
}
.integration {
  display: flex;
  align-items: flex-start;
  .integration-name {
    display: flex;
    align-items: center;
    height: 36px;
    margin-bottom: 12px;
    .icon {
      margin-right: 12px;
    }
  }
  .content {
    width: 100%;
  }
  .btn {
    color: var(--primary);
    justify-self: flex-end;
  }
}
</style>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TeamMixin from './TeamMixin';
import errorHandler from '@/services/errorHandler';

import auth from '@/auth';
import { get } from 'lodash-es';
import { readCookie } from '@/utils/cookie';
import { uuid } from '@/utils/uuid';
import googleAuth from '@/services/googleAuth';

export default {
  mixins: [TeamMixin],
  computed: {
    ...mapState('projects', ['sharedProjects']),
    ...mapState('webappSystem', ['ipAddress']),
    ...mapState('devices', { currentDevice: 'currentItem' }),
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      isPricingExperimentScreenActive: 'experiments/isPricingExperimentScreenActive'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      fetchSharedProjects: 'projects/fetchSharedProjects',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchPairingToken: 'pairingTokens/fetchOne',
      updatePairingToken: 'pairingTokens/update',
      generateFigmaOAuthUrl: 'figmaOAuth/generateFigmaOAuthUrl'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    _isTeamInvitationRoute(path) {
      if (!path) {
        return false;
      }

      const resolved = this.$router.resolve(path);
      if (!resolved?.route) {
        return false;
      }
      const { query, name } = resolved.route;

      return name === 'team' && (query.source === 'team-invite' || query.invite);
    },
    async onGoogleSuccess(googleUser) {
      const { is_login, access_token, ...user } = await auth.googleLogin(googleUser);
      localStorage.setItem('token', access_token);
      const googleBasicProfile = googleAuth.getBasicProfile(googleUser);

      if (!is_login) {
        this.$trackEvent('signup.signup.success', {
          cta: 'google-button',
          device_id: this.currentDevice.device_id,
          email: googleBasicProfile.email,
          ...this.$route.query
        });
        this.signupExperiments();
        await this.fetchSharedProjects();
        const transactionId = uuid();
        this.$gtm.trackEvent({
          event: 'sign_up',
          fbEventID: `CompleteRegistration:${user.id || googleBasicProfile.sub}`,
          fbIpAddress: this.ipAddress,
          fbUserAgent: navigator.userAgent,
          fbc: readCookie('_fbc'),
          fbp: readCookie('_fbp'),
          fbCurrency: 'USD',
          fbValue: 5,
          fbFirstName: googleBasicProfile.given_name,
          fbLastName: googleBasicProfile.family_name,
          event_category: 'Signup',
          event_action: googleBasicProfile.name,
          event_label: googleBasicProfile.email,
          transaction_id: transactionId,
          external_id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email
        });
      } else {
        this.$trackEvent('login.login.success', {
          cta: 'google-button'
        });
      }

      await this.redirectAfterAuth(is_login);
    },
    async onGoogleFailure(err) {
      errorHandler.captureExceptionAndTrack(err, {
        name: 'login.google-login.failure',
        data: { cta: 'google-button', message: JSON.stringify(err.message) }
      });
    },
    async enablePairingToken() {
      const { pairing, pt: token } = this.$route.query ?? {};
      if (!pairing) {
        return;
      }

      const { id } = await this.fetchPairingToken({ id: token, params: { primary_key: 'token' } });
      this.updatePairingToken({ id, payload: { is_paired: true } });
    },
    async redirectAfterAuth(isLogin) {
      await this.enablePairingToken();

      const { sharedProjects = [] } = this;
      const { query } = this.$route;
      const isSignup = !isLogin;
      let routeName = 'root';

      let passOnBoarding = get(this.$route, 'query.passOnBoarding', false);
      const requestOAuth = get(this.$route, 'query.request_oauth', false);

      if (isSignup && !passOnBoarding) {
        let newQuery = { ...query };
        routeName = sharedProjects.length && !query.pairing ? 'shared' : 'onboarding';

        if (query.redirect) {
          const { route } = this.$router.resolve(query.redirect);
          routeName = route.name === 'payment' ? 'payment' : routeName;
          newQuery = { ...route.query };
        }

        if (query.pairing && (query.platform === 'figma' || query.platform === 'vscode')) {
          const user = await this.getUser({ id: 'me' });
          const redirectRoute = this.$router.resolve({ name: 'paired', query: { ...query, after_login: true } });
          if (!user.enabled_figma_api) {
            return this.$router.replace({ name: 'figma-generate-token', query: { callbackUrl: redirectRoute.href } });
          }
          newQuery = { redirect: redirectRoute.href };
        }

        return this.$router.replace({ name: routeName, query: newQuery }, () => {});
      } else if (query.pairing && passOnBoarding) {
        let newQuery = query;
        newQuery.redirect = `http://${window.location.host}/figma/generate-token?callbackUrl=${newQuery.redirect}`;
        return this.$router.replace({ name: 'paired', query: { ...newQuery, after_login: true } });
      } else if (query.pairing && requestOAuth) {
        const user = await this.getUser({ id: 'me' });
        const redirectRoute = this.$router.resolve({ name: 'paired', query: { ...query, after_login: true } });
        if (!user.enabled_figma_api) {
          return this.$router.replace({ name: 'figma-generate-token', query: { callbackUrl: redirectRoute.href } });
        }
        return this.$router.replace(redirectRoute.href);
      } else if (query.pairing) {
        return this.$router.replace({ name: 'paired', query: { ...query, after_login: true } });
      } else if (query.redirect) {
        if (query.redirect.startsWith('http')) {
          window.location.href = query.redirect;
          return;
        }

        const pairingRedirect = query.redirect?.includes('pairing=true')
          ? `${query.redirect}&after_login=true`
          : query.redirect;

        return this.$router.replace(pairingRedirect || '/', () => {});
      }

      return this.$router.replace({ name: routeName, query }, () => {});
    },
    signupExperiments() {
      localStorage.setItem('pricingScreenFlowActive', this.isPricingExperimentScreenActive);
      this.$trackEvent('pricing::screen-tier-flow', { active: this.isPricingExperimentScreenActive });
    }
  }
};

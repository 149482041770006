import { mapState, mapGetters, mapActions } from 'vuex';
import { toastError, toastSuccess } from '@/services/bus';
import { normalizeDomain } from '@/utils/urls';
import CustomDomainPaywall from '@/components/Paywalls/CustomDomainPaywall.vue';

export default {
  components: {
    CustomDomainPaywall
  },
  data() {
    return {
      customDomain: '',
      customDomainEdit: false,
      isDomainWorking: false,
      showPaywall: false
    };
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('domains', { domains: 'items' }),
    ...mapGetters({
      isPro: 'teamMemberships/isPro',
      isViewer: 'teamMemberships/isViewer'
    })
  },
  methods: {
    ...mapActions({
      createDomain: 'domains/create',
      fetchCustomDomains: 'domains/fetchAllOfParent'
    }),
    validateDomain(value) {
      const domain = normalizeDomain(value);
      return /^((?!-))(xn--)?[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)+$/.test(domain);
    },
    async addCustomDomain() {
      try {
        const { short_id: id } = this.project;
        const newDomain = normalizeDomain(this.customDomain);

        if (!this.validateDomain(newDomain)) {
          toastError('Invalid domain');
          return;
        }

        this.$trackEvent('project-settings-meta.add-domain-button.click');

        this.isDomainWorking = true;
        if (newDomain && !this.domains.find(({ domain = '' }) => domain?.toLowerCase() === newDomain)) {
          if (this.isPro) {
            await this.createCustomDomain({ domain: newDomain });
            this.customDomain = '';
            this.fetchCustomDomains({ parent: 'projects', id, skipCache: true });

            toastSuccess('Domain added successfully!');
            this.$trackEvent('project-settings-meta.add-domain.success', {
              domain: newDomain,
              team: this.team.id,
              user: this.user.id,
              is_pro: this.isPro
            });
          } else {
            this.$trackEvent('project-settings-meta.add-domain.pro-required');
            this.showPaywall = true;
          }
        } else {
          toastError('This domain seems to exist already 🤔');
        }
      } catch (err) {
        let message = err?.data?.message || err?.response?.data?.message || 'Failed creating your custom domain :(';
        if (this.isViewer && err?.response?.status === 403) message = 'Viewer role cannot create custom domains';
        toastError(message);
        this.$trackEvent('project-settings-meta.add-domain.failure');
      } finally {
        this.isDomainWorking = false;
        this.customDomain = '';
      }
    },
    async createCustomDomain({ domain }) {
      const { project } = this;
      const { id } = project;

      if (!domain || !domain.trim()) return toastError('Please provide a domain');

      const payload = { domain: domain.trim() };

      return this.createDomain({ parent: 'projects', id, payload });
    },
    onPaywallClose() {
      this.showPaywall = false;
      this.$trackEvent('project-settings.paywall.close');
    }
  }
};

<template>
  <div class="container" data-cy="contact-anima-modal">
    <Calendly :url="calendarLink" />
  </div>
</template>

<script>
import Calendly from '@/components/Calendly/Calendly';

export default {
  name: 'contact-anima',
  props: {
    mode: {
      type: String,
      default: 'light',
      validator: (v) => ['light', 'dark', 'transparent'].includes(v)
    },
    teamSlug: {
      type: String
    },
    source: {
      type: String
    },
    afterClosing: {
      type: Function
    },
    calendarLink: {
      type: String
    }
  },
  components: {
    Calendly
  },
  computed: {
    contentHtml() {
      return this.content.replaceAll('\n', '<br />');
    },
    illustrationSrc() {
      return require(`@/assets/illustrations/quirky-clock-${this.mode}.svg`);
    }
  },
  methods: {
    openPlans() {
      const params = {
        teamSlug: this.$route.params.teamSlug || this.teamSlug,
        source: this.source,
        afterClosing: this.afterClosing
      };
      this.$trackEvent('upgrade-team-modal.pricing-button.click', { page: this.$route.name });
      this.$router.push({ name: 'team-pricing', params });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.container {
  max-width: unset;
  margin: 0;
  padding: 0;
  @include mobile {
    ::v-deep .calendly-inline-widget {
      width: 100%;
      height: 1200px !important;
    }
  }
  ::v-deep .calendly-inline-widget {
    width: 100%;
  }
}
.container::after {
  height: 0;
}
</style>

<template>
  <div class="container" data-cy="project-members-invite-modal">
    <div class="title">Invite people to collaborate</div>
    <div class="subtitle">Add members &amp; guests to collaborate on this project</div>
    <div class="navigation">
      <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
    </div>
    <div class="body">
      <component :is="component" v-bind="props" @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Layout/Tabs/Tabs';
import TeamMembersForm from '@/components/Team/Settings/MembersForm';
import GuestsForm from '@/components/Project/Settings/Guests';
import PublicLink from '@/components/Project/Invite/PublicLink';
import { mapActions } from 'vuex';

export default {
  name: 'project-invite',
  data() {
    return {
      tabs: [
        { label: 'Invite to team', component: TeamMembersForm, slug: 'team-invite', dataCy: 'team-invite-tab' },
        { label: 'Invite to project', component: GuestsForm, slug: 'project-invite', dataCy: 'project-invite-tab' },
        { label: 'Publish', component: PublicLink, slug: 'prototype-link', dataCy: 'prototype-link-tab' }
      ],
      activeTabLabel: 'Invite to team',
      component: TeamMembersForm
    };
  },
  props: {
    defaultTabSlug: {
      type: String,
      default: 'team-invite',
      validator: (value) => ['team-invite', 'project-invite', 'prototype-link'].includes(value)
    },
    eventSource: {
      type: String
    }
  },
  mounted() {
    const relevantTab = this.getRelevantTab();
    this.onTabChange(relevantTab, false);
  },
  components: {
    Tabs
  },
  computed: {
    props() {
      return { inviteLayout: true, showInviteWithLink: true, eventSource: this.eventSource };
    }
  },
  methods: {
    ...mapActions({
      fetchProject: 'projects/fetchOne'
    }),
    async fetchData() {
      const { projectId } = this.$route.params;
      this.fetchProject({ id: projectId });
    },
    onTabChange(tab, click = true) {
      this.activeTabLabel = tab.label;
      this.component = tab.component;
      click && this.$trackEvent('project-invite.tab.click', { label: tab.label });
    },
    getRelevantTab() {
      const { tab: queryTab } = this.$route.query;
      const tabSlug = queryTab || this.defaultTabSlug;
      return this.tabs.find((tab) => tab.slug === tabSlug);
    }
  },
  watch: {
    '$route.params': {
      handler: 'fetchData',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_navigationBar.scss';
.subtitle {
  width: 520px;
}
.navigation {
  justify-content: center;
  width: 100%;
  margin: 40px 0 10px;
}
</style>

<template>
  <div data-cy="figma-import-select-frames-content" class="content">
    <!-- <p class="secondary title">Import options</p> -->
    <!-- ---------------------- -->

    <div class="grid page" @click="importAll">
      <div class="root-selection">
        <img :src="require('@/assets/svg/figma.svg')" width="50" />
        <div class="details">
          <h3>Import All</h3>
          <p class="secondary">{{ getFramesCountText(totalFramesNumber) }}</p>
        </div>
      </div>
      <FigmaCard @selectChange="importAll" controlled :value="isImportAll" :data="projectData" />
    </div>

    <file-upload-divider class="divider" />

    <div @click="importSome" class="root-selection">
      <img :src="require('@/assets/svg/figma-multi.svg')" width="50" />
      <div class="details">
        <h3>Import selected frames</h3>
      </div>
      <div style="margin-left: auto" class="toggle-collapse" :class="isImportAll ? 'down' : 'up'" />
    </div>

    <div class="collapsable">
      <div v-show="!isImportAll" v-for="page of figmaPages" :key="page.id" class="page-section">
        <div style="padding-left: 75px" class="collapsable" v-show="page.frames.length > 0">
          <div class="category" @click="toggleCollapsePage(page.id)">
            <div style="margin-bottom: 10px">
              <div class="flex items-center">
                <Checkbox
                  noMargin
                  class="page-checkbox"
                  v-on:input="handlePageSelectChange(page.id, $event)"
                  :value="isAllPageSelected(page.id)"
                >
                </Checkbox>
                <h4 class="page-title">
                  {{ page.name }}
                </h4>
              </div>
              <p class="secondary">{{ getFramesCountText(framesNumByPage(page.id)) }}</p>
            </div>
            <div class="toggle-collapse" :class="isPageFramesShown(page.id) ? 'up' : 'down'" />
          </div>

          <div v-show="isPageFramesShown(page.id)" class="grid frame">
            <FigmaCard
              @selectChange="selectFrame(page.id, frame.id)"
              controlled
              :value="isFrameSelected(page.id, frame.id)"
              v-for="frame of page.frames"
              :key="frame.id"
              :data="frame"
              :isLoadingThumbnail="isFetchingThumbnails[page.id]"
            />
          </div>
        </div>
        <file-upload-divider v-if="page.frames.length > 0" class="divider" />
      </div>
    </div>

    <!-- ---------------------- -->

    <!-- <div :class="['root-selection', { marked: isImportAll }]" @click="importAll">
      <img :src="require('@/assets/svg/figma.svg')" width="50" />
      <div class="details">
        <h3>Import all frames</h3>
        <p class="secondary">{{ getFramesCountText(totalFramesNumber) }}</p>
      </div>
    </div>
    <div class="root-selection" :class="isImportSome && 'marked'" @click="importSome">
      <img :src="require('@/assets/svg/figma-multi.svg')" width="50" />
      <div class="details">
        <h3>Select frames</h3>
      </div>
    </div>
    <div v-show="isImportSome" class="pages-selection">
      <div v-for="page of figmaPages" :key="page.id" class="page-section">
        <div class="collapsable">
          <div class="category" @click="toggleCollapsePage(page.id)">
            <div>
              <h4 class="page-title">
                {{ page.name }}
              </h4>
              <p class="secondary">{{ getFramesCountText(framesNumByPage(page.id)) }}</p>
            </div>
            <div class="toggle-collapse" :class="isPageFramesShown(page.id) ? 'down' : 'up'" />
          </div>
          <div v-show="isPageFramesShown(page.id)">
            <ul>
              <li v-for="frame of page.frames" v-bind:key="frame.id" class="frame-checkbox flex">
                <checkbox v-on:input="selectFrame(page.id, frame.id)" :value="isFrameSelected(page.id, frame.id)">
                  <span class="secondary">{{ frame.name }}</span>
                </checkbox>
              </li>
            </ul>
          </div>
        </div>
        <file-upload-divider class="divider" />
      </div>
    </div> -->

    <div v-if="hasSelectedFrames" class="actions">
      <file-upload-divider class="divider" />
      <an-button data-cy="figma-import-import-file-button" variant="primary" :isWorking="isWorking" @click="submit"
        >Import Figma file</an-button
      >
      <an-link @click="close">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import FileUploadDivider from './FileUploadDivider.vue';
import FigmaCard from './FigmaCard.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'FigmaSelectFrames',
  emits: ['submit'],
  props: {
    figmaPages: {
      required: true
    },
    projectData: {
      required: true
    },
    isWorking: {
      required: false
    }
  },
  data() {
    const selectedByFrames = {};
    const pageFramesShown = {};
    for (const page of this.figmaPages) {
      selectedByFrames[page.id] = {};
      pageFramesShown[page.id] = true;
      for (const frame of page.frames) {
        selectedByFrames[page.id][frame.id] = true;
      }
    }
    const pageIds = Object.keys(pageFramesShown);
    if (pageIds.length > 0) {
      for (let i = 0; i < pageIds.length; i++) {
        pageFramesShown[pageIds[i]] = i == 0;
      }
    }

    return {
      selectedByFrames,
      importBy: 'some',
      pageFramesShown
    };
  },
  components: {
    Checkbox,
    FileUploadDivider,
    FigmaCard
  },
  computed: {
    ...mapState('figmaIntegration', { isFetchingThumbnails: 'isFetchingThumbnails' }),
    isPageFramesShown() {
      return (pageId) => this.pageFramesShown[pageId];
    },
    isImportAll() {
      return this.importBy === 'all';
    },
    isImportSome() {
      return this.importBy === 'some';
    },
    hasSelectedFrames() {
      const hasSelectedFrame = (pageId) => Object.values(this.selectedByFrames[pageId]).some((selected) => selected);
      return this.figmaPages.map((page) => page.id).some(hasSelectedFrame);
    },
    totalFramesNumber() {
      return this.figmaPages.map((page) => page.frames.length).reduce((sum, x) => sum + x);
    },
    framesNumByPage() {
      return (pageId) => this.figmaPages.find((page) => page.id === pageId).frames.length;
    },
    getFramesByPage() {
      return (pageId) => this.figmaPages.find((page) => page.id === pageId).frames;
    },
    isAllSelected() {
      return this.figmaPages.every((page) => this.isAllPageSelected(page.id));
    },
    isAllPageSelected() {
      return (pageId) => Object.values(this.selectedByFrames[pageId]).every((selected) => selected);
    },
    isFrameSelected() {
      return (pageId, frameId) => !!this.selectedByFrames[pageId][frameId];
    }
  },
  methods: {
    ...mapMutations({
      setIsFetchingThumbnails: 'figmaIntegration/setIsFetchingThumbnails'
    }),
    handlePageSelectChange(pageId, value) {
      this.pageFramesShown[pageId] = value;
      Object.keys(this.selectedByFrames[pageId]).forEach((key) => {
        this.selectedByFrames[pageId][key] = value;
      });
    },
    getFramesCountText(framesCount) {
      let word = framesCount == 1 ? 'frame' : 'frames';
      return `${framesCount} ${word} found`;
    },
    importSome() {
      this.importBy = 'some';
      this.applyOnAll(false);

      // open only the first page
      const pageIds = Object.keys(this.pageFramesShown);

      if (pageIds.length > 0) {
        for (let i = 0; i < pageIds.length; i++) {
          this.pageFramesShown[pageIds[i]] = i == 0;
        }
      }
    },
    importAll() {
      if (this.importBy == 'all') {
        this.importSome();
        return;
      }

      this.importBy = 'all';
      this.applyOnAll(true);
      Object.keys(this.pageFramesShown).forEach((key) => {
        this.pageFramesShown[key] = false;
      });
    },
    toggleCollapsePage(pageId) {
      const value = !this.pageFramesShown[pageId];

      if (value) {
        if (!this.isFetchingThumbnails[pageId] || this.isFetchingThumbnails[pageId] === false) {
          const frameIds = this.getFramesByPage(pageId)
            .filter((frame) => !frame.thumbnailUrl)
            .map((frame) => frame.id);
          if (frameIds.length > 0) {
            this.setIsFetchingThumbnails({
              value: true,
              pageId
            });
            this.$emit('get-thumbnails', { frameIds, pageId });
          }
        }
      }

      this.pageFramesShown[pageId] = value;
    },
    submit() {
      for (let i = 0; i < Object.keys(this.selectedByFrames).length; i++) {
        const pageId = Object.keys(this.selectedByFrames)[i];
        const frameIds = this.getFramesByPage(pageId)
          .filter(({ id, thumbnailUrl }) => !!this.selectedByFrames[pageId][id] && !thumbnailUrl)
          .map((frame) => frame.id);
        if (frameIds.length > 0) {
          this.setIsFetchingThumbnails({
            value: true,
            pageId
          });
          this.$emit('get-thumbnails', { frameIds, pageId });
        }
      }

      this.$emit('submit', this.selectedByFrames);
    },
    close() {
      this.$emit('close');
    },
    applyOnAll(value) {
      for (let pageId of this.figmaPages.map((page) => page.id)) {
        for (let frame of this.getFramesByPage(pageId)) {
          this.selectedByFrames[pageId][frame.id] = value;
        }
      }
    },
    selectAll() {
      const assignedValue = this.isAllSelected ? false : true;
      this.applyOnAll(assignedValue);
    },
    selectAllPage(pageId) {
      const assignedValue = this.isAllPageSelected(pageId) ? false : true;
      for (let frame of this.getFramesByPage(pageId)) {
        this.selectedByFrames[pageId][frame.id] = assignedValue;
      }
    },
    selectFrame(pageId, frameId) {
      this.selectedByFrames[pageId][frameId] = !this.selectedByFrames[pageId][frameId];
    },
    async selectAllFrames() {
      let res = {};
      const pages = this.figmaPages;
      for (const page of pages) {
        res[page] = {};
        const frames = this.getFramesByPage(page);
        for (const frame of frames) {
          res[page][frame] = true;
        }
      }
      await this.syncFrames(res);
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 32px 32px;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-content: stretch;
  cursor: default;

  &.page {
    grid-template-columns: repeat(auto-fill, 260px);
  }
  &.frame {
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 8px 8px;
    max-height: 308px;
    overflow: auto;
  }
}

.content {
  width: calc(625px + 75px + 16px);
  text-align: left;
  transform: translateX(-105px);
}

.frame-checkbox {
  padding: 15px 0;
  &:first-child {
    margin-top: 15px;
  }
  &:last-child {
    margin-bottom: 15px;
  }
}
.toggle-collapse {
  background-image: url('~@/assets/svg/small-arrow-down.svg');
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
  &.down {
    transform: rotate(180deg);
  }
}
.pages-selection {
  padding: 30px 90px;
  overflow-y: auto;
  max-height: 35vh;
}
.page-title {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h3,
h4 {
  @include secondary-title;
}
.root-selection {
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  padding: 30px 0;
  img {
    margin-right: 25px;
  }

  &.marked {
    background: var(--light-container-background);
  }
}

.secondary {
  opacity: 0.4;
  user-select: none;
}
.title {
  margin-bottom: 20px;
}
.category {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-section:last-child .divider {
  display: none;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  .divider {
    margin-bottom: 40px;
  }
  & > * {
    margin-bottom: 20px;
  }
}

.page-checkbox {
  position: relative;
  left: -25px;
}
</style>

export const indefiniteArticle = (word = '') => {
  const vowelRegex = /^[aeiou]/i;
  return vowelRegex.test(word) ? 'an' : 'a';
};

export const pluralize = (amount, word, suffix = 's', withAmount = false) => {
  let str = '';
  if (withAmount) str = `${amount} `;
  str += `${word}${amount !== 1 ? suffix : ''}`;
  return str;
};

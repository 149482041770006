<template>
  <div class="container">
    <div class="whats-next-container">
      <div class="header-container">
        <div class="title">Your code is ready!</div>
        <div class="title-big">What’s next?</div>
      </div>
      <div class="actions-container">
        <div class="action-card" @click="handlePreview()">
          <div class="thumbnail" :style="thumbStyle"></div>
          <div class="content">
            <div class="title-container">
              <svg-icon name="anima-logo" :height="18" :width="18" />
              <div class="title">View in Anima</div>
            </div>
            <an-button>Let’s go!</an-button>
          </div>
        </div>
        <div class="actions-column">
          <div class="action-card" data-cy="inspect" @click="handleInspect()">
            <div class="content">
              <div class="title-container">
                <svg-icon name="inspect" :height="18" :width="18" />
                <div class="title">Inspect code</div>
              </div>
              <div class="cta-container">
                <svg-icon fill="#878782" :size="18" name="arrow-right"></svg-icon>
              </div>
            </div>
          </div>
          <div class="action-card" @click="handlePublish">
            <div class="content">
              <div class="title-container">
                <svg-icon name="publish" :height="18" :width="18" />
                <div class="title">Publish as a website</div>
              </div>
              <svg-icon fill="#878782" :size="18" name="arrow-right"></svg-icon>
            </div>
          </div>
          <div class="action-card" data-cy="download-zip" @click="handleDownload()">
            <div class="content">
              <div class="title-container">
                <svg-icon name="zip" :height="18" :width="18" />
                <div class="title">Download ZIP</div>
              </div>
              <div class="cta-container">
                <span v-if="teamMemberships.team_plan === 'Free'" class="badge red">Pro</span>
                <svg-icon fill="#878782" :size="18" name="arrow-right"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getCdnUrl } from '@/utils/urls';
import { openModal } from '@/services/bus';
import { TeamMixin } from '@/mixins';
import router from '@/router';

export default {
  name: 'whats-next',
  mixins: [TeamMixin],
  data() {
    return {};
  },
  mounted() {
    const { projectId } = this.$route.params;
    this.fetchProject({ id: projectId });
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'currentItem' }),
    ...mapGetters({
      modes: 'omniview/modes'
    }),
    thumbStyle() {
      const { thumb_url: thumbUrl } = this.project;
      const backgroundSrc =
        getCdnUrl(thumbUrl, { width: 400, height: 200 }) || require('@/assets/illustrations/empty-project.svg');
      return {
        background: `url("${backgroundSrc}") #ffffff no-repeat`,
        backgroundSize: 'cover'
      };
    }
  },
  methods: {
    ...mapActions({
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchProject: 'projects/fetchOne'
    }),
    async handlePreview() {
      this.$trackEvent('whats-next.click', { target: 'preview' });
      this.handleModeChange('play');
    },
    async handleInspect() {
      const eventParams = { target: 'code' };

      this.$trackEvent('whats-next.click', eventParams);
      this.handleModeChange('code');
    },
    async handleDownload() {
      const eventParams = { target: 'download' };
      this.$trackEvent('whats-next.click', eventParams);
      this.handleModeChange('code', true);
    },
    async handleModeChange(mode, openCodePreferences = false) {
      this.$emit('close');
      // eslint-disable-next-line
      let { popup, ...q } = router.currentRoute.query;
      let newRoute = {
        ...router.currentRoute,
        query: { ...q, mode }
      };

      if (openCodePreferences) {
        newRoute.query.modal = 'export';
      }
      router.push(newRoute);
    },
    handlePublish() {
      this.$trackEvent('whats-next.click', { target: 'publish' });
      openModal({
        name: 'project-invite',
        props: { eventSource: 'omniview', defaultTabSlug: 'prototype-link' },
        onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_navigationBar.scss';

.container {
  height: 100%;
  margin-top: 0px;
  .whats-next-container {
    align-items: flex-start;
    gap: 52px;
    display: flex;
    flex-direction: column;
    .header-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .title {
        font-family: 'Roslindale';
        font-size: 28px;
      }
      .title-big {
        @include headline;
        font-size: 48px;
      }
    }
    .actions-container {
      display: flex;
      width: 854px;
      align-items: flex-start;
      gap: 32px;

      .actions-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        flex: 1 0 0;
        align-self: stretch;
      }
      .action-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        background-color: #ffffff;
        border: var(--border);
        border-radius: 10px;
        width: 411px;
        &:hover {
          background-color: var(--secondary);
          color: white;
        }
        .thumbnail {
          border-radius: 10px 10px 0 0;
          height: 212px;
          width: 100%;
        }
        .title-container {
          display: flex;
          padding: 12px 0px;
          align-items: center;
          gap: 12px;
          .title {
            @include secondary-title;
            @include ellipsis;
            font-size: 18px;
          }
        }

        .cta-container {
          display: flex;
          gap: 12px;
          .badge {
            display: flex;
            height: 18px;
            padding: 0px 5px;
            justify-content: center;
            align-items: center;
            background: var(--light-green-5, #ccebe2);
            border-radius: 30px;
            color: var(--light-green-11, #18775b);
            text-align: center;
            font-size: 12px;
            font-family: Mulish;
            font-weight: 700;
            text-transform: uppercase;
            &.red {
              background: var(--light-tomato-5, #ffd1c9);
              color: var(--light-tomato-11, #d43029);
            }
          }
        }

        .content {
          display: flex;
          padding: 16px 24px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
        }
      }
    }
  }
}
</style>

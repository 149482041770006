import { toastError } from '@/services/bus';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      type: 'regular',
      exportWhenReady: false,
      clicked: false,
      isCodeLoading: false,
      isCodeReady: {
        canary: false,
        regular: false
      }
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapGetters({
      isExportAllowed: 'omniview/isExportAllowed',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs'
    }),
    isReadyToDownload() {
      return this.isCodeReady[this.type];
    }
  },
  watch: {
    isReadyToDownload: {
      handler(isReady) {
        if (isReady) {
          this.isCodeLoading = false;
          if (this.clicked) {
            this.exportCode();
          }
        }
      }
    },
    currentProjectRelease: {
      handler(obj) {
        this.isCodeReady = {
          regular: !!obj.download_url_web,
          canary: !!obj.canary_download_url_web
        };
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchProjectRelease: 'projectReleases/fetchOne'
    }),
    startPolling() {
      const id = this.currentProject.live_project_release;
      if (this.isReadyToDownload) return;

      const request = setInterval(() => {
        if (this.isReadyToDownload) {
          clearInterval(request);
          return;
        }

        this.fetchProjectRelease({ id, skipCache: true }).catch(() => {
          clearInterval(request);
          this.exportWhenReady = false;
          this.isCodeLoading = false;
        });
      }, 5000);
    },
    getDownloadUrl() {
      if (!this.type) return null;
      let dlUrl;
      switch (this.type) {
        case 'canary':
          dlUrl = this.currentProjectRelease.canary_download_url_web;
          break;
        case 'regular':
          dlUrl = this.currentProjectRelease.download_url_web;
          break;
      }
      return dlUrl;
    },
    exportCode() {
      this.clicked = false;
      const url = this.getDownloadUrl();
      this.$trackEvent('omni.code-mode.download-code');

      /* for Cypres test */
      const anchor = document.createElement('a');
      anchor.setAttribute('data-cy', 'exportCode-anchor');
      anchor.href = url;
      document.body.appendChild(anchor);
      if (window.Cypress) {
        this.isCodeLoading = false;
        this.exportWhenReady = false;
        return;
      }
      /* --- */

      let win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
      this.isCodeLoading = false;
      this.exportWhenReady = false;
      anchor.remove();
    },
    handleExportCodeLegacy() {
      const { layout, framework } = this.codeDownloadPrefs;

      if (framework == 'react') {
        toastError('Please resync your design to download the React package');
        return;
      }

      if (this.isExportAllowed) {
        this.clicked = true;
        this.type = layout && layout == 'auto_flexbox' ? 'canary' : 'regular';
        this.isCodeLoading = true;

        this.$trackEvent('omniview.download-code-button.clicked', { framework, layout, page: 'omniview' });
        this.$gtm.trackEvent({
          event: 'html_code_download',
          event_category: 'HTML Code Download',
          event_action: this.currentUser?.latest_paired_design_tool,
          event_label: 'HTML'
        });
        this.startPolling();

        if (!this.isReadyToDownload) {
          this.exportWhenReady = true;
          return;
        }
        this.exportCode();
      } else {
        toastError('Please upgrade your plan to download code');
      }
    }
  }
};

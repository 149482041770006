<template>
  <div v-html="require(`!html-loader!../../assets/svg/${name}.svg`)" :style="style" class="svg-container" />
</template>

<script>
export default {
  name: 'svg-icon',
  props: {
    name: {
      type: String,
      default: null
    },
    hasFill: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 16
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    fill: {
      type: String
    },
    stroke: {
      type: String
    }
  },
  computed: {
    style() {
      const { width, height, size, fill, stroke } = this;
      return {
        width: `${width || size}px`,
        height: `${height || size}px`,
        fill,
        stroke
      };
    }
  },
  mounted() {
    if (this.$el.firstElementChild.nodeName === 'svg') {
      const svgElement = this.$el.firstElementChild;

      svgElement.setAttribute('height', '100%');
      svgElement.setAttribute('width', '100%');

      svgElement.classList.add('svg-class');
      svgElement.fill && (svgElement.style.fill = this.fill);
      svgElement.stroke && (svgElement.style.stroke = this.stroke);
      for (const child of svgElement.children) {
        if (this.fill && child.fill) {
          child.style.fill = this.fill;
        }
        if (this.stroke && child.stroke) {
          svgElement.style.stroke = this.stroke;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
  }
}
</style>

<template>
  <div class="container">
    <div class="title">Review plan</div>
    <div class="subtitle">{{ subtitle }}</div>
    <div class="body" v-if="team && selectedPricingOption">
      <div class="billing-info">
        <div v-if="isDowngrade" class="billing-plan">
          <div class="label">
            <span>Downgrading</span>
          </div>
          <div>Your plan will be downgraded to {{ selectedPricingOption.plan.displayName }}</div>
        </div>
        <div class="billing-plan">
          <div class="label">
            <span>Billing plan</span>
          </div>
          <PricingRow
            v-for="option in pricingOptions"
            :key="option.billingId"
            :price="option.monthlyPrice"
            :priceTags="option.priceTags"
            :planName="option.plan.displayName"
            :planInterval="option.billingPeriod.toLowerCase()"
            :isCurrentPlan="option.billingId === selectedPricingOption?.billingId"
            :disabled="isActivePricingOption(option) || isLoading"
            :planCurrency="option.currency"
            @click="changePricingOption(option)"
            :isFlatFee="option.pricingModel === 'FLAT_FEE'"
          />
        </div>

        <div v-if="isPlanSupportsSeats" class="paid-seats">
          <div class="label">Select your team's paid seats</div>
          <div class="label-tip">Contributors are paid seats that are able to sync designs and export code</div>
          <div class="number-of-contributors">
            <span>No. of contributors</span>
            <an-number-input
              v-model="quantity"
              :min="minSeats"
              :max="99"
              :disabled="isLoadingPrice || isLoading"
              :minErrorTooltipText="minErrorTooltipTextComputed"
              @subtract-blocked="onSubtractContributor"
            />
          </div>
        </div>
        <div v-if="addons.length" class="add-ons">
          <div class="label">Select your add-ons (optional)</div>
          <AddonRow
            v-for="_addon in addons"
            :key="_addon.id"
            :addon="_addon"
            :interval="selectedPricingOption?.billingPeriod"
            :is-selected="isAddonSelected(_addon)"
            :disabled="isLoadingPrice || isLoading"
            :selected-value="getAddonQuantity(_addon)"
            :countryCode="countryCode"
            @click="toggleAddon"
          />
        </div>
      </div>
      <CheckoutForm
        :isLoading="isLoading"
        :isLoadingPrice="isLoadingPrice"
        :isDowngrade="isDowngrade"
        :priceBreakdown="priceBreakdown"
        :checkoutDetails="checkoutDetails"
        :ctaText="ctaText"
        :isWorking="isWorking"
        :currency="selectedPricingOption?.currency"
        :interval="selectedPricingOption?.billingPeriod"
        @purchase="purchase"
      />
    </div>
    <LoadingScreen v-else />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { apiPlanName } from '@/utils/billing';
import { SubscriptionMixin } from '@/mixins';
import { toastError } from '@/services/bus';
import AddonRow from '@/components/Pricing/AddonRow';
import PricingRow from '@/components/Pricing/PricingRow';
import CheckoutForm from '@/components/Payment/CheckoutForm';
import { StiggPlan, provisionSubscription, estimateSubscription, getCustomer, getStiggClient } from '@/services/stigg';
import { getUserLocalization } from '@/utils/billing';
import { BillingPeriod } from '@stigg/js-client-sdk';
import errorHandler from '@/services/errorHandler';
import { trackEvent } from '@/services/tracking';
import LoadingScreen from '@/components/Loading/LoadingScreen.vue';

export default {
  name: 'payment-confirmation',
  data() {
    return {
      quantity: 2,
      isWorking: false,
      pricingOptions: [],
      selectedPricingOption: null,
      subscription: null,
      selectedAddons: {},
      priceBreakdown: {},
      isLoadingPrice: true,
      isLoading: false,
      countryCode: 'US',
      isDowngrade: false
    };
  },
  mixins: [SubscriptionMixin],
  components: {
    AddonRow,
    PricingRow,
    CheckoutForm,
    LoadingScreen
  },
  mounted() {
    getUserLocalization()
      .then((localization) => {
        this.countryCode = localization?.country_code || 'US';
        if (this.countryCode) {
          this.$trackEvent('payment.localization-country-code.success', {
            country_code: this.countryCode
          });
          this.$trackUserProps({ country_code: this.countryCode });
        }
        this.fetchPlans();
      })
      .catch(() => {
        this.fetchPlans();
      });
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapState('webappSystem', ['isPersonalizedOnboardingOpen', 'ipAddress']),
    ...mapGetters({
      contributorsCount: 'teamMemberships/contributorsCountInTeam',
      screensLimit: 'teamMemberships/screensLimit',
      isTeamTrialSupported: 'teams/isTeamTrialSupported'
    }),
    subtitle() {
      return this.isDowngrade ? 'Review your downgrade details' : 'Review your plan details and add-ons';
    },
    seatsFeatureId() {
      return this.selectedPricingOption?.plan.pricePoints.find(
        (point) => point.feature?.featureId === 'feature-pro-seats' || point.feature?.featureId === 'feature-seats'
      ).feature?.featureId;
    },
    isFlatFee() {
      return this.selectedPricingOption?.pricingModel === 'FLAT_FEE';
    },
    isPlanSupportsSeats() {
      return !!this.seatsFeatureId;
    },
    currPlanName() {
      return this.team.plan;
    },
    ctaText() {
      const { pricingType } = this.subscription ?? {};
      return pricingType === 'PAID' ? 'Update plan' : 'Purchase plan';
    },
    checkoutDetails() {
      const entitlements = [
        ...this.selectedPricingOption?.plan.entitlements,
        ...this.selectedPricingOption?.plan.inheritedEntitlements
      ];
      return entitlements
        .filter(
          (entitlement) =>
            !entitlement.hiddenFromWidgets?.includes('PAYWALL') || entitlement.feature?.id === 'feature-projects'
        )
        .map((entitlement) => {
          let addOnDescription = '';
          Object.entries(this.selectedAddons)
            .map(([key, value]) => ({ addonId: key, quantity: value }))
            .filter((addon) => addon.quantity > 0)
            .map((addon) => {
              const addonEntitlements = this.addons.find((a) => a.id === addon.addonId)?.entitlements || [];
              addonEntitlements.map((e) => {
                if (e.feature?.id === entitlement.feature?.id)
                  addOnDescription += ` + ${e?.usageLimit * addon.quantity}`;
              });
            });
          return {
            label: entitlement.usageLimit
              ? `${entitlement.usageLimit}${addOnDescription} ${entitlement.feature?.displayName}`
              : `${entitlement.hasUnlimitedUsage ? 'Unlimited ' : ''}${entitlement.feature?.displayName}`,
            customized: !!addOnDescription
          };
        });
    },
    planRequiredMin() {
      return ['Team', 'Business'].includes(apiPlanName(this.selectedPricingOption?.plan?.displayName)) ? 3 : 1;
    },
    minSeats() {
      return Math.max(this.planRequiredMin, this.contributorsCount);
    },
    minErrorTooltipTextComputed() {
      return this.minSeats > 1 ? `Requires at least ${this.minSeats} seats` : `Requires at least one seat`;
    },
    addons() {
      return this.compatibleAddonsForPlan(this.selectedPricingOption?.plan, this.selectedPricingOption?.billingPeriod);
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      fetchCoupon: 'coupons/fetchOne',
      updateTeam: 'teams/update',
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    ...mapMutations({
      setShowPaymentBotError: 'webappSystem/setShowPaymentBotError',
      setIsPersonalizedOnboardingOpen: 'webappSystem/setIsPersonalizedOnboardingOpen'
    }),
    toggleAddon({ id, quantity }) {
      trackEvent('payment.addon.change', {
        addon: id,
        quantity
      });
      this.selectedAddons = {
        ...this.selectedAddons,
        [id]: quantity
      };
      this.calculatePrice();
    },
    compatibleAddonsForPlan(plan, billingPeriod) {
      const hiddenAddonIds = ['addon-code-exports-starter', 'addon-code-exports-pro', 'addon-code-exports-business'];
      const availableAddons = plan.compatibleAddons || [];

      const visibleAddons = availableAddons.filter((addon) => {
        return (
          !hiddenAddonIds.includes(addon.id) &&
          (addon.metadata?.billingPeriods ? addon.metadata?.billingPeriods.includes(billingPeriod) : true)
        );
      });

      const addonsWithPricePoints = visibleAddons
        .filter((addon) => addon.pricePoints?.length > 0)
        .sort((a, b) => {
          return a.pricePoints[0].amount - b.pricePoints[0].amount;
        });
      return addonsWithPricePoints;
    },
    isAddonSelected(addon) {
      return this.selectedAddons[addon.id] > 0;
    },
    getAddonQuantity(addon) {
      return this.selectedAddons[addon.id] || 0;
    },
    getAddonsPricingOptions(addons) {
      return addons.flatMap((addon) => {
        let pricePoints = this.countryCode
          ? addon.pricePoints?.filter((pricePoint) => pricePoint.billingCountryCode === this.countryCode.toLowerCase())
          : [];

        if (pricePoints?.length === 0) {
          pricePoints = addon.pricePoints?.filter((pricePoint) => pricePoint.currency === 'USD');
        }

        return pricePoints?.map((pricePoint) => ({
          addon,
          ...pricePoint,
          monthlyPrice: pricePoint.billingPeriod === BillingPeriod.Annually ? pricePoint.amount / 12 : pricePoint.amount
        }));
      });
    },
    createPricingOptions(plans) {
      const parsePriceTags = (plan) => {
        try {
          return JSON.parse(plan?.metadata?.priceTags || '[]');
        } catch (e) {
          return [];
        }
      };

      const pricingOptionsFromPlan = (plan) => {
        // In case of 2 price points per billing period (flat + seat), we should merge them and take the sum of the prices
        const pricePoints = plan?.pricePoints?.reduce((acc, pricePoint) => {
          const existingPricePoint = acc.find((p) => p.billingPeriod === pricePoint.billingPeriod);
          if (existingPricePoint) {
            existingPricePoint.amount += pricePoint.amount;
            existingPricePoint.pricingModel = 'FLAT_FEE';
          } else {
            acc.push(pricePoint);
          }
          return acc;
        }, []);

        return (
          pricePoints?.map((pricePoint) => ({
            ...pricePoint,
            plan,
            priceTags: parsePriceTags(plan)[pricePoint.billingPeriod] || [],
            monthlyPrice:
              pricePoint.billingPeriod === BillingPeriod.Annually ? pricePoint.amount / 12 : pricePoint.amount
          })) || []
        );
      };

      return plans.flatMap((plan) => pricingOptionsFromPlan(plan));
    },
    async fetchPlans() {
      const {
        query: { plan: selectedPlanId, interval, intentionType, addon }
      } = this.$route;

      if (this.team?.id) {
        if (this.team.uses_stigg_integration) {
          try {
            this.isLoading = true;
            const customer = await getCustomer(this.team.id);
            const hiddenAddonIds = [
              'addon-code-exports-starter',
              'addon-code-exports-pro',
              'addon-code-exports-business'
            ];
            this.subscription = customer.getActiveSubscriptions()[0];
            this.subscription.addons.map((_addon) => {
              if (hiddenAddonIds.includes(_addon.addon.id) === false) {
                this.selectedAddons[_addon.addon.id] = _addon.quantity;
              }
            });

            this.quantity = this.contributorsCount || this.subscription?.price?.feature?.unitQuantity || 1;

            const stigg = await getStiggClient();
            const { plans } = await stigg.getPaywall({ billingCountryCode: this.countryCode });

            const basePlan = plans.find((_plan) => _plan.id === selectedPlanId);

            this.pricingOptions = this.createPricingOptions([basePlan]).sort((a, b) => a.monthlyPrice - b.monthlyPrice);

            this.selectedPricingOption = this.pricingOptions.find(
              (option) => option.billingPeriod === interval && option.plan.id === selectedPlanId
            );

            if (addon) {
              this.selectedAddons[addon] = this.selectedAddons[addon] || 1;
            }
            this.isDowngrade = intentionType === 'DOWNGRADE_PLAN';
            this.calculatePrice();
          } catch (e) {
            return this.$router.push({
              name: 'team-payment',
              query: { ...this.$route.query, plan: 'pro', interval: interval?.toLowerCase() || 'annually' }
            });
          } finally {
            this.isLoading = false;
          }
        } else {
          return this.$router.push({
            name: 'team-payment',
            query: { ...this.$route.query, plan: 'pro', interval: interval?.toLowerCase() || 'annually' }
          });
        }
      }
    },
    isActivePricingOption(option) {
      return (
        this.subscription?.plan?.id === option?.plan?.id &&
        this.subscription?.price?.billingPeriod === option?.billingPeriod
      );
    },
    buildStiggPayload({ subscriptionId }) {
      const payload = {
        customerId: this.team.id,
        planId: this.selectedPricingOption?.plan?.id,
        unitQuantity: this.quantity,
        billingPeriod: this.selectedPricingOption?.billingPeriod,
        subscriptionId,
        addons: Object.entries(this.selectedAddons)
          .map(([key, value]) => ({ addonId: key, quantity: value }))
          .filter((addon) => {
            return addon.quantity > 0 && this.addons.find((a) => a.id === addon.addonId);
          })
      };

      if (this.isFlatFee) {
        delete payload.quantity;
      }

      if (this.isPlanSupportsSeats) {
        payload['billableFeatures'] = [
          {
            featureId: this.seatsFeatureId,
            quantity: this.quantity
          }
        ];
      }
      return payload;
    },
    async changePricingOption(option) {
      trackEvent('payment.pricing-option.change', {
        plan: option.plan.id,
        interval: option.billingPeriod
      });
      const { query } = this.$route;
      this.selectedPricingOption = option;
      await this.$router.replace({ query: { ...query, interval: option.billingPeriod } });
    },
    calculateDiscounts() {
      const discounts = [];

      // Calculate plan discounts
      const monthlyPricingOption = this.pricingOptions.find((option) => option.billingPeriod === BillingPeriod.Monthly);

      discounts.push({
        amount: (this.selectedPricingOption.monthlyPrice - monthlyPricingOption.monthlyPrice) * 12 * this.quantity,
        description: 'Plan discount'
      });

      // Calculate addons discounts
      const addonsPricingOptions = this.getAddonsPricingOptions(this.addons);
      const addonsDiscountAmount = Object.entries(this.selectedAddons).reduce((total, [key, value]) => {
        if (value === 0) return total;

        const monthlyPricingOption = addonsPricingOptions.find(
          (option) => option.addon.id === key && option.billingPeriod === BillingPeriod.Monthly
        );

        const annualPricingOption = addonsPricingOptions.find(
          (option) => option.addon.id === key && option.billingPeriod === BillingPeriod.Annually
        );

        return total + (annualPricingOption.monthlyPrice - monthlyPricingOption.monthlyPrice) * 12 * value;
      }, 0);

      if (addonsDiscountAmount !== 0) {
        discounts.push({
          amount: addonsDiscountAmount,
          description: 'Addons discount'
        });
      }
      return discounts;
    },
    async calculatePrice() {
      this.priceBreakdown = {};
      this.isLoadingPrice = true;
      try {
        const subscriptionId = this.subscription?.plan?.id === StiggPlan.FREE ? null : this.subscription?.id;
        const payload = this.buildStiggPayload({ subscriptionId });
        const data = await estimateSubscription({ ...payload, countryCode: this.countryCode });

        this.priceBreakdown.total = data?.subscription?.total?.amount;

        if (this.isDowngrade) {
          this.priceBreakdown.credit = data?.proration?.credit?.amount;
          this.priceBreakdown.netAmount = data?.proration?.netAmount?.amount;
        } else if (this.selectedPricingOption && this.selectedPricingOption.pricingModel !== 'FLAT_FEE') {
          // On upgrade add discounts to price breakdown, if any
          const isAnnualBilling = this.selectedPricingOption.billingPeriod === BillingPeriod.Annually;
          const discounts = isAnnualBilling ? this.calculateDiscounts() : [];
          this.priceBreakdown.discounts = discounts;
          this.priceBreakdown.basePrice =
            this.priceBreakdown.total - discounts.reduce((acc, discount) => acc + discount.amount, 0);
        }
      } catch (e) {
        console.log(e);
        toastError(`${e}`);
      } finally {
        this.isLoadingPrice = false;
      }
    },
    async purchase() {
      this.$trackEvent('payment.purchase.click', { intention: this.isDowngrade ? 'downgrade' : 'upgrade' });
      this.isWorking = true;
      try {
        const payload = this.buildStiggPayload({ subscriptionId: null });

        let successUrl = `${window.location.origin}/team/${this.team.slug}/payment/success`;
        if (this.isDowngrade) {
          successUrl = `${successUrl}?type=downgrade`;
        }
        payload['successUrl'] = successUrl;
        payload['countryCode'] = this.countryCode;
        await provisionSubscription(payload);
      } catch (e) {
        errorHandler.captureExceptionAndTrack(e, 'payment.purchase.click.error');
        console.log(e);
        toastError(e);
      } finally {
        this.isWorking = false;
      }
    },
    onCloseMinContributorsPrompt() {
      this.showMinContributorsPrompt = false;
      this.$trackEvent('payment.min-contributors-prompt.close');
    },
    onSubtractContributor() {
      trackEvent('payment.seats.blocked.reducing', {
        minSeats: this.minSeats
      });
    }
  },
  watch: {
    '$route.query'(newQuery, oldQuery) {
      const { interval: newInterval, ...newQueryWithoutInterval } = newQuery;
      const { interval: oldInterval, ...oldQueryWithoutInterval } = oldQuery;

      // avoid refetching plan details from Stigg only on interval change, when we can just recalculate the price
      if (JSON.stringify(newQueryWithoutInterval) !== JSON.stringify(oldQueryWithoutInterval)) {
        this.fetchPlans();
      } else if (newInterval !== oldInterval) {
        this.calculatePrice();
      }
    },
    quantity() {
      if (this.quantity > this.minSeats) {
        trackEvent('payment.seats.change', {
          seats: this.quantity
        });
      }
      this.calculatePrice();
    },
    team({ id }) {
      if (id) {
        this.fetchPlans();
      }
    },
    selectedPricingOption() {
      this.calculatePrice();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';

.container {
  margin-top: 64px;
}

.body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 760px;
  margin-top: 16px;
  padding-top: 32px;
  border-top: var(--border);

  @include mobile {
    flex-direction: column;
  }
}

.billing-info {
  width: 390px;

  @include mobile {
    max-width: 100%;
  }
}

.billing-plan {
  margin-bottom: 16px;
}

.number-of-contributors,
.number-of-screens {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.paid-screens,
.paid-seats {
  margin-bottom: 10px;

  &-description {
    color: var(--secondary-text);
    font-size: 14px;

    ::v-deep .an-link {
      color: var(--secondary-text) !important;
      text-decoration: underline !important;
    }
  }
}

.subtitle {
  text-align: center;
}

.add-ons {
  margin-top: 64px;
}

.label-tip {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
}
</style>

<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <LoadingScreen v-if="loading" />
    <div class="container" v-else>
      <div class="illustration pulse" />
      <div class="title">Login complete!</div>
      <div class="subtitle">We successfully paired your GitHub account with {{ platformName }}.<br /></div>
      <div class="actions">
        <an-button @click="handleOpen" data-cy="back-to-platform-button">Back to {{ platformName }}</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import auth from '@/auth';
import { toastError } from '@/services/bus';
import errorHandler from '@/services/errorHandler';
import { uuid } from '@/utils/uuid';
import jwt from 'jsonwebtoken';
import { SignupMixin } from '@/mixins';

export default {
  data() {
    return {
      email: '',
      loading: false,
      platform: '',
      figmaSource: '',
      hasError: false,
      conversionUser: null
    };
  },
  mixins: [SignupMixin],
  components: {
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.onMounted();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('devices', { currentDevice: 'currentItem' }),
    platformIllustrationSrc() {
      if (!['xd', 'sketch', 'figma'].includes(this.platform)) return '';
      return require(`@/assets/illustrations/pair/pair-${this.platform}.svg`);
    },
    platformName() {
      switch (this.platform) {
        case 'xd':
          return 'Adobe XD';
        case 'sketch':
          return 'Sketch';
        case 'figma':
          return 'Figma';
        case 'vscode':
          return 'VSCode';
        default:
          return 'Figma';
      }
    },
    platformRedirect() {
      switch (this.platform) {
        case 'xd':
          return 'adbxd://';
        case 'sketch':
          return 'sketch://animaapp';
        case 'figma':
          return 'figma://animaapp';
        case 'vscode':
          return 'vscode:extension/animaapp.vscode-anima';
        default:
          return 'figma://animaapp';
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      fetchPairingToken: 'pairingTokens/fetchOne',
      updatePairingToken: 'pairingTokens/update'
    }),
    async onMounted() {
      try {
        this.loading = true;
        const code = this.$route.query.code;
        const state = this.$route.query.state;
        const {
          platform,
          pt: token,
          intent,
          existingUser,
          redirect
        } = await jwt.verify(state, process.env.GITHUB_MESSAGE_KEY);
        this.platform = platform;
        this.intent = intent;

        // Login
        const { access_token, is_login } = await auth.githubLogin(code, existingUser);
        localStorage.setItem('token', access_token);
        const user = await this.getUser({ id: 'me' });
        if (!is_login) {
          this.$trackEvent('signup.signup.success', {
            cta: 'github',
            email: user.email,
            device_id: this.currentDevice.device_id,
            ...this.$route.query
          });
        } else {
          this.$trackEvent('login.login.success', {
            cta: 'github',
            email: user.email,
            device_id: this.currentDevice.device_id
          });
        }
        if (!localStorage.getItem('animp')) {
          this.$tracking.setUserEmail(user.email);
          this.$tracking.setUserId(user.id);
          this.$tracking.alias(user.id);
        }

        if (this.platform !== 'web') {
          await this.performPairing(token);
        } else {
          this.redirectToWebApp(redirect);
        }
      } catch (error) {
        console.error(error);
        this.hasError = true;
        errorHandler.captureException(error);
        toastError("We couldn't log you in with your Github account");
      } finally {
        this.loading = false;
      }
    },
    redirectToWebApp(redirect = '/') {
      const pairingRedirect = `${redirect}?after_login=true`;
      this.$router.replace(pairingRedirect, () => {});
    },
    handleOpen() {
      this.$trackEvent('github-paired.open-platform-link.click', { platform: this.platformName });
      window.location.replace(this.platformRedirect);
    },
    async performPairing(token) {
      try {
        const { id } = await this.fetchPairingToken({ id: token, params: { primary_key: 'token' } });
        this.updatePairingToken({ id, payload: { is_paired: true } });
        this.updateUser({ id: 'me', payload: { latest_paired_design_tool: this.platformName } });
        this.$trackEvent('github-paired.plugin-pairing.success', {
          platform: this.platform,
          ...(this.intent && { intent: this.intent })
        });

        const transactionId = uuid();

        this.$gtm.trackEvent({
          event: 'paired_plugin',
          event_category: 'paired_plugin',
          event_action: this.platformName,
          event_label: this.user?.role,
          transaction_id: transactionId,
          external_id: this.user?.id,
          first_name: this.user?.first_name,
          last_name: this.user?.last_name,
          email: this.user?.email
        });
      } catch (err) {
        this.$trackEvent('github-paired.plugin-pairing.failure', {
          token,
          platform: this.platform,
          message: err.message
        });
        toastError('Oh no, something went wrong!');
        this.hasError = true;
      }
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
}

.illustration {
  height: 230px;
  width: 290px;
  background-image: url('~@/assets/illustrations/github-connected.svg');
  position: relative;
  margin-top: 100px;
}

.title {
  @include headline;
  font-weight: 400;
}

.footer {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  50% {
    transform: scale3d(2, 2, 2);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse both linear 0.3s;
  animation-delay: 0.2s;
}
</style>

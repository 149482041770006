<template>
  <div :style="{ width: isSidebarVisible ? (isComponentsSidebarMinimized ? '40px' : '300px') : 0 }" class="sidebar">
    <div
      v-if="activeMode.name == 'C'"
      @click="handleToggleSidebar"
      :class="{ open: !isComponentsSidebarMinimized }"
      class="sidebar-toggle"
    >
      <svg-icon
        class="icon"
        :name="!isComponentsSidebarMinimized ? 'arrow-left' : 'arrow-right'"
        :size="25"
        fill="currentColor"
      ></svg-icon>
    </div>

    <div class="content">
      <div v-show="!isComponentsSidebarMinimized && showContent" class="c">
        <div class="header">
          <div class="title">
            <div class="flex items-center">
              <div v-if="stack.length > 0" style="margin-right: 10px" @click="back" class="icon-c">
                <svg-icon style="color: white" fill="currentColor" name="arrow-left" :size="24" />
              </div>
              Components
            </div>
          </div>
        </div>
        <!-- <virtual-list :key="stack.length" class="vv-list" wclass="v-list" :size="66" :remain="getRemain" :bench="0"> -->

        <ul :key="stack.length" :class="animation" class="flex flex-col w-full c-list" :style="getHeight">
          <li
            @click="handleComponentCardClicked(w)"
            class="component-item"
            :class="{ active: isActive(w) }"
            v-for="w in components"
            :key="w.id"
          >
            <div style="flex: 1; width: 260px" class="flex items-center">
              <!-- <img
                :style="{ objectFit: w.width > 60 ? 'contain' : 'fit' }"
                class="component-image"
                :src="getComponentBg(w)"
              /> -->
              <WebComponentThumb :webComponent="w" :maxWidth="260" :maxHeight="46" />
              <div class="text flex flex-col">
                <div class="name">
                  {{ w.name }}
                </div>
              </div>
              <svg-icon
                v-if="w.subsIds.length > 0"
                v-tip="'Contains multiple components'"
                style="color: white; margin-left: auto; flex-shrink: 0"
                fill="currentColor"
                name="stack"
                :size="24"
              />
            </div>
          </li>
        </ul>

        <!-- </virtual-list> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
// import VirtualList from 'vue-virtual-scroll-list';
import { get, orderBy } from 'lodash-es';
import { SELECT_OVERRIDE_NODE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { EventBus } from '@/services/bus';
import WebComponentThumb from '@/components/OmniView/Code/WebComponentThumb.vue';

export default {
  components: {
    // VirtualList
    WebComponentThumb
  },
  data() {
    return {
      showContent: false,
      root: null,
      animation: 'fadeIn',
      stack: []
    };
  },
  watch: {
    isSidebarVisible: {
      // delay showing content only after the animation is finished
      handler(to) {
        const timeout = this.activeMode.name == 'C' ? 400 : 250;
        if (to) {
          setTimeout(() => {
            this.showContent = true;
          }, timeout);
        } else {
          this.showContent = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('webComponents', { DbWebComponents: 'items' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      activeMode: 'omniview/activeMode',
      isComponentsSidebarMinimized: 'omniview/isComponentsSidebarMinimized',
      queue: 'omniview/queue',
      panelHeight: 'omniview/panelHeight',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      isComponentView: 'webComponents/isComponentView'
    }),
    components() {
      const { screenSlug } = this.$route.params;

      let arr;
      if (this.stack.length == 0) {
        arr = this.DbWebComponents;
      } else {
        arr = this.stack[this.stack.length - 1].subsIds.map((id) => {
          const master = this.DbWebComponents.find((w) => w.id == id);
          return master;
        });
      }

      arr = arr.filter((c) => (c.is_live || c.is_suggestion) && c.name.toLowerCase() != screenSlug.toLowerCase());
      arr = arr.filter(
        (c) =>
          !!c.instances.find((i) => {
            const slug = get(i, 'screen_slug', false);
            return slug == screenSlug;
          })
      );
      return orderBy(arr, ['name'], ['asc']);
    },
    getRemain() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return Math.floor((h - 60 - 70) / 66);
    },
    getHeight() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return {
        '--height': Math.floor(h - 60 - 70 - this.panelHeight) + 'px'
      };
    },
    isSidebarVisible() {
      return this.activeMode.name != 'In' && !this.isCompareEnabled;
    }
  },
  methods: {
    ...mapMutations({
      setIsComponentsSidebarMinimized: 'omniview/setIsComponentsSidebarMinimized'
    }),
    handleToggleSidebar() {
      let state = !this.isComponentsSidebarMinimized;
      this.$trackEvent('omniview.components-panel.toggle', {
        action: state
      });
      this.setIsComponentsSidebarMinimized(state);
    },
    async back() {
      this.animation = 'slideInLeft';
      // await this.$nextTick();
      this.stack.pop();
    },
    getComponentBg(wc) {
      let fallbackUrl = `https://anima-uploads.s3.amazonaws.com/projects/${this.currentProject.id}/web_components/screenshots/${wc.fingerprint}.png`;
      let url = get(wc, 'screenshot_url', '');
      if (!url) url = fallbackUrl;
      return url;
    },
    isActive(w) {
      const { layer } = this.$route.query;
      const primaryInstance = w.instances.find((i) => i.is_primary);

      if (!primaryInstance) return false;
      return primaryInstance.model_id == layer;
    },
    async handleComponentCardClicked(w) {
      // const { screenSlug } = this.$route.params;

      this.animation = '';
      // await this.$nextTick();

      const primaryInstance = w.instances.find((i) => i.is_primary);
      if (!primaryInstance) return;

      if ((w.subsIds || []).length > 0) {
        this.animation = 'slideInRight';
        this.stack.push(w);
      }

      // const slug = get(primaryInstance, 'screen_slug', screenSlug);

      const data = {
        nodeId: primaryInstance.model_id,
        metadata: {
          source: 'client'
        }
      };

      const selectNode = () => {
        EventBus.$emit(SEND_MESSAGE, {
          action: SELECT_OVERRIDE_NODE,
          data
        });
      };

      let openInlibrary = () => {
        EventBus.$emit('open-component-in-library', {
          nodeId: primaryInstance.model_id,
          openComponentInterface: false,
          preProcessParams: {
            forcePreProcess: true
          }
        });
      };

      const job = () => {
        selectNode();
        openInlibrary();
      };

      job();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../Code/block.scss';
.sidebar {
  position: absolute;
  left: 0;
  bottom: 0;
  top: var(--omniview-topbar-height);
  flex-shrink: 0;
  z-index: 13;
  // height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 0 0 #2d2d2d;
  will-change: width;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateZ(0);

  &:hover {
    .open {
      visibility: visible;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3b3b3b;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #2d2d2d;
}
.sidebar-toggle {
  position: absolute;
  width: 40px;
  height: 40px;
  right: -20px;
  top: 40px;
  background: #333333;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  color: white;

  &.open {
    visibility: hidden;
    .icon {
      left: 0;
    }
  }
  .icon {
    position: relative;
    left: -2px;
  }
}
.resizer {
  position: absolute;
  height: 100%;
  width: 14px;
  background: transparent;
  left: -10px;
  top: 0;
  z-index: 100;
  &:hover {
    div {
      background: #1a1a1a !important;
    }
  }

  div {
    height: 100%;
    width: 4px;
    margin-left: auto;
    background: transparent;
  }
}

.c {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.header {
  user-select: none;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;
  z-index: 10;
  .title {
    font-size: 16px;
    color: white;
    max-width: 260px;
  }
}

.component-item {
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px;
  cursor: pointer;

  transition: background 0.2s;

  &.active {
    background: #2d2d2d;
  }

  .text {
    margin-left: 15px;
    overflow: hidden;
  }

  .name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .size {
    opacity: 0.4;
    font-size: 12px;
  }

  // &:hover {
  //   background: #2d2d2d;
  // }
}
.component-image {
  flex-shrink: 0;
  width: 60px;
  height: 46px;
  max-width: 60px;
}
.c-list {
  padding-bottom: 60px;
  height: var(--height);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
    background-color: #303030;
  }
  &::-webkit-scrollbar-thumb {
    background: #b3b2b2;
    border-radius: 200px;
  }
}
</style>

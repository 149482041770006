import { isEmpty, upperFirst } from 'lodash-es';
import { createStoreModule } from '../utils';
import { isSufficientAccessLevel, isSufficientPlan, isSufficientRole } from '@/services/permissions';
import api from '@/api';
import { getActivePlanName } from '@/services/stigg';
import Vue from 'vue';

const fetchAllOfParent =
  (parent) =>
  async (ctx, { id, params = {}, saveResults = true, skipCache = false }) => {
    try {
      const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
      const url = `/v2/${parent}s/${id}/team_memberships/`;
      const { data } = await api.list(url, {
        params: { page_size: 500, order_by: 'created_at', ...params },
        cachePolicy
      });

      data?.results?.map((tm) => {
        if (tm.uses_stigg_integration) {
          // Patch plan name asynchronously to improve performance
          if (saveResults) {
            ctx.dispatch('patchStiggPlanName', { parent, teamId: tm.team });
          }
        }
      });

      if (saveResults) {
        const upperParent = upperFirst(parent);
        ctx.commit(`set${upperParent}Memberships`, data);

        if (parent === 'team') {
          // set current team membership for user permissions
          const currentUser = ctx.rootState.users?.currentItem;
          ctx.commit('setCurrentTeamMembership', currentUser);
        }
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return error;
    }
  };

const mutationForParent =
  (parent) =>
  (state, { results = [] } = {}) => {
    state[parent] = results;
  };

const getForParent = (parent) => (state) =>
  state[parent].map((tm) => ({
    id: tm.team,
    name: tm.team_name,
    slug: tm.team_slug,
    plan: tm.team_plan,
    uses_stigg_integration: tm.uses_stigg_integration,
    logo_url: tm.team_logo,
    members: tm.members,
    membersLength: tm.members?.length,
    totalMembers: tm.totalMembers,
    active_projects_count: tm.active_projects_count || 0,
    role: tm.role
  }));

export default createStoreModule({
  name: 'teamMembership',
  modelName: 'team_memberships',
  crud: true,
  endpoint: '/v2/team_memberships',
  state: {
    user: [],
    team: []
  },
  mutations: {
    patchStiggTeamPlanForParent: (state, { id, teamPlan, parent }) => {
      const memberships = state[parent];
      const index = memberships?.findIndex((tm) => tm.team === id);
      if (index !== -1) {
        Vue.set(state[parent][index], 'team_plan', teamPlan);
      }
    },
    setUserMemberships: mutationForParent('user'),
    setTeamMemberships: mutationForParent('team'),
    setCurrentTeamMembership: async (state, { email } = {}) => {
      const { team: teamMemberships } = state;
      const myMembership = teamMemberships.find((tm) => tm.email === email);
      if (myMembership?.uses_stigg_integration) {
        myMembership['team_plan'] = await getActivePlanName(myMembership.team);
      }
      state.currentItem = myMembership || {};
    },
    updateTeamMemberships(state, { teamId, payload }) {
      const { user, team } = state;
      const updateMembershipsOfId = (membership) => {
        if (membership.team !== teamId) return membership;
        return { ...membership, ...payload };
      };
      state.user = user.map(updateMembershipsOfId);
      state.team = team.map(updateMembershipsOfId);
    }
  },
  actions: {
    fetchAllUserMemberships: fetchAllOfParent('user'),
    fetchAllTeamMemberships: fetchAllOfParent('team'),
    patchStiggPlanName: async ({ commit, rootState, dispatch }, { parent, teamId }) => {
      let teamPlan = rootState.stigg.subscriptions[teamId]?.plan?.displayName;
      if (teamPlan) {
        commit(`patchStiggTeamPlanForParent`, { id: teamId, teamPlan, parent });
        return;
      }

      const subscription = await dispatch('stigg/fetchSubscription', { teamId }, { root: true });
      teamPlan = subscription?.plan?.displayName;
      if (!teamPlan) return;
      commit(`patchStiggTeamPlanForParent`, { id: teamId, teamPlan, parent });
    },
    resendInvitation: (_, { id }) => api.post(`/memberships/${id}/resend`)
  },
  getters: {
    userTeams: getForParent('user'),
    getScreensLimit: (state) => {
      const { user: userMemberships = [] } = state;
      return ({ teamSlug } = {}) => {
        const membership = userMemberships?.find((tm) => tm.team_slug === teamSlug) || state.currentItem;
        return membership.team_screens_limit;
      };
    },
    hasPermissions: (state, getters, rootState) => {
      const { user: userMemberships = [] } = state;
      const { plan: userPlan } = rootState.users.currentItem ?? {};
      return ({ teamSlug, role: requiredRole, plan: requiredPlan, accessLevel: requiredAccessLevel } = {}) => {
        const membership = userMemberships?.find((tm) => tm.team_slug === teamSlug) || state.currentItem;

        // this will return true if:
        // user is a team member of that team,
        // user has sufficient role (if `role` is not provided, returns true)
        // user has sufficient plan (if `plan` is not provided, returns true)
        // user has sufficient access level (if `accessLevel` is not provided, returns true)
        return !!(
          !isEmpty(membership) &&
          isSufficientRole(requiredRole, membership.role) &&
          (isSufficientPlan(requiredPlan, membership.team_plan) || isSufficientPlan(requiredPlan, userPlan)) &&
          isSufficientAccessLevel(requiredAccessLevel, membership.access_level)
        );
      };
    },
    contributorsCountInTeam(state) {
      const { team: teamMembers } = state;
      return teamMembers?.filter((tm) => tm.access_level === 'contributor').length || 0;
    },
    getTeam(state, getters, rootState) {
      return ({ id, slug } = {}) => {
        const userMemberships = getters.userTeams;
        let key = id ? 'id' : 'slug';
        let val = id || slug || rootState?.route?.params?.teamSlug;
        return userMemberships?.find((tm) => tm[key] === val);
      };
    },
    isFree(state, getters, rootState) {
      const { teamSlug } = rootState?.route?.params;
      return getters.hasPermissions({ teamSlug, plan: 'free' });
    },
    isPro(state, getters, rootState) {
      const { teamSlug } = rootState?.route?.params;
      return getters.hasPermissions({ teamSlug, plan: 'pro' });
    },
    screensLimit(state, getters, rootState) {
      const { teamSlug } = rootState?.route?.params;
      return getters.getScreensLimit({ teamSlug });
    },
    isOwner(state) {
      return state.currentItem?.role === 'owner';
    },
    isViewer(state) {
      return state.currentItem?.access_level === 'viewer';
    }
  }
});

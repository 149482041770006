<template>
  <div class="google-login-wrapper">
    <div ref="googleAuthButton" />
  </div>
</template>

<script>
import errorHandler from '@/services/errorHandler';
import { toastError } from '@/services/bus';
export default {
  data() {
    return {
      clientId: process.env.GOOGLE_AUTH_CLIENT_ID
    };
  },
  async mounted() {
    this.checkGoogleScript();
  },
  methods: {
    checkGoogleScript() {
      const id = 'google-script';

      if (document.getElementById(id)) {
        if (window.google && window.google.accounts) {
          this.setupGoogleAuth();
        } else {
          document.getElementById(id).remove();
          this.addGoogleScript();
          this.$trackEvent('google.google-script-reloaded');
        }
      } else {
        this.addGoogleScript(id);
      }
    },
    addGoogleScript(id) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.id = id;

      script.onload = () => {
        this.setupGoogleAuth();
      };

      script.onerror = () => {
        document.getElementById(id).remove();
        errorHandler.captureException('onerror: Could not load the Google script');
        this.$trackEvent('google.google-script-failed');
        toastError('Could not load the Google script, try login with email and password');
      };

      document.head.appendChild(script);
    },
    setupGoogleAuth() {
      if (!window.google || !window.google.accounts) {
        errorHandler.captureException('setupGoogleAuth: window.google or window.google.accounts are undefined');
        return;
      }
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.handleCredentialResponse,
        auto_select: true,
        locale: 'en'
      });

      window.google.accounts.id.renderButton(this.$refs.googleAuthButton, {
        size: 'large',
        width: '345',
        theme: 'filled_blue',
        logo_alignment: 'left',
        locale: 'en'
      });
    },
    handleCredentialResponse(response) {
      this.$trackEvent('login.google-login-button.click', {
        cta: 'google-button'
      });
      response.credential ? this.$emit('success', { ...response }) : this.$emit('failure', { ...response });
    }
  }
};
</script>

<style lang="scss" scoped>
.google-login-wrapper {
  height: 46px;
}
</style>

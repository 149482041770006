<template>
  <div class="export_panel" @click="closeIfTarget">
    <div class="panel" data-cy="export-panel">
      <form
        v-show="false"
        ref="codesandboxFormRef"
        action="https://codesandbox.io/api/v1/sandboxes/define"
        method="POST"
        target="_blank"
      >
        <input type="hidden" name="parameters" :value="codesandboxParams" />
        <input type="submit" value="Open in sandbox" />
      </form>
      <div class="export_scope">
        <p class="title small-text">Export scope</p>
        <div class="cards">
          <div
            class="card enabled"
            @click="selectScope(1)"
            :class="[selectedScope == 1 ? 'active' : '']"
            data-cy="project-scope"
          >
            <img src="@/assets/svg/full-project.svg" alt="Full project" />
            <p class="small-text">Full project</p>
            <div class="overlay"></div>
          </div>
          <div
            class="card enabled"
            @click="selectScope(2)"
            :class="[selectedScope == 2 ? 'active' : '']"
            data-cy="screen-scope"
          >
            <img src="@/assets/svg/current-screen.svg" alt="Current screen" />
            <p class="small-text">Current screen</p>
            <div class="overlay"></div>
          </div>
          <div
            class="card"
            v-on="
              showSelection()
                ? {
                    click: () => {
                      return selectScope(3);
                    }
                  }
                : {}
            "
            :class="[showSelection() ? 'enabled' : 'disabled', selectedScope == 3 ? 'active' : '']"
            data-cy="selection-scope"
          >
            <img src="@/assets/svg/selection.svg" alt="Selection" />
            <p class="small-text">Selection</p>
            <div class="overlay"></div>
          </div>
        </div>
      </div>

      <SelectedFramework @close="$emit('close')" class="selected-framework" />

      <div class="export-options">
        <an-button
          @click="exportZip"
          size="sm"
          style="margin-left: 20px"
          data-cy="zip-option"
          :isWorking="zipClicked"
          variant="primary"
        >
          Download ZIP
        </an-button>
        <an-button
          @click="exportSandbox"
          size="sm"
          style="margin-left: 15px"
          data-cy="sandbox-option"
          variant="secondary"
          :isWorking="isGeneratingPlaygroundCode"
          v-if="selectedScope != 1"
        >
          Open {{ sandboxCTA }}
        </an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import { EventBus } from '@/services/bus';
import { TeamMixin } from '@/mixins';
import { UserMixin } from '@/mixins';
import { openLink } from '@/components/OmniView/utils';
import downloadCodeMixin from '@/components/OmniView/downloadCodeMixin';
import downloadCodeMixinLegacy from '@/components/OmniView/downloadCodeMixinLegacy';
import { openModal, toastWarning } from '@/services/bus';
import SelectedFramework from '../SelectedFramework.vue';
import { useGenerateCode } from '@/hooks';

const projectScopeType = 1;
const projectScreenType = 2;
const projectSelectionType = 3;

export default {
  mixins: [downloadCodeMixin, downloadCodeMixinLegacy, TeamMixin, UserMixin],
  components: {
    SelectedFramework
  },
  setup() {
    const { generatePlaygroundURLAsync } = useGenerateCode();

    return {
      generatePlaygroundURLAsync
    };
  },
  data() {
    return {
      selectedScope: 1,
      showGeneratingOnBackgroundToast: true,
      waitForPackageTimeout: 0,
      zipClicked: false,
      codesandboxParams: '',
      codepenParams: ''
    };
  },
  mounted() {
    EventBus.$on('open-in-codesandbox', this.handleOpenInCodeSandbox);
    EventBus.$on('downloadComplete', this.close);
    this.selectedScope = this.showSelection() ? 3 : 1;
  },
  beforeDestroy() {
    this.notifyBackgroundExport();
    EventBus?.$off('open-in-codesandbox', this.handleOpenInCodeSandbox);
    EventBus?.$off('downloadComplete', this.close);
  },
  methods: {
    ...mapMutations({
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      setIsExportingCodeComponent: 'omniview/setIsExportingCodeComponent',
      setIsGeneratingPlaygroundCode: 'omniview/setIsGeneratingPlaygroundCode'
    }),
    ...mapActions({
      nextOnboardingStage: 'userOnboardings/nextStage',
      trackExportedCodeInitiated: 'tracking/trackExportedCodeInitiated'
    }),

    handleOpenInCodeSandbox({ params }) {
      this.setMarketingActiveUser('developer');
      this.codesandboxParams = params;
      this.$nextTick(() => {
        this.$refs.codesandboxFormRef?.submit();
      });
    },
    close() {
      this.zipClicked = false;
    },
    closeIfTarget(event) {
      if (event.target.className == 'export_panel') {
        this.$emit('close');
      }
    },
    showSelection() {
      const { layer } = this.$route.query;
      return !!layer;
    },
    selectScope(option) {
      this.selectedScope = option;
    },
    exportZip() {
      if (this.zipClicked) {
        return;
      }
      this.zipClicked = true;
      const eventPayload = this.omniviewFrameworkPayload;
      let eventData = {
        ...eventPayload,
        panel: 'export_code_modal',
        action: 'download_package',
        count_screens: this.countExportedScreens,
        exported: this.selectedScopeCta
      };
      if (!this.isExportAllowed) {
        this.zipClicked = false;
        this.$emit('close');
        if (this.shouldShowPaywall) {
          return this.openUpgradeDownloadCodeModal({ eventData });
        }
        return openModal({
          name: 'export-code-as-viewer',
          variant: 'center',
          opacity: 0.3,
          mode: 'dark',
          whiteOverlay: true,
          background: '#2d2d2d',
          width: 500,
          closeButton: true
        });
      }
      this.trackExportedCodeInitiated(eventData);
      let exportFunction;
      switch (this.selectedScope) {
        case 1:
          exportFunction = () => {
            EventBus.$emit('export-code');
          };
          break;
        case 2:
          exportFunction = () => {
            const { screenSlug } = this.$route.params;
            EventBus.$emit('export-code', { type: 'screen', slug: screenSlug });
          };
          break;
        case 3:
          exportFunction = () => {
            const { layer } = this.$route.query;
            EventBus.$emit('export-code', { type: 'selection', layer });
          };
          break;
      }
      exportFunction();
      this.nextOnboardingStage({ currentStageSlug: 'export-code' });
    },
    notifyBackgroundExport() {
      if (this.exportCodeLoading && this.showGeneratingOnBackgroundToast) {
        toastWarning('Your package is being prepared in the background. We’ll notify you when it’s done.');
      }
      clearTimeout(this.waitForPackageTimeout);
    },
    async exportSandbox() {
      if (this.isExportingToPlayground) {
        return;
      }

      const eventPayload = this.omniviewFrameworkPayload;
      let eventData = {
        ...eventPayload,
        panel: 'export_code_modal',
        action: this.codegenLang === 'html' ? 'codepen' : 'codesandbox',
        exported: this.selectedScopeCta
      };

      if (this.shouldShowPaywall) {
        this.$emit('close');
        return this.openUpgradeDownloadCodeModal({ eventData });
      }

      this.setIsGeneratingPlaygroundCode(true);
      eventData.action = 'playground';
      eventData.eventName = 'OPEN_IN_PLAYGROUND';

      let progressCount = 0;
      let hasShownWarning = false;

      const interval = setInterval(() => {
        progressCount++;

        if (progressCount > 10 && !hasShownWarning) {
          toastWarning('Your export is taking a little longer than expected.');
          hasShownWarning = true;
        }
      }, 1000);

      try {
        const url = await this.generatePlaygroundURLAsync({
          onProgress: () => {
            return null;
          },
          screenOnly: this.selectedScope !== 1
        });
        openLink({ link: url });
      } catch (err) {
        toastWarning('Something went wrong when exporting your code. Please try again or reach out to the team.');
      } finally {
        clearTimeout(interval);
        this.setIsGeneratingPlaygroundCode(false);
      }

      this.trackExportedCodeInitiated(eventData);
      this.nextOnboardingStage({ currentStageSlug: 'export-code' });
    }
  },
  computed: {
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapGetters({
      codegenReactSyntax: 'codePreferences/codegenReactSyntax',
      codegenReactStyle: 'codePreferences/codegenReactStyle',
      codegenVueStyle: 'codePreferences/codegenVueStyle',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      codegenLengthUnit: 'codePreferences/codegenLengthUnit',
      codegenAutoAnimateMode: 'codePreferences/codegenAutoAnimateMode',
      codegenLang: 'codePreferences/codegenLang',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      isGeneratingPlaygroundCode: 'omniview/isGeneratingPlaygroundCode',
      isPro: 'teamMemberships/isPro',
      styleType: 'codePreferences/codeStyling',
      isExportingCodeComponent: 'omniview/isExportingCodeComponent',
      isExportAllowed: 'omniview/isExportAllowed',
      isScreenLimitReached: 'omniview/isScreenLimitReached',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps',
      codePrefsStyling: 'codePreferences/codeStyling'
    }),
    selectedScopeCta() {
      switch (this.selectedScope) {
        case projectScopeType:
          return 'project';
        case projectScreenType:
          return 'screen';
        case projectSelectionType:
          return 'selection';
      }
      return 'selection';
    },
    countExportedScreens() {
      switch (this.selectedScope) {
        case projectScopeType:
          return this.currentProject.components_count || 1;
        case projectScreenType:
          return 1;
        case projectSelectionType:
          return 0;
      }
      return 0;
    },
    sandboxCTA() {
      return 'Playground';
    }
  },
  watch: {
    isExportingCodeComponent(newValue) {
      if (!newValue) {
        this.zipClicked = false;
      }
      return newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.export_panel {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 201;
  left: 0;
  top: 0;
  overflow: hidden;
  color: white;
}

.panel {
  position: absolute;
  right: 54px;
  top: 61px;
  border-radius: 16px;
  width: 390px;
  background: var(--dark-onboarding-background);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);

  &::after {
    background: var(--dark-onboarding-background);
    position: absolute;
    width: 14px;
    height: 14px;
    display: block;
    content: '';
    transform: rotate(45deg);
    top: -7px;
    right: 100px;
    z-index: -1;
  }
}

.export_scope {
  padding: 16px 24px;
  .title {
    margin-bottom: 8px;
  }
  .cards {
    display: flex;
    justify-content: space-between;
    .card {
      width: 100px;
      height: 62px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      border: 1px solid var(--dark-background);
      border-radius: 4px;
      z-index: 0;

      img {
        margin-top: 13px;
        width: 14.5px;
        opacity: 0.4;
      }
      p {
        margin-top: 6px;
        line-height: 20px;
      }
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--red);
        opacity: 0.1;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
      }
    }
    & .disabled {
      opacity: 0.6;
      cursor: default;
    }
    & .enabled {
      &:hover {
        p {
          opacity: 1;
        }
        img {
          opacity: 1;
        }
        background: var(--secondary);
      }
      &.active {
        border: 1px solid var(--red);
        background: none;
        p {
          opacity: 1;
        }
        img {
          opacity: 1;
        }
        .overlay {
          opacity: 0.1;
        }
      }
    }
  }
}

.export-options {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 17px 0px;
  background: var(--secondary);
  .export-option {
    background-color: #333333;
    border-top: 1px solid var(--dark-onboarding-background);
    display: flex;
    padding: 24px;
    cursor: pointer;
    .share {
      margin-left: auto;
      opacity: 0.4;
    }
    p {
      margin-left: 18px;
      font-size: 14px;
    }
    &:hover {
      background: var(--dark-onboarding-background);
    }
    &.working {
      cursor: default;
      user-select: none;
      p {
        opacity: 0.4;
      }
      img {
        opacity: 0.4;
      }
    }
  }
}

.small-text {
  opacity: 0.4;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.loader {
  width: 36px;
  margin-left: auto;
}

.selected-framework {
  padding: 12px 14px 17px 24px;
}
</style>

<template>
  <header :style="getHeaderStyle" class="header">
    <!-- NAVIGATOR -->
    <NetflixMenu @handleScreenChange="handleScreenChange" />
    <!-- LOGO -->
    <router-link tag="div" :to="{ name: 'team', params: { teamSlug: currentProject.team_slug } }" class="logo">
      <an-logo type="mini" />
    </router-link>
    <!-- SCREEN -->
    <div :style="getHeaderTitleStyle" class="titles">
      <div class="project-screen">
        <router-link
          tag="div"
          :to="{
            name: 'project',
            params: { teamSlug: currentProject.team_slug, projectId: $route.params.projectShortId }
          }"
          class="project-title"
        >
          {{ currentProject.name }}
        </router-link>
        <svg-icon :size="20" class="t-icon" fill="currentColor" name="caret-right"></svg-icon>
        <div ref="trigger" class="screen-title" @click="toggleNavigator({})">
          <span>{{ $route.params.screenSlug }}</span>
          <svg-icon class="icon" :size="20" fill="currentColor" name="carret-down"></svg-icon>
        </div>
      </div>
      <div class="info">Page {{ getScreenNumber }} of {{ this.currentComponents.length }}.</div>
    </div>
    <div v-if="!isMobile" class="mode-breakpoints">
      <div v-if="isReleaseOmni" class="mode-selector">
        <div
          :class="{ selected: activeMode.name == mode.name }"
          @click="
            handleModeChange({
              mode
            })
          "
          class="mode-container"
          v-for="(mode, i) in modes"
          :key="i"
        >
          <svg-icon fill="currentColor" class="mode-icon" :size="20" :name="mode.icon"></svg-icon>
          <div class="mode-text">{{ mode.displayName }}</div>
        </div>
      </div>
      <!-- BREAKPOINTS -->
      <BreakpointsSelector @breakpointChange="handleBreakpointChange" @handleScreenChange="handleScreenChange" />
    </div>

    <div class="actions">
      <missing-fonts-widget
        v-if="currentRelease && currentRelease.missing_fonts && currentRelease.missing_fonts.length > 0"
        class="sync-warning"
        :items="currentRelease.missing_fonts"
        @done="handleFontUploaded"
      >
        <div class="sync-warning-btn" @click="$trackEvent('missing-fonts.button.click')">Missing fonts</div>
      </missing-fonts-widget>
      <div v-if="!isReleaseOmni" class="sync-tip">You’re previewing a draft</div>
      <an-button :isWorking="loadingSync" @click="handleSync" variant="primary">Sync to project</an-button>
    </div>
  </header>
</template>

<script>
import BreakpointsSelector from '@/components/OmniView/Header/BreakpointsSelector.vue';
import MissingFontsWidget from '@/components/Widgets/MissingFontsWidget.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { mapWidthToBreakpointText } from '@/components/OmniView/utils';
import { EventBus, openModal } from '@/services/bus';
import api from '@/api';
import { poll } from '@/utils/javascript';
import { UserMixin } from '@/mixins';
import NetflixMenu from '@/components/OmniView/Header/NetflixMenu';

export default {
  components: {
    BreakpointsSelector,
    MissingFontsWidget,
    NetflixMenu
  },
  data() {
    return {
      loadingSync: false
    };
  },
  mixins: [UserMixin],

  mounted() {
    EventBus.$on('handleScreenChange', this.handleScreenChange);
  },
  destroyed() {
    EventBus.$off('handleScreenChange', this.handleScreenChange);
  },

  computed: {
    ...mapState('components', { currentComponents: 'items' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapGetters({
      allModes: 'omniview/modes',
      activeMode: 'omniview/activeMode',
      currentComponent: 'components/currentComponent',
      breakpoints: 'omniview/breakpoints',
      activeBreakpoint: 'omniview/activeBreakpoint',
      currentFrameWidth: 'omniview/currentFrameWidth',
      _isSyncAllowed: 'omniview/isSyncAllowed',
      slugsMap: 'omniview/slugsMap'
    }),

    modes() {
      return this.allModes.filter((m) => m.name != 'Co');
    },
    isReleaseOmni() {
      return this.$route.name == 'releaseOmniview';
    },
    getHeaderTitleStyle() {
      return {
        'font-size': this.isMobile ? '12px' : '14px'
      };
    },

    getHeaderStyle() {
      return {
        height: this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)',
        'min-height': this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)'
      };
    },

    getScreenNumber() {
      const index = this.currentComponents.map((c) => c.modelID).indexOf(this.currentComponent.modelID);
      if (index == -1) {
        return 1;
      }
      return index + 1;
    }
  },
  methods: {
    mapWidthToBreakpointText,
    ...mapMutations({
      setCompareOpacity: 'omniview/setCompareOpacity',
      selectScreen: 'components/setCurrentItem'
    }),

    ...mapActions({
      handleModeChange: 'omniview/handleModeChange',
      fetchRelease: 'releases/fetchOne',
      pollSyncingProject: 'projects/pollSyncingProject',
      createProjectRelease: 'projectReleases/create',
      updateProject: 'projects/update'
    }),

    async handleSync() {
      const { projectShortId, releaseShortId } = this.$route.params;
      const { team_slug: teamSlug } = this.currentProject;

      try {
        if (!this._isSyncAllowed) {
          this.handleOpenSyncUpgradeModal();
          return;
        }

        this.loadingSync = true;
        const projectRelease = await this.createProjectRelease({
          parent: 'projects',
          id: projectShortId,
          payload: {
            release: {
              type: 'releases',
              short_id: releaseShortId
            }
          }
        });

        this.syncProjectReleaseToProject(projectRelease);

        this.$trackEvent('sync-preview.sync.click');
        this.trackUserSyncData(this.$route.query.platform);

        this.$gtm.trackEvent({
          event: 'sync_design',
          event_category: 'Sync Design'
        });
      } catch (error) {
        this.loadingSync = false;
        if (error.response.data.message == 'Already exists') {
          this.$router.push({ name: 'project', params: { teamSlug, projectId: projectShortId } });
        }
      }
    },

    handleOpenSyncUpgradeModal() {
      openModal({ name: 'team-partial-access', mode: 'dark' });
    },

    async handleFontUploaded() {
      await this.regenerateReleaseCode();
      EventBus.$emit('refresh-iframe');
    },
    async regenerateReleaseCode() {
      await api.post('/rpc/project/regenerate_code', {
        project_id: this.currentProject.id,
        release_short_id: this.currentRelease.short_id
      });

      await poll({
        fn: () => this.fetchRelease({ id: this.currentRelease.id, skipCache: true }),
        validate: (release) => {
          return release && release.is_code_generation_done;
        },
        interval: 500,
        maxAttempts: 90
      });
    },
    async syncProjectReleaseToProject(projectRelease) {
      const { projectShortId, screenSlug } = this.$route.params;

      const { team_slug: teamSlug } = await this.updateProject({
        id: projectShortId,
        payload: {
          live_project_release: {
            type: 'project_releases',
            id: projectRelease.id
          }
        }
      });

      await this.pollSyncingProject({ id: projectShortId, waitBeforeRun: true });

      this.loadingSync = false;

      this.$router.replace({ name: 'omniview', params: { teamSlug, projectId: projectShortId, screenSlug } });
    },

    handleBreakpointChange(e) {
      this.$emit('breakpointChange', e);
    },
    handleScreenChange({ screenSlug, component, fetchBreakpoints = true }) {
      const { projectShortId, releaseShortId } = this.$route.params;

      this.$router
        .replace({
          name: this.$route.name || 'syncPreview',
          params: { projectShortId, releaseShortId, screenSlug: screenSlug || this.slugsMap[component?.variableID] }
        })
        .then(() => {
          this.selectScreen(component);
          this.$emit('screenChange', {
            fetchBreakpoints
          });
        })
        .catch(() => {});
    },

    getScreenThumb(component) {
      const { thumbURLString, thumbnail_url } = component;
      return {
        'background-image': `url('${thumbURLString || thumbnail_url}')`
      };
    },
    toggleNavigator(params = {}) {
      EventBus.$emit('toggle-netflix-memu', params);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Header';
.sync-tip {
  margin-right: 20px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sync-warning {
  margin-right: 20px;
  cursor: pointer;
}
.sync-warning-btn {
  color: var(--red);
  font-size: 14px;
}
</style>

import { orderBy } from 'lodash';
import store from '..';
import { createStoreModule } from '../utils';
export default createStoreModule({
  name: 'component',
  modelName: 'components',
  crud: true,
  endpoint: '/v2/components',
  state: {
    currentComponentData: {},
    currentComponentAssets: [],
    svgAssets: [],
    pngAssets: [],
    sections: [],
    allowSectionsEdit: true
  },
  crudMutations: {
    setItems: (state, { results, total, count, page }) => {
      state.total = total;
      state.count = count;
      state.page = page;

      const customResults = results.map((item, index) => {
        if (!item.sorting_section.id) {
          return {
            ...item,
            sorting_section: {
              id: 'allId'
            },
            sorting_section_position: index + 1
          };
        }
        return item;
      });

      store.state['omniview'].projectSlugs = results.map((s) => s.slug);

      state.items = customResults;
    }
  },
  mutations: {
    setComponents: (state, components) => (state.items = components),
    setCurrentComponentData: (state, model) => {
      const addedLayersIds = [];
      const assets = [];

      const findAssets = (currentNode) => {
        let i, currentChild, result;
        for (i = 0; i < currentNode.subviews.length; i++) {
          currentChild = currentNode.subviews[i];

          if (currentChild.model_class == 'ADModelImageView' && addedLayersIds.indexOf(currentChild.modelID) == -1) {
            addedLayersIds.push(currentChild.modelID);
            assets.push(currentChild);
          }
          result = findAssets(currentChild);
          if (result !== false) {
            return result;
          }
        }
        return false;
      };

      findAssets(model);

      state.currentComponentData = model;

      // set assets

      let png = [];
      let svg = [];
      const extRegex = /(?:\.([^.]+))?$/;

      for (let index = 0; index < assets.length; index++) {
        const layer = assets[index];
        const ext = extRegex.exec(layer.fileName)[1];

        if (ext == 'jpg' || ext == 'png') {
          png.push(layer);
        }
        if (ext == 'svg') {
          svg.push(layer);
        }
      }

      state.pngAssets = png;
      state.svgAssets = svg;
      state.currentComponentAssets = assets;
    },
    setSections: (state, sections) => {
      if (sections.length == 0) {
        state.sections = [
          {
            id: 'allId',
            name: 'Main section',
            listPosition: 1
          }
        ];
        return;
      }
      state.sections = sections;
    },
    setAllowSectionsEdit: (state, flag) => (state.allowSectionsEdit = flag)
  },
  getters: {
    currentComponent: (s) => s.currentItem,
    currentComponentData: (s) => s.currentComponentData,
    currentComponentAssets: (s) => s.currentComponentAssets,
    sections: (s) => s.sections,
    allowSectionsEdit: (s) => s.allowSectionsEdit,
    screenBounds: (state, _getters, rootState) => {
      let homepageScreen;
      const screens = state.items;
      if (screens.length == 1) {
        return {
          homepageScreen: screens[0],
          minScreen: {
            width: 20
          }
        };
      }
      const currentProject = rootState?.projects?.currentItem || {};
      const { live_project_release_homepage_slug: homepageSlug } = currentProject;
      const sortedByWidth = orderBy(screens, ['width'], ['asc']);
      const minScreen = sortedByWidth[0];
      if (homepageSlug) {
        const correctHomepageSlug = screens.find((screen) => screen.slug === homepageSlug);
        homepageScreen = correctHomepageSlug;
      }

      if (!homepageScreen) {
        homepageScreen = sortedByWidth[sortedByWidth.length - 1];
      }

      return {
        homepageScreen,
        minScreen
      };
    },
    linkedScreens(state) {
      const screens = state.items;
      const slugs = screens.map((s) => s.slug);
      // masterScreens will hold an object of all screens where
      // master_slug === slug in the format of: { <slug>: <object> }
      const masterScreens = screens
        .filter((screen) => screen.master_slug === screen.slug)
        .reduce((acc, currentScreen) => {
          const { master_slug: masterSlug } = currentScreen;
          return { ...acc, [masterSlug]: { ...currentScreen, linkedScreens: [] } };
        }, {});
      // iterate all screens not in masterScreens, and add them
      // as "linkedScreens" to the relevant masterScreen.
      screens
        .filter((screen) => screen.master_slug !== screen.slug)
        .forEach((screen) => {
          const { master_slug: masterSlug, slug } = screen;
          if (masterScreens[masterSlug]) {
            masterScreens[masterSlug].linkedScreens.push(screen);
          } else if (!slugs.includes(screen.master_slug)) {
            masterScreens[slug] = screen;
          }
        });

      // return an array of the values.
      return Object.values(masterScreens).sort((a, b) => !!a.is_locked - !!b.is_locked);
    },
    totalLinkedScreens(state, getters) {
      return getters.linkedScreens.length;
    },
    totalScreens(state) {
      return state.items.length;
    },
    totalScreensWithBreakpoints(state, getters) {
      return getters.linkedScreens.filter((screen) => screen.linkedScreens?.length > 0)?.length || 0;
    },
    similarScreensIdsTemp(state, getters) {
      const { linkedScreens: masterScreensList = [] } = getters;
      const similarScreenIds = masterScreensList
        .map((masterScreen) => {
          const { linkedScreens = [] } = masterScreen;
          const flatScreensList = [masterScreen, ...linkedScreens].map((screen) => ({ id: screen.id }));
          return flatScreensList;
        })
        .filter((similarScreens) => similarScreens.length > 1);
      return similarScreenIds;
    }
  },
  actions: {}
});

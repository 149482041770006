<template>
  <div
    :class="['inline-onboarding-progress-container', { pulse, pointer: !inOmniview }, theme]"
    data-cy="inline-personalized-onboarding"
    @click="$emit('click')"
  >
    <div class="hint" v-if="!inOmniview">
      <span>{{ hintTitle }}</span>
    </div>
    <div :class="['progress-container', theme]">
      <span class="stage-count">{{ progressText }}</span>
      <div class="progress">
        <ProgressBar :backgroundColor="progressBackgroundColor" :barColor="progressBarColor" :progress="progress" />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';

export default {
  data() {
    return {
      pulse: false
    };
  },
  props: {
    userOnboarding: {
      type: Object
    },
    inOmniview: {
      type: Boolean,
      default: false
    },
    noPulse: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProgressBar
  },
  computed: {
    flow() {
      return this.userOnboarding?.onboarding_flow;
    },
    activeStageIndex() {
      return this.userOnboarding.active_stage_index;
    },
    theme() {
      return this.inOmniview ? 'dark' : 'light';
    },
    totalStages() {
      return this.flow?.stages.length;
    },
    hintTitle() {
      return this.flow?.short_title;
    },
    progress() {
      if (!this.totalStages) return 0;
      return this.activeStageIndex / this.totalStages;
    },
    progressText() {
      return this.progress === 1 ? 'Done!' : `${this.activeStageIndex}/${this.totalStages}`;
    },
    progressBackgroundColor() {
      return this.theme === 'light' ? 'rgba(216, 216, 216, 0.5)' : 'white';
    },
    progressBarColor() {
      return this.theme === 'light' ? 'white' : 'var(--green)';
    }
  },
  methods: {
    createPulse() {
      if (!this.noPulse) {
        this.pulse = true;
        setTimeout(() => (this.pulse = false), 1000);
      }
    }
  },
  watch: {
    userOnboarding: {
      handler: 'createPulse',
      immediate: true
    },
    progressText: {
      handler: 'createPulse'
    }
  }
};
</script>

<style lang="scss" scoped>
$accent-color: rgba(216, 216, 216, 0.3);
.inline-onboarding-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background-color: white;
  box-shadow: var(--shadow);
  transition: all 0.2s ease;
  padding: 5px 12px;
  &.pointer {
    cursor: pointer;
  }
  &:hover {
    box-shadow: var(--slightly-stronger-shadow);
  }
  &.light {
    border: 1px solid $accent-color;
  }
  &.dark {
    color: white;
    background-color: #2d2d2d;
    &:hover {
      background-color: #303030;
    }
  }
}
.pulse {
  animation: pulse 1.5s 2;
}
.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.light {
    color: white;
    background-color: var(--green);
    border-radius: 16px;
    padding: 0 12px;
  }
}
.hint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  + .progress-container {
    margin-left: 16px;
  }
}
.stage-count {
  font-size: 10px;
  font-weight: 400;
}
.progress {
  margin-left: 8px;
  width: 64px;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(216, 216, 216, 0.8);
    box-shadow: 0 0 0 0 rgba(216, 216, 216, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(216, 216, 216, 0);
    box-shadow: 0 0 0 10px rgba(216, 216, 216, 0);
  }
  100% {
    -moz-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
  }
}
</style>

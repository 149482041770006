<template>
  <div class="container">
    <div class="illustration">
      <svg-icon name="warn" :width="24" :height="24" />
    </div>
    <div class="text">
      {{ text }}
      <an-link :href="url" target="_blank" variant="secondary" @click="onClick">{{ ctaText }}</an-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plan-upgrade-needed-banner',
  data() {
    return {
      text: 'Starting August-1st our Pro plan will include 3 projects. We encourage teams to schedule a call to get a special offer.',
      ctaText: 'Learn more',
      url: 'https://support.animaapp.com/en/articles/6329198-updated-pricing-model'
    };
  },
  methods: {
    onClick() {
      this.$trackEvent('plan-upgrade-needed-banner.cta.click');
    }
  }
};
</script>

<style>
.warn {
  background-color: hsl(43, 90%, 95%) !important;
}
</style>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .illustration {
    margin-right: 30px;
  }
}

a {
  font-weight: bold;
}
</style>

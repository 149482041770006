<template>
  <div
    :style="getPanelStyle"
    v-bind:class="{ panelTopPosition: showOnlyCode }"
    ref="omniPanel"
    id="omniPanel"
    class="panels"
  >
    <ChromeExtensionPricing v-show="isChromeExtension && showPricing" />
    <!-- PANEL HEAD -->
    <div class="panel-wrapper" @click="showAndHidePricing(true)">
      <div :style="getPathSelectorStyles" v-show="showPath" class="path-selector">
        <div v-for="(p, idx) in currentNodePath" :key="idx" class="flex items-center">
          <template v-if="p.nodeId">
            <div
              @mouseover="handlePathMouseOver(p.nodeId)"
              @mouseout="handlePathMouseOut"
              :class="{ clickable: p.nodeId, active: currentNode.id == p.nodeId }"
              @click="handlePathClicked(p, idx)"
              class="path-container"
            >
              <div class="path-label">{{ p.name }}</div>
            </div>
            <svg-icon
              v-show="idx < currentNodePath.length - 1"
              class="path-icon"
              :size="20"
              fill="currentColor"
              name="arrow-right"
            ></svg-icon>
          </template>
        </div>
      </div>
      <div v-if="activeMode.name == 'C'" class="flex flex-col w-full h-full">
        <div class="panel-head">
          <div style="flex: auto"></div>

          <div class="actions" v-bind:class="{ addChromeExtensionButtons: isChromeExtension && showOnlyCode }">
            <CodeTab
              v-show="!isStyleguideOpen"
              @expand-clicked="setIsStyleguideOpen(!isStyleguideOpen)"
              :isExpanded="false"
              showExpandButton
              codeIcon="code-icons/css"
              text="Styleguide"
              :style="{
                position: 'relative',
                top: 0,
                left: 0
              }"
            />
            <div @click="handleClosePanel({ manual: true })" class="close-button" v-show="!isChromeExtension">
              <svg-icon
                :size="20"
                fill="currentColor"
                class="icon"
                :name="isOpen ? 'carret-down' : 'carret-top'"
              ></svg-icon>
            </div>
          </div>
        </div>

        <!-- PANEL CONTENT -->
        <div class="panel-body">
          <PanelPaywall />
          <CleanCode @open-feedback="isCodeFeedbackOpen = true" @scrollCallback="styleGuideScroll" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs';
import dayjs from 'dayjs';
import { has } from 'lodash-es';
import { ResizeObserver } from '@juggle/resize-observer';

import { EventBus, toastSuccess } from '@/services/bus';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  PANEL_DRAG,
  SEND_MESSAGE,
  SELECT_OVERRIDE_NODE,
  HIGHLIGHT_COMMENT
} from '@/utils/events/omniviewEvents';
import Code from '@/components/OmniView/Panels/Code.vue';
import PanelPaywall from '@/components/OmniView/Panels/PanelPaywall';
import ChromeExtensionPricing from '@/components/OmniView/Panels/ChromeExtensionPricing';
import { UserMixin } from '@/mixins';
import copyToClipboard from '@/utils/copyToClp';
import CodeTab from '@/components/OmniView/Panels/codeTab.vue';

export default {
  components: {
    CleanCode: Code,
    CodeTab,
    ChromeExtensionPricing,
    PanelPaywall
  },
  data() {
    return {
      channelFeedback: null,
      resizablePanel: null,
      currentActivePanel: 'cleanCode',
      isCodeFeedbackOpen: false,
      showPricing: false,
      animateStyleguideTab: false,
      scrollCallback: undefined
    };
  },
  mixins: [UserMixin],
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode', isChromeExtension: 'isChromeExtension' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentNodePath: 'omniview/currentNodePath',
      activeMode: 'omniview/activeMode',
      codegenLang: 'codePreferences/codegenLang',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      currentNodeHTML: 'omniview/currentNodeHTML',
      currentNodeJSX: 'omniview/currentNodeJSX',
      isComponentView: 'webComponents/isComponentView',
      isOpen: 'omniview/isPanelOpen',
      visitedStyleguide: 'omniview/visitedStyleguide',
      styleType: 'codePreferences/codeStyling',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      isStyleguideOpen: 'styleguide/isStyleguideOpen',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    }),
    showStyleguideNotification() {
      const { projectId } = this.$route.params;
      return !has(this.visitedStyleguide, projectId);
    },
    nodeHTML() {
      let h = '';
      switch (this.codegenLang) {
        case 'react':
          h = this.currentNodeJSX;
          break;

        case 'html':
          h = this.currentNodeHTML;
          break;

        default:
          break;
      }
      return h;
    },

    selected() {
      return !!this.currentNode.id;
    },
    showPath() {
      return this.selected && !this.isComponentView;
    },
    getPathSelectorStyles() {
      let styles = this.activeMode.name == 'Co' ? { bottom: 0, cursor: 'default' } : { top: '-20px' };
      return styles;
    },
    getPanelStyle() {
      if (this.activeMode.name == 'Co') {
        return {
          minHeight: 0,
          maxHeight: 0
        };
      }
      return {
        minHeight: this.activeMode.name == 'C' ? '40px' : 0
      };
    }
  },

  methods: {
    ...mapMutations({
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setCodegenReactSyntax: 'codePreferences/setCodegenReactSyntax',
      setCodegenReactLanguage: 'codePreferences/setCodegenReactLanguage',
      setCodegenHTMLLayout: 'codePreferences/setCodegenHTMLLayout',
      setCodegenLengthUnit: 'codePreferences/setCodegenLengthUnit',
      setCodegenAutoAnimateMode: 'codePreferences/setCodegenAutoAnimateMode',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      setPanelHeight: 'omniview/setPanelHeight',
      setVisitedStyleguide: 'omniview/setVisitedStyleguide',
      setIsStyleguideOpen: 'styleguide/setIsStyleguideOpen'
    }),
    ...mapActions({
      resetSelection: 'omniview/resetSelection',
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      handleModeChange: 'omniview/handleModeChange',
      nextOnboardingStage: 'userOnboardings/nextStage',
      trackExportedCodeSuccess: 'tracking/trackExportedCodeSuccess'
    }),

    styleGuideScroll() {
      if (this.scrollCallback) {
        this.scrollCallback();
        this.scrollCallback = undefined;
      }
    },

    toggleStyleguide() {
      EventBus.$emit('toggle-styleguide');
    },

    toggleCompare() {
      this.setIsCompareEnabled(true);
      this.handleClosePanel();
      this.$trackEvent('omniview.compare-button.click');
    },
    copyCode({ text, type, panel = '' }) {
      if (this.shouldShowPaywall) {
        return EventBus.$emit('show-paywall', { action: 'copy-code', type, framework: this.codegenLang });
      }

      this.trackExportedCodeSuccess({
        type,
        framework: this.codegenLang,
        layout: this.codegenHTMLLayout,
        action: 'copy_to_clipboard',
        ...(panel ? { panel, source: 'button' } : {})
      });

      if (type == 'css') {
        this.animateStyleguideTab = true;
      }

      this.$gtm.trackEvent({
        event: 'copy_code',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.workplace_type,
        event_label: this.codegenLang
      });
      this.setMarketingActiveUser('developer');

      this.nextOnboardingStage({ currentStageSlug: 'export-code' });

      copyToClipboard(text);

      toastSuccess('Copied to clipboard!', {
        timeout: 2000
      });
    },

    handleClosePanel({ forceClose = false } = {}) {
      const panel = this.$refs.omniPanel;
      if (panel) {
        if (this.isOpen || forceClose) {
          panel.style.height = '40px';
        } else {
          this.handleCloseOpen();
        }
      }
    },
    handleCloseOpen({ tab } = {}) {
      if (this.isOpen) return;
      const panel = this.$refs.omniPanel;

      if (panel) {
        panel.style.height = '35%';
        this.lastPanelState = 'open';

        tab && (this.currentActivePanel = tab);
      }
    },
    initResizablePanel() {
      let panel = this.$refs.omniPanel;
      if (!panel) {
        panel = document.getElementById('omniPanel');
      }
      this.resizablePanel = interact(panel).resizable({
        edges: {
          top: true
        },
        onstart: () => {
          EventBus.$emit(PANEL_DRAG, true);
        },
        onend: (event) => {
          EventBus.$emit(PANEL_DRAG, false);
          event.target.style.transition = 'height 0.2s';
        },
        onmove: (event) => {
          event.target.style.height = event.rect.height + 'px';
          event.target.style.transition = 'none';
        }
      });
    },
    initPanelHeightObserver() {
      let panel = this.$refs.omniPanel;
      if (!panel) {
        panel = document.getElementById('omniPanel');
      }
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.setPanelHeight(Math.round(entry.contentRect.height));
        });
      });

      observer.observe(panel);
    },
    showAndHidePricing(bool) {
      if (this.isChromeExtension) {
        this.$trackEvent('omniview.chrome.extension.show.pricing.modal');
        // correct format: this.$trackEvent('omniview.chrome-extension-pricing.show');
        this.showPricing = bool;
      }
    },
    handlePathClicked({ nodeId, name }, idx) {
      switch (this.activeMode.name) {
        case 'Co':
          EventBus.$emit(SEND_MESSAGE, {
            action: HIGHLIGHT_COMMENT,
            data: {
              nodeId
            }
          });
          break;
        case 'C':
          EventBus.$emit(SEND_MESSAGE, {
            action: SELECT_OVERRIDE_NODE,
            data: {
              nodeId,
              metadata: {
                source: 'path'
              }
            }
          });
          break;

        default:
          break;
      }
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.dom-path.click', { tag: name, depth: idx, ...eventPayload });
    },

    handlePathMouseOver(nodeId) {
      if (!nodeId) return;
      const mode = this.activeMode.displayName;

      EventBus.$emit(SEND_MESSAGE, {
        action: `simulate-${mode}-mouseover`,
        data: {
          nodeId
        }
      });
    },
    handlePathMouseOut() {
      const mode = this.activeMode.displayName;

      EventBus.$emit(SEND_MESSAGE, {
        action: `simulate-${mode}-mouseout`
      });
    },

    openFeedbackPeriodically() {
      const lastFeedbackTime = localStorage.getItem('lastFeedbackTimestamp');
      if (lastFeedbackTime) {
        const hoursSince = dayjs(new Date(Date.now()).toISOString()).diff(
          dayjs(new Date(parseInt(lastFeedbackTime)).toISOString()),
          'hour'
        );
        if (hoursSince > 72) {
          this.$trackEvent('omniview-code-feedback-show');
          this.isCodeFeedbackOpen = true;
        }
      } else {
        this.isCodeFeedbackOpen = true;
      }
    },
    handleCodeSelect({ panel }) {
      if (panel == 'css') {
        this.animateStyleguideTab = true;
      }
    }
  },

  async mounted() {
    if (this.isChromeExtension) {
      await this.fetchAllUserMemberships({ id: 'me', skipCache: true });
    }

    this.handleClosePanel();
    this.initResizablePanel();
    this.initPanelHeightObserver();
    EventBus.$on('open-code-feedback-periodically', this.openFeedbackPeriodically);
    EventBus.$on(CLOSE_PANEL, this.handleClosePanel);
    EventBus.$on(OPEN_PANEL, this.handleCloseOpen);
    EventBus.$on('copy-code', this.copyCode);
    EventBus.$on('code-select', this.handleCodeSelect);
  },
  destroyed() {
    EventBus.$off('open-code-feedback-periodically', this.openFeedbackPeriodically);
    EventBus.$off(CLOSE_PANEL, this.handleClosePanel);
    EventBus.$off(OPEN_PANEL, this.handleCloseOpen);
    EventBus.$off('copy-code', this.copyCode);
    EventBus.$off('code-select', this.handleCodeSelect);
  }
};
</script>

<style lang="scss" scoped>
@import './panels';
</style>

<style lang="scss">
.secondary.white-button {
  border: 1px solid white !important;
  background: transparent !important;
  color: white !important;

  .three-dots div {
    background: white !important;
  }

  &:hover {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    .three-dots div {
      background: var(--primary) !important;
    }
  }
}

.blink-dot {
  background: var(--primary);
  box-shadow: 0 0 0 0 var(--primary);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.animate-dot {
  animation: pulse-red 1.5s infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.path-container {
  transition: 0.1s opacity;
}
.clickable:hover {
  opacity: 1;
}
</style>

import recognizedDomains from './recognizedDomains.json';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email) => emailRegex.test(email);

export const normalizeEmail = (email) => email?.trim().toLowerCase();

export const isRecognizedEmailDomain = (email) => {
  const emailDomain = normalizeEmail(email)?.split('@')[1];
  return recognizedDomains.includes(emailDomain);
};

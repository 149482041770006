<template>
  <article
    class="column"
    :class="{
      'current-plan': isCurrentPlan,
      'has-screen-options': screenPriceOptions && screenPriceOptions.length > 0,
      [theme]: true
    }"
  >
    <h3 class="title">{{ planName }}</h3>
    <div class="divider" />
    <div :class="['trial-subtitle', { hide: hideTrialElement }]">Start with a 7 day free trial</div>

    <h2 class="price">
      <svg-icon v-if="icon" :name="icon.name" :height="icon.height" :width="icon.width" />
      <span v-else-if="priceText">{{ priceText }}</span>
      <span v-else> {{ formattedPrice }}<span class="small" v-if="hasPrice"> / mo</span> </span>
    </h2>
    <div v-if="screenPriceOptions && screenPriceOptions.length > 0" class="pricing-screens">
      <an-stepper
        :options="screenPriceOptions"
        :default-value="screenPriceOptionSelected || '5'"
        @select="onSelectScreens"
        :theme="theme"
      />
      <span>No. of screens</span>
      <span v-tip="{ content: 'Figma frame screens/XD artboards', placement: 'top' }">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.4" cx="8" cy="8" r="8" fill="currentColor" />
          <path
            d="M6.47044 4.498C6.71977 4.35133 6.98377 4.245 7.26244 4.179C7.54111 4.113 7.80877 4.08 8.06544 4.08C8.51277 4.08 8.90144 4.157 9.23144 4.311C9.56144 4.45767 9.81811 4.663 10.0014 4.927C10.1848 5.191 10.2764 5.499 10.2764 5.851C10.2764 6.181 10.2141 6.47433 10.0894 6.731C9.96477 6.98767 9.80344 7.226 9.60544 7.446C9.41477 7.65867 9.21677 7.87133 9.01144 8.084C8.81344 8.29667 8.64111 8.524 8.49444 8.766C8.34777 9.008 8.25977 9.27933 8.23044 9.58L8.18644 9.833H7.53744V9.481C7.53744 9.11433 7.58877 8.799 7.69144 8.535C7.80144 8.26367 7.93711 8.02533 8.09844 7.82C8.25977 7.60733 8.41744 7.40567 8.57144 7.215C8.73278 7.017 8.86478 6.819 8.96744 6.621C9.07744 6.41567 9.13244 6.18833 9.13244 5.939C9.13244 5.653 9.03344 5.42933 8.83544 5.268C8.63744 5.09933 8.35877 5.015 7.99944 5.015C7.75744 5.015 7.50444 5.05533 7.24044 5.136C6.98377 5.20933 6.72711 5.323 6.47044 5.477V4.498ZM7.20744 12V10.68H8.52744V12H7.20744Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>
    <p v-else class="price-details">{{ subtitle }}</p>

    <an-button
      :data-cy="`pricing-column-cta-${planName.toLowerCase()}`"
      class="cta"
      variant="primary"
      @click="onClick"
      :disabled="isCurrentPlan"
    >
      {{ ctaButtonLabel }}
    </an-button>
    <div v-if="trialSupported" :class="['trial-disclaimer', { hide: hideTrialElement }]">{{ trialDisclaimer }}</div>
    <div class="description">
      {{ description }}
    </div>
    <div class="features-list">
      <div
        v-for="feature in features"
        :key="feature.label"
        class="features-list-item"
        :class="{ inactive: !feature.isActive }"
      >
        <svg-icon v-if="!feature.hideCheckbox" :name="getIconName(feature)" :size="24" />
        <div>{{ feature.label }}</div>
        <div v-if="feature.isNew" class="new-badge">New</div>
      </div>
      <div class="see-all-features-link" v-if="showDetailsLink">
        <an-link @click="$emit('click:details')" variant="primary">See all features</an-link>
      </div>
    </div>
    <div class="ribbon" v-if="ribbonName">
      <svg-icon :name="ribbonName" :width="109" :height="109" />
    </div>
  </article>
</template>

<script>
import { formatPrice } from '@/utils/billing';

export default {
  name: 'PricingColumn',
  props: {
    planName: {
      type: String,
      required: true
    },
    price: {
      type: Number
    },
    icon: {
      type: Object,
      validator: (v) => {
        if (!v) return true;
        return v.name && v.height && v.width;
      }
    },
    priceText: {
      type: String,
      required: false
    },
    screenPriceOptions: {
      type: Array,
      required: false
    },
    subtitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    features: {
      type: Array,
      required: true,
      validator: (v) => v.every((f) => 'label' in f && 'isActive' in f)
    },
    minQuantity: {
      type: Number,
      default: 1
    },
    ctaText: {
      type: String
    },
    billingCycle: {
      type: String,
      required: true,
      validator: (value) => ['month', 'annual'].includes(value)
    },
    isCurrentPlan: {
      type: Boolean,
      required: true
    },
    selectScreens: {
      type: () => Function,
      required: false
    },
    screenPriceOptionSelected: {
      type: String,
      required: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value)
    },
    ribbonName: {
      type: String,
      default: ''
    },
    trialSupported: {
      type: Boolean,
      default: false
    },
    showDetailsLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPrice() {
      return this.price > 0;
    },
    formattedPrice() {
      const price = this.price * this.minQuantity;
      return formatPrice(price);
    },
    ctaButtonLabel() {
      let label = `Get ${this.planName} plan`;
      if (this.isCurrentPlan) {
        label = 'Current plan';
      } else if (this.trialSupported) {
        label = 'Start Free Trial';
      } else if (this.ctaText) {
        label = this.ctaText;
      }
      return label;
    },
    hideTrialElement() {
      return !this.trialSupported || this.isCurrentPlan;
    },
    trialDisclaimer() {
      const period = this.billingCycle === 'annual' ? 'year' : 'month';
      return `Valid for 1 ${period} + 7 days trial`;
    }
  },
  methods: {
    onClick() {
      this.$emit('select-plan', this.plan);
    },
    onSelectScreens(value, skipTracking) {
      this.$emit('select-screens', { screen: value, skipTracking });
    },
    getIconName({ isActive }) {
      if (isActive) {
        return `circle-check${this.theme === 'dark' ? '-inverse' : ''}`;
      }
      return 'circle-minus-red';
    }
  },
  mounted() {
    if (this.screenPriceOptions && this.screenPriceOptions.length > 0) {
      const selected = this.screenPriceOptions.find((option) => option.value === this.screenPriceOptionSelected || '5');
      this.onSelectScreens(selected, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px;
  width: 330px;
  border: var(--border);
  border-radius: 15px;
  text-align: center;
  transition: all 0.2s ease;
  cursor: default;
  &.dark {
    background-color: var(--secondary);
    border: none;
  }
  &.current-plan:not(.has-screen-options) {
    opacity: 0.3;
  }
  &:hover {
    border: var(--primary-border);
    &.dark {
      border: none;
    }
  }
  + .column {
    margin-left: 40px;
  }
  @media screen and (max-width: 1440px) {
    + .column {
      margin-left: 20px;
    }
  }
  @include mobile {
    + .column {
      margin: 50px 0 0;
    }
  }
}
.title {
  @include secondary-title;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 5px;
}
.trial-subtitle {
  @include secondary-title;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.hide {
  opacity: 0;
}
.price {
  @include secondary-title;
  font-size: 60px;
  font-weight: 700;
  line-height: 80px;
  height: 90px;
  .small {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    opacity: 0.6;
  }
}
.trial-disclaimer {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
}
.divider {
  margin: 20px 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    border-top: 1px solid var(--divider-color);
  }
}
.description {
  height: 3em;
}
.price-details {
  @include body-text;
  font-family: 'Mulish';
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  opacity: 0.6;
}
.pricing-screens {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 0.5em;
}
.cta {
  margin: 30px 0;
}
.features-carry {
  color: var(--secondary-text);
  margin-top: 20px;
  height: 32px;
}
.features-list {
  text-align: left;
  margin-top: 40px;
  width: 100%;
}
.quirky-scratch-container {
  @include quirky-text;
  margin: 20px 0 0 36px;
  &.invisible {
    visibility: hidden;
  }
  .scratch {
    height: 2px;
    border-radius: 100px;
    background: var(--primary);
    width: 34px;
    transform: rotate(-2deg);
    margin-top: 15px;
    margin-bottom: -19px;
  }
}
.features-list-item {
  display: flex;
  align-items: center;
  padding: 4px 0;
  .svg-container {
    margin-right: 14px;
    flex-shrink: 0;
  }
  + .features-list-item {
    margin-top: 20px;
  }
  &.inactive {
    opacity: 0.3;
  }
}
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
}
.see-all-features-link {
  margin-top: 20px;
}
.new-badge {
  width: 34px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
  line-height: 22px;
  color: white;
  background-color: var(--green);
  margin-left: 10px;
}
</style>

import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'notification',
  modelName: 'notifications',
  crud: true,
  endpoint: '/v2/users/me/notifications',
  actions: {
    setAsRead({ dispatch }, { id }) {
      dispatch('updateNotificationLocally', { id, payload: { status: 'READ' } });
    },
    updateNotificationLocally({ state, commit }, { id, payload }) {
      const { items, count, page, total } = state;
      const results = items.map((item) => {
        if (item.id === id) return { ...item, ...payload };
        return item;
      });
      commit('setItems', { results, count, page, total });
    }
  },
  getters: {
    hasNewComments(state) {
      return ({ id = '', type = 'project' } = {}) => {
        const { items } = state;
        const unreadProjectComments = items.filter((n) => {
          const { event_type, status } = n;
          return status === 'UNREAD' && event_type === 'COMMENT' && n[type]?.id === id;
        });
        return !!unreadProjectComments.length;
      };
    }
  }
});

/* experemetal */

import api from '@/api';
import { EventBus } from '@/services/bus';
import { concat } from 'lodash-es';

export const createStoreModule = (options) => {
  const defaultOptions = {
    name: '',
    modelName: '',
    crud: false,
    actions: {},
    mutations: {},
    crudMutations: {},
    state: {},
    getters: {}
  };
  const config = { ...defaultOptions, ...options };
  const storeModule = {
    namespaced: true,
    state: {
      ...config.state
    },
    mutations: {
      ...config.mutations
    },
    crudMutations: {
      ...config.crudMutations
    },
    actions: {
      ...config.actions
    },
    getters: {
      ...config.getters
    }
  };

  if (config.crud) {
    const { endpoint } = config;
    if (!endpoint) throw new Error('Crud is enabled, an endpoint must be provided');
    const isMultiEndpoints = Object.prototype.toString.call(endpoint) === '[object Object]';

    const defaultEndpoint = isMultiEndpoints ? endpoint.default : endpoint;

    const crudState = {
      items: [],
      currentItem: {},
      total: 0,
      page: 0,
      pageSize: 10,
      count: 0
    };

    const setItems = (state, { results, total, count, page }) => {
      state.items = results;
      state.total = total;
      state.count = count;
      state.page = page;
    };
    const setCurrentItem = (state, item) => {
      state.currentItem = item;
    };
    const unshiftItems = (state, newItems) => {
      state.items = concat([], newItems, state.items);
      state.count = state.items.length;
    };
    const pushItems = (state, newItems) => {
      state.items = concat([], state.items, newItems);
      state.count = state.items.length;
    };
    const removeFromItems = (state, { id }) => {
      const newItems = state.items?.filter((item) => item?.id !== id);
      if (newItems.length !== state.items.length) {
        state.items = newItems;
        state.count = state.items.length;
        state.total = state.total - 1;
      }
    };
    const editFromItems = (state, updatedItem) => {
      state.items = state.items?.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
    };

    const crudMutations = {
      setItems,
      setCurrentItem,
      unshiftItems,
      pushItems,
      editFromItems,
      removeFromItems
    };

    const fetchAllOfParent = async (ctx, { parent, id, params, nextPage = false, skipCache = false } = {}) => {
      try {
        const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
        const url = `/v2/${parent}/${id}/${config.modelName}`;
        const _params = { ...params };
        if (nextPage) _params.page = ctx.state.page + 1;

        const { data } = await api.list(url, { params: _params, cachePolicy });
        if (nextPage) {
          const { items } = ctx.state;
          data.results = items.concat(data.results);
          data.count = data.results.length;
        }
        ctx.commit('setItems', data);
        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };
    const fetchAll = async (ctx, { params, skipCache = false } = {}) => {
      try {
        const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
        const url = endpoint.list ? endpoint.list : defaultEndpoint;
        const { data } = await api.list(url, { params, cachePolicy });
        ctx.commit('setItems', data);
        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };
    const fetchOne = async (ctx, { id, params, skipCache = false, storeResult = true } = {}) => {
      try {
        const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
        const url = endpoint.get ? endpoint.get : defaultEndpoint;
        const { data } = await api.get(url, id, { params, cachePolicy });

        if (storeResult && data) {
          ctx.commit('setCurrentItem', data);
        }

        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };
    const create = async ({ commit }, { parent, id, payload, storeResult = false }) => {
      try {
        const url = endpoint.create ? endpoint.create : `/v2/${parent}/${id}/${config.modelName}`;
        const { data } = await api.post(url, payload);
        EventBus.$emit(`${config.name}.created`);

        if (storeResult) {
          commit('setCurrentItem', data);
        }

        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };
    const update = async ({ commit }, { parent, parentId, id, payload, storeResult = false }) => {
      try {
        const url = endpoint.update
          ? endpoint.update
          : defaultEndpoint
          ? defaultEndpoint
          : `/v2/${parent}/${parentId}/${config.modelName}`;
        const { data } = await api.put(url, id, payload);
        EventBus.$emit(`${config.name}.updated`);

        if (storeResult) {
          commit('setCurrentItem', data);
        }

        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };
    const _delete = async (_, { parent, parentId, id, params = {} }) => {
      try {
        const url = endpoint.delete
          ? endpoint.delete
          : defaultEndpoint
          ? defaultEndpoint
          : `/v2/${parent}/${parentId}/${config.modelName}`;
        const { data } = await api.delete(url, id, params);
        EventBus.$emit(`${config.name}.deleted`);

        return data;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        return Promise.reject(error);
      }
    };

    const crudActions = {
      fetchAll,
      fetchAllOfParent,
      fetchOne,
      create,
      update,
      delete: _delete
    };

    storeModule.state = { ...storeModule.state, ...crudState };
    storeModule.actions = { ...storeModule.actions, ...crudActions };
    storeModule.mutations = { ...storeModule.mutations, ...crudMutations, ...storeModule.crudMutations };
  }

  return storeModule;
};

export default {
  methods: {
    getSlackAppUrl({ id, redirect }) {
      const isDev = process.env.NODE_ENV === 'development';
      const baseUrl = 'https://slack.com/oauth/authorize?client_id=62248055877.599865009525&scope=incoming-webhook';
      const state = `${id};${redirect}${isDev ? ';true' : ''}`;
      return `${baseUrl}&state=${state}`;
    }
  }
};

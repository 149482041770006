export const createCookie = (name, value, expiryDays) => {
  const d = new Date();
  d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * expiryDays);

  // @or: must specify domain for cookie to shared between subdomains (www and projects in our case)
  // https://stackoverflow.com/questions/18492576/share-cookie-between-subdomain-and-domain
  if (process.env.NODE_ENV === 'development') {
    document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/;domain=animaapp.loc`;
  } else {
    document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/;domain=animaapp.com`;
  }
};

export const readCookie = (name) => {
  const cookie = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c)
    .find((c) => c.startsWith(name));
  return cookie && cookie.split('=')[1];
};

<template>
  <div class="iframe-wrapper">
    <div :style="{ 'pointer-events': isDragging ? 'all' : 'none' }" class="overlay"></div>

    <transition name="fadeIn">
      <ComponentFrame
        mask
        :padding="0"
        showBorder
        name="componentIframe"
        v-show="isComponentView"
        key="componentIframe"
      />
    </transition>

    <div
      key="fullIframe"
      v-show="!isComponentView"
      @click="handleIframeContainerClicked"
      ref="rsc"
      :class="{ responsive: isResponseMode }"
      class="iframe-container"
    >
      <div v-if="activeMode.name === 'In'" @click.stop="toggleFullScreen" class="full-screen-toggle">
        <svg-icon v-if="isFullScreen" :size="20" fill="currentColor" name="page-minimize"></svg-icon>
        <svg-icon v-else fill="currentColor" :size="20" name="page-enlarge"></svg-icon>
      </div>
      <div :style="getResizableIframeStyles" ref="rs" id="resizable-iframe" class="resizable-iframe">
        <div v-if="!isPreview" class="pop-out-container" v-show="isCompareEnabled">
          <div class="compare-controlls">
            <span style="width: 42px">{{ Math.ceil(compareOpacity * 100) + '%' }}</span>
            <div class="slidecontainer">
              <svg-icon
                style="flex-shrink: 0"
                fill="currentColor"
                :size="20"
                name="compare-0"
                v-tip="{
                  content: 'Result of Anima generated code',
                  placement: 'top-center',
                  offset: 12
                }"
              ></svg-icon>
              <!-- <vue-slider
                style="width: 100%; margin-left: 8px; margin-right: 10px"
                @change="handleSliderInput"
                :value="compareOpacity * 100"
                :min="0"
                :max="100"
                tooltip="none"
              ></vue-slider> -->

              <svg-icon
                style="flex-shrink: 0"
                fill="currentColor"
                :size="20"
                name="compare-1"
                v-tip="{
                  content: 'Original design from design tool',
                  placement: 'top-center',
                  offset: 12
                }"
              ></svg-icon>
            </div>
            <svg-icon
              style="cursor: pointer"
              @click.native="setIsCompareEnabled(false)"
              fill="currentColor"
              :size="20"
              name="close"
            ></svg-icon>
          </div>
          <div v-if="currentComponent" ref="popOut" :style="popOutStyle" class="pop-out">
            <img :src="currentComponent.thumbnail_url" alt="Current Component" />
          </div>
        </div>

        <span :class="{ scrollable: zoomOption === 'normal' }">
          <div @click.stop class="tools">
            <!-- RESIZE HANDLER -->
            <transition name="flash">
              <div v-if="isResponseMode" class="flash-indicator"></div>
            </transition>
            <div :class="{ show: firstRenderOrDrag }" v-show="isResponseMode" class="canvas-resize-handle">
              <div :style="getRightHandlerStyle" class="handle right-handle">
                <div class="gutter-handle"></div>
                <div id="tab-handle" class="tab-handle"></div>
              </div>
              <div class="handle left-handle"></div>
            </div>
          </div>

          <iframe
            data-cy="main-iframe"
            :style="getIframeStyles"
            data-hj-allow-iframe=""
            ref="iframeRef"
            id="iframeId"
            name="iframeName"
            class="iframe"
            frameborder="0"
            :src="iframeUrl"
          ></iframe>
        </span>

        <!-- IFRAME -->
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import api from '@/api';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash';
import interact from 'interactjs';
import { SocketMixin } from '@/mixins';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { SEND_MESSAGE, PANEL_DRAG } from '@/utils/events/omniviewEvents';
import ComponentFrame from './ComponentFrame';
import { uuid } from '@/utils/uuid';
import { ResizeObserver } from '@juggle/resize-observer';
import errorHandler from '@/services/errorHandler';
//import LoadingScreen from '@/components/Loading/LoadingScreen';

export default {
  mixins: [SocketMixin],
  components: {
    ComponentFrame
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    previewMasterSlug: {
      type: String
    },
    ready: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      resizableInstance: null,
      width: 0,
      height: 0,
      offset: -1,
      position: { x: 0, y: 0 },
      firstRenderOrDrag: false,
      componentProgressive: null,
      baseSlug: ''
    };
  },

  asyncComputed: {
    iframeUrl: {
      default: '',
      async get() {
        if (this.isPreview) {
          return this.getPreviewURL;
        }
        if (!this.ready) {
          return '';
        }
        // wait until the project in populated
        await this.$waitFor(() => !!this.currentProject.id, true);
        const { subdomain } = this.currentProject || {};

        if (!subdomain) {
          errorHandler.captureMessage('MainFrame: missing subdomain', 'error');
          return '';
        }

        const domain = process.env.NODE_ENV === 'staging' ? 'staging-animaapp.io' : 'animaapp.io';
        let protocol = 'https://';
        let url = `${protocol}${subdomain}.${domain}`;
        return `${url}/${this.baseSlug}?t=${this.iframeId}&handoff=1`;
      }
    }
  },
  computed: {
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('omniview', { compareOpacity: 'compareOpacity' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapGetters({
      breakpoints: 'omniview/breakpoints',
      activeBreakpoint: 'omniview/activeBreakpoint',
      currentFrameWidth: 'omniview/currentFrameWidth',
      currentFrameHeight: 'omniview/currentFrameHeight',
      activeMode: 'omniview/activeMode',
      currentNode: 'omniview/currentNode',
      domLoading: 'omniview/domLoading',
      iframeId: 'omniview/iframeId',
      isAnimaScriptReady: 'omniview/isAnimaScriptReady',
      isComponentView: 'webComponents/isComponentView',
      zoomOption: 'omniview/zoomOption',
      screenBounds: 'components/screenBounds'
    }),
    isProjectLocked() {
      const { is_locked } = this.currentProject;
      return is_locked;
    },
    popOutStyle() {
      return {
        opacity: this.compareOpacity
      };
    },
    routeSlug() {
      return this.$route.params.screenSlug;
    },

    selected() {
      return !!this.currentNode.id;
    },

    isResponseMode() {
      return this.activeBreakpoint.id == 'res' && this.isDesktop;
    },

    maxContainerWidth() {
      // 30 px to account for resize handlers and 20 for spacing
      return `${this.width - 110}px`;
    },
    minContainerWidth() {
      if (this.breakpoints.length == 0) {
        let width = get(this.currentComponent || {}, 'width', 0);
        return width ? width + 'px' : 'auto';
      } else {
        return this.breakpoints[this.breakpoints.length - 1].width + 'px';
      }
    },
    getPreviewURL() {
      if (this.currentRelease?.is_code_generation_failed == true) {
        this.$trackEvent('omniview.code-generation.failure', { release_short_id: this.currentRelease?.short_id });
        return 'https://animaapp.s3.amazonaws.com/static/error-pages/anima4-error.html';
      }

      // prevent invalid url when loading
      const { releaseShortId } = this.$route.params;
      if (isEmpty(this.currentProject) || (isEmpty(this.currentComponent) && !releaseShortId)) return '';

      // @Amir if a releaseShortId exists in the url that means we are in the sync page
      // initially the previewMasterSlug is undefined we only want to render if it exists
      if (releaseShortId && !this.previewMasterSlug) return '';

      //progressive upload handle
      if (this.componentProgressive) {
        if (this.componentProgressive.length == 0) {
          this.$trackEvent('omniview.code-generation.generation', {
            release_short_id: this.currentRelease?.short_id,
            screen: this.previewMasterSlug
          });
          return 'https://animaapp.s3.amazonaws.com/static/error-pages/still-generated.html';
        }
        if (this.componentProgressive[0]?.is_code_generation_failed == true) {
          this.$trackEvent('omniview.code-generation.failure', { release_short_id: this.currentRelease?.short_id });
          return 'https://animaapp.s3.amazonaws.com/static/error-pages/anima4-error.html';
        }
      }

      const { subdomain, is_restart_button_enabled } = this.currentProject || {};
      const { master_slug } = this.currentComponent;
      const domain = process.env.NODE_ENV === 'staging' ? 'staging-animaapp.io' : 'animaapp.io';
      let protocol = 'https://';
      let url = `${subdomain}.${domain}`;

      if (releaseShortId) {
        url = `${url}/preview/${releaseShortId}/${this.previewMasterSlug}`;
      } else if (master_slug) {
        url = `${url}/${master_slug}`;
      }
      url += '?handoff=1';
      if (is_restart_button_enabled) {
        url += '&r=1';
      }
      return `${protocol}${url}`;
    },
    updatePreviewURL() {
      // prevent invalid url when loading
      const { releaseShortId } = this.$route.params;

      //progressive upload handle
      if (this.componentProgressive && this.componentProgressive[0]?.is_code_generation_failed == true) {
        this.$trackEvent('omniview.code-generation.failure', { release_short_id: this.currentRelease?.short_id });
        return 'https://animaapp.s3.amazonaws.com/static/error-pages/anima4-error.html';
      }

      const { subdomain, is_restart_button_enabled } = this.currentProject || {};
      const { master_slug } = this.currentComponent;
      const domain = process.env.NODE_ENV === 'staging' ? 'staging-animaapp.io' : 'animaapp.io';
      let protocol = 'https://';

      let url = `${subdomain}.${domain}`;

      if (releaseShortId) {
        url = `${url}/preview/${releaseShortId}/${this.previewMasterSlug}`;
      } else if (master_slug) {
        url = `${url}/${master_slug}`;
      }
      url += '?handoff=1';
      if (is_restart_button_enabled) {
        url += '&r=1';
      }
      return `${protocol}${url}`;
    },
    routeKey() {
      const { releaseShortId } = this.$route.params;
      const { master_slug } = this.currentComponent;
      if (releaseShortId) return releaseShortId;
      if (master_slug) return master_slug;
      return uuid();
    },
    iframeScale() {
      const { homepageScreen } = this.screenBounds;
      const realWidth = this.isResponseMode
        ? homepageScreen?.width
        : this.currentComponent.width
        ? this.currentComponent.width
        : this.activeBreakpoint.width;

      const defaultScale = {
        x: 1,
        y: 1,
        maxHeight: '100%',
        height: '100%'
      };
      if (!this.isDesktop) return defaultScale;
      if (this.zoomOption === 'width') {
        const scale = Math.min(this.width / realWidth, 1);
        const ratio = 1 / scale;
        return {
          x: scale,
          y: scale,
          maxHeight: `calc(100% * ${ratio})`,
          // minHeight: this.height + 'px',
          height: `calc(100% * ${ratio})`
          // maxHeight: this.currentComponent.height + 'px',
          // height: this.currentComponent.height + 'px'
        };
      }

      if (this.zoomOption === 'fit') {
        const scaleX = Math.min(this.width / realWidth, 1);
        const scaleY = Math.min(this.height / this.currentComponent.height, 1);
        const scale = Math.min(scaleX, scaleY);
        return {
          x: scale,
          y: scale,
          maxHeight: this.currentComponent.height + 'px',
          height: this.currentComponent.height + 'px'
        };
      }

      return defaultScale;
    },
    getRightHandlerStyle() {
      if (this.isResponseMode && this.currentFrameWidth + 20 > this.width) {
        return {
          width: '36px',
          '--handle-before-left': '17px'
        };
      }
      return {
        width: 14 + 'px',
        '--handle-before-left': '-3px'
      };
    },
    getResizableIframeStyles() {
      const { homepageScreen, minScreen } = this.screenBounds;
      const realWidth = this.isProjectLocked
        ? 0
        : this.isResponseMode
        ? homepageScreen?.width
        : this.currentComponent.width
        ? this.currentComponent.width
        : this.activeBreakpoint.width;

      return {
        height: this.getViewportHeight(),
        ...(!this.isResponseMode
          ? {
              width: this.isDesktop && realWidth ? realWidth * this.iframeScale.x + 'px' : '100%'
            }
          : {}),
        maxWidth:
          this.isResponseMode && this.zoomOption == 'normal'
            ? this.width + 'px'
            : this.width * this.iframeScale.x + 'px',
        minWidth:
          this.isResponseMode && this.zoomOption == 'normal'
            ? (minScreen?.width ?? 20) + 'px'
            : (minScreen?.width ?? 20) * this.iframeScale.x + 'px'
      };
    },
    getIframeWidth() {
      const { homepageScreen } = this.screenBounds;
      const realWidth = this.isResponseMode
        ? homepageScreen?.width
        : this.currentComponent.width
        ? this.currentComponent.width
        : this.activeBreakpoint.width;
      let width = '100%';
      if (this.isResponseMode || this.isMobile || this.isProjectLocked) {
        switch (this.zoomOption) {
          case 'normal':
            width = '100%';
            break;
          case 'fit':
            width = `calc(100% * ${1 / this.iframeScale.x})`;
            break;
          case 'width':
            width = `calc(100% * ${1 / this.iframeScale.x})`;
            break;

          default:
            break;
        }
      } else {
        width = realWidth + 'px';
      }
      return width;
    },
    getIframeStyles() {
      return {
        width: this.getIframeWidth,
        transform: `scaleX(${this.iframeScale.x}) scaleY(${this.iframeScale.y})`,
        transformOrigin: 'top left',
        maxHeight: this.iframeScale.maxHeight,
        ...(this.iframeScale.minHeight
          ? {
              minHeight: this.iframeScale.minHeight
            }
          : {}),
        height: this.iframeScale.height
      };
    }
  },
  methods: {
    ...mapMutations({
      setCurrentFrameWidth: 'omniview/setCurrentFrameWidth',
      setCurrentFrameHeight: 'omniview/setCurrentFrameHeight',
      setCompareOpacity: 'omniview/setCompareOpacity',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setIsFullScreen: 'omniview/setIsFullScreen',
      setDomLoading: 'omniview/setDomLoading',
      setZoomOption: 'omniview/setZoomOption'
    }),
    ...mapActions({
      resetSelection: 'omniview/resetSelection'
    }),
    toggleFullScreen() {
      // if (!this.isFullScreen) {
      //   this.setActiveBreakpoint({ id: 'res' });
      // }
      this.setIsFullScreen(!this.isFullScreen);
    },
    handleIframeContainerClicked() {
      if (this.activeMode.name == 'In') return;
      if (this.selected) {
        this.resetSelection();
      }
    },

    getViewportHeight() {
      let viewportHeight = 0;
      if (this.isPreview) {
        viewportHeight = get(this.currentComponent, 'viewportHeight', 0);
      } else {
        viewportHeight = get(this.currentComponent, 'viewport_height', 0);
      }

      if (viewportHeight !== 0) {
        return viewportHeight + 'px';
      } else {
        return '100%';
      }
    },

    handleSliderInput(value) {
      this.setCompareOpacity(value / 100);
    },
    sendMessage(message) {
      let iframe = this.$refs.iframeRef;
      if (!iframe) {
        iframe = document.getElementById('iframeId');
      }
      iframe.contentWindow.postMessage(
        { ...message, version: 'v2', isDev: process.env.NODE_ENV == 'development', codeModeV2: true },
        '*'
      );
    },
    initResizeObserver() {
      const myObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.setCurrentFrameWidth(Math.round(entry.contentRect.width));
          this.setCurrentFrameHeight(Math.round(entry.contentRect.height));
        });
      });
      const myObserver2 = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.contentRect.width) {
            this.width = entry.contentRect.width;
          }
          if (entry.contentRect.height) {
            this.height = entry.contentRect.height;
          }
        });
      });

      myObserver.observe(this.$refs.rs);
      myObserver2.observe(this.$refs.rsc);
    },

    initResizableInstance() {
      this.resizableInstance = interact(this.$refs.rs).resizable({
        edges: {
          right: '.right-handle',
          left: '.left-handle',
          cursorChecker: () => 'col-resize'
        },
        onstart: () => (this.isDragging = true),
        onend: () => {
          this.isDragging = false;
          this.offset = this.currentFrameWidth;
          this.firstRenderOrDrag = false;
        },
        onmove: (event) => {
          const w = this.offset - event.rect.width;
          event.target.style.setProperty('--width', Math.max(Math.min(event.rect.width - w, this.width), 20) + 'px');
          // event.target.style.width = `${Math.min(event.rect.width - w, this.width)}px`;
        }
      });
    },

    initPopOutDraggable() {
      interact(this.$refs.popOut).draggable({
        listeners: {
          move: (event) => {
            this.position.x += event.dx;
            this.position.y += event.dy;

            event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
          }
        }
      });
    },

    handleDragToggle(isDragging) {
      this.isDragging = isDragging;
    },
    updateOffset() {
      this.offset = this.currentFrameWidth;
    },
    toggleHighlightDragHandler(f) {
      this.firstRenderOrDrag = f;
    },

    scroller(event) {
      const { deltaY, deltaX, deltaMode } = event;
      EventBus.$emit(SEND_MESSAGE, {
        action: 'scroll',
        event: {
          deltaX,
          deltaY,
          deltaMode
        }
      });
      event.stopPropagation();
      event.preventDefault();
    },
    handleCustomWidth(width) {
      const { rs } = this.$refs;
      Object.assign(rs.style, {
        width: width + 'px'
      });
      setTimeout(() => {
        this.updateOffset();
      }, 200);
    },
    refreshIframe() {
      const { iframeRef } = this.$refs;
      const timestamp = new Date().getTime();
      iframeRef.src += '&time=' + timestamp;
    },
    updateGeneratedIframe(_resource, _resourceType, info) {
      const { relevantResourceId, relevantPageId } = info;

      if (relevantResourceId && relevantPageId) {
        const { iframeRef } = this.$refs;
        const newSrc = this.updatePreviewURL;
        iframeRef.src = newSrc;
      }
    },
    setSocket() {
      if (this.isPreview) {
        this.openSocket(this.currentRelease?.id);
        if (!this.socket) return;
        const { screenSlug } = this.$route.params;
        this.socket.on(
          { resource: 'release', action: 'updated', page: screenSlug + '.html', res_id: this.currentRelease?.id },
          this.updateGeneratedIframe
        );
      }
    },
    onChangeScreen() {
      this.setSocket();
      EventBus.$emit(SEND_MESSAGE, {
        action: 'change-screen',
        slug: this.$route.params.screenSlug
      });
    },
    onChangeIframeUrl() {
      fetch(this.iframeUrl, { mode: 'cors' }).then((response) => {
        if (response.status !== 200) {
          this.$trackEvent('omniview.iframe.failure', { errorCode: response.status, url: this.iframeUrl });
        }
      });
    }
  },
  watch: {
    currentRelease: {
      handler: 'setSocket',
      immediate: true
    },
    routeSlug: {
      handler: 'onChangeScreen'
    },
    iframeUrl: {
      handler: 'onChangeIframeUrl'
    },
    currentFrameWidth(to) {
      if (this.offset == -1 && to) {
        this.updateOffset();
      }
    },
    previewMasterSlug(newVal) {
      if (newVal && this.currentRelease?.screens_metadata && this.currentRelease?.screens_metadata.length > 0) {
        const params = { slug: newVal };
        this.componentProgressive = [];
        api.get(`v3/releases/${this.currentRelease?.id}/components`, null, { params }).then((res) => {
          this.componentProgressive = res?.data.results;
        });
      }
    }
  },
  beforeMount() {
    this.baseSlug = this.$route.params.screenSlug;
  },
  mounted() {
    this.initResizeObserver();
    this.initResizableInstance();
    if (!this.isPreview) {
      this.initPopOutDraggable();

      this.$refs.popOut.addEventListener('wheel', this.scroller);
    }

    // this a fallback load in case anima-scripts didnt not load (legacy project...)
    this.$refs.iframeRef.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          this.setDomLoading(false);
        }, 5000);
        this.$emit('iframe-loaded');
      },
      true
    );

    EventBus.$on('refresh-iframe', this.refreshIframe);
    EventBus.$on('setCustomWith', this.handleCustomWidth);
    EventBus.$on('highlight-handler', this.toggleHighlightDragHandler);
    EventBus.$on('update-offset', this.updateOffset);
    EventBus.$on(SEND_MESSAGE, this.sendMessage);
    EventBus.$on(PANEL_DRAG, this.handleDragToggle);

    // reset zoom option on mount
    // this.setZoomOption({
    //   option: 'normal'
    // });
  },
  destroyed() {
    if (!this.isPreview) {
      this.$refs.popOut && this.$refs.popOut.removeEventListener('wheel', this.scroller);
    }
    EventBus.$off('refresh-iframe', this.refreshIframe);
    EventBus.$off('setCustomWith', this.handleCustomWidth);
    EventBus.$off('highlight-handler', this.toggleHighlightDragHandler);
    EventBus.$off('update-offset', this.updateOffset);
    EventBus.$off(SEND_MESSAGE, this.sendMessage);
    EventBus.$off(PANEL_DRAG, this.handleDragToggle);
  }
};
</script>

<style lang="scss" scoped>
@import './MainFrame.scss';
</style>

<template>
  <div class="copy-text">
    <div class="text-container">
      <span class="label" v-if="label">{{ label }}:</span>
      <span class="text">{{ text }}</span>
    </div>
    <div class="copy">
      <an-link variant="primary" @click="copy">Copy</an-link>
    </div>
  </div>
</template>

<script>
import copy from '@/utils/copyToClp';
export default {
  props: {
    label: {
      type: String
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    copy() {
      copy(this.text);
      this.$emit('copied');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.copy-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
  padding-right: 80px;
  border-radius: 10px;
  background-color: var(--light-container-background);
  .text-container {
    @include ellipsis;
  }
  .label {
    font-weight: bold;
    margin-right: 5px;
  }

  .copy {
    margin-left: 12px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>

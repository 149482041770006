<template>
  <div class="list-card" :style="gridStyle" data-cy="new-project-card">
    <div class="plus-sign"></div>
    <span class="title">New project</span>
    <div class="new-project-gif" @click="newProject"></div>
  </div>
</template>

<script>
import { openModal } from '@/services/bus';

export default {
  computed: {
    gridStyle() {
      const { isMobile } = this;
      let min, columnGap, rowGap;
      if (isMobile) {
        min = '335px';
        columnGap = '20px';
        rowGap = '30px';
      } else {
        min = '300px';
        columnGap = '30px';
        rowGap = '40px';
      }
      return {
        display: 'grid',
        justifyContent: 'start',
        columnGap,
        rowGap,
        gridTemplateColumns: `repeat(auto-fill, minmax(${min}, 1fr))`
      };
    }
  },
  methods: {
    newProject() {
      const { isUserDeveloper } = this;
      this.$trackEvent('team-empty-state.new-project.click', { isUserDeveloper });
      openModal({ name: 'new-project' });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-card {
  background-color: var(--white);
  box-shadow: var(--shadow);
  border-radius: 10px;
  position: relative;
  @include mobile {
    height: 323px;
  }
}

.new-project-gif {
  position: absolute;
  border-radius: 10px;
  line-height: 160px;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/illustrations/anima-art.gif');
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.new-project-gif:hover {
  cursor: pointer;
  opacity: 1;
}
.title {
  font-family: Roslindale;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  display: block;
  position: relative;
  text-align: center;
}

.new-project-gif:hover .title {
  display: none;
}

.plus-sign {
  box-sizing: border-box;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 2px solid #3b3b3b;
  outline: 4px solid white;
  transition: all 0.4s ease-in-out;
  margin: auto;
  top: 50%;
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.plus-sign::after {
  content: ' ';
  position: absolute;
  display: block;
  background-color: #3b3b3b;
  height: 2px;
  margin-top: -1px;
  top: 50%;
  left: 10px;
  right: 10px;
  z-index: 9;
  border-radius: 99px;
  transition: all 0.2s ease-in-out;
}
.plus-sign::before {
  content: ' ';
  position: absolute;
  display: block;
  background-color: #3b3b3b;
  width: 2px;
  margin-left: -1px;
  left: 50%;
  top: 10px;
  bottom: 10px;
  z-index: 9;
  border-radius: 99px;
  transition: all 0.2s ease-in-out;
}

.list-card:hover > .plus-sign {
  transform: scale(2);
  transform-origin: top;
  border: 1px solid #ff6250;
}

.list-card:hover > .plus-sign::before {
  background-color: #ff6250;
}
.list-card:hover > .plus-sign::after {
  background-color: #ff6250;
}
</style>

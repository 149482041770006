import { trackEvent, debugEvent } from '@/services/tracking';
import { isProd, isCanary } from '@/utils/environment';
import Sentry from '@/plugins/sentry';

class ErrorHandler {
  constructor() {
    this.trackEvent = isProd() || isCanary() ? trackEvent : debugEvent;
  }
  captureMessage(...args) {
    Sentry?.captureMessage(...args);
  }
  captureMessageAndTrack(message, event) {
    Sentry?.captureMessage(message);
    const { name, data = {} } = event;
    this.captureMessage(message);
    this.trackEvent(name, data);
  }
  isError(obj) {
    return Object.prototype.toString.call(obj) === '[object Error]';
  }
  captureException(error) {
    if (this.isError(error)) {
      Sentry?.captureException(error);
    } else if (typeof error === 'string' || error instanceof String) {
      Sentry?.captureException(error);
    } else {
      const exception =
        error?.response?.data?.message || error?.error || error?.message || error?.originalError || error;
      Sentry?.captureException(exception);
    }
  }
  captureExceptionAndTrack(err, event) {
    const { name, data = {} } = event;
    this.captureException(err);
    this.trackEvent(name, data);
  }
}

const errorHandler = new ErrorHandler();

export default errorHandler;

import { createStoreModule } from '../utils';
import router from '@/router';
import dayjs from 'dayjs';
import Vue from 'vue';
import { isEmpty } from 'lodash-es';

const getTeamData = (rootState, rootGetters) => {
  const { id: teamId } = rootState?.teams?.currentItem;
  const { teamSlug } = rootState?.route?.params;
  const { team_plan, role, access_level } = rootState?.teamMemberships?.currentItem;
  const teamByMembership = rootGetters['teamMemberships/getTeam']({ id: teamId, slug: teamSlug });
  const teamMembershipData = {};

  // report following data only for team related pages.
  if (teamSlug) {
    teamMembershipData.team_role = role;
    teamMembershipData.team_access_level = access_level;
  }

  return {
    team_id: teamId || rootState?.users?.defaultTeamId || teamByMembership?.id,
    team_plan,
    team_slug: teamSlug,
    ...teamMembershipData
  };
};

export default createStoreModule({
  name: 'tracking',
  state: {
    extraTrackingData: {},
    extraExportedCodeEventData: {}
  },
  mutations: {
    setExtraData: (state, extraData) => (state.extraTrackingData = extraData),
    setExtraExportedCodeEventData: (state, extraData) => {
      state.extraExportedCodeEventData = extraData;
    }
  },
  getters: {
    extraData(state, getters, rootState, rootGetters) {
      const teamData = getTeamData(rootState, rootGetters);

      return {
        ...state.extraTrackingData,
        ...teamData
      };
    },
    omniviewFrameworkProps(state, getters, rootState, rootGetters) {
      const { projects_components_count: screensCount } = rootState?.teams?.currentItem;
      const layout = rootGetters['codePreferences/codegenHTMLLayout'];
      const framework = rootGetters['codePreferences/codegenLang'];
      const engine = rootGetters['codePreferences/codegenEngine'];

      let eventPayload = { framework, engine, number_of_screens: screensCount };

      if (framework === 'html') {
        eventPayload = { ...eventPayload, layout, 'style-type': rootGetters['codePreferences/codeStyling'] };
      } else if (framework === 'react') {
        eventPayload = {
          ...eventPayload,
          syntax: rootGetters['codePreferences/codegenReactSyntax'],
          'style-type': rootGetters['codePreferences/codegenReactStyle'],
          language: rootGetters['codePreferences/codegenReactLanguage']
        };
      } else if (framework === 'vue') {
        eventPayload = { ...eventPayload, 'style-type': rootGetters['codePreferences/codegenVueStyle'] };
      }
      return eventPayload;
    }
  },
  actions: {
    trackPageView({ rootState, dispatch }, toRoute) {
      const isDesktop = rootState.screenSize.isDesktop;
      const screenWidth = window?.innerWidth;
      const { name, params, query } = toRoute;
      Vue.prototype.$trackEvent('webapp.page.view', { ...params, ...query, isDesktop, screenWidth, name });

      dispatch('trackGtmPageView', toRoute);
    },
    trackGtmPageView({ rootGetters }, toRoute) {
      let gtmProps = {
        role: rootGetters['users/role'],
        workplaceType: rootGetters['users/workplaceType'],
        intent: rootGetters['users/intent'],
        email: rootGetters['users/userEmail'],
        firstName: rootGetters['users/firstName'],
        lastName: rootGetters['users/lastName']
      };

      if (Object.values(gtmProps).some((v) => v)) {
        Vue.prototype.$gtm.trackView(toRoute.name, toRoute.path, gtmProps);
      } else {
        Vue.prototype.$gtm.trackView(toRoute.name, toRoute.path);
      }
    },
    trackIdleTime({ rootState }, { startTime }) {
      const isDesktop = rootState.screenSize.isDesktop;
      const screenWidth = window?.innerWidth;
      const { name } = router.currentRoute;
      const timeInPage = dayjs().diff(startTime, 'second');
      Vue.prototype.$trackEvent('webapp.page.idle', { timeInPage, isDesktop, screenWidth, name });
    },
    trackIntercomShow() {
      Vue.prototype.$trackEvent('webapp.intercom.show');
    },
    // The exported_code.initiated should be tracked as close to the user action that triggered it as possible.
    // That allows us to "track" which panel/action caused it.
    trackExportedCodeInitiated({ dispatch, commit }, extraParams) {
      dispatch('__trackExportedCode', { status: 'initiated', params: extraParams });

      if (!isEmpty(extraParams)) {
        // The "initiated" event can be used to save extra parameters that will be used by the success
        // and failure events
        commit('setExtraExportedCodeEventData', extraParams);
      }
    },
    trackExportedCodeSuccess({ dispatch, commit }, extraParams = {}) {
      dispatch('__trackExportedCode', { status: 'success', params: extraParams });
      commit('setExtraExportedCodeEventData', {});
    },
    trackExportedCodeFailure({ dispatch, commit }, extraParams = {}) {
      dispatch('__trackExportedCode', { status: 'failure', params: extraParams });
      commit('setExtraExportedCodeEventData', {});
    },
    __trackExportedCode({ state, getters }, { status, params = {} }) {
      const payload = {
        ...getters.omniviewFrameworkProps,
        ...state.extraExportedCodeEventData,
        ...params,
        origin: 'webapp'
      };
      // Instead of dynamically generating the event name, we need to call the $trackEvent function
      // with the event name as string literal, otherwise the extract-events script won't detect them.
      if (status === 'initiated') {
        Vue.prototype.$trackEvent('exported_code.initiated', payload);
      } else if (status === 'success') {
        Vue.prototype.$trackEvent('exported_code.success', payload);
      } else if (status === 'failure') {
        Vue.prototype.$trackEvent('exported_code.failed', payload);
      }
    }
  }
});

<template>
  <div>
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="container-form">
        <input
          ref="input"
          type="file"
          :name="fieldName"
          :disabled="isSaving"
          @change="save($event.target)"
          :accept="accept"
          class="input-file"
        />
        <div v-if="isSaving && $slots.loading">
          <slot name="loading" />
        </div>
        <div v-else-if="$slots.default" @click="$refs.input.click()">
          <slot />
        </div>
        <div v-else-if="isInitial" @click="$refs.input.click()">
          <slot name="init" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import api from '@/api';

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  data() {
    return {
      uploadError: null,
      currentStatus: null
    };
  },
  props: {
    fieldName: {
      type: String,
      default: 'file'
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    url: {
      type: String,
      required: true
    },
    context: {
      type: Object,
      default: () => ({})
    },
    isFileValid: {
      type: Function,
      default: () => true
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
    },
    async save({ name, files }) {
      const { context } = this;
      const fileRef = this.$refs.input;
      if (!this.isFileValid(fileRef)) {
        fileRef.value = '';

        if (!/safari/i.test(navigator.userAgent)) {
          fileRef.type = '';
          fileRef.type = 'file';
        }
        return;
      }
      try {
        this.$emit('start', { ...context, fileRef });
        // handle file changes
        const formData = new FormData();

        if (!files.length) return;

        // append the files to FormData
        const file = files[0];

        formData.append(name, file, file.name);

        this.currentStatus = STATUS_SAVING;
        const { data } = await api.post(this.url, formData, {
          onUploadProgress: (e) => this.$emit('upload-progress', e)
        });
        if (!isEmpty(context)) {
          data.context = context;
        }
        this.$emit('success', data);
      } catch (error) {
        this.$emit('failure', { error, context });
      } finally {
        this.reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-form {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
</style>

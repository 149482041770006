<!-- This is a wrapper component that should be removed once the transition from mixins to hooks is complete  -->

<script>
import { defineComponent } from 'vue';
import codegenMixin from './codegenMixin';

export default defineComponent({
  mixins: [codegenMixin],
  render() {
    return this.$slots.default;
  }
});
</script>

<style lang="scss" scoped></style>

<template>
  <div class="search-input-container">
    <div class="clickable-icon" @click="toggleOpen(!isOpen)">
      <svg-icon name="search" :size="24" />
    </div>
    <transition name="search-input">
      <div class="input" v-if="isOpen">
        <an-input
          v-model="innerValue"
          size="sm"
          placeholder="Search"
          noBorder
          focus
          @input="onValueChange"
          @blur="onBlur"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
export default {
  data() {
    return {
      innerValue: '',
      isOpen: false
    };
  },
  methods: {
    onValueChange: debounce(function (val) {
      this.$emit('input', val);
    }, 150),
    toggleOpen(value) {
      this.isOpen = value;
      if (!value) {
        this.innerValue = '';
        this.$emit('input', '');
      }
    },
    onBlur() {
      if (!this.innerValue) {
        this.isOpen = false;
      }
    }
  },
  watch: {
    isOpen(value) {
      this.$emit('toggle-open', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .clickable-icon {
    margin-right: 16px;
  }
  .input {
    transition: width 0.5s ease;
    max-width: 185px;
    .inputContainer {
      width: 100%;
    }
  }
}

.search-input-enter-active,
.search-input-leave-active {
  width: 200px;
}
.search-input-enter,
.search-input-leave-to {
  width: 0;
}
</style>

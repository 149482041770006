var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-preview-container w-full",style:({ height: _vm.previewLoading ? '30px' : _vm.maskSize.height * _vm.scaleValue + 'px', overflow: 'hidden' })},[(_vm.previewLoading)?_c('div',{staticClass:"flex items-center justify-center w-full"},[_c('svg-icon',{staticClass:"icon-spin",attrs:{"fill":"currentColor","name":"spin","size":30}})],1):_vm._e(),(!_vm.isExternalImage && !_vm.previewLoading)?_c('div',{staticClass:"mask-container",style:({
      width: _vm.maskSize.width + 'px',
      height: _vm.maskSize.height + 'px',
      transform: `scale(${_vm.scaleValue})`,
      transformOrigin: 'left top',
      maxWidth: _vm.scaleValue == 1 ? '100%' : 'unset'
    })}):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.src && !_vm.previewLoading),expression:"src && !previewLoading"}],ref:"previewImageRef",style:({
      transform: `scale(${_vm.scaleValue})`,
      transformOrigin: 'left top',
      maxWidth: _vm.scaleValue == 1 ? '100%' : 'unset'
    }),attrs:{"src":_vm.previewSrc,"alt":""},on:{"load":_vm.updateMaskSize,"error":function($event){_vm.localLoading = false}}}),(!_vm.loading && !_vm.src)?_c('div',{staticStyle:{"opacity":"0.4"}},[_vm._v("Could not generate image preview")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <an-prompt v-if="open" data-cy="custom-domain-paywall" @close="$emit('close')">
    <img
      class="illustration"
      src="@/assets/illustrations/quirky-clock-light.svg"
      alt="Quirky clock light"
      title="Unlock Pro Features"
      width="198"
      height="120"
    />
    <h3 class="title">Unlock Pro Features</h3>
    <p class="subtitle">
      Upgrade your plan to add your custom domain as well as download code or components in HTML, React or Vue.
    </p>
    <div class="actions">
      <an-button @click="onUpgradeClick">See upgrade plans</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </an-prompt>
</template>

<script>
export default {
  name: 'custom-domain-paywall',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onUpgradeClick() {
      this.$trackEvent(`${this.$route.name}.paywall-upgrade-button.click`);
      this.$router.push({ name: 'pricing' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.illustration {
  width: 198px;
  height: 120px;
  margin-inline: auto;
}

.subtitle {
  text-align: center;
}
</style>

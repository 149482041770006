export const teamWatcherPlugin = (store) => {
  let currentTeamId = store.state.teams.currentItem ? store.state.teams.currentItem.id : null;

  store.subscribe((mutation) => {
    if (mutation.type === 'teams/setCurrentItem') {
      const teamId = mutation.payload?.id;

      if (teamId !== currentTeamId) {
        currentTeamId = teamId;
        store.dispatch('stigg/fetchSubscription', { teamId, shouldSetAsCurrent: true });
      }
    }
  });
};

<template>
  <main data-cy="playground-omniview" class="content-wrapper">
    <div class="content">
      <ProjectLimitReached v-if="isProjectLimitReached" />
      <Playground v-else />
    </div>
  </main>
</template>

<script setup>
import Playground from './Playground.vue';
import ProjectLimitReached from '@/components/OmniView/ProjectLimitReached.vue';
import { useCheckExportPermissions } from '@/hooks';

const { isProjectLimitReached } = useCheckExportPermissions();
</script>

<style lang="scss" scoped>
.content-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-top: var(--omniview-topbar-height);
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #2d2d2d;
}
</style>

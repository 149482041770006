import api from '@/api';
import { get } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      CodeReady: {
        Flex: false,
        Absolute: false,
        React: false
      },
      _type: 'Flex',
      _exportWhenReady: false,
      _clicked: false,
      exportCodeLoading: false,
      _isReadyToDownload: false
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapGetters({
      _codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      codegenReactLanguage: 'codePreferences/codegenReactLanguage',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    })
  },
  watch: {
    _isReadyToDownload: {
      handler(isReady) {
        if (isReady) {
          this.exportCodeLoading = false;
          if (this._clicked) {
            this._exportCode();
          }
        }
      }
    }
  },
  mounted() {
    this.checkRelease(this.currentProjectRelease || {});
  },
  methods: {
    ...mapActions({
      fetchProjectRelease: 'projectReleases/fetchOne'
    }),
    checkRelease(release) {
      let typeMap = {
        Flex: !!get(release, 'download_zips.Flex', ''),
        Play: !!get(release, 'download_zips.Play', ''),
        React: !!get(release, 'download_zips.React', ''),
        Vue: !!get(release, 'download_zips.Vue', '')
      };

      this.CodeReady = typeMap;
      this._isReadyToDownload = typeMap[this._type];
    },
    _startPolling() {
      if (this._isReadyToDownload) return;
      const id = this.currentProject.live_project_release;
      this.exportCodeLoading = true;

      const fetchAction = this.fetchProjectRelease;

      const request = setInterval(() => {
        if (this._isReadyToDownload) {
          clearInterval(request);
          return this.handleExportCode();
        }

        fetchAction({ id, skipCache: true })
          .then((data) => {
            this.checkRelease(data);
          })
          .catch(() => {
            clearInterval(request);
            this.exportCodeLoading = false;
          });
      }, 5000);
    },
    _getDownloadUrl() {
      if (!this._type) return null;
      return this.currentProjectRelease.download_zips[this._type];
    },
    async requestPackage({ settings }) {
      const { id } = this.currentProjectRelease;
      this._setType();
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.package-on-demand.start', eventPayload);
      // notify_when_done: false -> For legacy webapp - can remove when old webapp cache will be invalidated
      const payload = {
        package_types: this._type,
        settings,
        notify_when_done: false
      };
      return await api.post(`/project_release/${id}/package`, payload);
    },
    _exportCode() {
      this._clicked = false;
      const url = this._getDownloadUrl();
      this.$trackEvent('omni.code-mode.download-code');
      this.$trackEvent('omniview.export-code.code-exported');

      /* for Cypres test */
      const anchor = document.createElement('a');
      anchor.setAttribute('data-cy', 'exportCode-anchor');
      anchor.href = url;
      document.body.appendChild(anchor);
      if (window.Cypress) {
        this.exportCodeLoading = false;
        return;
      }
      /* --- */

      let win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
      this.exportCodeLoading = false;
      this._exportWhenReady = false;
      anchor.remove();
    },
    _setType() {
      const { layout, framework } = this._codeDownloadPrefs;

      this._type = 'Play';
      if (framework === 'react') {
        this._type = 'React';
      } else if (framework === 'vue') {
        this._type = 'Vue';
      } else if (layout === 'auto_flexbox' || layout === 'flexbox') {
        this._type = 'Flex';
      }
    },
    trackExportCodeEvent() {
      const { layout, framework } = this._codeDownloadPrefs;
      this.$trackEvent('omniview.download-code-button.clicked', {
        framework,
        layout,
        page: 'omniview'
      });
      this.$gtm.trackEvent({
        event: 'html_code_download',
        event_category: 'HTML Code Download',
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: framework
      });
    },
    handleExportCode() {
      this._clicked = true;
      this._setType();

      this.trackExportCodeEvent();
      this.checkRelease(this.currentProjectRelease);

      if (this._isReadyToDownload) {
        this._exportCode();
      } else {
        this._startPolling();
      }
    }
  }
};

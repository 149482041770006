<template>
  <div class="progress-bubble-container">
    <transition name="accordion">
      <div class="bubble-container">
        <div class="bubble-end" />
        <div class="bubble">
          <div class="close-button" @click="dismiss">
            <svg-icon name="close" :size="20" />
          </div>
          <Stages
            :list="stages"
            :selectedIndex="selectedStageIndex"
            :activeIndex="activeStageIndex"
            :eventProps="eventProps"
            inOmniview
            @click:stage="onClickStage"
            @click:cta="onClickCta"
          />
          <div :class="['inline-progress-container', { done: status === 'done' }]" v-if="!turnedToDone">
            <InlineProgress
              :userOnboarding="userOnboarding"
              :inOmniview="inOmniview"
              noPulse
              @click="onClickInlineProgress"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import InlineProgress from '@/components/PersonalizedOnboarding/InlineProgress';
import Stages from '@/components/PersonalizedOnboarding/Stages';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty } from 'lodash-es';
import { EventBus } from '@/services/bus';

export default {
  data() {
    return {
      turnedToDone: false,
      selectedStageIndex: 0
    };
  },
  props: {
    inOmniview: {
      type: Boolean,
      default: true
    }
  },
  components: {
    InlineProgress,
    Stages
  },
  computed: {
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    ...mapState('webappSystem', ['shouldOpenOnboarding', 'showConfettiOnDone']),
    ...mapGetters({
      flow: 'userOnboardings/onboardingFlow',
      stages: 'userOnboardings/onboardingStages',
      status: 'userOnboardings/onboardingStatus',
      currentOnboardingStage: 'userOnboardings/currentStage'
    }),
    activeStageIndex() {
      return this.userOnboarding.active_stage_index;
    },
    eventProps() {
      return {
        onboardingType: this.flow.type,
        context: 'omniview'
      };
    }
  },
  methods: {
    ...mapActions({
      nextOnboardingStage: 'userOnboardings/nextStage'
    }),
    onClickInlineProgress() {
      this.$trackEvent('personalized-onboarding.inline-progress.click', { ...this.eventProps });
    },
    dismiss() {
      const currentStageSlug = this.currentOnboardingStage?.slug;
      this.nextOnboardingStage({ currentStageSlug });
      this.$emit('dismiss');
    },
    onClickStage(stage) {
      this.$trackEvent('personalized-onboarding.stage.click', {
        stage: stage.title,
        slug: stage.slug,
        ...this.eventProps
      });
      this.selectedStageIndex = stage.index;
    },
    onClickCta(stage) {
      const { data: stageData, slug } = stage;
      if (!isEmpty(stageData)) {
        switch (stageData.action) {
          case 'navigate':
            this.onNavigation(stageData.data || {});
            break;
          case 'next-stage':
            this.nextOnboardingStage({ currentStageSlug: slug });
            break;
          default:
            break;
        }
      }
    },
    onNavigation({ route = '', route_name: name = '', route_params: params = {}, route_query: query = {} }) {
      if (route.startsWith('http')) {
        window.open(route, '_blank');
      } else {
        const routeToResolve = route || { name, params, query };
        const resolved = this.$router.resolve(routeToResolve);
        this.$router.push(resolved.href);
      }
    }
  },
  watch: {
    activeStageIndex: {
      handler(newIndex) {
        this.selectedStageIndex = newIndex;
        if (newIndex >= this.stages?.length) this.$emit('dismiss');
      },
      immediate: true
    },
    turnedToDone(newValue) {
      if (newValue) this.$emit('dismiss');
    },
    status: {
      handler(newValue, oldValue) {
        if (newValue === 'done' && oldValue !== 'done') {
          this.$trackEvent('personalized-onboarding.flow.complete', { ...this.eventProps });
          setTimeout(() => (this.turnedToDone = true), 3000);
          if (this.showConfettiOnDone) {
            EventBus.$emit('confetti');
          }
        } else {
          this.turnedToDone = newValue === 'done';
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-bubble-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: calc(100% + 10px);
  right: 0;
}
.bubble-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
  .bubble {
    position: relative;
    width: 260px;
    background-color: var(--green);
    color: white;
    border-radius: 6px;
    .close-button {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  .bubble-end {
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 10px solid var(--green);
    margin-right: 20px;
  }
}
.inline-progress-container {
  margin-left: 20px;
  margin-bottom: 15px;
  width: 125px;
  &.done {
    width: 130px;
  }
}
.slide-inline-progress-enter-active,
.slide-inline-progress-leave-active {
  transition: all 0.2s;
  overflow: hidden;
}
.slide-inline-progress-enter,
.slide-inline-progress-leave-to {
  transform: translateX(40px);
  opacity: 0;
  overflow: hidden;
}
</style>

<template>
  <div class="container">
    <h3 class="title">Export code</h3>
    <p class="subtitle">{{ ctaText }}</p>
    <div class="actions">
      <an-button :isWorking="isWorking" @click="handleCTA">{{ ctaButtonText }}</an-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TeamMembershipMixin } from '@/mixins';
import { toastError, toastSuccess } from '@/services/bus';
import errorHandler from '@/services/errorHandler';

export default {
  name: 'export-code-as-viewer',
  data() {
    return {
      isWorking: false
    };
  },
  mixins: [TeamMembershipMixin],
  computed: {
    ...mapGetters({
      isOwner: 'teamMemberships/isOwner',
      isViewer: 'teamMemberships/isViewer'
    }),
    ctaText() {
      if (this.isOwner) {
        return `Oh hi! It seems you are marked as a Viewer... exporting code requires a Contributor role.`;
      } else if (this.isViewer) {
        return `Oh hi! It seems you are marked as a Viewer... exporting code requires a Contributor role. Let's ask for access!`;
      } else {
        return '';
      }
    },
    ctaButtonText() {
      return this.isOwner ? 'Update role' : 'Request access';
    }
  },
  methods: {
    handleCTA() {
      if (this.isOwner) {
        this.$router.push({ name: 'team-members' });
      } else {
        this.handleRequestAccess();
      }
    },
    async handleRequestAccess() {
      try {
        this.isWorking = true;
        this.$trackEvent('export-code-as-viewer.request-access-button.click');

        await this.askForAccess();

        this.$trackEvent('export-code-as-viewer.request-access.success');
        toastSuccess('Successfully sent request for access!');
        this.$emit('close');
      } catch (err) {
        if (err?.response?.status === 409) {
          toastError('You already requested access over the past week.');
        } else {
          toastError("Failed to request access. We've been notified and we will investigate!");
        }

        errorHandler.captureExceptionAndTrack(err, {
          name: 'export-code-as-viewer.request-access.failure',
          data: { message: err.message }
        });
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('export-code-as-viewer.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

@include mobile {
  .modal {
    height: 280px;
  }
}

.container {
  margin: 0;
  height: 280px;

  &:after {
    height: unset;
  }

  .title {
    font-size: 36px;
    line-height: 50px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    color: hsla(0, 0%, 60%, 1);
  }
}
</style>

import { sample } from 'lodash-es';
import crypto from 'crypto';

export const avatarColors = ['#009379', '#F2BFAF', '#3366FF', '#F8D57E', '#BFAFF2', '#FF6250'];

export const getRandomColor = () => sample(avatarColors);
export const getColorForUser = (str) => {
  const hash = crypto.createHmac('sha256', str.toLowerCase()).digest('hex');
  const index = parseInt(hash, 16) % avatarColors.length;
  return avatarColors[index];
};

const getRGBA = (color) => {
  let r, g, b, a;
  r = g = b = a = 0;
  if (color) {
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    r = (rgb >> 16) & 0xff; // extract red
    g = (rgb >> 8) & 0xff; // extract green
    b = (rgb >> 0) & 0xff; // extract blue
  }
  return { r, g, b, a };
};

// `How to check if hex color is “too black”?` https://stackoverflow.com/a/12043228
export const isDark = (color) => {
  const { r, g, b } = getRGBA(color);
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma < 200;
};

// color SVG children paths.
export const colorPathChildren = (ref, color) => {
  const paths = ref?.getElementsByTagName('path');
  if (paths) {
    [...paths].forEach((p) => (p.style.fill = color));
  }
};
export const colorCircleChildren = (ref, color) => {
  const circles = ref?.getElementsByTagName('circle');
  if (circles) {
    [...circles].forEach((c) => (c.style.stroke = color));
  }
};
export const colorRectChildren = (ref, color) => {
  const rects = ref?.getElementsByTagName('rect');
  if (rects) {
    [...rects].forEach((r) => (r.style.fill = color));
  }
};

<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ content }}</div>
    <div class="actions">
      <an-button @click="archive" :isWorking="isWorking">Archive</an-button>
      <an-link @click="cancel">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { EventBus, toastError, toastSuccess } from '@/services/bus';
import api from '@/api';

export default {
  name: 'archive-project',
  data() {
    return {
      content:
        'Moving your project to the team archive will keep the screens but no one will be able to access the prototype and specs.',
      isWorking: false
    };
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    title() {
      const { name } = this.project;
      return `Archive project - ${name}`;
    }
  },
  methods: {
    ...mapActions({
      updateProject: 'projects/update'
    }),
    async archive() {
      const { teamSlug } = this.$route.params;
      const { id, name } = this.project;
      const payload = { is_archived: true };
      this.isWorking = true;

      this.$trackEvent('archive-project.archive.click');

      try {
        await this.updateProject({ id, payload });

        api.clearCacheOf(`${teamSlug}/projects`);

        EventBus.$emit('update-projects', { skipCache: true });
        EventBus.$emit('reload-team-info');

        this.$trackEvent('archive-project.archive.success');

        this.$emit('close', { redirect: { name: 'team' } });

        toastSuccess(`"${name}" was successfully Archived.`);
      } catch (err) {
        toastError(`Failed archiving "${name}"`);
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('archive-project.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>

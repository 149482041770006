export const CODE_PREFERENCES_MODULE = 'codePreferences';

export const HTML_FLEXBOX = 'flexbox';
export const HTML_ABSOLUTE = 'absolute';
export const LENGTH_PX = 'px';
export const LENGTH_VW = 'vw';
export const SYNTAX_FUNCTIONAL = 'functional';
export const SYNTAX_CLASS = 'class';
export const STYLE_CSS = 'css';
export const STYLE_SASS = 'sass';
export const STYLE_STYLED = 'styled';
export const HTML_FRAMEWORK = 'html';
export const HTML_AUTO_ANIMATE_DISABLED = 'auto-animate-disabled';
export const HTML_AUTO_ANIMATE_ALLOWED = 'auto-animate-allowed';
export const LANGUAGE_JAVASCRIPT = 'javascript';
export const LANGUAGE_TYPESCRIPT = 'typescript';
export const ENGINE_ATHENA = 'athena';
export const ENGINE_LEGACY = 'legacy';

export const FRAMEWORK_TO_LABEL = {
  vue: 'Vue',
  react: 'React',
  html: 'HTML'
};

export const STYLING_TO_LABEL = {
  [STYLE_CSS]: 'CSS',
  [STYLE_SASS]: 'SASS',
  [STYLE_STYLED]: 'Styled',
  [HTML_FLEXBOX]: 'Flex',
  [HTML_ABSOLUTE]: 'Absolute'
};

export const SYNTAX_TO_LABEL = {
  [SYNTAX_FUNCTIONAL]: 'Functions',
  [SYNTAX_CLASS]: 'Classes'
};

export const SET_DB_CODE_PREFERENCES = 'SET_DB_CODE_PREFERENCES';
export const SET_CODE_PREFERENCES = 'SET_CODE_PREFERENCES';
export const SET_CODEGEN_LANG = 'setCodegenLang';
export const SET_CODEGEN_HTML_LAYOUT = 'setCodegenHTMLLayout';
export const SET_CODEGEN_VUE_STYLE = 'setCodegenVueStyle';
export const SET_CODEGEN_REACT_STYLE = 'setCodegenReactStyle';
export const SET_CODEGEN_REACT_SYNTAX = 'setCodegenReactSyntax';
export const SET_CODEGEN_AUTO_ANIMATE_MODE = 'setCodegenAutoAnimateMode';
export const SET_CODEGEN_REACT_LANGUAGE = 'setCodegenReactLanguage';
export const SET_CODEGEN_ENGINE = 'setCodegenEngine';
export const SET_CODEGEN_LENGTH_UNIT = 'setCodegenLengthUnit';

export const SET_DB_CODE_PREFERENCES_ACTION = `${CODE_PREFERENCES_MODULE}/${SET_DB_CODE_PREFERENCES}`;
export const SET_CODE_PREFERENCES_ACTION = `${CODE_PREFERENCES_MODULE}/${SET_CODE_PREFERENCES}`;

<template>
  <div class="view slideInLeft">
    <div class="title-container">
      <div class="title">Comments</div>
      <div class="filter-dropdown">
        <v-tip placement="bottom-end" :trigger="'click'" offset="4">
          <div class="flex items-center">
            <span :style="{ textTransform: 'capitalize' }">{{ commentsFilter }}</span>
            <svg-icon :size="20" fill="currentColor" name="small-arrow-down"></svg-icon>
          </div>

          <template slot="popover">
            <div class="filter-pop">
              <div class="filter-items">
                <div
                  v-close-popover
                  :class="{ selected: commentsFilter === 'open' }"
                  @click="setCommentsFilter('open')"
                  class="filter"
                >
                  Open
                </div>
                <div
                  v-close-popover
                  :class="{ selected: commentsFilter === 'resolved' }"
                  @click="setCommentsFilter('resolved')"
                  class="filter"
                >
                  Resolved
                </div>
              </div>
            </div>
          </template>
        </v-tip>
      </div>
    </div>

    <div :style="{ 'padding-top': '60px', paddingBottom: selected ? '120px' : 0 }" class="body-wrapper">
      <template v-if="getCommentsByNode.length === 0">
        <EmptyState
          :src="require(`@/assets/illustrations/delivery-owl-dark.svg`)"
          v-if="commentsFilter === 'open'"
          title="No comments yet"
        >
          Click on any element to start a conversation
        </EmptyState>
        <EmptyState
          :src="require(`@/assets/illustrations/delivery-owl-dark.svg`)"
          v-if="commentsFilter === 'resolved'"
          title="You’re all caught up!"
        >
          There are no resolved comments yet.
        </EmptyState>
      </template>
      <virtual-list v-else class="vv-list" wclass="v-list" :size="133" :remain="getRemain" :bench="0">
        <div class="comments-list">
          <Comment
            @highlight="$emit('highlight', $event)"
            @resolve="$emit('resolve', $event)"
            @openReplies="
              $emit('change', {
                view: 'replies',
                comment
              })
            "
            v-for="comment in getCommentsByNode"
            :key="comment.id"
            :comment="comment"
            @mouseover="handleCommentMouseOver(comment)"
            @mouseout.native="handleCommentMouseOut"
          />
        </div>
      </virtual-list>
    </div>
    <!-- NEW COMMENT INPUT -->
    <div v-show="selected" class="new-comment-container">
      <div class="form-container">
        <an-textarea
          @enter="handleCreateComment"
          v-model="newCommentText"
          :className="`comment-textarea ${selected ? 'active' : ''}`"
          autoFocus
          rows="1"
          placeholder="Add your comment"
          ref="newCommentRef"
          data-cy="new-comment-input"
        />
        <div :class="{ active: isInputActive }" @click="handleCreateComment" class="icon-container">
          <svg-icon class="icon" name="send" :size="23" fill="currentColor"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Comment from '@/components/OmniView/Comments/Comment.vue';
import EmptyState from '@/components/OmniView/EmptyState.vue';
import { RESET_COMMENTS, SEND_MESSAGE, SIMULATE_MOUSEOUT, SIMULATE_MOUSEOVER } from '@/utils/events/omniviewEvents';
import { EventBus } from '@/services/bus';
import { uuid } from '@/utils/uuid';
import VirtualList from 'vue-virtual-scroll-list';
import errorHandler from '@/services/errorHandler';
import { updateArrayItemById } from '@/utils/javascript';

export default {
  name: 'comments',
  components: {
    Comment,
    EmptyState,
    VirtualList
  },
  data() {
    return {
      isWorking: false,
      newCommentText: '',
      isDeleting: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.newCommentRef && this.$refs.newCommentRef.updateAutosize();
    }, 200);

    EventBus.$on('focus-comment-input', this.focusInput);
  },
  destroyed() {
    EventBus.$off('focus-comment-input', this.focusInput);
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      commentsWithReplies: 'comments/commentsWithReplies',
      commentsFilter: 'comments/commentsFilter'
    }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),

    selected() {
      return !!this.currentNode.id;
    },
    getCommentsByNode() {
      let sorted = [...this.commentsWithReplies];
      // sort
      sorted.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      // filter
      let filter = this.commentsFilter.toUpperCase();

      // if (this.currentNode.id) {
      //   return filtered.filter(comment => comment.node_id == this.currentNode.id);
      // }
      return sorted.filter((c) => c.status === filter);
    },
    isInputActive() {
      return this.newCommentText !== '';
    },
    getRemain() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return Math.floor((h - (this.selected ? 243 : 120)) / 133);
      // return 0
    }
  },

  methods: {
    ...mapMutations({
      setCurrentNode: 'omniview/setCurrentNode',
      setComments: 'comments/setComments',
      setCommentsFilter: 'comments/setCommentsFilter'
    }),
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      createComment: 'comments/create'
    }),
    focusInput() {
      this.$nextTick(() => {
        this.$refs.newCommentRef.focus();
      });
    },
    clearSelection() {
      this.setCurrentNode({});
      EventBus.$emit(SEND_MESSAGE, {
        action: RESET_COMMENTS
      });
    },
    handleCommentMouseOver({ node_id }) {
      EventBus.$emit(SEND_MESSAGE, {
        action: SIMULATE_MOUSEOVER,
        data: {
          nodeId: node_id
        }
      });
    },
    handleCommentMouseOut() {
      EventBus.$emit(SEND_MESSAGE, {
        action: SIMULATE_MOUSEOUT
      });
    },
    async handleCreateComment() {
      const { projectId } = this.$route.params;

      if (!this.newCommentText) return;
      if (this.isWorking) return;
      try {
        const text = this.newCommentText;
        if (!text) return;
        this.isWorking = true;
        // optimistic create

        const created_at = new Date();
        created_at.setHours(created_at.getHours() - 1);
        const fakeNewComment = {
          id: uuid(),
          component: this.currentComponent.id,
          node_id: this.currentNode.id,
          text,
          color: '#fe7bfb',
          user: this.currentUser,
          status: 'OPEN',
          created_at
        };
        this.setComments([fakeNewComment, ...this.comments]);
        // RESET COMMENT FILTER OTHERWISE USER WONT SEE HIS NEW COMMENT
        this.setCommentsFilter('open');

        this.newCommentText = '';

        const payload = {
          text,
          color: '#fe7bfb',
          node_id: this.currentNode.id,
          component: {
            type: 'components',
            id: this.currentComponent.id
          }
        };
        // send create comment request
        const newComment = await this.createComment({
          parent: 'projects',
          id: projectId,
          payload: payload
        });
        const comments = updateArrayItemById(this.comments, fakeNewComment.id, newComment);
        this.setComments(comments);

        this.isWorking = false;
      } catch (error) {
        errorHandler.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../View.scss';

.title-container {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;
  // z-index: 10;
  overflow: hidden;
  .title {
    font-size: 16px;
    color: white;
  }

  &.sub {
    top: 60px;
    color: white;
    .title {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 20px;
    }
    .icon {
      margin-left: auto;
      flex-shrink: 0;
      opacity: 0.4;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

.body-wrapper {
  flex: 1 1 auto;
  padding-bottom: 120px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.comments-list {
  width: 100%;
  height: 100%;
}
.comment-form {
  flex-shrink: 0;
  padding: 16px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.comment-input {
  background-color: #2b2b2b !important;
  border: 1px solid #2b2b2b !important;
  // border-radius: 200px !important;
  &::placeholder {
    color: #d9d9d9;
  }
}
.select-banner {
  background: #2b2b2b;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  flex-direction: column;
  margin-top: 20px;
}
.banner-text {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
.new-comment-container {
  min-height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  border-top: 1px solid #2d2d2d;

  .form-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 16px;
    color: white;
    transition: background 0.2s;

    .icon {
      opacity: 0.3;
      left: -2px;
      position: relative;
      color: white;
    }

    &.active {
      cursor: pointer;
      background: var(--primary);
      color: white;
      .icon {
        opacity: 1;
      }
    }
  }
}

.filter-dropdown {
  color: white;
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}
.filter-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 16px;
}
.filter {
  color: white;
  cursor: pointer;
  // margin-bottom: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    color: var(--primary);
  }
  &.selected {
    color: var(--primary);
  }
}

.filter-pop {
  padding: 20px;
  background: #333333;
  max-width: 110px;
  color: white;
  border-radius: 10px;
  font-size: 14px;
}
</style>

<template>
  <div class="pricing-badge" :style="{ backgroundColor: color }">{{ name }}</div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'var(--green)'
    }
  }
};
</script>

<style lang="scss" scoped>
.pricing-badge {
  color: var(--white);
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
}
</style>

<template>
  <div
    :class="containerClasses"
    @click="$emit('click:body', item)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="notification-avatar">
      <an-user-avatar :user="item.actor" />
    </div>
    <div class="notification-content">
      <div class="header">
        <div class="name">{{ notificationTitle }}</div>
        <div class="time" v-if="hover">
          <div v-if="isUnread">
            <an-link @click.stop="$emit('mark-read', item)" variant="primary">Mark as read</an-link>
          </div>
          <div class="time" v-else>{{ timeAgo }}</div>
        </div>
        <div class="time" v-else>{{ timeAgo }}</div>
      </div>
      <div class="project" v-if="hasProjectData">
        <span v-if="isComment">on </span>
        <an-link variant="primary" @click.stop="$emit('click:project', item)">{{ item.project.name }}</an-link>
      </div>
      <div class="project" v-else-if="hasTeamData">
        <span v-if="isComment">on </span>
        <an-link variant="primary" @click.stop="$emit('click:team', item)">{{ item.team.name }}</an-link>
      </div>
      <div class="comment" v-html="content" />
      <div v-if="isAccessRequest && !item.action_completed" class="actions">
        <an-button variant="secondary" size="sm" @click="onClickManageAccess">Manage access</an-button>
        <an-button variant="primary" size="sm" @click="onClickApprove" :isWorking="isWorking"> Approve </an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash-es';
import { formatDateTimeConversational } from '@/utils/date';
import { TeamMembershipMixin } from '@/mixins';
import { toastError, toastSuccess } from '@/services/bus';
import errorHandler from '@/services/errorHandler';

export default {
  data() {
    return {
      hover: false,
      commentLengthLimit: 75,
      isWorking: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mixins: [TeamMembershipMixin],
  computed: {
    containerClasses() {
      return {
        notification: true,
        new: this.isUnread
      };
    },
    isComment() {
      return this.item.event_type === 'COMMENT';
    },
    isSync() {
      return this.item.event_type === 'SYNC';
    },
    isAccessRequest() {
      return this.item.event_type === 'ACCESS_REQUEST';
    },
    timeAgo() {
      return formatDateTimeConversational(this.item.created_at);
    },
    isRead() {
      const { status } = this.item;
      return status === 'READ';
    },
    isUnread() {
      const { status } = this.item;
      return status === 'UNREAD';
    },
    actorName() {
      return get(this.item, 'actor.name');
    },
    teamName() {
      if (this.hasTeamData) return get(this.item, 'team.name');
      return null;
    },
    actorEmail() {
      return get(this.item, 'actor.email');
    },
    notificationTitle() {
      if (this.isAccessRequest) {
        return this.item?.action_completed ? 'New contributor added' : 'Access request';
      }
      return this.actorName;
    },
    hasProjectData() {
      return !isEmpty(this.item?.project?.name);
    },
    hasTeamData() {
      if (!this.item?.team) return false;
      const { slug, name } = this.item?.team;
      return !!(slug && name);
    },
    content() {
      const { commentLengthLimit } = this;
      let content;
      if (this.isComment) {
        content = this.item.content;
        if (content && content.length > commentLengthLimit) {
          return content.slice(0, commentLengthLimit) + '...';
        }
      } else if (this.isAccessRequest) {
        const { actorName, actorEmail, item } = this;
        const { team, action_completed } = item;
        if (action_completed) {
          content = `${actorName} <b>(${actorEmail})</b> has joined <b>${team.name}</b> as contributor.`;
        } else {
          content = `${actorName} <b>(${actorEmail})</b> has requested contributor access to team ${team.name}:`;
        }
      } else {
        content = this.item.text;
      }
      return content;
    }
  },
  methods: {
    onClickManageAccess() {
      this.$trackEvent('notifications.manage-access.click');
      this.$router.push({
        name: 'team-members',
        params: { teamSlug: this.item.team.slug }
      });
    },
    async onClickApprove() {
      this.$trackEvent('notifications.approve-request-access.click');
      const { team, actor } = this.item;
      try {
        this.isWorking = true;
        await this.approveContributorAccess({ teamSlug: team.slug, email: actor.email });
        this.$emit('mark-completed', this.item);

        this.$trackEvent('notifications.approve-request-access.success', { teamSlug: team.slug });

        const teamName = this.teamName || 'the team';
        const successMessage = `Yay! <b>${this.actorName}</b> is now a contributor in ${teamName}`;
        toastSuccess(successMessage);
      } catch (err) {
        errorHandler.captureMessageAndTrack(err, {
          name: 'notifications.approve-request-access.failure',
          data: { message: err?.message, teamSlug: team.slug, email: actor.email }
        });
        const status = err?.response?.status;
        let errorMessage;
        switch (status) {
          case 403:
            errorMessage = 'You do not have permission to approve this request.';
            break;
          case 404:
            errorMessage = `${actor.email} is not a team member in ${team.name}`;
            break;
          case 409:
            errorMessage = `${actor.email} is already a contributor in ${team.name}`;
            break;
          default:
            errorMessage = 'Something went wrong.';
        }
        toastError(errorMessage);
      } finally {
        this.isWorking = false;
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 24px 30px;
  cursor: pointer;
  transition: background 0.5s ease;
  &:hover {
    background-color: var(--light-container-background);
  }
  .notification-avatar {
    margin-right: 20px;
  }
  .notification-content {
    font-size: 14px;
    width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      .name {
        font-weight: bold;
        @include ellipsis;
      }
      .time {
        color: var(--secondary-text);
        font-size: 12px;
      }
    }
    .project {
      font-size: 12px;
      margin-bottom: 6px;
    }
    .actions {
      margin-top: 16px;
      display: flex;
      align-items: center;
      > * + * {
        margin-left: 10px;
      }
    }
  }
  + .notification {
    border-top: var(--border);
  }
}
</style>

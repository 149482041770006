<template>
  <UploadImage :url="uploadUrl" :src="team.logo_url" @success="$emit('success')" :width="size" :height="size" />
</template>

<script>
import UploadImage from '@/components/Upload/UploadImage';
import { getCdnUrl } from '@/utils/urls';

export default {
  props: {
    team: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 50
    }
  },
  components: {
    UploadImage
  },
  computed: {
    uploadUrl() {
      return `/v2/uploads/teams/${this.team.id}/logo_url`;
    },
    src() {
      const { team, size } = this;
      return getCdnUrl(team.logo_url, { size });
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="title">Move project</div>
    <div class="body">
      <div class="label">Current team</div>
      <div class="teams-list">
        <div
          :class="['team-row-container', 'selectable', 'current-team', { selected: isSelected(currentTeam) }]"
          @click="selectedTeam = currentTeam"
        >
          <TeamRow :team="currentTeam" :key="currentTeam.id" :members="teamMembers" />
        </div>
      </div>

      <div class="label">Move to</div>
      <div class="teams-list">
        <div
          v-for="(t, index) in teams"
          :key="index"
          :class="['team-row-container', 'selectable', { selected: isSelected(t) }]"
          @click="selectedTeam = t"
        >
          <TeamRow :team="t" :members="t.members" :key="t.id" />
        </div>
      </div>

      <div v-if="showActions && selectedTeam" class="check-box-move-team">
        <Checkbox v-model="agree">
          I understand that all my current team members will lose access to this project and it’ll be fully accessible
          to {{ selectedTeam.name }} team members.
        </Checkbox>
      </div>
      <div class="actions" v-if="showActions && agree">
        <an-button @click="moveProject" :isWorking="isWorking">Move project</an-button>
        <an-link @click="cancel">Cancel</an-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TeamRow from '@/components/Team/TeamRow';
import Checkbox from '@/components/Checkbox/Checkbox';
import { toastError, toastSuccess } from '@/services/bus';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'move-project',
  data() {
    return {
      userMemberships: [],
      selectedTeam: null,
      agree: false,
      isWorking: false
    };
  },
  components: {
    TeamRow,
    Checkbox
  },
  computed: {
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('teams', { currentTeam: 'currentItem' }),
    ...mapState('teamMemberships', { teamMembers: 'team' }),
    ...mapGetters('teamMemberships', { userTeamMemberships: 'userTeams' }),
    isSelected() {
      return ({ id } = {}) => this.selectedTeam?.id === id;
    },
    teams() {
      const { id: currentTeamId } = this.currentTeam;
      return this.userMemberships.filter((tm) => tm.id !== currentTeamId);
    },
    showActions() {
      return this.selectedTeam?.id !== this.currentTeam?.id;
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions({
      updateProject: 'projects/update'
    }),
    reset() {
      this.selectedTeam = this.currentTeam;
      this.userMemberships = cloneDeep(this.userTeamMemberships || []);
    },
    async moveProject() {
      const { selectedTeam, currentProject } = this;
      try {
        const payload = { team: { id: selectedTeam.id, type: 'teams' } };
        this.isWorking = true;

        this.$trackEvent('move-project.move-project-button.click');
        await this.updateProject({ id: currentProject.id, payload });

        this.$trackEvent('move-project.move-project-button.success');

        this.$router.push({ name: 'team', params: { teamSlug: selectedTeam.slug } });

        this.$emit('close');
        toastSuccess(`${currentProject.name} was successfully moved.`);
      } catch (err) {
        this.$trackEvent('move-project.move-project-button.failure', { message: err.message });
        toastError('Sorry, we failed moving this project to another team.');
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('move-project.cancel.click');
    }
  },
  watch: {
    currentTeam() {
      this.reset();
    },
    userTeamMemberships() {
      this.reset();
    },
    selectedTeam({ id } = {}) {
      if (id === this.currentTeam.id) {
        this.agree = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container,
.body {
  max-width: 750px;
}
.teams-list {
  margin: 20px 0 30px;
  max-height: 350px;
  overflow-y: auto;
}
.selectable {
  border-top: var(--border);
  padding: 30px 20px;
  cursor: pointer;
  &:last-child {
    border-bottom: var(--border);
  }
  &.current-team {
    border-top: none;
    border-bottom: none;
  }
}
.selectable:hover,
.selected {
  border-top: 1px solid transparent;
  background-color: var(--light-container-background);
  border-radius: 10px;
  + .selectable {
    border-top: 1px solid transparent;
  }
}
.check-box-move-team {
  margin-top: 30px;
}
</style>

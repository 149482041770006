<template>
  <div class="container">
    <div class="illustration">
      <svg-icon name="storybook-beta" :width="128" :height="40" />
    </div>
    <div class="text">
      {{ text }}
      <an-link :href="url" target="_blank" variant="info" @click="onClick">{{ ctaText }}</an-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'storybook-beta-banner',
  data: () => ({
    text: 'Connect your Storybook to Figma & Xd.',
    ctaText: 'Sign up now!',
    url: 'https://anima-storybook.typeform.com/to/Vkt8zmCd?source=anima-web&banner=top-bar-banner'
  }),
  methods: {
    onClick() {
      this.$trackEvent('storybook-beta-banner.cta.click');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .illustration {
    margin-right: 30px;
  }
}
</style>

<template>
  <div class="announcement-container">
    <div class="announcement-header">
      <div class="date">{{ date }}</div>
      <div class="tag">{{ tag }}</div>
    </div>
    <div class="announcement-body">
      <div class="title">{{ item.title }}</div>
      <div class="image" v-if="item.image_url">
        <img :src="item.image_url" />
      </div>
      <div class="content">{{ item.content }}</div>
      <div class="cta" v-if="item.cta && item.cta_url">
        <an-button @click="onClick" variant="secondary">{{ item.cta }}</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    date() {
      const { created_at: createdAt } = this.item;
      return formatDate(createdAt, 'MMMM DD, YYYY');
    },
    tag() {
      const { is_published_as_draft: isDraft, tag } = this.item;
      return isDraft ? 'DRAFT (admins only)' : tag;
    }
  },
  methods: {
    onClick() {
      const { item } = this;
      this.$trackEvent('announcements.item.click', { title: item.title });
      if (item.cta_url?.startsWith('/')) {
        this.$router.push(item.cta_url);
      } else {
        window.open(item.cta_url, '_blank');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.announcement-container {
  padding: 30px;
  + .announcement-container {
    border-top: var(--border);
  }
}
.announcement-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  font-size: 14px;
  line-height: 24px;
  .date {
    opacity: 0.4;
  }
  .tag {
    color: var(--primary);
    font-weight: 600;
  }
}
.announcement-body {
  .title,
  .content {
    margin-top: 12px;
  }
  .title {
    font-weight: 700;
  }
  .image {
    margin: 30px 0;
    img {
      border-radius: 16px;
      width: 100%;
    }
  }
  .cta {
    margin-top: 30px;
  }
}
</style>

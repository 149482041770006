<template>
  <article class="column">
    <h3 class="title">{{ addon.displayName }}</h3>
    <div class="divider" />

    <h2 class="price">
      {{ formattedPrice }}
      <span class="per-amount">/ {{ formattedInterval }}</span>
    </h2>
    <ul class="plan-entitlements">
      <li v-for="entitlement in addon.entitlements" :key="entitlement.id">
        <span v-if="entitlement.hasUnilimitedUsage">Unlimited {{ entitlement.feature.displayName }}</span>
        <span v-else>{{ entitlement.usageLimit }} {{ entitlement.feature.displayName }}</span>
      </li>
    </ul>
    <an-button class="cta" variant="primary" @click="onClick">Add to my plan</an-button>
  </article>
</template>

<script>
import { formatPrice } from '@/utils/billing';
import { formatAddonPrice } from '@/utils/formatAddonPrice';
import { BillingPeriod } from '@stigg/js-client-sdk';

export default {
  name: 'PricingColumn',
  props: {
    addon: {
      required: true
    },
    currentSubscription: {
      required: true
    },
    interval: {
      required: true
    },
    countryCode: {
      required: true
    }
  },
  computed: {
    hasPrice() {
      return this.price > 0;
    },
    formattedInterval() {
      return this.interval === BillingPeriod.Monthly ? 'per month' : 'per year';
    },
    formattedPrice() {
      const addonPrices = this.addon.pricePoints;
      const countryCode = this.countryCode.toLowerCase();

      const pricePoint = formatAddonPrice(addonPrices, countryCode, this.interval);

      return formatPrice(pricePoint.amount, { currency: pricePoint.currency });
    }
  },
  methods: {
    onClick() {
      this.$emit('select-addon', this.addon);
    }
  },
  mounted() {
    if (this.screenPriceOptions && this.screenPriceOptions.length > 0) {
      const selected = this.screenPriceOptions.find((option) => option.value === this.screenPriceOptionSelected || '5');
      this.onSelectScreens(selected, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px;
  width: 350px;
  max-width: 400px;
  flex: 1 1 30%;
  margin: 20px;
  border: var(--border);
  border-radius: 15px;
  text-align: center;
  transition: all 0.2s ease;
  cursor: default;

  &.dark {
    background-color: var(--secondary);
    border: none;
  }

  &.current-plan:not(.has-screen-options) {
    opacity: 0.3;
  }

  &:hover {
    border: var(--primary-border);

    &.dark {
      border: none;
    }
  }
}

.title {
  @include secondary-title;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 5px;
}

.trial-subtitle {
  @include secondary-title;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}

.hide {
  opacity: 0;
}

.price {
  @include secondary-title;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  font-size: 40px;
  font-weight: 700;
  line-height: 80px;
  height: 70px;

  .small {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    opacity: 0.6;
  }
}

.trial-disclaimer {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
}

.divider {
  margin: 20px 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    border-top: 1px solid var(--divider-color);
  }
}

.description {
  height: 3em;
}

.price-details {
  @include body-text;
  font-family: 'Mulish';
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  opacity: 0.6;
}

.pricing-screens {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 0.5em;
}

.cta {
  margin: 30px 0;
}

.features-carry {
  color: var(--secondary-text);
  margin-top: 20px;
  height: 32px;
}

.features-list {
  text-align: left;
  margin-top: 40px;
  width: 100%;
}

.quirky-scratch-container {
  @include quirky-text;
  margin: 20px 0 0 36px;

  &.invisible {
    visibility: hidden;
  }

  .scratch {
    height: 2px;
    border-radius: 100px;
    background: var(--primary);
    width: 34px;
    transform: rotate(-2deg);
    margin-top: 15px;
    margin-bottom: -19px;
  }
}

.features-list-item {
  display: flex;
  align-items: center;
  padding: 4px 0;

  .svg-container {
    margin-right: 14px;
    flex-shrink: 0;
  }

  + .features-list-item {
    margin-top: 20px;
  }

  &.inactive {
    opacity: 0.3;
  }
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
}

.see-all-features-link {
  margin-top: 20px;
}

.new-badge {
  width: 34px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
  line-height: 22px;
  color: white;
  background-color: var(--green);
  margin-left: 10px;
}

.per-amount {
  font-size: 14px;
}
</style>

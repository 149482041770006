<template>
  <div class="container">
    <div class="illustration"><img src="@/assets/illustrations/time-lamp-desktop.svg" /></div>
    <div class="title">Switch to desktop</div>
    <div class="subtitle">
      <p>Welcome aboard {{ firstName }}!</p>
      <p>It's time to create your first project. You'll need a desktop for this part.</p>
      <p>Head to your inbox on your desktop, we’ve sent you an email with instructions.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'switch-to-desktop',
  computed: mapGetters({ firstName: 'users/firstName' }),
  mounted() {
    this.$intercom.update({ hide_default_launcher: true });
  },
  watch: {
    isDesktop(val) {
      if (val) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.subtitle {
  > p + p {
    margin-top: 30px;
  }
}
.illustration {
  width: 335px;
  height: 120px;
}
</style>

<template>
  <transition :name="transitionName">
    <div :class="['sidebar-container', side, theme]" :style="containerStyles" v-if="isOpen">
      <slot @close="$emit('close')" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    side: {
      type: String,
      default: 'left',
      validator: (v) => ['left', 'right'].includes(v)
    },
    backgroundColor: {
      type: String,
      default: '#3b3b3b'
    },
    theme: {
      type: String,
      default: 'dark',
      validator: (v) => ['dark', 'light'].includes(v)
    },
    width: {
      type: [Number, String],
      default: 280
    },
    height: {
      type: [Number, String],
      default: '100vh'
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    containerStyles() {
      let { backgroundColor, width, height } = this;
      width = typeof width === 'number' ? `${width}px` : width;
      height = typeof height === 'number' ? `${height}px` : height;
      return {
        backgroundColor,
        width,
        height
      };
    },
    transitionName() {
      return `slide-sidebar-${this.side}`;
    }
  },
  watch: {
    isOpen(newState) {
      if (this.isMobile) {
        if (newState) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'visible';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  display: flex;
  height: 100vh;
  z-index: 1000;
  transition: all 0.3s ease;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 0;
}
.slide-sidebar-leave-active,
.slide-sidebar-enter-active {
  transition: 0.2s;
}
.slide-sidebar-right-enter,
.slide-sidebar-right-leave-to {
  transform: translateX(100%);
}
.slide-sidebar-left-enter,
.slide-sidebar-left-leave-to {
  transform: translateX(-100%);
}
</style>

<template>
  <div class="sidebar-container">
    <div class="logo-section" v-if="isDesktop">
      <router-link to="/">
        <an-logo />
      </router-link>
    </div>

    <div v-if="isMobile" style="width: 100%">
      <div @click="closeOnMobile">
        <svg-icon name="close" :size="30" fill="white" />
      </div>
      <div class="user-details-row">
        <div>
          <an-user-avatar :user="user" :size="60" />
        </div>
        <div>
          <div class="user-name">{{ user.name }}</div>
          <div class="user-email">{{ user.email }}</div>
        </div>
      </div>
    </div>

    <div class="section-first">
      <div class="link-label" @click="navigate('shared')" v-if="showSharedProjects">Shared with me</div>
    </div>

    <div class="title teams">
      <span>Teams</span>
      <an-tooltip text="New team" position="top-left" :offset="5" v-if="isDesktop">
        <div class="add-team" @click="newTeam" data-cy="new-team-button">
          <svg-icon name="plus" />
        </div>
      </an-tooltip>
    </div>

    <div class="section">
      <div v-for="team in userTeams" :key="team.id" class="team-link" data-cy="team-link" @click="navigateToTeam(team)">
        <div :class="teamLinkClasses(team)">
          {{ team.name }}
        </div>
        <div class="plan" v-if="shouldShowPlan">
          {{ fixPlanName(team) }}
        </div>
      </div>
    </div>

    <div class="title" v-if="isDesktop">
      <span>Discover Anima</span>
    </div>

    <div class="section last">
      <div class="link-label" @click="navigate('plugins')" v-if="isDesktop">Plugins</div>
      <div class="link-label" @click="openOnboardingPanel" v-if="showOnboardingLink">
        {{ viewOnboardingPanelText }}
      </div>
      <div class="link-label" v-if="isDesktop">
        <a :href="helpCenterUrl" target="_blank" @click="trackNavigation('Help Center')"> Help Center </a>
      </div>
      <div class="link-label" @click="logout" v-if="isMobile">Logout</div>
      <div class="link-label" v-show="false">Showcase</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { EventBus } from '@/services/bus';
import { UserMixin } from '@/mixins';
import auth from '@/auth';
import errorHandler from '@/services/errorHandler';
import { readablePlanName } from '@/utils/billing';

export default {
  data() {
    return {
      anima101Url: 'https://www.animaapp.com/resources/anima-101',
      helpCenterUrl: 'https://support.animaapp.com/',
      showSharedProjects: !!localStorage.getItem('hasSharedProjects'),
      showBanner: true,
      searchQuery: ''
    };
  },
  mounted() {
    this.fetchData();
    EventBus.$on('reload-user-memberships', this.fetchData);
  },
  destroyed() {
    EventBus.$off('reload-user-memberships', this.fetchData);
  },
  mixins: [UserMixin],
  computed: {
    shouldShowPlan() {
      return !process.env.ORGANIZATION_NAME;
    },
    ...mapState('projects', ['sharedProjects']),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { userTeamMemberships: 'user' }),
    ...mapState('userOnboardings', { userOnboarding: 'currentItem' }),
    ...mapGetters('teamMemberships', ['userTeams']),
    ...mapGetters({ isActiveExperiment: 'experiments/isActive' }),
    showOnboardingLink() {
      return this.viewOnboardingPanelText && this.isDesktop && this.userOnboarding.status === 'done';
    },
    viewOnboardingPanelText() {
      return this.userOnboarding?.onboarding_flow?.short_title;
    }
  },
  methods: {
    ...mapActions({
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchSharedProjects: 'projects/fetchSharedProjects'
    }),
    ...mapMutations({
      selectTeam: 'teams/setCurrentItem',
      setUserMemberships: 'teamMemberships/setUserMemberships',
      setIsOnboardingOpen: 'webappSystem/setIsPersonalizedOnboardingOpen'
    }),
    async fetchData() {
      try {
        this.fetchSharedProjectsData();
        await this.fetchAllUserMemberships({ id: 'me', skipCache: true });
        this.checkForDefaultTeam();
        this.fetchExtraTeamMemberships();
      } catch (error) {
        errorHandler.captureException(error);
      }
    },
    async fetchSharedProjectsData() {
      await this.fetchSharedProjects();
      this.showSharedProjects = !!this.sharedProjects?.length;
      localStorage.setItem('hasSharedProjects', this.showSharedProjects);
    },
    newTeam() {
      this.$trackEvent('sidebar.new-team.click');
      this.$router.push({ name: 'new-team' });
    },
    fixPlanName(team) {
      if (team.uses_stigg_integration) {
        return team.plan;
      } else {
        return readablePlanName(team.plan) || 'Free';
      }
    },
    openOnboardingPanel() {
      this.setIsOnboardingOpen(true);
      this.$trackEvent('sidebar.onboarding-hint.click');
    },
    async fetchExtraTeamMemberships() {
      try {
        // fetch the first 5 team members of the user's teams.
        const promises = this.userTeamMemberships?.map(async (tm) => {
          try {
            const { results, total: totalMembers } = await this.fetchTeamMemberships({
              id: tm.team,
              params: { page_size: 5 },
              saveResults: false
            });
            return { ...tm, members: results, membersLength: results?.length, totalMembers };
          } catch (err) {
            return tm;
          }
        });
        if (promises) {
          const results = await Promise.all(promises);
          this.setUserMemberships({ results });
          this.trackUserTeamsData();
        }
      } catch (error) {
        errorHandler.captureException(error);
      }
    },
    teamLinkClasses(team) {
      const { teamSlug } = this.$route.params;
      return {
        'link-label': true,
        selected: team?.slug === teamSlug
      };
    },
    navigateToTeam(team) {
      this.selectTeam(team);
      this.closeOnMobile();
      this.navigate('team', { teamSlug: team.slug });
    },
    navigate(pageName, params = {}) {
      this.trackNavigation(pageName, params);
      this.closeOnMobile();
      this.$router.push({ name: pageName, params }, () => {});
    },
    trackNavigation(pageName, params = {}) {
      this.$trackEvent('webapp.sidebar.navigation', { pageName, ...params });
    },
    logout() {
      this.$trackEvent('sidebar.logout.click');
      auth.logout();
      window.location.reload();
    },
    search() {
      this.$trackEvent('sidebar.help-center.search');
      window.open(`${this.helpCenterUrl}?q=${this.searchQuery}`, '_blank');
    },
    closeOnMobile() {
      if (this.isMobile) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  line-height: var(--sidebar-line-height);
  padding: 20px 30px;
  height: 100%;
  width: 100%;
  color: #fff;

  @include mobile {
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}

.title {
  width: 100%;
  margin: 50px 0 0;
  color: rgba(255, 255, 255, 0.4);
  cursor: default;

  @include mobile {
    margin-top: 0;
  }

  &.teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 30px;

    @include desktop {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    @include mobile {
      padding: 0;
    }

    .add-team {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #ffffff;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.user {
  &-details-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 40px 0 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    > * + * {
      margin-left: 16px;
    }
  }

  &-name {
    @include headline;
    @include ellipsis;
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
  }

  &-email {
    @include ellipsis;
    color: #ffffff;
    opacity: 0.4;
  }
}

.logo-section {
  display: flex;

  @include mobile {
    height: 30px;
  }

  .logo {
    @include desktop {
      width: 85px;
      height: 25px;
    }
  }

  > * + * {
    margin-left: 20px;
  }
}

.section {
  &-first {
    margin: 50px 0 0;
    padding: 0;
  }

  width: 100%;
  padding: 30px 0 10px;

  &.last {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 30px;

    > * + * {
      margin-top: 24px;
    }

    @include mobile {
      margin-bottom: 80px;
    }
  }
}

.team-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-link + .team-link,
.link-label + .link-label {
  margin-top: 20px;
}

.link-label {
  @include ellipsis;
  width: 170px;
  cursor: pointer;

  &.selected {
    font-weight: bold;
  }
}

.selected {
  font-weight: 500;
}

.plan {
  max-width: 60px;
  cursor: pointer;
  text-align: right;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
}

.anima-5-promo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.section__search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .section__search-icon {
    position: absolute;
    right: 0;
    cursor: pointer;

    &::v-deep path {
      fill: #ffffff;
    }
  }

  .section__search-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .section__search-input {
    width: 100%;
    height: 40px;
    border-bottom: var(--border);
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background-color: transparent;
    outline: none;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}
</style>

import { createStoreModule } from '../utils';
import { getFeatureTogglesFromUrl, getOverridesFromURL, getTimeZoneExperiments } from '@/services/experiments';
import errorHandler from '@/services/errorHandler';
import { isEmpty } from 'lodash-es';

export default createStoreModule({
  name: 'experiments',
  crud: false,
  state: {
    userExperiments: {},
    timeZoneExperiments: {},
    overrides: {},
    featureToggles: {},
    isExperimentsDoneLoading: false
  },
  getters: {
    experiments: (s, _, rootState) => {
      let experiments = s.userExperiments;
      if (isEmpty(experiments)) {
        experiments = rootState.users?.currentItem?.experiments || {};
      }
      const toggles = s.featureToggles || {};
      const overrides = s.overrides || {};
      return { ...s.timeZoneExperiments, ...experiments, ...overrides, ...toggles };
    },
    isActive: (_, getters) => (experimentName, variant) => {
      const allExperiments = getters.experiments;
      const value = allExperiments[experimentName];
      if (variant) return variant === value;
      return value;
    },
    isTrialActive: (_, getters) => getters.isActive('trial-ft'),
    isCustomDomainButtonActive: (_, getters) => getters.isActive('custom-domain-button'),
    isDevicesLimitActive: (_, getters) => getters.isActive('devices-limit'),
    isPrototypeOnboardingActive: (_, getters) => getters.isActive('prototype-onboarding'),
    isCustomPlanNewActive: (_, getters) => getters.isActive('custom-plan'),
    isDesignFileActive: (_, getters) => getters.isActive('design-file'),
    isUpgradePlanActive: (_, getters) => getters.isActive('upgrade-plan-modal'),
    isPricingScreenFlowActive: (_, _getters, _rootState, rootGetters) => {
      return (
        rootGetters['teamMemberships/screensLimit'] ||
        localStorage.getItem('pricingScreenFlowActive', 'false') === 'true'
      );
    },

    isPricingExperimentScreenActive: (_, getters) => getters.isActive('tz.show.pricing-screen-based-flow'),
    isFullProjectPlaygroundActive: (_, getters, _rootState, rootGetters) =>
      getters.isActive('playground-full-project') || rootGetters['users/isAnimaUser']
  },
  mutations: {
    setUserExperiments: (state, data) => (state.userExperiments = data),
    setFeatureToggles: (state, data) => (state.featureToggles = data),
    setTimeZoneExperiments: (state, data) => (state.timeZoneExperiments = data),
    setOverrides: (state, data) => (state.overrides = data),
    addOverrides: (state, data) => (state.overrides = { ...state.overrides, ...data }),
    addFeatureToggles: (state, data) => (state.featureToggles = { ...state.overrides, ...data })
  },
  actions: {
    populateExperiments({ commit, dispatch, rootState }) {
      const currentUser = rootState.users.currentItem ?? {};
      try {
        commit('setUserExperiments', currentUser.experiments || {});
        dispatch('setOverridesFromUrl');
      } catch (err) {
        errorHandler.captureException(err);
      }
    },
    setOverridesFromUrl({ commit }) {
      const overrides = getOverridesFromURL();
      const toggles = getFeatureTogglesFromUrl();
      commit('addOverrides', overrides);
      commit('addFeatureToggles', toggles);
    },
    async fetchTimeZoneExperiments({ commit }) {
      try {
        const resp = await getTimeZoneExperiments();
        const experiments = {};
        Object.entries(resp.data || {}).map(([feature, enabled]) => (experiments[feature] = enabled === 'TRUE'));
        commit('setTimeZoneExperiments', experiments);
      } catch (e) {
        console.error('Issue trying to retrieve timezone experiments');
      }
    }
  }
});

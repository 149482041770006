<template>
  <div>
    <span v-html="content" />
    <span class="action" v-if="ctaText">
      <an-link variant="primary" @click="onClickCta">{{ ctaText }}</an-link>
    </span>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
export default {
  name: 'notification-toast',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    content() {
      switch (this.notification.event_type) {
        case 'ACCESS_REQUEST':
          return this.getAccessRequestContent();
        case 'ROLE_APPROVED':
          return this.getRoleApprovedContent();
        default:
          return null;
      }
    },
    ctaText() {
      switch (this.notification.event_type) {
        case 'ACCESS_REQUEST':
          return 'Review request';
        default:
          return null;
      }
    }
  },
  methods: {
    onClickCta() {
      EventBus.$emit('open-notifications-panel');
      this.$emit('close');
    },
    getAccessRequestContent() {
      const { actor, team } = this.notification;
      return `<b>${actor.name}</b> has requested access to ${team.name}.`;
    },
    getRoleApprovedContent() {
      const { team } = this.notification;
      return `You're now a contributor on <b>${team.name}</b>!`;
    }
  }
};
</script>

<style scoped>
.action {
  margin-left: 8px;
}
</style>

<template>
  <div>
    <div v-if="notFound">
      <PageNotFound :showTopBar="false" resource="team" />
    </div>
    <div v-else>
      <div class="team-header-container">
        <TeamHeader @click-new-project="newProject" />
      </div>
      <div class="navigation">
        <div class="flex items-center">
          <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
          <div class="search" v-if="isDesktop && showSearch">
            <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
          </div>
        </div>
        <div class="sorting-section" v-if="isDesktop && showSort">
          <div ref="grid" class="clickable-icon" @click="onChangeLayoutClick('grid')">
            <svg-icon name="grid" :size="30" />
          </div>
          <div ref="horizontal" class="clickable-icon" @click="onChangeLayoutClick('horizontal-list')">
            <svg-icon name="horizontal-list" :size="30" />
          </div>
          <PopoverMenu :items="sortingOptions" position="right" :selectedLabel="sortByLabel">
            <div slot="reference" class="sort-select">
              {{ sortByLabel }}
              <svg-icon name="select-arrow-down" :size="24" />
            </div>
          </PopoverMenu>
        </div>
      </div>
      <div class="sort-mobile">
        <div class="search" v-if="showSearch">
          <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
        </div>
        <PopoverMenu
          v-if="showSort && !searchBarOpen"
          :items="sortingOptions"
          position="right"
          :selectedLabel="sortByLabel"
        >
          <div slot="reference" class="sort-select">
            {{ sortByLabel }}
            <svg-icon name="select-arrow-down" :size="24" />
          </div>
        </PopoverMenu>
      </div>
      <div class="content">
        <transition name="fadeIn" mode="out-in">
          <router-view
            :shouldUpdate="shouldUpdateProjects"
            :searchQuery="searchQuery"
            :layout="layout"
            :key="activeTabLabel"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Tabs from '@/components/Layout/Tabs/Tabs';
import TeamHeader from '@/components/Team/Header';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import SearchInput from '@/components/SearchInput/SearchInput';
import PageNotFound from '@/views/PageNotFound';
import { nextTick } from '@/utils/sugar';
import { EventBus, openModal, toastError, toastSuccess, showIntercomBubble } from '@/services/bus';
import { colorPathChildren, colorRectChildren } from '@/utils/colors';
import { SocketMixin, OnboardingDemoMixin } from '@/mixins';
import {
  hideUpdatePaymentBanner,
  hideUpgradeTeamBanner,
  showUpdatePaymentBanner,
  showUpgradeTeamBanner
} from '@/services/banners';
import { isDev, isCanary } from '@/utils/environment';
import { isRecognizedEmailDomain } from '@/utils/email';

const defaultTabLabel = 'All projects';

export default {
  data() {
    return {
      sortingOptions: [
        { label: 'Last updated', value: 'updated', onClick: () => this.setSortBy('updated') },
        { label: 'Date created', value: 'created', onClick: () => this.setSortBy('created') },
        { label: 'Name A-Z', value: 'name', onClick: () => this.setSortBy('name') }
      ],
      sortBy: 'updated',
      activeTabLabel: defaultTabLabel,
      shouldUpdateProjects: false,
      searchQuery: '',
      searchBarOpen: false,
      layout: localStorage.getItem('teamPageLayout'),
      loading: false,
      notFound: false
    };
  },
  components: {
    TeamHeader,
    Tabs,
    PopoverMenu,
    SearchInput,
    PageNotFound
  },
  created() {
    if (this.user.id) {
      if (this.$hj) {
        this.$hj('identify', this.user.id, {
          email: this.user.email
        });
      }
      if (this.$FullStory) {
        this.$FullStory.identify(this.user.id, {
          email: this.user.email
        });
      }
    }
  },
  mixins: [SocketMixin, OnboardingDemoMixin],
  mounted() {
    EventBus.$on('reload-team-info', this.triggerReloadTeam);
    // eslint-disable-next-line no-constant-condition
    if (false && localStorage.getItem('showedDemoBubble') && !isRecognizedEmailDomain(this.user.email)) {
      localStorage.setItem('showedDemoBubble', true);
      this.$trackEvent('bubble.suggest-demo.show');
      showIntercomBubble({
        props: {
          message: `Hi there! 👋
                Are you looking for a custom solution? We can help you with that!
                <button style="color: white; background-color: var(--primary); padding: 0.5rem 0.75rem; border-radius: 100px; margin-top: 1rem;">Book a Sales Call</button>
              `,
          onClick: () => {
            this.$trackEvent('bubble.suggest-demo.click');
            window.open('https://calendly.com/d/g7h-f4p-3jw?source=anima-web&banner=chat', '_blank');
          },
          hideTimeout: 20 * 1000 // 20 seconds
        }
      });
    }
  },
  destroyed() {
    EventBus.$off('reload-team-info', this.triggerReloadTeam);
    this.toggleUpgradeBanner(false);
    hideUpgradeTeamBanner();
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('projects', { projects: 'items' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapGetters('stripeSubscriptions', ['activeSubscription']),
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      paymentStatus: 'stigg/paymentStatus'
    }),
    sortByLabel() {
      const { sortBy, sortingOptions } = this;
      const option = sortingOptions.find((so) => so.value === sortBy);
      return option.label;
    },
    showSearch() {
      return !this.$route.name?.includes('team-settings') && this.projects.length;
    },
    showSort() {
      return !this.$route.name?.includes('team-settings') && this.projects.length;
    },
    tabs() {
      const items = [
        { label: 'All projects', routeName: 'team', dataCy: 'all-projects-tab' },
        { label: 'Archive', routeName: 'team-archive', dataCy: 'archive-tab' },
        { label: 'Settings', routeName: 'team-settings', dataCy: 'settings-tab' }
      ];
      if (this.isMobile) {
        items.pop();
      }
      return items;
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      acceptInvite: 'teamInvitations/acceptInvite',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchStripeSubscriptions: 'stripeSubscriptions/fetchAllOfParent',
      fetchPaypalSubscriptions: 'paypalSubscriptions/fetchAllOfParent',
      fetchNotificationsSettings: 'notificationsSettings/fetchAllOfParent'
    }),
    ...mapMutations({
      setTeam: 'teams/setCurrentItem'
    }),
    triggerReloadTeam({ skipCache = true } = {}) {
      this.shouldUpdateProjects = skipCache;
      this.fetchData(skipCache);
    },
    async handleLinkInvitation(teamSlug, invitationCode) {
      try {
        this.loading = true;
        await this.acceptInvite({ team_slug: teamSlug, invitation_code: invitationCode });

        const { name } = await this.fetchTeam({ id: teamSlug, params: { is_slug: true } });

        this.$trackEvent('team-page.invitation-link.success');

        this.$router.replace({ query: {} });

        toastSuccess(`You were added to ${name} successfully`);

        nextTick(() => {
          EventBus.$emit('reload-user-memberships');
          EventBus.$emit('reload-team-info');
        });
      } catch (err) {
        const { message, response = {} } = err;
        this.$trackEvent('team-page.invitation-link.failure', { message });

        if (response.status === 409) {
          this.$router.replace({ query: {} });
        } else {
          toastError('Seems like this invitation is no longer valid.');
          this.$router.replace({ name: 'root' });
        }
      }
    },
    async fetchData(skipCache = false) {
      const { teamSlug: id } = this.$route.params;
      const { invite } = this.$route.query;
      const params = { is_slug: true };
      const transaction = this.$sentry.startTransaction({ name: 'team-page-loading' });

      if (invite) {
        return this.handleLinkInvitation(id, invite);
      }

      try {
        this.loading = true;

        await Promise.all([
          this.fetchTeam({ id, params, skipCache }),
          this.fetchTeamMemberships({ id, params, skipCache }),
          this.fetchSubscriptions({ id, skipCache }),
          this.fetchNotificationsSettings({ parent: 'teams', id, params, skipCache })
        ]);
      } catch (err) {
        this.notFound = true;
      } finally {
        this.loading = false;
        this.selectLayout();
        transaction.finish();
      }
    },
    async fetchSubscriptions({ id, skipCache = false }) {
      const params = { is_slug: true, is_master: true, is_test: isDev() || isCanary() };

      return Promise.all([
        this.fetchStripeSubscriptions({ parent: 'teams', id, params, skipCache }),
        this.fetchPaypalSubscriptions({ parent: 'teams', id, params, skipCache })
      ]);
    },
    newProject() {
      openModal({ name: 'new-project' });
    },
    onTabChange(tab) {
      this.activeTabLabel = tab.label;
      this.$router.push({ name: tab.routeName }, () => {});
      nextTick(() => (this.shouldUpdateProjects = false));
    },
    async onRouteChange() {
      const { name } = this.$route;
      const activeTab = this.tabs.find((t) => t.routeName === name);
      if (activeTab) {
        this.activeTabLabel = activeTab.label;
      }
      this.setSortBy('updated');
      this.notFound = false;
      await this.fetchData(true);
      this.selectLayout();
    },
    setSortBy(value) {
      this.sortBy = value;
    },
    toggleSearchOpen(value) {
      this.searchBarOpen = value;
      if (value) {
        this.$trackEvent('team-page.search.open');
      }
    },
    toggleUpgradeBanner(show) {
      if (show) showUpgradeTeamBanner();
      else hideUpgradeTeamBanner();
    },
    showUpgradeBannerIfNeeded() {
      const hasLockedProjects = this.projects.some((p) => p.is_locked);
      this.toggleUpgradeBanner(hasLockedProjects);
    },
    showUpdatePaymentBannerIfNeeded() {
      if (!this.team?.uses_stigg_integration) return;

      if (this.paymentStatus?.status !== 'REQUIRED') {
        hideUpdatePaymentBanner();
        return;
      }

      showUpdatePaymentBanner({
        paymentUrl: this.paymentStatus?.paymentUrl
      });
    },
    onChangeLayoutClick(layout) {
      this.$trackEvent('team-page.switch-layout.click', { layout });
      this.selectLayout(layout);
    },
    selectLayout(value) {
      let layoutValue = value || localStorage.getItem('teamPageLayout') || this.layout;
      if (!['grid', 'horizontal-list'].includes(layoutValue)) {
        layoutValue = 'grid';
      }
      this.layout = layoutValue;
      localStorage.setItem('teamPageLayout', this.layout);

      const gridRef = this.$refs.grid;
      const horizontalRef = this.$refs.horizontal;
      const gridColor = this.layout === 'grid' ? 'var(--primary)' : 'var(--secondary)';
      const horizontalColor = this.layout === 'horizontal-list' ? 'var(--primary)' : 'var(--secondary)';
      colorPathChildren(gridRef, gridColor);
      colorRectChildren(horizontalRef, horizontalColor);
    },
    setSocket() {
      this.openSocket(this.team?.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'team', action: 'updated' }, (team) => this.setTeam(team));
    },
    onTeamUpdated() {
      this.setSocket();
    }
  },
  watch: {
    '$route.path': {
      handler: 'onRouteChange',
      immediate: true
    },
    sortBy(newValue) {
      const { path } = this.$route;
      const query = { sort: newValue };
      this.$router.replace({ path, query }, () => {});
    },
    projects: 'showUpgradeBannerIfNeeded',
    team: {
      handler: 'onTeamUpdated',
      immediate: true
    },
    paymentStatus: 'showUpdatePaymentBannerIfNeeded'
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_navigationBar.scss';
.content {
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    margin: 30px 20px 0;
  }
}
.team-header-container {
  @include mobile {
    padding: 0 20px;
  }
}
.search {
  @include desktop {
    margin-left: 25px;
  }
}
.sort-mobile {
  @include desktop {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}
.sort-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navigation {
  display: flex;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
}
.projects {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
}
.projects > .project {
  margin: 16px;
}
.sorting-section {
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 20px;
  }
}
</style>

<template>
  <div class="block centered">
    <div class="block-title">
      <span>HTML Tag</span>
      <v-tip style="position: relative; margin-left: auto" :trigger="'hover'" :delay="{ show: 400, hide: 0 }">
        <GhostInput disabled class="tag editableInput" ref="tagNameRef" v-model="newTagName" />
        <template slot="popover">
          <div class="br-tip">
            <div class="br-tip-title">Changing the HTML tag on React components is not available yet.</div>
          </div>
        </template>
      </v-tip>

      <div style="position: relative; margin-left: auto">
        <GhostInput
          :disabled="nodeOverrides['is_image'] || isNativeImage"
          :undo="!isNativeImage && !nodeOverrides['is_image']"
          :permaUndo="!isNativeImage && !nodeOverrides['is_image']"
          @undo="undoTagNameChange"
          @focus="isTagInputFocused = true"
          @blur="handleTagBlur"
          @input="isTagInputFocused = true"
          @keydown.enter="handleTagKeydownEnter"
          @keydown="handleTagKeydown"
          class="editableInput tag"
          ref="tagNameRef"
          v-model="newTagName"
        />
        <div v-if="isTagInputFocused" class="autocomplete tag">
          <div
            v-for="(tag, index) in tagNameAutocomplete"
            :key="tag"
            @mousedown.prevent.stop="handleTagNameAutocompleteChange(tag)"
            :class="{ 'selected-tag': index == selectedTagIndex }"
            class="item"
            @mouseenter="selectedTagIndex = index"
          >
            {{ tag }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tagNames from './validHTMLTags.json';
import Fuse from 'fuse.js';
import { has } from 'lodash-es';
import { EventBus } from '@/services/bus';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import GhostInput from '@/components/Input/GhostInput.vue';
import { mapActions, mapGetters } from 'vuex';
import { codegenCache } from '@/services/idb/codegenCache';

export default {
  components: {
    GhostInput
  },
  data() {
    return {
      selectedTagIndex: 0,
      newTagName: '',
      tagCollection: [],
      fuseTagName: new Fuse([], {}),
      showTagAutoComplete: false,
      isTagInputFocused: false
    };
  },
  watch: {
    currentNodeTagName: {
      handler(tag) {
        if (tag) {
          const { tagNameRef } = this.$refs;

          this.newTagName = tag;
          const tags = tagNames.find((tags) => tags.includes(tag)) || tagNames[0];
          this.fuseTagName.setCollection(tags);
          this.tagCollection = tags;
          if (tagNameRef) {
            tagNameRef.$refs.editable.innerHTML = tag;
          }
        }
      },
      immediate: true
    },

    tagNameAutocomplete(to, from) {
      if (to.length != from.length) {
        this.selectedTagIndex = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      codegenLang: 'codePreferences/codegenLang',
      currentNode: 'omniview/currentNode',
      nodeOverrides: 'omniview/nodeOverrides',
      isNativeImage: 'omniview/isNativeImage',
      omniviewFrameworkPayload: 'tracking/omniviewFrameworkProps'
    }),

    currentNodeTagName() {
      let t = '';
      t = this.nodeOverrides['tagName'] ? this.nodeOverrides['tagName'] : this.currentNode.tagName;
      if (this.nodeOverrides['is_image']) {
        t = 'img';
      }
      return t;
    },
    tagNameAutocomplete() {
      const list = this.fuseTagName.search(this.newTagName);
      if (!list || (list && list.length == 0)) {
        return this.tagCollection;
      }
      return list.map((i) => i.item);
    }
  },

  methods: {
    ...mapActions({
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      getBackAllTheOverrides: 'omniview/getBackAllTheOverrides'
    }),
    handleTagKeydown(e) {
      if (e.keyCode === 38 && this.isTagInputFocused) {
        e.preventDefault();
        this.selectedTagIndex = Math.max(this.selectedTagIndex - 1, 0);
      } else if (e.keyCode === 40 && this.isTagInputFocused) {
        e.preventDefault();
        this.selectedTagIndex = Math.min(this.selectedTagIndex + 1, this.tagNameAutocomplete.length - 1);
      }
    },
    handleTagKeydownEnter() {
      if (this.isTagInputFocused) {
        const tag = this.tagNameAutocomplete[this.selectedTagIndex];
        this.handleTagNameAutocompleteChange(tag);
      } else {
        this.handleTagNameChange();
      }
    },
    handleTagBlur() {
      this.isTagInputFocused = false;
      this.handleTagNameChange();
    },
    handleTagNameAutocompleteChange(tag) {
      this.newTagName = tag;
      this.handleTagNameChange();
    },
    handleTagNameChange() {
      let tag = this.newTagName;
      const { tagNameRef } = this.$refs;

      const resetTag = () => {
        this.newTagName = this.currentNode.tagName.toLowerCase();
        tagNameRef.$refs.editable.innerHTML = this.currentNode.tagName.toLowerCase();
        this.$nextTick(() => {
          this.isTagInputFocused = false;
        });
        return;
      };

      if (this.currentNode.tagName.toLowerCase().trim() === tag.toLowerCase().trim()) {
        resetTag();
      }

      if (!tag || !this.tagCollection.map((c) => c.toLowerCase()).includes(tag.toLowerCase())) {
        tagNameRef.$refs.editable.innerHTML = this.newTagName = this.currentNode.tagName;
        this.$trackEvent('omniview.code-override.invalid-tag-name', { tagName: tag });
        return;
      }
      codegenCache.clearAll();

      this.newTagName = tag;
      tagNameRef.$refs.editable.innerHTML = tag;
      this.$nextTick(() => {
        tagNameRef.triggerFocus();
        this.isTagInputFocused = false;
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: 'set-tag-name',
        tagName: tag.toLowerCase()
      });

      this.getBackAllTheOverrides();

      let fields = {
        tagName: tag.toLowerCase()
      };

      if (!has(this.nodeOverrides, 'originalTagName')) {
        fields['originalTagName'] = this.currentNode.tagName.toLowerCase();
      }

      this.updateNodeOverrides({
        nodeId: this.currentNode.id,
        fields
      });
      const eventPayload = this.omniviewFrameworkPayload;
      this.$trackEvent('omniview.code-override.changed-tag-name', { tagName: tag.toLowerCase(), ...eventPayload });
    },
    undoTagNameChange() {
      if (!has(this.nodeOverrides, 'originalTagName')) return;
      this.newTagName = this.nodeOverrides['originalTagName'];
      this.handleTagNameChange();
    }
  }
};
</script>

<style lang="scss" scoped>
@import './block.scss';

.selected-tag {
  background: #1d1d1d;
  cursor: pointer;
  opacity: 1;
}
</style>

import axios from 'axios';
import errorHandler from './services/errorHandler';
import { readCookie } from './utils/cookie';
import { getRecaptchaToken } from './utils/recaptcha';

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  const _url = new URL(url);
  return _url.searchParams.get(name);
};

const clearAuthStorage = () => {
  localStorage.removeItem('isAnonymousUser');
  localStorage.removeItem('token');
  localStorage.removeItem('lastWorkspace');
  localStorage.removeItem('animp');
};

const login = async (email, password) => {
  const captchaToken = await getRecaptchaToken('login');
  const response = await axios.post(
    '/auth',
    {
      username: email,
      password: password,
      captcha_token: captchaToken,
      nonce: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)
    },
    {
      headers: {
        Authorization: `Basic`
      }
    }
  );

  return response.data;
};
const signup = async (firstName, lastName, email, password, acceptCommunication) => {
  const captchaToken = await getRecaptchaToken('signup');
  const response = await axios.post('/signup', {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    source: 'signup.form',
    captcha_token: captchaToken,
    visitor_id: readCookie('ana_visitorId'),
    referrer: readCookie('ana_originReferrer'),
    utm_source: readCookie('utm_source'),
    utm_medium: readCookie('utm_medium'),
    utm_campaign: readCookie('utm_campaign'),
    utm_term: readCookie('utm_term'),
    utm_content: readCookie('utm_content'),
    accept_communication: acceptCommunication
  });

  return response.data;
};

const logout = () => {
  clearAuthStorage();
};

const loginWithTempToken = (tempToken, cb) => {
  axios
    .post('/users/me/exchange_temp_token_for_token', { temp_token: tempToken })
    .then((response) => {
      var data = response.data;
      if (data.access_token) {
        localStorage.setItem('token', data.access_token);
        if (cb) cb(true);
      } else {
        if (cb) cb(false);
      }
    })
    .catch((error) => {
      errorHandler.captureException(error);
      if (cb) cb(false);
    });
};

export const getToken = () => {
  return localStorage.getItem('token') || getParameterByName('t');
};

export const isLoggedIn = () => !!getToken();
export const isAnonymousUser = () => localStorage.getItem('isAnonymousUser') === 'true';

export const googleLogin = async (credentials) => {
  const { credential } = credentials;
  const token = await getRecaptchaToken('login');
  const { data } = await axios.post('/oauth/google', { id_token: credential, captcha_token: token });
  return data;
};

export const githubLogin = async (code, existingUser) => {
  const { data } = await axios.post('/oauth/github', { code, existingUser });
  return data;
};

export const signGithubMessage = async (data) => {
  try {
    const url = `${process.env.API_BASE_URL}/rpc/github/sign`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      const data = await response.json();
      return data.jwt;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const auth = {
  login,
  signup,
  logout,
  loginWithTempToken,
  isLoggedIn,
  isAnonymousUser,
  getToken,
  googleLogin,
  githubLogin,
  signGithubMessage
};

export default auth;

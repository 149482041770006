<template>
  <div class="view slideInLeft">
    <div v-show="!(selected && activeView == 'overrides')" class="title-container">
      <div @click="handleTabChange('overrides')" class="tab-toggle">
        <span>Overrides</span>
        <div v-show="activeView == 'overrides'" class="active"></div>
      </div>
      <div @click="handleTabChange('assets')" class="tab-toggle">
        <span>Assets</span>
        <div v-show="activeView == 'assets'" class="active"></div>
      </div>
    </div>
    <div
      :style="{
        paddingTop: selected && activeView == 'overrides' ? '0' : '60px',
        marginBottom: selected && activeView == 'overrides' ? '43px' : '0'
      }"
      class="body-wrapper"
    >
      <keep-alive>
        <component :is="activeView" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Assets from '@/components/OmniView/Code/Assets.vue';
import Overrides from '@/components/OmniView/Code/Overrides.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'code-view',
  components: {
    Overrides,
    Assets
  },
  data() {
    return {
      activeView: 'overrides'
    };
  },
  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode'
    }),
    selected() {
      return !!this.currentNode.id;
    }
  },
  methods: {
    handleTabChange(view) {
      this.activeView = view;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../View.scss';

.title-container {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;
  z-index: 10;
  font-size: 16px;
  color: white;
}

.tab-toggle {
  position: relative;
  height: 100%;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  .active {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: var(--primary);
  }
}

.body-wrapper {
  flex: 1 1 auto;
  padding-top: 60px;
  overflow: auto;
  color: white;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>

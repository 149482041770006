import Vue from 'vue';
import tippy from 'tippy.js';

const tippyDirective = {
  bind: (el, bind) => {
    const { value } = bind;
    if (!value) return;
    let offset = [0, 12],
      content = '',
      placement = 'bottom';
    if (typeof value === 'object') {
      content = value.content;
      offset = value.offset || [0, 12];
      placement = value.placement || 'bottom';
    }
    if (typeof value === 'string') {
      content = value;
    }

    tippy(el, {
      content,
      placement,
      offset: offset,
      delay: 400
    });
  },
  componentUpdated(el, bind) {
    if (!bind) return;
    const { value } = bind;
    if (value) {
      let content = '';
      if (typeof value === 'object') {
        content = value.content;
      }
      if (typeof value === 'string') {
        content = value;
      }
      el._tippy.setContent(content);
    }
  },
  unbind: (el) => {
    if (el._tippy) {
      el._tippy.destroy();
    }
  }
};

Vue.directive('tippy', tippyDirective);

<template>
  <div class="container">
    <div class="title">Something went wrong</div>
    <div class="body">The exporting process failed, please try to download the package again.</div>
  </div>
</template>

<script>
import { downloadFile } from '@/utils/javascript';

export default {
  name: 'package-failed',
  async mounted() {
    this.$trackEvent('omniview.package-download-failed-modal.show');
  },
  methods: {
    download() {
      downloadFile(this.downloadUrl);
    },
    close() {
      this.$trackEvent('omniview.package-download-failed-modal.close');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title {
  @include headline;
  font-weight: 500;
}
.body {
  margin: 20px 0 30px;
  color: var(--dark-secondary-text);
}
</style>

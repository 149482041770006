<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex items-center justify-center" v-show="loading">
      <DefaultLoader noMargin :size="35" theme="dark" />
    </div>
    <pre
      class="fadeInFast"
      style="padding-bottom: 60px"
      v-show="!loading && parsedCode"
      :class="[className, plugins.join(' ')]"
    >
    <code @click="handleCodeClick" :class="[className, plugins.join(' '), { 'no-select': disableSelection }]" v-html="parsedCode"/>
  </pre>
  </div>
</template>

<script>
import Prism from 'prismjs';
import { handleStyleguideClick, setupEventListeners } from './prismUtils';
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/services/bus';
import { codegenCleanCode } from '@/services/codegen/cleanCode';
import DefaultLoader from '@/components/Loading/DefaultLoader';

export default {
  components: {
    DefaultLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    code: {
      type: String
    },
    inline: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'markup'
    },
    mode: {
      type: String,
      default: 'default'
    },
    componentData: {
      type: Object,
      default: () => ({ master: {}, instance: {} })
    },
    masterId: {
      type: String,
      requried: true
    },
    plugins: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    disableSelection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parsedCode: '',
      worker: null,
      isParsing: false
    };
  },

  watch: {
    code() {
      this.parseCode();
    }
  },
  computed: {
    ...mapState('webComponents', { webComponents: 'items' }),
    ...mapGetters({
      currentStyleguide: 'styleguide/currentStyleguide',
      currentNode: 'omniview/currentNode'
    }),
    prismLanguage() {
      return Prism.languages[this.language];
    },
    className() {
      return `language-${this.language}`;
    },
    getCacheKeyParams() {
      return {
        screenSlug: this.$route.params.screenSlug,
        language: this.language,
        type: this.type,
        mode: this.mode,
        ...(this.mode !== 'styleguide' ? { nodeId: this.currentNode.id } : {})
      };
    }
  },
  created() {
    this.worker = new Worker('/domWorker.js');

    // add event listener to handle webworker response
    this.worker.addEventListener(
      'message',
      async (e) => {
        if (e.data.nodeId !== this.currentNode.id) return;
        if (e.data.html) {
          this.parsedCode = e.data.html;
          const cacheKey = codegenCleanCode.getNodeCacheKey(this.getCacheKeyParams);

          codegenCleanCode.addToCache(cacheKey, e.data.html);
          this.onDone();
        }

        if (e.data.viewsMap) {
          this.$emit('update-generated-views', e.data.viewsMap);
        }
      },
      false
    );

    // send message to the webworker
  },
  mounted() {
    this.parseCode();
  },
  destroyed() {},
  methods: {
    async onDone() {
      await this.$nextTick();
      setupEventListeners({
        mode: this.mode,
        language: this.language,
        type: this.type,
        $el: this.$el
      });
      this.$emit('onRender');
      this.isParsing = false;
    },
    async parseCode() {
      this.parsedCode = '';
      if (!this.code) return;

      const cacheKey = codegenCleanCode.getNodeCacheKey(this.getCacheKeyParams);

      const cachedCode = await codegenCleanCode.getFromCache(cacheKey);
      if (cachedCode) {
        requestAnimationFrame(() => {
          this.parsedCode = cachedCode;
          this.onDone();
        });

        return;
      }

      if (this.language === 'css') {
        let result = '';
        if (this.language == 'markup') {
          result = Prism.highlight(
            codegenCleanCode.prettify(this.code.replaceAll(/ data-id=".*"+/g, ''), 'html', {
              printWidth: 140
            }),
            this.prismLanguage
          );
        } else {
          result = Prism.highlight(this.code, this.prismLanguage);
        }
        await this.$nextTick();
        requestAnimationFrame(() => {
          this.parsedCode = result;
        });
        // return;
      } else {
        this.isParsing = true;
      }

      const prismCode = Prism.highlight(
        codegenCleanCode.prettify(this.code, this.language, { printWidth: 140 }),
        this.prismLanguage
      );
      this.worker.postMessage(
        JSON.stringify({
          language: this.language,
          html: prismCode,
          mode: this.mode,
          type: this.type,
          componentId: this.$route.query.component,
          webComponents: this.webComponents,
          nodeId: this.currentNode.id
        })
      );
    },
    handleCodeClick(e) {
      switch (this.mode) {
        case 'styleguide':
          handleStyleguideClick({
            $emit: (event, data) => this.$emit(event, data),
            el: e.target
          });
          break;

        case 'default':
          if (this.language == 'markup' || this.language == 'jsx' || this.language == 'html') {
            const className = e.target.textContent;
            const styleguideClasses = this.currentStyleguide.classes;
            const isStyleguideClass = !!Object.values(styleguideClasses).find((s) => s.name == className);
            if (className) {
              // EventBus.$emit('handle-class-click', { className, isStyleguideClass });
              this.$emit('handle-class-click', { className, isStyleguideClass });
            }

            if (this.language == 'jsx') {
              let model_id = e.target.getAttribute('model_id');
              if (model_id) {
                EventBus.$emit('open-component-in-library', {
                  nodeId: model_id,
                  openComponentInterface: false,
                  preProcessParams: {
                    forcePreProcess: true
                  }
                });
              }
            }
          }
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

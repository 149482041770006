<template>
  <div class="code_preferences" @click="closeIfTarget">
    <div class="panel" data-cy="code-prefs-panel">
      <h1>Framework setup</h1>
      <div class="cards">
        <div
          class="card"
          :class="[lang == 'react' ? 'active' : '']"
          @click="handleCodegenLangChange('react')"
          data-cy="react-pref"
        >
          <img src="@/assets/svg/react-logo.svg" alt="react" />
          <p>React</p>
          <div class="overlay"></div>
        </div>
        <div
          class="card"
          :class="[lang == 'vue' ? 'active' : '']"
          @click="handleCodegenLangChange('vue')"
          data-cy="vue-pref"
        >
          <img src="@/assets/svg/vue-logo.svg" alt="vue" />
          <p>Vue</p>
          <div class="overlay"></div>
        </div>
        <div
          class="card"
          :class="[lang == 'html' ? 'active' : '']"
          @click="handleCodegenLangChange('html')"
          data-cy="html-pref"
        >
          <img src="@/assets/svg/html-logo.svg" alt="html" />
          <p>HTML</p>
          <div class="overlay"></div>
        </div>
      </div>
      <div class="preferences">
        <div class="flex w-full">
          <CodePrefCol
            v-if="lang == 'html'"
            class="column"
            :value="htmlLayout"
            @change="handleHTMLLayoutChange"
            title="Styling"
            :options="htmlLayoutOptions"
          />
          <CodePrefCol
            v-if="lang == 'html'"
            class="column"
            :value="lengthUnit"
            @change="handleLengthUnitChange"
            title="Length unit"
            :options="lengthUnitOptions"
          />
          <CodePrefCol
            v-if="isActiveExperiment('auto-animate-mode') && lang == 'html'"
            class="column"
            :value="autoAnimateMode"
            @change="handleAutoAnimateModeChange"
            title="Auto-Animate"
            :options="autoAnimateModeOptions"
          />

          <CodePrefCol
            v-if="lang == 'react'"
            class="column"
            :value="reactLanguage || 'javascript'"
            @change="handleReactLanguageChange"
            title="Language"
            :options="reactLanguageOptions"
          />

          <CodePrefCol
            v-if="lang == 'vue'"
            class="column"
            :value="vueStyle"
            @change="handleVueStyleChange"
            title="Styling"
            :options="vueStyleOptions"
          />
        </div>
      </div>

      <div class="save-buttons">
        <an-button @click="clickSavePreferences" data-cy="save-code-prefs">{{ mainButtonCTA }}</an-button>
      </div>
      <div class="disclaimer">You can change this later.</div>
    </div>
  </div>
</template>

<script>
import CodePrefCol from '@/components/OmniView/Panels/CodePrefCol';
// import { maxCodesandboxInspectCount } from '@/utils/variables';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  SET_CODE_PREFERENCES_ACTION,
  SET_DB_CODE_PREFERENCES_ACTION,
  FRAMEWORK_TO_LABEL
} from '@/store/modules/codePreferences/constants';
import errorHandler from '@/services/errorHandler';
import { EventBus } from '@/services/bus';

export default {
  components: {
    CodePrefCol
  },
  data() {
    return {
      lang: '',
      reactStyle: '',
      vueStyle: '',
      reactSyntax: '',
      reactLanguage: '',
      htmlLayout: '',
      lengthUnit: '',
      autoAnimateMode: ''
    };
  },
  mounted() {
    this.lang = this.codegenLang;
    this.reactLanguage = this.codegenReactLanguage;
    this.reactStyle = this.codegenReactStyle;
    this.vueStyle = this.codegenVueStyle;
    this.reactSyntax = this.codegenReactSyntax;
    this.htmlLayout = this.codegenHTMLLayout;
    this.lengthUnit = this.codegenLengthUnit;
    this.autoAnimateMode = this.codegenAutoAnimateMode;
  },

  methods: {
    ...mapMutations({
      setIsCodePreferencesRequested: 'omniview/setIsCodePreferencesRequested',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized'
    }),
    ...mapActions({
      setDBCodePreferences: SET_DB_CODE_PREFERENCES_ACTION,
      setCodePreferences: SET_CODE_PREFERENCES_ACTION
    }),
    closeIfTarget(event) {
      if (event.target.className == 'code_preferences') {
        this.close();
      }
    },
    handleCodegenLangChange(lang) {
      this.lang = lang;
    },
    handleHTMLLayoutChange(value) {
      this.htmlLayout = value;
    },
    handleReactSyntaxChange(value) {
      this.reactSyntax = value;
    },
    handleReactLanguageChange(value) {
      this.reactLanguage = value;
    },
    handleReactStyleChange(value) {
      this.reactStyle = value;
    },
    handleVueStyleChange(value) {
      this.vueStyle = value;
    },
    handleLengthUnitChange(value) {
      this.lengthUnit = value;
    },
    handleAutoAnimateModeChange(value) {
      this.autoAnimateMode = value;
    },

    async clickSavePreferences() {
      if (this.currentPreferences.framework !== this.codegenLang) {
        this.$trackEvent('omniview.codegen-framework.change', {
          from: this.codegenLang,
          to: this.currentPreferences.framework
        });
      }

      await this.setCodePreferences(this.currentPreferences);

      EventBus.$emit('CODE_PREFERENCES_UPDATED');

      const dbPrefs = this.convertLocalStoragePrefsToDB(this.currentPreferences);
      try {
        this.setDBCodePreferences(dbPrefs);
      } catch (err) {
        errorHandler.captureExceptionAndTrack(err, {
          name: 'omniview.dev-preferences-update.failure',
          data: { message: err.message }
        });
      }
      this.close();
    },
    close() {
      this.setIsCodePreferencesRequested(false);
      EventBus.$emit('OPEN_EXPORT_PANEL');
    }
  },
  computed: {
    ...mapState('codePreferences', [
      'htmlLayoutOptions',
      'lengthUnitOptions',
      'reactSyntaxOptions',
      'reactLanguageOptions',
      'reactStyleOptions',
      'vueStyleOptions',
      'autoAnimateModeOptions'
    ]),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'currentItem' }),
    ...mapGetters({
      isActiveExperiment: 'experiments/isActive',
      codegenReactSyntax: 'codePreferences/codegenReactSyntax',
      codegenReactLanguage: 'codePreferences/codegenReactLanguage',
      codegenReactStyle: 'codePreferences/codegenReactStyle',
      codegenVueStyle: 'codePreferences/codegenVueStyle',
      codegenHTMLLayout: 'codePreferences/codegenHTMLLayout',
      codegenLengthUnit: 'codePreferences/codegenLengthUnit',
      codegenAutoAnimateMode: 'codePreferences/codegenAutoAnimateMode',
      codegenLang: 'codePreferences/codegenLang',
      convertLocalStoragePrefsToDB: 'codePreferences/convertLocalStoragePrefsToDB'
    }),
    mainButtonCTA() {
      if (this.codegenLang == this.currentPreferences.framework) {
        return `Continue with ${FRAMEWORK_TO_LABEL[this.lang]}`;
      }
      return `Change to ${FRAMEWORK_TO_LABEL[this.lang]}`;
    },
    currentPreferences() {
      const framework = this.lang ? this.lang : 'html';
      let styling = '';
      let syntax = '';
      let lengthUnit = '';
      let autoAnimateMode = '';
      let reactLanguage = '';
      if (framework) {
        switch (this.lang) {
          case 'html':
            autoAnimateMode = this.autoAnimateMode;
            lengthUnit = this.lengthUnit;
            styling = this.htmlLayout;
            break;
          case 'vue':
            styling = this.vueStyle;
            break;
          case 'react':
            syntax = this.reactSyntax;
            styling = this.reactStyle;
            reactLanguage = this.reactLanguage;
            break;
        }
      }
      const ret = { framework, styling, syntax, autoAnimateMode, lengthUnit, reactLanguage };
      Object.keys(ret).forEach((key) => !ret[key] && delete ret[key]);
      return ret;
    }
  }
};
</script>

<style lang="scss" scoped>
.code_preferences {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 201;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
h1 {
  @include headline;
  width: 420px;
  margin-bottom: 39px;
}
.panel {
  padding: 48px 40px 32px 40px;
  background: var(--dark-onboarding-background);
  border-radius: 10px;
}
.cards {
  display: flex;
  justify-content: space-between;
  .card {
    background: #333333;
    border: 1px solid var(--dark-background);
    width: 130px;
    height: 100px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    img {
      height: 28px;
      margin: 19px auto 11px auto;
    }
    p {
      font-weight: 600;
      opacity: 0.7;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--red);
      opacity: 0.1;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
    &.active {
      border: 1px solid var(--red);
      background: none;
      p {
        opacity: 1;
      }
      .overlay {
        opacity: 0.1;
      }
    }
    &:hover {
      border: 1px solid var(--red);
      background: none;
      p {
        opacity: 1;
      }
      .overlay {
        opacity: 0.1;
      }
    }
  }
}

.preferences {
  padding-top: 23px;
  padding-bottom: 24px;

  .full-width {
    width: 100%;
    display: block;
  }
  .column {
    text-align: left;
    width: 50%;
  }
  border-bottom: 1px solid var(--secondary);
}
.save-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  & * {
    margin-left: 16px;
    margin-right: 16px;
  }
  .keep-button {
    cursor: pointer;
    font-size: 14px;
  }
  .keep-button:hover {
    text-decoration: underline;
  }
}
.disclaimer {
  font-weight: 400;
  color: #999999;
  font-size: 14px;
  margin-top: 12px;
}
</style>

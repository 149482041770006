<template>
  <div class="signup-page-container" data-cy="signup-page">
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="signup-container">
      <LoadingScreen v-if="googleLoading" />
      <div class="form-container" v-else>
        <div class="title">Create Account</div>
        <div class="subtitle">
          Already have an account?
          <an-link @click="$router.push({ name: 'login', query: $route.query })" variant="primary">Log in</an-link>
        </div>
        <div class="social-signup">
          <GoogleButton @success="onGoogleSignupSuccess" @failure="onGoogleSignupFailure" />
        </div>
        <div class="text-divider">
          <Divider text="Or" :theme="theme" />
        </div>
        <form @submit.prevent="signup">
          <div class="property" v-if="nameRequired">
            <div class="name-property">
              <label class="input">
                <span class="sr-only">First Name</span>
                <an-input
                  v-model="firstName"
                  id="firstName"
                  :theme="theme"
                  :invalid="!validFirstName"
                  placeholder="First name"
                  data-cy="signup-first-name"
                  autocomplete="given-name"
                />
              </label>
              <label class="input">
                <span class="sr-only">Last Name</span>
                <an-input
                  v-model="lastName"
                  id="lastName"
                  :theme="theme"
                  :invalid="!validLastName"
                  placeholder="Last name"
                  data-cy="signup-last-name"
                  autocomplete="family-name"
                />
              </label>
            </div>
            <div class="invalid-message" v-if="!validFirstName || !validLastName" data-cy="signup-name-error">
              Umm.. we hope you’re not a ghost
            </div>
          </div>
          <div class="property">
            <label class="input">
              <span class="sr-only">Email</span>
              <an-input
                id="email"
                type="email"
                v-model="email"
                placeholder="name@work-email.com"
                :theme="theme"
                :invalid="!validEmail"
                data-cy="signup-email"
                autocomplete="email"
              />
              <span class="invalid-message" v-if="errorMessage" data-cy="signup-email-error">
                {{ errorMessage }}
              </span>
            </label>
          </div>
          <div class="property">
            <label class="input">
              <span class="sr-only">Password</span>
              <an-input
                id="pass"
                type="password"
                v-model="password"
                placeholder="Password"
                :theme="theme"
                :invalid="!validPassword"
                data-cy="signup-password"
                autocomplete="new-password"
              />
              <span class="invalid-message" v-if="!validPassword" data-cy="signup-password-error">
                Password must contain at least 8 characters
              </span>
            </label>
          </div>
          <an-checkbox
            class="join-mailing-list"
            v-model="acceptCommunication"
            :labelStyle="{ marginLeft: '12px' }"
            variant="dark"
          >
            <span>Join our email list for special offers and updates!</span>
          </an-checkbox>
          <div class="terms-of-service">
            By creating an account, I agree with Anima’s
            <an-link variant="primary" :href="privacyUrl" target="_blank">Privacy Policy</an-link> and
            <an-link variant="primary" :href="termsUrl" target="_blank">Terms of Service.</an-link>
          </div>
          <div class="actions">
            <an-button
              @click="onRecoverClick"
              v-if="showRecoverPasswordButton"
              class="justify-center"
              size="xl"
              data-cy="signup-recover"
              style="margin-bottom: 16px"
            >
              Recover password
            </an-button>
            <an-button class="justify-center" type="submit" :isWorking="loading" size="xl" data-cy="signup-submit">
              Create account
            </an-button>
          </div>
        </form>
      </div>
      <div class="signup-illustration" v-if="!googleLoading" />
    </div>
    <div />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import GoogleButton from '@/components/Button/GoogleButton';
import Divider from '@/components/Divider/Divider';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { SignupMixin } from '@/mixins';

import auth from '@/auth';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import { toastError } from '@/services/bus';
import { readCookie } from '@/utils/cookie';
import errorHandler from '@/services/errorHandler';
import { uuid } from '@/utils/uuid';
import { sendGoogleConversion, sendFacebookConversion } from '@/services/conversion';

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      errorMessage: '',
      theme: 'dark',
      validFirstName: true,
      validLastName: true,
      validEmail: true,
      validPassword: true,
      termsUrl: 'https://www.animaapp.com/tos/TOS.pdf',
      privacyUrl: 'https://support.animaapp.com/en/articles/8120546-anima-privacy-policy-explained',
      loading: false,
      googleLoading: false,
      showRecoverPasswordButton: false,
      acceptCommunication: false
    };
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
    this.onMounted();
  },
  beforeDestroy() {
    this.removeTheme();
  },
  components: {
    GoogleButton,
    Divider,
    LoadingScreen,
    OnboardingTopBar
  },
  mixins: [SignupMixin],
  computed: {
    ...mapState('projects', ['sharedProjects']),
    ...mapState('webappSystem', ['ipAddress']),
    ...mapState('devices', { currentDevice: 'currentItem' }),
    nameRequired() {
      return this.isDesktop;
    },
    ...mapGetters({
      isPricingExperimentScreenActive: 'experiments/isPricingExperimentScreenActive'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      createTeam: 'teams/create',
      fetchSharedProjects: 'projects/fetchSharedProjects'
    }),
    ...mapMutations({
      setCurrentUser: 'users/setCurrentItem',
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    onMounted() {
      const { prefill } = this.$route.query;
      const email = readCookie('subscribed_email');
      if (prefill && isValidEmail(normalizeEmail(email))) {
        this.email = email;
      }
    },
    validateName() {
      this.validateFirstName();
      this.validateLastName();
    },
    validateFirstName() {
      this.validFirstName = !!this.firstName?.trim().length || !this.nameRequired;
    },
    validateLastName() {
      this.validLastName = !!this.lastName?.trim().length || !this.nameRequired;
    },
    validateEmail() {
      const email = normalizeEmail(this.email);
      this.validEmail = isValidEmail(email);
      if (!this.validEmail) {
        this.errorMessage = 'Please enter a valid email address';
      } else {
        this.errorMessage = '';
      }
    },
    validatePassword() {
      this.validPassword = this.password.length >= 8;
    },
    validateForm() {
      this.validateName();
      this.validateEmail();
      this.validatePassword();
      const isValid = this.validEmail && this.validFirstName && this.validLastName && this.validPassword;
      if (!isValid) {
        this.$trackEvent('signup.signup.invalid-form', {
          is_email_valid: this.validEmail,
          is_password_valid: this.validPassword,
          is_first_name_valid: this.validFirstName,
          is_last_name_valid: this.validLastName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        });
      }
      return isValid;
    },
    async onGoogleSignupSuccess(googleUser) {
      try {
        this.googleLoading = true;
        await this.onGoogleSuccess(googleUser);
        this.signupExperiments();
      } catch (error) {
        this.googleLoading = false;
        errorHandler.captureException(error);
        toastError("We couldn't log you in with your Google account :(");
      }
    },
    async onGoogleSignupFailure(err) {
      this.onGoogleFailure(err);
      toastError("We couldn't log you in with your Google account :(");
    },
    onRecoverClick() {
      this.$trackEvent('signup.recover-password.click');
      this.$router.replace({ name: 'forgot-password', query: { ...this.$route.query, email: this.email } });
    },
    async signup() {
      try {
        const { query } = this.$route;
        this.$trackEvent('signup.signup-button.click', query);
        this.errorMessage = '';

        if (!this.validateForm()) {
          return;
        }

        const email = normalizeEmail(this.email);
        const { firstName, lastName } = this;

        const transactionId = uuid();

        this.loading = true;
        const { access_token: token, ...user } = await auth.signup(
          firstName,
          lastName,
          email,
          this.password,
          this.acceptCommunication
        );
        localStorage.setItem('token', token);
        this.signupExperiments();
        this.setCurrentUser(user);

        this.signupTracking(user, transactionId);

        await this.fetchSharedProjects();
        setTimeout(() => {
          this.redirectAfterAuth();
          this.loading = false;
        }, 1000);
      } catch (error) {
        const validationErrors = [
          'Email exists. If this is you, please login',
          'Ummm. Is this a valid email you got there?',
          "Hmm.. We couldn't verify you're a human. Try refreshing the page?"
        ];

        this.errorMessage = error?.response?.data?.message || 'Failed to sign up';
        if (this.errorMessage === 'Email exists. If this is you, please login') {
          this.showRecoverPasswordButton = true;
        }

        if (!validationErrors.includes(this.errorMessage)) {
          errorHandler.captureException(this.errorMessage);
        }

        this.$trackEvent('signup.signup.failure', { cta: 'anima', message: this.errorMessage });
        this.loading = false;
      }
    },
    signupTracking(user, transactionId) {
      try {
        const { query } = this.$route;
        const email = normalizeEmail(this.email);
        const { firstName, lastName } = this;

        this.$trackEvent('signup.signup.success', {
          ...query,
          cta: 'anima',
          device_id: this.currentDevice.device_id,
          email: email
        });

        this.$gtm.trackEvent({
          event: 'sign_up',
          fbEventID: `CompleteRegistration:${user.id}`,
          fbIpAddress: this.ipAddress,
          fbUserAgent: navigator.userAgent,
          fbc: readCookie('_fbc'),
          fbp: readCookie('_fbp'),
          fbCurrency: 'USD',
          fbValue: 5,
          fbFirstName: firstName,
          fbLastName: lastName,
          event_category: 'Signup',
          event_action: `${firstName} ${lastName}`,
          event_label: email,
          transaction_id: transactionId,
          external_id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email
        });

        this.$tracking.setUserEmail(email);
        this.$tracking.setUserId(user.id);
        this.$tracking.alias(user.id);

        sendGoogleConversion('signup', transactionId, {
          email,
          first_name: user.first_name,
          last_name: user.last_name
        });

        sendFacebookConversion('CompleteRegistration', transactionId, {
          id: user.id,
          email,
          first_name: user.first_name,
          last_name: user.last_name
        });
      } catch (err) {
        errorHandler.captureException(err);
      }
    },
    signupExperiments() {
      localStorage.setItem('pricingScreenFlowActive', this.isPricingExperimentScreenActive);
      this.$trackEvent('pricing::screen-tier-flow', { active: this.isPricingExperimentScreenActive });
    }
  }
};
</script>

<style lang="scss" scoped>
$topBarHeight: 80px;
.form-container {
  width: 343px;
  text-align: center;
  @include mobile {
    width: 375px;
    padding: 16px;
  }
}
.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - #{$topBarHeight});
  width: 100%;

  ::v-deep .top-bar {
    position: unset;
  }
  .container {
    margin: 0;
    width: 345px;
    @include mobile {
      width: 100%;
    }
  }
  .signup-illustration-experiment {
    background-image: url('~@/assets/illustrations/signup-illustration.svg');
  }
  .signup-illustration {
    background: url('~@/assets/illustrations/signup.svg');
    background-size: cover;
    margin-left: 210px;
    width: 624px;
    height: 550px;
    @media screen and (max-width: 1280px) {
      width: 500px;
      height: 440px;
    }
    @include mobile {
      display: none;
    }
  }
}
.title {
  @include headline;
  font-weight: 500;
  text-align: center;
}
.subtitle {
  margin: 20px 40px 0;
  @include mobile {
    margin: 16px 0;
  }
}
.social-signup,
.text-divider {
  margin: 40px 0;
  width: 100%;
}
.title {
  font-weight: 700;
  @include mobile {
    font-size: 32px;
    line-height: 50px;
  }
}
.property {
  .name-property {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 50px;
    .input {
      margin: 0;
      & + .input {
        margin-left: 15px;
      }
    }
  }
  + .property {
    margin-top: 24px;
  }
}
.terms-of-service {
  font-size: 14px;
  text-align: center;
  margin: 16px 0 16px;
}
.join-mailing-list {
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
  justify-content: center;
}
.actions {
  height: 48px;
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    width: 100%;
    &.an-button {
      @include mobile {
        border-radius: 0;
      }
    }
  }
}
.invalid-message {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  color: var(--red);
}
</style>

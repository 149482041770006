import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';
import * as modules from './modules';
import { isProd } from '@/utils/environment';
import { teamWatcherPlugin } from './plugins/teamWatcherPlugin';
import { gtagPlugin } from './plugins/gtag';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: isProd ? [teamWatcherPlugin, gtagPlugin] : [createLogger(), teamWatcherPlugin, gtagPlugin]
});

export const useStore = () => store;

export default store;

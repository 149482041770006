<template>
  <div class="pagination-container">
    <span>{{ paginationText }}</span>
    <div class="pagination-bar">
      <ProgressBar :progress="progress" :height="4" />
    </div>
    <an-button @click="$emit('load-more')" variant="secondary" :isWorking="isLoadingMore">Load more</an-button>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar';
export default {
  props: {
    itemsType: {
      type: String,
      required: true
    },
    currentAmount: {
      type: Number,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProgressBar
  },
  computed: {
    paginationText() {
      const { totalAmount, currentAmount, itemsType } = this;
      return `You've seen ${currentAmount} of ${totalAmount} ${itemsType}s`;
    },
    progress() {
      const { currentAmount, totalAmount } = this;
      let progress = 0;
      if (totalAmount !== 0) {
        progress = currentAmount / totalAmount;
      }
      return progress;
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
}
.pagination-bar {
  width: 240px;
  margin: 20px 0 30px;
}
</style>

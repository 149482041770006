<template>
  <div class="container">
    <div class="title">Permissions</div>
    <div class="subtitle">Manage how collaborators can interact with your projects at team &amp; project level.</div>
    <div class="body">
      <div class="label">Project settings</div>
      <an-toggle v-model="hotspotsEnabled"> Show hotspot hints by default </an-toggle>
    </div>
    <transition name="fade">
      <div class="actions">
        <an-button @click="save" :isWorking="isWorking">Save changes</an-button>
        <an-link @click="$emit('close')">Cancel</an-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { toastError } from '@/services/bus';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'team-settings-permissions',
  data() {
    return {
      hotspotsEnabled: false,
      isWorking: false
    };
  },
  mounted() {
    this.hotspotsEnabled = this.team.is_hotspots_enabled_by_default;
  },
  computed: mapState('teams', { team: 'currentItem' }),
  methods: {
    ...mapActions({
      updateTeam: 'teams/update'
    }),
    async save() {
      const { id } = this.team;
      const payload = { is_hotspots_enabled_by_default: this.hotspotsEnabled };

      try {
        this.isWorking = true;
        await this.updateTeam({ id, payload });
        this.$emit('close');
      } catch (err) {
        toastError('Failed saving permision changes');
      } finally {
        this.isWorking = false;
      }
    }
  },
  watch: {
    team(newValue) {
      this.hotspotsEnabled = newValue.is_hotspots_enabled_by_default;
    }
  }
};
</script>

<style lang="scss" scoped>
/*@import '@/styles/_fullscreenLayout.scss';*/
</style>

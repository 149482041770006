<template>
  <div class="container" data-cy="upgrade-plan-modal">
    <h2 class="title">Enjoying Anima?</h2>
    <p class="subtitle">
      We’d love to talk with you about your experience with Anima.<br />
      Your feedback can help us improve Anima for you.
    </p>
    <div class="actions">
      <an-button data-cy="upgrade-team-btn" @click="letsTalk">Schedule a call</an-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'upgrade-plan-modal',
  methods: {
    letsTalk() {
      window.open('https://calendly.com/anima-product/anima-user-interview', '_blank');
      this.$emit('close', { isActionClicked: true });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.container {
  height: 100%;
}
</style>

import api from '@/api';
import { uniqueValuesArrayByKey } from '@/utils/javascript';
import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'educationPosts',
  modelName: 'educationPosts',
  endpoint: '/v2/education_posts',
  state: {
    items: [],
    total: 0,
    count: 0,
    page: 0
  },
  mutations: {
    setItems: (state, { results, total, count, page }) => {
      state.items = results;
      state.total = total;
      state.count = count;
      state.page = page;
    }
  },
  actions: {
    async fetchAll({ state, commit }, { params: _params = {}, nextPage = false } = {}) {
      const page = nextPage ? state.page + 1 : 0;
      const pageSize = 9;
      const params = { order_by: 'id', page, page_size: pageSize, ..._params };
      const { data } = await api.list('/v2/education_posts', { params });
      if (nextPage) {
        const { items } = state;
        data.results = uniqueValuesArrayByKey(items.concat(data.results));
        data.count = data.results.length;
      }
      commit('setItems', data);
    }
  }
});

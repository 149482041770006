<template>
  <div class="empty">
    <div class="flex items-center flex-col">
      <img class="image" :src="src" />
      <div class="title">{{ title }}</div>
      <div class="text"><slot /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.empty {
  flex-direction: column;
  height: calc(100vh - 130px);
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  min-width: 360px;
  padding: 0 42px;
  user-select: none;
  color: white;
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 20px;
}
.text {
  text-align: center;
  min-height: 60px;
  font-size: 16px;
}

.image {
  max-width: 220px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>

import { createStoreModule } from '../utils';
import { getActiveSubscription } from '@/services/stigg';

export default createStoreModule({
  name: 'stigg',
  modelName: 'stigg',
  state: {
    currTeamId: null,
    subscriptions: {}
  },
  getters: {
    currSubscription: (state) => state.subscriptions[state.currTeamId],
    getSubscription: (state) => (teamId) => state.subscriptions[teamId],
    paymentStatus: (_, getters) => {
      if (getters.isPaid && getters.currSubscription?.paymentCollection === 'FAILED') {
        return { status: 'REQUIRED', paymentUrl: getters.currSubscription?.latestInvoice?.paymentUrl };
      }
      return { status: 'OK' };
    },
    isPaid: (_, getters) =>
      getters.currSubscription?.pricingType === 'PAID' || getters.currSubscription?.pricingType === 'CUSTOM'
  },
  mutations: {
    setSubscription(state, { subscription, teamId }) {
      state.subscriptions = { ...state.subscriptions, [teamId]: subscription };
    },
    setCurrTeamId(state, teamId) {
      state.currTeamId = teamId;
    }
  },
  actions: {
    async fetchSubscription({ commit }, { teamId, shouldSetAsCurrent }) {
      const subscription = await getActiveSubscription(teamId);
      commit('setSubscription', { subscription, teamId });
      if (shouldSetAsCurrent) {
        commit('setCurrTeamId', teamId);
      }
      return subscription;
    }
  }
});

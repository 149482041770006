<template>
  <div :class="['stage-container', { open, inOmniview }, theme]" @click="$emit('click:stage')">
    <div class="header">
      <div style="display: flex; align-items: center">
        <div v-if="done" :class="['done-icon', { open }]">
          <svg-icon name="circle-check-inverse" :size="20" />
        </div>
        <div :class="['number', theme, { open }]" v-else>{{ stage.number }}</div>
        <div :class="['title', { open, done }]">{{ stage.title }}</div>
      </div>
      <div class="skip" v-if="open && isSkipEnabled" @click.stop="skip">Skip</div>
    </div>
    <transition name="accordion" mode="out-in">
      <div class="body" v-if="open">
        <div class="text" v-html="stage.text" v-if="stage.text" />
        <div class="sub-stages" v-if="subStages">
          <SubStage
            v-for="subStage in subStages"
            :key="subStage.slug"
            :stage="subStage"
            :selected="selectedSubStage === subStage.index"
            @click:header="onClickSubStageHeader(subStage)"
            @click:action="onSubStageAction"
          />
        </div>
        <div class="cta" v-if="stage.cta_text">
          <an-button @click.stop="clickCTA" size="sm">{{ stage.cta_text }}</an-button>
        </div>
        <div class="video-container" v-if="stage.video_url">
          <div
            class="video-thumb-container"
            @click="showVideo"
            @mouseover="videoHover = true"
            @mouseleave="videoHover = false"
          >
            <div class="play-icon"><svg-icon name="circle-play" :size="32" /></div>
            <div :class="['video-thumb', { hover: videoHover }]" :style="videoThumbStyle" />
          </div>
          <div class="video-title">{{ stage.video_title }}</div>
        </div>
        <an-prompt v-if="showVideoModal" @close="closeVideo" :width="700" mode="transparent" whiteOverlay>
          <video-modal :src="stage.video_url" />
        </an-prompt>
      </div>
    </transition>
  </div>
</template>

<script>
import VideoModal from '../Learn/VideoModal.vue';
import SubStage from './SubStage.vue';

export default {
  data() {
    return {
      showVideoModal: false,
      videoHover: false,
      selectedSubStage: 0
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      validator: (v) => ['dark', 'light'].includes(v)
    },
    done: {
      type: Boolean,
      required: true
    },
    activeStage: {
      type: Boolean,
      default: false
    },
    stage: {
      type: Object,
      required: true,
      validator: (s) => {
        return s.title && s.number >= 0 && (s.text || s.sub_stages?.length) && s.slug;
      }
    },
    eventProps: {
      type: Object,
      default: () => ({})
    },
    inOmniview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VideoModal,
    SubStage
  },
  mounted() {
    this.resetSelectedSubStage();
  },
  computed: {
    videoThumbStyle() {
      const { video_thumb_url: videoThumb } = this.stage;
      return {
        background: `url('${videoThumb}') no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    eventProps_() {
      return {
        ...this.eventProps,
        stage: this.stage.title,
        slug: this.stage.slug
      };
    },
    isSkipEnabled() {
      return !!this.stage.is_skip_enabled && !this.done && this.activeStage;
    },
    subStages() {
      return this.stage.sub_stages;
    }
  },
  methods: {
    clickCTA() {
      const { stage } = this;
      this.$emit('click:cta', stage);
      this.$trackEvent('personalized-onboarding-stage.cta.click', { cta: stage.cta_text, ...this.eventProps_ });
    },
    showVideo() {
      this.$trackEvent('personalized-onboarding-stage.video-modal.open', {
        video: this.stage.video_title,
        ...this.eventProps_
      });
      this.showVideoModal = true;
    },
    closeVideo() {
      this.$trackEvent('personalized-onboarding-stage.video-modal.close', {
        video: this.stage.video_title,
        ...this.eventProps_
      });
      this.showVideoModal = false;
      if (this.stage.data.action === 'next-stage') {
        this.$emit('click:cta', this.stage);
      }
    },
    skip() {
      this.$trackEvent('personalized-onboarding-stage.skip.click', { ...this.eventProps_ });
      this.$emit('click:skip');
    },
    onClickSubStageHeader(subStage) {
      this.selectedSubStage = subStage.index;
      this.$trackEvent('personalized-onboarding-stage.sub-stage.click', {
        ...this.eventProps_,
        subStageSlug: subStage.slug
      });
    },
    resetSelectedSubStage() {
      const selectedSubStageIndex = this.subStages.findIndex((s) => !s.is_done);
      this.selectedSubStage = Math.max(selectedSubStageIndex, 0);
    },
    onSubStageAction(stage) {
      this.$emit('click:cta', stage);
    }
  }
};
</script>

<style lang="scss" scoped>
$dark-border: 1px solid rgba(87, 87, 87, 1);
$light-border: 1px solid rgba(87, 87, 87, 0.1);
.stage-container {
  width: 100%;
  padding: 20px 24px;
  cursor: default;
  &.inOmniview {
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  + .stage-container {
    border-top: none;
  }
  &.dark {
    border-top: $dark-border;
    border-bottom: $dark-border;
  }
  &.light {
    border-top: $light-border;
    border-bottom: $light-border;
  }
  &.open {
    padding-bottom: 16px;
  }
  &.open:not(.inOmniview) {
    background-color: var(--dark-background);
    &.light {
      background-color: transparent;
    }
  }
  &:not(.open) {
    cursor: pointer;
  }
  &:last-child {
    border-bottom: none;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border: 1px solid white;
    user-select: none;
    &:not(.open) {
      opacity: 0.5;
    }
    &.light {
      border: 1px solid #2d2d2d;
      &.open {
        background-color: var(--yellow);
      }
    }
  }
  .done-icon {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
    &:not(.open) {
      opacity: 0.5;
    }
  }
  .title {
    font-size: 14px;
    margin-left: 10px;
    &.open {
      font-weight: 700;
    }
    &:not(.open) {
      opacity: 0.5;
    }
  }
  .skip {
    justify-self: flex-end;
    font-size: 10px;
    opacity: 0.6;
    font-weight: 200;
    cursor: pointer;
  }
}
.body {
  font-size: 12px;
  line-height: 20px;
  .text {
    margin-top: 20px;
  }
  .sub-stages {
    margin-top: 10px;
    padding-left: 4px;
  }
  .cta {
    margin-top: 20px;
  }
  ::v-deep ul {
    margin-top: 10px;
    list-style-type: disc;
    li {
      margin-left: 20px;
    }
  }
  .video {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }
    &-thumb-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: var(--border);
      border-radius: 10px;
      overflow: hidden;
      width: 210px;
      height: 120px;
      > .play-icon {
        z-index: 1;
        cursor: pointer;
      }
    }
    &-thumb {
      cursor: pointer;
      width: 210px;
      height: 120px;
      transition: all 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      &.hover {
        opacity: 0.8;
      }
    }
    &-title {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>

<template>
  <div :class="theme === 'dark' ? 'dark-mode' : 'light-mode'">
    <LoadingScreen v-if="!paywallConfig" />
    <div v-else-if="isPricingPage || showColumns">
      <StiggProvider
        v-if="!withinCustomerPortal && teamId"
        :apiKey="apiKey"
        :theme="themeConfiguration"
        :customerId="teamId"
      >
        <div :class="`stigg-plan-interval-${billingPeriod.toLowerCase()}`">
          <Paywall v-if="paywallConfig" v-bind="paywallConfig" />
        </div>
      </StiggProvider>
      <Paywall v-if="withinCustomerPortal && paywallConfig" v-bind="paywallConfig" />
      <div v-if="!hasFreeSubscription && addons.length > 0">
        <h2 class="title-addons">Add-ons</h2>
        <div class="body-addons">
          <AddonColumn
            v-for="_addon in this.addons"
            :key="_addon.id"
            :addon="_addon"
            :interval="billingPeriod"
            :current-subscription="subscription"
            :country-code="countryCode"
            @select-addon="selectAddon(_addon)"
          />
        </div>
      </div>
    </div>
    <div v-if="!showColumns && !isPricingPage && paywallConfig" class="columns-controls">
      <button v-if="currentPlan !== 'Free'" data-cy="cancel-plan-cta" @click="() => cancelPlan(team)">
        Cancel Plan
      </button>
      <an-button data-cy="change-plan-cta" @click="toggleColumns" variant="secondary">Change Plan</an-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { applyReactInVue } from 'vuereact-combined';
import { StiggPlan, getCustomer, cancelUpdates, getStiggClient } from '@/services/stigg';
import AddonColumn from '@/components/Pricing/AddonColumn';
import { BillingPeriod } from '@stigg/js-client-sdk';
import { toastError } from '@/services/bus';
import { getUserLocalization } from '@/utils/billing';
import { StiggProvider, Paywall } from '@stigg/react-sdk';
import Sentry from '@/plugins/sentry';
import { trackEvent } from '@/services/tracking';
import LoadingScreen from '@/components/Loading/LoadingScreen.vue';

const DarkThemeConfig = {};

const LightThemeConfig = {};

export default {
  components: {
    StiggProvider: applyReactInVue(StiggProvider),
    Paywall: applyReactInVue(Paywall),
    AddonColumn,
    LoadingScreen
  },
  mounted() {
    if (this.teamId) {
      getUserLocalization()
        .then((localization) => {
          this.countryCode = localization?.country_code;

          if (this.countryCode) {
            this.$trackEvent('payment.localization-country-code.success', {
              country_code: this.countryCode
            });
            this.$trackUserProps({ country_code: this.countryCode });
          }

          this.setupPaywallConfig();
        })
        .catch(() => {
          this.setupPaywallConfig();
        });
    }
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      contributorsCount: 'teamMemberships/contributorsCountInTeam'
    }),
    teamId() {
      return this.team?.id;
    },
    hasFreeSubscription() {
      return !this.subscription || this.subscription?.plan.id === StiggPlan.FREE;
    },
    isPricingPage() {
      return this.$route.path.endsWith('/pricing');
    },
    productId() {
      return this.$route.query.productId || 'product-anima';
    }
  },
  methods: {
    async setupPaywallConfig() {
      this.retrieveAddons().then(() => {
        this.currentPlan = this.subscription?.plan?.displayName;
        this.paywallConfig = {
          productId: this.productId,
          preferredBillingPeriod: this.subscription?.price?.billingPeriod || BillingPeriod.Annually,
          highlightedPlanId: StiggPlan.PRO,
          billingCountryCode: this.countryCode,
          textOverrides: {
            planDescription: 'Manage plan',
            highlightChip: 'Popular',
            planCTAButton: {
              currentPlan: 'Current plan',
              startNew: 'Get plan',
              upgrade: 'Upgrade',
              downgrade: 'Get plan',
              custom: 'Contact us'
            },
            price: {
              custom: 'Custom',
              priceNotSet: 'Price not set'
            }
          },
          onBillingPeriodChange: (billingPeriod) => {
            this.billingPeriod = billingPeriod;
          },
          onPlanSelected: (params) => this.onPlanSelected(params, this.team)
        };
      });
    },
    async getAddons(paywall) {
      const hiddenAddonIds = [
        'addon-code-exports-starter',
        'addon-code-exports-pro',
        'addon-code-exports-business',
        'addon-anima-bonus-pack'
      ];
      const availableAddons =
        paywall.plans.find((plan) => plan.id === this.subscription?.plan?.id)?.compatibleAddons || [];
      return availableAddons.filter((addon) => hiddenAddonIds.includes(addon.id) === false);
    },
    getContactLink() {
      const contactLink = this.paywall?.plans.find((plan) => plan.id === 'plan-anima-custom')?.metadata?.contactLink;
      return contactLink ?? 'https://calendly.com/anima-team/sales';
    },
    async retrieveAddons() {
      const stigg = await getStiggClient();
      const customer = await getCustomer(this.team.id);
      this.subscription = customer.getActiveSubscriptions()[0];
      this.paywall = await stigg.getPaywall({ productId: this.productId });
      this.addons = await this.getAddons(this.paywall);
      this.billingPeriod = BillingPeriod.Annually;
    },
    async onPlanSelected({ plan, selectedBillingPeriod, intentionType }, team) {
      trackEvent('payment.plan-selected', {
        plan_id: plan.id,
        billing_period: selectedBillingPeriod,
        intention_type: intentionType
      });
      const query = {
        intentionType,
        interval: selectedBillingPeriod,
        plan: plan.id
      };

      switch (intentionType) {
        case 'UPGRADE_PLAN':
          this.confirmPayment(query);
          break;
        case 'DOWNGRADE_PLAN':
          plan.id === StiggPlan.FREE ? this.cancelPlan(team) : this.confirmPayment(query);
          break;
        case 'CHANGE_BILLING_PERIOD':
          this.confirmPayment(query);
          break;
        case 'CANCEL_SCHEDULED_UPDATES':
          await cancelUpdates(team.id)
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              Sentry?.captureException(err);
              toastError(`Failed to cancel an update. Error: ${err}`);
            });
          break;
        case 'REQUEST_CUSTOM_PLAN_ACCESS':
          this.$trackEvent('pricing.contact-us.click');
          window.open(this.getContactLink(), '_blank');
          break;
        default:
          this.confirmPayment(query);
          break;
      }
    },
    selectAddon(addon) {
      trackEvent('payment.addon-selected', {
        addon_id: addon.id,
        plan_id: this.subscription?.plan?.id
      });
      const query = {
        intention: 'UPGRADE_PLAN',
        interval: this.subscription?.price?.billingPeriod || BillingPeriod.Annually,
        plan: this.subscription?.plan?.id,
        addon: addon.id
      };
      this.confirmPayment(query);
    },
    confirmPayment(query) {
      this.$router.push({ name: 'team-payment-confirmation', query });
    },
    cancelPlan(team) {
      this.$router.push({ name: 'team-downgrade', params: { teamSlug: team.slug } }, () => {});
    },
    toggleColumns() {
      this.showColumns = !this.showColumns;
    }
  },
  props: ['theme', 'withinCustomerPortal'],
  data() {
    return {
      apiKey: process.env.STIGG_API_KEY,
      billingPeriod: this.subscription?.price?.billingPeriod || BillingPeriod.Annually,
      themeConfiguration: this.theme === 'dark' ? DarkThemeConfig : LightThemeConfig,
      paywallConfig: null,
      paywall: null,
      subscription: null,
      addons: [],
      showColumns: false,
      currentPlan: null,
      countryCode: 'US',
      currencyCode: 'USD'
    };
  },
  watch: {
    team({ id }) {
      if (id) {
        this.retrieveAddons();
      }
    }
  }
};
</script>

<style lang="scss">
.title-addons {
  font-family: 'Roslindale Deck';
  font-size: 36px;
  line-height: 50px;
  font-weight: 500;
  text-align: center;
  margin: 40px 0;
}

.body-addons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.light-mode {
  padding-top: 32px;
  width: 100%;

  .react-loading-skeleton {
    --base-color: rgba(0, 0, 0, 0.1) !important;
    --highlight-color: rgba(0, 0, 0, 0.2) !important;
  }

  .stigg-skeleton-loader-plans-container > div {
    background-color: #fff !important;
    border: 1px solid #d8d8d8 !important;
  }

  .stigg-monthly-period-text,
  .stigg-annual-period-text {
    color: rgb(43, 43, 43) !important;
  }

  .css-1rco36w-StyledSwitch {
    .react-switch-bg {
      background-color: #ff6250 !important;
    }

    .react-switch-handle {
      background-color: #fff !important;
      top: 3px !important;
      left: 3px;
      height: 15px !important;
      width: 15px !important;
    }
  }

  .stigg-plan-offering-container {
    background-color: #fff;
    color: rgb(43, 43, 43);
    border: 1px solid #d8d8d8 !important;
  }

  .stigg-plan-description,
  .stigg-plan-entitlements-title,
  .stigg-entitlement-name,
  .stigg-price-text,
  .stigg-plan-header,
  .stigg-starting-at-text,
  .stigg-price-text > p[class*='-PriceSpan'] {
    color: rgb(43, 43, 43) !important;
  }

  .stigg-price-unit-and-billing-period-text {
    min-height: 36px !important;
  }

  .stigg-price-text > span,
  .stigg-price-unit-and-billing-period-text > p,
  .stigg-price-text > p {
    color: #3b3b3b !important;
    opacity: 0.8;
  }

  .stigg-price-tier-select p {
    color: #3b3b3b !important;
  }

  svg[class*='PoweredByStiggThemedSvg'] path {
    fill: rgb(0 0 0 / 75%) !important;
  }
}

.dark-mode {
  background-color: rgb(43, 43, 43);
  padding-top: 90px;
  width: 100%;
}

ul.MuiMenu-list {
  background: #3b3b3b !important;
}
.css-1rco36w-StyledSwitch {
  .react-switch-handle {
    top: 1.5px !important;
  }

  .react-switch-bg {
    width: 32px !important;
  }
}

.stigg-skeleton-loader-billing-period {
  background: none !important;
  border: none !important;
}

.stigg-skeleton-loader-plans-container {
  gap: 30px !important;
}

.stigg-skeleton-loader-plans-container > div {
  background-color: #3b3b3b !important;
  border: none !important;
  box-sizing: content-box !important;
  border-radius: 15px !important;
  min-height: 727px !important;
}

.react-loading-skeleton {
  --base-color: rgba(255, 255, 255, 0.2) !important;
  --highlight-color: rgba(255, 255, 255, 0.3) !important;
}

.stigg-period-picker-container {
  background: none !important;
  border: none !important;
}

.stigg-discount-rate-text {
  font-family: Mulish !important;
  color: #ff6250 !important;
}

.stigg-discount-rate-text::before {
  content: 'up to ';
}

.stigg-price-tier-menu-item-text[aria-selected='true'] {
  background: #ff6250de !important;
}

.stigg-plan-header {
  font-family: Roslindale !important;
  font-size: 28px !important;
  line-height: 40px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
  color: #fff !important;
}

.stigg-plan-header::after {
  margin-top: 20px;
  display: block;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  border-bottom: 2px solid #c8c6c6;
  opacity: 0.2;
}

.stigg-plan-description {
  margin-top: 20px !important;
  min-height: 40px !important;
  margin-bottom: 12px !important;
}

.stigg-starting-at-text {
  margin-top: 4px !important;
}
.stigg-price-text {
  margin-bottom: 8px !important;
}

.stigg-plan-interval-annually {
  .stigg-plan-anima-pro {
    .stigg-plan-entitlements-container::after {
      content: '🎁   +10 bonus screens';
      font-weight: 700;
      height: 30px;
      font-size: 16px;
      line-height: 20px;
      font-family: Roslindale !important;
      white-space: pre-wrap;
    }
  }
}

.stigg-price-text,
.stigg-price-text > p[class*='-PriceSpan'] {
  font-family: Roslindale !important;
  color: #fff !important;
  font-weight: 700 !important;
  line-height: 80px !important;
  font-size: 60px !important;
  text-align: center;
}

.stigg-price-text > span,
.stigg-price-text > p[class*='-UnitSpan'] {
  font-family: Mulish !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #a3a8b8 !important;
  margin-left: 12px !important;
  text-transform: lowercase !important;
}

.stigg-price-billing-period-text {
  display: none !important;
}

.stigg-plan-header-divider {
  display: none !important;
}

.stigg-plan-entitlements-container {
  order: 1;
  gap: 18px !important;
  margin-bottom: 0 !important;
}

.stigg-plan-description,
.stigg-plan-entitlements-title,
.stigg-entitlement-name {
  color: white !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.stigg-entitlement-name {
  text-align: left;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.stigg-entitlement-row-icon path {
  fill: #009379 !important;
}

.stigg-plan-offering-container {
  border-radius: 15px !important;
  border: none !important;
  overflow: hidden !important;
  box-sizing: content-box !important;
}

.stigg-paywall-plan-button-layout {
  order: 0;
  margin-top: 0 !important;
  width: 100% !important;
}

.stigg-paywall-plan-button {
  background-color: #ff6250 !important;
  border-radius: 100px !important;
  width: 100% !important;
}

.stigg-scheduled-updates-alert-container .MuiGrid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stigg-paywall-schedule-update-text {
  margin-bottom: -38px !important;
  position: relative;
  top: -22px;
}

.stigg-paywall-plan-button-text {
  font-family: Roslindale !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.stigg-paywall-plan-button svg {
  display: none !important;
}

.stigg-highlight-badge {
  background-color: #009379 !important;
  border-radius: 0 !important;
  top: 32px !important;
  right: -47px !important;
  rotate: 45deg !important;
  width: 184px !important;
  height: 34px !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.stigg-highlight-badge-text {
  font-family: Roslindale !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.columns-controls {
  margin-top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.columns-controls button:active,
.columns-controls button:focus {
  outline: none;
  appearance: none;
}
</style>

<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <div class="container dark">
      <div class="title">Reset your password</div>
      <div class="subtitle">Hi again! Enter your new password and you’re good to go.</div>
      <div class="body">
        <div class="label">New password</div>
        <div class="input">
          <an-input
            type="password"
            v-model="password"
            :theme="theme"
            :invalid="!passwordValid"
            placeholder="Password"
            focus
          />
          <div class="invalid-message" v-if="!passwordValid">Password must contain at least 8 characters</div>
        </div>
        <div class="label">Confirm new password</div>
        <div class="input">
          <an-input
            type="password"
            v-model="confirmPassword"
            :theme="theme"
            :invalid="!confirmPasswordValid"
            placeholder="Password"
          />
          <div class="invalid-message" v-if="!confirmPasswordValid">
            {{ errorMessage }}
          </div>
        </div>
        <transition name="fadeIn">
          <div class="actions" v-if="password && confirmPassword">
            <an-button @click="submit" :isWorking="isWorking">Reset password</an-button>
          </div>
        </transition>
        <div class="link">
          <an-link @click="navigateToLogin" :theme="theme">Return to log in</an-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import api from '@/api';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordValid: true,
      confirmPasswordValid: true,
      showButton: false,
      errorMessage: '',
      isWorking: false,
      theme: 'dark'
    };
  },
  components: {
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
  },
  methods: {
    validatePassword() {
      this.passwordValid = this.password?.length >= 8;
    },
    validateConfirmation() {
      this.confirmPasswordValid = this.confirmPassword === this.password;
      if (!this.passwordValid) {
        this.errorMessage = '';
      } else if (this.confirmPassword !== this.password) {
        this.errorMessage = 'Is this the same password though?';
      }
    },
    isValid() {
      this.validatePassword();
      this.validateConfirmation();
      return this.passwordValid && this.confirmPasswordValid;
    },
    async submit() {
      try {
        this.isWorking = true;
        const { password } = this;
        const { token } = this.$route.params;

        this.$trackEvent('reset-password.reset-password-button.click');

        if (this.isValid()) {
          await api.post('/recover', { token, password });
          this.$trackEvent('reset-password.reset-password.success');
          this.$router.replace({ name: 'login', query: this.$route.query });
        }
      } catch (err) {
        const { response } = err;
        const message = response?.data?.message || "We couldn't save your changes...";
        this.$trackEvent('reset-password.reset-password.failure');
        if (this.isDesktop) toastError(message);
      } finally {
        this.isWorking = false;
      }
    },
    navigateToLogin() {
      this.$trackEvent('forgot-password.navigate-to-login.click');
      this.$router.replace({ name: 'login', query: this.$route.query });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  margin-top: 160px;
  max-width: 100%;
  @include mobile {
    margin-top: 110px;
  }
  .subtitle {
    @include desktop {
      margin: 20px 0 0;
      width: 100%;
    }
  }
  .body {
    margin: 40px auto;
    max-width: 444px;
  }
}
.input + .link {
  margin-top: 40px;
}
.link {
  text-align: center;
  margin: 20px 0 0;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
.actions {
  margin-top: 40px;
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button.an-button {
    @include mobile {
      width: 100%;
      border-radius: 0;
    }
  }
}
.invalid-message {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  color: var(--red);
}
</style>

<template>
  <div class="current-settings" @click="openCodePrefsPanel">
    <div class="selected_framework">
      <div class="selected-title small-text">Selected Framework</div>
      <div class="framework_settings">
        <div class="framework">{{ frameworkLabel }}</div>
        <p class="selected-options small-text">({{ frameworkAdditionalInfoLabel }})</p>
      </div>
    </div>
    <div class="open-codeprefs" data-cy="open-code-prefs">
      <img src="@/assets/svg/settings-icon.svg" alt="Change framework" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SelectedFramework',
  computed: {
    ...mapGetters({
      frameworkLabel: 'codePreferences/frameworkLabel',
      frameworkAdditionalInfoLabel: 'codePreferences/frameworkAdditionalInfoLabel'
    })
  },
  methods: {
    ...mapMutations({
      setIsCodePreferencesRequested: 'omniview/setIsCodePreferencesRequested'
    }),
    openCodePrefsPanel() {
      this.$trackEvent('omniview.export-code.open-pref-panel');
      this.setIsCodePreferencesRequested(true);
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.current-settings {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid var(--dark-onboarding-background);

  &:hover {
    background: #333333;
  }
  .small-text {
    opacity: 0.4;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }

  .open-codeprefs {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 10px;
    border-radius: 50%;

    img {
      opacity: 0.4;
      transition: all 0.1s;
    }
  }
  .selected_framework {
    .framework_settings {
      display: flex;
    }
    .framework {
      margin-right: 4px;
      line-height: 24px;
    }
    .selected-options {
      transform: translateY(4px);
    }
  }
}
</style>

<template>
  <span data-cy="update-payment-banner">
    A payment is required for your subscription to remain active.&nbsp;
    <an-link @click="onUpdatePaymentClick" variant="primary">
      <span>Update payment</span>
    </an-link>
  </span>
</template>

<script>
import { TeamMixin } from '@/mixins';

export default {
  name: 'update-payment-banner',
  mixins: [TeamMixin],
  props: {
    paymentUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$trackEvent('update-payment-banner.show');
  },
  methods: {
    onUpdatePaymentClick() {
      this.$trackEvent('update-payment-banner.click');
      window.open(this.paymentUrl, '_blank');
    }
  }
};
</script>

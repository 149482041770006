<template>
  <transition name="fadeHeight">
    <div :style="bannerStyle" v-if="show && isDesktop">
      <an-banner v-bind="props" @close="onClose({ isClickEvent: true })">
        <component :is="componentName" v-bind="props" />
      </an-banner>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      componentName: '',
      props: {},
      doNotShowAgain: false,
      customOnClose: null
    };
  },
  mounted() {
    EventBus.$on('show-banner', this.showBanner);
    EventBus.$on('hide-banner', this.onHide);
  },
  destroyed() {
    EventBus.$off('show-banner', this.showBanner);
    EventBus.$off('hide-banner', this.onHide);
  },
  computed: {
    ...mapState('webappSystem', ['bannerWebAppHeight']),
    bannerStyle() {
      return { height: `${this.bannerWebAppHeight}px` };
    }
  },
  methods: {
    ...mapMutations('webappSystem', ['setIsBannerOpen', 'setBannerHeight']),
    showBanner({ name, onClose, ...props }) {
      if (this.doNotShowAgain) return;
      this.componentName = name;
      this.props = props;
      this.show = true;
      this.customOnClose = onClose;
      this.$trackEvent('webapp.banner.show', { name });
      this.setBannerHeight(props.size === 'big' ? 80 : 60);

      this.setIsBannerOpen(true);
    },
    onHide({ name, onClose } = {}) {
      if (!name || name === this.componentName) {
        if (onClose) {
          this.customOnClose = onClose;
        }
        this.onClose();
      }
    },
    onClose() {
      if (this.show) {
        this.$trackEvent('webapp.banner.close');
        this.show = false;
        if (this.customOnClose) {
          this.customOnClose();
        }
      }
      this.componentName = '';
      this.props = {};
      this.customOnClose = null;

      this.setIsBannerOpen(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s ease;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>

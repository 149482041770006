<template>
  <div class="pricing-row" :class="{ selected: isCurrentPlan, disabled }" @click="onClick">
    <div class="content">
      <div class="plan-type">{{ planType }}</div>
      <div class="price-container">
        <div class="price">{{ formattedPrice }}</div>
        <div v-if="screenQty && 0" class="per-amount">/ for up to {{ screenQty }} screens</div>
        <div v-else-if="isFlatFee" class="per-amount">/ mo</div>
        <div v-else class="per-amount">/ mo per contributor</div>
      </div>
      <div v-if="priceTags.length" class="price-badges-container">
        <Badge v-for="tag in priceTags" :key="tag" :name="tag" />
      </div>
    </div>
    <div class="ribbon" v-if="showRibbon">
      <svg-icon name="save-money-ribbon" :width="109" :height="100" />
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/billing';
import Badge from '@/components/Pricing/Badge';

export default {
  props: {
    planName: {
      type: String,
      validator: (v) => ['Basic', 'Pro', 'ProScreens', 'Team', 'Business', null].includes(v)
    },
    planInterval: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    isCurrentPlan: {
      type: Boolean,
      required: true
    },
    isFlatFee: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showRibbon: {
      type: Boolean,
      default: false
    },
    planCurrency: {
      type: String
    },
    screenQty: {
      type: Number,
      default: null
    },
    priceTags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    formattedPrice() {
      return formatPrice(this.price, {
        currency: this.planCurrency
      });
    },
    planType() {
      const { planName, planInterval } = this;
      const interval = planInterval === 'annually' ? 'Annual plan' : 'Monthly plan';
      if (!planName) return interval;
      return `${planName} - ${interval}`;
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  },
  components: {
    Badge
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.pricing-row {
  display: flex;
  justify-content: space-between;
  border: var(--border);
  border-radius: 10px;
  cursor: pointer;
  .content {
    margin-left: 30px;
  }
  .ribbon .svg-container svg {
    border-radius: 0 0 10px;
  }
  &:hover,
  &.selected {
    border: var(--primary-border);
  }
  &.disabled {
    opacity: 0.4;
  }
  + .pricing-row {
    margin-top: 20px;
  }
}
.plan-type {
  font-weight: bold;
  margin-top: 10px;
}
.price-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.price {
  @include headline;
  margin: 6px 6px 0 0;
}
.per-amount {
  font-size: 14px;
  margin-bottom: 6px;
}

.price-badges-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 4px;
}
</style>

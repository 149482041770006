<template>
  <transition-group name="slide-stage">
    <Stage
      v-for="stage in stages"
      :key="stage.index"
      :theme="theme"
      :open="stage.index === selectedIndex"
      :done="activeIndex > stage.index"
      :stage="stage"
      :eventProps="eventProps"
      :activeStage="stage.index === activeIndex"
      :inOmniview="inOmniview"
      @click:stage="$emit('click:stage', stage)"
      @click:cta="$emit('click:cta', $event)"
      @click:skip="$emit('click:skip', stage)"
    />
  </transition-group>
</template>

<script>
import Stage from '@/components/PersonalizedOnboarding/Stage';
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      default: 'dark',
      validator: (v) => ['dark', 'light'].includes(v)
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    eventProps: {
      type: Object,
      default: () => ({})
    },
    inOmniview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Stage
  },
  computed: {
    stages() {
      if (this.inOmniview) {
        return this.list.filter((stage) => stage.index >= this.activeIndex);
      }
      return this.list;
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-stage-enter-active,
.slide-stage-leave-active {
  transition: all 0.2s;
  overflow: hidden;
}
.slide-stage-enter,
.slide-stage-leave-to {
  transform: translateX(10px);
  opacity: 0;
  overflow: hidden;
}
</style>

<template>
  <div v-if="false" class="pricing-features">
    <Collapse class="pricing-features__headline" title="Detailed Features" open>
      <table v-if="!loading" v-observe-visibility="{ callback: onPricingFeaturesVisible, once: true }">
        <thead :class="[theme]" :style="theadStyle">
          <tr class="pricing-features__headers">
            <th></th>
            <th @click="selectPlan('Free')">
              <h3>Free</h3>
              <a href="https://projects.animaapp.com/payment?plan=free&interval=annually">Start now</a>
            </th>
            <th @click="selectPlan('Pro')">
              <h3>Pro</h3>
              <a href="https://projects.animaapp.com/payment?plan=pro&interval=annually">Start now</a>
            </th>
            <th @click="selectPlan('Business')" v-if="showBusinessPlan">
              <h3>Business</h3>
              <p>Start now</p>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(rows, key) in data">
            <tr class="pricing-features__subheader" :key="rows.length + key">
              <td>{{ key }}</td>
              <td></td>
              <td></td>
              <td v-if="showBusinessPlan"></td>
            </tr>

            <tr
              v-for="(row, index) in rows"
              class="pricing-features__row"
              :class="{ last: index === rows.length - 1 }"
              :key="row[1] + index"
            >
              <td>{{ row[1] }}</td>
              <td v-if="row[2].includes('v')">
                <svg-icon name="checked" :size="30" style="color: #f8d57e" />
              </td>
              <td v-else-if="row[2].includes('x')">
                <svg-icon name="close" :size="30" style="color: #999999" />
              </td>
              <td v-else>{{ row[2] }}</td>
              <td v-if="row[3].includes('v')">
                <svg-icon name="checked" :size="30" style="color: #ffaca3" />
              </td>
              <td v-else-if="row[3].includes('x')">
                <svg-icon name="close" :size="30" style="color: #999999" />
              </td>
              <td v-else>{{ row[3] }}</td>
              <td v-if="row[4].includes('v') && showBusinessPlan">
                <svg-icon name="checked" :size="30" style="color: #009379" />
              </td>
              <td v-else-if="row[4].includes('x') && showBusinessPlan">
                <svg-icon name="close" :size="30" style="color: #999999" />
              </td>
              <td v-else-if="showBusinessPlan">{{ row[4] }}</td>
            </tr>
            <tr style="height: 50px" :key="key"></tr>
          </template>
        </tbody>
      </table>
    </Collapse>
  </div>
</template>

<script>
import api from '@/api';
import { groupBy } from 'lodash-es';
import Collapse from '@/components/Collapse/Collapse.vue';

export default {
  data() {
    return {
      data: [],
      loading: false
    };
  },
  components: {
    Collapse
  },
  props: {
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value)
    },
    topmost: {
      type: Number,
      default: 0
    },
    hideBusinessPlan: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.fetchPlans();
  },
  computed: {
    theadStyle() {
      return {
        top: this.topmost + 'px'
      };
    },
    showBusinessPlan() {
      return !this.hideBusinessPlan;
    }
  },
  methods: {
    async fetchPlans() {
      try {
        const res = await api.get('/get_detailed_payment_plans');
        this.data = groupBy(res.data.body, 0);
      } catch (error) {
        console.error(error);
      }
    },
    selectPlan(name) {
      this.$emit('select-plan', { name, source: 'pricing-features' });
    },
    onPricingFeaturesVisible(isVisible) {
      if (isVisible) {
        this.$trackEvent('webapp.pricing-table.visible');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.last {
  border-bottom: none !important;
}
.pricing-features {
  font-family: 'Roslindale';
  width: 100%;
  margin-top: 135px;
  margin-bottom: 135px;

  table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
  }

  thead {
    position: sticky;
    top: 80px;
    background-color: white;
    &.dark {
      background-color: var(--dark-onboarding-background);
    }
    th:not(:empty) {
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    width: 335px;

    table {
      text-align: center;
    }
  }
}
@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
}
.pricing-features__headline {
  ::v-deep.collapse-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    margin-bottom: 54px;
    margin-inline: 1.5rem;
    border-top: unset;
    border-bottom: unset;
    justify-content: unset;

    @media (max-width: 768px) {
      font-size: 46px;
      line-height: 62px;
      margin-bottom: 50px;
    }
  }
}

.pricing-features__headers {
  height: 125px;

  th {
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;

    a {
      margin-top: 13px;
      font-size: 1.25rem;
    }
  }

  th:nth-child(2) {
    a {
      color: #f8d57e;
    }
  }

  th:nth-child(3) {
    a {
      color: #ffaca3;
    }
  }

  th:nth-child(4) {
    a {
      color: #009379;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    th:first-of-type {
      display: none;
    }
  }
}
.pricing-features__row {
  height: 90px;
  border-bottom: 1px solid rgba(59, 59, 59, 0.2);

  td:first-child {
    padding-top: 29px;
    padding-left: 33px;
    padding-bottom: 32px;
    text-align: left;

    @media (max-width: 768px) {
      padding: 1rem;
    }

    @include mobile {
      text-align: center !important;
    }
  }

  td:not(:first-child) {
    text-align: center;
    .svg-container {
      margin-inline: auto;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 160px;

    td:first-of-type {
      flex: 1 100%;
      text-align: left;
      font-size: 17px;
      line-height: 24px;
    }
    td:not(:first-of-type) {
      flex: 1;
    }
  }
}
.pricing-features__subheader {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: 29px;
  text-align: left;

  td:first-of-type {
    padding: 29px 0 32px 33px;

    @include mobile {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    td:first-of-type {
      padding: 2rem;
    }
    td:not(:first-of-type) {
      display: none;
    }
  }
}
</style>

<template>
  <span class="select-container" tabindex="-1">
    <div
      class="select"
      :class="{ empty: isEmpty(value) }"
      :style="{ width: isEmpty(value) ? width : 'auto' }"
      @click="togglePopover"
    >
      {{ displayLabel }}
      <span v-if="value.emoji" class="emoji">
        <an-emoji :name="value.emoji" />
      </span>
    </div>
    <Popover
      :isOpen="isOpen"
      popperClass="popover-customize"
      :style="{ width }"
      :theme="theme"
      position="left"
      @close="close"
    >
      <div class="multi" v-if="multiSelect">Select one or more..</div>
      <div v-for="item in items" :key="item.label" class="option">
        <an-link
          :theme="theme"
          :variant="isMarked(item) ? 'primary' : 'secondary'"
          @click="onClick(item)"
          :data-cy="item.datacy"
          primaryHover
        >
          {{ item.label }}
        </an-link>
      </div>
    </Popover>
  </span>
</template>

<script>
import { isArray, isEmpty } from 'lodash-es';
import Popover from '@/components/Popovers/Popover';
export default {
  data() {
    return {
      popoverOpen: false
    };
  },
  props: {
    value: {
      type: [Object, Array]
    },
    items: {
      type: Array,
      required: true
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'dark',
      validator: (value) => ['dark', 'light'].includes(value)
    },
    width: {
      type: String
    }
  },
  components: {
    Popover
  },
  computed: {
    displayLabel() {
      if (isArray(this.value)) {
        return this.value.join(', ');
      }
      return this.value.displayValue || this.value.value;
    },
    isMarked() {
      return ({ value }) => {
        if (isArray(this.value)) {
          return this.value.includes(value);
        }
        return this.value.value === value;
      };
    },
    popoverClasses() {
      return ['popover-customize', this.theme];
    }
  },
  methods: {
    isEmpty,
    onClick(item) {
      this.$emit('choice', item);
    },
    togglePopover() {
      if (this.isOpen) this.close();
      else this.$emit('open');
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.select-container {
  display: inline;
  position: relative;
  outline: none;
}
.emoji {
  margin: 0 5px;
}
.select {
  display: inline;
  font-size: 32px;
  line-height: 54px;
  color: var(--primary);
  cursor: pointer;
  margin-left: 5px;
  &.empty {
    display: inline-block;
    height: 40px;
    border-bottom: 2px solid var(--primary);
  }
  @include mobile {
    font-size: 20px;
    line-height: 40px;
  }
}
.option {
  & + .option {
    margin-top: 20px;
  }
}
.popover-customize.dark {
  background-color: var(--secondary);
  @include mobile {
    font-size: 16px;
    line-height: 24px;
  }
}
.popover-customize,
.select.empty {
  width: 270px;
  @include mobile {
    width: 225px;
  }
}
.dark {
  .multi {
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 20px;
  }
}
</style>

<template>
  <div class="collapse" :class="{ separate: isOpen }">
    <div class="collapse-header" @click="toggleOpen">
      {{ title }}
      <svg-icon name="select-arrow-down" :class="{ icon: true, isOpen }" fill="currentColor" :size="30" />
    </div>
    <transition name="collapse">
      <div v-if="isOpen">
        <slot ref="slot" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 30px 0;
  border-top: var(--border);
  border-bottom: var(--border);
}
.collapse:not(.separate) + .collapse {
  .collapse-header {
    border-top: none;
  }
}
.icon {
  transition: 0.5s ease-in-out;
}
.isOpen {
  transform: rotate(180deg);
}
.collapse-enter-active,
.collapse-leave-active {
  transition: opacity 0.2s ease-out;
}
.collapse-enter,
.collapse-leave-to {
  opacity: 0;
}
</style>

import { isDev } from '@/utils/environment';
import api from '@/api';

export const checkAppStatus = async () => {
  if (isDev()) return { status: 'ok' };

  try {
    await api.get('/up');
    const res = await fetch('https://32ry8y9mbwg7.statuspage.io/api/v2/summary.json');
    const { status } = await res.json();

    if (!status?.indicator || status.indicator === 'none') {
      return { status: 'ok' };
    }
    return { status: 'problem' };
  } catch (err) {
    if (['Failed to fetch', 'Network Error'].includes(err.message) || err.status === 0) {
      return {
        status: 'problem',
        props: {
          text: 'Sorry, our application server is down. Please try again later.'
        }
      };
    }
    err;
  }
  return { status: 'ok' };
};

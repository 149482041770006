<template>
  <div class="checkout">
    <div class="checkout-title">Check out</div>
    <Loader v-if="isLoading" />
    <div v-else>
      <div class="checkout-list">
        <div v-for="line in checkoutDetails" :key="line.label" class="checkout-list-item">
          <svg-icon name="circle-check" :size="20" />
          <div :class="['checkout-list-item-text', { customized: line.customized }]">{{ line.label }}</div>
        </div>
      </div>
      <div v-if="isDowngrade">
        <div class="price-container">
          <span>Balance before change</span>
          <span class="price">{{ formattedPrice(priceBreakdown.credit) }}</span>
        </div>
        <div class="price-container">
          <span>Total</span>
          <div>
            <div v-if="!isLoadingPrice" class="price">{{ formattedPrice(priceBreakdown.total) }}</div>
            <InlineLoader class="price-loader" v-else />
          </div>
        </div>
        <div class="price-container">
          <span>Balance after change</span>
          <span class="price">{{ formattedPrice(priceBreakdown.netAmount) }}</span>
        </div>
      </div>
      <div v-else>
        <div v-if="priceBreakdown.discounts?.length">
          <div class="price-container">
            <span>Base price</span>
            <span class="price">{{ formattedPrice(priceBreakdown.basePrice) }}</span>
          </div>
          <div v-for="discount in priceBreakdown.discounts" :key="discount.description" class="price-container">
            <span>{{ discount.description }}</span>
            <span class="price">{{ formattedPrice(discount.amount) }}</span>
          </div>
          <div class="separator"></div>
        </div>
        <div class="price-container">
          <span style="align-self: flex-start">Total</span>
          <div>
            <div v-if="!isLoadingPrice" class="total-price">
              <span class="price">{{ formattedPrice(priceBreakdown.total) }}</span>
              <span class="price-interval">{{ intervalLabel }}</span>
            </div>
            <InlineLoader v-else class="price-loader" />
          </div>
        </div>
      </div>
      <div class="cta">
        <div>
          <an-button data-cy="purchase-cta-btn" @click="purchase" :isWorking="isWorking" :disabled="isLoadingPrice">
            {{ ctaText }}
          </an-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loading/DefaultLoader';
import InlineLoader from '@/components/Loading/InlineLoader';
import { formatPrice } from '@/utils/billing';
import { BillingPeriod } from '@stigg/js-client-sdk';

export default {
  name: 'CheckoutForm',
  components: {
    Loader,
    InlineLoader
  },
  props: {
    isLoading: Boolean,
    isLoadingPrice: Boolean,
    isDowngrade: Boolean,
    checkoutDetails: Array,
    ctaText: String,
    isWorking: Boolean,
    priceBreakdown: Object,
    currency: String,
    interval: String
  },
  computed: {
    intervalLabel() {
      return this.interval === BillingPeriod.Annually ? 'Billed yearly' : 'Billed monthly';
    }
  },
  methods: {
    purchase() {
      this.$emit('purchase');
    },
    formattedPrice(price) {
      return formatPrice(price, {
        currency: this.currency
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
.checkout {
  background: var(--light-container-background);
  width: 330px;
  padding: 30px;
  border-radius: 10px;
  margin-top: 36px;

  &-title {
    @include secondary-title;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }

  &-list {
    padding: 0 0 30px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }

  &-list-item {
    display: flex;
    align-items: flex-start;

    .svg-container {
      margin: 4px 14px 0 0;
    }

    &-text {
      flex: 1;

      &.customized {
        font-weight: 800;
      }
    }

    + .checkout-list-item {
      margin-top: 20px;
    }
  }
}
.price-loader ::v-deep .dot {
  width: 6px;
  height: 6px;
  background-color: var(--secondary-text);
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;

  .price {
    @include secondary-title;
    font-weight: 800;
  }
}

.total-price {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .price-interval {
    opacity: 0.6;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
}
.cta {
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: var(--border);
}
</style>

<template>
  <div class="screen-card" data-cy="screen-card">
    <slot name="overlay" />
    <div class="thumbnail" :style="thumbStyle" @mouseover="hoverThumb = !locked" @mouseout="hoverThumb = false">
      <div class="locked" v-if="locked" @click="handleClick(screen)">
        <div><svg-icon name="white-lock" :size="26" fill="white" /></div>
        <div>Screen locked</div>
      </div>
      <div :class="actionsClasses" @click="handleClick(screen)">
        <div v-if="!readOnly" class="popover-menu-wrapper">
          <Checkbox :value="selected" @input="select" variant="dark" />
          <PopoverMenu :items="popoverActions" position="right" v-if="popoverActions.length && !linkedScreen">
            <div slot="reference">
              <svg-icon fill="currentColor" style="color: #fff" name="three-dots-horizontal" :size="30" />
            </div>
          </PopoverMenu>
        </div>
        <div class="actions-text" v-if="!linkedScreen">
          <slot name="cta" v-if="this.$slots.cta" />
          <span v-else> View screen </span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        {{ screen.name }}
      </div>
      <div class="subtitle">
        <slot name="subtitle" v-if="this.$slots.subtitle" />
        <template v-else>
          {{ lastUpdated }}
          <svg-icon v-if="this.flags.hasNewComments" name="talking-bubble-alert" :size="24" />
          <svg-icon v-if="this.flags.home" name="rocket" :size="24" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import Checkbox from '@/components/Checkbox/Checkbox';
import { formatDateTimeConversational, formatDateTimeShort } from '@/utils/date';
import { getCdnUrl } from '@/utils/urls';
import { EventBus } from '@/services/bus';
import { TeamMixin } from '@/mixins';
export default {
  mixins: [TeamMixin],
  data() {
    return {
      hoverThumb: false
    };
  },
  components: {
    Checkbox,
    PopoverMenu
  },
  props: {
    locked: {
      type: Boolean,
      require: false
    },
    screen: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    flags: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean,
      default: false
    },
    linkedScreen: {
      type: Boolean,
      default: false
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      isPro: 'teamMemberships/isPro'
    }),
    isRegularPro() {
      return this.isPro && !this.team?.uses_stigg_integration;
    },
    thumbStyle() {
      const { thumb_url } = this.screen;
      const thumbUrl = getCdnUrl(thumb_url, { width: 280, height: 140 });
      const background = thumbUrl ? `url("${thumbUrl}") no-repeat` : 'var(--light-container-background)';
      return {
        background,
        'background-size': '100%'
      };
    },
    actionsClasses() {
      const { hoverThumb, screen } = this;
      const show = hoverThumb || screen.isSelected;
      return { actions: true, show };
    },
    popoverActions() {
      const { screen, actions } = this;
      return actions.map((action) => ({
        ...action,
        onClick: () => action.onClick(screen)
      }));
    },
    lastUpdated() {
      const { updated_at, linkedScreens } = this.screen;
      if (linkedScreens?.length > 0) {
        const linkedCounter = `${linkedScreens.length + 1} breakpoints`;
        return `${linkedCounter} · ${formatDateTimeShort(updated_at)}`;
      }
      return formatDateTimeConversational(updated_at);
    },
    lastUpdateInfo() {
      if (this.screen.plugin_name && this.screen.owner_name) {
        return `Last updated by ${this.screen.owner_name} from ${this.screen.plugin_name}`;
      }
      return '';
    }
  },
  methods: {
    handleClick(screen) {
      if (this.locked) {
        this.$trackEvent('project-page.locked-screen-paywall.show');
        const content = this.isPro
          ? 'Your team has reached the screens limit on the current plan.'
          : 'Your team has run out of screen on the free plan.\nUpgrade to unlock more!';
        this.openUpgradeLockedScreensModal({
          content,
          title: 'Unlock more screens',
          ctaTitle: this.isRegularPro ? 'Schedule a call' : 'See plans',
          illustrationType: this.isPro ? 'gauge' : 'clock',
          calendlyLink: this.isRegularPro
            ? 'https://calendly.com/anima-demo/adjust-plan?source=anima-web&banner=pro-screens&type=card'
            : null
        });
      } else {
        this.$emit('click-thumb', screen);
      }
    },
    select() {
      EventBus.$emit('select-card-item', this.screen);
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-card {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  min-height: 240px;
  max-height: 256px;
  box-shadow: var(--shadow);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.thumbnail {
  border-radius: 10px 10px 0 0;
  height: 140px;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.locked {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3b3b3b;
  opacity: 0.9;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  background-color: var(--card-background);
  padding: 20px 16px;
  border-radius: 0 0 10px 10px;
  cursor: default;
}
.title {
  @include secondary-title;
  @include ellipsis;
  font-size: 16px;
  margin-bottom: 14px;
  width: 100%;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 150px;
  background-color: rgba(59, 59, 59, 0.95);
  border-radius: 10px 10px 0 0;
  color: #ffffff;
  transition: all 0.2s ease-in;
  padding: 16px;
  cursor: pointer;
  opacity: 0;
  &.linked-screen {
    background-color: none;
  }
  &.show {
    opacity: 1;
  }
  .actions-text {
    margin: 20px 0;
  }
  .popover-menu-wrapper {
    justify-self: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>

<template>
  <div class="team-row-container">
    <div class="team-row">
      <div class="team-logo"><RoundedImage :src="getLogo(team)" dashedEmptyState /></div>
      <div>
        <div class="team-name">{{ team.name }}</div>
        <div class="team-projects">{{ numberOfProjects }}</div>
      </div>
    </div>
    <div class="members" v-if="members">
      <Members :members="members" :peekAmount="5" :size="30" />
    </div>
  </div>
</template>

<script>
import RoundedImage from '@/components/Upload/RoundedImage';
import Members from '@/components/Members/Members';
import { getCdnUrl } from '@/utils/urls';

export default {
  props: {
    team: {
      type: Object,
      required: true
    },
    members: {
      type: Array
    }
  },
  components: {
    RoundedImage,
    Members
  },
  computed: {
    numberOfProjects() {
      const { active_projects_count } = this.team;
      const plural = active_projects_count === 1 ? '' : 's';
      return `${active_projects_count} project${plural}`;
    }
  },
  methods: {
    getLogo({ logo_url }) {
      return getCdnUrl(logo_url, { size: 50 }) || require('@/assets/svg/empty-team-icon.svg');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.team-row {
  display: flex;
  align-items: center;
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .team-logo {
    margin-right: 20px;
  }
  .team-name {
    @include secondary-title;
  }
}
</style>

<template>
  <section class="container dark">
    <TopBar @close="onClose" closeButton />

    <div class="illustration">
      <img src="@/assets/illustrations/quirky-clock-dark.svg" width="200" height="120" />
    </div>

    <h1 class="title">Oops, too many devices logged in</h1>

    <p class="body">
      Only 2 devices can be linked to an account.<br />
      Log out from other devices or add more users.
    </p>

    <div class="actions">
      <an-button variant="primary" @click="addMoreUsers">Add more users</an-button>
      <an-link theme="dark" @click="logoutOtherDevices">Log me out from other devices</an-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import TopBar from '@/components/Onboarding/TopBar';
import errorHandler from '@/services/errorHandler';
import auth from '@/auth';

export default {
  name: 'devices-limit-reached',
  data() {
    return {
      theme: 'dark'
    };
  },
  computed: {
    ...mapState('devices', { devices: 'items' }),
    ...mapState('users', { user: 'currentItem' })
  },
  components: {
    TopBar
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
    this.fetchTeamData();
  },
  methods: {
    ...mapMutations('devices', ['removeFromItems']),
    ...mapActions('devices', {
      createDevice: 'createDevice',
      deleteDevice: 'delete',
      fetchAllDevices: 'fetchAllOfParent'
    }),
    ...mapActions('teams', {
      fetchTeam: 'fetchOne'
    }),
    fetchTeamData() {
      this.fetchTeam({ id: this.user.default_team_slug, params: { is_slug: true } });
    },
    addMoreUsers() {
      this.$trackEvent('devices-limit-reached.add-more-users.click');
      this.$router.push({ name: 'team-members', params: { teamSlug: this.user.default_team_slug } }, () => {});
    },
    onClose() {
      this.$trackEvent('devices-limit-reached.close-button.click');
      auth.logout();
      this.$router.push({ name: 'login' }, () => {});
    },
    async logoutOtherDevices() {
      try {
        this.$trackEvent('devices-limit-reached.logout-other-devices.click');

        await Promise.all(
          this.devices.map((device) => {
            this.removeFromItems(device);
            return this.deleteDevice(device);
          }),
          this.createDevice()
        );

        await this.fetchAllDevices({ id: 'me', parent: 'users', skipCache: true });
        this.$trackEvent('devices-limit-reached.logout-other-devices.success');

        this.$router.push({ name: 'root' }, () => {});
      } catch (error) {
        errorHandler.captureExceptionAndTrack(error, { name: 'devices-limit-reached.logout-other-devices.failure' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.illustration {
  img {
    aspect-ratio: 3/2;
    height: unset;
  }
}
.body {
  text-align: center;
}
</style>

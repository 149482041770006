<template>
  <VueIfBot>
    <transition name="slide-fade">
      <div v-if="shouldShowConsent" class="cookie-consent" role="dialog">
        <button class="close-button" @click="close">
          <svg-icon name="close" />
        </button>
        <p class="text">
          <strong>We only have to do this once!</strong> We use cookies to give you the best experience. To learn more
          about our cookies, check out our
          <a
            class="link--primary"
            href="https://www.animaapp.com/tos/TOS.pdf#page=9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>

        <section class="buttons">
          <an-button size="sm" @click="accept">Accept</an-button>
          <an-button variant="secondary" size="sm" @click="learnMore">Learn more</an-button>
        </section>
      </div>
    </transition>
  </VueIfBot>
</template>

<script setup>
import VueIfBot from 'vue-if-bot';
import { useStore } from '@/store';
import { computed } from 'vue';

const { dispatch, getters } = useStore();
const shouldShowConsent = computed(() => getters['consent/shouldShowConsent']);

const accept = () => {
  dispatch('consent/grantConsent');
};

const learnMore = () => {
  window.open('http://cookiesandyou.com/', '_blank');
};

const close = () => {
  dispatch('consent/denyConsent');
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

.cookie-consent {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 40px;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: #3b3b3b;
  color: white;
  font-family: 'Mulish', sans-serif;
  font-size: 15px;
  line-height: 25px;
  z-index: 9999;
  gap: 32px;

  .text {
    flex: 1 1 auto;
    margin-bottom: 0px;
    a {
      color: var(--primary);
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .close-button {
      align-self: flex-start;
    }
  }
}
</style>

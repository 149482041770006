<template>
  <div class="container">
    <LoadingScreen v-if="loading" />
    <div v-else>
      <div class="title">{{ title }}</div>
      <div class="subtitle">
        {{ subtitle }}
      </div>
      <div class="actions">
        <an-button @click="$router.push({ name: 'root' })">Continue to Anima</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import api from '@/api';
import errorHandler from '@/services/errorHandler';

export default {
  data() {
    return {
      hasError: false,
      loading: false
    };
  },
  components: {
    LoadingScreen
  },
  mounted() {
    this.onMounted();
  },
  computed: {
    title() {
      return this.hasError ? 'Something went wrong... 😕' : "You're all set! 🎉";
    },
    subtitle() {
      return this.hasError ? "We couldn't verify it's your email... Try again?" : 'Your new email is now verified.';
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      fetchPairingToken: 'pairingTokens/fetchOne',
      updatePairingToken: 'pairingTokens/update'
    }),
    async onMounted() {
      this.loading = true;
      try {
        await this.verifyEmail();
        await this.fetchUserData();
      } catch (err) {
        errorHandler.captureException(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchUserData() {
      const { email } = await this.getUser({ id: 'me' });
      this.email = email;
    },
    async verifyEmail() {
      try {
        const { token } = this.$route.params;
        const params = { type: 'email', token };
        await api.post('/users/me/verify', params);
        this.$trackEvent('verify-email.success');
      } catch (err) {
        this.hasError = true;
        this.$trackEvent('verify-email.failure', { message: err.message });
        throw err;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>

import { get, set, clear, del } from 'idb-keyval';
import md5 from 'object-hash';

class CodegenCache {
  constructor() {}

  init() {
    this.clearAll();
  }

  async setByKey(key, data, { parse = true } = {}) {
    if (!key) return;
    return set(key, parse ? JSON.stringify(data) : data);
  }
  async removeByKey(key) {
    if (!key) return;
    return del(key);
  }

  async getByKey(key, { parse = true } = {}) {
    if (!key) return false;
    const data = await get(key);
    if (data) {
      return parse ? JSON.parse(data) : data;
    }
    return null;
  }

  generateKeyFromObj(obj) {
    return md5(obj, {});
  }

  async clearAll() {
    return clear();
  }
}

export const codegenCache = new CodegenCache();

export default CodegenCache;
